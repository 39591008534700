import { createStyles, Tabs, Text, Textarea } from '@mantine/core';
import { IconEye, IconWriting } from '@tabler/icons-react';
import type React from 'react';
import { memo, useEffect, useState } from 'react';
import MarkdownRenderer from '../../../../MarkdownRenderer';

interface IMultiLineEditorTabsProps {
	defaultValue?: string;
	charPress?: string;
	moveCaretToEnd: any;
	placeholder?: string;
	previewPlaceholder?: string;
	onChange: (value: string) => void;
}

const useStyles = createStyles({
	textAreaWrapper: { marginTop: 0 },
	textAreaInput: { border: 'none', borderWidth: 2 },
	markdownWrapper: { padding: 10, minHeight: '285px' },
});

function MultiLineEditorTabs({
	defaultValue,
	charPress,
	moveCaretToEnd,
	onChange,
	placeholder,
	previewPlaceholder,
}: IMultiLineEditorTabsProps) {
	const { classes } = useStyles();

	const [inputValue, setInputValue] = useState<string>(
		`${defaultValue ?? ''}${charPress ?? ''}`
	);

	useEffect(() => {
		onChange(inputValue);
	}, [inputValue, onChange]);

	const handleTextAreaInputChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	): void => {
		setInputValue(event.target.value);
	};

	return (
		<Tabs defaultValue="edit" color="dark">
			<Tabs.List>
				<Tabs.Tab value="edit" icon={<IconWriting />}>
					Edit
				</Tabs.Tab>
				<Tabs.Tab value="preview" icon={<IconEye />}>
					Preview
				</Tabs.Tab>
			</Tabs.List>
			<Tabs.Panel value="preview">
				{!inputValue ? (
					<Text p={16} color="dimmed" size="sm">
						{previewPlaceholder}
					</Text>
				) : (
					<MarkdownRenderer className={classes.markdownWrapper}>
						{inputValue}
					</MarkdownRenderer>
				)}
			</Tabs.Panel>
			<Tabs.Panel value="edit">
				<Textarea
					autoFocus
					autosize
					data-autofocus
					classNames={{
						wrapper: classes.textAreaWrapper,
						input: classes.textAreaInput,
					}}
					minRows={12}
					rows={12}
					maxRows={24}
					placeholder={placeholder}
					value={inputValue}
					onFocus={moveCaretToEnd}
					onChange={handleTextAreaInputChange}
				/>
			</Tabs.Panel>
		</Tabs>
	);
}

export default memo(MultiLineEditorTabs);
