import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';

export const TestRunColumns: ColumnDefinition = {
	status: {
		field: 'status',
		editable: false,
		sortable: false,

		flex: 1,
		maxWidth: 80,
	},
	run_started_at: {
		field: 'run_started_at',
		headerName: 'Started at',
		editable: false,
		sortable: false,

		flex: 1,

		lockVisible: true,
		suppressMenu: true,
		maxWidth: 150,
	},
	run_finished_at: {
		field: 'run_finished_at',
		headerName: 'Finished at',
		editable: false,
		sortable: false,

		type: ColumnType.textFilterColumn,
		flex: 1,

		lockVisible: true,
		suppressMenu: true,
		maxWidth: 150,
	},
};
