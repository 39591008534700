import { Title } from '@repo/foundations';
import AddAutomationButton from '../../Automation/AddAutomationButton';
import {
	NavBarWrapper,
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
} from '.';

function AutomationListPageNavbar() {
	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Title order={1} size="sm" data-testid="automation-page-nav-bar-title">
					Automations
				</Title>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<AddAutomationButton />
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default AutomationListPageNavbar;
