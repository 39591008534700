import { wrappingInputRule } from 'prosemirror-inputrules';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import { ParseSpec } from 'prosemirror-markdown';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import toggleList from '../commands/toggleList';
import Node, { NodeOptions } from './Node';

export default class OrderedList extends Node {
	get name() {
		return 'ordered_list';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				order: {
					default: 1,
				},
			},
			content: 'list_item+',
			group: 'block',
			parseDOM: [
				{
					tag: 'ol',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									order: dom.hasAttribute('start')
										? parseInt(dom.getAttribute('start') || '1', 10)
										: 1,
								},
				},
			],
			toDOM: (node) =>
				node.attrs.order === 1
					? ['ol', 0]
					: ['ol', { start: node.attrs.order }, 0],
		};
	}

	commands({ type, schema }: NodeOptions) {
		return () => toggleList(type, schema.nodes.list_item);
	}

	keys({ type, schema }: NodeOptions) {
		return {
			'Shift-Ctrl-9': toggleList(type, schema.nodes.list_item),
		};
	}

	inputRules({ type }: NodeOptions) {
		return [
			wrappingInputRule(
				/^(\d+)\.\s$/,
				type,
				(match) => ({ order: +match[1] }),
				(match, node) => node.childCount + node.attrs.order === +match[1]
			),
		];
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.write('\n');

		const start = node.attrs.order !== undefined ? node.attrs.order : 1;
		const maxW = `${start + node.childCount - 1}`.length;
		const space = state.repeat(' ', maxW + 2);

		state.renderList(node, space, (i: number) => {
			const nStr = `${start + i}`;
			return `${state.repeat(' ', maxW - nStr.length) + nStr}. `;
		});
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'ordered_list',
			getAttrs: (tok) => ({
				order: parseInt(tok.attrGet('start') || '1', 10),
			}),
		};
	}
}
