import type { MouseEvent } from 'react';
import { Icon } from '@repo/foundations';
import { useNavigateHandler } from '../../../hooks/useNavigate';
import { DataTreeLoader } from '../DataTreeLoader';
import { LoadMore, TreeNode } from '../TreeNode';
import { useCatalogInfiniteList } from '../../../api/hooks/resourceCatalog';
import { EntityType } from '../../../lib/types';
import { CommonEntityTreeNode, getSameEntityTypeChildren } from '../utils';
import { Filter } from '../../../api/codegen/apiSchemas';

interface DictionaryTermTreeProps {
	teamId: string;
}

export function DictionaryTermTree({ teamId }: DictionaryTermTreeProps) {
	const {
		data: result,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
	} = useCatalogInfiniteList({
		filters: {
			teams: teamId,
			filter: {
				operator: 'and',
				operands: [
					{
						operator: 'not',
						field: 'parent_id',
						operands: [],
					},
					{
						field: 'entity_type',
						operator: 'exact',
						value: EntityType.dictionary_term,
					},
				],
			} as Filter,
			sort: {
				field: 'title',
				order: 'asc',
			},
		},
	});

	const nodes: CommonEntityTreeNode[] | undefined = result?.pages.map(
		(dictionaryTerm) => ({
			id: dictionaryTerm.id,
			entity: dictionaryTerm,
			hasChildren: Boolean(dictionaryTerm.has_child_of_same_type),
		})
	);

	const navigateHandler = useNavigateHandler();

	const handleNavigate = (event: MouseEvent, node: CommonEntityTreeNode) => {
		const url = `/dictionary/${node.id}`;
		navigateHandler(event)(url);
	};

	if (!nodes) {
		return <DataTreeLoader />;
	}

	return (
		<>
			{nodes.map((node) => (
				<TreeNode
					key={node.id}
					node={node}
					onClick={handleNavigate}
					getIcon={() => <Icon name="book" color="icon/primary/default" />}
					level={0}
					getLabel={(n) => n.entity.title}
					onLoadChildren={getSameEntityTypeChildren}
				/>
			))}
			{hasNextPage && (
				<LoadMore
					level={0}
					onClick={fetchNextPage}
					isLoading={isFetchingNextPage}
				/>
			)}
		</>
	);
}
