import { Group, useMantineTheme } from '@mantine/core';
import { isEmpty, isNil, noop } from 'lodash-es';
import { Icon, Text } from '@repo/foundations';
import type { ITemplate } from '../../../api';
import { EntityType } from '../../../lib/types';
import { openConfirmModal } from '../../ModalManager';
import { entityModalStore } from '../store';
import { TeamSelector } from './TeamSelector';
import { TemplateSelector } from './TemplateSelector';

export function MetadataHeader() {
	const theme = useMantineTheme();

	const handleTeamChange = (value: string) => {
		entityModalStore.teamId = value;
	};

	const handleTemplateChange = (value: ITemplate) => {
		const set = () => {
			entityModalStore.setTemplate(value);
			entityModalStore.setDescription(value.definition ?? '');
			entityModalStore.setTitle(value.title ?? '');
		};

		if (
			!isEmpty(entityModalStore.description) &&
			entityModalStore.template?.id !== value?.id
		) {
			openConfirmModal({
				title: 'Template overwrite',
				children: (
					<Text size="sm">
						If you change the template, the description will be overwritten with
						the template contents. Please copy your description before changing
						the template.
					</Text>
				),
				labels: { confirm: 'Confirm', cancel: 'Cancel' },
				confirmProps: { variant: 'primary' },
				onCancel: noop,
				onConfirm: set,
			});
		} else {
			set();
		}
	};

	const withTemplate =
		!isNil(entityModalStore.type) &&
		entityModalStore.type !== EntityType.metric;

	return (
		<Group spacing={theme.spacing.xs}>
			<TeamSelector onChange={handleTeamChange} editorOnly={true} />
			{withTemplate && (
				<>
					<Icon name="chevronRight" />
					<TemplateSelector
						onChange={handleTemplateChange}
						entityType={entityModalStore.type!}
						initialValue={entityModalStore.template?.id}
					/>
				</>
			)}
		</Group>
	);
}
