import type { Node } from 'prosemirror-model';
import headingToSlug from './headingToSlug';

export interface Heading {
	title: string;
	level: number;
	legacyId: string;
	id: string;
	pos: number;
}

export function getHeadings(doc: Node) {
	const headings: Heading[] = [];
	const previouslySeen: Record<string, number> = {};

	doc.descendants((node, pos) => {
		if (node.type.name !== 'heading') {
			return;
		}

		// Calculate the optimal id
		const id = headingToSlug(node);
		let slug = id;

		// Check if we've already used it, and if so how many times?
		// Make the new id based on that number ensuring that we have
		// unique ID's even when headings are identical
		if (previouslySeen[id] > 0) {
			slug = headingToSlug(node, previouslySeen[id]);
		}

		// Record that we've seen this id for the next loop
		previouslySeen[id] =
			previouslySeen[id] !== undefined ? previouslySeen[id] + 1 : 1;

		const staticId = node.attrs.id ?? slug;

		headings.push({
			title: node.textContent,
			level: node.attrs.level,
			legacyId: slug,
			id: staticId,
			pos,
		});
	});

	return headings;
}
