/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { queryClient } from '../api';
import { thisUserQuery } from '../api/hooks/user/useThisUser';
import { AuthCallback } from '../components/Auth/AuthCallback';
import { AuthImpersonate } from '../components/Auth/AuthImpersonate';
import { AuthSignout } from '../components/Auth/AuthSignout';
import { AuthStepProfile } from '../components/Auth/AuthStepProfile';
import { AuthStepLogin } from '../components/Auth/AuthStepLogin';
import { AuthStepSamlSso } from '../components/Auth/AuthStepSamlSso';
import { AuthStepSignup } from '../components/Auth/AuthStepSignup';
import { AuthStepStartContextProvider } from '../components/Auth/AuthStepStartContext';
import { AuthStepVerify } from '../components/Auth/AuthStepVerify';
import { AuthStepConfirm } from '../components/Auth/AuthStepConfirm';
import { AuthStepWorkspace } from '../components/Auth/AuthStepWorkspace';
import { ModalManagerProvider } from '../components/ModalManager';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { PrivateRoute } from '../components/PrivateRoute';
import { BillingCallback } from '../components/Settings/BillingCallback';
import { TeamPageRoute } from '../components/TeamPageRoute';
import SecodaAppShell from '../components/SecodaAppShell';
import { RootAuthRedirect } from '../pages/RootPage/RootAuthRedirect';
import { RootHomeRedirect } from '../pages/RootPage/RootHomeRedirect';
import { LEGACY_NON_TEAM_PATHS } from '../pages/LegacyNonTeamPage/constants';
import { LoadingSpinner } from '../components/LoadingSpinner';

const HomePage = React.lazy(() => import('../pages/HomePage/HomePage'));
const LegacyNonTeamPage = React.lazy(
	() => import('../pages/LegacyNonTeamPage')
);
const OnboardingViewerWelcomePage = React.lazy(
	() => import('../pages/Onboarding/OnboardingViewerWelcomePage')
);
const ChromeExtensionPage = React.lazy(
	() => import('../pages/ChromeExtensionPage/ChromeExtensionPage')
);
const TeamHomePage = React.lazy(() => import('../pages/HomePage/TeamHomePage'));
const SettingsPage = React.lazy(
	() => import('../components/Settings/SettingsPage')
);
const IntegrationPage = React.lazy(
	() => import('../components/Integration/IntegrationPage')
);
const IntegrationSelectionPage = React.lazy(
	() => import('../components/Integration/IntegrationSelectionPage')
);
const EmbeddedPromptPage = React.lazy(() => import('../pages/AIAssistantPage'));
const AnalyticsListPage = React.lazy(
	() => import('../pages/AnalyticsListPage')
);
const AnalyticsPage = React.lazy(() => import('../pages/AnalyticsPage'));
const AutomationListPage = React.lazy(
	() => import('../pages/AutomationListPage/AutomationListPage')
);
const AutomationPage = React.lazy(
	() => import('../pages/AutomationPage/AutomationPageWrapper')
);
const ChartPage = React.lazy(() => import('../pages/ChartPage'));
const CollectionPage = React.lazy(() => import('../pages/CollectionPage'));
const ColumnEntityPage = React.lazy(() => import('../pages/ColumnEntityPage'));
const DashboardPage = React.lazy(() => import('../pages/DashboardPage'));
const DictionaryTermPage = React.lazy(
	() => import('../pages/DictionaryTermPage')
);
const DocumentPage = React.lazy(() => import('../pages/DocumentPage'));
const DownloadPage = React.lazy(() => import('../pages/DownloadPage'));
const ErrorPage = React.lazy(() => import('../pages/ErrorPage/ErrorPage'));
const EventPage = React.lazy(() => import('../pages/EventPage'));
const FormListPage = React.lazy(
	() => import('../pages/FormListPage/FormListPage')
);
const FormPage = React.lazy(() => import('../pages/FormPage/FormPage'));
const InboxPage = React.lazy(() => import('../pages/InboxPage'));
const IncidentPage = React.lazy(() => import('../pages/IncidentPage'));
const IntegrationsBrowsePage = React.lazy(
	() => import('../pages/IntegrationsBrowsePage/IntegrationsBrowsePage')
);
const IntegrationsListPage = React.lazy(
	() => import('../pages/IntegrationsPage')
);
const InternalMarketplaceListPage = React.lazy(
	() =>
		import('../pages/InternalMarketplaceListPage/InternalMarketplaceListPage')
);
const InternalMarketplaceReviewPage = React.lazy(
	() =>
		import(
			'../pages/InternalMarketplaceReviewPage/InternalMarketplaceReviewPage'
		)
);
const InternalPage = React.lazy(() => import('../pages/InternalPage'));
const InspectIntegrationAPIPage = React.lazy(
	() =>
		import(
			'../pages/InternalPage/InspectIntegrationAPIPage/InspectIntegrationAPIPage'
		)
);
const BackgroundJobPage = React.lazy(
	() => import('../pages/InternalPage/BackgroundJobPage/BackgroundJobPage')
);
const IntegrationsStatusPage = React.lazy(
	() => import('../pages/InternalPage/IntegrationsStatusPage')
);
const QueryActionsPage = React.lazy(
	() => import('../pages/InternalPage/QueryActionsPage')
);
const JobPage = React.lazy(() => import('../pages/JobPage'));
const MarketplaceIntegrationSpecDetailPage = React.lazy(
	() =>
		import(
			'../pages/MarketplaceIntegrationSpecDetailPage/MarketplaceIntegrationSpecDetailPage'
		)
);
const MarketplaceIntegrationSpecVersionDetailPage = React.lazy(
	() =>
		import(
			'../pages/MarketplaceIntegrationSpecVersionDetailPage/MarketplaceIntegrationSpecVersionDetailPage'
		)
);
const MetricPage = React.lazy(() => import('../pages/MetricPage'));
const MonitorListPage = React.lazy(
	() => import('../pages/MonitorListPage/MonitorListPage')
);
const MonitorPage = React.lazy(() => import('../pages/MonitorPage'));
const NewMarketplaceIntegrationSpecVersionPage = React.lazy(
	() =>
		import(
			'../pages/NewMarketplaceIntegrationSpecVersionPage/NewMarketplaceIntegrationSpecVersionPage'
		)
);
const PlansPage = React.lazy(() => import('../pages/PlansPage/PlansPage'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage'));
const QuestionPage = React.lazy(() => import('../pages/QuestionPage'));
const SchemaPage = React.lazy(() => import('../pages/SchemaPage'));
const SearchPage = React.lazy(() => import('../pages/SearchPage'));
const TableEntityPagePromise = import('../pages/TableEntityPage');
const TableEntityPage = React.lazy(() => TableEntityPagePromise);
const TeamCatalogPage = React.lazy(
	() => import('../pages/TeamCatalogPage/TeamCatalogPage')
);
const TeamCollectionsPage = React.lazy(
	() => import('../pages/TeamCollectionsPage')
);
const TeamDictionaryTermsPage = React.lazy(
	() => import('../pages/TeamDictionaryTermsPage')
);
const TeamDocumentsPage = React.lazy(
	() => import('../pages/TeamDocumentsPage')
);
const TeamMetricsPage = React.lazy(() => import('../pages/TeamMetricsPage'));
const TeamQuestionsPage = React.lazy(
	() => import('../pages/TeamQuestionsPage')
);
const TeamsPage = React.lazy(() => import('../pages/TeamsPage/TeamsPage'));
const TemplatePage = React.lazy(() => import('../pages/TemplatePage'));
const TrashPage = React.lazy(() => import('../pages/TrashPage/TrashPage'));
const TunnelPage = React.lazy(() => import('../pages/TunnelPage'));
const TunnelsPage = React.lazy(() => import('../pages/TunnelsPage'));
const UserGroupProfilePage = React.lazy(
	() => import('../pages/UserGroupProfilePage')
);
const QueryPage = React.lazy(() => import('../pages/QueryPage/QueryPage'));
const WorkspaceQueriesPage = React.lazy(
	() => import('../pages/WorkspaceQueriesPage/WorkspaceQueriesPage')
);

export function withSuspenseWrapper<T>(
	WrappedComponent: React.ComponentType<T>,
	pageTitle?: string
) {
	// DisplayName for React Dev Tools.
	const displayName =
		WrappedComponent.displayName || WrappedComponent.name || 'Component';

	function ComponentWithSuspense(props: T) {
		return (
			<ErrorBoundary>
				<Suspense fallback={<LoadingSpinner />}>
					{pageTitle && (
						<Helmet>
							<title>{pageTitle}</title>
						</Helmet>
					)}
					<WrappedComponent {...(props as any)} />
				</Suspense>
			</ErrorBoundary>
		);
	}

	ComponentWithSuspense.displayName = `withSuspenseWrapper(${displayName})`;

	return ComponentWithSuspense;
}

const UserGroupProfilePageWithSuspense =
	withSuspenseWrapper(UserGroupProfilePage);
const DashboardPageWithSuspense = withSuspenseWrapper(
	DashboardPage,
	'Dashboard'
);
const ChartPageWithSuspense = withSuspenseWrapper(ChartPage, 'Chart');
const JobPageWithSuspense = withSuspenseWrapper(JobPage, 'Job');
const EventPageWithSuspense = withSuspenseWrapper(EventPage, 'Event');
const SearchPageWithSuspense = withSuspenseWrapper(SearchPage, 'Search');
const InboxPageWithSuspense = withSuspenseWrapper(InboxPage, 'Inbox');
const TableEntityPageWithSuspense = withSuspenseWrapper(
	TableEntityPage,
	'Table Entity'
);
const ColumnEntityPageWithSuspense = withSuspenseWrapper(
	ColumnEntityPage,
	'Column Entity'
);
const SchemaPageWithSuspense = withSuspenseWrapper(SchemaPage, 'Schema');
const ProfilePageWithSuspense = withSuspenseWrapper(ProfilePage);
const TunnelPageWithSuspense = withSuspenseWrapper(TunnelPage, 'Tunnel');
const TunnelsPageWithSuspense = withSuspenseWrapper(TunnelsPage, 'Tunnels');
const SettingsPageWithSuspense = withSuspenseWrapper(SettingsPage);
const IntegrationPageWithSuspense = withSuspenseWrapper(
	IntegrationPage,
	'Integrations'
);
const IntegrationsListPageWithSuspense = withSuspenseWrapper(
	IntegrationsListPage,
	'Integrations'
);
const IntegrationSelectionPageWithSuspense = withSuspenseWrapper(
	IntegrationSelectionPage,
	'Integrations'
); // Prettier-ignore
const MetricsPageWithSuspense = withSuspenseWrapper(MetricPage, 'Metric');
const DictionaryTermPageWithSuspense = withSuspenseWrapper(
	DictionaryTermPage,
	'Dictionary Term'
);
const CollectionPageWithSuspense = withSuspenseWrapper(
	CollectionPage,
	'Collection'
);
const QuestionPageWithSuspense = withSuspenseWrapper(QuestionPage, 'Question');
const DocumentPageWithSuspense = withSuspenseWrapper(DocumentPage, 'Document');
const PlansPageWithSuspense = withSuspenseWrapper(PlansPage, 'Plans');
const HomePageWithSuspense = withSuspenseWrapper(HomePage);
const TeamHomePageWithSuspense = withSuspenseWrapper(TeamHomePage, 'Team Home');
const TemplatePageWithSuspense = withSuspenseWrapper(TemplatePage, 'Template');
const EmbeddedPromptPageWithSuspense = withSuspenseWrapper(
	EmbeddedPromptPage,
	'Secoda AI'
);
const AnalyticsListPageWithSuspense = withSuspenseWrapper(
	AnalyticsListPage,
	'Analytics'
);
const AnalyticsPageWithSuspense = withSuspenseWrapper(
	AnalyticsPage,
	'Analytics'
);
const TeamCatalogPageWithSuspense = withSuspenseWrapper(
	TeamCatalogPage,
	'Catalog'
);
const TeamCollectionsPageWithSuspense = withSuspenseWrapper(
	TeamCollectionsPage,
	'Collections'
);
const TeamDictionaryTermPageWithSuspense = withSuspenseWrapper(
	TeamDictionaryTermsPage,
	'Dictionary'
);
const TeamMetricsPageWithSuspense = withSuspenseWrapper(
	TeamMetricsPage,
	'Metric'
);
const TeamDocumentsPageWithSuspense = withSuspenseWrapper(
	TeamDocumentsPage,
	'Documents'
);
const TeamQuestionsPageWithSuspense = withSuspenseWrapper(
	TeamQuestionsPage,
	'Questions'
);
const TeamsPageWithSuspense = withSuspenseWrapper(TeamsPage, 'Teams');
const MonitorPageWithSuspense = withSuspenseWrapper(MonitorPage, 'Monitor');
const MonitorListPageWithSuspense = withSuspenseWrapper(
	MonitorListPage,
	'Monitors'
);
const InternalPageWithSuspense = withSuspenseWrapper(InternalPage);
const IncidentPageWithSuspense = withSuspenseWrapper(IncidentPage, 'Incident');
const TrashPageWithSuspense = withSuspenseWrapper(TrashPage, 'Archived');
const AutomationPageWithSuspense = withSuspenseWrapper(
	AutomationPage,
	'Automation'
);
const AutomationListPageWithSuspense = withSuspenseWrapper(
	AutomationListPage,
	'Automations'
);
const FormListPageWithSuspense = withSuspenseWrapper(FormListPage, 'Forms');
const FormPageWithSuspense = withSuspenseWrapper(FormPage, 'Form');
const NewMarketplaceIntegrationSpecVersionPageWithSuspense =
	withSuspenseWrapper(NewMarketplaceIntegrationSpecVersionPage, 'Integrations');

const MarketplaceIntegrationSpecDetailPageWithSuspense = withSuspenseWrapper(
	MarketplaceIntegrationSpecDetailPage,
	'Integrations'
);

const MarketplaceIntegrationSpecVersionDetailPageWithSuspense =
	withSuspenseWrapper(
		MarketplaceIntegrationSpecVersionDetailPage,
		'Integrations'
	);

const IntegrationsBrowsePageWithSuspense = withSuspenseWrapper(
	IntegrationsBrowsePage,
	'Integrations'
);

const InternalMarketplaceListPageWithSuspense = withSuspenseWrapper(
	InternalMarketplaceListPage,
	'Integrations'
);

const InternalMarketplaceReviewPageWithSuspense = withSuspenseWrapper(
	InternalMarketplaceReviewPage,
	'Integrations'
);
const QueryPageWithSuspense = withSuspenseWrapper(QueryPage, 'Query');
const WorkspaceQueriesPageWithSuspense = withSuspenseWrapper(
	WorkspaceQueriesPage,
	'Queries'
);

export const routes = (
	<Route
		element={
			// ModalsProvider is used to render modals in the root of the app.
			// This is unique in that it needs to be within the react-router but outside all routes.
			<ErrorBoundary>
				<ModalManagerProvider modalProps={{ size: 'lg' }}>
					<Outlet />
				</ModalManagerProvider>
			</ErrorBoundary>
		}
	>
		<Route
			loader={() => queryClient.fetchQuery(thisUserQuery())}
			errorElement={
				<ErrorBoundary>
					<RootAuthRedirect />
				</ErrorBoundary>
			}
			path="/"
			element={
				<PrivateRoute>
					<RootHomeRedirect />
				</PrivateRoute>
			}
		/>
		<Route
			path="/auth"
			element={
				<AuthStepStartContextProvider>
					<AuthStepLogin />
				</AuthStepStartContextProvider>
			}
		/>
		<Route path="/auth/login" element={<RootAuthRedirect />} />
		<Route
			path="/auth/login-sso"
			element={
				<AuthStepStartContextProvider>
					<AuthStepSamlSso />
				</AuthStepStartContextProvider>
			}
		/>
		<Route
			path="/auth/signup"
			element={
				<AuthStepStartContextProvider>
					<AuthStepSignup />
				</AuthStepStartContextProvider>
			}
		/>
		<Route path="/auth/verify" element={<AuthStepVerify />} />
		<Route path="/auth/callback" element={<AuthCallback />} />
		<Route path="/auth/signout" element={<AuthSignout />} />
		<Route path="/auth/utilities/account" element={<AuthImpersonate />} />
		<Route
			errorElement={
				<ErrorBoundary>
					<RootAuthRedirect />
				</ErrorBoundary>
			}
			loader={async () => queryClient.fetchQuery(thisUserQuery())}
		>
			<Route path="/internal" element={<InternalPageWithSuspense />}>
				<Route index element={<Navigate replace to="integrations" />} />
				<Route path="integrations" element={<IntegrationsStatusPage />} />
				<Route path="query-actions" element={<QueryActionsPage />} />
				<Route path="inspect-api" element={<InspectIntegrationAPIPage />} />
				<Route path="background-job" element={<BackgroundJobPage />} />
			</Route>
			<Route
				path="/internal/marketplace"
				element={<InternalMarketplaceListPageWithSuspense />}
			/>
			<Route
				path="/internal/marketplace/review/:specId/version/:versionId"
				element={<InternalMarketplaceReviewPageWithSuspense />}
			/>
			<Route path="/download" element={<DownloadPage />} />
			<Route path="/onboarding/profile" element={<AuthStepProfile />} />
			<Route path="/onboarding/workspace" element={<AuthStepWorkspace />} />
			<Route path="/onboarding/confirm" element={<AuthStepConfirm />} />
			<Route
				path="/onboarding/welcome"
				element={
					<PrivateRoute hideNavBar hideSideBar>
						<OnboardingViewerWelcomePage />
					</PrivateRoute>
				}
			/>
			<Route path="/404" element={<ErrorPage code={404} />} />
			<Route path="/500" element={<ErrorPage code={500} />} />
			<Route path="/403" element={<ErrorPage code={403} />} />
			<Route
				path="/plans"
				element={
					<PrivateRoute hideSideBar>
						<PlansPageWithSuspense />
					</PrivateRoute>
				}
			/>
			<Route element={<SecodaAppShell />}>
				<Route
					path="/chrome-extension"
					element={
						<PrivateRoute>
							<ChromeExtensionPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/group/:id"
					element={
						<PrivateRoute>
							<UserGroupProfilePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/dashboard/:id/:tab?"
					element={
						<PrivateRoute hideNavBar>
							<DashboardPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/chart/:id/:tab?"
					element={
						<PrivateRoute hideNavBar>
							<ChartPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/job/:id/:tab?"
					element={
						<PrivateRoute hideNavBar>
							<JobPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/event/:id/:tab?"
					element={
						<PrivateRoute hideNavBar>
							<EventPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/search"
					element={
						<PrivateRoute>
							<SearchPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/inbox"
					element={
						<PrivateRoute>
							<InboxPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/analytics"
					element={
						<PrivateRoute guestRestricted>
							<AnalyticsListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/analytics/:id"
					element={
						<PrivateRoute guestRestricted>
							<AnalyticsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/table/:id/:tab?"
					element={
						<PrivateRoute hideNavBar>
							<TableEntityPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/column/:id/:tab?"
					element={
						<PrivateRoute hideNavBar>
							<ColumnEntityPageWithSuspense />
						</PrivateRoute>
					}
				/>

				<Route
					path="/schema/:id/:tab?"
					element={
						<PrivateRoute hideNavBar>
							<SchemaPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/monitor/:id"
					element={
						<PrivateRoute hideNavBar>
							<MonitorPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/automation/:id"
					element={
						<PrivateRoute editorRequired hideNavBar>
							<AutomationPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/incident/:id"
					element={
						<PrivateRoute hideNavBar>
							<IncidentPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/user/:id"
					element={
						<PrivateRoute>
							<ProfilePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/tunnels/new"
					element={
						<PrivateRoute>
							<TunnelPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/tunnels/:id"
					element={
						<PrivateRoute>
							<TunnelPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/tunnels"
					element={
						<PrivateRoute>
							<TunnelsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route path="/billing/callback" element={<BillingCallback />} />
				<Route
					path="/settings/:tab?/:subtab?"
					element={
						<PrivateRoute>
							<SettingsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations"
					element={
						<PrivateRoute adminRequired>
							<IntegrationsListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations/new"
					element={
						<PrivateRoute
							adminRequired
							hideSideBarFeatureFlag="integrationV3"
							hideNavBarFeatureFlag="integrationV3"
						>
							<IntegrationSelectionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations/browse"
					element={
						<PrivateRoute adminRequired hideNavBar>
							<IntegrationsBrowsePageWithSuspense />
						</PrivateRoute>
					}
				/>

				<Route
					path="/integrations/:id"
					element={
						<PrivateRoute adminRequired>
							<IntegrationPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations/:id/:tab"
					element={
						<PrivateRoute adminRequired>
							<IntegrationPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/metrics/:id"
					element={
						<PrivateRoute hideNavBar>
							<MetricsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/dictionary/:id"
					element={
						<PrivateRoute hideNavBar>
							<DictionaryTermPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/collections/:id"
					element={
						<PrivateRoute hideNavBar>
							<CollectionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/collections/:id/:tab"
					element={
						<PrivateRoute hideNavBar>
							<CollectionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/questions/:id"
					element={
						<PrivateRoute hideNavBar>
							<QuestionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/docs/:id"
					element={
						<PrivateRoute hideNavBar>
							<DocumentPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/templates/:id/"
					element={
						<PrivateRoute hideNavBar>
							<TemplatePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/ai/:id/"
					element={
						<PrivateRoute hideNavBar>
							<EmbeddedPromptPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/home"
					element={
						<PrivateRoute>
							<HomePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/"
					element={
						<PrivateRoute>
							<TeamPageRoute pageKey="home">
								<TeamHomePageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/catalog"
					element={
						<PrivateRoute>
							<TeamPageRoute pageKey="catalog">
								<TeamCatalogPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/collections"
					element={
						<PrivateRoute>
							<TeamPageRoute pageKey="collections">
								<TeamCollectionsPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/dictionary"
					element={
						<PrivateRoute>
							<TeamPageRoute pageKey="dictionary">
								<TeamDictionaryTermPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/metrics"
					element={
						<PrivateRoute>
							<TeamPageRoute pageKey="metrics">
								<TeamMetricsPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/docs"
					element={
						<PrivateRoute>
							<TeamPageRoute pageKey="documents">
								<TeamDocumentsPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/questions"
					element={
						<PrivateRoute>
							<TeamPageRoute pageKey="questions">
								<TeamQuestionsPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams"
					element={
						<PrivateRoute guestRestricted>
							<TeamsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/monitors"
					element={
						<PrivateRoute>
							<MonitorListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/automations"
					element={
						<PrivateRoute editorRequired>
							<AutomationListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/forms"
					element={
						<PrivateRoute>
							<FormListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/forms/:id/:tab?"
					element={
						<PrivateRoute hideNavBar>
							<FormPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/archived"
					element={
						<PrivateRoute>
							<TrashPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/queries"
					element={
						<PrivateRoute hideNavBar>
							<WorkspaceQueriesPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/new"
					element={
						<PrivateRoute hideNavBar adminRequired>
							<NewMarketplaceIntegrationSpecVersionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/:specId"
					element={
						<PrivateRoute hideNavBar adminRequired>
							<MarketplaceIntegrationSpecDetailPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/:specId/new-version"
					element={
						<PrivateRoute hideNavBar adminRequired>
							<NewMarketplaceIntegrationSpecVersionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/:specId/version/:versionId"
					element={
						<PrivateRoute hideNavBar adminRequired>
							<MarketplaceIntegrationSpecVersionDetailPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/:specId/version/:versionId"
					element={
						<PrivateRoute hideNavBar adminRequired>
							<MarketplaceIntegrationSpecVersionDetailPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/query/:id"
					element={
						<PrivateRoute hideNavBar>
							<QueryPageWithSuspense />
						</PrivateRoute>
					}
				/>

				<>
					{LEGACY_NON_TEAM_PATHS.map((path) => (
						<Route
							key={path}
							path={path}
							element={
								<PrivateRoute>
									<LegacyNonTeamPage oldPath={path} />
								</PrivateRoute>
							}
						/>
					))}
				</>
			</Route>
		</Route>
		<Route path="*" element={<ErrorPage code={404} />} />
	</Route>
);
