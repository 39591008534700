import {
	capitalize,
	compact,
	find,
	includes,
	isNil,
	lowerCase,
	map,
	sortBy,
} from 'lodash-es';
import type { IIntegration, INestedBreadcrumbs, ISearchMetadata } from '../api';
import { EntityType } from '../lib/types';
import {
	isCatalogEntity,
	isCollection,
	isDictionaryTerm,
	isDocument,
	isMetric,
	isQuestion,
	isSchema,
} from '../lib/utils/entity';
import type { TeamOut } from '../api/codegen/apiSchemas';
import { getEntityTypeDisplayInfo } from './entityDisplayUtils';
import { getNavigationUrl } from './url';

export interface BreadcrumbEntity {
	id: string;
	entity_type: EntityType;
	title: string;
	teams?: string[];
	integration?: string;
}

/**
 * Retrieves the team associated with the given entity.
 * If there are multiple teams associated with the entity, it picks the first one.
 * If there are no teams associated with the entity, it returns the ID of the 'General' team.
 * @param entity The entity object.
 * @param userTeams The array of teams the user is a member of. * @param workspaceIntegrations The array of workspace integrations.
 * @param workspaceIntegrations The array of workspace integrations.
 * @returns The ID of the associated team, or the ID of the default team if no specific team is found.
 */
const getEntityTeam = (
	entity: BreadcrumbEntity,
	userTeams: TeamOut[],
	workspaceIntegrations: IIntegration[]
) => {
	const userTeamIds = map(userTeams, 'id');
	// Retrieve the teams associated with the entity
	const entityTeams = entity.teams || [];
	// Retrieve the teams associated with the entity's integration (if any)
	const entityIntegrationTeams =
		find(
			workspaceIntegrations,
			(integration) => entity.integration === integration.id
		)?.teams || [];

	const validEntityTeamId = find(
		[...entityTeams, ...entityIntegrationTeams],
		(team) => includes(userTeamIds, team)
	);

	const defaultTeamId = find(userTeams, (team) => team.is_default_team)?.id;
	return validEntityTeamId || defaultTeamId;
};

/**
 * Returns a function that generates a breadcrumb link based on the provided entity, and workspace teams.
 * @param entity The entity object.
 * @param userTeams The array of teams the user is a member of.
 * @param workspaceIntegrations The array of workspace integrations.
 * @returns A function that generates the breadcrumb link.
 */
export const getBreadcrumbLink =
	(
		entity: BreadcrumbEntity,
		userTeams: TeamOut[] = [],
		workspaceIntegrations: IIntegration[] = []
	) =>
	(
		data: string | null | undefined,
		entityType?: EntityType | 'integration'
	) => {
		const value = data?.toLowerCase();
		const teamId = getEntityTeam(entity, userTeams, workspaceIntegrations);

		if (isCatalogEntity(entity)) {
			if (entityType === undefined) {
				return getNavigationUrl(`/teams/${teamId}/catalog`);
			}

			if (entityType === 'integration') {
				return getNavigationUrl(`/teams/${teamId}/catalog`, {
					filters: {
						integration: [value],
					},
				});
			}

			if (entityType === EntityType.table) {
				return getNavigationUrl(`/table/${value}`);
			}

			if (entityType === EntityType.column) {
				return getNavigationUrl(`/column/${value}`);
			}

			// Schema and parent has no specific page, so we return the catalog page with filters
			return getNavigationUrl(`/teams/${teamId}/catalog`, {
				filters: {
					parent: [value],
				},
			});
		}

		if (isCollection(entity)) {
			return `/teams/${teamId}/collections`;
		}

		if (isMetric(entity)) {
			return `/teams/${teamId}/metrics`;
		}

		if (isDictionaryTerm(entity)) {
			return `/teams/${teamId}/dictionary`;
		}

		if (isQuestion(entity)) {
			return `/teams/${teamId}/questions`;
		}

		if (isDocument(entity)) {
			return `/teams/${teamId}/docs`;
		}

		if (isSchema(entity)) {
			return `/teams/${teamId}/catalog`;
		}

		// Return an empty string if the entity type is not recognized
		return '';
	};

/**
 * Generates nestedBreadcrumbs for catalog entities.
 * @param type - Entity type.
 * @param breadcrumb - Breadcrumb function.
 * @param integration - Integration
 * @param nestedBreadcrumbs - Nested breadcrumb objects.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getCatalogEntityBreadcrumbs = (
	type: EntityType,
	breadcrumb: ReturnType<typeof getBreadcrumbLink>,
	integration?: IIntegration,
	nestedBreadcrumbs: INestedBreadcrumbs[] = []
) => {
	const { label } = getEntityTypeDisplayInfo(type);

	return [
		{ title: label, href: breadcrumb(null) },
		{
			title: integration?.name ?? 'Integration',
			href: breadcrumb(integration?.id, 'integration'),
		},
		...map(nestedBreadcrumbs, (item) => ({
			title: item.title,
			href: breadcrumb(item.id, item.entity_type),
		})),
	];
};

/**
 * Generates nestedBreadcrumbs for schemas.
 * @param breadcrumb - Breadcrumb function.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getSchemaBreadcrumbs = (
	breadcrumb: ReturnType<typeof getBreadcrumbLink>
) => [{ title: 'Schemas', href: breadcrumb(null) }];

/**
 * Generates nestedBreadcrumbs for collections.
 * @param breadcrumb - Breadcrumb function.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getCollectionBreadcrumbs = (
	breadcrumb: ReturnType<typeof getBreadcrumbLink>
) => [{ title: 'Collections', href: breadcrumb(null) }];

/**
 * Generates nestedBreadcrumbs for questions.
 * @param breadcrumb - Breadcrumb function.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getQuestionBreadcrumbs = (
	breadcrumb: ReturnType<typeof getBreadcrumbLink>
) => [{ title: 'Questions', href: breadcrumb(null) }];

/**
 * Generates nestedBreadcrumbs for metrics.
 * @param breadcrumb - Breadcrumb function.
 * @param nestedBreadcrumbs - Nested breadcrumb objects. Defaults to empty array.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getMetricBreadcrumbs = (
	breadcrumb: ReturnType<typeof getBreadcrumbLink>,
	nestedBreadcrumbs: INestedBreadcrumbs[] = []
) => [
	{
		title: 'Metrics',
		href: breadcrumb(null),
	},
	...map(nestedBreadcrumbs, (b) => ({
		title: b.title,
		href: `/metrics/${b.id}`,
	})),
];

/**
 * Generates nestedBreadcrumbs for dictionary terms.
 * @param breadcrumb - Breadcrumb function.
 * @param nestedBreadcrumbs - Nested breadcrumb objects. Defaults to empty array.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getDictionaryTermBreadcrumbs = (
	breadcrumb: ReturnType<typeof getBreadcrumbLink>,
	nestedBreadcrumbs: INestedBreadcrumbs[] = []
) => [
	{
		title: 'Dictionary',
		href: breadcrumb(null),
	},
	...map(nestedBreadcrumbs, (b) => ({
		title: b.title,
		href: `/dictionary/${b.id}`,
	})),
];

/**
 * Generates nestedBreadcrumbs for documents.
 * @param breadcrumb - Breadcrumb function.
 * @param nestedBreadcrumbs - Nested breadcrumb objects. Defaults to empty array.
 * @returns Array of breadcrumb objects with titles and hrefs.
 */
export const getDocumentBreadcrumbs = (
	breadcrumb: ReturnType<typeof getBreadcrumbLink>,
	nestedBreadcrumbs: INestedBreadcrumbs[] = []
) => [
	{
		title: 'Document',
		href: breadcrumb(null),
	},
	...map(nestedBreadcrumbs, (b) => ({
		title: b.title,
		href: `/docs/${b.id}`,
	})),
];

export function getBreadCrumbForEntity(
	entity: BreadcrumbEntity,
	teams?: TeamOut[],
	nestedBreadcrumbs?: INestedBreadcrumbs[],
	integrations?: IIntegration[]
) {
	const breadcrumb = getBreadcrumbLink(entity, teams, integrations);
	const integration = find(integrations, { id: entity.integration });

	const breadCrumbs = sortBy(nestedBreadcrumbs, ({ level }) => -level);

	if (isCatalogEntity(entity)) {
		return getCatalogEntityBreadcrumbs(
			entity.entity_type,
			breadcrumb,
			integration,
			breadCrumbs
		);
	}

	if (isSchema(entity)) {
		return getSchemaBreadcrumbs(breadcrumb);
	}

	if (isCollection(entity)) {
		return getCollectionBreadcrumbs(breadcrumb);
	}

	if (isQuestion(entity)) {
		return getQuestionBreadcrumbs(breadcrumb);
	}

	if (isMetric(entity) && !isNil(breadCrumbs)) {
		return getMetricBreadcrumbs(breadcrumb, breadCrumbs);
	}

	if (isDictionaryTerm(entity) && !isNil(breadCrumbs)) {
		return getDictionaryTermBreadcrumbs(breadcrumb, breadCrumbs);
	}

	if (isDocument(entity) && !isNil(breadCrumbs)) {
		return getDocumentBreadcrumbs(breadcrumb, breadCrumbs);
	}

	return [];
}

// NOTE: Generating breadcrumbs from search metadata is an approximation.
// This is used where it's expensive to do full recursive CTE queries in the backend to get the full path (Catalog page, selectors, etc..)
export function getBreadCrumbsFromSearchMetadata(
	metadata?: ISearchMetadata
): string[] {
	if (!metadata) {
		return [];
	}

	const { group, database, schema, table } = metadata;
	let breadcrumbs: (string | undefined)[] = [];

	if (database) {
		breadcrumbs = [database, schema];
		if (table) {
			breadcrumbs.push(table);
		}
	}

	if (group) {
		breadcrumbs = [group];
	}

	return compact(breadcrumbs);
}

export function getSummaryAndBreadCrumbs(
	entityType: string | null,
	metadata?: ISearchMetadata
) {
	const type = entityType ? capitalize(lowerCase(entityType)) : '';
	const breadCrumbs = getBreadCrumbsFromSearchMetadata(metadata);
	if (breadCrumbs.length === 0) {
		return type;
	}
	return `${type}${type ? ' · ' : ''}${breadCrumbs.join(' / ')}`;
}
