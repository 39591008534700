import { useMutation, useQuery } from '@tanstack/react-query';

import { apiClient, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';
import type { UseQueryOptionsArgs } from '../../factories/types';

import type { FormFieldValues, IFormSubmission } from '../../index';
import { queryClient } from '../../index';
import type { IForm, IFormField } from '../../types';

export const FORM_NAMESPACE = ['forms'];
export const SUBMISSION_NAMESPACE = ['submissions'];

export const formQueryKeys = createQueryKeys(FORM_NAMESPACE);
export const submissionQueryKeys = createQueryKeys(SUBMISSION_NAMESPACE);

/*
 * Form hooks
 */

export function useForm(formId: string, options?: UseQueryOptionsArgs) {
	const queryKey = [...FORM_NAMESPACE, formId];

	const queryFn = async () => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([formId]);
		const { data } = await apiClient.get(url, {});

		return data;
	};

	return useQuery(queryKey, queryFn, options);
}

export function useFormsList(
	filters: Record<string, unknown>,
	options?: UseQueryOptionsArgs
) {
	const queryKey = [...FORM_NAMESPACE, JSON.stringify(filters)];

	const queryFn = async () => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([]);
		const { data } = await apiClient.get(url, filters);
		return data;
	};

	return useQuery(queryKey, queryFn, options);
}

export function useCreateForm() {
	const mutationFn = async ({ form }: { form: IForm }): Promise<IForm> => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([]);
		const { data } = await apiClient.post<IForm>(url, form);

		return data;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(formQueryKeys.all());
		},
	});
}

export function useUpdateForm() {
	const mutationFn = async ({ form }: { form: IForm }) => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([form.id as string]);
		await apiClient.patch<IForm>(url, form);

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(formQueryKeys.all());
		},
	});
}

export function useDeleteForm() {
	const mutationFn = async ({ formId }: { formId: string }) => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([formId]);
		await apiClient.delete(url);

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(formQueryKeys.all());
		},
	});
}

export function useUpdateFormOrder() {
	const mutationFn = async ({
		formId,
		order,
	}: {
		formId: string;
		order: string[];
	}) => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([formId, 'order']);
		await apiClient.post(url, { order });

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(formQueryKeys.all());
		},
	});
}

/*
 * Form Field hooks
 */

export function useFormField(
	formId: string,
	fieldId: string,
	options?: UseQueryOptionsArgs
) {
	const queryKey = [...FORM_NAMESPACE, formId, fieldId];

	const queryFn = async () => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([
			formId,
			'fields',
			fieldId,
		]);
		const { data } = await apiClient.get(url, {});

		return data;
	};

	return useQuery(queryKey, queryFn, options);
}

export function useCreateFormField() {
	const mutationFn = async ({
		formId,
		field,
	}: {
		formId: string;
		field: IFormField;
	}): Promise<IFormField> => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([formId, 'fields']);
		const { data } = await apiClient.post<IFormField>(url, field);
		return data;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(formQueryKeys.all());
		},
	});
}

export function useUpdateFormField() {
	const mutationFn = async ({
		formId,
		field,
	}: {
		formId: string;
		field: IFormField;
	}) => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([
			formId,
			'fields',
			field.id || '',
		]);
		await apiClient.patch<IFormField>(url, field);

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(formQueryKeys.all());
		},
	});
}

export function useDeleteFormField() {
	const mutationFn = async ({
		formId,
		fieldId,
	}: {
		formId: string;
		fieldId: string;
	}) => {
		const url = getEndpoints(FORM_NAMESPACE).byPath([
			formId,
			'fields',
			fieldId,
		]);
		await apiClient.delete(url);

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(formQueryKeys.all());
		},
	});
}

export const useFormSubmissionsList =
	(formId: string, submitted?: boolean) => (options?: UseQueryOptionsArgs) => {
		const queryKey = [...SUBMISSION_NAMESPACE, formId];

		const queryFn = async (): Promise<IFormSubmission> => {
			let url = `${getEndpoints(SUBMISSION_NAMESPACE).byPath([])}`;
			url += `?form_id=${formId}`;
			if (submitted) {
				url += `&submitted=${submitted}`;
			}

			const { data } = await apiClient.get(url, {});

			return data;
		};

		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useQuery(queryKey, queryFn, options);
	};

export function useFormSubmission(
	submissionId: string,
	options?: UseQueryOptionsArgs
) {
	const queryKey = [...SUBMISSION_NAMESPACE, submissionId];

	const queryFn = async (): Promise<IFormSubmission> => {
		const url = getEndpoints(SUBMISSION_NAMESPACE).byPath([submissionId]);
		const { data } = await apiClient.get(url, {});

		return data;
	};

	return useQuery(queryKey, queryFn, options);
}

export function useDraftFormSubmission(
	formId: string,
	options?: UseQueryOptionsArgs
) {
	const queryKey = [...SUBMISSION_NAMESPACE, formId, 'draft'];

	const queryFn = async (): Promise<IFormSubmission> => {
		let url = getEndpoints(SUBMISSION_NAMESPACE).byPath(['draft']);
		url += `?form_id=${formId}`;
		const { data } = await apiClient.get(url, {});

		return data;
	};

	return useQuery(queryKey, queryFn, options);
}

export function useCreateFormSubmission() {
	const mutationFn = async ({
		form,
	}: {
		form: string;
	}): Promise<IFormSubmission> => {
		const url = getEndpoints(SUBMISSION_NAMESPACE).byPath([]);
		const { data } = await apiClient.post<IFormSubmission>(url, { form });

		return data;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(submissionQueryKeys.all());
		},
	});
}

export function useDeleteFormSubmission() {
	const mutationFn = async ({ submissionId }: { submissionId: string }) => {
		const url = getEndpoints(SUBMISSION_NAMESPACE).byPath([submissionId]);
		await apiClient.delete(url);

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(submissionQueryKeys.all());
		},
	});
}

export function useApproveFormSubmission() {
	const mutationFn = async ({ submissionId }: { submissionId: string }) => {
		const url = getEndpoints(SUBMISSION_NAMESPACE).byPath([
			submissionId,
			'approve',
		]);
		await apiClient.post(url, {});

		queryClient.invalidateQueries(submissionQueryKeys.all());

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(submissionQueryKeys.all());
		},
	});
}

export function useRejectFormSubmission() {
	const mutationFn = async ({
		submissionId,
		comment,
	}: {
		submissionId: string;
		comment?: string;
	}) => {
		const url = getEndpoints(SUBMISSION_NAMESPACE).byPath([
			submissionId,
			'reject',
		]);
		await apiClient.post(url, { comment });
		queryClient.invalidateQueries(submissionQueryKeys.all());

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(submissionQueryKeys.all());
		},
	});
}

export function useSubmitFormSubmission() {
	const mutationFn = async ({ submissionId }: { submissionId: string }) => {
		const url = getEndpoints(SUBMISSION_NAMESPACE).byPath([
			submissionId,
			'submit',
		]);
		await apiClient.post(url, {});

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(submissionQueryKeys.all());
		},
	});
}

export function useUpdateFormSubmissionValues() {
	const mutationFn = async ({
		submissionId,
		formFieldValues,
		fieldId,
	}: {
		submissionId: string;
		formFieldValues: FormFieldValues;
		fieldId: string;
	}) => {
		const url = getEndpoints(SUBMISSION_NAMESPACE).byPath([
			submissionId,
			'values',
		]);
		await apiClient.put(url, { value: formFieldValues, field: fieldId });

		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(submissionQueryKeys.all());
		},
	});
}

export function useReviseFormSubmission() {
	const mutationFn = async ({
		submissionId,
	}: {
		submissionId: string;
	}): Promise<IFormSubmission> => {
		const url = getEndpoints(SUBMISSION_NAMESPACE).byPath([
			submissionId,
			'revise',
		]);
		const { data } = await apiClient.post(url, {});

		return data;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(submissionQueryKeys.all());
		},
	});
}
