import {
	ActionIcon,
	createStyles,
	Group,
	Loader,
	ScrollArea,
	Stack,
} from '@mantine/core';
import { useMount } from 'ahooks';
import { Icon, Text } from '@repo/foundations';
import { Button } from '@repo/foundations';
import { useCallback, useState } from 'react';
import {
	useAIEmbeddedTerminate,
	useAuthUser,
	useBulkGenerateAiMetadata,
	useStreamingAIPrompt,
} from '../../api';
import { useStreamingContent } from '../../hooks/useStreamingContent';
import type { EntityType } from '../../lib/types';
import { trackEvent } from '../../utils/analytics';
import { useForceReRender } from '../../utils/hook/useForceReRender';
import { RichEditor } from '../RichEditor';
import { closeAllModals } from '../ModalManager';
import DescriptionEditorTooltip from '../RichTooltip/DescriptionEditorTooltip';
import { useAiEnabled } from '../../hooks/useAIEnabled';

export interface IDescriptionEditorProps {
	entityId?: string;
	entityType: EntityType;
	readOnly?: boolean;
	value: string;
	onChange: (value: string) => void;
	save?: () => void;
	shouldTriggerAIonMount?: boolean;
	classNames?: Partial<
		Record<'editorWrapper' | 'editorScrollAreaWrapper' | 'editor', string>
	>;
	integrationId?: string;
	persistDescription?: boolean;
}

const useStyles = createStyles((theme, readOnly: boolean) => ({
	editorScrollAreaWrapper: {
		maxHeight: 200,
	},
	aiButton: {
		position: 'absolute',
		top: theme.spacing.sm,
		right: theme.spacing.xs,

		'@media print': {
			display: 'none',
		},
	},
	editor: {
		fontSize: theme.fontSizes.sm,
	},
	descEditorWrapper: {
		width: '100%',
		color: readOnly
			? theme.other.getColor('text/secondary/default')
			: theme.other.getColor('text/primary/default'),
	},
}));

function DescriptionEditor({
	entityId,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	entityType,
	value,
	onChange,
	save,
	classNames,
	readOnly = false,
	shouldTriggerAIonMount = false,
	integrationId,
	persistDescription,
}: IDescriptionEditorProps) {
	const { cx, classes, theme } = useStyles(!!persistDescription || readOnly);

	const { isViewerOrGuestUser, user, workspace } = useAuthUser();
	const { enableAi } = useAiEnabled();

	const { key, forceUpdate } = useForceReRender();

	const { mutateAsync: generateAIDescription } = useBulkGenerateAiMetadata();
	const [showTooltip, setShowTooltip] = useState(false);

	const [calculatedPos, setCalculatedPos] = useState('bottom-start');

	const {
		isStreaming,
		hasAIGeneratedText,
		pollingId,
		setPollingId,
		activePrompt,
		setActivePrompt,
	} = useStreamingAIPrompt();

	const aiGeneratedDescription = useStreamingContent(
		isStreaming,
		activePrompt?.response?.content || '',
		forceUpdate
	);

	const { mutateAsync: terminateAIEmbedded } = useAIEmbeddedTerminate();

	const generateDescription = useCallback(async () => {
		if (!entityId) return;

		setPollingId(null);
		setActivePrompt(null);

		const { jobId } = await generateAIDescription({
			data: {
				entity_ids: [entityId],
				field: 'description',
			},
		});

		setPollingId(jobId);
		forceUpdate();
	}, [
		entityId,
		forceUpdate,
		generateAIDescription,
		setActivePrompt,
		setPollingId,
	]);

	useMount(async () => {
		if (shouldTriggerAIonMount) {
			// Trigger AI
			await generateDescription();
		}
	});

	const handleChange = useCallback(
		(updatedValue?: string) => {
			if (hasAIGeneratedText) {
				// Don't allow editing while Secoda AI is writing.
				return;
			}

			onChange(updatedValue ?? '');
		},
		[hasAIGeneratedText, onChange]
	);

	const handleStopClick = useCallback(async () => {
		if (!pollingId) {
			return;
		}

		await terminateAIEmbedded({
			id: pollingId,
		});

		setPollingId(null);
		setActivePrompt(null);
	}, [pollingId, setActivePrompt, setPollingId, terminateAIEmbedded]);

	const showAI = entityId && !isViewerOrGuestUser && !readOnly && enableAi;

	const handleKeepClick = useCallback(() => {
		if (!entityId) return;
		onChange(aiGeneratedDescription || '');
		setActivePrompt(null);
		trackEvent(
			'ai/description/keep',
			{
				entityId,
			},
			user,
			workspace!
		);
		save?.();
		closeAllModals();
	}, [
		aiGeneratedDescription,
		entityId,
		onChange,
		save,
		setActivePrompt,
		user,
		workspace,
	]);

	const handleRejectClick = useCallback(() => {
		if (!entityId) return;

		setActivePrompt(null);
		trackEvent(
			'ai/description/reject',
			{
				entityId,
			},
			user,
			workspace!
		);
		forceUpdate();
	}, [entityId, forceUpdate, setActivePrompt, user, workspace]);

	const initialValue = !hasAIGeneratedText ? value : aiGeneratedDescription;

	// Calculates the x-position of the tooltip based on the mouse position so it appears directly below the cursor
	const calculatePosition = useCallback((e: React.MouseEvent) => {
		const mouseX = e.clientX;
		const targetDims = (e.target as HTMLElement).getBoundingClientRect();

		if (mouseX < targetDims.width / 3 + targetDims.left)
			setCalculatedPos('bottom-start');
		else if (mouseX > (targetDims.width / 3) * 2 + targetDims.left)
			setCalculatedPos('bottom-end');
		else setCalculatedPos('bottom');
	}, []);

	return (
		<Stack
			className={classes.descEditorWrapper}
			spacing="xs"
			onMouseLeave={() => {
				setShowTooltip(false);
			}}
		>
			<ScrollArea
				className={cx(
					classes.editorScrollAreaWrapper,
					classNames?.editorScrollAreaWrapper
				)}
				offsetScrollbars
			>
				{integrationId && persistDescription ? (
					<DescriptionEditorTooltip
						integrationId={integrationId}
						initialValue={initialValue}
						showTooltip={showTooltip}
						calculatedPos={calculatedPos}
						onClick={(e) => {
							setShowTooltip(true);
							calculatePosition(e);
						}}
						persistDescription={persistDescription}
					/>
				) : (
					<RichEditor
						key={key}
						placeholder="Add description..."
						className={cx(classes.editor, classNames?.editor)}
						readOnly={hasAIGeneratedText || readOnly} // Disable editing when Secoda AI is writing
						initialValue={initialValue} // Use Secoda AI generated content if available
						onChangeCallback={handleChange}
						disableTopGap
						limited
					/>
				)}
				{!isStreaming &&
					!hasAIGeneratedText &&
					showAI &&
					!persistDescription && (
						<Group position="right" className={classes.aiButton}>
							<ActionIcon variant="subtle" onClick={generateDescription}>
								<Icon name="sparkles" color="icon/ai/default" />
							</ActionIcon>
						</Group>
					)}
			</ScrollArea>
			{isStreaming && (
				<Group position="apart">
					<Group spacing="xs">
						<Icon name="sparkles" color="icon/ai/default" />
						<Text size="sm" color="icon/ai/default" weight="semibold">
							Secoda AI is writing
						</Text>
						<Loader
							color={theme.other.getColor('icon/ai/default')}
							size="sm"
							variant="dots"
						/>
					</Group>
					<Button
						leftIconName="playerStop"
						variant="default"
						onClick={handleStopClick}
					>
						Stop
					</Button>
				</Group>
			)}
			{!isStreaming && hasAIGeneratedText && (
				<Group position="apart">
					<Button
						variant="default"
						leftIconName="x"
						onClick={handleRejectClick}
					>
						Reject
					</Button>
					<Group>
						<Button
							variant="default"
							leftIconName="reload"
							onClick={generateDescription}
						>
							Try again
						</Button>
						<Button
							leftIconName="check"
							onClick={handleKeepClick}
							variant="primary"
						>
							Keep
						</Button>
					</Group>
				</Group>
			)}
		</Stack>
	);
}

export default DescriptionEditor;
