import { Badge, Box, Card, Group, Image, Menu, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { IconButton } from '@repo/foundations';
import { useAuthUser } from '../../api';
import { useDeleteMarketplaceIntegrationSpecWithConfirmation } from '../../api/hooks/marketplace';
import type { IMarketplaceIntegrationSpec } from '../../api/types/models/marketplace';
import { useNavigate } from '../../hooks/useNavigate';
import {
	publishStatusBadgeColor,
	publishStatusBadgeLabel,
} from '../../utils/marketplaceUtils';
import { useSpecCardStyles } from './IntegrationSpecCard.helpers';

function SpecMenu({ spec }: { spec: IMarketplaceIntegrationSpec }) {
	const { apiDeleteSpec } =
		useDeleteMarketplaceIntegrationSpecWithConfirmation();
	const navigate = useNavigate();

	const deleteSpec = async () => {
		await apiDeleteSpec(spec.id, () => {});
	};

	const createNewVersion = () => {
		navigate(`/marketplace-integration/${spec.id}/new-version`);
	};

	const connect = () => {
		navigate(`/integrations/new?selectedMarketplaceSpec=${spec.id}`);
	};

	return (
		<Menu position="bottom-end">
			<Menu.Target>
				<IconButton iconName="dots" />
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item icon={<Icon name="plugConnected" />} onClick={connect}>
					<Text size="sm">Connect</Text>
				</Menu.Item>

				<Menu.Item icon={<Icon name="circlePlus" />} onClick={createNewVersion}>
					<Text size="sm">Create new version</Text>
				</Menu.Item>

				<Menu.Item
					icon={<Icon name="trash" color="text/critical/default" />}
					onClick={deleteSpec}
				>
					<Text size="sm" color="text/critical/default">
						Delete integration
					</Text>
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}

export function MarketplaceIntegrationSpecCard({
	spec,
	onClick,
	withConnectButton = true,
	showCommunityBadge = true,
	withMenu = false,
}: {
	spec: IMarketplaceIntegrationSpec;
	onClick: VoidFunction;
	withConnectButton?: boolean;
	showCommunityBadge?: boolean;
	withMenu?: boolean;
}) {
	const { classes, theme } = useSpecCardStyles();
	const { workspace } = useAuthUser();

	return (
		<Card
			variant="clickable"
			className={classes.integrationItem}
			sx={{
				position: 'relative',
			}}
			onClick={onClick}
		>
			{withMenu && (
				<Box
					pos="absolute"
					sx={{
						zIndex: 99,
						top: theme.spacing.md,
						right: theme.spacing.md,
					}}
				>
					<SpecMenu spec={spec} />
				</Box>
			)}
			<Stack h="100%">
				<Box className={classes.integrationLogoWrapper}>
					<Image
						width="16px"
						height="16px"
						src={spec.versions[0].icon_url}
						fit="contain"
					/>
				</Box>
				<Group spacing="xs" noWrap>
					<Text size="sm" weight="bold">
						{spec.versions[0].name}
					</Text>
					{showCommunityBadge && (
						<Badge size="xs" color="cyan" m={0}>
							Community
						</Badge>
					)}

					{spec.workspace_id === workspace.id && (
						<Badge
							size="xs"
							color={publishStatusBadgeColor(spec.versions[0].publish_status)}
							m={0}
						>
							{publishStatusBadgeLabel(spec.versions[0].publish_status)}
						</Badge>
					)}
				</Group>
				<Text size="xs">{spec.versions[0].description}</Text>
				<Stack sx={{ flexGrow: 1 }} justify="end">
					<Text size="xs" color="text/secondary/default">
						Version {spec.versions[0].version_number} • Created by{' '}
						{spec.workspace_name}
					</Text>
				</Stack>
			</Stack>
		</Card>
	);
}
