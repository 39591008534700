import type { MutationFunction } from '@tanstack/react-query';
import type { IApiListResponse } from '../../api';
import type { QueryKeyFactory } from '../../api/factories';
import type { EntityType } from '../../lib/types';
import { ExtendedDataTableColumn } from '../TableV2/types';

export type ColumnDefinition = Record<string, any>;

export enum ColumnType {
	'textFilterColumn' = 'textFilterColumn',
}

export type CatalogServerType =
	| EntityType.table
	| EntityType.column
	| EntityType.dashboard
	| EntityType.chart
	| EntityType.job
	| EntityType.event
	| EntityType.event_property
	| EntityType.dictionary_term
	| EntityType.metric
	| EntityType.document
	| EntityType.question
	| EntityType.collection
	| EntityType.user
	| EntityType.schema
	| EntityType.database
	| EntityType.test_run
	| 'secoda_entity'
	| 'entities'
	| 'monitors'
	| 'automations'
	| 'automation_jobs'
	| 'automation_history'
	| 'incidents'
	| 'measurements'
	| 'downstreamResources'
	| 'integrations'
	| 'tunnels'
	| 'catalog'
	| 'reports'
	| 'template';

export interface CatalogServer {
	type: CatalogServerType;
	queryKeyFactory: QueryKeyFactory;
	queryFn: (args: {
		filters: Record<string, string>;
		page: number;
		isViewerUser?: boolean;
	}) => Promise<IApiListResponse<any>>;
	invalidateCache: VoidFunction;
	update?: MutationFunction<any, any>;
}

export interface CatalogConfig {
	label: string;
	columns: ColumnDefinition;
	server: CatalogServer;
	tableV2ColumnsHook?: () => ExtendedDataTableColumn<any>[];
}
