import { Select } from '@mantine/core';
import { metricOptions } from '../../../utils/metrics';
import { type IMetric } from '../../../api';
import type { Metric } from '../../../lib/models';

export function PrimarySelectAction({
	onChange,
	metric,
}: {
	onChange: (value: string) => void;
	metric: IMetric | Metric;
}) {
	const { metricCols } = metricOptions(metric.results ?? []);

	return (
		<Select
			disabled={!metricCols.length}
			placeholder="Select primary column"
			defaultValue={metric.primary}
			onChange={onChange}
			data={metricCols.map(({ name }) => ({ value: name, label: name }))}
		/>
	);
}
