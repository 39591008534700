import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { isHash } from '../url';
import { isInternalUrl } from '../utils';
import { isModKey } from '../keyboard';

export function useEditorClickLink() {
	const navigate = useNavigate();

	return useCallback(
		(href: string, event: MouseEvent, forceNewTab: boolean = false) => {
			if (isModKey(event) || forceNewTab) {
				window.open(href, '_blank');
				return;
			}

			if (isHash(href)) {
				window.location.href = href;
				return;
			}

			if (isInternalUrl(href) && !href.includes('http')) {
				navigate(href);
			} else if (href) {
				window.location.href = href;
			}
		},
		[navigate]
	);
}
