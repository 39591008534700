import { createStyles, Box, Group, getDefaultZIndex } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useHotkeys } from '@mantine/hooks';
import { Button } from '@repo/foundations';
import { RichMarkdownEditor } from '../..';
import { useAISummary } from './useAISummary';

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'relative',
	},
	toolbar: {
		position: 'absolute',
		top: 'calc(100% + 16px)',
		width: '100%',
		background: theme.other.getColor('fill/primary/default'),
		padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
		borderRadius: theme.radius.md,
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		boxShadow: theme.shadows.md,
		zIndex: getDefaultZIndex('modal'),
	},
	content: {
		background: theme.other.getColor('fill/ai-secondary/default'),
		padding: `${theme.spacing['4xs']} ${theme.spacing.xs}`,
	},
}));

interface AISummaryContainerProps {
	initialSummary?: string;
	editor: RichMarkdownEditor;
	onShowToast: (message: string, type: 'error' | 'info') => void;
	onCancel: () => void;
	onSave: (summary: string) => void;
}

export function AISummaryContainer({
	initialSummary,
	editor,
	onShowToast,
	onSave,
	onCancel,
}: AISummaryContainerProps) {
	const { classes } = useStyles();

	const { summary, isLoading, generate } = useAISummary({
		editor,
		initialSummary,
		onShowToast,
	});

	useHotkeys([['Escape', onCancel]], undefined, true);

	return (
		<Box className={classes.wrapper}>
			<Text className={classes.content}>
				{isLoading && !summary ? 'Generating summary...' : summary}
			</Text>
			<Group className={classes.toolbar} spacing={0} position="apart">
				{isLoading ? (
					<>
						<Group spacing="xs">
							<Icon name="sparkles" color="icon/ai/default" />
							<Text size="xs" color="text/primary/disabled">
								Summarize
							</Text>
						</Group>
						<Button
							leftIconName="playerStop"
							variant="default"
							onClick={onCancel}
						>
							Stop
						</Button>
					</>
				) : (
					<>
						<Button variant="default" onClick={generate}>
							Try again
						</Button>
						<Group spacing="xs">
							<Button variant="default" onClick={onCancel}>
								Discard
							</Button>
							<Button variant="primary" onClick={() => onSave(summary)}>
								Keep
							</Button>
						</Group>
					</>
				)}
			</Group>
		</Box>
	);
}
