import { WorkspaceSelector } from '../SideBar/WorkspaceSelector';
import { NavBarWrapper } from '.';

function PlansPageNavBar() {
	return (
		<NavBarWrapper>
			<WorkspaceSelector />
		</NavBarWrapper>
	);
}

export default PlansPageNavBar;
