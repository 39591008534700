import { capitalize } from 'lodash-es';
import type React from 'react';
import { useIntegrationList } from '../../../api';
import IntegrationLogo from '../../IntegrationLogo';
import { useNavigateHandler } from '../../../hooks/useNavigate';
import { DataTreeLoader } from '../DataTreeLoader';
import { TreeNode } from '../TreeNode';
import { getEntityIconByType } from '../utils';
import type { NewCatalogTreeNode } from './utils';
import {
	getCatalogNodeChildrenNew,
	handleNavigateCatalogNodeNew,
	isIntegrationRootNode,
} from './utils';

interface CatalogTreeProps {
	teamId: string;
}

function NewCatalogTree({ teamId }: CatalogTreeProps) {
	// Fetch integrations assigned to the team
	const { data: integrationsData, isLoading: isLoadingIntegrations } =
		useIntegrationList({
			filters: {
				teams: teamId,
				include_via_entities: true,
				sort_by: 'name',
			},
		});

	const navigateHandler = useNavigateHandler();

	const nodes: NewCatalogTreeNode[] | undefined =
		integrationsData?.results
			.filter((integration) => isIntegrationRootNode(integration))
			.map((integration) => ({
				id: integration.id,
				hasChildren: true,
				type: 'integration',
				teamId,
				integration,
			})) || [];

	const getIcon = (node: NewCatalogTreeNode) => {
		if (node.type === 'integration') {
			return <IntegrationLogo integrationId={node.integration.id} size={16} />;
		}

		return getEntityIconByType(node.entity.entity_type);
	};

	const getLabel = (node: NewCatalogTreeNode) => {
		if (node.type === 'integration') {
			return capitalize(node.integration.name);
		}

		return node.entity.title;
	};

	const handleNavigate = (
		event: React.MouseEvent,
		node: NewCatalogTreeNode
	) => {
		handleNavigateCatalogNodeNew(event, node, navigateHandler);
	};

	if (isLoadingIntegrations) {
		return <DataTreeLoader />;
	}

	return (
		<>
			{nodes.map((node) => (
				<TreeNode
					key={node.id}
					node={node}
					getIcon={getIcon}
					level={0}
					onClick={handleNavigate}
					getLabel={getLabel}
					onLoadChildren={getCatalogNodeChildrenNew}
				/>
			))}
		</>
	);
}

export default NewCatalogTree;
