import { toggleMark } from 'prosemirror-commands';
import { Plugin } from 'prosemirror-state';
import sanitizeHtml from 'sanitize-html';
import { htmlToMarkdown } from '@repo/secoda-editor/importer';
import Extension from '../lib/Extension';
import isMarkdown from '../lib/isMarkdown';
import isUrl from '../lib/isUrl';
import selectionIsInCode from '../queries/isInCode';
import { isResourceUrl } from '../utils/urls';
import { LANGUAGES } from './Prism';

function sanitize(html: string): string {
	const cleanHTML = sanitizeHtml(html, {
		enforceHtmlBoundary: true,
		allowedTags: [
			'address',
			'article',
			'aside',
			'footer',
			'header',
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'h6',
			'hgroup',
			'main',
			'nav',
			'section',
			'blockquote',
			'dd',
			'div',
			'dl',
			'dt',
			'figcaption',
			'figure',
			'hr',
			'li',
			'main',
			'ol',
			'p',
			'pre',
			'ul',
			'a',
			'abbr',
			'bdi',
			'bdo',
			'br',
			'cite',
			'code',
			'data',
			'dfn',
			'em',
			'i',
			'img',
			'kbd',
			'mark',
			'q',
			'rb',
			'rp',
			'rt',
			'rtc',
			's',
			'samp',
			'small',
			'strong',
			'sub',
			'sup',
			'time',
			'u',
			'var',
			'wbr',
			'caption',
			'col',
			'colgroup',
			'table',
			'tbody',
			'td',
			'tfoot',
			'th',
			'thead',
			'tr',
		],
		allowedAttributes: {
			// default values since we're overriding the default
			'*': ['data-pm-slice'], // required for copying and pasting within the editor
			a: ['href', 'name', 'target'],
			img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
			// -- /default values

			// div[class] is used to identify some nodes, like Notice, Chartblock, Mention, etc
			div: ['class'],
			// id is used for the headings anchor link
			h1: ['id'],
			h2: ['id'],
			h3: ['id'],
			h4: ['id'],
			h5: ['id'],
			h6: ['id'],
		},
	});
	return cleanHTML;
}

function isDropboxPaper(html: string): boolean {
	// The best we have to detect if a paste is likely coming from Paper
	// In this case it's actually better to use the text version.
	return html?.includes('usually-unique-id');
}

function isContentFromGoogleDocs(html: string): boolean {
	// Check for a Google Docs URL in embedded images or links, which might be a part of the pasted content
	const hasGoogleDocsURL = /https:\/\/docs\.google\.com/.test(html);

	// Check for a Google Docs URL in embedded images or links, which might be a part of the pasted content
	const hasGoogleDocsId = /docs-internal/.test(html);

	// Check for specific Google Docs styles or classes that are usually added to the pasted content
	// Note: These classes and styles can change; you might need to update them based on current observations
	const hasGoogleDocsStyles = /class="[^"]*goog-[^"]+/.test(html);

	// Combine the checks to determine if the content is likely from Google Docs
	return hasGoogleDocsURL || hasGoogleDocsStyles || hasGoogleDocsId;
}

/**
 * Add support for additional syntax that users paste even though it isn't
 * supported by the markdown parser directly by massaging the text content.
 *
 * @param text The incoming pasted plain text
 */
function normalizePastedMarkdown(text: string): string {
	const CHECKBOX_REGEX = /^\s?(\[(X|\s|_|-)\]\s(.*)?)/gim;

	// Find checkboxes not contained in a list and wrap them in list items
	while (text.match(CHECKBOX_REGEX)) {
		text = text.replace(CHECKBOX_REGEX, (match) => `- ${match.trim()}`);
	}

	// Find multiple newlines and insert a hard break to ensure they are respected
	text = text.replace(/\n{3,}/g, '\n\n\\\n');

	// Find single newlines and insert an extra to ensure they are treated as paragraphs
	text = text.replace(/\b\n\b/g, '\n\n');

	return text;
}

export default class PasteHandler extends Extension {
	get name() {
		return 'markdown-paste';
	}

	get plugins() {
		return [
			new Plugin({
				props: {
					transformPastedHTML(html: string) {
						if (isDropboxPaper(html)) {
							// Fixes double paragraphs when pasting from Dropbox Paper.
							return html.replace(/<div><br><\/div>/gi, '<p></p>');
						}

						if (isContentFromGoogleDocs(html)) {
							return htmlToMarkdown(html);
						}

						// HTML is from prosemirror
						if (html?.includes('data-pm-slice')) {
							// remove heading actions div - will append extra "#" to the heading text content
							// this is a limitation with sanitize-html that it doesn't allow to customize the text content parsing of elements
							// it just uses element.innerText
							// the alternative is to write/fork our own sanitize-html library
							html = html.replace(
								/<span contenteditable="false" class="heading-actions ">.*?<\/span>/gm,
								''
							);
						}

						return sanitize(html);
					},
					handlePaste: (view, event: ClipboardEvent) => {
						if (view.props.editable && !view.props.editable(view.state)) {
							return false;
						}
						if (!event.clipboardData) {
							return false;
						}

						const text = event.clipboardData.getData('text/plain');
						const html = event.clipboardData.getData('text/html');
						const vscode = event.clipboardData.getData('vscode-editor-data');
						const { state, dispatch } = view;

						// First check if the clipboard contents can be parsed as a single
						// url, this is mainly for allowing pasted urls to become embeds
						if (isUrl(text)) {
							if (
								!this.editorState.disableExtensions?.includes(
									'resource_link'
								) &&
								isResourceUrl(text)
							) {
								// Insert a resource_link for internal links if `resource_link` extension is enabled
								const transaction = view.state.tr.replaceSelectionWith(
									state.schema.node('resource_link', { href: text })
								);
								view.dispatch(transaction);
								return true;
							}

							// Just paste the link mark directly onto the selected text
							if (!state.selection.empty) {
								toggleMark(this.editorState.schema.marks.link, {
									href: text,
								})(state, dispatch);
								return true;
							}

							// Go ahead and insert the link directly
							const transaction = view.state.tr
								.insertText(text, state.selection.from, state.selection.to)
								.addMark(
									state.selection.from,
									state.selection.to + text.length,
									state.schema.marks.link.create({
										href: text,
									})
								);
							view.dispatch(transaction);
							return true;
						}

						// If the users selection is currently in a code block then paste
						// as plain text, ignore all formatting and HTML content.
						if (selectionIsInCode(view.state)) {
							event.preventDefault();

							view.dispatch(view.state.tr.insertText(text));
							return true;
						}

						// Because VSCode is an especially popular editor that places metadata
						// on the clipboard, we can parse it to find out what kind of content
						// was pasted.
						const vscodeMeta = vscode ? JSON.parse(vscode) : undefined;
						const pasteCodeLanguage = vscodeMeta?.mode;

						if (pasteCodeLanguage && pasteCodeLanguage !== 'markdown') {
							event.preventDefault();

							// code_fence needs to be enabled for this replacement to work
							if (view.state.schema.nodes.code_fence) {
								view.dispatch(
									view.state.tr
										.replaceSelectionWith(
											view.state.schema.nodes.code_fence.create({
												language: Object.keys(LANGUAGES).includes(
													vscodeMeta.mode
												)
													? vscodeMeta.mode
													: null,
											})
										)
										.insertText(text)
								);
							}

							return true;
						}

						// If the HTML on the clipboard is from Prosemirror then the best
						// compatability is to just use the HTML parser, regardless of
						// whether it "looks" like Markdown, see: outline/outline#2416
						if (html?.includes('data-pm-slice')) {
							return false;
						}

						// If the text on the clipboard looks like Markdown OR there is no
						// html on the clipboard then try to parse content as Markdown
						if (
							(isMarkdown(text) && !isDropboxPaper(html)) ||
							html.length === 0 ||
							pasteCodeLanguage === 'markdown'
						) {
							event.preventDefault();

							const paste = this.editor.pasteParser.parse(
								normalizePastedMarkdown(text)
							);

							if (paste) {
								const slice = paste.slice(0);
								const transaction = view.state.tr.replaceSelection(slice);
								view.dispatch(transaction);
							}

							return true;
						}

						// Otherwise use the default HTML parser which will handle all paste
						// "from the web" events
						return false;
					},
				},
			}),
		];
	}
}
