export const FEATURE_FLAGS_DEFAULT_VALUES = {
	'monitoring-v2': false,
	'skip-default-billing-plan': false,
	'table-v2': true,
	'editor-ai-summary-block': false,
	'data-quality-score': false,
	'queries-v3': false,
	'region-selector': false,
	'integration-v3': false,
	'native-type-filters': false,
	'ai-filters': false,
	'data-quality-score-sla': false,
	'automation-lineage-metadata-propagation': false,
	'automation-history': false,
	'ai-personas': false,
} as const;
