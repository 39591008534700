import { EmptyState } from '../EmptyState';

function ErrorUnknown() {
	return (
		<EmptyState
			title="Unknown error."
			description="Please try refreshing your browser or contact customer support"
			includeGoBack
			size="lg"
		/>
	);
}

export default ErrorUnknown;
