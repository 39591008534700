import { Box, createStyles, Menu } from '@mantine/core';
import type { ButtonVariants } from '@repo/foundations';
import type { Icon } from '@tabler/icons-react';
import { useDebounceEffect } from 'ahooks';
import {
	find,
	forEach,
	isEmpty,
	isNil,
	map,
	size,
	startCase,
	uniq,
} from 'lodash-es';
import type React from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { fuzzySearchFilter } from '../../utils/fuse';
import { pluralize } from '../../utils/stringUtils';
import { EmptyState, type ButtonDetails } from '../EmptyState';
import type { ItemIconType } from '../ItemIcon';
import SelectorSearch from '../MultiSelector/SelectorSearch';
import type { ISingleSelectorItemProps } from './SingleSelectorItem';
import SingleSelectorItem from './SingleSelectorItem';
import SingleSelectorTarget from './SingleSelectorTarget';
import type { SelectablePropertyItem } from './types';

interface ISingleSelectorProps {
	width?: number;
	placeholder?: string;
	hideOnEmpty?: boolean;
	placeholderIcon?: Icon;
	variant?: ButtonVariants;
	initialSelected?: string | boolean;
	property: string;
	iconType: ItemIconType;
	options: SelectablePropertyItem[];
	isViewerUser: boolean;
	searchable: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: (value: any) => void;
	readOnly?: boolean;
	selectorItem?: React.FunctionComponent<ISingleSelectorItemProps>;
	itemSize?: number;
	itemsToRender?: number;
	onSearchTermChange?: (value: string) => void;
	target?: React.ReactElement;
	defaultOpened?: boolean;
	displayIcon?: boolean;
	supportSelected?: boolean;
	onClose?: () => void;
	emptyState?: React.ReactNode;
}

const useStyles = createStyles({
	menuDropdown: {
		minHeight: 'fit-content',
	},
});

function SingleSelector({
	defaultOpened = false,
	displayIcon = true,
	supportSelected = true,
	hideOnEmpty = false,
	iconType,
	initialSelected,
	isViewerUser,
	itemSize = 32,
	itemsToRender = 8,
	onChange,
	onClose,
	onSearchTermChange,
	options,
	placeholder,
	placeholderIcon,
	property,
	readOnly = false,
	searchable,
	selectorItem: SelectorItem = SingleSelectorItem,
	target,
	variant,
	width = 300,
	emptyState,
}: ISingleSelectorProps) {
	const [selected, setSelected] = useState(initialSelected);
	const [searchTerm, setSearchTerm] = useState('');
	const { classes } = useStyles();
	const [hasGroups, setHasGroups] = useState<boolean>(false);
	const [groups, setGroups] = useState<string[]>([]);

	useDebounceEffect(
		() => {
			onSearchTermChange?.(searchTerm);
		},
		[searchTerm],
		{
			wait: 500,
		}
	);

	useEffect(() => {
		setSelected(initialSelected);

		if (!isEmpty(options)) {
			// Show groups in the not selected list
			if ('group' in options[0]) {
				setHasGroups(true);
				setGroups(uniq(map(options, 'group')) as string[]);
			}
		}
	}, [initialSelected, setSelected, options]);

	// Memoized filtered not selected items grouped by groups based on the search term, inherited values, and user permissions
	const filteredGroups: { [key: string]: SelectablePropertyItem[] } =
		useMemo(() => {
			if (!hasGroups) {
				return {};
			}

			const newGroups: { [key: string]: SelectablePropertyItem[] } = {};

			forEach(options, (option) => {
				if (option.group) {
					if (!newGroups[option.group]) {
						newGroups[option.group] = [option];
					} else {
						newGroups[option.group].push(option);
					}
				}
			});

			return newGroups;
		}, [options, hasGroups]);

	const item = find(
		options,
		(option) => selected === option.value
	) as SelectablePropertyItem;

	const filteredOptions = useMemo(() => {
		if (isEmpty(searchTerm)) {
			return options;
		}
		return fuzzySearchFilter(searchTerm, options, ['label']).filter(
			(f) => !f.hidden
		);
	}, [searchTerm, options]);

	if (readOnly || isViewerUser) {
		return (
			<SingleSelectorTarget
				hideOnEmpty={hideOnEmpty}
				iconType={iconType}
				isViewerUser={isViewerUser}
				readOnly={readOnly || isNil(onChange)}
				selected={supportSelected ? item : undefined}
			/>
		);
	}

	const handleClearSearch = () => {
		setSearchTerm('');
	};

	const handleOnChange = (i: SelectablePropertyItem) => {
		onChange?.(i.value);
		setSelected(i.value);
	};

	const buttons: ButtonDetails[] = [
		{
			name: 'Clear search',
			action: handleClearSearch,
			isPrimary: false,
			size: 'sm',
		},
	];

	const itemContent = (_: number, element: SelectablePropertyItem) => (
		<SelectorItem
			isSelected={supportSelected && selected === element.value}
			iconType={iconType}
			item={element}
			isViewerUser={isViewerUser}
			onClick={handleOnChange}
			displayIcon={displayIcon}
		/>
	);

	const height =
		size(filteredOptions) > itemsToRender
			? 256
			: size(filteredOptions) * itemSize;

	return (
		<Menu
			withinPortal
			width={width}
			position="bottom-start"
			defaultOpened={defaultOpened}
			onClose={onClose}
			classNames={{
				dropdown: classes.menuDropdown,
			}}
		>
			<Menu.Target>
				{target || (
					<SingleSelectorTarget
						hideOnEmpty={hideOnEmpty}
						readOnly={readOnly || isNil(onChange)}
						placeholder={placeholder}
						placeholderIcon={placeholderIcon}
						variant={variant}
						selected={supportSelected ? item : undefined}
						iconType={iconType}
						isViewerUser={isViewerUser}
					/>
				)}
			</Menu.Target>
			<Menu.Dropdown>
				{searchable && (
					<SelectorSearch
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
					/>
				)}
				{searchable &&
					filteredOptions.length === 0 &&
					(emptyState || (
						<EmptyState
							iconName="search"
							title={`No ${pluralize(property)} found`}
							description="No resources or invalid search"
							buttons={buttons}
							includeGoBack={false}
							size="sm"
						/>
					))}
				{hasGroups &&
					!isViewerUser &&
					map(groups, (group) => (
						<Box key={group}>
							<Menu.Label>{startCase(group)}</Menu.Label>
							<Virtuoso
								data={filteredGroups[group]}
								totalCount={size(filteredGroups)}
								itemContent={itemContent}
								style={{
									height:
										size(filteredGroups[group]) > itemsToRender
											? 256
											: size(filteredGroups[group]) * itemSize,
								}}
							/>
						</Box>
					))}
				{!hasGroups && (
					<Virtuoso
						data={filteredOptions}
						totalCount={size(filteredOptions)}
						itemContent={itemContent}
						style={{
							height,
						}}
					/>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}

export default memo(SingleSelector);
