/* eslint-disable consistent-return */
/* eslint-disable func-style */
import type { EditorView } from 'prosemirror-view';
import { Mark, Node } from 'prosemirror-model';
import { EditorDictionary, ToastType } from '@repo/secoda-editor';

interface FindPlaceholderLinkResult {
	node: Node;
	pos: number;
}

function findPlaceholderLink(
	doc: Node,
	href: string
): FindPlaceholderLinkResult | undefined {
	let result: FindPlaceholderLinkResult | undefined;

	function findLinks(node: Node, pos: number = 0) {
		// Get text nodes
		if (node.type.name === 'text') {
			// Get marks for text nodes
			node.marks.forEach((mark: Mark) => {
				// Any of the marks links?
				if (mark.type.name === 'link') {
					// Any of the links to other docs?
					if (mark.attrs.href === href) {
						result = { node, pos };
						if (result) return false;
					}
				}
			});
		}

		if (!node.content.size) {
			return;
		}

		node.descendants(findLinks);
	}

	findLinks(doc);
	return result;
}

const createAndInsertLink = async function (
	view: EditorView,
	title: string,
	href: string,
	options: {
		dictionary: EditorDictionary;
		onCreateLink: (title: string) => Promise<string>;
		onShowToast: (message: string, code: string) => void;
	}
) {
	const { dispatch, state } = view;
	const { onCreateLink, onShowToast } = options;

	try {
		const url = await onCreateLink(title);
		const result = findPlaceholderLink(view.state.doc, href);

		if (!result) return;

		dispatch(
			view.state.tr
				.removeMark(
					result.pos,
					result.pos + result.node.nodeSize,
					state.schema.marks.link
				)
				.addMark(
					result.pos,
					result.pos + result.node.nodeSize,
					state.schema.marks.link.create({ href: url })
				)
		);
	} catch (err) {
		const result = findPlaceholderLink(view.state.doc, href);
		if (!result) return;

		dispatch(
			view.state.tr.removeMark(
				result.pos,
				result.pos + result.node.nodeSize,
				state.schema.marks.link
			)
		);

		// Let the user know
		if (onShowToast) {
			onShowToast(options.dictionary.createLinkError, ToastType.Error);
		}
	}
};

export default createAndInsertLink;
