import type { EditorView } from 'prosemirror-view';

export type Options = {
	id: string;
};

const insertChartBlock = (view: EditorView, pos: number, options: Options) => {
	const { id } = options;
	const { schema, tr } = view.state;

	const $pos = tr.doc.resolve(pos);
	view.dispatch(
		view.state.tr.replaceWith(
			$pos.pos,
			$pos.pos + ($pos.nodeAfter?.nodeSize || 0),
			schema.nodes.chart_block.create({
				id,
			})
		)
	);
};

export default insertChartBlock;
