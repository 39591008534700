import { Menu, useMantineTheme } from '@mantine/core';
import { useContext } from 'react';
import type { FilterMenuItem } from '../FilterCarousel.constants';
import { SearchFilterStoreContext } from '../store';

interface IFilterOptionItemProps {
	item: FilterMenuItem;
}

function FilterOptionItem({ item }: IFilterOptionItemProps) {
	const theme = useMantineTheme();
	const searchFilterStore = useContext(SearchFilterStoreContext);

	const Icon = item.icon;

	const handleSelectFilterOption = () => {
		searchFilterStore.setFocusedFilter(item.value);
	};

	return (
		<Menu.Item
			data-testid="search-page=filter-option-item"
			onClick={handleSelectFilterOption}
			icon={
				<Icon size={20} color={theme.other.getColor('icon/primary/default')} />
			}
		>
			{item.label}
		</Menu.Item>
	);
}

export default FilterOptionItem;
