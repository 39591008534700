import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { omit } from 'lodash-es';
import { joinFilters } from '../../../pages/AnalyticsPage/utils/utils';
import { apiClient, getEndpoints } from '../../common';
import { baseQueryHooksFactory, createQueryKeys } from '../../factories';
import type { IMetricWidget } from '../../types';
import type {
	IIntegrationMetric,
	IMetricWidgetSample,
} from '../../types/models';

export const INTEGRATION_METRIC_MEASUREMENT_NAMESPACE = [
	'integration_analytics',
	'measurements',
];

export const INTEGRATION_METRIC_NAMESPACE = ['integration_analytics', 'metric'];

export const genEmptyChartData = (daysAgo: number, valueNames: string[]) => {
	const datapoints: Record<string, string | number>[] = [];
	for (let i = daysAgo; i >= 0; i -= 1) {
		const date = new Date();
		date.setDate(date.getDate() - i);

		const datapoint: Record<string, string | number> = {
			timestamp: new Date().toISOString(),
		};
		valueNames.forEach((valueName) => {
			datapoint[valueName] = 0;
		});
		datapoints.push(datapoint);
	}
	return datapoints;
};

export const integrationMetricKeyFactory = createQueryKeys(
	INTEGRATION_METRIC_NAMESPACE
);

function addPrefixToKeys<T>(
	prefix: string,
	obj: Record<string, T>
): Record<string, T> {
	const newObj: Record<string, T> = {};

	Object.keys(obj).forEach((key) => {
		newObj[prefix + key] = obj[key];
	});

	return newObj;
}

export const { useIntegrationMetric, useIntegrationMetricList } =
	baseQueryHooksFactory<IIntegrationMetric, 'integrationMetric'>(
		'integrationMetric',
		integrationMetricKeyFactory
	);

export type IIntegrationMetricMeasurement = Record<string, string | number>[];

export function useIntegrationMetricMeasurement(
	metricID: string,
	filter: Record<string, string>,
	value: Record<string, string>,
	lookbackDays: number = 7
) {
	const queryKey = [
		...INTEGRATION_METRIC_MEASUREMENT_NAMESPACE,
		metricID,
		filter,
		value,
		lookbackDays,
	];
	const queryFn = async () => {
		const url = getEndpoints(INTEGRATION_METRIC_MEASUREMENT_NAMESPACE).byPath([
			metricID,
		]);
		const today = dayjs();
		const since = today.subtract(lookbackDays, 'day');
		const { data } = await apiClient.get<IIntegrationMetricMeasurement>(url, {
			params: {
				...addPrefixToKeys('filter__', filter),
				...addPrefixToKeys('value__', value),
				since: since.format('YYYY-MM-DD'),
				time_granularity: 'day',
			},
		});
		return data;
	};

	return useQuery(queryKey, queryFn);
}

export function useMeasurementFromWidget(
	metricWidget: IMetricWidget | IMetricWidgetSample
) {
	const filters = joinFilters(metricWidget);
	const filterWithoutLookback = omit(filters, 'lookback');

	const lookback = filters.lookback || 'weekly';
	const lookbackDays = lookback === 'weekly' ? 7 : 30;
	const value = metricWidget.metric_metadata.value || {};

	if (!metricWidget.metric_metadata.metric_id) {
		throw new Error('Metric ID is required');
	}

	return useIntegrationMetricMeasurement(
		metricWidget.metric_metadata.metric_id,
		filterWithoutLookback,
		value,
		lookbackDays
	);
}
