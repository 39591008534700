import { createStyles, Stack, TextInput } from '@mantine/core';
import { useDebounceFn } from 'ahooks';
import { observer } from 'mobx-react-lite';
import {
	DISABLE_EXTENSIONS_FOR_CREATION_MODAL_EDITOR,
	RichEditor,
} from '../../RichEditor';
import { entityModalStore } from '../store';
import { getDocumentationEditorPlaceholder } from './Header.helpers';

const useStyles = createStyles((theme) => ({
	center: {
		backgroundColor: theme.colors.gray[2],
	},
	title: {
		height: theme.spacing.sm,
		padding: 0,
	},
	input: {
		color: theme.other.getColor('text/primary/default'),
		fontSize: theme.fontSizes.xl,
		fontWeight: theme.other.typography.weight.semibold,
		lineHeight: theme.spacing.md,
		padding: `0 ${theme.spacing.xs}`,
		'&:focus': {
			outline: 'none',
			borderColor: 'transparent',
			boxShadow: 'none',
		},
	},
	content: {
		padding: `${theme.spacing.md} ${theme.spacing.xs}`,
	},
}));

function EntityModalHeader() {
	const { classes, theme } = useStyles();

	const findRelatedQuestions = useDebounceFn(
		entityModalStore.findRelatedQuestions,
		{ wait: 300 }
	);

	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		entityModalStore.setTitle(event.currentTarget.value);
		findRelatedQuestions.run();
	};

	const handleDescriptionChange = (description?: string) => {
		entityModalStore.setDescription(description ?? '');
	};

	const placeholder = getDocumentationEditorPlaceholder(entityModalStore.type);

	return (
		// We put the `key` prop here to force re-rendering of the component on
		// template change.
		<Stack
			key={entityModalStore.template?.id}
			px={theme.spacing.xl}
			py={theme.spacing.md}
			spacing="sm"
		>
			<TextInput
				className={classes.title}
				classNames={{
					input: classes.input,
				}}
				placeholder="Untitled"
				size="xl"
				variant="unstyled"
				// Do not use `value={entityModalStore.title}` directly here
				// because it's an observed value and will cause re-renders.
				defaultValue={
					entityModalStore.template?.title ?? entityModalStore.title ?? ''
				}
				onChange={handleTitleChange}
				data-autofocus
			/>
			<RichEditor
				key={entityModalStore.richEditorKey}
				className={classes.content}
				multiplayer={false}
				readOnly={false}
				disableExtensions={DISABLE_EXTENSIONS_FOR_CREATION_MODAL_EDITOR}
				// Do not use `value={entityModalStore.description}` directly here
				// because it's an observed value and will cause re-renders.
				initialValue={
					entityModalStore.template?.definition ??
					entityModalStore.description ??
					''
				}
				placeholder={placeholder}
				onChangeCallback={handleDescriptionChange}
				limited={entityModalStore.type === 'metric' ? true : false}
			/>
		</Stack>
	);
}

export default observer(EntityModalHeader);
