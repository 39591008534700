import {
	MantineNumberSize,
	Skeleton,
	SkeletonProps,
	useMantineTheme,
} from '@mantine/core';
import { TextProps } from '../Text/Text';
import { getTextWidth } from './util';
import { typography } from '@repo/theme/primitives';

export type TextSkeletonProps = SkeletonProps &
	Pick<TextProps, 'size'> & {
		text?: string;
		height?: MantineNumberSize;
	};

export function TextSkeleton({
	text,
	size,
	height,
	...rest
}: TextSkeletonProps) {
	const theme = useMantineTheme();
	const isInline = !!text;

	const actualHeight = height ?? typography.lineHeight.text[size ?? 'md'];

	let width = rest.width ?? '100%';

	if (isInline) {
		width = `${getTextWidth(text, {
			fontFamily: rest.ff ?? theme.fontFamily,
			fontWeight: rest.fw ?? 'normal',
			fontSize: typography.text[size ?? 'md'],
		})}px`;
	}

	return (
		<Skeleton
			style={{ width, maxWidth: '100%' }}
			height={actualHeight}
			{...rest}
		/>
	);
}
