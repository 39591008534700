import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';

export type IntegrationColumnFields =
	| 'name'
	| 'integration_type'
	| 'teams'
	// | 'integration_status'
	| 'scheduled_extractions_last_run'
	| 'scheduled_extractions_next_run';

export const IntegrationColumns: ColumnDefinition = {
	name: {
		field: 'name',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
		flex: 1,
		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		checkboxSelection: true,
	},
	type: {
		field: 'integration_type',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
		flex: 1,
	},
	teams: {
		field: 'teams',
		editable: true,
		sortable: false,
	},
	last_run: {
		field: 'scheduled_extractions_last_run',
	},
	next_run_at: {
		field: 'scheduled_extractions_next_run',
	},
};
