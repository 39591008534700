import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../network';
import { resourceRelationsQueryKeyFactory } from '.';

export interface IDeleteResourceRelationArgs {
	fromEntityId: string;
	toEntityId: string;
	onSuccess: () => void;
	onError: () => void;
}

export function useDeleteResourceRelation({
	fromEntityId,
	toEntityId,
	onSuccess,
	onError,
}: IDeleteResourceRelationArgs) {
	const queryKey = resourceRelationsQueryKeyFactory.byArgs('delete_relation');

	const mutationFn = async () => {
		const { data } = await axios.delete(`${api()}lineage/relations`, {
			data: {
				from_entity: fromEntityId,
				to_entity: toEntityId,
			},
		});
		return data;
	};

	return useMutation(queryKey, mutationFn, {
		onSuccess,
		onError,
	});
}
