export type UUID = string;

export enum UserRole {
	ADMIN = 'admin',
	EDITOR = 'editor',
	VIEWER = 'viewer',
	GUEST = 'guest',
}

export enum EntityType {
	metric = 'metric',
	cluster = 'cluster',
	database = 'database',
	schema = 'schema',
	table = 'table',
	column = 'column',
	user = 'user',
	dashboard_group = 'dashboard_group',
	dashboard = 'dashboard',
	chart = 'chart',
	analysis = 'analysis',
	query = 'query',
	lineage = 'lineage',
	job = 'job',
	job_group = 'job_group',
	category = 'category',
	run = 'run',
	collection = 'collection',
	dictionary_term = 'dictionary_term',
	document = 'document',
	event = 'event',
	event_property = 'event_property',
	workspace = 'workspace',
	question = 'question',
	request = 'question',
	reply = 'question_reply',
	question_reply = 'question_reply',
	test = 'test',
	test_run = 'test_run',
	user_group = 'user_group',
	integration = 'integration',
	template = 'template',
	all = 'all',
}

export enum LookerEntityType {
	view = 'view',
	explore = 'explore',
}

export type QueryResults = Array<Array<string | number | null>>;
