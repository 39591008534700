import { wrappingInputRule } from 'prosemirror-inputrules';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import { ParseSpec } from 'prosemirror-markdown';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import toggleList from '../commands/toggleList';
import Node, { NodeOptions } from './Node';

export default class BulletList extends Node {
	get name() {
		return 'bullet_list';
	}

	get schema(): NodeSpec {
		return {
			content: 'list_item+',
			group: 'block',
			parseDOM: [{ tag: 'ul' }],
			toDOM: () => ['ul', 0],
		};
	}

	commands({ type, schema }: NodeOptions) {
		return () => toggleList(type, schema.nodes.list_item);
	}

	keys({ type, schema }: NodeOptions) {
		return {
			'Shift-Ctrl-8': toggleList(type, schema.nodes.list_item),
		};
	}

	inputRules({ type }: NodeOptions) {
		return [wrappingInputRule(/^\s*([-+*])\s$/, type)];
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.renderList(node, '  ', () => `${node.attrs.bullet || '*'} `);
	}

	parseMarkdown(): ParseSpec {
		return { block: 'bullet_list' };
	}
}
