import { reverse } from 'lodash-es';
import type {
	IApiListResponse,
	INestedBreadcrumbs,
	ISecodaEntity,
} from '../../api';
import { useIntegrationList } from '../../api';
import { useNestedBreadcrumbsList } from '../../api/hooks/nestedBreadcrumbs';

import type { SecodaEntity } from '../../lib/models';
import { getBreadCrumbForEntity } from '../../utils/breadcrumb';
import { useApiListTeams } from '../../api/codegen/apiComponents';

interface IBreadcrumb {
	title: string;
	href: string;
}

/**
 * Generates nestedBreadcrumbs for the given SecodaEntity.
 *
 * @param entity - The SecodaEntity for which to generate nestedBreadcrumbs.
 * @returns An array of breadcrumb objects.
 */
export function useBreadcrumbs(
	entity: SecodaEntity | ISecodaEntity
): IBreadcrumb[] {
	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			select: (res) => res.results,
		}
	);

	const { data: integrations } = useIntegrationList({
		options: {
			select: (res) => res.results,
		},
	});

	const { data: nestedBreadcrumbs } = useNestedBreadcrumbsList({
		filters: {
			id: entity.id,
		},
		options: {
			select: (res: IApiListResponse<INestedBreadcrumbs>) =>
				reverse(res.results || []),
		},
	});

	return getBreadCrumbForEntity(entity, teams, nestedBreadcrumbs, integrations);
}
