import { Box, createStyles, Group, Stack } from '@mantine/core';
import { useNavigate } from 'react-router';
import { useHover } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import { ReactNode } from 'react';
import type { ISecodaEntity } from '../../../../api';
import { SecodaEntityIcon } from '../../../SecodaEntity';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { ResourceLinkType } from '../../../Link/useResourceLink';
import { ResourcePreview } from '../../../ResourcePreview';
import { getSummaryAndBreadCrumbs } from '../../../../utils/breadcrumb';

const useStyles = createStyles((theme) => ({
	itemWrapper: {
		padding: theme.spacing.xs,
		marginLeft: `-${theme.spacing.sm}`,
		marginRight: `-${theme.spacing.xs}`,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		cursor: 'pointer',
	},
	iconWrapper: {
		paddingTop: theme.other.space[1],
	},
}));
interface IRelatedEntityProps {
	entity: ISecodaEntity;
	hoverButton?: ReactNode;
}

export function EntityDisplay({ entity, hoverButton }: IRelatedEntityProps) {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const { ref, hovered } = useHover<HTMLDivElement>();

	const handleClick = () => {
		navigate(
			buildResourceUrl({
				id: entity.id,
				entity_type: entity.entity_type,
			})
		);
	};

	const icon = (
		<SecodaEntityIcon
			inverseIconColor
			entity={{
				id: entity.id,
				entity_type: entity.entity_type,
				integration: entity.integration as string,
				title: entity.title,
			}}
			inline
			// NOTE: Keep this consistent with Icon.tsx value
			size={20}
		/>
	);

	const breadcrumbs = getSummaryAndBreadCrumbs(
		entity.native_type || entity.entity_type,
		entity.search_metadata
	);

	return (
		<ResourcePreview
			data={entity}
			id={entity.id}
			resourceType={ResourceLinkType.Entity}
			isLoading={false}
			hasError={false}
			position="left-start"
			width={300}
			withinPortal
		>
			<Group
				ref={ref}
				spacing="xs"
				position="apart"
				noWrap
				className={classes.itemWrapper}
				onClick={handleClick}
			>
				<Group spacing="xs" align="flex-start" noWrap maw="80%">
					<Box className={classes.iconWrapper}>{icon}</Box>
					<Stack spacing={0}>
						<Text size="sm" lineClamp={1}>
							{entity.title || 'Untitled'}
						</Text>
						<Text size="xs" color="text/secondary/default" lineClamp={1}>
							{breadcrumbs}
						</Text>
					</Stack>
				</Group>
				{hovered && hoverButton}
			</Group>
		</ResourcePreview>
	);
}
