import { Text, Group, createStyles } from '@mantine/core';
import { map } from 'lodash-es';

const useStyles = createStyles((theme) => ({
	kbd: {
		display: 'flex',
		height: '20px',
		padding: `${theme.spacing['4xs']} ${theme.spacing['3xs']}`,
		flexDirection: 'column',
		justifyContent: 'center',
		gap: theme.spacing.xs,
		borderRadius: theme.radius.xs,
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.xs,
	},
}));

interface HotKeyProps {
	hotkey: string;
}

function HotKey({ hotkey }: HotKeyProps) {
	const { classes } = useStyles();
	return (
		<Group spacing={4}>
			{map(hotkey.toUpperCase().replace('/', ''), (key) => (
				<Text key={key} className={classes.kbd}>
					{key}
				</Text>
			))}
		</Group>
	);
}

export default HotKey;
