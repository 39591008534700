import {
	Checkbox,
	Group,
	Radio,
	createStyles,
	getStylesRef,
} from '@mantine/core';
import { ListBox } from '@repo/foundations';
import { FilterType } from './types';
import type { FilterItem } from './types';

const useStyles = createStyles(
	(theme, { selected }: { selected: boolean }) => ({
		menuItem: {
			[`&[data-hovered] .${getStylesRef('checkbox')}`]: {
				visibility: 'visible',
			},
		},
		checkbox: {
			ref: getStylesRef('checkbox'),

			visibility: selected ? 'visible' : 'hidden',
		},
	})
);

interface FilterMenuItemProps {
	filterType?: FilterType;
	item: FilterItem;
	renderMenuItem?: (item: FilterItem) => React.ReactNode;
	onClick: (close: boolean) => void;
	selected?: boolean;
	getProps: (
		userProps: Omit<React.HTMLProps<HTMLElement>, 'ref'>
	) => Omit<React.HTMLProps<HTMLElement>, 'ref'>;
}

export function FilterMenuItem({
	filterType,
	item,
	renderMenuItem,
	onClick,
	selected = false,
	getProps,
}: FilterMenuItemProps) {
	const { classes } = useStyles({ selected });

	return (
		<ListBox.Item
			{...getProps({
				onClick: () => onClick(true),
				className: classes.menuItem,
			})}
		>
			<Group
				data-testid={`filter-button-${item.label.replace(/\s/g, '-').toLowerCase()}`}
				spacing="2xs"
			>
				{filterType === FilterType.Multiple && (
					<Checkbox
						color="dark"
						checked={selected}
						onClick={(event) => event.stopPropagation()}
						onChange={() => onClick(false)}
						className={classes.checkbox}
					/>
				)}
				{filterType === FilterType.Single && (
					<Radio
						color="dark"
						checked={selected}
						onClick={(event) => event.stopPropagation()}
						onChange={() => onClick(false)}
						className={classes.checkbox}
					/>
				)}
				{item.icon}
				{renderMenuItem ? renderMenuItem(item) : item.label}
			</Group>
		</ListBox.Item>
	);
}
