import { makeAutoObservable } from 'mobx';
import type { QueryKey } from '@tanstack/react-query';
import { EntityType } from '../../lib/types';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import {
	fetchSecodaEntity,
	updateSecodaEntity,
} from '../../api/hooks/secodaEntity/fetchSecodaEntities';
import { ISecodaEntity, queryClient } from '../../api';
import { updateTableRow } from '../CatalogView/events';

class EntityDrawerStore {
	// @ts-expect-error TS(2564): Property 'entity' has no initializer and is not de... Remove this comment to see the full error message
	entity: ISecodaEntity;

	isOpen = false;

	loading = false;

	activeQueryKey: QueryKey | undefined;

	isError = false;

	isEditable = false;

	title = '';

	description = '';

	icon = '🗂';

	constructor() {
		makeAutoObservable(this);
	}

	setLoading() {
		this.loading = true;
	}

	resetLoading() {
		this.loading = false;
	}

	setIsError = (value: boolean) => {
		this.isError = value;
	};

	openEntityDrawerById = async (
		isEditable: boolean,
		id: string,
		type: EntityType,
		activeQueryKey?: QueryKey,
		params: unknown = undefined
	) => {
		this.setLoading();
		this.setIsOpen(true);

		this.isEditable = isEditable;
		this.activeQueryKey = activeQueryKey;

		await fetchSecodaEntity(id, params)
			.then((entity) => {
				this.entity = entity;

				this.title = entity.title;
				this.description = entity.description || '';
				if (entity.icon) {
					this.icon = entity.icon;
				}

				this.resetLoading();
			})
			.catch(() => {
				this.resetLoading();
				this.setIsError(true);
			});
	};

	openEntityDrawerWithError = () => {
		this.setIsError(true);
		this.setIsOpen(true);
	};

	updateEntity = async (
		key: string,
		value: DjangoValueType,
		saveRemotely = true
	) => {
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		this.entity[key] = value;
		if (saveRemotely) {
			await updateSecodaEntity({
				data: {
					id: this.entity.id,
					[key]: value,
				},
			});
		}
	};

	updateByKey =
		(key: string) =>
		async (value: DjangoValueType, saveRemotely = true) => {
			await this.updateEntity(key, value, saveRemotely);
		};

	setTitle = (value: string) => {
		this.title = value;
	};

	setDescription = (value: string) => {
		this.description = value;
	};

	setIcon = (value: string) => {
		this.icon = value;
	};

	setIsOpen = (value: boolean) => {
		this.isOpen = value;
	};

	closeEntityDrawer = () => {
		this.setIsOpen(false);
		this.setIsError(false);
		this.resetLoading();

		// table v1
		updateTableRow(this.entity);

		// table v2
		if (this.activeQueryKey) {
			queryClient.invalidateQueries(this.activeQueryKey);
		}
	};
}

export default new EntityDrawerStore();
