import { createStyles, PopoverBaseProps, Text, Box } from '@mantine/core';
import { useAuthUser, useIntegrationPrefetched } from '../../api';
import RichTooltip from './RichTooltip';

export interface DescriptionEditorTooltipProps {
	integrationId: string;
	initialValue?: string;
	showTooltip: boolean;
	calculatedPos: string;
	persistDescription: boolean;
	onClick: (e: React.MouseEvent) => void;
}

const useStyles = createStyles((theme) => ({
	editorWrapper: {
		width: '100%',
		position: 'relative',
		paddingRight: theme.spacing.sm,
		marginRight: theme.spacing.sm,
	},
}));

function DescriptionEditorTooltip({
	integrationId,
	initialValue,
	showTooltip,
	calculatedPos,
	onClick,
	persistDescription,
}: DescriptionEditorTooltipProps) {
	const { data: integration } = useIntegrationPrefetched({ id: integrationId });
	const { isAdminUser } = useAuthUser();
	const { classes } = useStyles();

	return persistDescription ? (
		<RichTooltip
			title={
				initialValue
					? `Description comes from ${integration?.name}`
					: `No description set in ${integration?.name}`
			}
			body={
				isAdminUser && (
					<Text size="sm" color={'text/secondary/default'}>
						To maintain in Secoda, go to integration settings.
					</Text>
				)
			}
			linkLabel={isAdminUser ? `${integration?.name} settings` : undefined}
			linkUrl={
				isAdminUser ? `/integrations/${integration?.id}/preferences` : undefined
			}
			hidden={!integrationId || !showTooltip}
			position={calculatedPos as PopoverBaseProps['position']}
		>
			<Box className={classes.editorWrapper} onClick={onClick}>
				<Text>{initialValue ? initialValue : 'No description'}</Text>
			</Box>
		</RichTooltip>
	) : (
		<Box className={classes.editorWrapper} onClick={onClick}>
			<Text>{initialValue ? initialValue : 'No description'}</Text>
		</Box>
	);
}

export default DescriptionEditorTooltip;
