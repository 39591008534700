import { Skeleton, Stack } from '@mantine/core';

import { IconButton, TextSkeleton } from '@repo/foundations';
import { type SecodaEntity } from '../../../../lib/models';
import CollapsableStack from '../../../CollapsableStack';
import { ISecodaEntity, useAuthUser } from '../../../../api';
import { useFreshness } from '../../../Freshness/useFreshness';
import { StaticProperty } from '../../EntityPropertySidebar';
import MonitorStatusBadge from '../../../../pages/MonitorPage/components/MonitorStatusBadge';
import MeasurementsChart from '../../../../pages/MonitorPage/components/MeasurementsChart';
import { isViewerOfEntity } from '../../../../utils/authorization/roles';
import AddMonitorButton from '../../../AddMonitor/AddMonitorButton';
import { EmptyState } from '../../../EmptyState';

interface FreshnessStackProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function FreshnessStack({ entity }: FreshnessStackProps) {
	const { data, monitor, loading, error } = useFreshness({
		entity,
		timeRange: '30',
	});

	const { user } = useAuthUser();
	const viewerOfEntity = isViewerOfEntity(user, entity);
	const editorOfEntity = !viewerOfEntity;

	return (
		<CollapsableStack
			groupName="Freshness"
			actions={
				editorOfEntity &&
				!monitor &&
				!loading &&
				!error && (
					<AddMonitorButton
						initialIntegration={entity.integration}
						initialMetricType="freshness"
						initialSearchTerm={entity.title}
						initialTable={entity.id}
						customTarget={
							<IconButton
								iconName="plus"
								variant="tertiary"
								tooltip="Add freshness monitor"
							/>
						}
					/>
				)
			}
		>
			{monitor && !loading && !error && (
				<Stack spacing="md">
					<StaticProperty
						label="Status"
						custom={<MonitorStatusBadge monitor={monitor ?? undefined} />}
					/>
					<MeasurementsChart
						values={data ?? []}
						learningMode={monitor?.status === 'learning'}
						withBorder={false}
					/>
				</Stack>
			)}
			{!monitor && !loading && !error && (
				<EmptyState
					size="sm"
					title="No freshness monitor found"
					description=""
					includeGoBack={false}
				/>
			)}
			{loading && (
				<Stack spacing="md">
					<StaticProperty label="Status" custom={<TextSkeleton />} />
					<Skeleton w="100%" h={200} />
				</Stack>
			)}
			{error && (
				<EmptyState
					size="sm"
					title="Error loading freshness monitor"
					description=""
					includeGoBack={false}
				/>
			)}
		</CollapsableStack>
	);
}
