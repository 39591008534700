import { map, truncate } from 'lodash-es';
import type { FilterOption } from '../../pages/SearchPage/FilterCarousel/FilterCarousel.constants';

export const formatTargetDisplay = (
	label: string,
	isInclude: boolean,
	selectedOptions: FilterOption[]
) => {
	if (selectedOptions.length === 0) {
		return label;
	}
	const targetDisplay = `${!isInclude ? 'Not: ' : ''} ${map(
		selectedOptions,
		'label'
	).join(', ')}`;
	return truncate(targetDisplay, { length: 24 });
};
