import type { SelectablePropertyItem } from '../../../SingleSelector/types';

export const SELECTABLE_PROPERTY_OPTIONS: Record<
	string,
	SelectablePropertyItem[]
> = {
	published: [
		{
			label: 'Published',
			value: true,
			color: 'fill/success-secondary/default',
			fill: 'fill/success-secondary/default',
			size: 8,
		},
		{
			label: 'Draft',
			value: false,
			color: 'fill/secondary/default',
			fill: 'fill/secondary/default',
			size: 8,
		},
	],
	pii: [
		{
			label: 'PII',
			value: true,
			iconName: 'shieldLockFilled',
			color: 'icon/caution/default',
		},
		{
			label: 'Not PII',
			value: false,
			textColor: 'text/secondary/default',
			iconName: 'shieldLockUnfilled',
			color: 'icon/secondary/default',
			shouldHideIcon: true,
		},
	],
	verified: [
		{
			label: 'Verified',
			value: true,
			iconName: 'verified',
			color: 'fill/emphasis/default',
		},
		{
			label: 'Not verified',
			value: false,
			textColor: 'text/secondary/default',
			color: 'icon/secondary/default',
			iconName: 'verifiedUnfilled',
			shouldHideIcon: true,
		},
	],
	priority: [
		{
			label: 'None',
			value: 'NONE',
			iconName: 'priorityNone',
			color: 'icon/secondary/default',
		},
		{
			label: 'Low',
			value: 'LOW',
			iconName: 'priorityLow',
			color: 'icon/secondary/default',
		},
		{
			label: 'Medium',
			value: 'MEDIUM',
			iconName: 'priorityMedium',
			color: 'icon/secondary/default',
		},
		{
			label: 'High',
			value: 'HIGH',
			iconName: 'priorityHigh',
			color: 'icon/secondary/default',
		},
	],
	status: [
		{
			label: 'Answered',
			value: 'ANSWERED',
			color: 'fill/success-secondary/default',
			textColor: 'text/success/default',
		},
		{
			label: 'In progress',
			value: 'IN_PROGRESS',
			color: 'fill/info-secondary/default',
			textColor: 'text/info/default',
		},
		{
			label: 'In review',
			value: 'IN_REVIEW',
			hidden: true,
			color: 'fill/warning-secondary/default',
			textColor: 'text/warning/default',
		},
		{
			label: 'Unanswered',
			value: 'UNANSWERED',
			color: 'fill/caution-secondary/default',
			textColor: 'text/caution/default',
		},
		{
			label: 'Cancelled',
			value: 'CANCELLED',
			color: 'fill/transparent-secondary/default',
			textColor: 'text/primary/default',
		},
	],
};
