import { setBlockType } from 'prosemirror-commands';
import type { Node as ProsemirrorNode, NodeSpec } from 'prosemirror-model';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import Node, { NodeOptions } from './Node';

export default class Paragraph extends Node {
	get name() {
		return 'paragraph';
	}

	get schema(): NodeSpec {
		return {
			content: 'inline*',
			group: 'block',
			selectable: false,
			parseDOM: [{ tag: 'p' }],
			toDOM: () => ['p', 0],
		};
	}

	keys({ type }: NodeOptions) {
		return {
			'Shift-Ctrl-0': setBlockType(type),
		};
	}

	commands({ type }: NodeOptions) {
		return () => setBlockType(type);
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		// Render empty paragraphs as hard breaks to ensure that newlines are
		// persisted between reloads (this breaks from markdown tradition)
		if (
			node.textContent.trim() === '' &&
			node.childCount === 0 &&
			!state.inTable
		) {
			state.write('\\\n');
		} else {
			state.renderInline(node);
			state.closeBlock(node);
		}
	}

	parseMarkdown() {
		return { block: 'paragraph' };
	}
}
