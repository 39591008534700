import { Skeleton, Stack, useMantineTheme } from '@mantine/core';

import { Text } from '@repo/foundations';
import { type SecodaEntity } from '../../../../lib/models';
import CollapsableStack from '../../../CollapsableStack';
import { isViewerOfEntity } from '../../../../utils/authorization/roles';
import { ISecodaEntity, useAuthUser } from '../../../../api';
import { useResourceRelationList } from '../../../../api/hooks/relations';
import { RelatedResourceCreateMenu } from './RelatedResourceCreateMenu';
import { RelatedEntity } from './RelatedEntity';

interface IRelatedResourceSelectorProps {
	entity: SecodaEntity | ISecodaEntity;
	isVisible: boolean;
}

export function RelatedResourceStack({
	isVisible,
	entity,
}: IRelatedResourceSelectorProps) {
	const theme = useMantineTheme();

	const { user } = useAuthUser();
	const viewerOfEntity = isViewerOfEntity(user, entity);
	const editorOfEntity = !viewerOfEntity;

	const {
		data: entities,
		isLoading,
		isError,
	} = useResourceRelationList({
		entityId: entity.id,
		options: {
			select: (data) => data.results,
		},
	});

	if (!isVisible || isError) {
		return null;
	}

	return (
		<CollapsableStack
			groupName="Related"
			collapsedText={String(
				entities && entities.length > 0 ? entities.length : ''
			)}
			actions={
				editorOfEntity && (
					<RelatedResourceCreateMenu
						entity={entity}
						relatedEntityIds={entities?.map((r) => r.id) || []}
					/>
				)
			}
		>
			{isLoading && <Skeleton h={theme.other.space[10]} />}
			{!isLoading && entities?.length === 0 && (
				<Text size="sm" color="text/secondary/default">
					Add related resources.
				</Text>
			)}
			<Stack spacing="xs">
				{entities?.map((r) => (
					<RelatedEntity
						key={r.id}
						entityId={entity.id}
						relatedEntity={r}
						isEditorOfEntity={editorOfEntity}
					/>
				))}
			</Stack>
		</CollapsableStack>
	);
}
