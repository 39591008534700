import { DEFAULT_THEME } from '@mantine/styles';

/**
 * Ensures that a number is positive.
 * @param {number} number - The input number to check.
 * @returns {number} - The positive number or 0 if input is not provided or not positive.
 */
export function ensurePositive(number: number | undefined) {
	if (number === undefined) {
		return 0;
	}

	return number > 0 ? number : 0;
}

/**
 * Checks if a value is equivalent to the boolean value `true`.
 * @param {unknown} value - The value to check.
 * @returns {boolean} - Returns `true` if the value is equivalent to `true`, `false` otherwise.
 */
export function isTrue(value: unknown): boolean {
	if (typeof value === 'string') {
		return value.toLowerCase() === 'true';
	}
	if (typeof value === 'boolean') {
		return value;
	}
	return false;
}

/**
 * Checks if a string is a valid JSON.
 * @param {string} str - The string to check.
 * @returns {boolean} - Returns `true` if the string is a valid JSON, `false` otherwise.
 */
export function isJson(str?: string | null): boolean {
	if (!str) {
		return false;
	}
	try {
		const o = JSON.parse(str);
		if (o && typeof o === 'object') {
			return true;
		}
	} catch (e) {
		return false;
	}
	return false;
}

/**
 * Generates the SHA-1 hash of a string message.
 * @param {string} message - The message to hash.
 * @returns {Promise<string>} - A promise that resolves with the SHA-1 hash as a hexadecimal string.
 */
export function sha1(message: string): Promise<string> {
	const utf8 = new TextEncoder().encode(message);
	return crypto.subtle.digest('SHA-1', utf8).then((hashBuffer) => {
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray
			.map((bytes) => bytes.toString(16).padStart(2, '0'))
			.join('');
		return hashHex;
	});
}

/**
 * Generates the SHA-256 hash of a string message.
 * @param {string} message - The message to hash.
 * @returns {Promise<string>} - A promise that resolves with the SHA-256 hash as a hexadecimal string.
 */
export function sha256(message: string): Promise<string> {
	const utf8 = new TextEncoder().encode(message);
	return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray
			.map((bytes) => bytes.toString(16).padStart(2, '0'))
			.join('');
		return hashHex;
	});
}

/**
 * Computes the hash code of a string.
 * @param {string} str - The string to compute the hash code for.
 * @returns {number} - The computed hash code.
 */
export function hashCode(str: string): number {
	let hash = 0;
	for (let i = 0; i < str.length; i += 1) {
		// eslint-disable-next-line no-bitwise
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}

/**
 * Picks a color type based on a string.
 * @param {string} [str] - The string to determine the color type.
 * @returns {string} - The picked color type.
 */
export function pickColorType(str?: string, disabled?: boolean | null): string {
	const keys = ['blue', 'cyan', 'lime', 'violet', 'grape', 'green'];

	if (disabled) {
		return 'gray';
	}

	if (!str) {
		return 'blue';
	}

	return keys[Math.abs(hashCode(str)) % keys.length];
}

/**
 * Picks a color based on a string.
 * @param {string} [str] - The string to determine the color.
 * @returns {string} - The picked color.
 */
export function pickColor(str?: string, level?: number): string {
	return DEFAULT_THEME.colors[pickColorType(str)][level || 4];
}

/**
 * Checks if a given URL is an internal link within the application.
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns `true` if the URL is internal, `false` otherwise.
 *
 * Important Note: If this is called server-side, it will always return false.
 * The reason this is in a shared util is because it's used in an editor plugin
 * which is also in the shared code.
 */
export function isInternalUrl(url: string): boolean {
	// Empty strings are never internal
	if (url === '') {
		return false;
	}

	// Relative paths are always internal
	if (url[0] === '/') {
		return true;
	}

	const linkHost =
		typeof window !== 'undefined' ? window.location.host : undefined;

	const currentHost = new URL(url).host;
	return linkHost === currentHost;
}

/**
 * Returns a random width between the min and max width.
 *
 * @param minWidth Minimum width of the element. Default is 50.
 * @param maxWidth Maximum width of the element. Default is 100.
 */
export function getRandomWidth(minWidth = 50, maxWidth = 100) {
	return `${Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth}%`;
}

export type ObjectValues<T> = T[keyof T];

export function timeout(ms: number | undefined) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
