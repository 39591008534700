import { useDebouncedValue } from '@mantine/hooks';
import { useCallback } from 'react';

import { FilterValue } from '../types';
import { useGetFilter } from '../../../api/codegen/apiComponents';
import { getFilterValueFromApiCatalogFilter } from '../utils';
import { trackEvent } from '../../../utils/analytics';

interface UseFilterDropdownAIProps {
	onChange: (value: FilterValue, close: boolean) => void;
}

export function useFilterDropdownAI({ onChange }: UseFilterDropdownAIProps) {
	const {
		error: errorFilter,
		isLoading: isLoadingFilter,
		mutateAsync: generateFilter,
	} = useGetFilter({});
	const [debouncedLoading] = useDebouncedValue(isLoadingFilter, 300);

	const askAi = useCallback(
		async (searchTerm: string) => {
			if (!searchTerm) {
				return;
			}

			try {
				const filterResult = await generateFilter({
					queryParams: { query: searchTerm },
				});

				const filterValue = getFilterValueFromApiCatalogFilter(filterResult);

				filterValue.forEach((value, idx) => {
					onChange(value, idx === filterValue.length - 1);
				});

				trackEvent('ai/filters/apply', {
					search_term: searchTerm,
					success: 'true',
				});
			} catch (e) {
				trackEvent('ai/filters/apply', {
					search_term: searchTerm,
					success: 'false',
				});
			}
		},
		[generateFilter, onChange]
	);

	return {
		loading: debouncedLoading,
		error: errorFilter,
		askAi,
	};
}
