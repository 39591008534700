import { baseQueryHooksFactory } from '../../factories';
import type { ISecodaEntity } from '../../types';
import { getDefaultUpdateFn } from '../base/useUpdateBaseModel';
import { secodaEntitiesQueryKeyFactory } from '../secodaEntity';
import { resourceCatalogQueryKeyFactory } from './constants';

const { fetchCatalogList, useCatalogList, useCatalogInfiniteList } =
	baseQueryHooksFactory<ISecodaEntity, 'catalog'>(
		'catalog',
		resourceCatalogQueryKeyFactory
	);

export const updateCatalog = getDefaultUpdateFn<ISecodaEntity>(
	secodaEntitiesQueryKeyFactory.namespace
);

export { fetchCatalogList, useCatalogList, useCatalogInfiniteList };
