import { useQuery } from '@tanstack/react-query';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	fetchAllPages,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IApiListResponse, IBaseModel, ICollection } from '../../types';
import { filterFromListCacheByIds } from '../../utils';

export const COLLECTIONS_NAMESPACE = ['collection', 'collections'];

export const collectionsQueryKeyFactory = createQueryKeys(
	COLLECTIONS_NAMESPACE
);

const { prefetchCollection, prefetchCollectionList } = prefetchFunctionsFactory(
	'collection',
	collectionsQueryKeyFactory
);

export const filterCollectionsByIds = (ids: string[]) =>
	filterFromListCacheByIds<ICollection>(collectionsQueryKeyFactory, ids);

const {
	useCollection,
	useCollectionInfiniteList,
	useCollectionList,
	useCreateCollection,
	useDeleteCollection,
	useUpdateCollection,
	fetchCollectionList,
	updateCollection,
} = baseQueryHooksFactory<ICollection, 'collection'>(
	'collection',
	collectionsQueryKeyFactory
);

async function toArray(
	asyncIterator: AsyncGenerator<IApiListResponse<IBaseModel>, void, unknown>
) {
	const arr: IBaseModel[] = [];
	// eslint-disable-next-line no-restricted-syntax, no-constant-condition
	while (true) {
		// eslint-disable-next-line no-await-in-loop
		const { done, value } = await asyncIterator.next();
		if (done) break;
		arr.push(...value.results);
	}

	return arr;
}

export const useCollectionListAll = () =>
	useQuery({
		queryKey: collectionsQueryKeyFactory.allLists(),
		queryFn: async () => ({
			results: (await toArray(
				fetchAllPages(collectionsQueryKeyFactory, {})
			)) as ICollection[],
		}),
	});

export {
	updateCollection,
	fetchCollectionList,
	prefetchCollection,
	prefetchCollectionList,
	useCollection,
	useCollectionInfiniteList,
	useCollectionList,
	useCreateCollection,
	useDeleteCollection,
	useUpdateCollection,
};
