import { Box, createStyles, Loader, Skeleton } from '@mantine/core';
import { Suspense } from 'react';
import { Icon } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import { useDictionaryTerm, useDocument } from '../../../../../api';
import { useGetOrCreateDictionaryTerm } from '../../../../../api/hooks/dictionaryTerm/useGetOrCreateDictionaryTerm';
import { useGetOrCreateDocument } from '../../../../../api/hooks/document/useGetOrCreateDocument';
import { Providers } from '../../../../../providers';
import {
	entityBasePathFromCurrentPath,
	entityIdFromCurrentPath,
} from '../../../../../utils/shared.utils';

const useStyles = createStyles((theme) => ({
	wrapper: {
		':hover': {
			color:
				theme.colorScheme === 'dark'
					? theme.colors.dark[0]
					: theme.colors.gray[9],
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[7]
					: theme.colors.gray[0],
		},
		padding: theme.spacing.xs,
		fontWeight: typography.weight.semibold,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing.xs,
		borderRadius: theme.radius.xs,
		color:
			theme.colorScheme === 'dark'
				? `${theme.colors.dark[0]} !important`
				: `${theme.colors.gray[7]} !important`,
	},
}));

export function DictionaryTermEmbed({ id }: { id: string }) {
	const parentId = entityIdFromCurrentPath();
	const { classes } = useStyles();

	const { data: parent, isLoading: isLoadingParent } = useDictionaryTerm({
		id: parentId!,
		options: {
			enabled: !!parentId,
		},
	});

	const { data: document, isLoading: isLoadingDocument } =
		useGetOrCreateDictionaryTerm({
			id,
			parent: parent?.id,
			teams: parent?.teams?.[0] ? [parent?.teams?.[0]] : undefined,
			options: {
				enabled: parent,
			},
		});

	if (isLoadingParent || isLoadingDocument) {
		return <Loader size="xs" />;
	}

	if (!document || document.archived) {
		/**
			If a page has been deleted, its URL will lead users to a blank page.
			The expected outcome is that when a page gets deleted, its links should be removed from all pages in the workspace.
			This functionality has not been implemented yet on the backend, so I'm opting to return null here and not render the broken link.
			This will function exactly as if the link has been removed from the page by the backend.
			Slack for context: https://secodaworkspace.slack.com/archives/C04JNGRFHDE/p1709055923449639
			Backend task: https://linear.app/secoda/issue/ENG-7086/remove-page-broken-links-from-documents-automatically-when-a-page-is
		 */
		return null;
	}

	return (
		<a href={`/dictionary/${id}`} className={classes.wrapper}>
			<Icon name="file" />
			<Box>{document.title ?? 'Untitled'}</Box>
		</a>
	);
}

export function DocumentEmbed({
	id,
	withParent = true,
}: {
	id: string;
	withParent?: boolean;
}) {
	const parentId = entityIdFromCurrentPath();
	const { classes } = useStyles();

	const { data: parent, isLoading: isLoadingParent } = useDocument({
		id: parentId!,
		options: {
			enabled: !!parentId && withParent,
		},
	});

	const { data: document, isLoading: isLoadingDocument } =
		useGetOrCreateDocument({
			id,
			parent: withParent ? parent?.id : undefined,
			teams:
				withParent && parent?.teams?.[0] ? [parent?.teams?.[0]] : undefined,
			options: {
				enabled: (withParent && parent) || !withParent,
			},
		});

	if ((withParent && isLoadingParent) || isLoadingDocument) {
		return <Loader size="xs" />;
	}

	if (!document || document.archived) {
		/**
			If a page has been deleted, its URL will lead users to a blank page.
			The expected outcome is that when a page gets deleted, its links should be removed from all pages in the workspace.
			This functionality has not been implemented yet on the backend, so I'm opting to return null here and not render the broken link.
			This will function exactly as if the link has been removed from the page by the backend.
			Slack for context: https://secodaworkspace.slack.com/archives/C04JNGRFHDE/p1709055923449639
			Backend task: https://linear.app/secoda/issue/ENG-7086/remove-page-broken-links-from-documents-automatically-when-a-page-is
		 */
		return null;
	}

	return (
		<a href={`/docs/${id}`} className={classes.wrapper}>
			<Icon name="file" />
			<Box>{document.title ?? 'Untitled'}</Box>
		</a>
	);
}

export function Page(props: { id: string }) {
	const { id } = props;

	const isDocument = entityBasePathFromCurrentPath() === 'docs';
	const isDictionaryTerm = entityBasePathFromCurrentPath() === 'dictionary';

	if (isDictionaryTerm) {
		return <DictionaryTermEmbed id={id} />;
	}
	if (isDocument) {
		return <DocumentEmbed withParent id={id} />;
	}

	// Default to document for Catalog, Collections documentation.
	return <DocumentEmbed withParent={false} id={id} />;
}

export function PageContainer(props: { id: string }) {
	const { id } = props;

	return (
		<Providers>
			<Suspense fallback={<Skeleton height={32} />}>
				<Page id={id} />
			</Suspense>
		</Providers>
	);
}
