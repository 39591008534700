import type { QueryKey } from '@tanstack/react-query';
import type { ApiPaths } from './apiPaths';
import { resolvePathParam } from './apiContext';

export function apiQueryKey(
	path: ApiPaths,
	pathParams: Record<string, string> = {},
	queryParams: Record<string, unknown> | null = null
): QueryKey {
	const res: Array<unknown> = path
		.split('/')
		.filter(Boolean)
		.map((i) => resolvePathParam(i, pathParams));

	if (queryParams) {
		res.push(queryParams);
	}

	return res;
}
