// NOTE: ideally when we revist this we avoid Mantine Text and Mantine Button.
import {
	// eslint-disable-next-line no-restricted-imports
	Button,
	createStyles,
	Group,
	Menu,
	Text,
	UnstyledButton,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { noop, startCase } from 'lodash-es';
import { typography } from '@repo/theme/primitives';

interface IFilterDropdownHeaderProps {
	label: string;
	dangerLabel?: string;
	isInclusive: boolean;
	onMenuChange?: (opened: boolean) => void;
	onInclusiveClick?: () => void;
	onExclusiveClick?: () => void;
	onDangerClick?: () => void;
}

const useStyles = createStyles((theme) => ({
	label: {
		fontSize: theme.fontSizes.xs,
		fontWeight: typography.weight.regular,
		lineHeight: '16px',
		color: theme.other.getColor('text/secondary/default'),
	},
	buttonRoot: {
		borderWidth: 0,
	},
	buttonRightIcon: {
		marginLeft: 0,
	},
	buttonLabel: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.xs,
		fontWeight: typography.weight.semibold,
	},
}));

export function FilterMenuDropdownHeader({
	label,
	dangerLabel = 'Clear filters',
	isInclusive,
	onMenuChange = noop,
	onInclusiveClick = noop,
	onExclusiveClick = noop,
	onDangerClick = noop,
}: IFilterDropdownHeaderProps) {
	const { classes, theme } = useStyles();

	return (
		<Group position="apart" mb={6}>
			<Group spacing={0}>
				<Text className={classes.label} mr={2}>
					{startCase(label)}
				</Text>
				<Menu
					width={180}
					position="bottom-start"
					onChange={onMenuChange}
					withinPortal
				>
					<Menu.Target>
						<Button
							classNames={{
								root: classes.buttonRoot,
								rightIcon: classes.buttonRightIcon,
								label: classes.buttonLabel,
							}}
							variant="subtle"
							rightIcon={
								<IconChevronDown
									color={theme.other.getColor('icon/secondary/default')}
									size={16}
								/>
							}
							p={2}
						>
							{isInclusive ? 'is' : 'is not'}
						</Button>
					</Menu.Target>
					<Menu.Dropdown>
						<Menu.Item onClick={onInclusiveClick}>is</Menu.Item>
						<Menu.Item onClick={onExclusiveClick}>is not</Menu.Item>
					</Menu.Dropdown>
				</Menu>
			</Group>
			<UnstyledButton px={4} py={2} onClick={onDangerClick}>
				<Text className={classes.label}>{dangerLabel}</Text>
			</UnstyledButton>
		</Group>
	);
}
