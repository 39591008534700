/* eslint-disable @typescript-eslint/no-empty-function */
import type { MantineTheme } from '@mantine/core';
import {
	Checkbox,
	createStyles,
	Group,
	Menu,
	Radio,
	Text,
} from '@mantine/core';
import { includes, isNil, map, noop, truncate } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useContext, useLayoutEffect } from 'react';
import { typography } from '@repo/theme/primitives';
import type { FilterMenuItem, FilterOption } from '../FilterCarousel.constants';
import { SearchFilterStoreContext } from '../store';

interface IFilterDropdownItemProps {
	filterOption: FilterOption;
	filterMenuItem?: FilterMenuItem;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	label: {
		color: theme.colors.gray[9],
		fontSize: theme.fontSizes.sm,
		fontWeight: typography.weight.regular,
	},
}));

function FilterDropdownItem({
	filterOption,
	filterMenuItem,
}: IFilterDropdownItemProps) {
	const searchFilterStore = useContext(SearchFilterStoreContext);

	const { theme, classes } = useStyles();

	if (isNil(filterMenuItem)) {
		return null;
	}

	const { isRadio } = filterMenuItem;

	const checked = includes(
		map(
			searchFilterStore.searchFilters[filterMenuItem.value]?.selectedOptions,
			'value'
		),
		filterOption.value
	);

	useLayoutEffect(() => {
		searchFilterStore.determineControlVisibility();
	}, [checked]);

	const handleClick = () => {
		if (isRadio) {
			searchFilterStore.toggleSelectedFilterOptions(
				filterMenuItem.value,
				filterOption
			);
		} else {
			searchFilterStore.setSelectedFilterOptions(
				filterMenuItem.value,
				filterOption,
				!checked
			);
		}
	};

	return (
		<Menu.Item
			data-testid="search-page-filter-dropdown-item"
			icon={
				isRadio ? (
					<Radio size="xs" checked={checked} onChange={noop} />
				) : (
					<Checkbox checked={checked} onChange={noop} />
				)
			}
			onClick={handleClick}
		>
			<Group spacing="xs">
				{filterOption.icon ? (
					<filterOption.icon
						color={theme.other.getColor('icon/primary/default')}
						size="20"
					/>
				) : null}
				<Text className={classes.label}>
					{truncate(filterOption.label, { length: 28 })}
				</Text>
			</Group>
		</Menu.Item>
	);
}

export default observer(FilterDropdownItem);
