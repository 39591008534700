import { Box, Flex, createStyles, ScrollArea, Stack } from '@mantine/core';
import { map } from 'lodash-es';
import { useCallback, useState } from 'react';
import { Button } from '@repo/foundations';
import type { IWorkspace } from '../../api';
import { joinWorkspace } from '../../api';
import { trackEventAnonymous } from '../../utils/analytics';
import { AuthStepWorkspaceJoinRow } from './AuthStepWorkspaceJoinRow';
import { AuthLayoutBase } from './AuthLayouts/AuthLayoutBase';
import {
	anonymousId,
	EMAIL_VERIFICATION_LOCAL_STORAGE_KEY,
	referrer,
} from './AuthStepEmailComponent.constants';

const useStyles = createStyles((theme) => ({
	page: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		height: '100vh',
		alignItems: 'center',
	},
	container: {
		height: '100%',
		justify: 'space-between',
		paddingLeft: theme.spacing.sm,
		paddingRight: theme.spacing.sm,
		width: theme.other.space[100],
	},
}));

interface AuthStepWorkspaceJoinProps {
	workspaces: IWorkspace[];
	toggleTab: (tab: 'join' | 'create') => void;
}

export function AuthStepWorkspaceJoin({
	toggleTab,
	workspaces,
}: AuthStepWorkspaceJoinProps) {
	const { classes, theme } = useStyles();
	const [loading, setLoading] = useState(false);

	const handleJoinWorkspace = useCallback(async (id: string) => {
		trackEventAnonymous(
			'onboarding/workspace/join',
			{
				path: window.location.pathname,
				anonymous_id: anonymousId(),
				referrer: referrer(),
			},
			localStorage.getItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY) ?? ''
		);

		setLoading(true);
		await joinWorkspace(id);
		// We use `window.open` rather than `navigate` in this context in order to
		// trigger a reload which causes the workspace information to be refreshed
		// with the most up to date information about user's workspace membership.
		window.open('/onboarding/confirm', '_self');
	}, []);

	return (
		<Box className={classes.page}>
			<AuthLayoutBase
				title="Join or create a workspace"
				subTitle="You can create or join more workspaces later."
			>
				<Stack className={classes.container}>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: theme.spacing.md,
						}}
					>
						<ScrollArea.Autosize mah={350} type="auto">
							<Stack my={theme.other.space[1.5]} spacing="sm">
								{map(workspaces, (workspace) => (
									<AuthStepWorkspaceJoinRow
										key={workspace.id}
										disabled={loading}
										workspace={workspace}
										onJoinClick={() => handleJoinWorkspace(workspace.id)}
									/>
								))}
							</Stack>
						</ScrollArea.Autosize>
					</Box>
				</Stack>
				<Flex justify="center">
					<Button
						onClick={() => toggleTab('create')}
						leftIconName="plus"
						mx="xs"
					>
						Create new workspace
					</Button>
				</Flex>
			</AuthLayoutBase>
		</Box>
	);
}
