import { Text, Tooltip } from '@mantine/core';
import { has, map, truncate } from 'lodash-es';
import type { ICollection, ITag } from '../../../api';
import type { User, UserGroup } from '../../../lib/models';
import BoolChip from '../../CatalogView/TableView/components/BoolChip';
import CollectionChip from '../../CatalogView/TableView/components/CollectionChip';
import TagChip from '../../CatalogView/TableView/components/TagChip';
import UserChip from '../../CatalogView/TableView/components/UserChip';
import UserGroupChip from '../../CatalogView/TableView/components/UserGroupChip';
import { PIIIcon, VerifiedIcon } from '../../CatalogView/TableView/icons';
import MarkdownRenderer from '../../MarkdownRenderer';
import type { Metadata, MetadataMapKey } from '../types';

interface IEntityValueProps {
	type: MetadataMapKey;
	value: Metadata[MetadataMapKey];
}

function EntityValue({ type, value }: IEntityValueProps) {
	switch (type) {
		case 'verified':
			return (
				<BoolChip
					key={type}
					inline
					active={Boolean(value)}
					activeText="Verified"
					activeIcon={<VerifiedIcon />}
				/>
			);

		case 'pii':
			return (
				<BoolChip
					key={type}
					inline
					active={Boolean(value)}
					activeText="PII"
					activeIcon={<PIIIcon />}
				/>
			);

		case 'tags':
			return (
				<>
					{map(value as unknown as ITag[], (tag) => (
						<TagChip key={tag.id} tag={tag} />
					))}
				</>
			);

		case 'collections':
			return (
				<>
					{map(value as unknown as ICollection[], (collection) => (
						<CollectionChip
							inline
							key={collection.id}
							collection={collection}
						/>
					))}
				</>
			);

		case 'owners':
			return (
				<>
					{map(value as (User | UserGroup)[], (owner) => {
						if (has(owner, 'display_name')) {
							return <UserChip key={owner.id} inline user={owner as User} />;
						}

						return (
							<UserGroupChip
								key={owner.id}
								inline
								userGroup={owner as UserGroup}
							/>
						);
					})}
				</>
			);
		case 'description':
		case 'definition':
			return (
				<Tooltip
					key={type}
					withinPortal
					multiline
					position="top-start"
					width={400}
					label={
						<MarkdownRenderer>
							{truncate(value as string, { length: 600 })}
						</MarkdownRenderer>
					}
					disabled={!value}
				>
					<Text>{value}</Text>
				</Tooltip>
			);

		default:
			return null;
	}
}

export default EntityValue;
