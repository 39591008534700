import type { IMarketplaceIntegrationSpecVersion } from '../api/types/models/marketplace';

export const publishStatusBadgeColor = (
	publishStatus: IMarketplaceIntegrationSpecVersion['publish_status']
) => {
	if (publishStatus === 'APPROVED') {
		return 'green';
	}
	if (publishStatus === 'SUBMITTED') {
		return 'yellow';
	}
	if (publishStatus === 'REJECTED') {
		return 'red';
	}

	return 'gray';
};
export const publishStatusBadgeLabel = (
	publishStatus: IMarketplaceIntegrationSpecVersion['publish_status']
) => {
	if (publishStatus === 'APPROVED') {
		return 'Approved';
	}
	if (publishStatus === 'SUBMITTED') {
		return 'In review';
	}
	if (publishStatus === 'REJECTED') {
		return 'Rejected';
	}

	return 'Draft';
};
