import { Stack } from '@mantine/core';

import type { SelectablePropertyItem } from '../../../SingleSelector/types';
import { StaticProperty } from '../../EntityPropertySidebar';
import { AIGovernanceInclusionStatus } from '../AIGovernanceInclusionStatus/AIGovernanceInclusionStatus';

export interface SecodaMetadataStackProps {
	createdAtLabelSecoda: string;
	updatedAtLabelSecoda: string;
	resourceTypeSecoda: string | null;
	withFrequentUsers: boolean;
	frequentUsersSecoda: SelectablePropertyItem[];
	viewsValue: string;
	withAIInclusionStatus: boolean;
	entityId: string;
}

function SecodaMetadataStack({
	createdAtLabelSecoda,
	updatedAtLabelSecoda,
	resourceTypeSecoda,
	withFrequentUsers,
	frequentUsersSecoda,
	viewsValue,
	withAIInclusionStatus,
	entityId,
}: SecodaMetadataStackProps) {
	return (
		<Stack spacing="xs">
			{createdAtLabelSecoda && (
				<StaticProperty
					type="text"
					label="Created"
					value={createdAtLabelSecoda}
				/>
			)}
			{updatedAtLabelSecoda && (
				<StaticProperty
					type="text"
					label="Updated"
					value={updatedAtLabelSecoda}
				/>
			)}
			{resourceTypeSecoda && (
				<StaticProperty type="text" label="Type" value={resourceTypeSecoda} />
			)}
			{withFrequentUsers && frequentUsersSecoda.length > 0 && (
				<StaticProperty
					type="users"
					label="Frequent Users"
					value={frequentUsersSecoda}
				/>
			)}
			<StaticProperty type="text" label="Views" value={viewsValue} />
			{withAIInclusionStatus && (
				<AIGovernanceInclusionStatus entityId={entityId} />
			)}
		</Stack>
	);
}

export default SecodaMetadataStack;
