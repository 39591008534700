import { useCallback, useMemo } from 'react';
import { isNil, range } from 'lodash-es';
import { ListBox } from '@repo/foundations';
import { Stack } from '@mantine/core';
import { FilterMenuItem } from '../FilterMenuItem';
import { FilterType } from '../types';
import type {
	FilterDropdownConfigList,
	FilterItem,
	FilterValue,
	FilterValueType,
} from '../types';
import {
	FILTER_OPTIONS_DIVIDER,
	IS_NOT_SET_FILTER_ITEM,
	IS_SET_FILTER_ITEM,
} from '../constants';
import { FilterItemSkeleton } from '../FilterItemSkeleton';
import { FilterItemError } from '../FilterItemError';
import { useFilterDropdownList } from './useFilterDropdownList';

interface FilterDropdownListProps {
	value: FilterValue;
	searchPlaceholder: FilterDropdownConfigList['searchPlaceholder'];
	getItems: FilterDropdownConfigList['getItems'];
	getItemsById?: FilterDropdownConfigList['getItemsById'];
	filterType?: FilterType;
	renderMenuItem?: (item: FilterItem) => React.ReactNode;
	onChange: (value: FilterValue, close: boolean) => void;
	hasIsNotSetOption?: boolean;
	hasIsSetOption?: boolean;
}

export function FilterDropdownList({
	getItems,
	getItemsById,
	value,
	searchPlaceholder = 'Search',
	filterType = FilterType.Multiple,
	renderMenuItem,
	onChange,
	hasIsNotSetOption,
	hasIsSetOption,
}: FilterDropdownListProps) {
	const { selected, unselected, loading, error, searchTerm, setSearchTerm } =
		useFilterDropdownList({
			getItems,
			getItemsById,
			value,
			hasIsNotSetOption,
			hasIsSetOption,
		});

	const onSelect = useCallback(
		(item: FilterItem) => (close: boolean) => {
			if (item.value === IS_NOT_SET_FILTER_ITEM.value) {
				onChange({ ...value, isNotSetApplied: true }, close);
			} else if (item.value === IS_SET_FILTER_ITEM.value) {
				onChange({ ...value, isSetApplied: true }, close);
			} else if (filterType === FilterType.Single) {
				onChange(
					{
						...value,
						value: item.value,
					},
					close
				);
			} else if (filterType === FilterType.Multiple) {
				let currentValue: FilterValueType[] = [];

				if (!isNil(value.value)) {
					currentValue = Array.isArray(value.value)
						? value.value
						: [value.value];
				}

				onChange(
					{
						...value,
						value: [...currentValue, item.value],
					},
					close
				);
			} else {
				throw new Error('Invalid filter type');
			}
		},
		[filterType, onChange, value]
	);

	const onDeselect = useCallback(
		(item: FilterItem) => (close: boolean) => {
			if (item.value === IS_NOT_SET_FILTER_ITEM.value) {
				onChange({ ...value, isNotSetApplied: false }, close);
			} else if (item.value === IS_SET_FILTER_ITEM.value) {
				onChange({ ...value, isSetApplied: false }, close);
			} else if (filterType === FilterType.Single) {
				// selecting a already checked radio button shouldn't do anything
				// NOOP
			} else if (filterType === FilterType.Multiple) {
				let currentValue: FilterValueType[] = [];

				if (!isNil(value.value)) {
					currentValue = Array.isArray(value.value)
						? value.value
						: [value.value];
				}

				const newValue = currentValue.filter(
					(currentValueItem) => currentValueItem !== item.value
				);

				onChange(
					{
						...value,
						value: newValue,
					},
					close
				);
			} else {
				throw new Error('Invalid filter type');
			}
		},
		[filterType, onChange, value]
	);

	const items = useMemo(() => {
		const result: Array<FilterItem | typeof FILTER_OPTIONS_DIVIDER> = [
			...selected,
		];

		if (selected.length > 0 && unselected.length) {
			result.push(FILTER_OPTIONS_DIVIDER);
		}

		result.push(...unselected);

		return result;
	}, [selected, unselected]);

	return (
		<ListBox.ItemsDropdown
			search={{
				onChange: setSearchTerm,
				value: searchTerm,
				placeholder: searchPlaceholder,
			}}
			items={items}
			renderItem={(item, getProps, idx) => {
				if (item === FILTER_OPTIONS_DIVIDER) {
					return <ListBox.Divider key={`divider-${idx}`} />;
				}

				const isSelected = selected.includes(item);

				return (
					<FilterMenuItem
						key={`filter-value-${item.value}`}
						filterType={filterType}
						item={item}
						renderMenuItem={renderMenuItem}
						onClick={isSelected ? onDeselect(item) : onSelect(item)}
						selected={isSelected ? true : undefined}
						getProps={getProps}
					/>
				);
			}}
		>
			{loading && (
				<Stack spacing="2xs">
					{range(3).map((idx) => (
						<FilterItemSkeleton key={idx} />
					))}
				</Stack>
			)}
			{error && <FilterItemError>An error has ocurred</FilterItemError>}
		</ListBox.ItemsDropdown>
	);
}
