import type { EditorState } from 'prosemirror-state';
import { Icon } from '@repo/foundations';
import type { EditorDictionary, MenuItem } from '@repo/secoda-editor';

export default function tableRowMenuItems(
	state: EditorState,
	index: number,
	dictionary: EditorDictionary
): MenuItem[] {
	return [
		{
			name: 'toggleHeaderRow',
			tooltip: dictionary.toggleHeader,
			icon: () => <Icon name="tableRow" color="icon/primary/default" />,
			visible: index === 0,
		},
		{
			name: 'addRowBefore',
			tooltip: dictionary.addRowBefore,
			icon: () => <Icon name="rowInsertTop" color="icon/primary/default" />,
			attrs: { index },
			active: () => false,
		},
		{
			name: 'addRowAfter',
			tooltip: dictionary.addRowAfter,
			icon: () => <Icon name="rowInsertBottom" color="icon/primary/default" />,
			attrs: { index },
			active: () => false,
		},
		{
			name: 'separator',
		},
		{
			name: 'deleteRow',
			tooltip: dictionary.deleteRow,
			icon: () => <Icon name="trash" color="icon/primary/default" />,
			active: () => false,
		},
	];
}
