import type { MantineThemeOverride } from '@mantine/core';
import { rem } from '@mantine/core';
import { isNil, omitBy } from 'lodash-es';

export const ActionIcon: MantineThemeOverride['components'] = {
	ActionIcon: {
		styles: (theme, params, context) => {
			let radius = theme.radius.xs;
			let size = rem(32);
			let padding = 6;

			if (context.size === 'sm' || context.size === 'xs') {
				radius = theme.radius.xs;
				size = rem(28);
				padding = 4;
			}

			const root = omitBy(
				{
					radius,
					height: size,
					width: size,
					padding,
				},
				isNil
			);
			return {
				root,
			};
		},
		variants: {
			default: (theme) => ({
				root: {
					borderColor: theme.colors.gray[3],
					'&:hover': {
						backgroundColor: theme.colors.gray[1],
					},
				},
			}),
			subtle: (theme) => ({
				root: {
					border: 0,
					'&:hover': {
						backgroundColor: theme.other.getColor('surface/primary/hover'),
					},
					'&:active': {
						backgroundColor: theme.other.getColor('surface/primary/active'),
					},
				},
			}),
			outline: (theme) => ({
				root: {
					color: theme.other.getColor('icon/primary/default'),
					borderRadius: theme.radius.sm,
					border: 'thin solid',
					borderColor: theme.other.getColor('border/primary/default'),
				},
			}),
		},
	},
};
