import { Avatar, createStyles, Text } from '@mantine/core';
import { type Icon as TablerIcon, IconCircle } from '@tabler/icons-react';
import { upperFirst } from 'lodash-es';
import type { ReactElement, ReactNode } from 'react';
import type { SecodaThemeShades } from '@repo/theme/types';
import type { SelectablePropertyItem } from '../SingleSelector/types';
import TagIcon from '../TagIcon';
import { colorGroupToFillMap } from '../UserAvatar/helpers';
import { pickColorType } from '../../utils/utils';

type IItemIconItem = Partial<
	Pick<
		SelectablePropertyItem,
		'icon' | 'label' | 'color' | 'defaultIcon' | 'size' | 'fill'
	>
>;

export type ItemIconType =
	| 'none'
	| 'badge'
	| 'react-node'
	| 'avatar'
	| 'tabler'
	| 'tag'
	| 'emoji'
	| 'circle';

export type IItemIconProps = {
	type: ItemIconType;
	item: IItemIconItem;
	color?: SecodaThemeShades;
};

type IStyleParams = Pick<IItemIconProps, 'color' | 'type'>;

const useStyles = createStyles(
	(theme, { color = 'icon/primary/default', type }: IStyleParams) => ({
		root: {
			backgroundColor:
				type !== 'avatar'
					? theme.other.themeColor(theme, color, 2)
					: theme.other.themeColor(theme, color),
		},
		emoji: {
			fontSize: theme.fontSizes.md,
			lineHeight: theme.fontSizes.md,
		},
	})
);

function ItemIcon({
	type,
	item,
	color = 'icon/primary/default',
}: IItemIconProps) {
	const { theme, classes } = useStyles({
		color,
		type,
	});

	if (type === 'none') {
		return null;
	}

	if (type === 'react-node') {
		return item.icon as ReactElement;
	}

	const iconColor = theme.other.themeColor(
		theme,
		item?.color || item?.fill || 'gray'
	);

	const iconFill = theme.other.themeColor(theme, item?.fill || 'gray');

	if (type === 'circle') {
		return (
			<IconCircle color={iconColor} fill={iconFill} size={item?.size ?? 12} />
		);
	}

	if (type === 'avatar') {
		let placeholder: ReactNode = upperFirst(item.label?.slice(0, 1));

		if (item.icon) {
			placeholder = item.icon;
		}

		if (item.defaultIcon) {
			const Icon = item.defaultIcon;
			placeholder = <Icon size={item?.size ?? 12} color={iconColor} />;
		}

		const colorGroup = pickColorType(item.label);
		const { fillStart, fillEnd, textColor } = colorGroupToFillMap(colorGroup);

		return (
			<Avatar
				classNames={{ root: classes.root }}
				size={item?.size ?? 'xs'}
				src={item?.icon as string | undefined}
				sx={{
					color: textColor,
					backgroundImage: theme.fn.gradient({
						from: fillStart,
						to: fillEnd,
						deg: 180,
					}),
				}}
				radius="lg"
			>
				{placeholder}
			</Avatar>
		);
	}

	if (type === 'tabler') {
		const Icon = item.icon as TablerIcon;
		return <Icon size={item?.size ?? 12} color={iconColor} fill={iconFill} />;
	}

	if (type === 'tag') {
		return <TagIcon color={iconColor} />;
	}

	return <Text className={classes.emoji}>{item.icon}</Text>;
}

export default ItemIcon;
