import { isNil } from 'lodash-es';
import { useAuthUser, useSearchViewList } from '../../../api';
import type { FilterView } from '../types';

export function useFilterViews() {
	const { user } = useAuthUser();
	const { data: views } = useSearchViewList({
		options: {
			select: (response) =>
				(response.results ?? [])
					.map(
						(view) =>
							({
								label: view.name,
								value: view.id,
								filters: view.selected_filters,
								isOwner: view.owner_id === user.id,
								teams: view.teams,
							}) as FilterView
					)
					.sort((a, b) => {
						if (isNil(a.label)) return 1;
						return a.label.localeCompare(b.label);
					}),
		},
	});

	return {
		views: views ?? [],
	};
}
