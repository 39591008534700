import type { InputRule } from 'prosemirror-inputrules';
import type {
	Node as ProsemirrorNode,
	NodeSpec,
	NodeType,
	Schema,
} from 'prosemirror-model';
import { ParseSpec } from 'prosemirror-markdown';
import type { Command } from 'prosemirror-state';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type { CommandFactory } from '../lib/Extension';
import Extension from '../lib/Extension';

export type NodeOptions = { type: NodeType; schema: Schema };

export default abstract class Node extends Extension<NodeType> {
	get type() {
		return 'node';
	}

	get schema(): NodeSpec {
		return {};
	}

	get markdownToken(): string {
		return '';
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	inputRules(_options: NodeOptions): InputRule[] {
		return [];
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	keys(_options: NodeOptions): Record<string, Command> {
		return {};
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	commands(
		_options: NodeOptions
	): Record<string, CommandFactory> | CommandFactory {
		return {};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode): void {
		// eslint-disable-next-line no-console
		console.error('toMarkdown not implemented', state, node);
	}

	parseMarkdown(): ParseSpec | void {
		return undefined;
	}
}
