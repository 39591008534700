import { ColumnColumns } from './column';

export const EventPropertyColumns = [
	'title',
	'type',
	'description',
	'tags',
	'owners',
].reduce((columns, columnName) => {
	const columnDef = ColumnColumns?.[columnName] ?? {
		field: columnName,
		editable: false,
		sortable: false,
	};

	return {
		...columns,
		[columnName]: columnDef,
	};
}, {});
