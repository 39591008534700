import { camelCase, forEach, isArray, upperFirst } from 'lodash-es';
import { getDefaultQueryFn } from '../hooks/base/useBaseModel';
import { getDefaultListQueryFn } from '../hooks/base/useBaseModelList';
import queryClient from '../queryClient';
import type { ListQueryParams } from '../types';
import type { QueryKeyFactory } from './queryKeyFactory';
import type {
	PrefetchFunctionsFactory,
	PrefetchListFunctionArgs,
} from './types';

/**
 * Iteratively fetches all pages of a list query and stores them in the query cache.
 * **CAUTION**: This function will not stop until it has fetched all pages of the list
 * so do NOT run it unless it is necessary. This will
 * be removed once we populate values directly from backend instead of sending
 * PrimaryKeyField values in the response.
 *
 * Contact @vinothpandian before you use this function anywhere else.
 *
 * @param queryKeyFactory Query key factory for the model
 * @param filters Filters to apply to the list query
 * @return An async generator that yields the response of each page
 */
export async function* fetchAllPages(
	queryKeyFactory: QueryKeyFactory,
	filters: ListQueryParams['filters']
) {
	let pageNumber = 1;
	try {
		while (true) {
			// eslint-disable-next-line no-await-in-loop
			const response = await queryClient.fetchQuery({
				queryKey: queryKeyFactory.list(pageNumber, filters),
				queryFn: getDefaultListQueryFn(
					{ page: pageNumber, filters },
					queryKeyFactory.namespace
				),
			});
			yield response;
			if (response.meta.next_page === null) return;
			pageNumber += 1;
		}
	} catch {
		// Do nothing
	}
}

/**
 * Returns an object with two functions, one to prefetch a single model by id,
 * and one to prefetch a list of models.
 *
 * For ex., if the model name is 'column', the returned object will have two
 * functions, `prefetchColumn` and `prefetchColumnList`.
 *
 * @param modelName The name of the model, e.g. 'column'
 * @param namespace The namespace of the model, e.g. ['table', 'columns']
 * @param queryKeyFactory The query key factory for the model
 * @returns An object with two functions, one to prefetch a single model by id,
 * and one to prefetch a list of models
 */
export const prefetchFunctionsFactory = <TModelName extends string>(
	modelName: TModelName,
	queryKeyFactory: QueryKeyFactory
) => {
	const name = upperFirst(camelCase(modelName));

	const prefetch = `prefetch${name}`;
	const prefetchList = `prefetch${name}List`;

	return {
		/**
		 * Prefetches a single model and stores it in the query cache.
		 * This significantly reduces the time it takes to load the page,
		 * since the data is already in the cache.
		 *
		 * @param id The id of the model to prefetch
		 */
		[prefetch]: async (id: string) => {
			await queryClient.prefetchQuery({
				queryKey: queryKeyFactory.byId(id),
				queryFn: getDefaultQueryFn(id, queryKeyFactory.namespace),
			});
		},
		/**
		 * Prefetches a list of models and stores them in the query cache.
		 * This significantly reduces the time it takes to load the page,
		 * since the data is already in the cache.
		 *
		 * @param page Page number or array of page numbers to prefetch
		 * @param filters Filters to apply to the list query
		 * @param allPages If true, prefetches all pages of the list (This slows down the prefetching process)
		 */
		[prefetchList]: async (args: PrefetchListFunctionArgs) => {
			const { page = 1, filters = {}, allPages = false } = args || {};

			if (allPages) {
				// eslint-disable-next-line no-restricted-syntax
				for await (const _ of fetchAllPages(queryKeyFactory, filters)) {
					// Do nothing
				}
				return;
			}

			if (isArray(page)) {
				forEach(page, (pageNumber) => {
					queryClient.prefetchQuery({
						queryKey: queryKeyFactory.list(pageNumber, filters),
						queryFn: getDefaultListQueryFn(
							{ page: pageNumber, filters },
							queryKeyFactory.namespace
						),
					});
				});
				return;
			}

			await queryClient.prefetchQuery({
				queryKey: queryKeyFactory.list(page, filters),
				queryFn: getDefaultListQueryFn(
					{ page, filters },
					queryKeyFactory.namespace
				),
			});
		},
	} as PrefetchFunctionsFactory<TModelName>;
};
