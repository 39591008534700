/* eslint-disable react-hooks/rules-of-hooks */
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { FilterValue } from '../FilterCarousel.constants';
import FilterDropdown from '../FilterDropdown';
import RelatedFilter from '../RelatedFilter';
import { SearchFilterStoreContext } from '../store';

interface IAddFilterDropdownProps {
	onClose: () => void;
}

function AddFilterDropdown({ onClose }: IAddFilterDropdownProps) {
	const searchFilterStore = useContext(SearchFilterStoreContext);

	const { focusedFilter } = searchFilterStore;
	if (isNil(focusedFilter)) {
		return null;
	}

	const filterMenuItem = searchFilterStore.searchFilters[focusedFilter];

	// Render custom RelatedFilter component
	if (filterMenuItem.value === FilterValue.RELATED) {
		return (
			<RelatedFilter
				onDeleteFilter={() =>
					searchFilterStore.deleteFilter(FilterValue.RELATED)
				}
			/>
		);
	}

	const handleIsIncludeChange = (value: boolean) => {
		searchFilterStore.setSelectedFilterIsInclude(filterMenuItem.value, value);
	};

	return (
		<FilterDropdown
			filterMenuItem={filterMenuItem}
			handleIsIncludeChange={handleIsIncludeChange}
			onClose={onClose}
		/>
	);
}

export default observer(AddFilterDropdown);
