import { uniqBy } from 'lodash-es';
import type { IBaseModel } from '../../api';

export const makeRecordsExpandable = <
	T extends IBaseModel & { has_child_of_same_type?: boolean },
>(
	records: T[],
	expandedRecords: T[],
	paramExpandedLevel: number,
	setExpandedRecords: (arg0: (prev: T[]) => T[]) => void
) =>
	uniqBy(
		records.map((record) => ({
			...record,
			paramExpandedLevel,
			paramExpanded: expandedRecords.some(
				(expandedRecord) => expandedRecord.id === record.id
			),
			onExpandClick: record.has_child_of_same_type
				? () => {
						setExpandedRecords((prev) =>
							prev.some((prevRecord) => prevRecord.id === record.id)
								? prev.filter((prevRecord) => prevRecord.id !== record.id)
								: [...prev, record]
						);
					}
				: undefined,
		})),
		'id'
	) as T[];
