import { Group, Stack, createStyles } from '@mantine/core';
import { ListBox, Text } from '@repo/foundations';
import { range } from 'lodash-es';
import { useMemo } from 'react';
import { FILTER_OPTIONS_DIVIDER } from '../constants';
import {
	FilterOptionType,
	type FilterOption,
	type FilterValue,
} from '../types';
import { useFilterDropdownAI } from '../FilterDropdown/useFilterDropdownAI';
import { FilterItemError } from '../FilterItemError';
import { FilterItemSkeleton } from '../FilterItemSkeleton';
import { FilterItemEmpty } from '../FilterItemEmpty';
import { filterExcessDividers } from '../utils';
import { useNestedSearch } from './useNestedSearch';
import { NestedFilterMenuItem } from './NestedFilterMenuItem';
import { AIFilterItem } from './AIFilter/AIFilterItem';

const useStyles = createStyles((theme) => ({
	menuItem: {
		padding: theme.spacing['2xs'],
		margin: `0 ${theme.spacing['2xs']}`,
		width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
	},
}));

interface AddFilterDropdownProps {
	options: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
	onItemClick: (option: FilterOption) => void;
	onAddFilter: (value: FilterValue, close: boolean) => void;
}

export function AddFilterDropdown({
	options,
	onItemClick,
	onAddFilter,
}: AddFilterDropdownProps) {
	const { classes } = useStyles();
	const filteredOptions = useMemo(
		() => filterExcessDividers(options),
		[options]
	);
	const { items, searchTerm, isEmptyResults, setSearchTerm, error, loading } =
		useNestedSearch({ options: filteredOptions });

	const {
		error: aiError,
		loading: aiLoading,
		askAi,
	} = useFilterDropdownAI({
		onChange: onAddFilter,
	});

	return (
		<ListBox.ItemsDropdown
			search={{
				onChange: setSearchTerm,
				value: searchTerm,
				placeholder: 'Filter by...',
			}}
			items={items}
			renderItem={(item, getProps, idx) => {
				if (item === FILTER_OPTIONS_DIVIDER) {
					return <ListBox.Divider key={`divider-${idx}`} />;
				}

				if ('option' in item) {
					return (
						<NestedFilterMenuItem
							key={`${item.option.type}-${item.item.value}`}
							item={item.item}
							option={item.option}
							onClick={(close) =>
								onAddFilter(
									{
										operator: item.option.filterDropdownConfig.defaultOperator,
										filterType: item.option.type,
										value: item.item.value,
									},
									close
								)
							}
							className={classes.menuItem}
							getProps={getProps}
						/>
					);
				} else if (item.type === FilterOptionType.AI) {
					return (
						<AIFilterItem
							option={item}
							getProps={getProps}
							className={classes.menuItem}
							onClick={() =>
								searchTerm ? askAi(searchTerm) : onItemClick(item)
							}
							searchTerm={searchTerm}
							error={!!aiError}
							loading={aiLoading}
						/>
					);
				} else {
					return (
						<ListBox.Item
							key={item.type}
							{...getProps({
								onClick: () => onItemClick(item),
								className: classes.menuItem,
							})}
						>
							<Group
								data-testid={`filter-button-${item.label.toLowerCase()}`}
								spacing="2xs"
							>
								{item.icon}
								<Text size="sm">{item.label}</Text>
							</Group>
						</ListBox.Item>
					);
				}
			}}
		>
			{loading && (
				<Stack spacing="2xs" pt={items.length > 0 ? '2xs' : undefined}>
					{range(3).map((idx) => (
						<FilterItemSkeleton key={idx} />
					))}
				</Stack>
			)}
			{error && <FilterItemError>An error has ocurred</FilterItemError>}
			{isEmptyResults && <FilterItemEmpty>No filters found</FilterItemEmpty>}
		</ListBox.ItemsDropdown>
	);
}
