import { useContext, useEffect, useMemo } from 'react';
import type {
	FilterOptionType,
	FilterValue,
} from '../../../../components/Filter/types';
import { SearchFilterV2StoreContext } from '../../../../components/Filter';
import { FILTER_OPTIONS_CONFIG } from '../../../../components/Filter/constants';

interface UseFilterBarProps {
	fixedFilterOptions?: FilterOptionType[];
}

export function useFilterBar({ fixedFilterOptions }: UseFilterBarProps) {
	const store = useContext(SearchFilterV2StoreContext);

	useEffect(() => {
		store.prefetchPromises();
	}, [store]);

	const valuesToShow: FilterValue[] = useMemo(() => {
		if (!fixedFilterOptions) {
			return store.values;
		}

		const missingOptions = fixedFilterOptions.filter(
			(optionType) =>
				!store.values.some((value) => value.filterType === optionType)
		);

		return [
			...store.values,
			...missingOptions.map(
				(filterType) =>
					({
						filterType,
						operator:
							FILTER_OPTIONS_CONFIG[filterType].filterDropdownConfig
								.defaultOperator,
						value: null,
					}) as FilterValue
			),
		];
	}, [fixedFilterOptions, store.values]);

	return {
		store,
		values: valuesToShow,
	};
}
