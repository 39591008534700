import { useCallback, useEffect, useState } from 'react';
import { useAIEditorSummary } from '../../../../../../api/hooks/ai/useAIEditorSummary';
import { RichMarkdownEditor } from '../..';

interface UseAISummaryProps {
	editor: RichMarkdownEditor;
	initialSummary?: string;
	onShowToast: (message: string, type: 'error' | 'info') => void;
}

export function useAISummary({
	editor,
	initialSummary,
	onShowToast,
}: UseAISummaryProps) {
	let ignore = false;

	const [summary, setSummary] = useState(initialSummary ?? '');
	const { mutateAsync, isLoading } = useAIEditorSummary({});

	const generate = useCallback(async () => {
		try {
			const document = editor.serializer.serialize(editor.view.state.doc);
			const response = await mutateAsync({ content: document });

			if (ignore) {
				return;
			}

			setSummary(response.content);
		} catch (error) {
			onShowToast('Error creating summary', 'error');
		}
	}, [document]);

	useEffect(() => {
		if (!initialSummary) {
			generate();
		}

		return () => {
			ignore = true;
		};
	}, [document]);

	return {
		summary,
		generate,
		isLoading,
	};
}
