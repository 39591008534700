import { TextInput, Stack, useMantineTheme } from '@mantine/core';
import { isEmpty } from 'lodash-es';
import { useContext } from 'react';
import { Text } from '@repo/foundations';
import { Button } from '@repo/foundations';
import { AuthStepStartContext } from './AuthStepStartContext';

const REGEX_EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;

interface AuthStepEmailComponentProps {
	cta: string;
}

export function AuthStepEmailComponent({ cta }: AuthStepEmailComponentProps) {
	const theme = useMantineTheme();

	const context = useContext(AuthStepStartContext);
	if (!context) {
		throw new Error(
			'AuthStepEmailComponent must be used within a AuthStepStartContextProvider'
		);
	}
	const {
		emailAuthLoading,
		startEmailAuth,
		forceSSO,
		email,
		setEmail,
		error,
		setError,
	} = context;

	const handleClick = () => {
		setError(null);
		if (email && !isEmpty(email) && email.match(REGEX_EMAIL)) {
			startEmailAuth();
		} else {
			setError('Please enter a valid email address');
		}
	};

	return (
		<Stack pt={theme.other.space[2]}>
			{!forceSSO && (
				<>
					<TextInput
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleClick();
							}
						}}
						onChange={(e) => {
							setError(null);
							setEmail(e.currentTarget.value);
						}}
						value={email}
						type="email"
						label="Work email"
						error={!!error}
						styles={{ input: { borderRadius: theme.other.space[2] } }}
					/>
					<Button
						onClick={handleClick}
						w="100%"
						size="lg"
						loading={emailAuthLoading}
					>
						{cta}
					</Button>
				</>
			)}
			{error && (
				<Text weight="semibold" size="xs" color="text/critical/default">
					{error}
				</Text>
			)}
		</Stack>
	);
}
