import {
	ActionIcon,
	createStyles,
	Group,
	Loader,
	ScrollArea,
	Stack,
	TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {
	IconChevronLeft,
	IconChevronRight,
	IconSearch,
} from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Text } from '@repo/foundations';
import type { UseAddMonitorItemsArgs } from '../../api';
import { useAddMonitorItems } from '../../api';
import { formatNumber } from '../../utils/numberUtils';
import { useAddMonitorStoreContext } from './context';
import MultipleSelectorItem from './MultipleSelectorItem';

interface MultipleItemsSelectorProps {
	type: UseAddMonitorItemsArgs['type'];
}

const useStyles = createStyles((theme) => ({
	root: {
		border: 'thin solid',
		borderColor: theme.other.getColor('border/primary/default'),
		borderRadius: theme.radius.sm,
	},
	searchInput: {
		border: 'none',
		borderBottom: 'thin solid',
		borderColor: theme.other.getColor('border/primary/default'),
		borderRadius: theme.radius.sm,
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
		backgroundColor: theme.other.getColor('surface/input/default'),
		'&:focus, &:active': {
			border: 'none',
			outline: 'none',
		},
	},
	pagination: {
		borderTop: 'thin solid',
		borderColor: theme.other.getColor('border/inverse/active'),
		backgroundColor: theme.other.getColor('surface/input/default'),
		borderRadius: theme.radius.sm,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
	},
}));

function MultipleItemsSelector({ type }: MultipleItemsSelectorProps) {
	const { classes, theme } = useStyles();
	const store = useAddMonitorStoreContext();
	const [title] = useDebouncedValue(store.searchTerm, 500);

	const { isLoading, data } = useAddMonitorItems({
		id: store.id,
		metricType: store.getFormValue('metricType'),
		type: store.type,
		page: store.page,
		title,
	});

	useEffect(() => {
		store.setType(type);
	}, [type]);

	const items = data?.results ?? [];

	const handleCheck = (id: string, checked: boolean) => {
		if (checked) {
			store.addToSelected(id);
			return;
		}

		store.removeFromSelected(id);
	};

	const disablePreviousPage = data?.links.previous === null;

	const disableNextPage = data?.links.next === null;

	const firstItemIndex = data?.count
		? formatNumber((store.page - 1) * 50 + 1)
		: '0';
	const totalCount = formatNumber(data?.count ?? 0);
	const lastItemOnPage = store.page * 50;
	const lastItemIndex =
		lastItemOnPage > +totalCount ? totalCount : formatNumber(lastItemOnPage);

	const handleItemChecked = (id: string) => (checked: boolean) => {
		handleCheck(id, checked);
	};

	return (
		<Stack className={classes.root} spacing="none">
			<TextInput
				classNames={{
					input: classes.searchInput,
				}}
				placeholder="Search..."
				icon={<IconSearch size="1rem" />}
				readOnly={isLoading}
				value={store.searchTerm}
				onChange={(e) => store.setSearchTerm(e.currentTarget.value)}
			/>
			<ScrollArea h={theme.other.space[80]}>
				{items?.map((item) => (
					<MultipleSelectorItem
						type={type}
						key={item.id}
						item={item}
						isChecked={
							Boolean(item.monitor) ||
							store.getFormValue('selected').includes(item.id)
						}
						onChange={handleItemChecked(item.id)}
					/>
				))}
			</ScrollArea>
			<Group p="sm" py="xs" className={classes.pagination} position="apart">
				{isLoading ? (
					<Loader size="sm" />
				) : (
					<Text size="xs" color="text/secondary/default">
						Showing {firstItemIndex} to {lastItemIndex} of {totalCount}
					</Text>
				)}
				<Group spacing="xs">
					<ActionIcon
						size="xs"
						disabled={disablePreviousPage}
						onClick={store.previousPage}
					>
						<IconChevronLeft size="2rem" />
					</ActionIcon>
					<ActionIcon
						size="xs"
						disabled={disableNextPage}
						onClick={store.nextPage}
					>
						<IconChevronRight size="2rem" />
					</ActionIcon>
				</Group>
			</Group>
		</Stack>
	);
}

export default observer(MultipleItemsSelector);
