import { Skeleton } from '@mantine/core';
import { Suspense } from 'react';

import { MetricContainer } from './MetricContainer';

interface ChartBlockContainerProps {
	id: string;
	readOnly: boolean;
}

export function ChartBlockContainer({
	id,
	readOnly,
}: ChartBlockContainerProps) {
	return (
		<Suspense fallback={<Skeleton height={300} />}>
			<MetricContainer
				id={id}
				readOnly={readOnly}
				withQuery={false}
				withChart
			/>
		</Suspense>
	);
}
