import { Badge, createStyles } from '@mantine/core';
import { useState } from 'react';
import { Monitor } from '../../../api';
import { useNavigate } from '../../../hooks/useNavigate';
import { MONITOR_ICON_MAPPING } from '../constants';
import { getMonitorStatusIcon } from '../utils';
import { useIncidentDrawer } from '../v2/IncidentDrawerContext';
import MonitorErrorDrawer from './MonitorErrorDrawer';

interface IMonitorStatusBadgeProps {
	monitor?: Monitor;
}

const useStyles = createStyles(() => ({
	badgeClickable: {
		cursor: 'pointer',
	},
}));

function MonitorStatusBadge({ monitor }: IMonitorStatusBadgeProps) {
	const [errorDrawerOpen, setErrorDrawerOpen] = useState<boolean>(false);
	const navigate = useNavigate();
	const { classes, cx } = useStyles();

	const { openIncident } = useIncidentDrawer();

	if (!monitor) {
		return null;
	}

	const toggleErrorDrawer = () => {
		setErrorDrawerOpen((prevState) => !prevState);
	};

	const handleStatusBadgeClick = () => {
		if (monitor.status === 'error') {
			toggleErrorDrawer();
		} else if (monitor.status === 'incident' && monitor.last_incident) {
			openIncident(monitor.last_incident);
		}
	};

	const leftSectionIcon = getMonitorStatusIcon(monitor.status || 'pending');

	const badgeColor = {
		pending: 'cyan',
		error: 'red',
		incident: 'orange',
		normal: 'green',
		learning: 'yellow',
		inactive: 'gray',
	}[monitor.status];

	const badgeClassName = cx({
		[classes.badgeClickable]:
			monitor.status === 'error' || monitor.status === 'incident',
	});

	const label = MONITOR_ICON_MAPPING[monitor.status || 'pending'].name;

	return (
		<>
			<Badge
				size="xl"
				className={badgeClassName}
				leftSection={leftSectionIcon}
				color={badgeColor}
				onClick={handleStatusBadgeClick}
				my={0}
			>
				{label}
			</Badge>
			<MonitorErrorDrawer
				monitor={monitor}
				open={errorDrawerOpen}
				onClose={toggleErrorDrawer}
			/>
		</>
	);
}

export default MonitorStatusBadge;
