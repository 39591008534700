import { Badge, Center, createStyles } from '@mantine/core';
import type { ITag } from '../../../../api';
import { primaryTextColor, primaryTextSize, primaryTextWeight } from '../theme';
import TagIcon from '../../../TagIcon';

interface ITagChipProps {
	tag: ITag;
}

const useStyles = createStyles((theme) => ({
	badge: {
		backgroundColor: theme.other.getColor('fill/transparent-secondary/default'),
		padding: '0 8px',
		height: 20,
	},
	label: {
		fontSize: primaryTextSize,
		color: primaryTextColor,
		fontWeight: primaryTextWeight,
		textTransform: 'none',
	},
}));

function TagChip({ tag }: ITagChipProps) {
	const { classes } = useStyles();

	const tagIcon =
		tag.id === 'empty' ? null : (
			<Center>
				<TagIcon color={tag.color} />
			</Center>
		);

	return (
		<Badge
			classNames={{
				root: classes.badge,
				inner: classes.label,
			}}
			leftSection={tagIcon}
		>
			{tag.name}
		</Badge>
	);
}

export default TagChip;
