import { Group, createStyles } from '@mantine/core';
import { TOP_NAVBAR_HEIGHT } from '../constants';

export const useStyles = createStyles((theme) => ({
	wrapper: {
		width: '100%',
		height: TOP_NAVBAR_HEIGHT,
		paddingTop: theme.spacing.sm,
		paddingBottom: theme.spacing.sm,
		paddingLeft: theme.spacing.xl,
		paddingRight: theme.spacing.xl,
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
		gap: 0,

		'@media print': {
			display: 'none',
		},
	},
}));

interface NavBarWrapperProps {
	children: React.ReactNode;
}

export function NavBarWrapper({ children }: NavBarWrapperProps) {
	const { classes } = useStyles();

	return <Group className={classes.wrapper}>{children}</Group>;
}
