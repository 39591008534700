import { InputRule } from 'prosemirror-inputrules';
import type {
	Attrs,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { NodeSelection, TextSelection } from 'prosemirror-state';
import { Resizable } from 're-resizable';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@repo/foundations';
import { ParseSpec } from 'prosemirror-markdown';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import insertFiles from '../commands/insertFiles';
import uploadPlaceholderPlugin from '../lib/uploadPlaceholder';
import type { ComponentProps, Dispatch } from '../types';
import { extractImageSizesFromSrc } from '../utils/image';
import { uploadPlugin } from '../lib/uploadPlugin';
import { getEventFiles } from '../utils/files';
import ReactNode from './ReactNode';
import { NodeOptions } from './Node';
/**
 * Matches following attributes in Markdown-typed image: [, alt, src, class]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "class") -> [, "", "image.jpg", "small"]
 * ![Lorem](image.jpg "class") -> [, "Lorem", "image.jpg", "small"]
 */
const IMAGE_INPUT_REGEX =
	/!\[(?<alt>[^\]\[]*?)]\((?<filename>[^\]\[]*?)(?=\“|\))\“?(?<layoutclass>[^\]\[\”]+)?\”?\)$/;

const IMAGE_CLASSES = ['right-50', 'left-50'];

const getLayoutAndTitle = (tokenTitle: string) => {
	if (!tokenTitle) return {};
	if (IMAGE_CLASSES.includes(tokenTitle)) {
		return {
			layoutClass: tokenTitle,
		};
	}
	return {
		title: tokenTitle,
	};
};

const downloadImageNode = async (node: ProsemirrorNode) => {
	const image = await fetch(node.attrs.src);
	const imageBlob = await image.blob();
	const imageURL = URL.createObjectURL(imageBlob);
	const extension = imageBlob.type.split('/')[1];
	const potentialName = node.attrs.alt || 'image';
	// Create a temporary link node and click it with our image data
	const link = document.createElement('a');
	link.href = imageURL;
	link.download = `${potentialName}.${extension}`;
	document.body.appendChild(link);
	link.click();
	// Cleanup
	document.body.removeChild(link);
};

interface ImageBoxProps {
	src: string;
	alt: string;
	title: string;
	node: ProsemirrorNode;
	handleResize: ({
		node,
		size,
	}: {
		node: ProsemirrorNode;
		size: {
			width: number | string;
			height: number | string;
		};
	}) => boolean;
	readOnly?: boolean;
	defaultWidth: number;
	defaultHeight: number;
}

function ImageBox({
	src,
	alt,
	title,
	node,
	handleResize,
	readOnly,
	defaultWidth,
	defaultHeight,
}: ImageBoxProps) {
	const [width, setWidth] = useState(defaultWidth ?? 250);
	const [height, setHeight] = useState(defaultHeight ?? 0);
	const ref = useRef<HTMLImageElement>(null);
	return (
		<Resizable
			size={{ width, height: height || 'auto' }}
			lockAspectRatio
			enable={{
				top: !readOnly,
				right: !readOnly,
				bottom: !readOnly,
				left: !readOnly,
				topRight: !readOnly,
				bottomRight: !readOnly,
				bottomLeft: !readOnly,
				topLeft: !readOnly,
			}}
			onResizeStop={(e, direction, resizeRef) => {
				if (readOnly) return;
				setWidth(resizeRef.clientWidth);
				setHeight(resizeRef.clientHeight);
				handleResize({
					node,
					size: {
						width: resizeRef.clientWidth,
						height: resizeRef.clientHeight,
					},
				});
			}}
		>
			<img
				ref={ref}
				src={src}
				alt={alt}
				title={title}
				style={{
					userSelect: 'none',
					pointerEvents: 'none',
					MozWindowDragging: 'no-drag',
					height: '100%',
					width: '100%',
				}}
			/>
		</Resizable>
	);
}

export default class Image extends ReactNode {
	get name() {
		return 'image';
	}

	get schema(): NodeSpec {
		return {
			inline: true,
			attrs: {
				src: {},
				alt: {
					default: null,
				},
				layoutClass: {
					default: null,
				},
				title: {
					default: null,
				},
				width: {
					default: null,
				},
				height: {
					default: null,
				},
			},
			content: 'text*',
			group: 'inline',
			selectable: true,
			draggable: false,
			parseDOM: [
				{
					tag: 'div[class~=image]',
					getAttrs: (dom: HTMLElement | string) => {
						if (typeof dom === 'string') {
							return null;
						}

						const img = dom.getElementsByTagName('img')[0];
						const { className } = dom;
						const layoutClassMatched =
							className && className.match(/image-(.*)$/);
						const layoutClass = layoutClassMatched
							? layoutClassMatched[1]
							: null;
						return {
							src: img?.getAttribute('src'),
							alt: img?.getAttribute('alt'),
							title: img?.getAttribute('title'),
							width: img?.getAttribute('width'),
							height: img?.getAttribute('height'),
							layoutClass,
						};
					},
				},
				{
					tag: 'img',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									src: dom.getAttribute('src'),
									alt: dom.getAttribute('alt'),
									title: dom.getAttribute('title'),
									width: dom.getAttribute('width'),
									height: dom.getAttribute('height'),
								},
				},
			],
			toDOM: (node) => {
				const className = node.attrs.layoutClass
					? `image image-${node.attrs.layoutClass}`
					: 'image';
				return [
					'div',
					{
						class: className,
					},
					[
						'img',
						{
							...node.attrs,
							style: {
								// TODO(marcio): this is wrong - this renders as `style="[object Object]"` in the DOM
								width: node.attrs.width,
								height: node.attrs.height,
							},
							contentEditable: false,
						},
					],
					['p', { class: 'caption' }, 0],
				];
			},
		};
	}

	handleKeyDown =
		({ node, getPos }: ComponentProps) =>
		(event: React.KeyboardEvent<HTMLParagraphElement>) => {
			// Pressing Enter in the caption field should move the cursor/selection
			// below the image
			if (event.key === 'Enter') {
				event.preventDefault();
				const { view } = this.editorState;
				const $pos = view.state.doc.resolve(getPos() + node.nodeSize);
				view.dispatch(
					view.state.tr.setSelection(new TextSelection($pos)).split($pos.pos)
				);
				view.focus();
				return;
			}
			// Pressing Backspace in an an empty caption field should remove the entire
			// image, leaving an empty paragraph
			if (event.key === 'Backspace' && event.currentTarget.innerText === '') {
				const { view } = this.editorState;
				const $pos = view.state.doc.resolve(getPos());
				const tr = view.state.tr.setSelection(new NodeSelection($pos));
				view.dispatch(tr.deleteSelection());
				view.focus();
			}
		};

	handleBlur =
		({ node, getPos }: ComponentProps) =>
		(event: React.FocusEvent<HTMLParagraphElement>) => {
			const alt = event.target.innerText;
			const { src, title, layoutClass } = node.attrs;
			if (alt === node.attrs.alt) return;
			const { view } = this.editorState;
			const { tr } = view.state;
			// Update meta on object
			const pos = getPos();
			const transaction = tr.setNodeMarkup(pos, undefined, {
				src,
				alt,
				title,
				layoutClass,
			});
			view.dispatch(transaction);
		};

	handleSelect =
		({ getPos }: ComponentProps) =>
		(event: React.MouseEvent) => {
			event.preventDefault();
			const { view } = this.editorState;
			const $pos = view.state.doc.resolve(getPos());
			const transaction = view.state.tr.setSelection(new NodeSelection($pos));
			view.dispatch(transaction);
		};

	handleDownload =
		({ node }: ComponentProps) =>
		(event: React.MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			downloadImageNode(node);
		};

	handleResize = ({
		node,
		size,
	}: {
		node: ProsemirrorNode;
		size: {
			width: number | string;
			height: number | string;
		};
	}) => {
		const {
			view: { dispatch, state },
		} = this.editorState;
		const nodeAttrs = node?.attrs || {};
		const attrs = {
			...((state.selection as NodeSelection)?.node?.attrs ?? {}),
			...nodeAttrs,
			title: null,
			width: size.width,
			height: size.height,
		};
		const { selection } = state;
		dispatch(state.tr.setNodeMarkup(selection.from, undefined, attrs));
		return true;
	};

	component = (props: ComponentProps) => {
		const { isSelected } = props;
		const { alt, src, title, layoutClass, width, height } = props.node.attrs;
		const className = layoutClass ? `image image-${layoutClass}` : 'image';
		return (
			<div contentEditable={false} className={className}>
				<ImageWrapper
					className={isSelected ? 'ProseMirror-selectednode' : ''}
					onClick={this.handleSelect(props)}
				>
					<Button onClick={this.handleDownload(props)}>
						<Icon name="download" />
					</Button>
					<ImageBox
						src={src}
						alt={alt}
						title={title}
						node={props.node}
						readOnly={this.editorState.readOnly}
						defaultWidth={width}
						defaultHeight={height}
						handleResize={this.handleResize}
					/>
				</ImageWrapper>
				<Caption
					onKeyDown={this.handleKeyDown(props)}
					onBlur={this.handleBlur(props)}
					className="caption"
					tabIndex={-1}
					role="textbox"
					contentEditable
					suppressContentEditableWarning
					data-caption={this.options.dictionary.imageCaptionPlaceholder}
				>
					{alt}
				</Caption>
			</div>
		);
	};

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		let markdown = ` ![${state.esc(
			(node.attrs.alt || '').replace('\n', '') || '',
			false
		)}](${state.esc(node.attrs.src, false)}`;
		if (node.attrs.width) {
			markdown += `=${node.attrs.width > 25 ? node.attrs.width : 250}x`;
			markdown += `${node.attrs.height > 25 ? node.attrs.height : 250 ?? ''}`;
		}
		if (node.attrs.layoutClass) {
			markdown += ` "${state.esc(node.attrs.layoutClass)}"`;
		} else if (node.attrs.title) {
			markdown += ` "${state.esc(node.attrs.title)}"`;
		}
		markdown += ')';
		// Do not add a newline – this will break embedding images in tables, since
		// the newline will be parsed as a new cell, and a double escape is
		// required, `\\\n`.
		state.write(markdown);
		state.closeBlock(node);
	}

	parseMarkdown(): ParseSpec {
		return {
			node: 'image',
			getAttrs: (token) => {
				let src = token.attrGet('src') ?? '';

				const { width, height } = extractImageSizesFromSrc(src);
				src = src.replace(/=.+$/, '');
				return {
					src,
					width,
					height,
					alt:
						(token?.children &&
							token.children[0] &&
							token.children[0].content) ||
						null,
					...getLayoutAndTitle(token.attrGet('title') ?? ''),
				};
			},
		};
	}

	commands({ type }: NodeOptions) {
		return {
			downloadImage: () => (state: EditorState) => {
				if (!(state.selection instanceof NodeSelection)) {
					return false;
				}
				const { node } = state.selection;
				if (node.type.name !== 'image') {
					return false;
				}
				downloadImageNode(node);
				return true;
			},
			deleteImage: () => (state: EditorState, dispatch: Dispatch) => {
				dispatch(state.tr.deleteSelection());
				return true;
			},
			alignRight: () => (state: EditorState, dispatch: Dispatch) => {
				if (!(state.selection instanceof NodeSelection)) {
					return false;
				}
				const attrs = {
					...(state.selection.node?.attrs ?? {}),
					title: null,
					layoutClass: 'right-50',
				};
				const { selection } = state;
				dispatch(state.tr.setNodeMarkup(selection.from, undefined, attrs));
				return true;
			},
			alignLeft: () => (state: EditorState, dispatch: Dispatch) => {
				if (!(state.selection instanceof NodeSelection)) {
					return false;
				}
				const attrs = {
					...(state.selection.node?.attrs ?? {}),
					title: null,
					layoutClass: 'left-50',
				};
				const { selection } = state;
				dispatch(state.tr.setNodeMarkup(selection.from, undefined, attrs));
				return true;
			},
			alignFullWidth: () => (state: EditorState, dispatch: Dispatch) => {
				if (!(state.selection instanceof NodeSelection)) {
					return false;
				}
				const attrs = {
					...(state.selection.node?.attrs ?? {}),
					title: null,
					layoutClass: 'full-width',
				};
				const { selection } = state;
				dispatch(state.tr.setNodeMarkup(selection.from, undefined, attrs));
				return true;
			},
			replaceImage: () => (state: EditorState) => {
				if (!(state.selection instanceof NodeSelection)) {
					return false;
				}
				const { view } = this.editorState;
				const { node } = state.selection;
				const { uploadFile, onFileUploadStart, onFileUploadStop, onShowToast } =
					this.editorState;
				if (!uploadFile) {
					throw new Error('uploadFile prop is required to replace images');
				}
				if (node.type.name !== 'image') {
					return false;
				}
				// Create an input element and click to trigger picker
				const inputElement = document.createElement('input');
				inputElement.type = 'file';
				inputElement.accept = 'image/*';
				inputElement.onchange = (event: Event) => {
					const files = getEventFiles(event);
					insertFiles(view, event, state.selection.from, files, {
						uploadFile,
						onFileUploadStart,
						onFileUploadStop,
						onShowToast,
						dictionary: this.options.dictionary,
						replaceExisting: true,
					});
				};
				inputElement.click();
				return true;
			},
			alignCenter: () => (state: EditorState, dispatch: Dispatch) => {
				if (!(state.selection instanceof NodeSelection)) {
					return false;
				}
				const attrs = {
					...(state.selection.node?.attrs ?? {}),
					layoutClass: null,
				};
				const { selection } = state;
				dispatch(state.tr.setNodeMarkup(selection.from, undefined, attrs));
				return true;
			},
			createImage:
				(attrs?: Attrs) => (state: EditorState, dispatch: Dispatch) => {
					const { selection } = state;
					const position =
						selection instanceof TextSelection
							? selection.$cursor?.pos
							: selection.$to.pos;
					if (position === undefined) {
						return false;
					}
					const node = type.create(attrs);
					const transaction = state.tr.insert(position, node);
					dispatch(transaction);
					return true;
				},
		};
	}

	inputRules({ type }: NodeOptions) {
		return [
			new InputRule(IMAGE_INPUT_REGEX, (state, match, start, end) => {
				const [okay, alt, src, matchedTitle] = match;
				const { tr } = state;
				if (okay) {
					tr.replaceWith(
						start - 1,
						end,
						type.create({
							src,
							alt,
							...getLayoutAndTitle(matchedTitle),
						})
					);
				}
				return tr;
			}),
		];
	}

	get plugins() {
		return [uploadPlaceholderPlugin, uploadPlugin(this.options)];
	}
}

const Button = styled.button`
	position: absolute;
	top: 8px;
	right: 8px;
	border: 0;
	margin: 0;
	padding: 0;
	border-radius: 4px;
	background: ${(props) => props.theme.background};
	color: ${(props) => props.theme.textSecondary};
	width: 24px;
	height: 24px;
	display: inline-block;
	cursor: pointer;
	opacity: 0;
	transition: opacity 100ms ease-in-out;
	&:active {
		transform: scale(0.98);
	}
	&:hover {
		color: ${(props) => props.theme.text};
		opacity: 1;
	}
`;
const Caption = styled.p`
	border: 0;
	display: block;
	font-size: 13px;
	font-style: italic;
	font-weight: normal;
	color: ${(props) => props.theme.textSecondary};
	padding: 2px 0;
	line-height: 16px;
	text-align: center;
	min-height: 1em;
	outline: none;
	background: none;
	resize: none;
	user-select: text;
	cursor: text;
	&:empty:not(:focus) {
		visibility: hidden;
	}
	&:empty:before {
		color: ${(props) => props.theme.placeholder};
		content: attr(data-caption);
		pointer-events: none;
	}
`;
const ImageWrapper = styled.span`
	line-height: 0;
	display: inline-block;
	position: relative;
	&:hover {
		${Button} {
			opacity: 0.9;
		}
	}
	&.ProseMirror-selectednode + ${Caption} {
		visibility: visible;
	}
`;
