import { Menu, createStyles, Center, Loader, TextInput } from '@mantine/core';

import { showNotification } from '@mantine/notifications';
import { size } from 'lodash-es';
import { Virtuoso } from 'react-virtuoso';
import { useInputState, useDebouncedValue } from '@mantine/hooks';
import { useMemo, useRef } from 'react';
import { IconButton } from '@repo/foundations';
import type { ISecodaEntity, SearchResult } from '../../../../api';
import { useMention } from '../../../../api';
import type { SecodaEntity } from '../../../../lib/models';
import {
	invalidateResourceRelationList,
	useCreateResourceRelation,
} from '../../../../api/hooks/relations';
import SearchResultItem from '../../../MultiResourceSelector/SearchResultItem';
import { EntityType } from '../../../../lib/types';
import type { ButtonDetails } from '../../../EmptyState';
import { EmptyState } from '../../../EmptyState';

interface IRelatedResourceCreateMenuProps {
	entity: SecodaEntity | ISecodaEntity;
	relatedEntityIds: string[];
}

const useStyles = createStyles((theme) => ({
	textInput: {
		color: theme.other.getColor('text/secondary/default'),
		backgroundColor: theme.other.getColor('surface/input/default'),
		marginBottom: theme.spacing['2xs'],
		borderRadius: 0,
		borderTopLeftRadius: theme.other.borderRadius.md,
		borderTopRightRadius: theme.other.borderRadius.md,
		border: 'none',
		borderBottom: `${theme.other.borderWidth.sm} solid ${theme.other.getColor('border/primary/default')}`,
	},
	target: {
		width: '100%',
	},
}));

export function RelatedResourceCreateMenu({
	entity,
	relatedEntityIds,
}: IRelatedResourceCreateMenuProps) {
	const { classes, theme } = useStyles();

	const [searchTerm, setSearchTerm] = useInputState('');
	const [debounced] = useDebouncedValue(searchTerm, 300);

	const inputRef = useRef<HTMLInputElement>(null);

	const { isFetching, data } = useMention({
		searchTerm: debounced,
		filters: {
			operator: 'not',
			operands: [
				{
					operator: 'or',
					operands: [
						{
							operands: [],
							operator: 'in',
							field: 'id',
							value: [...relatedEntityIds, entity.id],
						},
						{
							operands: [],
							operator: 'in',
							field: 'entity_type',
							value: [
								EntityType.user,
								EntityType.user_group,
								EntityType.question_reply,
								EntityType.event_property,
								EntityType.collection,
							],
						},
					],
				},
			],
		},
		options: {
			select: (data) => data.results,
		},
	});

	const { mutateAsync: createResourceRelation } = useCreateResourceRelation({
		options: {
			onSuccess: () => {
				showNotification({
					message: 'Resource relation created',
					color: 'green',
				});
				invalidateResourceRelationList(entity.id);
			},
			onError: () => {
				showNotification({
					message: 'Failed to create resource relation',
					color: 'red',
				});
			},
		},
	});

	const handleCreateResourceRelation = (selectedResource: SearchResult) => {
		createResourceRelation({
			data: {
				from_entity: entity.id,
				to_entity: selectedResource.id,
			},
		});
		setSearchTerm('');
	};

	const itemContent = (index: number, item: SearchResult) => (
		<SearchResultItem
			key={item.id}
			item={item}
			isViewerUser={false} // We already checked in the parent component
			onClick={handleCreateResourceRelation}
		/>
	);

	const emptyButtons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Clear search',
				action: () => setSearchTerm(''),
				isPrimary: false,
				size: 'sm',
			},
		],
		[setSearchTerm]
	);

	const height =
		size(data) > 6 ? theme.other.space[60] : size(data) * theme.other.space[12];

	return (
		<Menu
			position="top-end"
			withinPortal
			width={300}
			onOpen={() => setTimeout(() => inputRef.current?.focus(), 10)}
		>
			<Menu.Target>
				<IconButton
					iconName="plus"
					variant="tertiary"
					tooltip="Add related resource"
				/>
			</Menu.Target>
			<Menu.Dropdown>
				<TextInput
					classNames={{
						input: classes.textInput,
					}}
					placeholder="Search resources"
					value={searchTerm}
					onChange={setSearchTerm}
					ref={inputRef}
				/>
				{size(data) > 0 && !isFetching && (
					<Virtuoso
						style={{
							height,
							scrollbarColor: `${theme.other.getColor('surface/tertiary/active')} transparent`,
						}}
						data={data}
						totalCount={size(data)}
						itemContent={itemContent}
					/>
				)}
				{isFetching && (
					<Center h={height}>
						<Loader size="sm" />
					</Center>
				)}
				{size(data) === 0 && !isFetching && (
					<EmptyState
						iconName="search"
						title="No results found"
						description="No resources or invalid search"
						buttons={emptyButtons}
						includeGoBack={false}
						size="sm"
					/>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}
