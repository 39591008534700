import copy from 'copy-to-clipboard';
import { textblockTypeInputRule } from 'prosemirror-inputrules';
import type {
	Attrs,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { Selection } from 'prosemirror-state';
import { refractor } from 'refractor';
import bash from 'refractor/lang/bash';
import clike from 'refractor/lang/clike';
import csharp from 'refractor/lang/csharp';
import css from 'refractor/lang/css';
import go from 'refractor/lang/go';
import java from 'refractor/lang/java';
import javascript from 'refractor/lang/javascript';
import json from 'refractor/lang/json';
import markup from 'refractor/lang/markup';
import objectivec from 'refractor/lang/objectivec';
import perl from 'refractor/lang/perl';
import php from 'refractor/lang/php';
import powershell from 'refractor/lang/powershell';
import python from 'refractor/lang/python';
import ruby from 'refractor/lang/ruby';
import rust from 'refractor/lang/rust';
import sql from 'refractor/lang/sql';
import typescript from 'refractor/lang/typescript';
import yaml from 'refractor/lang/yaml';
import { ActionIcon, NativeSelect } from '@mantine/core';
import ReactDOM from 'react-dom';
import { Icon } from '@repo/foundations';
import { ParseSpec } from 'prosemirror-markdown';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { ToastType } from '@repo/secoda-editor';
import toggleBlockType from '../commands/toggleBlockType';
import Prism, { LANGUAGES } from '../plugins/Prism';
import isInCode from '../queries/isInCode';
import { Dispatch } from '../types';
import Node, { NodeOptions } from './Node';

const PERSISTENCE_KEY = 'rme-code-language';
const DEFAULT_LANGUAGE = 'javascript';

[
	bash,
	css,
	clike,
	csharp,
	go,
	java,
	javascript,
	json,
	markup,
	objectivec,
	perl,
	php,
	python,
	powershell,
	ruby,
	rust,
	sql,
	typescript,
	yaml,
].forEach(refractor.register);

export default class CodeFence extends Node {
	get languageOptions() {
		return Object.entries(LANGUAGES);
	}

	get name() {
		return 'code_fence';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				language: {
					default: DEFAULT_LANGUAGE,
				},
			},
			content: 'text*',
			group: 'block',
			code: true,
			defining: true,
			draggable: false,
			parseDOM: [
				{ tag: 'pre', preserveWhitespace: 'full' },
				{
					tag: '.code-block',
					preserveWhitespace: 'full',
					contentElement: 'code',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									language: dom.dataset.language,
								},
				},
			],
			toDOM: (node) => {
				const codeActions = document.createElement('div');
				codeActions.className = 'block-actions code-actions';
				codeActions.contentEditable = 'false';

				const options = this.languageOptions.map(([key, label]) => ({
					value: key,
					label,
				}));

				ReactDOM.render(
					<>
						<NativeSelect
							rightSection={<Icon name="chevronDown" />}
							className="block-select"
							data={options}
							value={node.attrs.language}
							size="xs"
							onChange={this.handleLanguageChange}
						/>
						<ActionIcon
							onClick={this.handleCopyToClipboard}
							size={20}
							style={{
								display: 'inline',
								cursor: 'pointer',
							}}
						>
							<Icon name="clipboard" color="icon/primary/active" />
						</ActionIcon>
					</>,
					codeActions
				);

				return [
					'div',
					{
						class: 'block-container code-block',
						'data-language': node.attrs.language,
					},
					codeActions,
					['pre', ['code', { spellCheck: false }, 0]],
				];
			},
		};
	}

	commands({ type, schema }: NodeOptions) {
		return (attrs?: Attrs) =>
			toggleBlockType(type, schema.nodes.paragraph, {
				language: localStorage?.getItem(PERSISTENCE_KEY) || DEFAULT_LANGUAGE,
				...attrs,
			});
	}

	keys({ type, schema }: NodeOptions) {
		return {
			'Shift-Ctrl-\\': toggleBlockType(type, schema.nodes.paragraph),
			'Shift-Enter': (state: EditorState, dispatch?: Dispatch) => {
				if (!isInCode(state)) return false;
				const { tr, selection } = state;
				const text = selection?.$anchor?.nodeBefore?.text;

				let newText = '\n';

				if (text) {
					const splitByNewLine = text.split('\n');
					const numOfSpaces =
						splitByNewLine[splitByNewLine.length - 1].search(/\S|$/);
					newText += ' '.repeat(numOfSpaces);
				}

				dispatch?.(tr.insertText(newText, selection.from, selection.to));
				return true;
			},
			Tab: (state: EditorState, dispatch?: Dispatch) => {
				if (!isInCode(state)) return false;

				const { tr, selection } = state;
				dispatch?.(tr.insertText('  ', selection.from, selection.to));
				return true;
			},
		};
	}

	handleCopyToClipboard = (event: React.MouseEvent<HTMLButtonElement>) => {
		const { view } = this.editorState;
		const { top, left } = event.currentTarget.getBoundingClientRect();
		const result = view.posAtCoords({ top, left });

		if (result) {
			const node = view.state.doc.nodeAt(result.pos);
			if (node) {
				copy(node.textContent);
				this.options.onShowToast(
					this.options.dictionary.codeCopied,
					ToastType.Info
				);
			}
		}
	};

	handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { view } = this.editorState;
		const { tr } = view.state;
		const element = event.target;
		const { top, left } = element.getBoundingClientRect();
		const result = view.posAtCoords({ top, left });

		if (result) {
			const language = element.value;

			const transaction = tr
				.setSelection(Selection.near(view.state.doc.resolve(result.inside)))
				.setNodeMarkup(result.inside, undefined, {
					language,
				});
			view.dispatch(transaction);

			localStorage?.setItem(PERSISTENCE_KEY, language);
		}
	};

	get plugins() {
		return [Prism({ name: this.name })];
	}

	inputRules({ type }: NodeOptions) {
		return [textblockTypeInputRule(/^```$/, type)];
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.write(`\`\`\`${node.attrs.language || ''}\n`);
		state.text(node.textContent, false);
		state.ensureNewLine();
		state.write('```');
		state.closeBlock(node);
	}

	get markdownToken() {
		return 'fence';
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'code_block',
			getAttrs: (tok) => ({ language: tok.info }),
		};
	}
}
