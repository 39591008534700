import type { InfiniteData } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { flatMapDeep, isNil } from 'lodash-es';
import type {
	IApiListResponse,
	IBaseModel,
	IBaseModelInfiniteListQueryArgs,
	InfiniteQueryFnParams,
	ListQueryParams,
	Namespace,
} from '../../types';
import { getDefaultListQueryFn } from './useBaseModelList';

export function getDefaultInfiniteQueryFn<TApiResponseData extends IBaseModel>(
	filters: ListQueryParams['filters'],
	namespace: Namespace
) {
	return async (params: InfiniteQueryFnParams) => {
		const page = params?.pageParam?.page ?? 1;

		const fetchBaseModelList = getDefaultListQueryFn<TApiResponseData>(
			{ page, filters },
			namespace
		);

		return fetchBaseModelList(params);
	};
}

/**
 * Hook for fetching an infinite scrolling list of base models.
 *
 * Usecase: Fetching & caching models for infinite scrolling lists
 *
 * @param params Params for react-query. Does not accept page or filters.
 * @returns React Query hook for paginated infinite scrolling lists
 */
function useBaseModalInfiniteList<
	TApiResponseData extends IBaseModel,
	TError = unknown,
>({
	queryKey,
	namespace,
	filters,
	queryFn: customQueryFn,
	options = {},
}: IBaseModelInfiniteListQueryArgs<TApiResponseData, TError>) {
	const queryFn =
		customQueryFn ||
		getDefaultInfiniteQueryFn<TApiResponseData>(filters, namespace);

	const select = ({
		pages,
		pageParams,
	}: InfiniteData<
		IApiListResponse<TApiResponseData>
	>): InfiniteData<TApiResponseData> & { count: number } => {
		const transformedPages = flatMapDeep(pages, (page) => page.results);

		return {
			count: pages?.[0]?.count ?? 0,
			pages: transformedPages,
			pageParams,
		};
	};

	return useInfiniteQuery<
		IApiListResponse<TApiResponseData>,
		TError,
		TApiResponseData
	>({
		queryKey: [...queryKey, filters],
		queryFn,
		keepPreviousData: true,
		getPreviousPageParam: (firstPage) =>
			isNil(firstPage.meta.previous_page)
				? null
				: { page: firstPage.meta.previous_page },
		getNextPageParam: (lastPage) =>
			isNil(lastPage.meta.next_page) ? null : { page: lastPage.meta.next_page },
		select,
		...options,
	});
}

export default useBaseModalInfiniteList;
