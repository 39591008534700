import { Badge, createStyles } from '@mantine/core';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { ColorNames } from '@repo/theme/utils';
import { DataQualityClassification } from './types';

const BADGE_COLORS: Record<
	DataQualityClassification,
	{ text: ColorNames; bg: ColorNames }
> = {
	[DataQualityClassification.GOOD]: {
		text: 'text/info/default',
		bg: 'fill/info-secondary/default',
	},
	[DataQualityClassification.MODERATE]: {
		text: 'text/warning/default',
		bg: 'fill/warning-secondary/default',
	},
	[DataQualityClassification.POOR]: {
		text: 'text/critical/default',
		bg: 'fill/critical-secondary/default',
	},
	[DataQualityClassification.UNAVAILABLE]: {
		text: 'text/primary/default',
		bg: 'fill/secondary/default',
	},
};

interface DataQualityBadgeProps {
	classification: DataQualityClassification;
}

const useStyles = createStyles(
	(
		theme,
		{
			classification,
		}: { classification: DataQualityBadgeProps['classification'] }
	) => ({
		root: {
			background: theme.other.getColor(BADGE_COLORS[classification].bg),
			borderRadius: theme.radius.sm,
			padding: `${theme.spacing['4xs']} ${theme.spacing['2xs']}`,
			margin: 0,
		},
		inner: {
			color: theme.other.getColor(BADGE_COLORS[classification].text),
		},
	})
);

export const DataQualityBadge = forwardRef(
	(
		{ classification }: DataQualityBadgeProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const { classes } = useStyles({ classification });

		return (
			<Badge
				ref={ref}
				classNames={{ root: classes.root, inner: classes.inner }}
			>
				{classification}
			</Badge>
		);
	}
);
DataQualityBadge.displayName = 'DataQualityBadge';
