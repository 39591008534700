import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { ParseSpec } from 'prosemirror-markdown';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { v4 } from '../../../../../utils/uuid/v4';

import { AIBlockContainer } from '../components/AIBlockContainer';
import aiBlockRule from '../rules/aiblock';
import type { ComponentProps } from '../lib/ComponentView';
import { Dispatch } from '../types';
import ReactNode from './ReactNode';
import { NodeOptions } from './Node';

export default class AIBlock extends ReactNode {
	get name() {
		return 'ai_block';
	}

	get markdownToken() {
		return 'ai_block';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '',
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			draggable: false,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.ai_block',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.getAttribute('id'),
								},
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'ai_block',
					id: node.attrs.id,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id } = node.attrs;
		if (id) {
			state.ensureNewLine();
			state.write(`[aiblock]${id}[/aiblock]\n\n`);
			state.ensureNewLine();
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'ai_block',
			getAttrs: (tok) => ({ id: tok.attrs?.[0][1] }),
		};
	}

	get rulePlugins() {
		return [aiBlockRule];
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Record<string, unknown>) =>
			(state: EditorState, dispatch: Dispatch) => {
				let id = v4();
				if (attrs && attrs.id && attrs.id !== '') {
					id = attrs.id;
				}

				dispatch(
					state.tr.insert(
						state.selection.from,
						type.create({ ...(attrs ?? {}), id })
					)
				);

				return true;
			};
	}

	component = ({ node }: ComponentProps) => (
		<AIBlockContainer id={node.attrs.id} />
	);
}
