import {
	Breadcrumbs as MantineBreadcrumbs,
	Group,
	createStyles,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { Button } from '../Buttons';

export const useStyles = createStyles((theme) => ({
	separator: {
		marginLeft: theme.other.space[0.25],
		marginRight: theme.other.space[0.25],
	},
	button: {
		fontSize: theme.other.typography.text.sm,
		lineHeight: theme.other.typography.lineHeight.text.sm,
	},
}));

export interface Crumb {
	title: string;
	href: string;
	icon?: string;
}

interface BreadcrumbsProps {
	crumbs: Array<Crumb>;
	'data-testid'?: string;
}

export function Breadcrumbs({
	crumbs,
	'data-testid': dataTestID,
}: BreadcrumbsProps) {
	const { classes } = useStyles();

	if (crumbs.length === 0) {
		return null;
	}

	return (
		<MantineBreadcrumbs
			data-testid={dataTestID}
			classNames={{
				separator: classes.separator,
			}}
		>
			{crumbs.map((crumb) => (
				<Button
					size="md"
					className={classes.button}
					key={crumb.title}
					component={Link}
					to={crumb.href}
					variant="tertiary"
				>
					{crumb.icon ? (
						<Group spacing="xs">
							<span>{crumb.icon}</span>
							<span>{crumb.title}</span>
						</Group>
					) : (
						<>{crumb.title}</>
					)}
				</Button>
			))}
		</MantineBreadcrumbs>
	);
}
