import { useDisclosure } from '@mantine/hooks';
import { useCallback, useMemo } from 'react';
import { isNil } from 'lodash-es';
import { MenuProps } from '@mantine/core';
import { ListBox } from '@repo/foundations';
import { FilterOperator, FilterOption, type FilterValue } from './types';
import { FilterTargetDetailed } from './FilterTarget/FilterTargetDetailed';
import { FilterTargetSimple } from './FilterTarget/FilterTargetSimple';
import { FilterDropdown } from './FilterDropdown/FilterDropdown';
import { useFilterLabel } from './useFilterLabel';
import type { OperatorMenuProps } from './OperatorMenu';
import {
	IS_NOT_SET_FILTER_ITEM,
	IS_SET_FILTER_ITEM,
	type FilterOperatorConfig,
} from './constants';
import { getValueAsArray } from './utils';
import { useFilter } from './useFilter';

interface FilterProps extends Omit<MenuProps, 'opened' | 'onChange'> {
	value: FilterValue;
	showDetailedLabel?: boolean;
	onClear?: () => void;
	onChange: (value: Partial<FilterValue>) => void;
	initialOpened?: boolean;
	operatorConfig?: FilterOperatorConfig;
	filterOption: FilterOption;
}

export function Filter({
	value,
	showDetailedLabel = false,
	onClear,
	onChange,
	initialOpened = false,
	operatorConfig,
	filterOption,
	...menuProps
}: FilterProps) {
	const [opened, { close, toggle, open }] = useDisclosure(initialOpened);

	const handleOnOpenChange = useCallback(
		(newOpen: boolean) => {
			if (newOpen) {
				open();
			} else {
				close();
			}
		},
		[close, open]
	);

	const valueLabel = useFilterLabel({ value });

	const { handleOnChange } = useFilter({
		value,
		onChange,
		close,
	});

	const operatorMenuProps = useMemo(() => {
		const operators = operatorConfig?.getOperators(value) ?? [];
		if (filterOption.filterDropdownConfig.hasIsNotSetOption) {
			// if option supports isNotSet, we add it to the list of operators
			operators.push(FilterOperator.isNotSet);
		}
		if (filterOption.filterDropdownConfig.hasIsSetOption) {
			// if option supports isSet, we add it to the list of operators
			operators.push(FilterOperator.isSet);
		}

		let selectedOperator = value.operator;
		// if option supports isNotSet, isNotSet is applied, and value is empty, we should set isNotSet as the operator for display purposes
		if (
			filterOption.filterDropdownConfig.hasIsNotSetOption &&
			value.isNotSetApplied &&
			(!filterOption.filterDropdownConfig.hasIsSetOption ||
				!value.isSetApplied) &&
			isNil(value.value)
		) {
			selectedOperator = FilterOperator.isNotSet;
		}

		// if option supports isSet, isSet is applied, and value is empty, we should set isSet as the operator for display purposes
		if (
			filterOption.filterDropdownConfig.hasIsSetOption &&
			value.isSetApplied &&
			(!filterOption.filterDropdownConfig.hasIsNotSetOption ||
				!value.isNotSetApplied) &&
			isNil(value.value)
		) {
			selectedOperator = FilterOperator.isSet;
		}

		return {
			operators: operators.map((item) => ({
				value: item,
				label: operatorConfig?.getLabel(item, value.value) ?? item.toString(),
			})),
			selected: selectedOperator,
			onChange: (operator) => {
				const valueAsArray = getValueAsArray(value);
				const newValue: Partial<FilterValue> = {
					operator,
					isNotSetApplied:
						operator === FilterOperator.isNotSet ||
						valueAsArray.includes(IS_NOT_SET_FILTER_ITEM.value),
					isSetApplied:
						operator === FilterOperator.isSet ||
						valueAsArray.includes(IS_SET_FILTER_ITEM.value),
				};

				handleOnChange(newValue, true);

				// if we are switching to other operator than isNotSet and value is empty, we should open dropdown for value selection
				if (
					!newValue.isNotSetApplied &&
					!newValue.isSetApplied &&
					isNil(value.value) &&
					!opened
				) {
					toggle();
				}
			},
			label:
				operatorConfig?.getLabel(selectedOperator, value.value) ??
				selectedOperator.toString(),
		} as OperatorMenuProps;
	}, [filterOption, handleOnChange, opened, operatorConfig, toggle, value]);

	const hasValue =
		!isNil(value.value) || value.isNotSetApplied || value.isSetApplied;
	const canShowDetailedLabel =
		showDetailedLabel && !!operatorConfig && hasValue;

	return (
		<ListBox
			closeOnClickOutside
			{...menuProps}
			opened={opened}
			onOpenChange={handleOnOpenChange}
		>
			<ListBox.Target>
				{canShowDetailedLabel ? (
					<FilterTargetDetailed
						label={filterOption.label}
						valueLabel={valueLabel}
						onClear={onClear}
						isMenuOpen={opened}
						operatorMenuProps={operatorMenuProps}
						onToggle={toggle}
					/>
				) : (
					<FilterTargetSimple
						label={valueLabel ?? filterOption.label}
						onClear={onClear}
						isMenuOpen={opened}
						onToggle={toggle}
						hasValue={hasValue}
					/>
				)}
			</ListBox.Target>
			{opened && (
				<FilterDropdown
					filterOption={filterOption}
					onChange={handleOnChange}
					value={value}
				/>
			)}
		</ListBox>
	);
}
