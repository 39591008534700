import type { Icon as TablerIcon } from '@tabler/icons-react';
import { IconAlertTriangle, IconCircleCheck } from '@tabler/icons-react';
import { colors } from '@repo/theme/primitives';
import type { ExtendedCustomColors } from '@repo/theme/primitives/colors';
import type { IncidentStatus } from '../../api';

export const INCIDENT_STATUS_MAPPING: Record<
	IncidentStatus,
	{
		color: string;
		fill: string;
		icon: TablerIcon;
		baseColor: ExtendedCustomColors;
	}
> = {
	resolved: {
		color: 'white',
		fill: colors.green[5],
		icon: IconCircleCheck,
		baseColor: 'green',
	},
	active: {
		color: 'white',
		fill: colors.red[5],
		icon: IconAlertTriangle,
		baseColor: 'red',
	},
	acknowledged: {
		color: 'white',
		fill: colors.yellow[5],
		icon: IconAlertTriangle,
		baseColor: 'yellow',
	},
	expired: {
		color: 'white',
		fill: colors.gray[5],
		icon: IconAlertTriangle,
		baseColor: 'gray',
	},
} as const;

export const ACKNOWLEDGE_ENABLED_STATES: IncidentStatus[] = ['active'];
export const RESOLVE_ENABLED_STATES: IncidentStatus[] = [
	'active',
	'acknowledged',
];
