import { Box, Menu } from '@mantine/core';
import { cloneDeep, find, isNil, map, partition } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useState, useContext } from 'react';
import { Icon, Text } from '@repo/foundations';
import {
	invalidateSearchViews,
	useAuthUser,
	useCreateSearchView,
	useSearchViewList,
	useWorkspace,
} from '../../../../api';
import type { ISearchView } from '../../../../api/types';
import { trackEvent } from '../../../../utils/analytics';
import type { SearchView } from '../FilterCarousel.constants';
import { SearchFilterStoreContext } from '../store';
import EditViewMenuCard from './EditViewMenuCard';
import ViewItem from './ViewItem';
import ViewMenuTarget from './ViewMenuTarget';

interface ViewMenuProps {
	standalone?: boolean;
}

function ViewMenu({ standalone = false }: ViewMenuProps) {
	const searchFilterStore = useContext(SearchFilterStoreContext);
	const { selectedView } = searchFilterStore;
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [showEditDropdown, setShowEditDropdown] = useState<boolean>(false);
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	const { data: views } = useSearchViewList({
		options: {
			select: (data) =>
				map(
					data.results,
					(d) =>
						({
							label: d.name,
							value: d.id,
							selectedFilters: d.selected_filters,
							isPersonal: d.owner_id === user.id,
							teams: d.teams,
						}) as SearchView
				).sort((a, b) => {
					if (a.isPersonal && !b.isPersonal) return -1;
					if (!a.isPersonal && b.isPersonal) return 1;
					if (isNil(a.label)) return 1;
					return a.label.localeCompare(b.label);
				}),
			placeholderData: {
				count: 0,
				total_pages: 0,
				meta: {
					page: 0,
					previous_page: 0,
					next_page: 0,
				},
				links: {
					next: null,
					previous: null,
				},
				results: [] as ISearchView[],
				resource_totals: {},
			},
		},
	});

	const [personalViews, sharedViews] = partition(
		views,
		(view) => view.isPersonal
	);

	const { mutateAsync: createSearchView } = useCreateSearchView({
		options: {
			onSuccess: () => {
				invalidateSearchViews(1, {});
				trackEvent('search/views/create', {}, user, workspace);
			},
		},
	});

	const duplicateView = (view: SearchView) => {
		if (!views) return;
		for (let copyID = 1; copyID <= views.length + 1; copyID += 1) {
			const newLabel = `${view.label} (${copyID})`;
			if (!find(views, { label: `${view.label} (${copyID})` })) {
				createSearchView({
					data: {
						name: newLabel,
						selected_filters: view.selectedFilters,
						teams: view.teams,
					},
				});
				break;
			}
		}
	};

	const handleCreateButton: React.MouseEventHandler<HTMLButtonElement> = (
		e
	) => {
		e.stopPropagation();
		searchFilterStore.setSelectedView(undefined);
		setShowEditDropdown(true);
	};

	return (
		<>
			<Menu
				opened={showMenu}
				onClose={() => setShowMenu(false)}
				position="bottom"
				withinPortal
				closeOnItemClick
				closeOnClickOutside
			>
				<Menu.Target data-testid="search-view-button">
					<ViewMenuTarget
						standalone={standalone}
						setShowEditDropdown={() => {
							setShowEditDropdown(true);
						}}
						onClick={() => setShowMenu(true)}
					/>
				</Menu.Target>
				<Menu.Dropdown>
					{views?.length === 0 && (
						<Box>
							<Menu.Label>
								<Text size="xs" color="text/secondary/default">
									Save search filters to show only
									<br /> the resources you want to see
								</Text>
							</Menu.Label>
							<Menu.Divider />
						</Box>
					)}
					{personalViews.length ? (
						<Menu.Label>Personal views</Menu.Label>
					) : null}
					{map(personalViews, (view) => (
						<ViewItem
							key={view.value}
							view={cloneDeep(view)}
							openEditDropdown={() => {
								setShowEditDropdown(true);
							}}
							onDuplicate={() => duplicateView(view)}
							closeMenu={() => setShowMenu(false)}
						/>
					))}
					{personalViews.length && sharedViews.length ? <Menu.Divider /> : null}
					{sharedViews.length ? <Menu.Label>Team views</Menu.Label> : null}
					{map(sharedViews, (view) => (
						<ViewItem
							key={view.value}
							view={cloneDeep(view)}
							openEditDropdown={() => {
								setShowEditDropdown(true);
							}}
							onDuplicate={() => duplicateView(view)}
							closeMenu={() => setShowMenu(false)}
						/>
					))}
					{views?.length ? <Menu.Divider /> : null}
					<Menu.Item
						key="create-new"
						onClick={handleCreateButton}
						icon={<Icon name="plus" />}
					>
						Create new view
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
			{showEditDropdown ? (
				<EditViewMenuCard
					view={selectedView}
					onClose={() => {
						setShowEditDropdown(false);
					}}
					opened={showEditDropdown}
				/>
			) : null}
		</>
	);
}

export default observer(ViewMenu);
