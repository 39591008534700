import type { ColumnDefinition } from '../../types';

export const MeasurementColumns: ColumnDefinition = {
	updated_at: {
		field: 'updated_at',
		headerName: 'Last run',
	},
	value: {
		field: 'value',
		headerName: 'Value',
		flex: 1,
		sortable: true,
	},
	incident: {
		field: 'incident',
		headerName: 'Incident',
		flex: 1,
		sortable: true,
	},
};
