import React, {
	createContext,
	useContext,
	useState,
	useCallback,
	useMemo,
	ReactNode,
	useEffect,
} from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';

interface IncidentDrawerContextType {
	incidentId: string | null;
	openIncident: (incident: string | null) => void;
	opened: boolean;
	close: () => void;
}

const defaultContext: IncidentDrawerContextType = {
	incidentId: null,
	openIncident: () => {},
	opened: false,
	close: () => {},
};

const IncidentDrawerContext =
	createContext<IncidentDrawerContextType>(defaultContext);

export function IncidentDrawerProvider({ children }: { children: ReactNode }) {
	const [incidentId, setIncidentId] = useState<string | null>(null);
	const [opened, { open, close }] = useDisclosure(false);
	const location = useLocation();

	const handleOpen = useCallback(
		(incident: string | null) => {
			setIncidentId(incident);
			if (incident) {
				open();
			}
		},
		[setIncidentId, open]
	);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const incident = params.get('incident');
		if (incident) {
			handleOpen(incident);
		}
	}, [location.search, handleOpen]);

	const contextValue = useMemo(
		() => ({
			incidentId: incidentId,
			openIncident: handleOpen,
			opened,
			close,
		}),
		[incidentId, handleOpen, opened, close]
	);

	return (
		<IncidentDrawerContext.Provider value={contextValue}>
			{children}
		</IncidentDrawerContext.Provider>
	);
}

export const useIncidentDrawer = (): IncidentDrawerContextType => {
	const context = useContext(IncidentDrawerContext);
	const navigate = useNavigate();

	// Handle a fallback when a context is not initialized (for example, inside a chart embedded in another context)
	if (context === defaultContext) {
		return {
			...context,
			openIncident: (incident: string | null) =>
				navigate(`/incident/${incident ?? ''}`),
		};
	}

	return context;
};
