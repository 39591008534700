import { Box } from '@mantine/core';
import { useEffect } from 'react';
import { Navigate, useLoaderData, useLocation } from 'react-router';
import { isNil } from 'lodash-es';
import { useAuthUser } from '../../api';
import { useRegionRedirect } from '../../api/hooks/useRegionRedirect';
import { sideBarStore } from '../SecodaAppShell/SideBar/store';
import { usePlan } from '../../hooks/usePlans';
import {
	trackEvent,
	useConfigureFullStory,
	useConfigureIntercom,
	useConfigureSentry,
} from '../../utils/analytics';
import EntityDrawer from '../EntityDrawer/EntityDrawer';
import EntityModal from '../EntityModal/EntityModal';
import { useSpotlightShortcuts } from '../Spotlight/useSpotlightShortcuts';
import { useFeatureFlags } from '../../utils/featureFlags';
import type {
	FeatureFlagsKeys,
	KebabToCamelCase,
} from '../../utils/featureFlags/types';
import { setGrafanaSessionMetadata } from '../../web-tracing';
import BodyWrapper from './BodyWrapper';

export interface IPrivateRouteProps {
	children: React.ReactNode;
	redirectTo?: string;
	hideSideBar?: boolean;
	hideNavBar?: boolean;
	adminRequired?: boolean;
	editorRequired?: boolean;
	guestRestricted?: boolean;
	hideSideBarFeatureFlag?: KebabToCamelCase<keyof FeatureFlagsKeys>;
	hideNavBarFeatureFlag?: KebabToCamelCase<keyof FeatureFlagsKeys>;
}

export function PrivateRoute({
	children,
	redirectTo = '/auth',
	hideSideBar = false,
	hideNavBar = false,
	adminRequired = false,
	editorRequired = false,
	guestRestricted = false,
	hideSideBarFeatureFlag,
	hideNavBarFeatureFlag,
}: IPrivateRouteProps) {
	// NOTE: This ensures workspace is loaded before rendering the page.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const _ = useLoaderData();
	const {
		user,
		workspace,
		isUnauthenticatedUser,
		isAdminUser,
		isEditorUser,
		isGuestUser,
	} = useAuthUser();
	const { paywall } = usePlan();
	const location = useLocation();

	const featureFlags = useFeatureFlags();

	useSpotlightShortcuts();
	useRegionRedirect();
	useConfigureIntercom();
	useConfigureSentry();
	useConfigureIntercom();
	useConfigureFullStory();

	// track page views in Mixpanel
	useEffect(() => {
		trackEvent(
			'page/view',
			{
				path: location.pathname,
			},
			user,
			workspace
		);
	}, [location.pathname, user, workspace]);

	// track session info in Grafana
	useEffect(() => {
		setGrafanaSessionMetadata({
			workspace_id: workspace?.id,
			user_id: user?.id,
			user_role: user?.role?.toString(),
		});
	}, [workspace, user]);

	// Authentication check
	if (isUnauthenticatedUser) {
		return (
			<Navigate
				to={`${redirectTo}?final_destination=${encodeURI(
					window.location.href
				)}`}
			/>
		);
	}

	// If the user leaves the onboarding flow and tries to access the root `/`, we
	// need to redirect them back to the workspace creation flow.
	if (user && isNil(user.workspace)) {
		return <Navigate to="/onboarding/workspace" />;
	}

	// Plan check
	if (
		paywall &&
		location.pathname !== '/plans' &&
		location.pathname !== '/settings/billing' &&
		// Playwright users should not be redirected to the plans page.
		!user?.email.includes('playwright')
	) {
		return <Navigate to="/plans" />;
	}

	// Permission check
	if (adminRequired && !isAdminUser) {
		return <Navigate to="/403" />;
	}
	if (editorRequired && !isEditorUser) {
		return <Navigate to="/403" />;
	}
	if (guestRestricted && isGuestUser) {
		return <Navigate to="/403" />;
	}

	const shouldHideSideBar =
		hideSideBar ||
		(hideSideBarFeatureFlag && featureFlags[hideSideBarFeatureFlag]);
	const shouldHideNavBar =
		hideNavBar ||
		(hideNavBarFeatureFlag && featureFlags[hideNavBarFeatureFlag]);

	sideBarStore.setShowSideBar(!shouldHideSideBar);

	return (
		<Box>
			<EntityDrawer />
			<EntityModal />
			<BodyWrapper hideNavbar={shouldHideNavBar}>{children}</BodyWrapper>
		</Box>
	);
}

export default PrivateRoute;
