import { showNotification } from '@mantine/notifications';
import { IconButton } from '@repo/foundations';
import {
	invalidateResourceRelationList,
	useDeleteResourceRelation,
} from '../../../../api/hooks/relations';
import type { ISecodaEntity } from '../../../../api';
import { EntityDisplay } from '../EntityDisplay/EntityDisplay';

interface IRelatedEntityProps {
	entityId?: string;
	relatedEntity: ISecodaEntity;
	isEditorOfEntity: boolean;
}

export function RelatedEntity({
	entityId,
	relatedEntity,
	isEditorOfEntity,
}: IRelatedEntityProps) {
	const { mutateAsync: deleteRelation } = useDeleteResourceRelation({
		fromEntityId: entityId || '',
		toEntityId: relatedEntity.id,
		onSuccess: () => {
			showNotification({
				message: 'Resource relation removed',
				color: 'green',
			});
			invalidateResourceRelationList(entityId || '');
			invalidateResourceRelationList(relatedEntity.id);
		},
		onError: () => {
			showNotification({
				message: 'Error deleting resource relation',
				color: 'red',
			});
		},
	});

	const handleDeleteClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		deleteRelation();
	};

	return (
		<EntityDisplay
			entity={relatedEntity}
			hoverButton={
				isEditorOfEntity && (
					<IconButton
						iconName="x"
						variant="tertiary"
						onClick={handleDeleteClick}
					/>
				)
			}
		/>
	);
}
