import { Input } from '@mantine/core';
import type { ChangeEventHandler } from 'react';

interface IRelatedFilterSearchProps {
	term: string;
	setTerm: (term: string) => void;
	search: (term: string) => void;
}

function RelatedFilterSearch({
	term,
	setTerm,
	search,
}: IRelatedFilterSearchProps) {
	const handleSearchTermChange: ChangeEventHandler<HTMLInputElement> = (
		event
	) => {
		setTerm(event.currentTarget.value);
		search(event.currentTarget.value);
	};

	return (
		<Input
			height={32}
			mb={10}
			value={term}
			placeholder="Search for a resource"
			onChange={handleSearchTermChange}
		/>
	);
}

export default RelatedFilterSearch;
