import type { EditorState } from 'prosemirror-state';
import { isInTable } from 'prosemirror-tables';
import { Icon } from '@repo/foundations';
import type { EditorDictionary, MenuItem } from '@repo/secoda-editor';
import isInList from '../queries/isInList';
import isMarkActive from '../queries/isMarkActive';
import isNodeActive from '../queries/isNodeActive';
import { ALT_KEY, CTRL_KEY, MOD_KEY, SHIFT_KEY } from '../utils/keyboard';

export default function formattingMenuItems(
	state: EditorState,
	isTemplate: boolean,
	dictionary: EditorDictionary
): MenuItem[] {
	const { schema } = state;
	const isTable = isInTable(state);
	const isList = isInList(state);
	const allowBlocks = !isTable && !isList;
	return [
		{
			name: 'placeholder',
			tooltip: dictionary.placeholder,
			icon: () => <Icon name="cursorText" color="icon/primary/default" />,
			active: isMarkActive(schema.marks.placeholder),
			visible: isTemplate,
		},
		{
			name: 'separator',
			visible: isTemplate,
		},
		{
			name: 'menu-text-styles',
			icon: () => <Icon name="letterCase" color="icon/primary/default" />,
			visible: allowBlocks,
			tooltip: dictionary.style,
			menuOptions: [
				{
					name: 'paragraph',
					tooltip: dictionary.text,
					icon: () => <Icon name="letterCase" color="icon/primary/default" />,
					active: isNodeActive(schema.nodes.paragraph),
					shortcutKeys: [SHIFT_KEY, CTRL_KEY, '0'],
				},
				{
					name: 'heading',
					tooltip: dictionary.heading1,
					icon: () => <Icon name="h1" color="icon/primary/default" />,
					active: isNodeActive(schema.nodes.heading, { level: 1 }),
					attrs: { level: 1 },
					shortcutKeys: [SHIFT_KEY, CTRL_KEY, '1'],
				},
				{
					name: 'heading',
					tooltip: dictionary.heading2,
					icon: () => <Icon name="h2" color="icon/primary/default" />,
					active: isNodeActive(schema.nodes.heading, { level: 2 }),
					attrs: { level: 2 },
					shortcutKeys: [SHIFT_KEY, CTRL_KEY, '2'],
				},
				{
					name: 'heading',
					tooltip: dictionary.heading3,
					icon: () => <Icon name="h3" color="icon/primary/default" />,
					active: isNodeActive(schema.nodes.heading, { level: 3 }),
					attrs: { level: 3 },
					shortcutKeys: [SHIFT_KEY, CTRL_KEY, '3'],
				},
			],
		},
		{
			name: 'strong',
			tooltip: dictionary.strong,
			icon: () => <Icon name="bold" color="icon/primary/default" />,
			active: isMarkActive(schema.marks.strong),
			shortcutKeys: [MOD_KEY, 'B'],
		},
		{
			name: 'em',
			tooltip: dictionary.em,
			icon: () => <Icon name="italic" color="icon/primary/default" />,
			active: isMarkActive(schema.marks.em),
			shortcutKeys: [MOD_KEY, 'I'],
		},
		{
			name: 'strikethrough',
			tooltip: dictionary.strikethrough,
			icon: () => <Icon name="strikethrough" color="icon/primary/default" />,
			active: isMarkActive(schema.marks.strikethrough),
			shortcutKeys: [MOD_KEY, 'D'],
		},
		{
			name: 'link',
			tooltip: dictionary.createLink,
			icon: () => <Icon name="link" color="icon/primary/default" />,
			active: isMarkActive(schema.marks.link),
			attrs: { href: '' },
			shortcutKeys: [MOD_KEY, 'J'],
		},
		{
			name: 'blockquote',
			tooltip: dictionary.quote,
			icon: () => <Icon name="quote" color="icon/primary/default" />,
			active: isNodeActive(schema.nodes.blockquote),
			attrs: { level: 2 },
			visible: allowBlocks,
			shortcutKeys: [MOD_KEY, ']'],
		},
		{
			name: 'code_inline',
			tooltip: dictionary.codeInline,
			icon: () => <Icon name="code" color="icon/primary/default" />,
			active: isMarkActive(schema.marks.code_inline),
			shortcutKeys: [MOD_KEY, 'E'],
		},
		{
			name: 'highlight',
			tooltip: dictionary.mark,
			icon: () => <Icon name="highlight" color="icon/primary/default" />,
			active: isMarkActive(schema.marks.highlight),
			visible: !isTemplate,
			shortcutKeys: [MOD_KEY, CTRL_KEY, 'H'],
		},
		{
			name: 'menu-lists',
			icon: () => <Icon name="list" color="icon/primary/default" />,
			visible: allowBlocks || isList,
			tooltip: dictionary.list,
			menuOptions: [
				{
					name: 'bullet_list',
					tooltip: dictionary.bulletList,
					icon: () => <Icon name="list" color="icon/primary/default" />,
					active: isNodeActive(schema.nodes.bullet_list),
					shortcutKeys: [SHIFT_KEY, CTRL_KEY, '8'],
				},
				{
					name: 'ordered_list',
					tooltip: dictionary.orderedList,
					icon: () => <Icon name="listNumbers" color="icon/primary/default" />,
					active: isNodeActive(schema.nodes.ordered_list),
					shortcutKeys: [SHIFT_KEY, CTRL_KEY, '9'],
				},
				{
					name: 'checkbox_list',
					tooltip: dictionary.checkboxList,
					icon: () => <Icon name="listCheck" color="icon/primary/default" />,
					keywords: 'checklist checkbox task',
					active: isNodeActive(schema.nodes.checkbox_list),
					shortcutKeys: [SHIFT_KEY, CTRL_KEY, '7'],
				},
			],
		},

		{
			name: 'separator',
		},
		{
			name: 'comment',
			tooltip: dictionary.comment,
			icon: () => <Icon name="message" color="icon/primary/default" />,
			visible: 'comment' in schema.marks,
			shortcutKeys: [MOD_KEY, ALT_KEY, 'M'],
		},
	];
}
