import { Group } from '@mantine/core';
import { lowerCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { Button } from '@repo/foundations';
import { useAuthUser } from '../../../../api';
import { EntityType } from '../../../../lib/types';
import { trackEvent } from '../../../../utils/analytics';

import { entityModalStore } from '../../store';

function DocumentFooter() {
	const { user, workspace } = useAuthUser();
	const navigate = useNavigate();

	if (
		entityModalStore.type !== EntityType.document &&
		entityModalStore.type !== EntityType.query
	) {
		return null;
	}

	const handleOnClick = () => {
		trackEvent('document/create', {}, user, workspace!);
		if (entityModalStore.type === EntityType.document) {
			entityModalStore.createDocument(navigate);
		} else {
			entityModalStore.createQuery(navigate);
		}
	};

	return (
		<Group position="right">
			<Button
				onClick={handleOnClick}
				loading={entityModalStore.loading}
				variant="primary"
			>
				{entityModalStore.action === 'create'
					? `Create ${lowerCase(entityModalStore.type)}`
					: `Save ${lowerCase(entityModalStore.type)}`}
			</Button>
		</Group>
	);
}

export default observer(DocumentFooter);
