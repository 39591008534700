import { Box, Text, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import { queryClient } from '../../api';
import {
	useApiDeleteMembership,
	useApiUpdateMembership,
} from '../../api/codegen/apiComponents';
import { apiQueryKey } from '../../api/codegen/apiQueryKey';
import type { TeamMembershipOut } from '../../api/codegen/apiSchemas';
import { CustomSelector } from '../CustomSelector';

type Props = {
	membership: TeamMembershipOut;
	disabled?: boolean;
};

const toValue = (membership: TeamMembershipOut) => {
	if (!membership.write) {
		return 'read';
	}
	return 'write';
};

const text = (membership: TeamMembershipOut, value: string) => {
	if (value === 'delete') {
		return 'Remove from team';
	}

	if (value === 'read') {
		return 'Can read';
	}

	if (membership.user) {
		return 'Can edit';
	}
	return 'Editors can edit';
};

export function MembershipSelector({ membership, disabled }: Props) {
	const theme = useMantineTheme();
	const [permission, setPermission] = useState<'read' | 'write'>(
		toValue(membership)
	);

	const { mutateAsync: updateTeamMembership } = useApiUpdateMembership({
		onSuccess: () => {
			queryClient.invalidateQueries(
				apiQueryKey('auth/teams/{team_id}', { team_id: membership.team_id })
			);
		},
		onError: () => {
			showNotification({
				title: 'Failed to update team member',
				message: 'Please check permissions and try again',
				color: 'red',
			});
		},
	});

	const { mutateAsync: deleteTeamMembership } = useApiDeleteMembership({
		onSuccess: () => {
			queryClient.invalidateQueries(
				apiQueryKey('auth/teams/{team_id}', { team_id: membership.team_id })
			);
		},
	});

	const onSelect = async (value: string) => {
		if (value === 'delete') {
			await deleteTeamMembership({
				pathParams: {
					teamId: membership.team_id,
					membershipId: membership.id,
				},
			});
		} else {
			await updateTeamMembership({
				pathParams: {
					teamId: membership.team_id,
					membershipId: membership.id,
				},
				body: {
					read: true,
					write: value === 'write',
				},
			});
			setPermission(value as 'read' | 'write');
		}
	};

	return (
		<CustomSelector
			disabled={disabled}
			menuProps={{
				width: 'target',
				position: 'bottom-start',
				withinPortal: true,
			}}
			items={[
				{
					value: 'read',
					disabled: membership.user?.role === 'admin',
				},
				{
					value: 'write',
					disabled:
						membership.user?.role === 'viewer' ||
						membership.user?.role === 'guest',
				},
				{
					value: 'delete',
				},
			]}
			renderItem={(item) => (
				<Box px={8} py={6}>
					<Text
						size="sm"
						color={
							item.disabled
								? theme.colors.gray[5]
								: item.value === 'delete'
									? theme.colors.red[5]
									: theme.colors.gray[9]
						}
					>
						{text(membership, item.value)}
					</Text>
				</Box>
			)}
			value={permission}
			onChange={onSelect}
		/>
	);
}
