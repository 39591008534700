import { Group, Popover, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { noop } from '@mantine/utils';
import { observer } from 'mobx-react-lite';
import {
	Button,
	IconButton,
	Icon,
	Switch,
	Text,
	Title,
} from '@repo/foundations';
import { useAuthUser } from '../../../api';
import { NewTeamModal } from '../../NewTeamModal/NewTeamModal';
import { appShellStore } from '../store';
import {
	NavBarWrapper,
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
} from '.';

function TeamsPageNavBar() {
	const { isAdminUser } = useAuthUser();

	const [
		createTeamModalOpened,
		{ open: openCreateTeamModal, close: closeCreateTeamModal },
	] = useDisclosure(false);

	const {
		navBarUI: {
			teamsPage: { includeArchive },
		},
		teamsPageIncludeArchiveHandler: { toggle: toggleIncludeArchive },
	} = appShellStore;

	return (
		<>
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Title order={1} size="sm" data-testid="teams-page-nav-bar-title">
						Teams
					</Title>
				</NavBarBreadcrumbWrapper>
				{isAdminUser && (
					<NavBarRightSideWrapper>
						<Popover shadow="lg">
							<Popover.Target>
								<IconButton
									tooltip="Settings"
									iconName="adjustmentsHorizontal"
								/>
							</Popover.Target>
							<Popover.Dropdown p={0}>
								<Stack p={4} w={256}>
									<Group px={8} py={6} position="apart">
										<Group spacing={8} align="center">
											<Icon name="archive" />
											<Text size="sm">Show archived teams</Text>
										</Group>
										<Switch
											checked={includeArchive}
											onChange={toggleIncludeArchive}
										/>
									</Group>
								</Stack>
							</Popover.Dropdown>
						</Popover>
						<Button onClick={openCreateTeamModal}>New team</Button>
					</NavBarRightSideWrapper>
				)}
			</NavBarWrapper>
			<NewTeamModal
				opened={createTeamModalOpened}
				onClose={closeCreateTeamModal}
				onFinish={noop}
			/>
		</>
	);
}

export default observer(TeamsPageNavBar);
