import type { MantineThemeOverride } from '@mantine/core';

export const Checkbox: MantineThemeOverride['components'] = {
	Checkbox: {
		styles: (theme, params, context) => {
			let borderRadius: number = theme.other.space[1];
			if (context.size === 'xs' || context.size === 'sm') {
				// eslint-disable-next-line prefer-destructuring
				borderRadius = theme.other.space[1];
			}
			let size: number = theme.other.space[5];
			let fontSize: string = theme.other.typography.text.lg;
			let lineHeight: string = theme.other.typography.lineHeight.text.lg;
			if (context.size === 'xs') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[3];
				fontSize = theme.other.typography.text.xxs;
				lineHeight = theme.other.typography.lineHeight.text.xxs;
			} else if (context.size === 'sm') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[4];
				fontSize = theme.other.typography.text.xs;
				lineHeight = theme.other.typography.lineHeight.text.xs;
			} else if (context.size === 'md') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[5];
				fontSize = theme.other.typography.text.sm;
				lineHeight = theme.other.typography.lineHeight.text.sm;
			} else if (context.size === 'lg') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[6];
				fontSize = theme.other.typography.text.md;
				lineHeight = theme.other.typography.lineHeight.text.md;
			} else if (context.size === 'xl') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[8];
				fontSize = theme.other.typography.text.lg;
				lineHeight = theme.other.typography.lineHeight.text.lg;
			}

			return {
				inner: {
					width: size,
					height: size,
				},
				input: {
					width: size,
					height: size,
					borderRadius,
					borderColor: theme.other.getColor('border/input/default'),
					'&:hover': {
						background: theme.other.getColor('surface/input/hover'),
						borderColor: theme.other.getColor('border/input/hover'),
					},
					'&:checked:hover': {
						background: theme.other.getColor('fill/brand/hover'),
						borderWidth: 0,
					},
					'&:checked': {
						background: theme.other.getColor('fill/brand/default'),
						borderWidth: 0,
					},
					'&:disabled': {
						background: theme.other.getColor('fill/primary/disabled'),
						borderColor: theme.other.getColor('border/transparent/default'),
					},
				},
				label: {
					fontSize,
					lineHeight,
					fontWeight: theme.other.typography.weight.semibold,
					color: theme.other.getColor('text/primary/default'),
					paddingLeft: theme.other.space[2],
				},
				description: {
					fontSize,
					lineHeight,
					fontWeight: theme.other.typography.weight.regular,
					color: theme.other.getColor('text/secondary/default'),
					paddingLeft: theme.other.space[2],
				},
			};
		},
	},
};
