import { showNotification } from '@mantine/notifications';
import { IS_LOCAL_OR_DEVELOPMENT, IS_PRODUCTION } from './envs';

type NotificationType = {
	title: string;
	message: string;
};

const CHROME_EXTENSION_ID = 'akcolkhleaionhppniljgglpeeohkljk';

export const sendChromeExtensionMessage = async (
	message: {
		type: string;
		[key: string]: string | null;
	},
	successNotification?: NotificationType,
	errorNotification?: NotificationType
): Promise<{ success: boolean; message: string }> => {
	if (!window.chrome?.runtime) {
		return {
			success: false,
			message: 'Chrome runtime is not available',
		};
	}

	const id = IS_LOCAL_OR_DEVELOPMENT
		? localStorage.getItem('extensionId')
		: CHROME_EXTENSION_ID;

	if (!id) {
		if (IS_PRODUCTION) {
			showNotification({
				title: 'Please provide a Chrome Extension ID',
				message: 'If the issue persists, contact customer support',
				color: 'red',
			});
		}
		return {
			success: false,
			message: 'Chrome Extension ID is missing',
		};
	}

	return new Promise((resolve) => {
		window.chrome.runtime.sendMessage(id!, message, async (response) => {
			if (!response?.success && errorNotification) {
				showNotification({
					...errorNotification,
					color: 'red',
				});
			} else if (response?.success && successNotification) {
				showNotification({
					...successNotification,
					color: 'green',
				});
			}
			resolve(response);
		});
	});
};
