import type { DefaultProps, Selectors } from '@mantine/core';
import {
	Box,
	createStyles,
	SimpleGrid,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useIntersection } from '@mantine/hooks';
import { useUpdateEffect } from 'ahooks';
import React from 'react';
import { Title } from '@repo/foundations';
import type { WidgetData } from './types';

const useStyles = createStyles((theme) => ({
	root: {
		gap: theme.spacing.md,
	},
	groupTitle: {
		fontSize: theme.other.typography.title.lg,
		fontWeight: theme.other.typography.weight.bold,
	},
	widgetItemsWrapper: {
		rowGap: theme.spacing.xs,
	},
	widgetWrapper: {
		paddingBottom: theme.spacing.xs,
	},
}));

type WidgetSelectorGroupStylesNames = Selectors<typeof useStyles>;

interface IWidgetRendererProps<T> {
	data: Omit<WidgetData<T>, 'renderer'>;
	onClick: VoidFunction;
}

export interface IWidgetSelectorGroupProps<T>
	extends DefaultProps<WidgetSelectorGroupStylesNames> {
	groupName: string;
	widgets: WidgetData<T>[];
	onVisible: VoidFunction;
	onHidden: VoidFunction;
	renderer?: (props: IWidgetRendererProps<T>) => JSX.Element;
	cols?: number;
	onWidgetSelect: (widget: Omit<WidgetData<T>, 'renderer'>) => void;
}

export function DefaultWidgetRenderer<T>({
	data,
	onClick,
}: IWidgetRendererProps<T>) {
	return <UnstyledButton onClick={onClick}>{data.title}</UnstyledButton>;
}

export function WidgetSelectorGroup<T>({
	groupName,
	widgets,
	onVisible,
	onHidden,
	classNames,
	styles,
	unstyled,
	className,
	renderer = DefaultWidgetRenderer,
	cols = 1,
	onWidgetSelect,
	...others
}: IWidgetSelectorGroupProps<T>) {
	const { classes, cx } = useStyles(undefined, {
		name: 'WidgetSelectorGroup',
		classNames,
		styles,
		unstyled,
	});

	const { ref, entry } = useIntersection();

	useUpdateEffect(() => {
		if (entry?.isIntersecting) {
			onVisible();
		} else {
			onHidden();
		}
	}, [entry?.isIntersecting]);

	return (
		<Stack className={cx(classes.root, className)} {...others}>
			<Box ref={ref}>
				<Title className={cx(classes.groupTitle, classNames?.groupTitle)}>
					{groupName}
				</Title>
			</Box>
			<SimpleGrid
				cols={cols}
				className={classes.widgetItemsWrapper}
				breakpoints={[
					{ maxWidth: 'sm', cols: 1 },
					{ maxWidth: 'md', cols: 2 },
				]}
			>
				{widgets.map((widget) => {
					const props = {
						data: widget,
						onClick: () => onWidgetSelect(widget),
					};

					// Renderer from widget data takes precedence
					// over the one passed as a prop
					const widgetRenderer = widget?.renderer
						? widget.renderer(props)
						: React.createElement(renderer, props);

					return (
						<Box
							key={widget.key || `${groupName}__${widget.title}`}
							className={classes.widgetWrapper}
						>
							{widgetRenderer}
						</Box>
					);
				})}
			</SimpleGrid>
		</Stack>
	);
}
