import { showNotification } from '@mantine/notifications';
import type { MutationFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { capitalize } from 'lodash-es';
import { Icon } from '@repo/foundations';
import { apiClient, getEndpoints } from '../../common';
import type { Namespace } from '../../types';
import { secodaEntitiesQueryKeyFactory } from './constants';

export type BulkDeleteData = Array<{
	id: string;
}>;

type RequestData = {
	data: BulkDeleteData;
	signal?: AbortSignal;
};

export function getDefaultBulkDeleteFn(
	namespace: Namespace
): MutationFunction<string[], RequestData> {
	return async ({ data, signal }) => {
		const baseUrl = getEndpoints(namespace).root();
		const url = `${baseUrl}bulk_delete`;

		const { data: responseData } = await apiClient.post<string[]>(url, data, {
			signal,
		});
		return responseData;
	};
}

interface UseBulkDeleteSecodaEntitiesArgs {
	name?: string;
}

function useBulkDeleteSecodaEntities({
	name = 'resources',
}: UseBulkDeleteSecodaEntitiesArgs = {}) {
	const { namespace } = secodaEntitiesQueryKeyFactory;
	const mutationFn = getDefaultBulkDeleteFn(namespace);

	return useMutation<string[], unknown, RequestData>({
		mutationFn,
		onError: () => {
			showNotification({
				title: `Error deleting ${name}`,
				message: 'If the issues persists, please contact support',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		},
		onSuccess: () => {
			showNotification({
				title: capitalize(`${name} deleted`),
				message: `The ${name} have been deleted`,
				color: 'green',
				icon: <Icon name="check" />,
			});
		},
	});
}

export default useBulkDeleteSecodaEntities;
