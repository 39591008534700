import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';

export type TunnelColumnFields = 'host' | 'username' | 'port';

export const TunnelColumns: ColumnDefinition = {
	host: {
		field: 'host',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
		flex: 1,

		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		checkboxSelection: true,
	},
	username: {
		field: 'username',
		editable: true,
		sortable: true,
	},
	port: {
		field: 'port',
		editable: true,
		sortable: true,
	},
};
