import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useFeatureFlags } from '../../utils/featureFlags';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import {
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
	DEFAULT_FILTER_OPTIONS,
	FILTER_OPTIONS_DIVIDER,
} from './constants';
import { SearchFilterV2Store, SearchFilterV2StoreContext } from './store';
import { FilterOptionType } from './types';

export function SearchFiltersV2Provider({ children }: { children: ReactNode }) {
	const { dataQualityScore, aiFilters } = useFeatureFlags();
	const { enableAi } = useAiEnabled();

	const defaultSearchFilterV2Store = useMemo(() => {
		let options = dataQualityScore
			? DEFAULT_FILTER_OPTIONS_WITH_DQS
			: DEFAULT_FILTER_OPTIONS;

		if (aiFilters && enableAi) {
			options = [FilterOptionType.AI, FILTER_OPTIONS_DIVIDER, ...options];
		}

		return new SearchFilterV2Store(options);
	}, [aiFilters, dataQualityScore, enableAi]);

	return (
		<SearchFilterV2StoreContext.Provider value={defaultSearchFilterV2Store}>
			{children}
		</SearchFilterV2StoreContext.Provider>
	);
}
