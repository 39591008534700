import { Notifications } from '@mantine/notifications';
import { useEventListener } from 'ahooks';
import 'focus-visible/dist/focus-visible';
import { Suspense, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router';
import { IntercomProvider } from 'react-use-intercom';
import { SpeedInsights } from '@vercel/speed-insights/react';
import GlobalBackgroundJob from './components/GlobalBackgroundJob';
import { FullWidthLoadingSpinner } from './components/LoadingSpinner';
import { Providers } from './providers';
import { router } from './router';
import { CANNY_APP_ID } from './utils/canny/canny';
import { CannyProvider } from './utils/canny/views/CannyProvider';
import { INTERCOM_APP_ID } from './utils/intercom';
import {
	SearchFilterStore,
	SearchFilterStoreContext,
} from './pages/SearchPage/FilterCarousel/store';
import { SearchFiltersV2Provider } from './components/Filter/provider';
import { DatabuilderJobPollingProvider } from './api/hooks/databuilderJob/context';
import { ErrorBoundary } from './components/ErrorBoundary';
import {
	getWithExpiry,
	setWithExpiry,
} from './components/ErrorBoundary/errorFallbackRenderer';

export function App() {
	const unhandledRejection = useCallback(async (ev: PromiseRejectionEvent) => {
		if (ev.reason && ev.reason.name === 'DatabaseClosedError') {
			// Sometimes the Dexie cache can get corrupted, so we need to handle this.
			const dbs = await window.indexedDB.databases();
			dbs.forEach((db) => {
				if (db.name) {
					window.indexedDB.deleteDatabase(db.name);
				}
			});
		}
	}, []);

	useEventListener('vite:preloadError', () => {
		if (!getWithExpiry('chunk_failed')) {
			setWithExpiry('chunk_failed', 'true', 10000);
			window.location.reload();
		}
	});

	useEventListener('unhandledrejection', unhandledRejection);

	// This is shared between the outline editor search modal, and the search page.
	// The table components override this store with their own instance.
	const [defaultSearchFilterStore] = useState(new SearchFilterStore());

	//  Mantine `Notifications` and `ModalsProvider` cannot be stored in the generic
	//  `Providers`, because it is reused in the outline editor and will duplicate
	//  calls to `modals.` and `notifications.`

	return (
		<Providers>
			<SpeedInsights />
			<SearchFiltersV2Provider>
				<SearchFilterStoreContext.Provider value={defaultSearchFilterStore}>
					<DatabuilderJobPollingProvider>
						<IntercomProvider appId={INTERCOM_APP_ID}>
							<CannyProvider appId={CANNY_APP_ID}>
								<Notifications limit={3} />
								<ErrorBoundary>
									<Suspense fallback={<FullWidthLoadingSpinner />}>
										<Helmet titleTemplate="%s - Secoda" defaultTitle="Secoda" />
										<div id="main">
											<RouterProvider router={router} />
											<GlobalBackgroundJob />
										</div>
									</Suspense>
								</ErrorBoundary>
							</CannyProvider>
						</IntercomProvider>
					</DatabuilderJobPollingProvider>
				</SearchFilterStoreContext.Provider>
			</SearchFiltersV2Provider>
		</Providers>
	);
}
