import { showNotification } from '@mantine/notifications';
import { makeAutoObservable } from 'mobx';
import slugify from 'slugify';
import { api } from '../../../network';
import { Metric } from '../../lib/models';
import { MetricNumericFormat } from '../Chart';
import { fetchExecutionStatusWithRetry } from './utils';

type ExecutionStatus = {
	logs: string;
	status: 'completed' | 'failed' | 'na';
};

class SqlChartsStore {
	metricId: string;

	metric: Metric;

	results: unknown[][] = [];

	executing = false;

	executionStatus: ExecutionStatus = {
		logs: '',
		status: 'na',
	};

	hasData = true;

	displayErrorDialog = false;

	constructor(metric: Metric) {
		this.metric = new Metric(metric);
		this.metricId = metric.id;
		this.results = this.metric.results;
		this.executionStatus = {
			logs: this.metric.last_error,
			status: this.metric.last_status,
		};

		makeAutoObservable(this);
	}

	showErrorDialog = () => {
		this.displayErrorDialog = true;
	};

	hideErrorDialog = () => {
		this.displayErrorDialog = false;
	};

	setExecuting(value: boolean) {
		this.executing = value;
	}

	setExecutionStatus(executionStatus: ExecutionStatus) {
		this.executionStatus = executionStatus;
	}

	setResults(results: unknown[][]) {
		this.results = results;
	}

	setFormatNumber(value: MetricNumericFormat) {
		this.metric.numeric_format = value;
	}

	download = async () => {
		const title = `${slugify(this.metric?.title ?? 'Export')}.csv`;

		try {
			// Use fetch to get the presigned url, instead of axios, because
			// axios has middleware that appends slashes.
			const response = await fetch(
				`${api()}file/presign_download/?path=metrics&unique_file_key=${
					this.metric.id
				}&original_name=${encodeURIComponent(title)}`
			);
			window.open((await response.text()).replaceAll('"', ''), '_blank');
		} catch (error) {
			showNotification({
				title: 'Error',
				message: 'Failed to download file',
				color: 'red',
			});
		}
	};

	execute = async (flush: {
		(): ReturnType<(value: string) => Promise<void>> | undefined;
	}) => {
		if (this.executing) {
			return;
		}

		this.setExecuting(true);

		// Flush the debounce update to ensure the latest version of the metric is used.
		await flush?.();

		// Ensure this metric is a new instance, so it has `execute()` method.
		this.metric = new Metric(this.metric);

		const {
			data: { id: executionId },
		} = await this.metric.execute();

		const url = `${api()}metric/executions/${executionId}/`;
		const executionStatus = await fetchExecutionStatusWithRetry(url);

		await this.metric.sync();

		this.setResults(this.metric.results);

		this.setExecuting(false);
		this.setExecutionStatus(executionStatus);

		if (executionStatus.status === 'failed') {
			this.showErrorDialog();
		}
	};
}

export default SqlChartsStore;
