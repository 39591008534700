import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { authHeaders } from '../../api/common';
import { BaseModel } from './base';
import { SecodaEntity } from './entity';

export interface ISlackHook extends BaseModel {
	entity: string;
	slack_conversation_id: string;
	slack_conversation_name: string;
}

export interface ISlackChannel {
	id: string;
	channel: string;
	name: string;
}

export class Slack extends BaseModel {
	constructor(obj: Slack) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['integration', 'slack'];
	}

	static get fields() {
		return SecodaEntity.fields.concat([
			'workspace_id',
			'team_id',
			'team_name',
			'webhook_channel',
			'webhook_channel_id',
			'incidents_channel',
			'incidents_channel_id',
			'webhook_configuration_url',
			'webhook_url',
		]);
	}

	static async get_conversations(refresh = false) {
		try {
			const res = await axios.get(`${Slack.apiRootPath}conversations/`, {
				...authHeaders(),
				params: {
					refresh,
				},
			});
			return res.data.results;
		} catch (e) {
			return [];
		}
	}

	static async channelName() {
		return axios.get(`${Slack.apiRootPath}channel_name/`, authHeaders());
	}

	static async channelTest(channel: string) {
		return axios.get(`${Slack.apiRootPath}channel_test/`, {
			...authHeaders(),
			params: {
				channel,
			},
		});
	}
}
