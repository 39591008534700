import {
	collectionsQueryKeyFactory,
	dictionaryTermsQueryKeyFactory,
	documentsQueryKeyFactory,
	eventPropertiesQueryKeyFactory,
	eventsQueryKeyFactory,
	fetchCollectionList,
	fetchDictionaryTermList,
	fetchDocumentList,
	fetchEventList,
	fetchEventPropertyList,
	fetchIncidentList,
	fetchIntegrationList,
	fetchJobList,
	fetchMeasurementList,
	fetchMonitorList,
	fetchQuestionList,
	fetchReportList,
	fetchSchemaList,
	fetchSecodaEntities,
	fetchTunnelList,
	fetchUserList,
	integrationsQueryKeyFactory,
	jobsQueryKeyFactory,
	questionsQueryKeyFactory,
	reportsQueryKeyFactory,
	schemasQueryKeyFactory,
	secodaEntitiesQueryKeyFactory,
	tunnelsQueryKeyFactory,
	updateCollection,
	updateDictionaryTerm,
	updateDocument,
	updateEvent,
	updateEventProperty,
	updateIntegration,
	updateJob,
	updateMonitor,
	updateQuestion,
	updateReport,
	updateSchema,
	updateTunnel,
	updateUser,
	usersQueryKeyFactory,
} from '../../api';
import {
	fetchAutomationList,
	updateAutomation,
} from '../../api/hooks/automation';
import { automationQueryKeyFactory } from '../../api/hooks/automation/constants';
import { automationHistoryQueryKeyFactory } from '../../api/hooks/automationHistory/constants';
import { fetchAutomationHistoryList } from '../../api/hooks/automationHistory/useAutomationHistory';
import {
	fetchAutomationJobList,
	updateAutomationJob,
} from '../../api/hooks/automationJob';
import { automationJobQueryKeyFactory } from '../../api/hooks/automationJob/constants';
import {
	databasesQueryKeyFactory,
	fetchDatabaseList,
	updateDatabase,
} from '../../api/hooks/database';
import { lineageQueryKeyFactory } from '../../api/hooks/lineage';
import { fetchLineage } from '../../api/hooks/lineage/fetchLineage';
import {
	fetchMetricList,
	metricsQueryKeyFactory,
	updateMetric,
} from '../../api/hooks/metricTerm';
import {
	incidentsQueryKeyFactory,
	measurementsQueryKeyFactory,
	monitorsQueryKeyFactory,
} from '../../api/hooks/monitoring/constants';
import {
	fetchCatalogList,
	updateCatalog,
} from '../../api/hooks/resourceCatalog';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import { updateSecodaEntity } from '../../api/hooks/secodaEntity/fetchSecodaEntities';
import { fetchTestRunList } from '../../api/hooks/table';
import {
	fetchTemplateList,
	templatesQueryKeyFactory,
} from '../../api/hooks/templates';
import { EntityType } from '../../lib/types';
import { useColumns as useIncidentColumns } from '../../pages/MonitorListPage/IncidentList.hooks';
import { useColumns as useMonitorColumns } from '../../pages/MonitorListPage/MonitorList.hooks';
import { useColumns as useCatalogColumns } from '../../pages/TeamCatalogPage/TeamCatalogPage.hooks';
import { useColumns as useCollectionColumns } from '../../pages/TeamCollectionsPage/TeamCollectionsPage.hooks';
import { useColumns as useDictionaryTermColumns } from '../../pages/TeamDictionaryTermsPage/TeamDictionaryTermsPage.hooks';
import { useColumns as useDocumentColumns } from '../../pages/TeamDocumentsPage/TeamDocumentsPage.hooks';
import { useColumns as useMetricColumns } from '../../pages/TeamMetricsPage/hooks';
import { useColumns as useQuestionColumns } from '../../pages/TeamQuestionsPage/TeamQuestionsPage.hooks';
import { AutomationColumns } from './TableView/columns/automation';
import { AutomationJobColumns } from './TableView/columns/automationJob';
import { CatalogColumns } from './TableView/columns/catalog';
import { ChartColumns } from './TableView/columns/chart';
import { CollectionColumns } from './TableView/columns/collection';
import { ColumnColumns } from './TableView/columns/column';
import { DefaultColumns } from './TableView/columns/common';
import { DashboardColumns } from './TableView/columns/dashboard';
import { DictionaryTermColumns } from './TableView/columns/dictionaryTerm';
import { DocumentColumns } from './TableView/columns/document';
import { EventColumns } from './TableView/columns/event';
import { EventPropertyColumns } from './TableView/columns/eventProperty';
import { IncidentsColumns } from './TableView/columns/incidents';
import { IntegrationColumns } from './TableView/columns/integrations';
import { JobColumns } from './TableView/columns/job';
import { LineageColumns } from './TableView/columns/lineage';
import { MeasurementColumns } from './TableView/columns/measurement';
import { MetricColumns } from './TableView/columns/metric';
import { MonitorsColumns } from './TableView/columns/monitors';
import { QuestionColumns } from './TableView/columns/questions';
import { ReportsColumns } from './TableView/columns/reports';
import { SchemaColumns } from './TableView/columns/schemas';
import { SecodaEntityColumns } from './TableView/columns/secodaEntity';
import { TableColumns } from './TableView/columns/table';
import { TemplateColumns } from './TableView/columns/template';
import { TestRunColumns } from './TableView/columns/testRuns';
import { TunnelColumns } from './TableView/columns/tunnels';
import { UserColumns } from './TableView/columns/user';
import { createReactQueryServer } from './helpers';
import type { CatalogConfig, CatalogServer, CatalogServerType } from './types';

const fetchSecodaEntitiesByType =
	(entityType: EntityType): CatalogServer['queryFn'] =>
	(args) =>
		fetchSecodaEntities({
			filters: {
				entity_type: entityType,
				...args.filters,
			},
			page: args.page,
		});

// NOTE: Remember to update api/contrib/catalog_constants.py when adding a new mapping
export const CATALOG_COLUMN_MAPPING: Record<string, CatalogConfig> = {
	catalog: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Catalog',
		columns: CatalogColumns,
		server: {
			...createReactQueryServer(
				'catalog',
				fetchCatalogList,
				resourceCatalogQueryKeyFactory
			),
			update: updateCatalog,
		},
	},
	monitorTables: {
		tableV2ColumnsHook: useMonitorColumns,
		label: 'Monitor tables',
		columns: CatalogColumns,
		server: {
			...createReactQueryServer(
				'catalog',
				fetchCatalogList,
				resourceCatalogQueryKeyFactory
			),
			update: updateCatalog,
		},
	},
	secoda_entity: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Resources',
		columns: SecodaEntityColumns,
		server: {
			...createReactQueryServer(
				'secoda_entity',
				fetchSecodaEntities,
				secodaEntitiesQueryKeyFactory
			),
			update: updateSecodaEntity,
		},
	},
	[EntityType.table]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Tables',
		columns: TableColumns,
		server: {
			...createReactQueryServer(
				EntityType.table,
				fetchSecodaEntitiesByType(EntityType.table),
				secodaEntitiesQueryKeyFactory
			),
			update: updateSecodaEntity,
		},
	},
	[EntityType.column]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Columns',
		columns: ColumnColumns,
		server: {
			...createReactQueryServer(
				EntityType.column,
				fetchSecodaEntitiesByType(EntityType.column),
				secodaEntitiesQueryKeyFactory
			),
			update: updateSecodaEntity,
		},
	},
	[EntityType.dashboard]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Dashboards',
		columns: DashboardColumns,
		server: {
			...createReactQueryServer(
				EntityType.dashboard,
				fetchSecodaEntitiesByType(EntityType.dashboard),
				secodaEntitiesQueryKeyFactory
			),
			update: updateSecodaEntity,
		},
	},
	[EntityType.chart]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Charts',
		columns: ChartColumns,
		server: {
			...createReactQueryServer(
				EntityType.chart,
				fetchSecodaEntitiesByType(EntityType.chart),
				secodaEntitiesQueryKeyFactory
			),
			update: updateSecodaEntity,
		},
	},
	[EntityType.job]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Jobs',
		columns: JobColumns,
		server: {
			...createReactQueryServer(
				EntityType.job,
				fetchJobList,
				jobsQueryKeyFactory
			),
			update: updateJob,
		},
	},
	[EntityType.event]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Events',
		columns: EventColumns,
		server: {
			...createReactQueryServer(
				EntityType.event,
				fetchEventList,
				eventsQueryKeyFactory
			),
			update: updateEvent,
		},
	},
	[EntityType.event_property]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Event Properties',
		columns: EventPropertyColumns,
		server: {
			...createReactQueryServer(
				EntityType.event_property,
				fetchEventPropertyList,
				eventPropertiesQueryKeyFactory
			),
			update: updateEventProperty,
		},
	},
	[EntityType.dictionary_term]: {
		tableV2ColumnsHook: useDictionaryTermColumns,
		label: 'Dictionary terms',
		columns: DictionaryTermColumns,
		server: {
			...createReactQueryServer(
				EntityType.dictionary_term,
				fetchDictionaryTermList,
				dictionaryTermsQueryKeyFactory
			),
			update: updateDictionaryTerm,
		},
	},
	[EntityType.metric]: {
		tableV2ColumnsHook: useMetricColumns,
		label: 'Metrics',
		columns: MetricColumns,
		server: {
			...createReactQueryServer(
				EntityType.metric,
				fetchMetricList,
				metricsQueryKeyFactory
			),
			update: updateMetric,
		},
	},
	[EntityType.document]: {
		tableV2ColumnsHook: useDocumentColumns,
		label: 'Documents',
		columns: DocumentColumns,
		server: {
			...createReactQueryServer(
				EntityType.document,
				fetchDocumentList,
				documentsQueryKeyFactory
			),
			update: updateDocument,
		},
	},
	[EntityType.question]: {
		tableV2ColumnsHook: useQuestionColumns,
		label: 'Questions',
		columns: QuestionColumns,
		server: {
			...createReactQueryServer(
				EntityType.question,
				fetchQuestionList,
				questionsQueryKeyFactory
			),
			update: updateQuestion,
		},
	},
	[EntityType.collection]: {
		tableV2ColumnsHook: useCollectionColumns,
		label: 'Collections',
		columns: CollectionColumns,
		server: {
			...createReactQueryServer(
				EntityType.collection,
				fetchCollectionList,
				collectionsQueryKeyFactory
			),
			update: updateCollection,
		},
	},
	[EntityType.user]: {
		label: 'Users',
		columns: UserColumns,
		server: {
			...createReactQueryServer(
				EntityType.user,
				fetchUserList,
				usersQueryKeyFactory
			),
			update: updateUser,
		},
	},
	[EntityType.schema]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Schema',
		columns: SchemaColumns,
		server: {
			...createReactQueryServer(
				EntityType.schema,
				fetchSchemaList,
				schemasQueryKeyFactory
			),
			update: updateSchema,
		},
	},
	[EntityType.database]: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Database',
		columns: SchemaColumns,
		server: {
			...createReactQueryServer(
				EntityType.database,
				fetchDatabaseList,
				databasesQueryKeyFactory
			),
			update: updateDatabase,
		},
	},
	entities: {
		tableV2ColumnsHook: useCatalogColumns,
		label: 'Entities',
		columns: DefaultColumns,
		server: createReactQueryServer(
			'entities',
			fetchSecodaEntities,
			secodaEntitiesQueryKeyFactory
		),
	},
	monitors: {
		tableV2ColumnsHook: useMonitorColumns,
		label: 'Monitors',
		columns: MonitorsColumns,
		server: {
			...createReactQueryServer(
				'monitors',
				fetchMonitorList,
				monitorsQueryKeyFactory
			),
			update: updateMonitor,
		},
	},
	incidents: {
		tableV2ColumnsHook: useIncidentColumns,
		label: 'Incidents',
		columns: IncidentsColumns,
		server: createReactQueryServer(
			'incidents',
			fetchIncidentList,
			incidentsQueryKeyFactory
		),
	},
	measurements: {
		label: 'Measurements',
		columns: MeasurementColumns,
		server: createReactQueryServer(
			'measurements',
			fetchMeasurementList,
			measurementsQueryKeyFactory
		),
	},
	downstreamResources: {
		label: 'Downstream Resources',
		columns: LineageColumns,
		server: createReactQueryServer(
			'downstreamResources',
			fetchLineage,
			lineageQueryKeyFactory
		),
	},
	integrations: {
		label: 'Integrations',
		columns: IntegrationColumns,
		server: {
			...createReactQueryServer(
				'integrations',
				fetchIntegrationList,
				integrationsQueryKeyFactory
			),
			update: updateIntegration,
		},
	},
	tunnels: {
		label: 'Tunnels',
		columns: TunnelColumns,
		server: {
			...createReactQueryServer(
				'tunnels',
				fetchTunnelList,
				tunnelsQueryKeyFactory
			),
			update: updateTunnel,
		},
	},
	reports: {
		label: 'Reports',
		columns: ReportsColumns,
		server: {
			...createReactQueryServer(
				'reports',
				fetchReportList,
				reportsQueryKeyFactory
			),
			update: updateReport,
		},
	},
	automations: {
		label: 'Automations',
		columns: AutomationColumns,
		server: {
			...createReactQueryServer(
				'automations',
				fetchAutomationList,
				automationQueryKeyFactory
			),
			update: updateAutomation,
		},
	},
	automation_jobs: {
		label: 'Automation jobs',
		columns: AutomationJobColumns,
		server: {
			...createReactQueryServer(
				'automation_jobs',
				fetchAutomationJobList,
				automationJobQueryKeyFactory
			),
			update: updateAutomationJob,
		},
	},
	automation_history: {
		label: 'Automation history',
		columns: AutomationJobColumns,
		server: {
			...createReactQueryServer(
				'automation_history',
				fetchAutomationHistoryList,
				automationHistoryQueryKeyFactory
			),
			update: undefined,
		},
	},
	[EntityType.template]: {
		label: 'Templates',
		columns: TemplateColumns,
		server: {
			...createReactQueryServer(
				'template',
				fetchTemplateList,
				templatesQueryKeyFactory
			),
			update: updateSecodaEntity,
		},
	},
	[EntityType.test_run]: {
		label: 'Test Runs',
		columns: TestRunColumns,
		server: {
			...createReactQueryServer(
				EntityType.test_run,
				fetchTestRunList,
				templatesQueryKeyFactory
			),
			update: updateSecodaEntity,
		},
	},
};

export const CATALOG_TYPES_ENABLE_DATA_QUALITY_SCORE: Array<CatalogServerType> =
	['catalog', EntityType.table];
