import { forEach, map, size } from 'lodash-es';
import { queryClient, searchQueryKeyFactory } from '../../api';
import { createQueryKeys } from '../../api/factories';
import { getSearchFilters, search } from '../../lib/models';
import type { EntityType } from '../../lib/types';
import type {
	FilterOption,
	SearchFilters,
} from './FilterCarousel/FilterCarousel.constants';
import {
	FilterValue,
	SortValue,
} from './FilterCarousel/FilterCarousel.constants';

export const SEARCH_QUERY_CLIENT_OPTIONS = {
	staleTime: 15 * 1000 * 60, // 15 minutes
	cacheTime: 15 * 1000 * 60, // 15 minutes
};

export const SEARCH_FILTERS_NAMESPACE = ['search', 'filters'];
export const SEARCH_FILTERS_QUERY_KEY = createQueryKeys(
	SEARCH_FILTERS_NAMESPACE
);

export function parseSearchFilters(
	sort: SortValue = SortValue.RELEVANCE,
	searchFilters: SearchFilters | null = null,
	excludeTypes: EntityType[] = []
) {
	const filters: Record<string, (string | boolean | object)[]> = {};
	if (searchFilters) {
		forEach(FilterValue, (key) => {
			const value = searchFilters[key];

			if (size(value.selectedOptions)) {
				// Special native_type filter
				// We need to filter on [native_type='' && entity_type='']
				if (key === FilterValue.NATIVE_TYPE) {
					const filterKey = value.isInclude ? key : `${key}__exclude`;
					filters[filterKey] = map(value.selectedOptions, (option) => ({
						entity_type: option.entityType,
						native_type: option.value,
					}));
					return;
				}

				let filterKey = searchFilters[key].isRadio ? key : `${key}__in`;
				if (!value.isInclude) {
					filterKey = `${filterKey}__exclude`;
				}
				filters[filterKey] = map(value.selectedOptions, 'value');
			}
		});
	}

	if (size(excludeTypes) > 0) {
		filters.entity_type__exclude = excludeTypes;
	}

	return {
		sort,
		...filters,
	};
}

export const getSearchResults = async (
	term: string,
	sort: SortValue = SortValue.RELEVANCE,
	searchFilters: SearchFilters | null = null,
	page = 1,
	excludeTypes = [] as EntityType[]
) => {
	const parsedSearchFilters = parseSearchFilters(
		sort,
		searchFilters,
		excludeTypes
	);

	const filters = {
		search_term: term,
		...parsedSearchFilters,
		page,
	};

	const response = await queryClient.fetchQuery({
		queryKey: searchQueryKeyFactory.list(page, filters),
		queryFn: async () => {
			const { data } = await search(filters);
			return data;
		},
	});

	return {
		results: response.results,
		totalPages: response.total_pages,
	};
};

export const getEntityFilters = (): Promise<
	Record<'databases' | 'groups' | 'schemas' | 'native_types', FilterOption[]>
> =>
	queryClient
		.fetchQuery({
			queryKey: SEARCH_FILTERS_QUERY_KEY.list(1, {}),
			queryFn: () => getSearchFilters(),
			...SEARCH_QUERY_CLIENT_OPTIONS,
		})
		.catch(() => ({
			databases: [],
			schemas: [],
			groups: [],
			native_types: [],
		}));
