import { forwardRef } from 'react';
import { UnstyledButton, createStyles } from '@mantine/core';
import type { UnstyledButtonProps } from '@mantine/core';
import { rem } from '@mantine/styles';
import { Icon, type IconNames } from '../Icon';
import { typography } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';

export type PlainButtonTones = 'default' | 'critical';
export type PlainButtonSizes = 'xs' | 'sm' | 'md';

interface PlainButtonProps
	extends Omit<UnstyledButtonProps, 'unstyled' | 'variant' | 'children'> {
	/** Controls button appearance  */
	tone?: PlainButtonTones;
	/** Predefined button size */
	size?: PlainButtonSizes;
	/** Button label */
	children: React.ReactNode;
	/** Callback for when it's clicked */
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	/** Disabled state */
	disabled?: boolean;

	/** Adds icon before button label  */
	leftIconName?: IconNames;
	/** Adds icon after button label  */
	rightIconName?: IconNames;

	id?: string;
}

interface PlainButtonStyleProps {
	size: PlainButtonSizes;
	tone: PlainButtonTones;
}

const useStyles = createStyles(
	(theme, { size, tone }: PlainButtonStyleProps) => {
		let labelColor: ColorNames = 'text/emphasis/default';
		let hoverLabelColor: ColorNames = 'text/emphasis/hover';
		let activeLabelColor: ColorNames = 'text/emphasis/active';
		let fontSize: string = typography.text.sm;
		let lineHeight: string = typography.lineHeight.text.sm;

		if (tone === 'critical') {
			labelColor = 'text/critical/default';
			hoverLabelColor = 'text/critical/hover';
			activeLabelColor = 'text/critical/active';
		}

		if (size === 'md') {
			fontSize = typography.text.md;
			lineHeight = typography.lineHeight.text.md;
		} else if (size === 'xs') {
			fontSize = typography.text.xs;
			lineHeight = typography.lineHeight.text.md;
		}

		return {
			container: {
				display: 'inline-block',
				position: 'relative',

				fontSize,
				lineHeight,
				fontWeight: typography.weight.semibold,

				// We need a radius so that the focus state is rounded
				borderRadius: theme.other.space[2],

				color: theme.other.getColor(labelColor),

				textDecorationLine: 'none',

				'&:hover': {
					color: theme.other.getColor(hoverLabelColor),
					textDecorationLine: 'underline',
				},
				'&:active': {
					color: theme.other.getColor(activeLabelColor),
					textDecorationLine: 'underline',
				},
				'&:focus': {
					outline: `solid ${theme.other.getColor(
						'border/emphasis/default'
					)} 2px`,
					outlineOffset: rem(theme.other.space[0.25]),
				},
				'&:disabled': {
					textDecorationLine: 'none',
					color: theme.other.getColor('text/primary/disabled'),
				},
			},
			inner: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				overflow: 'visible',

				height: '100%',
			},
			label: {
				whiteSpace: 'nowrap',
				height: '100%',
				overflow: 'hidden',
				display: 'flex',
				alignItems: 'center',
			},
		};
	}
);

const PlainButton = forwardRef<HTMLButtonElement, PlainButtonProps>(
	(
		{
			id,
			children,
			onClick,
			tone = 'default',
			size = 'sm',
			disabled = false,
			leftIconName,
			rightIconName,
			className,
			classNames,
			styles,
			...other
		},
		ref
	) => {
		const { classes, cx } = useStyles(
			{ size, tone },
			{
				name: 'PlainButton',
				classNames,
				styles,
			}
		);

		return (
			<UnstyledButton
				id={id}
				className={cx(classes.container, className)}
				disabled={disabled}
				onClick={onClick}
				ref={ref}
				{...other}
			>
				<span className={classes.inner}>
					{leftIconName && <Icon name={leftIconName} />}
					<span className={classes.label}>{children}</span>
					{rightIconName && <Icon name={rightIconName} />}
				</span>
			</UnstyledButton>
		);
	}
);

PlainButton.displayName = 'PlainButton';

export default PlainButton;
