import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';

export const MonitorsColumns: ColumnDefinition = {
	name: {
		field: 'name',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
		flex: 1,
		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		checkboxSelection: true,
	},
	metric_type: {
		field: 'metric_type',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
		flex: 1,
	},
	last_run: {
		field: 'last_attempted_at',
	},
	next_run_at: {
		field: 'next_run_at',
	},
	resource: {
		field: 'resource',
		editable: false,
		sortable: false,
		type: ColumnType.textFilterColumn,
	},
	status: {
		field: 'monitor_status',
	},
	frequency: {
		field: 'frequency',
		sortable: true,
		editable: true,
	},
};
