import {
	createStyles,
	HoverCard,
	Modal,
	ScrollArea,
	Title,
	UnstyledButton,
} from '@mantine/core';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { useUpdateEffect } from 'ahooks';
import { typography } from '@repo/theme/primitives';
import { Text } from '@repo/foundations';
import MarkdownRenderer from '../MarkdownRenderer';
import MultiLineEditorTabs from '../CatalogView/TableView/selectors/MultiLineEditorSelector/MultiLineEditorTabs';

export interface IPropertyEditorProps {
	value: string;
	onChange: (value: string) => void;
	readOnly?: boolean;
}

const useStyles = createStyles(
	(theme, { readOnly }: { readOnly: boolean }) => ({
		input: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			fontSize: theme.fontSizes.sm,
			fontWeight: typography.weight.regular,
			lineHeight: '16px',
			color: theme.colors.gray[9],
		},
		wrapper: {
			flexWrap: 'nowrap',
			cursor: !readOnly ? 'pointer' : 'default',
			marginLeft: `-${theme.spacing.sm}`,
			padding: `${theme.spacing['3xs']} ${theme.spacing.xs} `,
			borderRadius: theme.spacing.xs,
			'&:hover': {
				backgroundColor: theme.other.getColor('surface/input/hover'),
			},
		},
		editor: {
			fontSize: theme.fontSizes.sm,
			fontWeight: typography.weight.regular,
			lineHeight: '16x',
			color: theme.colors.gray[9],
		},
	})
);

function TextPropertyEditor({
	value: initialValue,
	onChange,
	readOnly = false,
}: IPropertyEditorProps) {
	const { classes } = useStyles({ readOnly });
	const [value, handleChange] = useInputState(initialValue);
	const [opened, handlers] = useDisclosure(false);

	useUpdateEffect(() => {
		onChange(value);
	}, [value]);

	const handleOnClick = () => {
		if (!readOnly) {
			handlers.open();
		}
	};

	return (
		<>
			{/* Truncates and display full in hover card if more than 3 lines */}
			<HoverCard
				position="left"
				shadow="md"
				width={300}
				disabled={value.split('\n').length < 4}
				withinPortal
			>
				<HoverCard.Target>
					<UnstyledButton className={classes.wrapper} onClick={handleOnClick}>
						{value === '' && (
							<Text color="text/secondary/default" size="sm">
								Add text
							</Text>
						)}
						{value !== '' && (
							<MarkdownRenderer className={classes.editor} inline lineClamp={3}>
								{value}
							</MarkdownRenderer>
						)}
					</UnstyledButton>
				</HoverCard.Target>
				<HoverCard.Dropdown>
					<ScrollArea.Autosize mah={500}>
						<MarkdownRenderer className={classes.editor}>
							{value}
						</MarkdownRenderer>
					</ScrollArea.Autosize>
				</HoverCard.Dropdown>
			</HoverCard>
			<Modal
				size="70%"
				opened={opened}
				onClose={handlers.close}
				title={<Title order={4}>Title</Title>}
				centered
				scrollAreaComponent={ScrollArea}
			>
				<MultiLineEditorTabs
					placeholder="Placeholder"
					previewPlaceholder="Preview placeholder"
					charPress={undefined}
					defaultValue={value}
					moveCaretToEnd={undefined}
					onChange={handleChange}
				/>
			</Modal>
		</>
	);
}

export default TextPropertyEditor;
