import { Badge, createStyles, Text } from '@mantine/core';
import type { ICollection } from '../../../../api';
import { primaryTextColor, primaryTextSize, primaryTextWeight } from '../theme';

interface IStyleParams {
	inline?: boolean;
}

interface ICollectionChipProps extends IStyleParams {
	collection: ICollection;
}

const useStyles = createStyles((theme, { inline }: IStyleParams) => ({
	icon: {
		fontSize: 14,
		marginRight: 2,
	},
	badge: {
		backgroundColor: theme.colors.gray[0],
		alignItems: 'center',
		padding: inline ? 0 : '0 12px',
	},
	label: {
		fontSize: primaryTextSize,
		color: primaryTextColor,
		fontWeight: primaryTextWeight,
		textTransform: 'none',
	},
}));

function CollectionChip({ collection, inline = false }: ICollectionChipProps) {
	const { classes } = useStyles({ inline });

	const icon =
		collection.id === 'empty' ? null : (
			<Text className={classes.icon}>{collection?.icon ?? '🗂️'}</Text>
		);

	return (
		<Badge
			classNames={{ root: classes.badge, inner: classes.label }}
			leftSection={icon}
		>
			{collection.title}
		</Badge>
	);
}

export default CollectionChip;
