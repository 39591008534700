import { Card, createStyles, Group, Text } from '@mantine/core';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { isNil, isNumber } from 'lodash-es';
import { memo } from 'react';
import { useNavigate } from 'react-router';
import { Icon } from '@repo/foundations';
import type { MeasurementChartData, TimeRange } from '../../../api';

import { EmptyState, type ButtonDetails } from '../../../components/EmptyState';
import SelectMenu from '../../../components/SelectMenu';
import { MEASUREMENT_TIME_RANGE_OPTIONS } from '../constants';

interface IMeasurementChartProps {
	title?: string;
	timeRange?: TimeRange;
	onTimeRangeChange?: (range: TimeRange) => void;
	values: MeasurementChartData[];
	learningMode?: boolean;
	withBorder?: boolean;
}

const useStyles = createStyles((theme) => ({
	root: {
		flexShrink: 0,
	},
	title: {
		color: theme.other.getColor('text/primary/default'),
		fontWeight: theme.other.typography.weight.bold,
	},
	content: {
		fontSize: theme.fontSizes.xl,
		fontWeight: theme.other.typography.weight.bold,
	},
	emptyState: {
		padding: theme.spacing.xl,
		paddingBottom: theme.other.space[16],
	},
}));

function MeasurementChart({
	title,
	timeRange,
	onTimeRangeChange,
	values,
	learningMode = false,
	withBorder = true,
}: IMeasurementChartProps) {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const handleRangeChange = (value: string | null) => {
		if (value === null) {
			return;
		}

		onTimeRangeChange?.(value as TimeRange);
	};

	const valueShade = theme.other.getColor('icon/success/default');
	const incidentShade = theme.other.getColor('icon/critical/default');

	const base = -values.reduce(
		(min, val) => Math.floor(Math.min(min, val.lower_threshold || 0)),
		Infinity
	);

	const emptyStateButton: ButtonDetails[] = [
		{
			name: 'Learn more',
			isPrimary: false,
			size: 'sm',
			action: () => {
				window.open('https://docs.secoda.co/features/monitoring', '_blank');
			},
			leftIconName: 'externalLink',
		},
	];
	return (
		<Card className={classes.root} withBorder={withBorder} p="md">
			<Group position="apart">
				{title && <Text className={classes.title}>{title}</Text>}
				{!learningMode && timeRange && (
					<SelectMenu
						w="14ch"
						icon={<Icon name="calendar" color="icon/primary/default" />}
						value={timeRange.toString()}
						data={MEASUREMENT_TIME_RANGE_OPTIONS}
						onChange={handleRangeChange}
					/>
				)}
			</Group>
			<Card.Section>
				{learningMode ? (
					<EmptyState
						className={classes.emptyState}
						illustrationName="ai"
						title="Automatic threshold is learning"
						description="Check back later for a detailed view of the status and history of this monitor."
						includeGoBack={false}
						buttons={emptyStateButton}
						size="sm"
					/>
				) : (
					<ReactECharts
						key={Math.random()}
						onEvents={{
							click: (event: { data: { incidentId?: string } }) => {
								const { data } = event;
								if (isNil(data?.incidentId)) {
									return;
								}

								navigate(`/incident/${data.incidentId}`);
							},
						}}
						option={{
							color: [theme.colors.primary[5]],
							tooltip: {
								confine: true,
								trigger: 'axis',
								axisPointer: {
									type: 'cross',
									animation: false,
									label: {
										backgroundColor: '#ccc',
										borderColor: '#aaa',
										borderWidth: 1,
										shadowBlur: 0,
										shadowOffsetX: 0,
										shadowOffsetY: 0,
										color: '#222',
									},
								},
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								formatter: (params: any[]) => {
									// This code will be removed soon, keeping legacy behavour for now
									// eslint-disable-next-line prefer-destructuring
									const { data } = params[0];
									const { lowerThreshold } = params[1].data;
									const { upperThreshold } = params[2].data;
									// eslint-disable-next-line prefer-destructuring
									const { name, value } = params[3];

									const date = dayjs(name).format('MMM DD, YYYY h:mm A');
									const valueLabel = (value - base).toFixed(2);

									const thresholds =
										isNumber(lowerThreshold) && isNumber(upperThreshold)
											? `${lowerThreshold.toFixed(
													2
												)} to ${upperThreshold.toFixed(2)}`
											: '';

									const dateString = `<strong>Date: </strong>${date}<br />`;
									const valueString = `<strong>Value: </strong>${valueLabel}<br/>`;
									const thresholdString = thresholds
										? `<strong>Thresholds: </strong>${thresholds}`
										: '';
									const incidentString = data.incident
										? '<br/><strong>Incident triggered</strong>'
										: '';

									return `${dateString}${valueString}${thresholdString}${incidentString}`;
								},
							},
							grid: {
								top: theme.other.space[6],
								bottom: theme.other.space[10],
								left: '7.5%',
								right: '7.5%',
							},
							xAxis: {
								type: 'category',
								nameLocation: 'middle',
								data: values.map((data) => data.truncated_time),
								axisLabel: {
									formatter: (value: string) =>
										dayjs(value).format('MMM DD, h:mm A'),
								},
								axisLine: {
									onZero: false,
								},
								boundaryGap: true,
							},

							yAxis: {
								type: 'value',
								splitNumber: 3,
								scale: true,
								axisLabel: {
									formatter: (value: number) => value - base,
								},
								axisPointer: {
									label: {
										formatter: (data: MeasurementChartData) =>
											(data.value - base).toFixed(2),
									},
								},
							},
							series: [
								{
									name: 'Incident',
									type: 'scatter',
									data: values.map((item) => {
										if (item.incident_exists) {
											return {
												value: item.value + base,
												symbol: 'circle',
												incident: true,
												incidentId: item.incident_id,
											};
										}

										return {
											value: item.value + base,
											symbol: 'none',
											incident: false,
											incidentId: null,
										};
									}),
									itemStyle: {
										color: incidentShade,
										borderColor: 'white',
										borderWidth: 2,
										opacity: 1,
									},
									symbolSize: 8,
									showSymbol: true,
									z: 3,
									emphasis: {
										focus: 'series',
										blurScope: 'coordinateSystem',
									},
								},
								{
									name: 'Lower',
									type: 'line',
									data: values.map((data) => ({
										value: data.lower_threshold
											? data.lower_threshold + base
											: undefined,
										lowerThreshold: data.lower_threshold,
									})),
									lineStyle: {
										opacity: 0,
									},
									stack: 'confidence-band',
									symbol: 'none',
								},
								{
									name: 'Upper',
									type: 'line',
									data: values.map((data) => ({
										value: data.upper_threshold
											? data.upper_threshold - data.lower_threshold
											: undefined,
										upperThreshold: data.upper_threshold,
									})),
									lineStyle: {
										opacity: 0,
									},
									areaStyle: {
										color: theme.fn.rgba(valueShade, 0.2),
										origin: 'auto',
									},
									stack: 'confidence-band',
									symbol: 'none',
									z: -1,
								},
								values.length > 1
									? {
											name: 'Value',
											type: 'line',
											data: values.map((item) => ({
												value: item.value + base,
												incident: false,
												symbol: 'none',
											})),
											lineStyle: {
												color: valueShade,
											},
											showSymbol: false,
										}
									: {
											name: 'Value',
											type: 'scatter',
											data: values.map((item) => ({
												value: item.value + base,
												incident: false,
												itemStyle: {
													color: item.incident_exists
														? incidentShade
														: valueShade,
												},
											})),
											symbolSize: 8,
											emphasis: {
												focus: 'series',
											},
										},
							],
						}}
					/>
				)}
			</Card.Section>
		</Card>
	);
}

export default memo(MeasurementChart);
