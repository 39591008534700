import type Token from 'markdown-it/lib/token';
import type { NodeSpec, Node } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import type { ReactElement } from 'react';
import { InputRule } from 'prosemirror-inputrules';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type { ComponentProps, Dispatch } from '../types';

import { ResourceLink as ReactResourceLink } from '../../../../Link';
import { resourceLinksRule } from '../rules/resourceLink';
import { isResourceUrl } from '../utils/urls';
import { ensureEntityRelation } from '../lib/ensureEntityRelation';
import ReactNode from './ReactNode';
import { NodeOptions } from './Node';

const LINK_INPUT_REGEX = /\[([^[]+)]\((\S+)\)$/;

export default class ResourceLink extends ReactNode {
	get name() {
		return 'resource_link';
	}

	get schema(): NodeSpec {
		return {
			content: 'text*',
			inline: true,
			group: 'inline',
			atom: true,
			attrs: {
				href: {
					default: '',
				},
			},
			parseDOM: [
				{
					tag: 'a[href]',
					getAttrs: (dom: string | HTMLElement) => ({
						href: (dom as HTMLElement).getAttribute('href') ?? '',
					}),
				},
			],
			toDOM: (node) => [
				'a',
				{
					href: node.attrs.href,
					contentEditable: 'false',
				},
				0,
			],
		};
	}

	get rulePlugins() {
		return [resourceLinksRule];
	}

	inputRules({ type }: NodeOptions) {
		return [
			new InputRule(LINK_INPUT_REGEX, (state, match, start, end) => {
				const [okay, alt, href] = match;
				const { tr } = state;

				if (okay && isResourceUrl(href)) {
					tr.replaceWith(
						start,
						end,
						type.create({ href }, state.schema.text(alt))
					);
				}

				return tr;
			}),
		];
	}

	component = (props: ComponentProps): ReactElement => (
		<ReactResourceLink
			href={props.node.attrs.href}
			isSelected={props.isSelected}
		/>
	);

	commands({ type }: NodeOptions) {
		return (attrs?: Record<string, unknown>) =>
			(state: EditorState, dispatch: Dispatch) => {
				dispatch(
					state.tr.insert(
						state.selection.from,
						type.create(
							attrs,
							state.schema.text(attrs?.title?.toString() ?? '')
						)
					)
				);

				ensureEntityRelation(!this.editorState.disableResourceLinking, attrs);

				return true;
			};
	}

	toMarkdown(state: MarkdownSerializerState, node: Node) {
		state.write('[');
		if (node.content.size > 0) {
			state.renderContent(node);
		} else {
			state.write(node.attrs.href);
		}
		state.write(']');
		state.write(`(${node.attrs.href})`);
	}

	parseMarkdown() {
		return {
			block: 'resource_link',
			getAttrs: (tok: Token) => ({
				href: tok.attrGet('href'),
			}),
		};
	}
}
