import type { EditorState } from 'prosemirror-state';
import { Icon } from '@repo/foundations';
import type { EditorDictionary, MenuItem } from '@repo/secoda-editor';
import isNodeActive from '../queries/isNodeActive';

export default function imageMenuItems(
	state: EditorState,
	dictionary: EditorDictionary
): MenuItem[] {
	const { schema } = state;
	const isLeftAligned = isNodeActive(schema.nodes.image, {
		layoutClass: 'left-50',
	});
	const isRightAligned = isNodeActive(schema.nodes.image, {
		layoutClass: 'right-50',
	});

	return [
		{
			name: 'alignLeft',
			tooltip: dictionary.alignLeft,
			icon: () => <Icon name="floatLeft" color="icon/primary/default" />,
			visible: true,
			active: isLeftAligned,
		},
		{
			name: 'alignCenter',
			tooltip: dictionary.alignCenter,
			icon: () => <Icon name="floatCenter" color="icon/primary/default" />,
			visible: true,
			active: (state) =>
				isNodeActive(schema.nodes.image)(state) &&
				!isLeftAligned(state) &&
				!isRightAligned(state),
		},
		{
			name: 'alignRight',
			tooltip: dictionary.alignRight,
			icon: () => <Icon name="floatRight" color="icon/primary/default" />,
			visible: true,
			active: isRightAligned,
		},
		{
			name: 'separator',
			visible: true,
		},
		{
			name: 'downloadImage',
			tooltip: dictionary.downloadImage,
			icon: () => <Icon name="download" color="icon/primary/default" />,
			visible: !!fetch,
			active: () => false,
		},
		{
			name: 'replaceImage',
			tooltip: dictionary.replaceImage,
			icon: () => <Icon name="replace" color="icon/primary/default" />,
			visible: true,
			active: () => false,
		},
		{
			name: 'deleteImage',
			tooltip: dictionary.deleteImage,
			icon: () => <Icon name="trash" color="icon/primary/default" />,
			visible: true,
			active: () => false,
		},
		{
			name: 'comment',
			tooltip: dictionary.comment,
			icon: () => <Icon name="message" color="icon/primary/default" />,
			visible: true,
		},
	];
}
