import type { ColumnDefinition } from '../../types';
import { TableColumns } from './table';

export const CollectionColumns: ColumnDefinition = [
	'title',
	'description',
	'published',
	'updated_at',
	'tags',
].reduce(
	(columnDefs, columnName) => {
		const isTitle = columnName === 'title';
		const columnDefinition = TableColumns[columnName];

		return {
			...columnDefs,
			[columnName]: {
				...columnDefinition,
				checkboxSelection: isTitle,
			},
		};
	},
	{
		children_count: {
			field: 'resource_count',
			editable: false,
			sortable: false,
		},
	}
);
