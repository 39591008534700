/* eslint-disable @typescript-eslint/no-explicit-any */
import { PROFILER_COLUMN_NAME } from '../../../../constants';
import type { ColumnName } from '../../helpers';
import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';
import {
	DataEntityColumns,
	DefaultColumns,
	DescriptionColumns,
	TableDerivativeColumns,
} from './common';

export const ColumnColumns: ColumnDefinition = {
	title: {
		field: 'title',
		editable: false,
		sortable: true,

		checkboxSelection: true,
		type: ColumnType.textFilterColumn,
		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		minWidth: 250,
	},
	type: {
		field: 'type',
		editable: false,
		sortable: true,
	},
	table_title: {
		field: 'table_title',

		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
	},
	...Object.entries(TableDerivativeColumns).reduce((columns, [, v]) => {
		const columnName = `table_${v.field}` as ColumnName;

		return {
			...columns,
			[columnName]: {
				...v,
				field: columnName,
			},
		};
	}, {}),
	description: {
		...DescriptionColumns.description,
		minWidth: 300,
	},
	[PROFILER_COLUMN_NAME]: {
		field: PROFILER_COLUMN_NAME,
		editable: true,
		minWidth: 225,
		width: 250,
		sortable: false,
	},
	...DefaultColumns,
	...DataEntityColumns,
};
