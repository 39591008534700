import { Box } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import React, { useCallback, useMemo } from 'react';
import { isNil } from 'lodash-es';
import type { ButtonVariants } from '@repo/foundations';
import type { ICollection } from '../../../api';
import { useAuthUser, useCollectionListAll } from '../../../api';
import { useCurrentTeamId } from '../../../utils/hook/useCurrentTeamId';

import MultiSelector from '../../MultiSelector/MultiSelector';

interface CollectionSelectorProps {
	onChange?: (value: string[]) => void;
	initialValue?: string[];
	forceVariant?: ButtonVariants;
	placeholder?: string | null;
	hideOnEmpty?: boolean;
	readOnly?: boolean;
}

export function CollectionSelector({
	onChange,
	initialValue,
	forceVariant,
	placeholder = 'Collection',
	hideOnEmpty = false,
	readOnly = false,
}: CollectionSelectorProps) {
	const teamId = useCurrentTeamId();
	const { user, isViewerOrGuestUser } = useAuthUser();
	const [selected, setSelected] = React.useState<string[]>(initialValue ?? []);
	const [searchTerm, setSearchTerm] = useDebouncedState('', 500);

	const filter = useCallback(
		(collection: ICollection) => {
			if (initialValue?.includes(collection.id)) {
				return true;
			}

			if (teamId && !collection.teams?.includes(teamId)) {
				return false;
			}

			if (searchTerm) {
				if (collection.title.toLowerCase().includes(searchTerm.toLowerCase())) {
					return true;
				}
				return false;
			}

			return true;
		},
		[initialValue, searchTerm, teamId]
	);

	const { data } = useCollectionListAll();

	const collections = useMemo(
		() =>
			data?.results?.filter(filter).map((collection) => ({
				label: collection.title,
				value: collection.id,
				icon: collection.icon,
				navigateTo: `/collections/${collection.id}`,
			})) ?? [],
		[data?.results, filter]
	);

	const handleChange = useCallback(
		(value: (string | boolean)[]) => {
			setSelected(value as string[]);
			onChange?.(value as string[]);
		},
		[onChange]
	);

	let variant: ButtonVariants = 'default';

	if (forceVariant) {
		variant = forceVariant;
	}

	return (
		<Box maw={256}>
			<MultiSelector
				readOnly={isViewerOrGuestUser || isNil(handleChange)}
				hideOnEmpty={hideOnEmpty}
				variant={variant}
				iconType="emoji"
				isViewerUser={false}
				options={collections}
				placeholder={placeholder}
				onChange={handleChange}
				initialSelected={selected}
				property="collection"
				permittedId={user.id}
				isMenuItemBadge={false}
				onSearchTermChange={setSearchTerm}
			/>
		</Box>
	);
}

export default CollectionSelector;
