/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import type Token from 'markdown-it/lib/token';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { NodeSelection } from 'prosemirror-state';
import { Resizable, ResizableProps } from 're-resizable';
import { useState } from 'react';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import embedsRule from '../rules/embeds';
import type { ComponentProps, Dispatch } from '../types';
import { stripWidthHeight } from '../utils/embed.helpers';
import {
	EMBED_HEIGHT,
	EMBED_WIDTH,
	MAX_EMBED_HEIGHT,
	MAX_EMBED_WIDTH,
} from './Embed.constants';
import { NodeOptions } from './Node';
import ReactNode from './ReactNode';

interface ResizeBoxProps {
	node: ProsemirrorNode;
	handleResize: ({
		node,
		size,
	}: {
		node: ProsemirrorNode;
		size: {
			width: number;
			height: number;
		};
	}) => boolean;
	readOnly?: boolean;
	defaultWidth: number;
	defaultHeight: number;
	handleSelect: ResizableProps['onResizeStart'];
	children: React.ReactNode;
}

function ResizeBox({
	handleSelect,
	children,
	node,
	handleResize,
	readOnly,
	defaultWidth,
	defaultHeight,
}: ResizeBoxProps) {
	const [width, setWidth] = useState(defaultWidth || EMBED_WIDTH);
	const [height, setHeight] = useState(defaultHeight || EMBED_HEIGHT);

	return (
		<Resizable
			size={{ width, height }}
			maxWidth={MAX_EMBED_WIDTH}
			maxHeight={MAX_EMBED_HEIGHT}
			onResizeStart={handleSelect}
			enable={{
				top: !readOnly,
				right: !readOnly,
				bottom: !readOnly,
				left: !readOnly,
				topRight: !readOnly,
				bottomRight: !readOnly,
				bottomLeft: !readOnly,
				topLeft: !readOnly,
			}}
			onResizeStop={(e, direction, ref) => {
				if (readOnly) return;
				setWidth(ref.clientWidth);
				setHeight(ref.clientHeight);

				handleResize({
					node,
					size: {
						width: ref.clientWidth,
						height: ref.clientHeight,
					},
				});
			}}
		>
			{children}
		</Resizable>
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache: Record<string, any> = {};

export default class Embed extends ReactNode {
	get name() {
		return 'embed';
	}

	get schema(): NodeSpec {
		return {
			content: 'inline*',
			group: 'block',
			atom: true,
			attrs: {
				href: {},
				width: {
					default: EMBED_WIDTH,
				},
				height: {
					default: EMBED_HEIGHT,
				},
			},
			parseDOM: [
				{
					tag: 'iframe.embed',
					getAttrs: (dom: HTMLElement | string) => {
						if (typeof dom === 'string') {
							return null;
						}

						const { embeds } = this.editorState;
						const href = dom.getAttribute('src') || '';

						if (embeds) {
							// eslint-disable-next-line no-restricted-syntax
							for (const embed of embeds) {
								const matches = embed.matcher(href);
								if (matches) {
									return {
										href,
									};
								}
							}
						}

						return {};
					},
				},
				{
					tag: 'a.disabled-embed',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									href: dom.getAttribute('href') || '',
								},
				},
			],
			toDOM: (node) => [
				'iframe',
				{
					class: 'embed',
					src: node.attrs.href,
					contentEditable: 'false',
				},
				0,
			],
		};
	}

	get rulePlugins() {
		return [embedsRule(this.options.embeds)];
	}

	handleSelect =
		({ getPos }: ComponentProps) =>
		(event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
			event.preventDefault();

			const { view } = this.editorState;
			const $pos = view.state.doc.resolve(getPos());
			const transaction = view.state.tr.setSelection(new NodeSelection($pos));
			view.dispatch(transaction);
		};

	handleResize = ({
		node,
		size,
	}: {
		node: ProsemirrorNode;
		size: {
			width: number;
			height: number;
		};
	}) => {
		const {
			view: { dispatch, state },
		} = this.editorState;

		if (size.width > MAX_EMBED_WIDTH || size.height > MAX_EMBED_HEIGHT) {
			size.height = EMBED_HEIGHT;
			size.width = EMBED_WIDTH;
		}

		const nodeAttrs = node?.attrs || {};
		const attrs = {
			...((state.selection as NodeSelection)?.node?.attrs ?? {}),
			...nodeAttrs,
			width: size.width,
			height: size.height,
		};
		const { selection } = state;

		dispatch(state.tr.setNodeMarkup(selection.from, undefined, attrs));
		return true;
	};

	component = (props: ComponentProps) => {
		const { isEditable, isSelected, theme, node } = props;
		const { embeds } = this.editorState;
		const { width, height } = node.attrs;

		// Matches are cached in module state to avoid re running loops and regex
		// here. Unfortunately this function is not compatible with React.memo or
		// we would use that instead.
		const hit = cache[node.attrs.href];
		let Component = hit ? hit.Component : undefined;
		let matches = hit ? hit.matches : undefined;
		let embed = hit ? hit.embed : undefined;

		if (!Component) {
			// eslint-disable-next-line no-restricted-syntax
			for (const e of embeds) {
				const m = e.matcher(node.attrs.href);
				if (m) {
					Component = e.component;
					matches = m;
					embed = e;
					cache[node.attrs.href] = { Component, embed, matches };
				}
			}
		}

		if (!Component) {
			return null;
		}

		return (
			<ResizeBox
				node={node}
				readOnly={this.editorState.readOnly}
				defaultWidth={width}
				defaultHeight={height}
				handleResize={this.handleResize}
				handleSelect={this.handleSelect(props)}
			>
				<Component
					isIframe
					{...node.attrs}
					attrs={{ ...node.attrs, matches }}
					isEditable={isEditable}
					isSelected={isSelected}
					theme={theme}
				/>
			</ResizeBox>
		);
	};

	commands({ type }: NodeOptions) {
		return (attrs?: Record<string, unknown>) =>
			(state: EditorState, dispatch: Dispatch) => {
				dispatch(state.tr.insert(state.selection.from, type.create(attrs)));
				return true;
			};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.ensureNewLine();
		const url = new URL(node.attrs.href);
		url.searchParams.append(
			'embedWidth',
			Math.abs(node.attrs.width ?? EMBED_WIDTH).toString()
		);
		url.searchParams.append(
			'embedHeight',
			Math.abs(node.attrs.height ?? EMBED_HEIGHT).toString()
		);
		state.write(`[iframe:embed](${state.esc(url.href, false).trim()})`);
		state.write('\n\n');
	}

	parseMarkdown() {
		return {
			node: 'embed',
			getAttrs: (token: Token) => {
				const link: string | undefined | null = token.attrGet('href');
				const { href, width, height } = stripWidthHeight(link);
				return {
					width: width ? parseFloat(width) : EMBED_WIDTH,
					height: height ? parseFloat(height) : EMBED_HEIGHT,
					href,
				};
			},
		};
	}
}
