import {
	createStyles,
	DefaultProps,
	Select as MantineSelect,
	Selectors,
	SelectProps as MantineSelectProps,
	Stack,
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import { forwardRef, useCallback } from 'react';
import { TextInputHelp, TextInputLabel } from '../TextInput';
import { TextInputError } from '../TextInput/TextInputError';
import type { ColorNames } from '@repo/theme/utils';

type SelectSizes = 'sm' | 'md';

export interface SelectStylesParams {
	size: SelectSizes;
	error?: string;
}

const useStyles = createStyles((theme, { size }: SelectStylesParams) => {
	let height: number = theme.other.space[8];

	let backgroundColor: ColorNames = 'surface/input/default';
	let hoverBackgroundColor: ColorNames = 'surface/input/default';
	let focusBackgroundColor: ColorNames = 'surface/input/default';
	const disabledBackgroundColor: ColorNames = 'surface/primary/disabled';

	let borderWidth: number = 0.5;
	let hoverBorderWidth: number = 0.5;
	let focusBorderWidth: number = 0.5;
	const disabledBorderWidth: number = 0;

	let borderColor: ColorNames = 'border/input/default';
	let hoverBorderColor: ColorNames = 'border/input/hover';
	let focusBorderColor: ColorNames = 'border/input/active';

	const boxShadow = `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`;

	if (size === 'sm') {
		height = theme.other.space[7];
	}

	return {
		input: {
			height,
			minHeight: height,
			backgroundColor: theme.other.getColor(backgroundColor),
			borderRadius: theme.radius.sm,
			borderWidth,
			borderStyle: 'solid',
			borderColor: theme.other.getColor(borderColor),
			'&:hover': {
				backgroundColor: theme.other.getColor(hoverBackgroundColor),
				borderWidth: hoverBorderWidth,
				borderColor: theme.other.getColor(hoverBorderColor),
			},
			'&:focus, &:active': {
				backgroundColor: theme.other.getColor(focusBackgroundColor),
				borderWidth: focusBorderWidth,
				borderColor: theme.other.getColor(focusBorderColor),
				boxShadow,
			},
			'&:disabled': {
				backgroundColor: theme.other.getColor(disabledBackgroundColor),
				borderWidth: disabledBorderWidth,
			},
		},
	};
});

type SelectStylesNames = Selectors<typeof useStyles>;

type SelectProps = {
	id?: string;
	size?: SelectSizes;
	label?: string;
	help?: string;
	error?: string;
	optional?: boolean;
} & Omit<MantineSelectProps, 'size' | 'required'> &
	DefaultProps<SelectStylesNames, SelectStylesParams>;

const Select = forwardRef<HTMLInputElement, SelectProps>(
	(
		{
			id,
			size = 'md',
			label,
			name,
			help,
			error,
			optional = false,
			disabled = false,
			onChange,
			...others
		},
		ref
	) => {
		const uuid = useId(id);
		const { classes, theme } = useStyles({ size });

		const handleOnChange = useCallback(
			(value: string) => {
				if (disabled) {
					return;
				}

				onChange?.(value);
			},
			[disabled, onChange]
		);

		return (
			<Stack w="100%" spacing={theme.spacing['3xs']}>
				<TextInputLabel label={label} optional={optional} inputId={uuid} />
				<MantineSelect
					id={uuid}
					ref={ref}
					classNames={classes}
					name={name}
					onChange={handleOnChange}
					{...others}
				/>
				<TextInputHelp help={help} error={error} />
				<TextInputError error={error} />
			</Stack>
		);
	}
);

Select.displayName = 'Select';

export { Select };
