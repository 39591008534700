import { Group, Stack, useMantineColorScheme } from '@mantine/core';
import { Text } from '@repo/foundations';
import { IconButton } from '@repo/foundations';
import { DefaultDevDropdown } from './DefaultDevDropdown';
import type { DevPanelItemProps } from './DevPanel';

export function DarkModeToggle({ close }: DevPanelItemProps) {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const isDark = colorScheme === 'dark';

	return (
		<DefaultDevDropdown label="Dark Mode" close={close}>
			<Stack>
				<Group>
					<IconButton
						tooltip="Toggle dark mode"
						iconName={isDark ? 'moon' : 'sun'}
						onClick={() => toggleColorScheme()}
					/>
					<Text>{isDark ? 'Make it light' : 'Make it dark'}</Text>
				</Group>
			</Stack>
		</DefaultDevDropdown>
	);
}
