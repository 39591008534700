import { Menu, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { ReactNode } from 'react';
import { FilterTargetSimple } from '../FilterTarget/FilterTargetSimple';
import type { FilterView } from '../types';
import { useFilterViews } from './useFilterViews';

const useStyles = createStyles((theme) => ({
	menuDropdown: {
		paddingTop: theme.spacing['2xs'],
	},
	menuDivider: {
		margin: `${theme.spacing['2xs']} 0`,
	},
	menuItem: {
		margin: `0 ${theme.spacing['2xs']}`,
		width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
		padding: theme.spacing['2xs'],
	},
	menuLabel: {
		paddingLeft: theme.spacing.sm,
		fontWeight: 600,
	},
}));

interface FilterViewsProps {
	initialOpened?: boolean;
	onChange: (view: FilterView | null) => void;
	value?: FilterView | null;
	renderTarget?: (
		view: FilterView | undefined,
		isMenuOpen: boolean,
		onToggle: () => void
	) => string | ReactNode;
}

export function FilterViews({
	initialOpened,
	onChange,
	value,
	renderTarget,
}: FilterViewsProps) {
	const { classes } = useStyles();
	const [opened, { toggle, close }] = useDisclosure(initialOpened);
	const { views } = useFilterViews();

	const userViews = views.filter((v) => v.isOwner);
	const teamViews = views.filter((v) => !v.isOwner);

	const handleClick = (view: FilterView | null) => () => {
		onChange(view);
		close();
	};

	const selectedView = views.find((v) => v.value === value?.value);

	return (
		<Menu
			opened={opened}
			closeOnClickOutside
			closeOnEscape
			closeOnItemClick
			onClose={close}
			classNames={{
				dropdown: classes.menuDropdown,
				divider: classes.menuDivider,
				label: classes.menuLabel,
				item: classes.menuItem,
			}}
		>
			<Menu.Target>
				{renderTarget ? (
					renderTarget(selectedView, opened, toggle)
				) : (
					<FilterTargetSimple
						label={selectedView?.label ?? 'Views'}
						onToggle={toggle}
						isMenuOpen={opened}
						hasValue
					/>
				)}
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item onClick={handleClick(null)}>All results</Menu.Item>
				{userViews.length > 0 && (
					<>
						<Menu.Divider />
						<Menu.Label>Personal views</Menu.Label>
						{userViews.map((view) => (
							<Menu.Item key={view.value} onClick={handleClick(view)}>
								{view.label}
							</Menu.Item>
						))}
					</>
				)}
				{teamViews.length > 0 && (
					<>
						<Menu.Divider />
						<Menu.Label>Team views</Menu.Label>
						{teamViews.map((view) => (
							<Menu.Item key={view.value} onClick={handleClick(view)}>
								{view.label}
							</Menu.Item>
						))}
					</>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}
