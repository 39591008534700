import { Group, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useCallback } from 'react';
import { usePublicEnv } from '../../../../api/hooks/utils/usePublicEnv';
import { fetchSwitchRegion } from '../../../../api/codegen/apiComponents';
import { queryClient } from '../../../../api';
import { publicEnvQueryKeyFactory } from '../../../../api/hooks/utils/constants';
import { useStyles } from './WorkspaceSelectorSubMenu';

export function WorkspaceSelectorRegionSubMenu() {
	const { classes, theme } = useStyles();
	const { data } = usePublicEnv();

	const handleClick = useCallback(async (region: string) => {
		await fetchSwitchRegion({
			pathParams: { regionName: region },
		});
		queryClient.invalidateQueries(publicEnvQueryKeyFactory.all());
		window.location.reload();
	}, []);

	return (
		<Stack className={classes.workspacesListSectionWrapper}>
			{data?.REGION_OPTIONS?.split(',')?.map((region) => (
				<Group
					onClick={() => handleClick(region)}
					key={region}
					className={classes.workspace}
					px={theme.spacing.xl}
				>
					<Text size="sm">{region}</Text>
					{data?.REGION === region && <Icon name="check" />}
				</Group>
			))}
		</Stack>
	);
}
