import { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import type { FilterOption, FilterValue } from '../types';
import type { AddedFilterResult } from '../store';
import { useAuthUser } from '../../../api';
import { trackEvent } from '../../../utils/analytics';

interface UseAddFilterProps {
	onAddFilter: (value: FilterValue) => AddedFilterResult;
	dropdownStandaloneOption?: FilterOption | null | undefined;
	close: () => void;
}

export function useAddFilter({
	onAddFilter,
	close,
	dropdownStandaloneOption = null,
}: UseAddFilterProps) {
	const { user, workspace } = useAuthUser();
	const location = useLocation();
	const [selectedFilterOption, setSelectedFilterOption] = useState<
		FilterOption | null | undefined
	>(dropdownStandaloneOption);

	const [filterValue, setFilterValue] = useState<FilterValue | null>(null);
	const [createdFilter, setCreatedFilter] = useState<AddedFilterResult | null>(
		null
	);

	const reset = useCallback(() => {
		setCreatedFilter(null);
		setFilterValue(null);
		setSelectedFilterOption(null);
	}, []);

	const handleAddFilter = useCallback(
		(value: FilterValue, shouldClose: boolean) => {
			if (createdFilter === null) {
				const result = onAddFilter(value);
				setCreatedFilter(result);
				setFilterValue(result.value);
			} else {
				createdFilter.changeFilter(value);
				setFilterValue(value);
			}

			if (shouldClose) {
				close();
			}

			trackEvent(
				'filters/select',
				{
					url: location.pathname,
					filter: value.filterType,
					operator: value.operator,
					value: JSON.stringify(value.value),
				},
				user,
				workspace
			);
		},
		[close, createdFilter, location.pathname, onAddFilter, user, workspace]
	);

	return {
		selectedFilterOption,
		setSelectedFilterOption,
		filterValue,
		reset,
		handleAddFilter,
	};
}
