import type { EditorView } from 'prosemirror-view';
import React from 'react';
import { EditorDictionary } from '@repo/secoda-editor';
import createAndInsertLink from '../commands/createAndInsertLink';
import FloatingToolbar from './FloatingToolbar';
import type { SearchResult } from './LinkEditor';
import LinkEditor from './LinkEditor';

type Props = {
	isActive: boolean;
	view: EditorView;
	dictionary: EditorDictionary;
	onCreateLink?: (title: string) => Promise<string>;
	onSearchLink?: (term: string) => Promise<SearchResult[]>;
	onClickLink: (href: string, event: MouseEvent, forceNewTab?: boolean) => void;
	scrollToAnchor: (id: string) => void;
	onShowToast: (msg: string, code: string) => void;
	onClose: () => void;
};

function isActive(props: Props) {
	const { view } = props;
	const { selection } = view.state;

	try {
		const paragraph = view.domAtPos(selection.from);
		return props.isActive && !!paragraph.node;
	} catch (err) {
		return false;
	}
}

export default class LinkToolbar extends React.Component<Props> {
	menuRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		window.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		window.removeEventListener('mousedown', this.handleClickOutside);
	}

	// @ts-expect-error TS(7006): Parameter 'ev' implicitly has an 'any' type.
	handleClickOutside = (ev) => {
		if (
			ev.target &&
			this.menuRef.current &&
			this.menuRef.current.contains(ev.target)
		) {
			return;
		}

		this.props.onClose();
	};

	handleOnCreateLink = async (title: string) => {
		const { dictionary, onCreateLink, view, onClose, onShowToast } = this.props;

		onClose();
		this.props.view.focus();

		if (!onCreateLink) {
			return;
		}

		const { dispatch, state } = view;
		const { from, to } = state.selection;
		if (from !== to) {
			// Selection must be collapsed
			return;
		}

		const href = `creating#${title}…`;

		// Insert a placeholder link
		dispatch(
			view.state.tr
				.insertText(title, from, to)
				.addMark(
					from,
					to + title.length,
					state.schema.marks.link.create({ href })
				)
		);

		createAndInsertLink(view, title, href, {
			onCreateLink,
			onShowToast,
			dictionary,
		});
	};

	handleOnSelectLink = ({
		href,
		title,
	}: {
		href: string;
		title: string;
		from: number;
		to: number;
	}) => {
		const { view, onClose } = this.props;

		onClose();
		this.props.view.focus();

		const { dispatch, state } = view;
		const { from, to } = state.selection;
		if (from !== to) {
			// Selection must be collapsed
			return;
		}

		dispatch(
			view.state.tr
				.insertText(title, from, to)
				.addMark(
					from,
					to + title.length,
					state.schema.marks.link.create({ href })
				)
		);
	};

	render() {
		const { onCreateLink, onClose, ...rest } = this.props;
		const { selection } = this.props.view.state;
		const active = isActive(this.props);

		return (
			<FloatingToolbar ref={this.menuRef} active={active} {...rest}>
				{active && (
					<LinkEditor
						from={selection.from}
						to={selection.to}
						onCreateLink={onCreateLink ? this.handleOnCreateLink : undefined}
						// @ts-expect-error TS(2322): Type '({ href, title, }: { href: string; title: st... Remove this comment to see the full error message
						onSelectLink={this.handleOnSelectLink}
						onRemoveLink={onClose}
						{...rest}
					/>
				)}
			</FloatingToolbar>
		);
	}
}
