import { languages } from 'monaco-editor/esm/vs/editor/editor.api';
import {
	CompletionService,
	ICompletionItem,
	LanguageIdEnum,
} from 'monaco-sql-languages';
/** import contribution file */
import 'monaco-sql-languages/esm/languages/mysql/mysql.contribution';
/** import worker files */
import { uniqBy } from 'lodash-es';
import EditorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import MySQLWorker from 'monaco-sql-languages/esm/languages/mysql/mysql.worker?worker';
import { ISecodaEntity, ITable } from '../../../api';
import { Filter } from '../../../api/codegen/apiSchemas';
import { fetchCatalogList } from '../../../api/hooks/resourceCatalog';

/** define MonacoEnvironment.getWorker  */
(globalThis as Record<string, unknown>).MonacoEnvironment = {
	getWorker(_: unknown, label: string) {
		if (label === LanguageIdEnum.MYSQL) {
			return new MySQLWorker();
		}
		return new EditorWorker();
	},
};

export const completionService: (
	integrationId?: string,
	integrationType?: string
) => CompletionService =
	(integrationId?: string, integrationType?: string) =>
	(
		model,
		position,
		completionContext,
		suggestions, // syntax context info at caretPosition
		entities // tables, columns in the syntax context of the editor text
	) =>
		new Promise(async (resolve) => {
			if (!suggestions) {
				return Promise.resolve([]);
			}
			const { keywords, syntax } = suggestions;
			const keywordsCompletionItems: ICompletionItem[] = keywords.map((kw) => ({
				label: kw,
				kind: languages.CompletionItemKind.Keyword,
				detail: 'keyword',
				sortText: '2' + kw,
			}));

			let syntaxCompletionItems: ICompletionItem[] = [];

			// In the future, we can use the relevantTable to filter the search results.
			// const relevantTable = entities
			// 	?.filter((entity) => entity.position.line <= position.lineNumber)
			// 	.sort((a, b) => b.position.line - a.position.line)?.[0];

			const search = async (
				searchTerm: string,
				additionalFilters?: Filter[]
			) => {
				const filters = {
					search_term: searchTerm,
					filter: {
						operator: 'and',
						operands: [
							{
								field: 'entity_type',
								operator: 'in',
								value: ['table', 'column', 'schema'],
							},
						],
					} as Filter,
					page_size: 50,
				};

				if (additionalFilters) {
					filters.filter.operands.push(...additionalFilters);
				}

				if (integrationId) {
					filters.filter.operands.push({
						field: 'integration_id',
						operator: 'exact',
						value: integrationId,
					} as Filter);
				}

				const { results } = await fetchCatalogList({
					filters,
					page: 1,
				});

				const isTable = (entity: ITable | ISecodaEntity): entity is ITable =>
					(entity as ITable).database !== undefined &&
					(entity as ITable).schema !== undefined;

				const autocomplete = (entity: ITable | ISecodaEntity) => {
					if (integrationType === 'bigquery' && isTable(entity)) {
						return `${entity.database}.${entity.schema}.${entity.title_cased}`;
					}
					return entity.title_full ?? entity.title_cased ?? entity.title;
				};

				return results.map((entity) => ({
					label: autocomplete(entity),
					insertText: autocomplete(entity),
					kind: languages.CompletionItemKind.Variable,
					detail: entity.entity_type,
				}));
			};

			const text = syntax?.[0]?.wordRanges?.[0]?.text;

			if (text && text.length > 3) {
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const searchResults = await search(text);
				syntaxCompletionItems = [...searchResults, ...syntaxCompletionItems];
			} else {
				const searchResults = await search('');
				syntaxCompletionItems = [...searchResults, ...syntaxCompletionItems];
			}

			syntaxCompletionItems = uniqBy(
				syntaxCompletionItems,
				(item) => item.label
			);

			resolve([...keywordsCompletionItems, ...syntaxCompletionItems]);
		});
