import type { ReactNode } from 'react';
import { forwardRef, useCallback } from 'react';
import type {
	DefaultProps,
	Selectors,
	TextInputProps as MantineTextInputProps,
} from '@mantine/core';
import {
	createStyles,
	Stack,
	TextInput as MantineTextInput,
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import type { ColorNames } from '@repo/theme/utils';
import { isEmpty } from 'lodash-es';

import { Icon, type IconNames } from '../Icon';
import { TextInputLabel } from './TextInputLabel';
import { TextInputHelp } from './TextInputHelp';
import { TextInputError } from './TextInputError';

export type TextInputVariants = 'default' | 'subtle';
type TextInputSizes = 'sm' | 'md';

export interface TextInputStylesParams {
	variant: TextInputVariants;
	size: TextInputSizes;
	error?: string;
	truncate: boolean;
}

const useStyles = createStyles(
	(theme, { variant, size, error, truncate }: TextInputStylesParams) => {
		let height: number = theme.other.space[8];

		let backgroundColor: ColorNames = 'surface/input/default';
		let hoverBackgroundColor: ColorNames = 'surface/input/default';
		let focusBackgroundColor: ColorNames = 'surface/input/default';
		const disabledBackgroundColor: ColorNames = 'surface/primary/disabled';

		let borderWidth: number = 0.5;
		let hoverBorderWidth: number = 0.5;
		let focusBorderWidth: number = 0.5;
		const disabledBorderWidth: number = 0;

		let borderColor: ColorNames = 'border/input/default';
		let hoverBorderColor: ColorNames = 'border/input/hover';
		let focusBorderColor: ColorNames = 'border/input/active';

		const boxShadow = `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`;

		if (size === 'sm') {
			height = theme.other.space[7];
		}

		if (variant === 'subtle') {
			backgroundColor = 'surface/secondary/default';
			hoverBackgroundColor = 'surface/secondary/hover';
			focusBackgroundColor = 'surface/secondary/active';
			borderWidth = 0;
			hoverBorderWidth = 0;
			focusBorderWidth = 0;
		}

		if (!isEmpty(error)) {
			backgroundColor = 'surface/critical/default';
			hoverBackgroundColor = 'surface/critical/default';
			focusBackgroundColor = 'surface/critical/default';
			borderColor = 'border/critical-secondary/default';
			hoverBorderColor = 'border/critical-secondary/default';
			focusBorderColor = 'border/critical-secondary/default';
		}

		return {
			root: {
				width: '100%',
			},
			wrapper: {},
			input: {
				height,
				minHeight: height,
				backgroundColor: theme.other.getColor(backgroundColor),
				borderRadius: theme.radius.sm,
				borderWidth,
				borderStyle: 'solid',
				borderColor: theme.other.getColor(borderColor),
				textOverflow: truncate ? 'ellipsis' : 'unset',
				'&:hover': {
					backgroundColor: theme.other.getColor(hoverBackgroundColor),
					borderWidth: hoverBorderWidth,
					borderColor: theme.other.getColor(hoverBorderColor),
				},
				'&:focus, &:active': {
					backgroundColor: theme.other.getColor(focusBackgroundColor),
					borderWidth: focusBorderWidth,
					borderColor: theme.other.getColor(focusBorderColor),
					boxShadow,
				},
				'&:disabled': {
					backgroundColor: theme.other.getColor(disabledBackgroundColor),
					borderWidth: disabledBorderWidth,
				},
			},
		};
	}
);

type TextInputStylesNames = Selectors<typeof useStyles>;

type TextInputProps = {
	id?: string;
	variant?: TextInputVariants;
	size?: TextInputSizes;
	label?: string;
	help?: string;
	error?: string;
	iconName?: IconNames;
	rightSection?: ReactNode;
	optional?: boolean;
	truncate?: boolean;
} & Omit<MantineTextInputProps, 'size' | 'required'> &
	DefaultProps<TextInputStylesNames, TextInputStylesParams>;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(
		{
			id,
			variant = 'default',
			type = 'text',
			size = 'md',
			label,
			name,
			help,
			error,
			iconName,
			rightSection,
			optional = false,
			disabled = false,
			truncate = false,
			onChange,
			...others
		},
		ref
	) => {
		const uuid = useId(id);
		const { classes, theme } = useStyles({
			variant,
			size,
			error,
			truncate,
		});

		const handleOnChange = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				if (disabled) {
					return;
				}

				onChange?.(event);
			},
			[disabled, onChange]
		);

		return (
			<Stack w="100%" spacing={theme.spacing['3xs']}>
				<TextInputLabel label={label} optional={optional} inputId={uuid} />
				<MantineTextInput
					id={uuid}
					ref={ref}
					classNames={classes}
					name={name}
					type={type}
					onChange={handleOnChange}
					size={size}
					icon={
						iconName ? (
							<Icon name={iconName} color="icon/secondary/default" />
						) : undefined
					}
					rightSection={rightSection}
					disabled={disabled}
					{...others}
				/>
				<TextInputHelp help={help} error={error} />
				<TextInputError error={error} />
			</Stack>
		);
	}
);

TextInput.displayName = 'TextInput';

export { TextInput };
