import { DefaultColumns } from './common';
import { TableColumns } from './table';

export const SecodaEntityColumns = {
	title: TableColumns.title,
	type: {
		field: 'native_type',
		headerName: 'Type',
	},
	...DefaultColumns,
};
