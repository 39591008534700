import {
	createStyles,
	UnstyledButton,
	Group,
	Text,
	Center,
	rem,
	Checkbox,
} from '@mantine/core';
import type { SpotlightAction, SpotlightActionProps } from '@mantine/spotlight';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SpotlightProvider } from '@mantine/spotlight';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';

class TableV2SpotlightStore {
	actions: SpotlightAction[] | undefined;

	selected: string[] = [];

	setSelected(fn: (arg0: string[]) => string[]) {
		this.selected = fn(this.selected);
	}

	setActions(actions: SpotlightAction[]) {
		this.actions = actions.map((action) => ({
			...action,
			onTrigger: () => {
				const existingTrigger = action.onTrigger;
				if (action.single) {
					this.selected = [action.id!];
				} else {
					this.setSelected((prev: string[]) =>
						prev.includes(action.id!)
							? // We do `startsWith` here to handle the case when the action has `::partial` suffix.
								prev.filter((id) => !id.startsWith(action.id!))
							: [...prev, action.id!]
					);
				}
				existingTrigger?.(action);
			},
		}));
	}

	constructor() {
		makeAutoObservable(this);
	}
}

export const paletteStore = new TableV2SpotlightStore();

const useStyles = createStyles((theme) => ({
	action: {
		position: 'relative',
		display: 'block',
		width: '100%',
		padding: `${rem(10)} ${rem(12)}`,
		borderRadius: theme.radius.sm,
		...theme.fn.hover({
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[4]
					: theme.colors.gray[1],
		}),

		'&[data-hovered]': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[4]
					: theme.colors.gray[1],
		},
	},
}));

export const Action = observer(
	({
		action,
		styles,
		classNames,
		hovered,
		onTrigger,
		...others
	}: SpotlightActionProps) => {
		const { classes } = useStyles(null as unknown as void, {
			styles,
			classNames,
			name: 'Spotlight',
		});

		const checked = paletteStore.selected.includes(action.id as string);

		const indeterminate =
			!checked &&
			paletteStore.selected.includes(`${action.id}::partial` as string);

		return (
			<UnstyledButton
				className={classes.action}
				data-hovered={hovered || undefined}
				tabIndex={-1}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					onTrigger();
				}}
				{...others}
			>
				<Group noWrap>
					<Checkbox
						indeterminate={indeterminate}
						checked={checked}
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							onTrigger();
						}}
					/>

					{action.icon && <Center>{action.icon}</Center>}

					<div style={{ flex: 1 }}>
						<Text>{action.title}</Text>
					</div>
				</Group>
			</UnstyledButton>
		);
	}
);

export const TableV2Spotlight = observer(({ children }) => (
	<SpotlightProvider
		closeOnClickOutside
		withOverlay
		overlayProps={{
			opacity: 0.5,
			blur: 0,
		}}
		actions={paletteStore.actions ?? []}
		actionComponent={Action}
		searchPlaceholder="Search..."
		shortcut={null}
		closeOnActionTrigger={false}
	>
		{children}
	</SpotlightProvider>
));
