import type { ColumnDefinition } from '../../types';

export const UserColumns: ColumnDefinition = {
	name: {
		sortable: true,

		field: 'display_name',
		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
	},
	email: {
		sortable: true,

		field: 'email',
	},
	role: {
		sortable: true,
		field: '_role',
	},
};
