import type { IEntityPermission, IUser } from '../api';
import type { User } from '../lib/models';
import type {
	EntityPermissionOrMembershipOut,
	LiteUserOut,
	TeamMembershipOut,
} from '../api/codegen/apiSchemas';
import { isNullOrWhiteSpace } from './stringUtils';

export function getDisplayName(user: User | IUser | LiteUserOut) {
	return isNullOrWhiteSpace(user.first_name as string)
		? user.email
		: `${user.first_name} ${user.last_name}`;
}

export function getTeamMembershipOrEntityPermisisonDisplayName(
	r: TeamMembershipOut | IEntityPermission | EntityPermissionOrMembershipOut
) {
	if (r.user) {
		return getDisplayName(r.user);
	}
	if ('group' in r && r.group) {
		return r.group.name;
	}
	if ('user_group' in r && r.user_group) {
		return r.user_group.name;
	}

	return '';
}
