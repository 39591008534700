import {
	createStyles,
	Divider,
	Popover,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useDisclosure, useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { Icon } from '@repo/foundations';
import { IconButton } from '@repo/foundations';
import { FeatureFlags } from './FeatureFlags';
import { DarkModeToggle } from './DarkModeToggle';
import { Home } from './Home';
import { ReactComponentPicker } from './ReactComponentPicker';
import { CustomReactQueryDevtools } from './CustomReactQueryDevtools';
import { DocumentationDebugger } from './DocumentationDebugger';
import { EducationModalToggles } from './EducationModalToggles';

const useStyles = createStyles((theme) => ({
	wrapper: {
		pointerEvents: 'auto',
		'@media print': {
			display: 'none',
		},
	},
	targetButton: {
		pointerEvents: 'auto',
		borderRadius: theme.other.borderRadius.lg,
	},
	button: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		alignItems: 'center',
		padding: theme.spacing['3xs'],
		gap: theme.spacing.xs,
		fontSize: theme.fontSizes.sm,
		width: '100%',
		backgroundColor: theme.other.getColor('fill/primary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/active'),
		},
	},
}));

export interface DevPanelItemProps {
	close: VoidFunction;
}
interface Item {
	key: string;
	icon: React.ReactNode;
	label: string;
	component: ({ close }: DevPanelItemProps) => JSX.Element;
}
const ITEMS: Item[] = [
	{
		key: 'feature-flags',
		icon: <Icon name="flag" />,
		label: 'Feature Flags',
		component: FeatureFlags,
	},
	{
		key: 'dark-mode',
		icon: <Icon name="moon" />,
		label: 'Dark Mode',
		component: DarkModeToggle,
	},
	{
		key: 'react-picker',
		icon: <Icon name="cursorText" />,
		label: 'React Debugger',
		component: ReactComponentPicker,
	},
	{
		key: 'react-query-devtools',
		icon: <Icon name="zoomQuestion" />,
		label: 'React Query Devtools',
		component: CustomReactQueryDevtools,
	},
	{
		key: 'documentation-debugger',
		icon: <Icon name="fileText" />,
		label: 'Documentation Debugger',
		component: DocumentationDebugger,
	},
	{
		key: 'education-modal',
		icon: <Icon name="newSection" />,
		label: 'Education Modal',
		component: EducationModalToggles,
	},
];

export function DevPanel() {
	const { classes } = useStyles();
	const [opened, { toggle, close }] = useDisclosure(false);
	const [activeItem, setActiveItem] = useState<Item | null>(null);

	const toggleMenu = () => {
		if (activeItem) {
			setActiveItem(null);
			close();
		} else {
			toggle();
		}
	};

	const closeItem = () => {
		setActiveItem(null);
	};

	const devPanel = useClickOutside(() => {
		if (!activeItem) {
			close();
		}
	});

	return (
		<Stack ref={devPanel} className={classes.wrapper}>
			<Popover position="top-start" shadow="md" opened={opened}>
				<Popover.Target>
					<IconButton
						iconName="tool"
						onClick={toggleMenu}
						size="md"
						className={classes.targetButton}
					/>
				</Popover.Target>
				<Popover.Dropdown>
					{activeItem ? (
						<activeItem.component close={closeItem} />
					) : (
						<>
							<Home />
							<Divider my="sm" />
							{ITEMS.map((item) => (
								<UnstyledButton
									key={item.key}
									onClick={() => setActiveItem(item)}
									className={classes.button}
								>
									{item.icon}
									{item.label}
								</UnstyledButton>
							))}
						</>
					)}
				</Popover.Dropdown>
			</Popover>
		</Stack>
	);
}
