import type { ColumnDefinition } from '../../types';
import { DefaultColumns } from './common';
import { DocumentColumns } from './document';

export const QuestionColumns: ColumnDefinition = {
	title: DocumentColumns.title,
	status: {
		field: 'status',
		editable: false,
		sortable: true,
	},
	created_at: {
		field: 'created_at',
		editable: false,
		sortable: true,
	},
	children_count: {
		field: 'children_count',
		editable: false,
		sortable: true,
	},
	collections: DocumentColumns.collections,
	priority: {
		field: 'priority',
		editable: false,
		sortable: true,
	},
	owners: {
		...DefaultColumns.owners,
	},
	assigned_to: {
		field: 'assigned_to',
		editable: true,
		sortable: true,
		type: 'users',
	},
};
