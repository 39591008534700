import { Modal, Stack, Text } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { InviteTeamMemberForm } from '../InviteTeamMemberForm/InviteTeamMemberForm';
import type { TeamOut } from '../../api/codegen/apiSchemas';

interface IInviteTeamMemberModalProps {
	onClose: VoidFunction;
	opened: boolean;
	team: TeamOut;
	onFinish: (teamId: string) => void;
}

export const InviteTeamMemberModal = observer(
	({ onClose, opened, team, onFinish }: IInviteTeamMemberModalProps) => (
		<Modal
			opened={opened}
			onClose={onClose}
			title={null}
			padding={0}
			size={480}
			withCloseButton={false}
			top={150}
		>
			<Stack spacing="md" mx="lg" my="md">
				<Text size="md">
					<Text span>Invite people to</Text>{' '}
					<Text span fw={600}>
						{team.icon} {team.name}
					</Text>
				</Text>
				<InviteTeamMemberForm
					team={team}
					onFinish={onFinish}
					placeholder="Add people or groups"
				/>
			</Stack>
		</Modal>
	)
);
