import { forwardRef, useCallback } from 'react';
import type {
	DefaultProps,
	Selectors,
	SwitchProps as MantineSwitchProps,
} from '@mantine/core';
import { createStyles, Switch as MantineSwitch } from '@mantine/core';
import { Text } from '../Text';

export const allSwitchSizes = ['sm', 'md'] as const;

export type SwitchSizes = (typeof allSwitchSizes)[number];

interface SwitchStylesParams {
	size: SwitchSizes;
}

const useStyles = createStyles((theme, { size }: SwitchStylesParams) => ({
	labelWrapper: {
		flexGrow: 1,
		gap: 0,
	},
	track: {
		width: size === 'sm' ? theme.other.space[6] : theme.other.space[8],
		height: size === 'sm' ? theme.other.space[4] : theme.other.space[5],
		minWidth: size === 'sm' ? theme.other.space[6] : theme.other.space[8],
		borderWidth: 0,
		borderRadius: theme.other.space[4],
		backgroundColor: theme.other.getColor('fill/brand/disabled'),
		'input:checked + &': {
			backgroundColor: theme.other.getColor('fill/brand/selected'),
		},
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/tertiary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/tertiary/active'),
		},
	},
	thumb: {
		width: size === 'sm' ? 12 : theme.other.space[4],
		height: size === 'sm' ? 12 : theme.other.space[4],
		borderWidth: 0,
		left: theme.other.space[0.5],
		backgroundColor: theme.other.getColor('fill/primary/default'),
		'input:checked + * > &': {
			left: size === 'sm' ? 'calc(100% - 14px)' : 'calc(100% - 18px)',
		},
		boxShadow: theme.shadows.sm,
	},
	description: {
		marginTop: 0,
	},
}));

type SwitchStylesNames = Selectors<typeof useStyles>;

type SwitchProps = {
	size?: SwitchSizes;
	label?: string | React.ReactNode;
	labelPosition?: 'left' | 'right';
	description?: string;
} & Omit<MantineSwitchProps, 'classNames'> &
	DefaultProps<SwitchStylesNames, SwitchStylesParams>;

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
	(
		{
			size = 'md',
			label,
			labelPosition = 'left',
			description,
			disabled,
			onChange,
			...others
		},
		ref
	) => {
		const { classes } = useStyles({ size });

		const handleOnChange = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				if (disabled) {
					return;
				}

				onChange?.(event);
			},
			[disabled, onChange]
		);

		if (!label) {
			return (
				<MantineSwitch
					ref={ref}
					classNames={classes}
					onChange={handleOnChange}
					labelPosition={labelPosition}
					{...others}
				/>
			);
		}

		const labelElement =
			typeof label === 'string' ? (
				<Text size="sm" weight="semibold">
					{label}
				</Text>
			) : (
				<>{label}</>
			);
		const descriptionElement = (
			<Text size="sm" color="text/secondary/default">
				{description}
			</Text>
		);

		return (
			<MantineSwitch
				ref={ref}
				classNames={classes}
				onChange={handleOnChange}
				label={labelElement}
				labelPosition={labelPosition}
				description={descriptionElement}
				{...others}
			/>
		);
	}
);

Switch.displayName = 'Switch';

export { Switch };
