import { cloneDeep } from 'lodash-es';
import { BaseModel } from './base';

export class Lineage extends BaseModel {
	// @ts-expect-error TS(2564): Property 'from_entity' has no initializer and is not def... Remove this comment to see the full error message
	from_entity: Record<string, any>;

	// @ts-expect-error TS(2564): Property 'to_entity' has no initializer and is not def... Remove this comment to see the full error message
	to_entity: Record<string, any>;

	// @ts-expect-error TS(2564): Property 'direction' has no initializer and is not def... Remove this comment to see the full error message
	direction: string;

	// @ts-expect-error TS(2564): Property 'depth' has no initializer and is not def... Remove this comment to see the full error message
	depth: number;

	// @ts-expect-error TS(2564): Property 'to_entity' has no initializer and is not def... Remove this comment to see the full error message
	through_entity: Record<string, any>;

	constructor(obj: Lineage) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['lineage'];
	}

	static get fields() {
		return ['id', 'from_entity', 'to_entity', 'direction', 'depth'];
	}
}
