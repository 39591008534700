/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';

export type ApiBulkCreateEntityPermissionsPathParams = {
	entityId: string;
};

export type ApiBulkCreateEntityPermissionsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiBulkCreateEntityPermissionsResponse =
	Schemas.EntityPermissionOrMembershipOut[];

export type ApiBulkCreateEntityPermissionsRequestBody =
	Schemas.EntityPermissionIn[];

export type ApiBulkCreateEntityPermissionsVariables = {
	body?: ApiBulkCreateEntityPermissionsRequestBody;
	pathParams: ApiBulkCreateEntityPermissionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiBulkCreateEntityPermissions = (
	variables: ApiBulkCreateEntityPermissionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiBulkCreateEntityPermissionsResponse,
		ApiBulkCreateEntityPermissionsError,
		ApiBulkCreateEntityPermissionsRequestBody,
		{},
		{},
		ApiBulkCreateEntityPermissionsPathParams
	>({
		url: '/resource/all/{entityId}/permissions_v2/bulk/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiBulkCreateEntityPermissions = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			ApiBulkCreateEntityPermissionsResponse,
			ApiBulkCreateEntityPermissionsError,
			ApiBulkCreateEntityPermissionsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		ApiBulkCreateEntityPermissionsResponse,
		ApiBulkCreateEntityPermissionsError,
		ApiBulkCreateEntityPermissionsVariables
	>({
		mutationFn: (variables: ApiBulkCreateEntityPermissionsVariables) =>
			fetchApiBulkCreateEntityPermissions({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetEntityGroupedPermissionsPathParams = {
	entityId: string;
};

export type ApiGetEntityGroupedPermissionsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiGetEntityGroupedPermissionsResponse =
	Schemas.GroupedPermissionsResponse[];

export type ApiGetEntityGroupedPermissionsVariables = {
	pathParams: ApiGetEntityGroupedPermissionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetEntityGroupedPermissions = (
	variables: ApiGetEntityGroupedPermissionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetEntityGroupedPermissionsResponse,
		ApiGetEntityGroupedPermissionsError,
		undefined,
		{},
		{},
		ApiGetEntityGroupedPermissionsPathParams
	>({
		url: '/resource/all/{entityId}/permissions_v2/grouped_permissions/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetEntityGroupedPermissions = <
	TData = ApiGetEntityGroupedPermissionsResponse,
>(
	variables: ApiGetEntityGroupedPermissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetEntityGroupedPermissionsResponse,
			ApiGetEntityGroupedPermissionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetEntityGroupedPermissionsResponse,
		ApiGetEntityGroupedPermissionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/resource/all/{entityId}/permissions_v2/grouped_permissions/',
			operationId: 'apiGetEntityGroupedPermissions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetEntityGroupedPermissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiAlterEntityPermissionPathParams = {
	entityId: string;
};

export type ApiAlterEntityPermissionError = Fetcher.ErrorWrapper<undefined>;

export type ApiAlterEntityPermissionVariables = {
	body: Schemas.AlterEntityPermissionRequest;
	pathParams: ApiAlterEntityPermissionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiAlterEntityPermission = (
	variables: ApiAlterEntityPermissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiAlterEntityPermissionError,
		Schemas.AlterEntityPermissionRequest,
		{},
		{},
		ApiAlterEntityPermissionPathParams
	>({
		url: '/resource/all/{entityId}/permissions_v2/alter/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiAlterEntityPermission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiAlterEntityPermissionError,
			ApiAlterEntityPermissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiAlterEntityPermissionError,
		ApiAlterEntityPermissionVariables
	>({
		mutationFn: (variables: ApiAlterEntityPermissionVariables) =>
			fetchApiAlterEntityPermission({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiEntityHasCustomPermissionsPathParams = {
	entityId: string;
};

export type ApiEntityHasCustomPermissionsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiEntityHasCustomPermissionsVariables = {
	pathParams: ApiEntityHasCustomPermissionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiEntityHasCustomPermissions = (
	variables: ApiEntityHasCustomPermissionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		boolean,
		ApiEntityHasCustomPermissionsError,
		undefined,
		{},
		{},
		ApiEntityHasCustomPermissionsPathParams
	>({
		url: '/resource/all/{entityId}/permissions_v2/has_custom/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiEntityHasCustomPermissions = <TData = boolean,>(
	variables: ApiEntityHasCustomPermissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			boolean,
			ApiEntityHasCustomPermissionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		boolean,
		ApiEntityHasCustomPermissionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/resource/all/{entityId}/permissions_v2/has_custom/',
			operationId: 'apiEntityHasCustomPermissions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiEntityHasCustomPermissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiResetEntityCustomPermissionsPathParams = {
	entityId: string;
};

export type ApiResetEntityCustomPermissionsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiResetEntityCustomPermissionsVariables = {
	pathParams: ApiResetEntityCustomPermissionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiResetEntityCustomPermissions = (
	variables: ApiResetEntityCustomPermissionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiResetEntityCustomPermissionsError,
		undefined,
		{},
		{},
		ApiResetEntityCustomPermissionsPathParams
	>({
		url: '/resource/all/{entityId}/permissions_v2/reset_custom/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiResetEntityCustomPermissions = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiResetEntityCustomPermissionsError,
			ApiResetEntityCustomPermissionsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiResetEntityCustomPermissionsError,
		ApiResetEntityCustomPermissionsVariables
	>({
		mutationFn: (variables: ApiResetEntityCustomPermissionsVariables) =>
			fetchApiResetEntityCustomPermissions({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetTablePreviewPathParams = {
	entityId: string;
};

export type ApiGetTablePreviewError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetTablePreviewVariables = {
	pathParams: ApiGetTablePreviewPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetTablePreview = (
	variables: ApiGetTablePreviewVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.DataFramePreviewResponse,
		ApiGetTablePreviewError,
		undefined,
		{},
		{},
		ApiGetTablePreviewPathParams
	>({
		url: '/resource/preview_v2/table/{entityId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetTablePreview = <
	TData = Schemas.DataFramePreviewResponse,
>(
	variables: ApiGetTablePreviewVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DataFramePreviewResponse,
			ApiGetTablePreviewError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.DataFramePreviewResponse,
		ApiGetTablePreviewError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/resource/preview_v2/table/{entityId}/',
			operationId: 'apiGetTablePreview',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetTablePreview({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetUrlPreviewPathParams = {
	entityId: string;
};

export type ApiGetUrlPreviewError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetUrlPreviewVariables = {
	pathParams: ApiGetUrlPreviewPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetUrlPreview = (
	variables: ApiGetUrlPreviewVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.UrlPreviewResponse,
		ApiGetUrlPreviewError,
		undefined,
		{},
		{},
		ApiGetUrlPreviewPathParams
	>({
		url: '/resource/preview_v2/url/{entityId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetUrlPreview = <TData = Schemas.UrlPreviewResponse,>(
	variables: ApiGetUrlPreviewVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.UrlPreviewResponse,
			ApiGetUrlPreviewError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.UrlPreviewResponse,
		ApiGetUrlPreviewError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/resource/preview_v2/url/{entityId}/',
			operationId: 'apiGetUrlPreview',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetUrlPreview({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiTablePropertiesAddCustomPropertyPathParams = {
	entityId: string;
};

export type ApiTablePropertiesAddCustomPropertyError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiTablePropertiesAddCustomPropertyVariables = {
	body: Schemas.AddCustomPropertyRequest;
	pathParams: ApiTablePropertiesAddCustomPropertyPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiTablePropertiesAddCustomProperty = (
	variables: ApiTablePropertiesAddCustomPropertyVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiTablePropertiesAddCustomPropertyError,
		Schemas.AddCustomPropertyRequest,
		{},
		{},
		ApiTablePropertiesAddCustomPropertyPathParams
	>({
		url: '/resource/table_properties/{entityId}/add_custom_property/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiTablePropertiesAddCustomProperty = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiTablePropertiesAddCustomPropertyError,
			ApiTablePropertiesAddCustomPropertyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiTablePropertiesAddCustomPropertyError,
		ApiTablePropertiesAddCustomPropertyVariables
	>({
		mutationFn: (variables: ApiTablePropertiesAddCustomPropertyVariables) =>
			fetchApiTablePropertiesAddCustomProperty({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiTablePropertiesRenameCustomPropertyPathParams = {
	entityId: string;
};

export type ApiTablePropertiesRenameCustomPropertyError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiTablePropertiesRenameCustomPropertyVariables = {
	body: Schemas.RenameCustomPropertyRequest;
	pathParams: ApiTablePropertiesRenameCustomPropertyPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiTablePropertiesRenameCustomProperty = (
	variables: ApiTablePropertiesRenameCustomPropertyVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiTablePropertiesRenameCustomPropertyError,
		Schemas.RenameCustomPropertyRequest,
		{},
		{},
		ApiTablePropertiesRenameCustomPropertyPathParams
	>({
		url: '/resource/table_properties/{entityId}/rename_custom_property/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiTablePropertiesRenameCustomProperty = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiTablePropertiesRenameCustomPropertyError,
			ApiTablePropertiesRenameCustomPropertyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiTablePropertiesRenameCustomPropertyError,
		ApiTablePropertiesRenameCustomPropertyVariables
	>({
		mutationFn: (variables: ApiTablePropertiesRenameCustomPropertyVariables) =>
			fetchApiTablePropertiesRenameCustomProperty({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiTablePropertiesRemoveCustomPropertyPathParams = {
	entityId: string;
};

export type ApiTablePropertiesRemoveCustomPropertyError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiTablePropertiesRemoveCustomPropertyVariables = {
	body: Schemas.RemoveCustomPropertyRequest;
	pathParams: ApiTablePropertiesRemoveCustomPropertyPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiTablePropertiesRemoveCustomProperty = (
	variables: ApiTablePropertiesRemoveCustomPropertyVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiTablePropertiesRemoveCustomPropertyError,
		Schemas.RemoveCustomPropertyRequest,
		{},
		{},
		ApiTablePropertiesRemoveCustomPropertyPathParams
	>({
		url: '/resource/table_properties/{entityId}/remove_custom_property/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiTablePropertiesRemoveCustomProperty = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiTablePropertiesRemoveCustomPropertyError,
			ApiTablePropertiesRemoveCustomPropertyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiTablePropertiesRemoveCustomPropertyError,
		ApiTablePropertiesRemoveCustomPropertyVariables
	>({
		mutationFn: (variables: ApiTablePropertiesRemoveCustomPropertyVariables) =>
			fetchApiTablePropertiesRemoveCustomProperty({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiTablePropertiesGenerateProfilePathParams = {
	entityId: string;
};

export type ApiTablePropertiesGenerateProfileError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiTablePropertiesGenerateProfileVariables = {
	pathParams: ApiTablePropertiesGenerateProfilePathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiTablePropertiesGenerateProfile = (
	variables: ApiTablePropertiesGenerateProfileVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.BackgroundJobOut,
		ApiTablePropertiesGenerateProfileError,
		undefined,
		{},
		{},
		ApiTablePropertiesGenerateProfilePathParams
	>({
		url: '/resource/table_properties/{entityId}/generate_profile/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiTablePropertiesGenerateProfile = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.BackgroundJobOut,
			ApiTablePropertiesGenerateProfileError,
			ApiTablePropertiesGenerateProfileVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.BackgroundJobOut,
		ApiTablePropertiesGenerateProfileError,
		ApiTablePropertiesGenerateProfileVariables
	>({
		mutationFn: (variables: ApiTablePropertiesGenerateProfileVariables) =>
			fetchApiTablePropertiesGenerateProfile({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiGetEntityStakeholdersPathParams = {
	entityId: string;
};

export type ApiGetEntityStakeholdersError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetEntityStakeholdersVariables = {
	pathParams: ApiGetEntityStakeholdersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetEntityStakeholders = (
	variables: ApiGetEntityStakeholdersVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.EntityStakeholderResponse,
		ApiGetEntityStakeholdersError,
		undefined,
		{},
		{},
		ApiGetEntityStakeholdersPathParams
	>({
		url: '/resource/all_v2/{entityId}/stakeholders/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetEntityStakeholders = <
	TData = Schemas.EntityStakeholderResponse,
>(
	variables: ApiGetEntityStakeholdersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EntityStakeholderResponse,
			ApiGetEntityStakeholdersError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.EntityStakeholderResponse,
		ApiGetEntityStakeholdersError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/resource/all_v2/{entityId}/stakeholders/',
			operationId: 'apiGetEntityStakeholders',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetEntityStakeholders(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiAsyncBulkUpdateEntityError = Fetcher.ErrorWrapper<undefined>;

export type ApiAsyncBulkUpdateEntityRequestBody = Schemas.BulkUpdateEntity[];

export type ApiAsyncBulkUpdateEntityVariables = {
	body?: ApiAsyncBulkUpdateEntityRequestBody;
} & ApiContext['fetcherOptions'];

export const fetchApiAsyncBulkUpdateEntity = (
	variables: ApiAsyncBulkUpdateEntityVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		string,
		ApiAsyncBulkUpdateEntityError,
		ApiAsyncBulkUpdateEntityRequestBody,
		{},
		{},
		{}
	>({
		url: '/resource/all_v2/async_bulk_update/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiAsyncBulkUpdateEntity = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			ApiAsyncBulkUpdateEntityError,
			ApiAsyncBulkUpdateEntityVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		string,
		ApiAsyncBulkUpdateEntityError,
		ApiAsyncBulkUpdateEntityVariables
	>({
		mutationFn: (variables: ApiAsyncBulkUpdateEntityVariables) =>
			fetchApiAsyncBulkUpdateEntity({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetBackgroundJobQueryParams = {
	type?: string | null;
};

export type ApiGetBackgroundJobError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetBackgroundJobResponse = Schemas.BackgroundJobOut[];

export type ApiGetBackgroundJobVariables = {
	queryParams?: ApiGetBackgroundJobQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetBackgroundJob = (
	variables: ApiGetBackgroundJobVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetBackgroundJobResponse,
		ApiGetBackgroundJobError,
		undefined,
		{},
		ApiGetBackgroundJobQueryParams,
		{}
	>({ url: '/admin/background_job/', method: 'get', ...variables, signal });

export const useApiGetBackgroundJob = <TData = ApiGetBackgroundJobResponse,>(
	variables: ApiGetBackgroundJobVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetBackgroundJobResponse,
			ApiGetBackgroundJobError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetBackgroundJobResponse,
		ApiGetBackgroundJobError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/admin/background_job/',
			operationId: 'apiGetBackgroundJob',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetBackgroundJob({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiCreateBackgroundJobError = Fetcher.ErrorWrapper<undefined>;

export type ApiCreateBackgroundJobVariables = {
	body: Schemas.BackgroundJobIn;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateBackgroundJob = (
	variables: ApiCreateBackgroundJobVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.BackgroundJobOut,
		ApiCreateBackgroundJobError,
		Schemas.BackgroundJobIn,
		{},
		{},
		{}
	>({
		url: '/admin/background_job/create/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiCreateBackgroundJob = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.BackgroundJobOut,
			ApiCreateBackgroundJobError,
			ApiCreateBackgroundJobVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.BackgroundJobOut,
		ApiCreateBackgroundJobError,
		ApiCreateBackgroundJobVariables
	>({
		mutationFn: (variables: ApiCreateBackgroundJobVariables) =>
			fetchApiCreateBackgroundJob({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiTerminateBackgroundJobPathParams = {
	jobId: string;
};

export type ApiTerminateBackgroundJobError = Fetcher.ErrorWrapper<undefined>;

export type ApiTerminateBackgroundJobVariables = {
	pathParams: ApiTerminateBackgroundJobPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiTerminateBackgroundJob = (
	variables: ApiTerminateBackgroundJobVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.BackgroundJobOut,
		ApiTerminateBackgroundJobError,
		undefined,
		{},
		{},
		ApiTerminateBackgroundJobPathParams
	>({
		url: '/admin/background_job/terminate/{jobId}/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiTerminateBackgroundJob = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.BackgroundJobOut,
			ApiTerminateBackgroundJobError,
			ApiTerminateBackgroundJobVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.BackgroundJobOut,
		ApiTerminateBackgroundJobError,
		ApiTerminateBackgroundJobVariables
	>({
		mutationFn: (variables: ApiTerminateBackgroundJobVariables) =>
			fetchApiTerminateBackgroundJob({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetLatestGitInfoError = Fetcher.ErrorWrapper<undefined>;

export type GetLatestGitInfoVariables = ApiContext['fetcherOptions'];

export const fetchGetLatestGitInfo = (
	variables: GetLatestGitInfoVariables,
	signal?: AbortSignal
) =>
	apiFetch<Schemas.CommitData, GetLatestGitInfoError, undefined, {}, {}, {}>({
		url: '/admin/github/latest_git_info/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetLatestGitInfo = <TData = Schemas.CommitData,>(
	variables: GetLatestGitInfoVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.CommitData,
			GetLatestGitInfoError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.CommitData, GetLatestGitInfoError, TData>({
		queryKey: queryKeyFn({
			path: '/admin/github/latest_git_info/',
			operationId: 'getLatestGitInfo',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetLatestGitInfo({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type GetCommitInfoPathParams = {
	databuilderJobId: string;
};

export type GetCommitInfoError = Fetcher.ErrorWrapper<undefined>;

export type GetCommitInfoVariables = {
	pathParams: GetCommitInfoPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetCommitInfo = (
	variables: GetCommitInfoVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.CommitData,
		GetCommitInfoError,
		undefined,
		{},
		{},
		GetCommitInfoPathParams
	>({
		url: '/admin/github/commit_info/{databuilderJobId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetCommitInfo = <TData = Schemas.CommitData,>(
	variables: GetCommitInfoVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<Schemas.CommitData, GetCommitInfoError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.CommitData, GetCommitInfoError, TData>({
		queryKey: queryKeyFn({
			path: '/admin/github/commit_info/{databuilderJobId}/',
			operationId: 'getCommitInfo',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetCommitInfo({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetIntegrationApiSpecPathParams = {
	integrationId: string;
};

export type ApiGetIntegrationApiSpecError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetIntegrationApiSpecVariables = {
	pathParams: ApiGetIntegrationApiSpecPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetIntegrationApiSpec = (
	variables: ApiGetIntegrationApiSpecVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.IntegrationApiSpec,
		ApiGetIntegrationApiSpecError,
		undefined,
		{},
		{},
		ApiGetIntegrationApiSpecPathParams
	>({
		url: '/admin/inspect_api/{integrationId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetIntegrationApiSpec = <
	TData = Schemas.IntegrationApiSpec,
>(
	variables: ApiGetIntegrationApiSpecVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.IntegrationApiSpec,
			ApiGetIntegrationApiSpecError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.IntegrationApiSpec,
		ApiGetIntegrationApiSpecError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/admin/inspect_api/{integrationId}/',
			operationId: 'apiGetIntegrationApiSpec',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetIntegrationApiSpec(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiRunIntegrationApiMethodPathParams = {
	integrationId: string;
	methodName: string;
};

export type ApiRunIntegrationApiMethodQueryParams = {
	/**
	 * @default false
	 */
	show_original?: boolean;
};

export type ApiRunIntegrationApiMethodError = Fetcher.ErrorWrapper<undefined>;

export type ApiRunIntegrationApiMethodVariables = {
	body?: Record<string, any>;
	pathParams: ApiRunIntegrationApiMethodPathParams;
	queryParams?: ApiRunIntegrationApiMethodQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiRunIntegrationApiMethod = (
	variables: ApiRunIntegrationApiMethodVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		void,
		ApiRunIntegrationApiMethodError,
		Record<string, any>,
		{},
		ApiRunIntegrationApiMethodQueryParams,
		ApiRunIntegrationApiMethodPathParams
	>({
		url: '/admin/inspect_api/{integrationId}/{methodName}/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiRunIntegrationApiMethod = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			void,
			ApiRunIntegrationApiMethodError,
			ApiRunIntegrationApiMethodVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		void,
		ApiRunIntegrationApiMethodError,
		ApiRunIntegrationApiMethodVariables
	>({
		mutationFn: (variables: ApiRunIntegrationApiMethodVariables) =>
			fetchApiRunIntegrationApiMethod({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetInternalIntegrationStatusQueryParams = {
	/**
	 * @default 10
	 */
	latest_k?: number;
};

export type ApiGetInternalIntegrationStatusError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiGetInternalIntegrationStatusResponse =
	Schemas.InternalIntegrationStatus[];

export type ApiGetInternalIntegrationStatusVariables = {
	queryParams?: ApiGetInternalIntegrationStatusQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetInternalIntegrationStatus = (
	variables: ApiGetInternalIntegrationStatusVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetInternalIntegrationStatusResponse,
		ApiGetInternalIntegrationStatusError,
		undefined,
		{},
		ApiGetInternalIntegrationStatusQueryParams,
		{}
	>({
		url: '/admin/internal_integration_status/status/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetInternalIntegrationStatus = <
	TData = ApiGetInternalIntegrationStatusResponse,
>(
	variables: ApiGetInternalIntegrationStatusVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetInternalIntegrationStatusResponse,
			ApiGetInternalIntegrationStatusError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetInternalIntegrationStatusResponse,
		ApiGetInternalIntegrationStatusError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/admin/internal_integration_status/status/',
			operationId: 'apiGetInternalIntegrationStatus',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetInternalIntegrationStatus(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiGetInternalIntegrationJobBreakdownPathParams = {
	databuilderJobId: string;
};

export type ApiGetInternalIntegrationJobBreakdownError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiGetInternalIntegrationJobBreakdownVariables = {
	pathParams: ApiGetInternalIntegrationJobBreakdownPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetInternalIntegrationJobBreakdown = (
	variables: ApiGetInternalIntegrationJobBreakdownVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiGetInternalIntegrationJobBreakdownError,
		undefined,
		{},
		{},
		ApiGetInternalIntegrationJobBreakdownPathParams
	>({
		url: '/admin/internal_integration_status/job_breakdown/{databuilderJobId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetInternalIntegrationJobBreakdown = <TData = undefined,>(
	variables: ApiGetInternalIntegrationJobBreakdownVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			undefined,
			ApiGetInternalIntegrationJobBreakdownError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		undefined,
		ApiGetInternalIntegrationJobBreakdownError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/admin/internal_integration_status/job_breakdown/{databuilderJobId}/',
			operationId: 'apiGetInternalIntegrationJobBreakdown',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetInternalIntegrationJobBreakdown(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type RemoveDanglingJobsPathParams = {
	integrationId: string;
};

export type RemoveDanglingJobsError = Fetcher.ErrorWrapper<undefined>;

export type RemoveDanglingJobsVariables = {
	pathParams: RemoveDanglingJobsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRemoveDanglingJobs = (
	variables: RemoveDanglingJobsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		RemoveDanglingJobsError,
		undefined,
		{},
		{},
		RemoveDanglingJobsPathParams
	>({
		url: '/admin/internal_integration_status/remove_dangling_jobs/{integrationId}/',
		method: 'patch',
		...variables,
		signal,
	});

export const useRemoveDanglingJobs = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RemoveDanglingJobsError,
			RemoveDanglingJobsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		RemoveDanglingJobsError,
		RemoveDanglingJobsVariables
	>({
		mutationFn: (variables: RemoveDanglingJobsVariables) =>
			fetchRemoveDanglingJobs({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type RemoveReservedJobPathParams = {
	databuilderJobId: string;
};

export type RemoveReservedJobError = Fetcher.ErrorWrapper<undefined>;

export type RemoveReservedJobVariables = {
	pathParams: RemoveReservedJobPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRemoveReservedJob = (
	variables: RemoveReservedJobVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		RemoveReservedJobError,
		undefined,
		{},
		{},
		RemoveReservedJobPathParams
	>({
		url: '/admin/internal_integration_status/remove_reserved_job/{databuilderJobId}/',
		method: 'patch',
		...variables,
		signal,
	});

export const useRemoveReservedJob = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RemoveReservedJobError,
			RemoveReservedJobVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		RemoveReservedJobError,
		RemoveReservedJobVariables
	>({
		mutationFn: (variables: RemoveReservedJobVariables) =>
			fetchRemoveReservedJob({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type RetryDatabuilderJobPathParams = {
	databuilderJobId: string;
};

export type RetryDatabuilderJobError = Fetcher.ErrorWrapper<undefined>;

export type RetryDatabuilderJobVariables = {
	pathParams: RetryDatabuilderJobPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRetryDatabuilderJob = (
	variables: RetryDatabuilderJobVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		RetryDatabuilderJobError,
		undefined,
		{},
		{},
		RetryDatabuilderJobPathParams
	>({
		url: '/admin/internal_integration_status/retry_databuilder_job/{databuilderJobId}/',
		method: 'patch',
		...variables,
		signal,
	});

export const useRetryDatabuilderJob = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RetryDatabuilderJobError,
			RetryDatabuilderJobVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		RetryDatabuilderJobError,
		RetryDatabuilderJobVariables
	>({
		mutationFn: (variables: RetryDatabuilderJobVariables) =>
			fetchRetryDatabuilderJob({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetInTrialWorkspacesError = Fetcher.ErrorWrapper<undefined>;

export type GetInTrialWorkspacesResponse = string[];

export type GetInTrialWorkspacesVariables = ApiContext['fetcherOptions'];

export const fetchGetInTrialWorkspaces = (
	variables: GetInTrialWorkspacesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetInTrialWorkspacesResponse,
		GetInTrialWorkspacesError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/admin/internal_integration_status/in_trial_workspaces/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetInTrialWorkspaces = <TData = GetInTrialWorkspacesResponse,>(
	variables: GetInTrialWorkspacesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetInTrialWorkspacesResponse,
			GetInTrialWorkspacesError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetInTrialWorkspacesResponse,
		GetInTrialWorkspacesError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/admin/internal_integration_status/in_trial_workspaces/',
			operationId: 'getInTrialWorkspaces',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetInTrialWorkspaces({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type IsSuperadminError = Fetcher.ErrorWrapper<undefined>;

export type IsSuperadminVariables = ApiContext['fetcherOptions'];

export const fetchIsSuperadmin = (
	variables: IsSuperadminVariables,
	signal?: AbortSignal
) =>
	apiFetch<boolean, IsSuperadminError, undefined, {}, {}, {}>({
		url: '/admin/internal_query_actions/is_superadmin/',
		method: 'get',
		...variables,
		signal,
	});

export const useIsSuperadmin = <TData = boolean,>(
	variables: IsSuperadminVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<boolean, IsSuperadminError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<boolean, IsSuperadminError, TData>({
		queryKey: queryKeyFn({
			path: '/admin/internal_query_actions/is_superadmin/',
			operationId: 'isSuperadmin',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchIsSuperadmin({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type LineageQueryParams = {
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type LineageError = Fetcher.ErrorWrapper<undefined>;

export type LineageVariables = {
	body: Schemas.LineageIn;
	queryParams?: LineageQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchLineage = (
	variables: LineageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedLineageOut2,
		LineageError,
		Schemas.LineageIn,
		{},
		LineageQueryParams,
		{}
	>({
		url: '/admin/internal_query_actions/lineage/',
		method: 'post',
		...variables,
		signal,
	});

export const useLineage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PagedLineageOut2,
			LineageError,
			LineageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.PagedLineageOut2,
		LineageError,
		LineageVariables
	>({
		mutationFn: (variables: LineageVariables) =>
			fetchLineage({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type LineageCountError = Fetcher.ErrorWrapper<undefined>;

export type LineageCountVariables = {
	body: Schemas.LineageIn;
} & ApiContext['fetcherOptions'];

export const fetchLineageCount = (
	variables: LineageCountVariables,
	signal?: AbortSignal
) =>
	apiFetch<number, LineageCountError, Schemas.LineageIn, {}, {}, {}>({
		url: '/admin/internal_query_actions/lineage_count/',
		method: 'post',
		...variables,
		signal,
	});

export const useLineageCount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			number,
			LineageCountError,
			LineageCountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		number,
		LineageCountError,
		LineageCountVariables
	>({
		mutationFn: (variables: LineageCountVariables) =>
			fetchLineageCount({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ResourceQueryParams = {
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ResourceError = Fetcher.ErrorWrapper<undefined>;

export type ResourceVariables = {
	body: Schemas.ResourceIn;
	queryParams?: ResourceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchResource = (
	variables: ResourceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedResourceOut,
		ResourceError,
		Schemas.ResourceIn,
		{},
		ResourceQueryParams,
		{}
	>({
		url: '/admin/internal_query_actions/resource/',
		method: 'post',
		...variables,
		signal,
	});

export const useResource = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PagedResourceOut,
			ResourceError,
			ResourceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.PagedResourceOut,
		ResourceError,
		ResourceVariables
	>({
		mutationFn: (variables: ResourceVariables) =>
			fetchResource({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ResourceCountError = Fetcher.ErrorWrapper<undefined>;

export type ResourceCountVariables = {
	body: Schemas.ResourceIn;
} & ApiContext['fetcherOptions'];

export const fetchResourceCount = (
	variables: ResourceCountVariables,
	signal?: AbortSignal
) =>
	apiFetch<number, ResourceCountError, Schemas.ResourceIn, {}, {}, {}>({
		url: '/admin/internal_query_actions/resource_count/',
		method: 'post',
		...variables,
		signal,
	});

export const useResourceCount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			number,
			ResourceCountError,
			ResourceCountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		number,
		ResourceCountError,
		ResourceCountVariables
	>({
		mutationFn: (variables: ResourceCountVariables) =>
			fetchResourceCount({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type CreateLinearTicketError = Fetcher.ErrorWrapper<undefined>;

export type CreateLinearTicketVariables = {
	body: Schemas.TicketIn;
} & ApiContext['fetcherOptions'];

export const fetchCreateLinearTicket = (
	variables: CreateLinearTicketVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.TicketCreateOut,
		CreateLinearTicketError,
		Schemas.TicketIn,
		{},
		{},
		{}
	>({
		url: '/admin/linear/create_linear_ticket/',
		method: 'post',
		...variables,
		signal,
	});

export const useCreateLinearTicket = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TicketCreateOut,
			CreateLinearTicketError,
			CreateLinearTicketVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.TicketCreateOut,
		CreateLinearTicketError,
		CreateLinearTicketVariables
	>({
		mutationFn: (variables: CreateLinearTicketVariables) =>
			fetchCreateLinearTicket({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetLinearTicketsPathParams = {
	title: string;
};

export type GetLinearTicketsError = Fetcher.ErrorWrapper<undefined>;

export type GetLinearTicketsResponse = Schemas.LinearTicket[];

export type GetLinearTicketsVariables = {
	pathParams: GetLinearTicketsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetLinearTickets = (
	variables: GetLinearTicketsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetLinearTicketsResponse,
		GetLinearTicketsError,
		undefined,
		{},
		{},
		GetLinearTicketsPathParams
	>({
		url: '/admin/linear/get_linear_tickets/{title}/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetLinearTickets = <TData = GetLinearTicketsResponse,>(
	variables: GetLinearTicketsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetLinearTicketsResponse,
			GetLinearTicketsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetLinearTicketsResponse,
		GetLinearTicketsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/admin/linear/get_linear_tickets/{title}/',
			operationId: 'getLinearTickets',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetLinearTickets({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type LinkLinearTicketError = Fetcher.ErrorWrapper<undefined>;

export type LinkLinearTicketVariables = {
	body: Schemas.LinkTicketIn;
} & ApiContext['fetcherOptions'];

export const fetchLinkLinearTicket = (
	variables: LinkLinearTicketVariables,
	signal?: AbortSignal
) =>
	apiFetch<undefined, LinkLinearTicketError, Schemas.LinkTicketIn, {}, {}, {}>({
		url: '/admin/linear/link_linear_ticket/',
		method: 'post',
		...variables,
		signal,
	});

export const useLinkLinearTicket = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			LinkLinearTicketError,
			LinkLinearTicketVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		LinkLinearTicketError,
		LinkLinearTicketVariables
	>({
		mutationFn: (variables: LinkLinearTicketVariables) =>
			fetchLinkLinearTicket({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type RemoveLinearTicketError = Fetcher.ErrorWrapper<undefined>;

export type RemoveLinearTicketVariables = {
	body: Schemas.RemoveTicketIn;
} & ApiContext['fetcherOptions'];

export const fetchRemoveLinearTicket = (
	variables: RemoveLinearTicketVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		RemoveLinearTicketError,
		Schemas.RemoveTicketIn,
		{},
		{},
		{}
	>({
		url: '/admin/linear/remove_linear_ticket/',
		method: 'post',
		...variables,
		signal,
	});

export const useRemoveLinearTicket = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RemoveLinearTicketError,
			RemoveLinearTicketVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		RemoveLinearTicketError,
		RemoveLinearTicketVariables
	>({
		mutationFn: (variables: RemoveLinearTicketVariables) =>
			fetchRemoveLinearTicket({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetCollectionEntitiesCountPathParams = {
	entityId: string;
};

export type ApiGetCollectionEntitiesCountError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiGetCollectionEntitiesCountResponse = {
	[key: string]: number;
};

export type ApiGetCollectionEntitiesCountVariables = {
	pathParams: ApiGetCollectionEntitiesCountPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetCollectionEntitiesCount = (
	variables: ApiGetCollectionEntitiesCountVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetCollectionEntitiesCountResponse,
		ApiGetCollectionEntitiesCountError,
		undefined,
		{},
		{},
		ApiGetCollectionEntitiesCountPathParams
	>({
		url: '/collection/collections/{entityId}/entities_count/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetCollectionEntitiesCount = <
	TData = ApiGetCollectionEntitiesCountResponse,
>(
	variables: ApiGetCollectionEntitiesCountVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetCollectionEntitiesCountResponse,
			ApiGetCollectionEntitiesCountError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetCollectionEntitiesCountResponse,
		ApiGetCollectionEntitiesCountError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/collection/collections/{entityId}/entities_count/',
			operationId: 'apiGetCollectionEntitiesCount',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetCollectionEntitiesCount(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type GetSearchMetaError = Fetcher.ErrorWrapper<undefined>;

export type GetSearchMetaVariables = ApiContext['fetcherOptions'];

export const fetchGetSearchMeta = (
	variables: GetSearchMetaVariables,
	signal?: AbortSignal
) =>
	apiFetch<Schemas.MetaOut, GetSearchMetaError, undefined, {}, {}, {}>({
		url: '/search/v2/meta/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetSearchMeta = <TData = Schemas.MetaOut,>(
	variables: GetSearchMetaVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<Schemas.MetaOut, GetSearchMetaError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.MetaOut, GetSearchMetaError, TData>({
		queryKey: queryKeyFn({
			path: '/search/v2/meta/',
			operationId: 'getSearchMeta',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetSearchMeta({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiListTeamsQueryParams = {
	/**
	 * @default false
	 */
	include_archived?: boolean;
	/**
	 * @default false
	 */
	only_joined?: boolean;
	/**
	 * @default false
	 */
	only_write?: boolean;
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ApiListTeamsError = Fetcher.ErrorWrapper<undefined>;

export type ApiListTeamsVariables = {
	queryParams?: ApiListTeamsQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * @param include_archived: Return archived teams
 * @param only_joined: Only return teams that current user has joined
 * @param only_write: Only return teams that current user is an editor of
 */
export const fetchApiListTeams = (
	variables: ApiListTeamsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedTeamOut,
		ApiListTeamsError,
		undefined,
		{},
		ApiListTeamsQueryParams,
		{}
	>({ url: '/auth/teams/', method: 'get', ...variables, signal });

/**
 * @param include_archived: Return archived teams
 * @param only_joined: Only return teams that current user has joined
 * @param only_write: Only return teams that current user is an editor of
 */
export const useApiListTeams = <TData = Schemas.PagedTeamOut,>(
	variables: ApiListTeamsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<Schemas.PagedTeamOut, ApiListTeamsError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.PagedTeamOut, ApiListTeamsError, TData>({
		queryKey: queryKeyFn({
			path: '/auth/teams/',
			operationId: 'apiListTeams',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiListTeams({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiCreateTeamError = Fetcher.ErrorWrapper<undefined>;

export type ApiCreateTeamVariables = {
	body: Schemas.TeamIn;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateTeam = (
	variables: ApiCreateTeamVariables,
	signal?: AbortSignal
) =>
	apiFetch<Schemas.TeamOut, ApiCreateTeamError, Schemas.TeamIn, {}, {}, {}>({
		url: '/auth/teams/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiCreateTeam = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TeamOut,
			ApiCreateTeamError,
			ApiCreateTeamVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.TeamOut,
		ApiCreateTeamError,
		ApiCreateTeamVariables
	>({
		mutationFn: (variables: ApiCreateTeamVariables) =>
			fetchApiCreateTeam({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetTeamPathParams = {
	teamId: string;
};

export type ApiGetTeamError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetTeamVariables = {
	pathParams: ApiGetTeamPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetTeam = (
	variables: ApiGetTeamVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.TeamOut,
		ApiGetTeamError,
		undefined,
		{},
		{},
		ApiGetTeamPathParams
	>({ url: '/auth/teams/{teamId}/', method: 'get', ...variables, signal });

export const useApiGetTeam = <TData = Schemas.TeamOut,>(
	variables: ApiGetTeamVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<Schemas.TeamOut, ApiGetTeamError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.TeamOut, ApiGetTeamError, TData>({
		queryKey: queryKeyFn({
			path: '/auth/teams/{teamId}/',
			operationId: 'apiGetTeam',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetTeam({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiUpdateTeamPathParams = {
	teamId: string;
};

export type ApiUpdateTeamError = Fetcher.ErrorWrapper<undefined>;

export type ApiUpdateTeamVariables = {
	body: Schemas.PartialTeamIn;
	pathParams: ApiUpdateTeamPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiUpdateTeam = (
	variables: ApiUpdateTeamVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.TeamOut,
		ApiUpdateTeamError,
		Schemas.PartialTeamIn,
		{},
		{},
		ApiUpdateTeamPathParams
	>({ url: '/auth/teams/{teamId}/', method: 'patch', ...variables, signal });

export const useApiUpdateTeam = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TeamOut,
			ApiUpdateTeamError,
			ApiUpdateTeamVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.TeamOut,
		ApiUpdateTeamError,
		ApiUpdateTeamVariables
	>({
		mutationFn: (variables: ApiUpdateTeamVariables) =>
			fetchApiUpdateTeam({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetMyMembershipsPathParams = {
	teamId: string;
};

export type ApiGetMyMembershipsError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetMyMembershipsResponse = Schemas.TeamMembershipOut[];

export type ApiGetMyMembershipsVariables = {
	pathParams: ApiGetMyMembershipsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetMyMemberships = (
	variables: ApiGetMyMembershipsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetMyMembershipsResponse,
		ApiGetMyMembershipsError,
		undefined,
		{},
		{},
		ApiGetMyMembershipsPathParams
	>({
		url: '/auth/teams/{teamId}/my_memberships/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetMyMemberships = <TData = ApiGetMyMembershipsResponse,>(
	variables: ApiGetMyMembershipsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetMyMembershipsResponse,
			ApiGetMyMembershipsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetMyMembershipsResponse,
		ApiGetMyMembershipsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/auth/teams/{teamId}/my_memberships/',
			operationId: 'apiGetMyMemberships',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetMyMemberships({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiJoinTeamPathParams = {
	teamId: string;
};

export type ApiJoinTeamError = Fetcher.ErrorWrapper<undefined>;

export type ApiJoinTeamVariables = {
	pathParams: ApiJoinTeamPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiJoinTeam = (
	variables: ApiJoinTeamVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.TeamMembershipOut,
		ApiJoinTeamError,
		undefined,
		{},
		{},
		ApiJoinTeamPathParams
	>({
		url: '/auth/teams/{teamId}/join/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiJoinTeam = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TeamMembershipOut,
			ApiJoinTeamError,
			ApiJoinTeamVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.TeamMembershipOut,
		ApiJoinTeamError,
		ApiJoinTeamVariables
	>({
		mutationFn: (variables: ApiJoinTeamVariables) =>
			fetchApiJoinTeam({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiLeaveTeamPathParams = {
	teamId: string;
};

export type ApiLeaveTeamError = Fetcher.ErrorWrapper<undefined>;

export type ApiLeaveTeamVariables = {
	pathParams: ApiLeaveTeamPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiLeaveTeam = (
	variables: ApiLeaveTeamVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiLeaveTeamError,
		undefined,
		{},
		{},
		ApiLeaveTeamPathParams
	>({
		url: '/auth/teams/{teamId}/leave/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiLeaveTeam = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiLeaveTeamError,
			ApiLeaveTeamVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiLeaveTeamError,
		ApiLeaveTeamVariables
	>({
		mutationFn: (variables: ApiLeaveTeamVariables) =>
			fetchApiLeaveTeam({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetTeamEmptyStatePathParams = {
	teamId: string;
	sectionName: string;
};

export type ApiGetTeamEmptyStateError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetTeamEmptyStateVariables = {
	pathParams: ApiGetTeamEmptyStatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetTeamEmptyState = (
	variables: ApiGetTeamEmptyStateVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.EmptyStateReponse,
		ApiGetTeamEmptyStateError,
		undefined,
		{},
		{},
		ApiGetTeamEmptyStatePathParams
	>({
		url: '/auth/teams/{teamId}/empty_state/{sectionName}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetTeamEmptyState = <TData = Schemas.EmptyStateReponse,>(
	variables: ApiGetTeamEmptyStateVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EmptyStateReponse,
			ApiGetTeamEmptyStateError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.EmptyStateReponse,
		ApiGetTeamEmptyStateError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/auth/teams/{teamId}/empty_state/{sectionName}/',
			operationId: 'apiGetTeamEmptyState',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetTeamEmptyState({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetTeamListUsersPathParams = {
	teamId: string;
};

export type ApiGetTeamListUsersQueryParams = {
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ApiGetTeamListUsersError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetTeamListUsersVariables = {
	pathParams: ApiGetTeamListUsersPathParams;
	queryParams?: ApiGetTeamListUsersQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetTeamListUsers = (
	variables: ApiGetTeamListUsersVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedLiteUserOut,
		ApiGetTeamListUsersError,
		undefined,
		{},
		ApiGetTeamListUsersQueryParams,
		ApiGetTeamListUsersPathParams
	>({
		url: '/auth/teams/{teamId}/users/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetTeamListUsers = <TData = Schemas.PagedLiteUserOut,>(
	variables: ApiGetTeamListUsersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedLiteUserOut,
			ApiGetTeamListUsersError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedLiteUserOut,
		ApiGetTeamListUsersError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/auth/teams/{teamId}/users/',
			operationId: 'apiGetTeamListUsers',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetTeamListUsers({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiBulkCreateMembershipsPathParams = {
	teamId: string;
};

export type ApiBulkCreateMembershipsError = Fetcher.ErrorWrapper<undefined>;

export type ApiBulkCreateMembershipsResponse = Schemas.TeamMembershipOut[];

export type ApiBulkCreateMembershipsRequestBody = Schemas.TeamMembershipIn[];

export type ApiBulkCreateMembershipsVariables = {
	body?: ApiBulkCreateMembershipsRequestBody;
	pathParams: ApiBulkCreateMembershipsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiBulkCreateMemberships = (
	variables: ApiBulkCreateMembershipsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiBulkCreateMembershipsResponse,
		ApiBulkCreateMembershipsError,
		ApiBulkCreateMembershipsRequestBody,
		{},
		{},
		ApiBulkCreateMembershipsPathParams
	>({
		url: '/auth/teams/{teamId}/memberships/bulk/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiBulkCreateMemberships = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			ApiBulkCreateMembershipsResponse,
			ApiBulkCreateMembershipsError,
			ApiBulkCreateMembershipsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		ApiBulkCreateMembershipsResponse,
		ApiBulkCreateMembershipsError,
		ApiBulkCreateMembershipsVariables
	>({
		mutationFn: (variables: ApiBulkCreateMembershipsVariables) =>
			fetchApiBulkCreateMemberships({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiListMembershipsPathParams = {
	teamId: string;
};

export type ApiListMembershipsQueryParams = {
	/**
	 * @default
	 */
	search_term?: string;
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ApiListMembershipsError = Fetcher.ErrorWrapper<undefined>;

export type ApiListMembershipsVariables = {
	pathParams: ApiListMembershipsPathParams;
	queryParams?: ApiListMembershipsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListMemberships = (
	variables: ApiListMembershipsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedTeamMembershipOut,
		ApiListMembershipsError,
		undefined,
		{},
		ApiListMembershipsQueryParams,
		ApiListMembershipsPathParams
	>({
		url: '/auth/teams/{teamId}/memberships/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiListMemberships = <TData = Schemas.PagedTeamMembershipOut,>(
	variables: ApiListMembershipsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedTeamMembershipOut,
			ApiListMembershipsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedTeamMembershipOut,
		ApiListMembershipsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/auth/teams/{teamId}/memberships/',
			operationId: 'apiListMemberships',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiListMemberships({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiUpdateMembershipPathParams = {
	teamId: string;
	membershipId: string;
};

export type ApiUpdateMembershipError = Fetcher.ErrorWrapper<undefined>;

export type ApiUpdateMembershipVariables = {
	body: Schemas.UpdateMembershipRequest;
	pathParams: ApiUpdateMembershipPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiUpdateMembership = (
	variables: ApiUpdateMembershipVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.TeamMembershipOut,
		ApiUpdateMembershipError,
		Schemas.UpdateMembershipRequest,
		{},
		{},
		ApiUpdateMembershipPathParams
	>({
		url: '/auth/teams/{teamId}/memberships/{membershipId}/',
		method: 'patch',
		...variables,
		signal,
	});

export const useApiUpdateMembership = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.TeamMembershipOut,
			ApiUpdateMembershipError,
			ApiUpdateMembershipVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.TeamMembershipOut,
		ApiUpdateMembershipError,
		ApiUpdateMembershipVariables
	>({
		mutationFn: (variables: ApiUpdateMembershipVariables) =>
			fetchApiUpdateMembership({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiDeleteMembershipPathParams = {
	teamId: string;
	membershipId: string;
};

export type ApiDeleteMembershipError = Fetcher.ErrorWrapper<undefined>;

export type ApiDeleteMembershipVariables = {
	pathParams: ApiDeleteMembershipPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiDeleteMembership = (
	variables: ApiDeleteMembershipVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiDeleteMembershipError,
		undefined,
		{},
		{},
		ApiDeleteMembershipPathParams
	>({
		url: '/auth/teams/{teamId}/memberships/{membershipId}/',
		method: 'delete',
		...variables,
		signal,
	});

export const useApiDeleteMembership = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiDeleteMembershipError,
			ApiDeleteMembershipVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiDeleteMembershipError,
		ApiDeleteMembershipVariables
	>({
		mutationFn: (variables: ApiDeleteMembershipVariables) =>
			fetchApiDeleteMembership({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiListApiKeysQueryParams = {
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ApiListApiKeysError = Fetcher.ErrorWrapper<undefined>;

export type ApiListApiKeysVariables = {
	queryParams?: ApiListApiKeysQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListApiKeys = (
	variables: ApiListApiKeysVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedAPIKeyOut,
		ApiListApiKeysError,
		undefined,
		{},
		ApiListApiKeysQueryParams,
		{}
	>({ url: '/auth/api_keys/', method: 'get', ...variables, signal });

export const useApiListApiKeys = <TData = Schemas.PagedAPIKeyOut,>(
	variables: ApiListApiKeysVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAPIKeyOut,
			ApiListApiKeysError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedAPIKeyOut,
		ApiListApiKeysError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/auth/api_keys/',
			operationId: 'apiListApiKeys',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiListApiKeys({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiCreateApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type ApiCreateApiKeyVariables = {
	body: Schemas.APIKeyIn;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateApiKey = (
	variables: ApiCreateApiKeyVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.APIKeyOut,
		ApiCreateApiKeyError,
		Schemas.APIKeyIn,
		{},
		{},
		{}
	>({ url: '/auth/api_keys/', method: 'post', ...variables, signal });

export const useApiCreateApiKey = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.APIKeyOut,
			ApiCreateApiKeyError,
			ApiCreateApiKeyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.APIKeyOut,
		ApiCreateApiKeyError,
		ApiCreateApiKeyVariables
	>({
		mutationFn: (variables: ApiCreateApiKeyVariables) =>
			fetchApiCreateApiKey({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiUpdateApiKeyPathParams = {
	apiKeyId: string;
};

export type ApiUpdateApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type ApiUpdateApiKeyVariables = {
	body: Schemas.APIKeyIn;
	pathParams: ApiUpdateApiKeyPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiUpdateApiKey = (
	variables: ApiUpdateApiKeyVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.APIKeyOut,
		ApiUpdateApiKeyError,
		Schemas.APIKeyIn,
		{},
		{},
		ApiUpdateApiKeyPathParams
	>({
		url: '/auth/api_keys/{apiKeyId}/',
		method: 'patch',
		...variables,
		signal,
	});

export const useApiUpdateApiKey = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.APIKeyOut,
			ApiUpdateApiKeyError,
			ApiUpdateApiKeyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.APIKeyOut,
		ApiUpdateApiKeyError,
		ApiUpdateApiKeyVariables
	>({
		mutationFn: (variables: ApiUpdateApiKeyVariables) =>
			fetchApiUpdateApiKey({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiDeleteApiKeyPathParams = {
	apiKeyId: string;
};

export type ApiDeleteApiKeyError = Fetcher.ErrorWrapper<undefined>;

export type ApiDeleteApiKeyVariables = {
	pathParams: ApiDeleteApiKeyPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiDeleteApiKey = (
	variables: ApiDeleteApiKeyVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiDeleteApiKeyError,
		undefined,
		{},
		{},
		ApiDeleteApiKeyPathParams
	>({
		url: '/auth/api_keys/{apiKeyId}/',
		method: 'delete',
		...variables,
		signal,
	});

export const useApiDeleteApiKey = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiDeleteApiKeyError,
			ApiDeleteApiKeyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiDeleteApiKeyError,
		ApiDeleteApiKeyVariables
	>({
		mutationFn: (variables: ApiDeleteApiKeyVariables) =>
			fetchApiDeleteApiKey({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type SwitchRegionPathParams = {
	regionName: string;
};

export type SwitchRegionError = Fetcher.ErrorWrapper<undefined>;

export type SwitchRegionVariables = {
	pathParams: SwitchRegionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSwitchRegion = (
	variables: SwitchRegionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		string,
		SwitchRegionError,
		undefined,
		{},
		{},
		SwitchRegionPathParams
	>({
		url: '/auth/region/{regionName}/',
		method: 'post',
		...variables,
		signal,
	});

export const useSwitchRegion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			SwitchRegionError,
			SwitchRegionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		string,
		SwitchRegionError,
		SwitchRegionVariables
	>({
		mutationFn: (variables: SwitchRegionVariables) =>
			fetchSwitchRegion({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type SetWorkspacePanPathParams = {
	workspaceId: string;
};

export type SetWorkspacePanError = Fetcher.ErrorWrapper<undefined>;

export type SetWorkspacePanVariables = {
	body: Schemas.WorkspacePlanIn;
	pathParams: SetWorkspacePanPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSetWorkspacePan = (
	variables: SetWorkspacePanVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.WorkspacePlanOut,
		SetWorkspacePanError,
		Schemas.WorkspacePlanIn,
		{},
		{},
		SetWorkspacePanPathParams
	>({
		url: '/superuser/workspace/{workspaceId}/plan',
		method: 'put',
		...variables,
		signal,
	});

export const useSetWorkspacePan = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.WorkspacePlanOut,
			SetWorkspacePanError,
			SetWorkspacePanVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.WorkspacePlanOut,
		SetWorkspacePanError,
		SetWorkspacePanVariables
	>({
		mutationFn: (variables: SetWorkspacePanVariables) =>
			fetchSetWorkspacePan({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type DisableWorkspacePathParams = {
	workspaceId: string;
};

export type DisableWorkspaceError = Fetcher.ErrorWrapper<undefined>;

export type DisableWorkspaceVariables = {
	pathParams: DisableWorkspacePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDisableWorkspace = (
	variables: DisableWorkspaceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		DisableWorkspaceError,
		undefined,
		{},
		{},
		DisableWorkspacePathParams
	>({
		url: '/superuser/workspace/{workspaceId}/disable',
		method: 'post',
		...variables,
		signal,
	});

export const useDisableWorkspace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			DisableWorkspaceError,
			DisableWorkspaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		DisableWorkspaceError,
		DisableWorkspaceVariables
	>({
		mutationFn: (variables: DisableWorkspaceVariables) =>
			fetchDisableWorkspace({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type EnableWorkspacePathParams = {
	workspaceId: string;
};

export type EnableWorkspaceError = Fetcher.ErrorWrapper<undefined>;

export type EnableWorkspaceVariables = {
	pathParams: EnableWorkspacePathParams;
} & ApiContext['fetcherOptions'];

export const fetchEnableWorkspace = (
	variables: EnableWorkspaceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		EnableWorkspaceError,
		undefined,
		{},
		{},
		EnableWorkspacePathParams
	>({
		url: '/superuser/workspace/{workspaceId}/enable',
		method: 'post',
		...variables,
		signal,
	});

export const useEnableWorkspace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			EnableWorkspaceError,
			EnableWorkspaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		EnableWorkspaceError,
		EnableWorkspaceVariables
	>({
		mutationFn: (variables: EnableWorkspaceVariables) =>
			fetchEnableWorkspace({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type SetWorkspaceLogoPathParams = {
	workspaceId: string;
};

export type SetWorkspaceLogoError = Fetcher.ErrorWrapper<undefined>;

export type SetWorkspaceLogoRequestBody = {
	/**
	 * @format binary
	 */
	file: Blob;
};

export type SetWorkspaceLogoVariables = {
	body: SetWorkspaceLogoRequestBody;
	pathParams: SetWorkspaceLogoPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSetWorkspaceLogo = (
	variables: SetWorkspaceLogoVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.WorkspaceSetLogoOut,
		SetWorkspaceLogoError,
		SetWorkspaceLogoRequestBody,
		{},
		{},
		SetWorkspaceLogoPathParams
	>({
		url: '/workspace/image/{workspaceId}/upload/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetWorkspaceLogo = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.WorkspaceSetLogoOut,
			SetWorkspaceLogoError,
			SetWorkspaceLogoVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.WorkspaceSetLogoOut,
		SetWorkspaceLogoError,
		SetWorkspaceLogoVariables
	>({
		mutationFn: (variables: SetWorkspaceLogoVariables) =>
			fetchSetWorkspaceLogo({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type SetUserProfileImageError = Fetcher.ErrorWrapper<undefined>;

export type SetUserProfileImageRequestBody = {
	/**
	 * @format binary
	 */
	file: Blob;
};

export type SetUserProfileImageVariables = {
	body: SetUserProfileImageRequestBody;
} & ApiContext['fetcherOptions'];

export const fetchSetUserProfileImage = (
	variables: SetUserProfileImageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		string,
		SetUserProfileImageError,
		SetUserProfileImageRequestBody,
		{},
		{},
		{}
	>({
		url: '/user/profile_picture/upload/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetUserProfileImage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			SetUserProfileImageError,
			SetUserProfileImageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		string,
		SetUserProfileImageError,
		SetUserProfileImageVariables
	>({
		mutationFn: (variables: SetUserProfileImageVariables) =>
			fetchSetUserProfileImage({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ListFormsQueryParams = {
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ListFormsError = Fetcher.ErrorWrapper<undefined>;

export type ListFormsVariables = {
	queryParams?: ListFormsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchListForms = (
	variables: ListFormsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedLiteFormOut,
		ListFormsError,
		undefined,
		{},
		ListFormsQueryParams,
		{}
	>({ url: '/forms/', method: 'get', ...variables, signal });

export const useListForms = <TData = Schemas.PagedLiteFormOut,>(
	variables: ListFormsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<Schemas.PagedLiteFormOut, ListFormsError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.PagedLiteFormOut, ListFormsError, TData>({
		queryKey: queryKeyFn({
			path: '/forms/',
			operationId: 'listForms',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchListForms({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type CreateFormError = Fetcher.ErrorWrapper<undefined>;

export type CreateFormVariables = {
	body: Schemas.FormIn;
} & ApiContext['fetcherOptions'];

export const fetchCreateForm = (
	variables: CreateFormVariables,
	signal?: AbortSignal
) =>
	apiFetch<Schemas.FormOut, CreateFormError, Schemas.FormIn, {}, {}, {}>({
		url: '/forms/',
		method: 'post',
		...variables,
		signal,
	});

export const useCreateForm = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormOut,
			CreateFormError,
			CreateFormVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.FormOut,
		CreateFormError,
		CreateFormVariables
	>({
		mutationFn: (variables: CreateFormVariables) =>
			fetchCreateForm({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type RetrieveFormPathParams = {
	formId: string;
};

export type RetrieveFormError = Fetcher.ErrorWrapper<undefined>;

export type RetrieveFormVariables = {
	pathParams: RetrieveFormPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRetrieveForm = (
	variables: RetrieveFormVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormOut,
		RetrieveFormError,
		undefined,
		{},
		{},
		RetrieveFormPathParams
	>({ url: '/forms/{formId}/', method: 'get', ...variables, signal });

export const useRetrieveForm = <TData = Schemas.FormOut,>(
	variables: RetrieveFormVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<Schemas.FormOut, RetrieveFormError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.FormOut, RetrieveFormError, TData>({
		queryKey: queryKeyFn({
			path: '/forms/{formId}/',
			operationId: 'retrieveForm',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRetrieveForm({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type DeleteFormPathParams = {
	formId: string;
};

export type DeleteFormError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFormVariables = {
	pathParams: DeleteFormPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteForm = (
	variables: DeleteFormVariables,
	signal?: AbortSignal
) =>
	apiFetch<undefined, DeleteFormError, undefined, {}, {}, DeleteFormPathParams>(
		{ url: '/forms/{formId}/', method: 'delete', ...variables, signal }
	);

export const useDeleteForm = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			DeleteFormError,
			DeleteFormVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		DeleteFormError,
		DeleteFormVariables
	>({
		mutationFn: (variables: DeleteFormVariables) =>
			fetchDeleteForm({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type UpdateFormPathParams = {
	formId: string;
};

export type UpdateFormError = Fetcher.ErrorWrapper<undefined>;

export type UpdateFormVariables = {
	body: Schemas.FormIn;
	pathParams: UpdateFormPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateForm = (
	variables: UpdateFormVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormOut,
		UpdateFormError,
		Schemas.FormIn,
		{},
		{},
		UpdateFormPathParams
	>({ url: '/forms/{formId}/', method: 'patch', ...variables, signal });

export const useUpdateForm = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormOut,
			UpdateFormError,
			UpdateFormVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.FormOut,
		UpdateFormError,
		UpdateFormVariables
	>({
		mutationFn: (variables: UpdateFormVariables) =>
			fetchUpdateForm({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type UpdateFormOrderPathParams = {
	formId: string;
};

export type UpdateFormOrderError = Fetcher.ErrorWrapper<undefined>;

export type UpdateFormOrderVariables = {
	body: Schemas.FormOrderIn;
	pathParams: UpdateFormOrderPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateFormOrder = (
	variables: UpdateFormOrderVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormOut,
		UpdateFormOrderError,
		Schemas.FormOrderIn,
		{},
		{},
		UpdateFormOrderPathParams
	>({ url: '/forms/{formId}/order/', method: 'post', ...variables, signal });

export const useUpdateFormOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormOut,
			UpdateFormOrderError,
			UpdateFormOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.FormOut,
		UpdateFormOrderError,
		UpdateFormOrderVariables
	>({
		mutationFn: (variables: UpdateFormOrderVariables) =>
			fetchUpdateFormOrder({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type CreateFormFieldPathParams = {
	formId: string;
};

export type CreateFormFieldError = Fetcher.ErrorWrapper<undefined>;

export type CreateFormFieldVariables = {
	body: Schemas.FormFieldLiteIn;
	pathParams: CreateFormFieldPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateFormField = (
	variables: CreateFormFieldVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormFieldOut,
		CreateFormFieldError,
		Schemas.FormFieldLiteIn,
		{},
		{},
		CreateFormFieldPathParams
	>({ url: '/forms/{formId}/fields/', method: 'post', ...variables, signal });

export const useCreateFormField = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormFieldOut,
			CreateFormFieldError,
			CreateFormFieldVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.FormFieldOut,
		CreateFormFieldError,
		CreateFormFieldVariables
	>({
		mutationFn: (variables: CreateFormFieldVariables) =>
			fetchCreateFormField({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type UpdateFormFieldPathParams = {
	formId: string;
	fieldId: string;
};

export type UpdateFormFieldError = Fetcher.ErrorWrapper<undefined>;

export type UpdateFormFieldVariables = {
	body: Schemas.FormFieldIn;
	pathParams: UpdateFormFieldPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateFormField = (
	variables: UpdateFormFieldVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormFieldOut,
		UpdateFormFieldError,
		Schemas.FormFieldIn,
		{},
		{},
		UpdateFormFieldPathParams
	>({
		url: '/forms/{formId}/fields/{fieldId}/',
		method: 'patch',
		...variables,
		signal,
	});

export const useUpdateFormField = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormFieldOut,
			UpdateFormFieldError,
			UpdateFormFieldVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.FormFieldOut,
		UpdateFormFieldError,
		UpdateFormFieldVariables
	>({
		mutationFn: (variables: UpdateFormFieldVariables) =>
			fetchUpdateFormField({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type DeleteFormFieldPathParams = {
	formId: string;
	fieldId: string;
};

export type DeleteFormFieldError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFormFieldVariables = {
	pathParams: DeleteFormFieldPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteFormField = (
	variables: DeleteFormFieldVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		DeleteFormFieldError,
		undefined,
		{},
		{},
		DeleteFormFieldPathParams
	>({
		url: '/forms/{formId}/fields/{fieldId}/',
		method: 'delete',
		...variables,
		signal,
	});

export const useDeleteFormField = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			DeleteFormFieldError,
			DeleteFormFieldVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		DeleteFormFieldError,
		DeleteFormFieldVariables
	>({
		mutationFn: (variables: DeleteFormFieldVariables) =>
			fetchDeleteFormField({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ListFormSubmissionsQueryParams = {
	form_id?: string | null;
	submitted?: boolean | null;
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ListFormSubmissionsError = Fetcher.ErrorWrapper<undefined>;

export type ListFormSubmissionsVariables = {
	queryParams?: ListFormSubmissionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchListFormSubmissions = (
	variables: ListFormSubmissionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedFormSubmissionOutLite,
		ListFormSubmissionsError,
		undefined,
		{},
		ListFormSubmissionsQueryParams,
		{}
	>({ url: '/submissions/', method: 'get', ...variables, signal });

export const useListFormSubmissions = <
	TData = Schemas.PagedFormSubmissionOutLite,
>(
	variables: ListFormSubmissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedFormSubmissionOutLite,
			ListFormSubmissionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedFormSubmissionOutLite,
		ListFormSubmissionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/submissions/',
			operationId: 'listFormSubmissions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchListFormSubmissions({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type CreateFormSubmissionError = Fetcher.ErrorWrapper<undefined>;

export type CreateFormSubmissionVariables = {
	body: Schemas.FormSubmissionIn;
} & ApiContext['fetcherOptions'];

export const fetchCreateFormSubmission = (
	variables: CreateFormSubmissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormSubmissionOut,
		CreateFormSubmissionError,
		Schemas.FormSubmissionIn,
		{},
		{},
		{}
	>({ url: '/submissions/', method: 'post', ...variables, signal });

export const useCreateFormSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormSubmissionOut,
			CreateFormSubmissionError,
			CreateFormSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.FormSubmissionOut,
		CreateFormSubmissionError,
		CreateFormSubmissionVariables
	>({
		mutationFn: (variables: CreateFormSubmissionVariables) =>
			fetchCreateFormSubmission({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ListDraftFormSubmissionQueryParams = {
	form_id?: string | null;
};

export type ListDraftFormSubmissionError = Fetcher.ErrorWrapper<undefined>;

export type ListDraftFormSubmissionVariables = {
	queryParams?: ListDraftFormSubmissionQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchListDraftFormSubmission = (
	variables: ListDraftFormSubmissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormSubmissionOut,
		ListDraftFormSubmissionError,
		undefined,
		{},
		ListDraftFormSubmissionQueryParams,
		{}
	>({ url: '/submissions/draft/', method: 'get', ...variables, signal });

export const useListDraftFormSubmission = <TData = Schemas.FormSubmissionOut,>(
	variables: ListDraftFormSubmissionVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.FormSubmissionOut,
			ListDraftFormSubmissionError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.FormSubmissionOut,
		ListDraftFormSubmissionError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/submissions/draft/',
			operationId: 'listDraftFormSubmission',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchListDraftFormSubmission({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type RetrieveFormSubmissionPathParams = {
	formSubmissionId: string;
};

export type RetrieveFormSubmissionQueryParams = {
	form_id?: string | null;
};

export type RetrieveFormSubmissionError = Fetcher.ErrorWrapper<undefined>;

export type RetrieveFormSubmissionVariables = {
	pathParams: RetrieveFormSubmissionPathParams;
	queryParams?: RetrieveFormSubmissionQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchRetrieveFormSubmission = (
	variables: RetrieveFormSubmissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormSubmissionOut,
		RetrieveFormSubmissionError,
		undefined,
		{},
		RetrieveFormSubmissionQueryParams,
		RetrieveFormSubmissionPathParams
	>({
		url: '/submissions/{formSubmissionId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useRetrieveFormSubmission = <TData = Schemas.FormSubmissionOut,>(
	variables: RetrieveFormSubmissionVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.FormSubmissionOut,
			RetrieveFormSubmissionError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.FormSubmissionOut,
		RetrieveFormSubmissionError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/submissions/{formSubmissionId}/',
			operationId: 'retrieveFormSubmission',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRetrieveFormSubmission({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type DeleteFormSubmissionPathParams = {
	formSubmissionId: string;
};

export type DeleteFormSubmissionError = Fetcher.ErrorWrapper<{
	status: 400;
	payload: {
		[key: string]: string;
	};
}>;

export type DeleteFormSubmissionVariables = {
	pathParams: DeleteFormSubmissionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteFormSubmission = (
	variables: DeleteFormSubmissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		DeleteFormSubmissionError,
		undefined,
		{},
		{},
		DeleteFormSubmissionPathParams
	>({
		url: '/submissions/{formSubmissionId}/',
		method: 'delete',
		...variables,
		signal,
	});

export const useDeleteFormSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			DeleteFormSubmissionError,
			DeleteFormSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		DeleteFormSubmissionError,
		DeleteFormSubmissionVariables
	>({
		mutationFn: (variables: DeleteFormSubmissionVariables) =>
			fetchDeleteFormSubmission({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApproveFormSubmissionPathParams = {
	formSubmissionId: string;
};

export type ApproveFormSubmissionError = Fetcher.ErrorWrapper<undefined>;

export type ApproveFormSubmissionVariables = {
	pathParams: ApproveFormSubmissionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApproveFormSubmission = (
	variables: ApproveFormSubmissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApproveFormSubmissionError,
		undefined,
		{},
		{},
		ApproveFormSubmissionPathParams
	>({
		url: '/submissions/{formSubmissionId}/approve/',
		method: 'post',
		...variables,
		signal,
	});

export const useApproveFormSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApproveFormSubmissionError,
			ApproveFormSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApproveFormSubmissionError,
		ApproveFormSubmissionVariables
	>({
		mutationFn: (variables: ApproveFormSubmissionVariables) =>
			fetchApproveFormSubmission({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type RejectFormSubmissionPathParams = {
	formSubmissionId: string;
};

export type RejectFormSubmissionError = Fetcher.ErrorWrapper<undefined>;

export type RejectFormSubmissionVariables = {
	body: Schemas.SubmissionRejectionIn;
	pathParams: RejectFormSubmissionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRejectFormSubmission = (
	variables: RejectFormSubmissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		RejectFormSubmissionError,
		Schemas.SubmissionRejectionIn,
		{},
		{},
		RejectFormSubmissionPathParams
	>({
		url: '/submissions/{formSubmissionId}/reject/',
		method: 'post',
		...variables,
		signal,
	});

export const useRejectFormSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			RejectFormSubmissionError,
			RejectFormSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		RejectFormSubmissionError,
		RejectFormSubmissionVariables
	>({
		mutationFn: (variables: RejectFormSubmissionVariables) =>
			fetchRejectFormSubmission({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type SubmitFormSubmissionPathParams = {
	formSubmissionId: string;
};

export type SubmitFormSubmissionError = Fetcher.ErrorWrapper<undefined>;

export type SubmitFormSubmissionVariables = {
	pathParams: SubmitFormSubmissionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSubmitFormSubmission = (
	variables: SubmitFormSubmissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormSubmissionOut,
		SubmitFormSubmissionError,
		undefined,
		{},
		{},
		SubmitFormSubmissionPathParams
	>({
		url: '/submissions/{formSubmissionId}/submit/',
		method: 'post',
		...variables,
		signal,
	});

export const useSubmitFormSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormSubmissionOut,
			SubmitFormSubmissionError,
			SubmitFormSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.FormSubmissionOut,
		SubmitFormSubmissionError,
		SubmitFormSubmissionVariables
	>({
		mutationFn: (variables: SubmitFormSubmissionVariables) =>
			fetchSubmitFormSubmission({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type UpdateFormSubmissionValuesPathParams = {
	formSubmissionId: string;
};

export type UpdateFormSubmissionValuesError = Fetcher.ErrorWrapper<{
	status: 400;
	payload: {
		[key: string]: string;
	};
}>;

export type UpdateFormSubmissionValuesVariables = {
	body: Schemas.FormSubmissionValueIn;
	pathParams: UpdateFormSubmissionValuesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateFormSubmissionValues = (
	variables: UpdateFormSubmissionValuesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		UpdateFormSubmissionValuesError,
		Schemas.FormSubmissionValueIn,
		{},
		{},
		UpdateFormSubmissionValuesPathParams
	>({
		url: '/submissions/{formSubmissionId}/values/',
		method: 'put',
		...variables,
		signal,
	});

export const useUpdateFormSubmissionValues = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			UpdateFormSubmissionValuesError,
			UpdateFormSubmissionValuesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		UpdateFormSubmissionValuesError,
		UpdateFormSubmissionValuesVariables
	>({
		mutationFn: (variables: UpdateFormSubmissionValuesVariables) =>
			fetchUpdateFormSubmissionValues({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ReviseFormSubmissionPathParams = {
	formSubmissionId: string;
};

export type ReviseFormSubmissionError = Fetcher.ErrorWrapper<{
	status: 400;
	payload: {
		[key: string]: string;
	};
}>;

export type ReviseFormSubmissionVariables = {
	pathParams: ReviseFormSubmissionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReviseFormSubmission = (
	variables: ReviseFormSubmissionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.FormSubmissionOut,
		ReviseFormSubmissionError,
		undefined,
		{},
		{},
		ReviseFormSubmissionPathParams
	>({
		url: '/submissions/{formSubmissionId}/revise/',
		method: 'post',
		...variables,
		signal,
	});

export const useReviseFormSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.FormSubmissionOut,
			ReviseFormSubmissionError,
			ReviseFormSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.FormSubmissionOut,
		ReviseFormSubmissionError,
		ReviseFormSubmissionVariables
	>({
		mutationFn: (variables: ReviseFormSubmissionVariables) =>
			fetchReviseFormSubmission({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetColumnTypesError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetColumnTypesResponse = string[];

export type ApiGetColumnTypesVariables = ApiContext['fetcherOptions'];

export const fetchApiGetColumnTypes = (
	variables: ApiGetColumnTypesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetColumnTypesResponse,
		ApiGetColumnTypesError,
		undefined,
		{},
		{},
		{}
	>({ url: '/table/columns_v2/types/', method: 'get', ...variables, signal });

export const useApiGetColumnTypes = <TData = ApiGetColumnTypesResponse,>(
	variables: ApiGetColumnTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetColumnTypesResponse,
			ApiGetColumnTypesError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetColumnTypesResponse,
		ApiGetColumnTypesError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/table/columns_v2/types/',
			operationId: 'apiGetColumnTypes',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetColumnTypes({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetPiiWordListError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetPiiWordListVariables = ApiContext['fetcherOptions'];

export const fetchApiGetPiiWordList = (
	variables: ApiGetPiiWordListVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PiiWordListResponse,
		ApiGetPiiWordListError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/table/columns_v2/pii_word_list/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetPiiWordList = <TData = Schemas.PiiWordListResponse,>(
	variables: ApiGetPiiWordListVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PiiWordListResponse,
			ApiGetPiiWordListError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PiiWordListResponse,
		ApiGetPiiWordListError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/table/columns_v2/pii_word_list/',
			operationId: 'apiGetPiiWordList',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetPiiWordList({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiUpdatePiiWordListError = Fetcher.ErrorWrapper<undefined>;

export type ApiUpdatePiiWordListVariables = {
	body: Schemas.PiiWordListRequest;
} & ApiContext['fetcherOptions'];

export const fetchApiUpdatePiiWordList = (
	variables: ApiUpdatePiiWordListVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PiiWordListResponse,
		ApiUpdatePiiWordListError,
		Schemas.PiiWordListRequest,
		{},
		{},
		{}
	>({
		url: '/table/columns_v2/pii_word_list/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiUpdatePiiWordList = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PiiWordListResponse,
			ApiUpdatePiiWordListError,
			ApiUpdatePiiWordListVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.PiiWordListResponse,
		ApiUpdatePiiWordListError,
		ApiUpdatePiiWordListVariables
	>({
		mutationFn: (variables: ApiUpdatePiiWordListVariables) =>
			fetchApiUpdatePiiWordList({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetPiiColumnsError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetPiiColumnsResponse = Schemas.PiiColumn[];

export type ApiGetPiiColumnsVariables = ApiContext['fetcherOptions'];

export const fetchApiGetPiiColumns = (
	variables: ApiGetPiiColumnsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetPiiColumnsResponse,
		ApiGetPiiColumnsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/table/columns_v2/pii/', method: 'get', ...variables, signal });

export const useApiGetPiiColumns = <TData = ApiGetPiiColumnsResponse,>(
	variables: ApiGetPiiColumnsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetPiiColumnsResponse,
			ApiGetPiiColumnsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetPiiColumnsResponse,
		ApiGetPiiColumnsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/table/columns_v2/pii/',
			operationId: 'apiGetPiiColumns',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetPiiColumns({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiColumnUpdatePiiQueryParams = {
	column_id: string;
};

export type ApiColumnUpdatePiiError = Fetcher.ErrorWrapper<undefined>;

export type ApiColumnUpdatePiiVariables = {
	queryParams: ApiColumnUpdatePiiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiColumnUpdatePii = (
	variables: ApiColumnUpdatePiiVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiColumnUpdatePiiError,
		undefined,
		{},
		ApiColumnUpdatePiiQueryParams,
		{}
	>({
		url: '/table/columns_v2/update_pii/',
		method: 'patch',
		...variables,
		signal,
	});

export const useApiColumnUpdatePii = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiColumnUpdatePiiError,
			ApiColumnUpdatePiiVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiColumnUpdatePiiError,
		ApiColumnUpdatePiiVariables
	>({
		mutationFn: (variables: ApiColumnUpdatePiiVariables) =>
			fetchApiColumnUpdatePii({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGithubWebhookError = Fetcher.ErrorWrapper<{
	status: 403;
	payload: string;
}>;

export type ApiGithubWebhookVariables = {
	body: Schemas.GithubWebhookIn;
} & ApiContext['fetcherOptions'];

/**
 * Handle a GitHub webhook request.
 */
export const fetchApiGithubWebhook = (
	variables: ApiGithubWebhookVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiGithubWebhookError,
		Schemas.GithubWebhookIn,
		{},
		{},
		{}
	>({
		url: '/integration/github/webhook/',
		method: 'post',
		...variables,
		signal,
	});

/**
 * Handle a GitHub webhook request.
 */
export const useApiGithubWebhook = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiGithubWebhookError,
			ApiGithubWebhookVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiGithubWebhookError,
		ApiGithubWebhookVariables
	>({
		mutationFn: (variables: ApiGithubWebhookVariables) =>
			fetchApiGithubWebhook({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetIntegrationGroupItemsPathParams = {
	integrationId: string;
};

export type ApiGetIntegrationGroupItemsError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetIntegrationGroupItemsResponse = Schemas.GroupItem[];

export type ApiGetIntegrationGroupItemsVariables = {
	pathParams: ApiGetIntegrationGroupItemsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetIntegrationGroupItems = (
	variables: ApiGetIntegrationGroupItemsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetIntegrationGroupItemsResponse,
		ApiGetIntegrationGroupItemsError,
		undefined,
		{},
		{},
		ApiGetIntegrationGroupItemsPathParams
	>({
		url: '/integration/integrations/{integrationId}/group_items/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetIntegrationGroupItems = <
	TData = ApiGetIntegrationGroupItemsResponse,
>(
	variables: ApiGetIntegrationGroupItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetIntegrationGroupItemsResponse,
			ApiGetIntegrationGroupItemsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetIntegrationGroupItemsResponse,
		ApiGetIntegrationGroupItemsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/integrations/{integrationId}/group_items/',
			operationId: 'apiGetIntegrationGroupItems',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetIntegrationGroupItems(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiGroupItemsRefreshSupportQueryParams = {
	integration_type: string;
};

export type ApiGroupItemsRefreshSupportError = Fetcher.ErrorWrapper<undefined>;

export type ApiGroupItemsRefreshSupportVariables = {
	queryParams: ApiGroupItemsRefreshSupportQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGroupItemsRefreshSupport = (
	variables: ApiGroupItemsRefreshSupportVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		boolean,
		ApiGroupItemsRefreshSupportError,
		undefined,
		{},
		ApiGroupItemsRefreshSupportQueryParams,
		{}
	>({
		url: '/integration/integrations/group_items/refresh_support/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGroupItemsRefreshSupport = <TData = boolean,>(
	variables: ApiGroupItemsRefreshSupportVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			boolean,
			ApiGroupItemsRefreshSupportError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<boolean, ApiGroupItemsRefreshSupportError, TData>({
		queryKey: queryKeyFn({
			path: '/integration/integrations/group_items/refresh_support/',
			operationId: 'apiGroupItemsRefreshSupport',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGroupItemsRefreshSupport(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiRefreshIntegrationGroupItemsPathParams = {
	integrationId: string;
};

export type ApiRefreshIntegrationGroupItemsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiRefreshIntegrationGroupItemsVariables = {
	pathParams: ApiRefreshIntegrationGroupItemsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiRefreshIntegrationGroupItems = (
	variables: ApiRefreshIntegrationGroupItemsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiRefreshIntegrationGroupItemsError,
		undefined,
		{},
		{},
		ApiRefreshIntegrationGroupItemsPathParams
	>({
		url: '/integration/integrations/{integrationId}/group_items/refresh/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiRefreshIntegrationGroupItems = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiRefreshIntegrationGroupItemsError,
			ApiRefreshIntegrationGroupItemsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiRefreshIntegrationGroupItemsError,
		ApiRefreshIntegrationGroupItemsVariables
	>({
		mutationFn: (variables: ApiRefreshIntegrationGroupItemsVariables) =>
			fetchApiRefreshIntegrationGroupItems({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetIntegrationGroupSettingsPathParams = {
	integrationId: string;
};

export type ApiGetIntegrationGroupSettingsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiGetIntegrationGroupSettingsResponse = {
	[key: string]: Schemas.GroupSetting;
};

export type ApiGetIntegrationGroupSettingsVariables = {
	pathParams: ApiGetIntegrationGroupSettingsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetIntegrationGroupSettings = (
	variables: ApiGetIntegrationGroupSettingsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetIntegrationGroupSettingsResponse,
		ApiGetIntegrationGroupSettingsError,
		undefined,
		{},
		{},
		ApiGetIntegrationGroupSettingsPathParams
	>({
		url: '/integration/integrations/{integrationId}/group_settings/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetIntegrationGroupSettings = <
	TData = ApiGetIntegrationGroupSettingsResponse,
>(
	variables: ApiGetIntegrationGroupSettingsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetIntegrationGroupSettingsResponse,
			ApiGetIntegrationGroupSettingsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetIntegrationGroupSettingsResponse,
		ApiGetIntegrationGroupSettingsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/integrations/{integrationId}/group_settings/',
			operationId: 'apiGetIntegrationGroupSettings',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetIntegrationGroupSettings(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiUpdateIntegrationGroupSettingsPathParams = {
	integrationId: string;
};

export type ApiUpdateIntegrationGroupSettingsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiUpdateIntegrationGroupSettingsResponse = {
	[key: string]: Schemas.GroupSetting;
};

export type ApiUpdateIntegrationGroupSettingsVariables = {
	body: Schemas.UpdateGroupSettingsRequest;
	pathParams: ApiUpdateIntegrationGroupSettingsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiUpdateIntegrationGroupSettings = (
	variables: ApiUpdateIntegrationGroupSettingsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiUpdateIntegrationGroupSettingsResponse,
		ApiUpdateIntegrationGroupSettingsError,
		Schemas.UpdateGroupSettingsRequest,
		{},
		{},
		ApiUpdateIntegrationGroupSettingsPathParams
	>({
		url: '/integration/integrations/{integrationId}/group_settings/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiUpdateIntegrationGroupSettings = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			ApiUpdateIntegrationGroupSettingsResponse,
			ApiUpdateIntegrationGroupSettingsError,
			ApiUpdateIntegrationGroupSettingsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		ApiUpdateIntegrationGroupSettingsResponse,
		ApiUpdateIntegrationGroupSettingsError,
		ApiUpdateIntegrationGroupSettingsVariables
	>({
		mutationFn: (variables: ApiUpdateIntegrationGroupSettingsVariables) =>
			fetchApiUpdateIntegrationGroupSettings({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type GetIntegrationExcludedTitlesPathParams = {
	integrationId: string;
};

export type GetIntegrationExcludedTitlesError = Fetcher.ErrorWrapper<undefined>;

export type GetIntegrationExcludedTitlesResponse = string[];

export type GetIntegrationExcludedTitlesVariables = {
	pathParams: GetIntegrationExcludedTitlesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetIntegrationExcludedTitles = (
	variables: GetIntegrationExcludedTitlesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetIntegrationExcludedTitlesResponse,
		GetIntegrationExcludedTitlesError,
		undefined,
		{},
		{},
		GetIntegrationExcludedTitlesPathParams
	>({
		url: '/integration/integrations/{integrationId}/excluded-titles/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetIntegrationExcludedTitles = <
	TData = GetIntegrationExcludedTitlesResponse,
>(
	variables: GetIntegrationExcludedTitlesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetIntegrationExcludedTitlesResponse,
			GetIntegrationExcludedTitlesError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetIntegrationExcludedTitlesResponse,
		GetIntegrationExcludedTitlesError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/integrations/{integrationId}/excluded-titles/',
			operationId: 'getIntegrationExcludedTitles',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetIntegrationExcludedTitles(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type SetExcludedExcludedTitlesPathParams = {
	integrationId: string;
};

export type SetExcludedExcludedTitlesError = Fetcher.ErrorWrapper<undefined>;

export type SetExcludedExcludedTitlesRequestBody = string[];

export type SetExcludedExcludedTitlesVariables = {
	body?: SetExcludedExcludedTitlesRequestBody;
	pathParams: SetExcludedExcludedTitlesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSetExcludedExcludedTitles = (
	variables: SetExcludedExcludedTitlesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		SetExcludedExcludedTitlesError,
		SetExcludedExcludedTitlesRequestBody,
		{},
		{},
		SetExcludedExcludedTitlesPathParams
	>({
		url: '/integration/integrations/{integrationId}/excluded-titles/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetExcludedExcludedTitles = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			SetExcludedExcludedTitlesError,
			SetExcludedExcludedTitlesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		SetExcludedExcludedTitlesError,
		SetExcludedExcludedTitlesVariables
	>({
		mutationFn: (variables: SetExcludedExcludedTitlesVariables) =>
			fetchSetExcludedExcludedTitles({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiPostIntegrationRunLogsPathParams = {
	integrationId: string;
};

export type ApiPostIntegrationRunLogsError = Fetcher.ErrorWrapper<{
	status: 403;
	payload: string;
}>;

export type ApiPostIntegrationRunLogsVariables = {
	body?: Record<string, any>;
	pathParams: ApiPostIntegrationRunLogsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiPostIntegrationRunLogs = (
	variables: ApiPostIntegrationRunLogsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiPostIntegrationRunLogsError,
		Record<string, any>,
		{},
		{},
		ApiPostIntegrationRunLogsPathParams
	>({
		url: '/integration/run_logs/{integrationId}/ingest/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiPostIntegrationRunLogs = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiPostIntegrationRunLogsError,
			ApiPostIntegrationRunLogsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiPostIntegrationRunLogsError,
		ApiPostIntegrationRunLogsVariables
	>({
		mutationFn: (variables: ApiPostIntegrationRunLogsVariables) =>
			fetchApiPostIntegrationRunLogs({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiUploadDbtArtifactsPathParams = {
	integrationId: string;
};

export type ApiUploadDbtArtifactsError = Fetcher.ErrorWrapper<undefined>;

export type ApiUploadDbtArtifactsRequestBody = {
	/**
	 * @format binary
	 */
	run_results: Blob;
	/**
	 * @format binary
	 */
	manifest: Blob;
};

export type ApiUploadDbtArtifactsVariables = {
	body: ApiUploadDbtArtifactsRequestBody;
	pathParams: ApiUploadDbtArtifactsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiUploadDbtArtifacts = (
	variables: ApiUploadDbtArtifactsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiUploadDbtArtifactsError,
		ApiUploadDbtArtifactsRequestBody,
		{},
		{},
		ApiUploadDbtArtifactsPathParams
	>({
		url: '/integration/dbt/{integrationId}/upload_artifacts/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiUploadDbtArtifacts = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiUploadDbtArtifactsError,
			ApiUploadDbtArtifactsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiUploadDbtArtifactsError,
		ApiUploadDbtArtifactsVariables
	>({
		mutationFn: (variables: ApiUploadDbtArtifactsVariables) =>
			fetchApiUploadDbtArtifacts({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiTriggerDbtRunPathParams = {
	integrationId: string;
};

export type ApiTriggerDbtRunError = Fetcher.ErrorWrapper<undefined>;

export type ApiTriggerDbtRunVariables = {
	pathParams: ApiTriggerDbtRunPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiTriggerDbtRun = (
	variables: ApiTriggerDbtRunVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiTriggerDbtRunError,
		undefined,
		{},
		{},
		ApiTriggerDbtRunPathParams
	>({
		url: '/integration/dbt/{integrationId}/trigger/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiTriggerDbtRun = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiTriggerDbtRunError,
			ApiTriggerDbtRunVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiTriggerDbtRunError,
		ApiTriggerDbtRunVariables
	>({
		mutationFn: (variables: ApiTriggerDbtRunVariables) =>
			fetchApiTriggerDbtRun({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetFailingTestsPathParams = {
	integrationId: string;
};

export type ApiGetFailingTestsError = Fetcher.ErrorWrapper<{
	status: 400;
	payload: string;
}>;

export type ApiGetFailingTestsVariables = {
	pathParams: ApiGetFailingTestsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetFailingTests = (
	variables: ApiGetFailingTestsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.DBTTestResultsOut,
		ApiGetFailingTestsError,
		undefined,
		{},
		{},
		ApiGetFailingTestsPathParams
	>({
		url: '/integration/dbt/{integrationId}/failing_tests/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetFailingTests = <TData = Schemas.DBTTestResultsOut,>(
	variables: ApiGetFailingTestsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DBTTestResultsOut,
			ApiGetFailingTestsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.DBTTestResultsOut,
		ApiGetFailingTestsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/dbt/{integrationId}/failing_tests/',
			operationId: 'apiGetFailingTests',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetFailingTests({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiCreateLineageError = Fetcher.ErrorWrapper<undefined>;

export type ApiCreateLineageVariables = {
	body: Schemas.LineageIn;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateLineage = (
	variables: ApiCreateLineageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.LineageOut,
		ApiCreateLineageError,
		Schemas.LineageIn,
		{},
		{},
		{}
	>({ url: '/lineage/', method: 'post', ...variables, signal });

export const useApiCreateLineage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.LineageOut,
			ApiCreateLineageError,
			ApiCreateLineageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.LineageOut,
		ApiCreateLineageError,
		ApiCreateLineageVariables
	>({
		mutationFn: (variables: ApiCreateLineageVariables) =>
			fetchApiCreateLineage({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiListLineageQueryParams = {
	id: string;
	direction: 'UPSTREAM' | 'DOWNSTREAM';
	search_term?: string | null;
	/**
	 * @default 1
	 */
	depth?: number | null;
	entity_type?: string | null;
	include_columns?: string | null;
	integration_types?: string | null;
	native_types?: string | null;
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ApiListLineageError = Fetcher.ErrorWrapper<undefined>;

export type ApiListLineageVariables = {
	queryParams: ApiListLineageQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListLineage = (
	variables: ApiListLineageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PaginatedLineageListOut,
		ApiListLineageError,
		undefined,
		{},
		ApiListLineageQueryParams,
		{}
	>({ url: '/lineage/', method: 'get', ...variables, signal });

export const useApiListLineage = <TData = Schemas.PaginatedLineageListOut,>(
	variables: ApiListLineageVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PaginatedLineageListOut,
			ApiListLineageError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PaginatedLineageListOut,
		ApiListLineageError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/',
			operationId: 'apiListLineage',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiListLineage({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiListColumnsByTableIdsQueryParams = {
	/**
	 * @default 1
	 */
	page?: number;
};

export type ApiListColumnsByTableIdsError = Fetcher.ErrorWrapper<undefined>;

export type ApiListColumnsByTableIdsVariables = {
	body: Schemas.TableIdsIn;
	queryParams?: ApiListColumnsByTableIdsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListColumnsByTableIds = (
	variables: ApiListColumnsByTableIdsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedColumnsOut,
		ApiListColumnsByTableIdsError,
		Schemas.TableIdsIn,
		{},
		ApiListColumnsByTableIdsQueryParams,
		{}
	>({ url: '/lineage/table-columns/', method: 'post', ...variables, signal });

export const useApiListColumnsByTableIds = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PagedColumnsOut,
			ApiListColumnsByTableIdsError,
			ApiListColumnsByTableIdsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.PagedColumnsOut,
		ApiListColumnsByTableIdsError,
		ApiListColumnsByTableIdsVariables
	>({
		mutationFn: (variables: ApiListColumnsByTableIdsVariables) =>
			fetchApiListColumnsByTableIds({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiListTestsByTableIdsQueryParams = {
	/**
	 * @default 1
	 */
	page?: number;
};

export type ApiListTestsByTableIdsError = Fetcher.ErrorWrapper<undefined>;

export type ApiListTestsByTableIdsVariables = {
	body: Schemas.TableIdsIn;
	queryParams?: ApiListTestsByTableIdsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListTestsByTableIds = (
	variables: ApiListTestsByTableIdsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedTestsOut,
		ApiListTestsByTableIdsError,
		Schemas.TableIdsIn,
		{},
		ApiListTestsByTableIdsQueryParams,
		{}
	>({ url: '/lineage/table-tests/', method: 'post', ...variables, signal });

export const useApiListTestsByTableIds = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PagedTestsOut,
			ApiListTestsByTableIdsError,
			ApiListTestsByTableIdsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.PagedTestsOut,
		ApiListTestsByTableIdsError,
		ApiListTestsByTableIdsVariables
	>({
		mutationFn: (variables: ApiListTestsByTableIdsVariables) =>
			fetchApiListTestsByTableIds({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiListTableCreationQueriesByDatabuilderIdsQueryParams = {
	/**
	 * @default 1
	 */
	page?: number;
};

export type ApiListTableCreationQueriesByDatabuilderIdsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiListTableCreationQueriesByDatabuilderIdsVariables = {
	body: Schemas.TableIdsIn;
	queryParams?: ApiListTableCreationQueriesByDatabuilderIdsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListTableCreationQueriesByDatabuilderIds = (
	variables: ApiListTableCreationQueriesByDatabuilderIdsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedQueriesOut,
		ApiListTableCreationQueriesByDatabuilderIdsError,
		Schemas.TableIdsIn,
		{},
		ApiListTableCreationQueriesByDatabuilderIdsQueryParams,
		{}
	>({
		url: '/lineage/table-creation-queries/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiListTableCreationQueriesByDatabuilderIds = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PagedQueriesOut,
			ApiListTableCreationQueriesByDatabuilderIdsError,
			ApiListTableCreationQueriesByDatabuilderIdsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.PagedQueriesOut,
		ApiListTableCreationQueriesByDatabuilderIdsError,
		ApiListTableCreationQueriesByDatabuilderIdsVariables
	>({
		mutationFn: (
			variables: ApiListTableCreationQueriesByDatabuilderIdsVariables
		) =>
			fetchApiListTableCreationQueriesByDatabuilderIds({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiRetrieveImpactedIdsPathParams = {
	fromId: string;
};

export type ApiRetrieveImpactedIdsError = Fetcher.ErrorWrapper<undefined>;

export type ApiRetrieveImpactedIdsVariables = {
	pathParams: ApiRetrieveImpactedIdsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiRetrieveImpactedIds = (
	variables: ApiRetrieveImpactedIdsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.ImpactedIdsOut,
		ApiRetrieveImpactedIdsError,
		undefined,
		{},
		{},
		ApiRetrieveImpactedIdsPathParams
	>({
		url: '/lineage/impacted/{fromId}/ids/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiRetrieveImpactedIds = <TData = Schemas.ImpactedIdsOut,>(
	variables: ApiRetrieveImpactedIdsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ImpactedIdsOut,
			ApiRetrieveImpactedIdsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.ImpactedIdsOut,
		ApiRetrieveImpactedIdsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/impacted/{fromId}/ids/',
			operationId: 'apiRetrieveImpactedIds',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiRetrieveImpactedIds({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiRetrieveImpactedPathsPathParams = {
	fromId: string;
};

export type ApiRetrieveImpactedPathsQueryParams = {
	/**
	 * @default DOWNSTREAM
	 */
	direction?: Schemas.LineageDirection | null;
};

export type ApiRetrieveImpactedPathsError = Fetcher.ErrorWrapper<undefined>;

export type ApiRetrieveImpactedPathsResponse = {
	[key: string]: Schemas.ImpactedPathsOut;
};

export type ApiRetrieveImpactedPathsVariables = {
	pathParams: ApiRetrieveImpactedPathsPathParams;
	queryParams?: ApiRetrieveImpactedPathsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiRetrieveImpactedPaths = (
	variables: ApiRetrieveImpactedPathsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiRetrieveImpactedPathsResponse,
		ApiRetrieveImpactedPathsError,
		undefined,
		{},
		ApiRetrieveImpactedPathsQueryParams,
		ApiRetrieveImpactedPathsPathParams
	>({
		url: '/lineage/impacted/{fromId}/paths/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiRetrieveImpactedPaths = <
	TData = ApiRetrieveImpactedPathsResponse,
>(
	variables: ApiRetrieveImpactedPathsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiRetrieveImpactedPathsResponse,
			ApiRetrieveImpactedPathsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiRetrieveImpactedPathsResponse,
		ApiRetrieveImpactedPathsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/impacted/{fromId}/paths/',
			operationId: 'apiRetrieveImpactedPaths',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiRetrieveImpactedPaths(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiExportImpactedCsvPathParams = {
	fromId: string;
};

export type ApiExportImpactedCsvError = Fetcher.ErrorWrapper<undefined>;

export type ApiExportImpactedCsvVariables = {
	pathParams: ApiExportImpactedCsvPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiExportImpactedCsv = (
	variables: ApiExportImpactedCsvVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.ImpactedCSVBackgroundJobOut,
		ApiExportImpactedCsvError,
		undefined,
		{},
		{},
		ApiExportImpactedCsvPathParams
	>({
		url: '/lineage/impacted/{fromId}/csv/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiExportImpactedCsv = <
	TData = Schemas.ImpactedCSVBackgroundJobOut,
>(
	variables: ApiExportImpactedCsvVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ImpactedCSVBackgroundJobOut,
			ApiExportImpactedCsvError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.ImpactedCSVBackgroundJobOut,
		ApiExportImpactedCsvError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/impacted/{fromId}/csv/',
			operationId: 'apiExportImpactedCsv',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiExportImpactedCsv({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiCreateResourceRelationError = Fetcher.ErrorWrapper<undefined>;

export type ApiCreateResourceRelationVariables = {
	body: Schemas.ResourceRelationIn;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateResourceRelation = (
	variables: ApiCreateResourceRelationVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.ResourceRelationOut,
		ApiCreateResourceRelationError,
		Schemas.ResourceRelationIn,
		{},
		{},
		{}
	>({ url: '/lineage/relations/', method: 'post', ...variables, signal });

export const useApiCreateResourceRelation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.ResourceRelationOut,
			ApiCreateResourceRelationError,
			ApiCreateResourceRelationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.ResourceRelationOut,
		ApiCreateResourceRelationError,
		ApiCreateResourceRelationVariables
	>({
		mutationFn: (variables: ApiCreateResourceRelationVariables) =>
			fetchApiCreateResourceRelation({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiListResourceRelationsQueryParams = {
	resource_id: string;
	/**
	 * @default 1
	 */
	page?: number;
};

export type ApiListResourceRelationsError = Fetcher.ErrorWrapper<undefined>;

export type ApiListResourceRelationsVariables = {
	queryParams: ApiListResourceRelationsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListResourceRelations = (
	variables: ApiListResourceRelationsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedResourceRelationOut,
		ApiListResourceRelationsError,
		undefined,
		{},
		ApiListResourceRelationsQueryParams,
		{}
	>({ url: '/lineage/relations/', method: 'get', ...variables, signal });

export const useApiListResourceRelations = <
	TData = Schemas.PagedResourceRelationOut,
>(
	variables: ApiListResourceRelationsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedResourceRelationOut,
			ApiListResourceRelationsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedResourceRelationOut,
		ApiListResourceRelationsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/relations/',
			operationId: 'apiListResourceRelations',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiListResourceRelations(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiDeleteResourceRelationError = Fetcher.ErrorWrapper<undefined>;

export type ApiDeleteResourceRelationVariables = {
	body: Schemas.ResourceRelationIn;
} & ApiContext['fetcherOptions'];

export const fetchApiDeleteResourceRelation = (
	variables: ApiDeleteResourceRelationVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiDeleteResourceRelationError,
		Schemas.ResourceRelationIn,
		{},
		{},
		{}
	>({ url: '/lineage/relations/', method: 'delete', ...variables, signal });

export const useApiDeleteResourceRelation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiDeleteResourceRelationError,
			ApiDeleteResourceRelationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiDeleteResourceRelationError,
		ApiDeleteResourceRelationVariables
	>({
		mutationFn: (variables: ApiDeleteResourceRelationVariables) =>
			fetchApiDeleteResourceRelation({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiRetrieveResourceRelationPathParams = {
	relationId: string;
};

export type ApiRetrieveResourceRelationError = Fetcher.ErrorWrapper<undefined>;

export type ApiRetrieveResourceRelationVariables = {
	pathParams: ApiRetrieveResourceRelationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiRetrieveResourceRelation = (
	variables: ApiRetrieveResourceRelationVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.ResourceRelationOut,
		ApiRetrieveResourceRelationError,
		undefined,
		{},
		{},
		ApiRetrieveResourceRelationPathParams
	>({
		url: '/lineage/relations/{relationId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiRetrieveResourceRelation = <
	TData = Schemas.ResourceRelationOut,
>(
	variables: ApiRetrieveResourceRelationVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ResourceRelationOut,
			ApiRetrieveResourceRelationError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.ResourceRelationOut,
		ApiRetrieveResourceRelationError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/relations/{relationId}/',
			operationId: 'apiRetrieveResourceRelation',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiRetrieveResourceRelation(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiCreateManualLineageError = Fetcher.ErrorWrapper<undefined>;

export type ApiCreateManualLineageVariables = {
	body: Schemas.LineageIn;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateManualLineage = (
	variables: ApiCreateManualLineageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.LineageOut,
		ApiCreateManualLineageError,
		Schemas.LineageIn,
		{},
		{},
		{}
	>({ url: '/lineage/manual/', method: 'post', ...variables, signal });

export const useApiCreateManualLineage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.LineageOut,
			ApiCreateManualLineageError,
			ApiCreateManualLineageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.LineageOut,
		ApiCreateManualLineageError,
		ApiCreateManualLineageVariables
	>({
		mutationFn: (variables: ApiCreateManualLineageVariables) =>
			fetchApiCreateManualLineage({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiListManualLineagesQueryParams = {
	from_entity?: string | null;
	from_entity_id?: string | null;
	from_entity__in?: string | null;
	to_entity?: string | null;
	to_entity_id?: string | null;
	to_entity__in?: string | null;
	integration_type?: string | null;
	integration__type?: string | null;
	/**
	 * @default 1
	 */
	page?: number;
};

export type ApiListManualLineagesError = Fetcher.ErrorWrapper<undefined>;

export type ApiListManualLineagesVariables = {
	queryParams?: ApiListManualLineagesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListManualLineages = (
	variables: ApiListManualLineagesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedLineageOut,
		ApiListManualLineagesError,
		undefined,
		{},
		ApiListManualLineagesQueryParams,
		{}
	>({ url: '/lineage/manual/', method: 'get', ...variables, signal });

export const useApiListManualLineages = <TData = Schemas.PagedLineageOut,>(
	variables: ApiListManualLineagesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedLineageOut,
			ApiListManualLineagesError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedLineageOut,
		ApiListManualLineagesError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/manual/',
			operationId: 'apiListManualLineages',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiListManualLineages({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiRetrieveManualLineagePathParams = {
	lineageId: string;
};

export type ApiRetrieveManualLineageError = Fetcher.ErrorWrapper<undefined>;

export type ApiRetrieveManualLineageVariables = {
	pathParams: ApiRetrieveManualLineagePathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiRetrieveManualLineage = (
	variables: ApiRetrieveManualLineageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.LineageOut,
		ApiRetrieveManualLineageError,
		undefined,
		{},
		{},
		ApiRetrieveManualLineagePathParams
	>({
		url: '/lineage/manual/{lineageId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiRetrieveManualLineage = <TData = Schemas.LineageOut,>(
	variables: ApiRetrieveManualLineageVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LineageOut,
			ApiRetrieveManualLineageError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.LineageOut,
		ApiRetrieveManualLineageError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/manual/{lineageId}/',
			operationId: 'apiRetrieveManualLineage',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiRetrieveManualLineage(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiDeleteManualLineagePathParams = {
	lineageId: string;
};

export type ApiDeleteManualLineageError = Fetcher.ErrorWrapper<undefined>;

export type ApiDeleteManualLineageVariables = {
	pathParams: ApiDeleteManualLineagePathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiDeleteManualLineage = (
	variables: ApiDeleteManualLineageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiDeleteManualLineageError,
		undefined,
		{},
		{},
		ApiDeleteManualLineagePathParams
	>({
		url: '/lineage/manual/{lineageId}/',
		method: 'delete',
		...variables,
		signal,
	});

export const useApiDeleteManualLineage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiDeleteManualLineageError,
			ApiDeleteManualLineageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiDeleteManualLineageError,
		ApiDeleteManualLineageVariables
	>({
		mutationFn: (variables: ApiDeleteManualLineageVariables) =>
			fetchApiDeleteManualLineage({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiRetrieveLineagePathParams = {
	lineageId: string;
};

export type ApiRetrieveLineageError = Fetcher.ErrorWrapper<undefined>;

export type ApiRetrieveLineageVariables = {
	pathParams: ApiRetrieveLineagePathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiRetrieveLineage = (
	variables: ApiRetrieveLineageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.LineageOut,
		ApiRetrieveLineageError,
		undefined,
		{},
		{},
		ApiRetrieveLineagePathParams
	>({ url: '/lineage/{lineageId}/', method: 'get', ...variables, signal });

export const useApiRetrieveLineage = <TData = Schemas.LineageOut,>(
	variables: ApiRetrieveLineageVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LineageOut,
			ApiRetrieveLineageError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.LineageOut,
		ApiRetrieveLineageError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/lineage/{lineageId}/',
			operationId: 'apiRetrieveLineage',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiRetrieveLineage({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiDeleteLineagePathParams = {
	lineageId: string;
};

export type ApiDeleteLineageError = Fetcher.ErrorWrapper<undefined>;

export type ApiDeleteLineageVariables = {
	pathParams: ApiDeleteLineagePathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiDeleteLineage = (
	variables: ApiDeleteLineageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiDeleteLineageError,
		undefined,
		{},
		{},
		ApiDeleteLineagePathParams
	>({ url: '/lineage/{lineageId}/', method: 'delete', ...variables, signal });

export const useApiDeleteLineage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiDeleteLineageError,
			ApiDeleteLineageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiDeleteLineageError,
		ApiDeleteLineageVariables
	>({
		mutationFn: (variables: ApiDeleteLineageVariables) =>
			fetchApiDeleteLineage({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiCreateAnnouncementError = Fetcher.ErrorWrapper<undefined>;

export type ApiCreateAnnouncementVariables = {
	body: Schemas.AnnouncementIn;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateAnnouncement = (
	variables: ApiCreateAnnouncementVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.AnnouncementOut,
		ApiCreateAnnouncementError,
		Schemas.AnnouncementIn,
		{},
		{},
		{}
	>({
		url: '/announcement/announcements/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiCreateAnnouncement = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.AnnouncementOut,
			ApiCreateAnnouncementError,
			ApiCreateAnnouncementVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.AnnouncementOut,
		ApiCreateAnnouncementError,
		ApiCreateAnnouncementVariables
	>({
		mutationFn: (variables: ApiCreateAnnouncementVariables) =>
			fetchApiCreateAnnouncement({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type CreateCsvsPathParams = {
	reportId: string;
};

export type CreateCsvsError = Fetcher.ErrorWrapper<undefined>;

export type CreateCsvsResponse = {
	[key: string]: string;
};

export type CreateCsvsVariables = {
	pathParams: CreateCsvsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateCsvs = (
	variables: CreateCsvsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		CreateCsvsResponse,
		CreateCsvsError,
		undefined,
		{},
		{},
		CreateCsvsPathParams
	>({
		url: '/report/{reportId}/create_csvs/',
		method: 'get',
		...variables,
		signal,
	});

export const useCreateCsvs = <TData = CreateCsvsResponse,>(
	variables: CreateCsvsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<CreateCsvsResponse, CreateCsvsError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<CreateCsvsResponse, CreateCsvsError, TData>({
		queryKey: queryKeyFn({
			path: '/report/{reportId}/create_csvs/',
			operationId: 'createCsvs',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchCreateCsvs({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ListPromptsQueryParams = {
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ListPromptsError = Fetcher.ErrorWrapper<undefined>;

export type ListPromptsVariables = {
	queryParams?: ListPromptsQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * List all top level prompts for the user
 */
export const fetchListPrompts = (
	variables: ListPromptsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedEmbeddedPromptParentOut,
		ListPromptsError,
		undefined,
		{},
		ListPromptsQueryParams,
		{}
	>({ url: '/ai/embedded_prompt/', method: 'get', ...variables, signal });

/**
 * List all top level prompts for the user
 */
export const useListPrompts = <TData = Schemas.PagedEmbeddedPromptParentOut,>(
	variables: ListPromptsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEmbeddedPromptParentOut,
			ListPromptsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedEmbeddedPromptParentOut,
		ListPromptsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/embedded_prompt/',
			operationId: 'listPrompts',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchListPrompts({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type CreatePromptError = Fetcher.ErrorWrapper<undefined>;

export type CreatePromptVariables = {
	body: Schemas.EmbeddedPromptIn;
} & ApiContext['fetcherOptions'];

/**
 * Create a new prompt
 */
export const fetchCreatePrompt = (
	variables: CreatePromptVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.EmbeddedPromptParentOut,
		CreatePromptError,
		Schemas.EmbeddedPromptIn,
		{},
		{},
		{}
	>({ url: '/ai/embedded_prompt/', method: 'post', ...variables, signal });

/**
 * Create a new prompt
 */
export const useCreatePrompt = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.EmbeddedPromptParentOut,
			CreatePromptError,
			CreatePromptVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.EmbeddedPromptParentOut,
		CreatePromptError,
		CreatePromptVariables
	>({
		mutationFn: (variables: CreatePromptVariables) =>
			fetchCreatePrompt({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type DeleteAllPromptsError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAllPromptsVariables = ApiContext['fetcherOptions'];

/**
 * Delete all prompts for the user
 */
export const fetchDeleteAllPrompts = (
	variables: DeleteAllPromptsVariables,
	signal?: AbortSignal
) =>
	apiFetch<undefined, DeleteAllPromptsError, undefined, {}, {}, {}>({
		url: '/ai/embedded_prompt/delete_all/',
		method: 'post',
		...variables,
		signal,
	});

/**
 * Delete all prompts for the user
 */
export const useDeleteAllPrompts = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			DeleteAllPromptsError,
			DeleteAllPromptsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		DeleteAllPromptsError,
		DeleteAllPromptsVariables
	>({
		mutationFn: (variables: DeleteAllPromptsVariables) =>
			fetchDeleteAllPrompts({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type SuggestedPromptsQueryParams = {
	location: string;
};

export type SuggestedPromptsError = Fetcher.ErrorWrapper<undefined>;

export type SuggestedPromptsVariables = {
	queryParams: SuggestedPromptsQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * Get suggested prompts
 */
export const fetchSuggestedPrompts = (
	variables: SuggestedPromptsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.SuggestedPromptsOut,
		SuggestedPromptsError,
		undefined,
		{},
		SuggestedPromptsQueryParams,
		{}
	>({
		url: '/ai/embedded_prompt/suggestions/',
		method: 'get',
		...variables,
		signal,
	});

/**
 * Get suggested prompts
 */
export const useSuggestedPrompts = <TData = Schemas.SuggestedPromptsOut,>(
	variables: SuggestedPromptsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SuggestedPromptsOut,
			SuggestedPromptsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.SuggestedPromptsOut,
		SuggestedPromptsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/embedded_prompt/suggestions/',
			operationId: 'suggestedPrompts',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchSuggestedPrompts({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type RetrievePromptPathParams = {
	promptId: string;
};

export type RetrievePromptError = Fetcher.ErrorWrapper<undefined>;

export type RetrievePromptVariables = {
	pathParams: RetrievePromptPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Retrieve a prompt by id
 */
export const fetchRetrievePrompt = (
	variables: RetrievePromptVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.EmbeddedPromptParentOut,
		RetrievePromptError,
		undefined,
		{},
		{},
		RetrievePromptPathParams
	>({
		url: '/ai/embedded_prompt/{promptId}/',
		method: 'get',
		...variables,
		signal,
	});

/**
 * Retrieve a prompt by id
 */
export const useRetrievePrompt = <TData = Schemas.EmbeddedPromptParentOut,>(
	variables: RetrievePromptVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EmbeddedPromptParentOut,
			RetrievePromptError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.EmbeddedPromptParentOut,
		RetrievePromptError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/embedded_prompt/{promptId}/',
			operationId: 'retrievePrompt',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRetrievePrompt({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type UpdatePromptPathParams = {
	promptId: string;
};

export type UpdatePromptError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePromptVariables = {
	body: Schemas.EmbeddedPromptEditIn;
	pathParams: UpdatePromptPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdatePrompt = (
	variables: UpdatePromptVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.EmbeddedPromptParentOut,
		UpdatePromptError,
		Schemas.EmbeddedPromptEditIn,
		{},
		{},
		UpdatePromptPathParams
	>({
		url: '/ai/embedded_prompt/{promptId}/',
		method: 'patch',
		...variables,
		signal,
	});

export const useUpdatePrompt = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.EmbeddedPromptParentOut,
			UpdatePromptError,
			UpdatePromptVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.EmbeddedPromptParentOut,
		UpdatePromptError,
		UpdatePromptVariables
	>({
		mutationFn: (variables: UpdatePromptVariables) =>
			fetchUpdatePrompt({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type DeletePromptPathParams = {
	promptId: string;
};

export type DeletePromptError = Fetcher.ErrorWrapper<undefined>;

export type DeletePromptVariables = {
	pathParams: DeletePromptPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePrompt = (
	variables: DeletePromptVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		DeletePromptError,
		undefined,
		{},
		{},
		DeletePromptPathParams
	>({
		url: '/ai/embedded_prompt/{promptId}/',
		method: 'delete',
		...variables,
		signal,
	});

export const useDeletePrompt = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			DeletePromptError,
			DeletePromptVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		DeletePromptError,
		DeletePromptVariables
	>({
		mutationFn: (variables: DeletePromptVariables) =>
			fetchDeletePrompt({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type TerminatePromptPathParams = {
	promptId: string;
};

export type TerminatePromptError = Fetcher.ErrorWrapper<undefined>;

export type TerminatePromptVariables = {
	pathParams: TerminatePromptPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Terminate a prompt
 */
export const fetchTerminatePrompt = (
	variables: TerminatePromptVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		TerminatePromptError,
		undefined,
		{},
		{},
		TerminatePromptPathParams
	>({
		url: '/ai/embedded_prompt/{promptId}/terminate/',
		method: 'post',
		...variables,
		signal,
	});

/**
 * Terminate a prompt
 */
export const useTerminatePrompt = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			TerminatePromptError,
			TerminatePromptVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		TerminatePromptError,
		TerminatePromptVariables
	>({
		mutationFn: (variables: TerminatePromptVariables) =>
			fetchTerminatePrompt({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type FeedbackPathParams = {
	promptId: string;
};

export type FeedbackError = Fetcher.ErrorWrapper<undefined>;

export type FeedbackVariables = {
	body: Schemas.EmbeddedPromptFeedbackIn;
	pathParams: FeedbackPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Post feedback to Slack
 */
export const fetchFeedback = (
	variables: FeedbackVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		FeedbackError,
		Schemas.EmbeddedPromptFeedbackIn,
		{},
		{},
		FeedbackPathParams
	>({
		url: '/ai/embedded_prompt/{promptId}/feedback/',
		method: 'post',
		...variables,
		signal,
	});

/**
 * Post feedback to Slack
 */
export const useFeedback = (
	options?: Omit<
		reactQuery.UseMutationOptions<undefined, FeedbackError, FeedbackVariables>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<undefined, FeedbackError, FeedbackVariables>({
		mutationFn: (variables: FeedbackVariables) =>
			fetchFeedback({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetAgentToolsError = Fetcher.ErrorWrapper<undefined>;

export type GetAgentToolsVariables = ApiContext['fetcherOptions'];

export const fetchGetAgentTools = (
	variables: GetAgentToolsVariables,
	signal?: AbortSignal
) =>
	apiFetch<Schemas.AgentToolsOut, GetAgentToolsError, undefined, {}, {}, {}>({
		url: '/ai/preferences/tools/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetAgentTools = <TData = Schemas.AgentToolsOut,>(
	variables: GetAgentToolsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AgentToolsOut,
			GetAgentToolsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.AgentToolsOut, GetAgentToolsError, TData>({
		queryKey: queryKeyFn({
			path: '/ai/preferences/tools/',
			operationId: 'getAgentTools',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetAgentTools({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type EnableAgentToolPathParams = {
	toolName: string;
};

export type EnableAgentToolError = Fetcher.ErrorWrapper<undefined>;

export type EnableAgentToolVariables = {
	pathParams: EnableAgentToolPathParams;
} & ApiContext['fetcherOptions'];

export const fetchEnableAgentTool = (
	variables: EnableAgentToolVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		EnableAgentToolError,
		undefined,
		{},
		{},
		EnableAgentToolPathParams
	>({
		url: '/ai/preferences/tools/{toolName}/enable/',
		method: 'post',
		...variables,
		signal,
	});

export const useEnableAgentTool = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			EnableAgentToolError,
			EnableAgentToolVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		EnableAgentToolError,
		EnableAgentToolVariables
	>({
		mutationFn: (variables: EnableAgentToolVariables) =>
			fetchEnableAgentTool({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type DisableAgentToolPathParams = {
	toolName: string;
};

export type DisableAgentToolError = Fetcher.ErrorWrapper<undefined>;

export type DisableAgentToolVariables = {
	pathParams: DisableAgentToolPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDisableAgentTool = (
	variables: DisableAgentToolVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		DisableAgentToolError,
		undefined,
		{},
		{},
		DisableAgentToolPathParams
	>({
		url: '/ai/preferences/tools/{toolName}/disable/',
		method: 'post',
		...variables,
		signal,
	});

export const useDisableAgentTool = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			DisableAgentToolError,
			DisableAgentToolVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		DisableAgentToolError,
		DisableAgentToolVariables
	>({
		mutationFn: (variables: DisableAgentToolVariables) =>
			fetchDisableAgentTool({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetCustomInstructionsError = Fetcher.ErrorWrapper<undefined>;

export type GetCustomInstructionsVariables = ApiContext['fetcherOptions'];

export const fetchGetCustomInstructions = (
	variables: GetCustomInstructionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.CustomInstructionsOut,
		GetCustomInstructionsError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/ai/preferences/instructions/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetCustomInstructions = <
	TData = Schemas.CustomInstructionsOut,
>(
	variables: GetCustomInstructionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.CustomInstructionsOut,
			GetCustomInstructionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.CustomInstructionsOut,
		GetCustomInstructionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/preferences/instructions/',
			operationId: 'getCustomInstructions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetCustomInstructions({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type SetCustomInstructionsError = Fetcher.ErrorWrapper<undefined>;

export type SetCustomInstructionsVariables = {
	body: Schemas.CustomInstructionsIn;
} & ApiContext['fetcherOptions'];

export const fetchSetCustomInstructions = (
	variables: SetCustomInstructionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.CustomInstructionsOut,
		SetCustomInstructionsError,
		Schemas.CustomInstructionsIn,
		{},
		{},
		{}
	>({
		url: '/ai/preferences/instructions/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetCustomInstructions = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.CustomInstructionsOut,
			SetCustomInstructionsError,
			SetCustomInstructionsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.CustomInstructionsOut,
		SetCustomInstructionsError,
		SetCustomInstructionsVariables
	>({
		mutationFn: (variables: SetCustomInstructionsVariables) =>
			fetchSetCustomInstructions({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type SetCustomDescriptionStyleError = Fetcher.ErrorWrapper<undefined>;

export type SetCustomDescriptionStyleVariables = {
	body: Schemas.CustomInstructionsIn;
} & ApiContext['fetcherOptions'];

export const fetchSetCustomDescriptionStyle = (
	variables: SetCustomDescriptionStyleVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.CustomInstructionsOut,
		SetCustomDescriptionStyleError,
		Schemas.CustomInstructionsIn,
		{},
		{},
		{}
	>({
		url: '/ai/preferences/descriptions/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetCustomDescriptionStyle = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.CustomInstructionsOut,
			SetCustomDescriptionStyleError,
			SetCustomDescriptionStyleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.CustomInstructionsOut,
		SetCustomDescriptionStyleError,
		SetCustomDescriptionStyleVariables
	>({
		mutationFn: (variables: SetCustomDescriptionStyleVariables) =>
			fetchSetCustomDescriptionStyle({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetCustomDescriptionStyleError = Fetcher.ErrorWrapper<undefined>;

export type GetCustomDescriptionStyleVariables = ApiContext['fetcherOptions'];

export const fetchGetCustomDescriptionStyle = (
	variables: GetCustomDescriptionStyleVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.CustomInstructionsOut,
		GetCustomDescriptionStyleError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/ai/preferences/descriptions/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetCustomDescriptionStyle = <
	TData = Schemas.CustomInstructionsOut,
>(
	variables: GetCustomDescriptionStyleVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.CustomInstructionsOut,
			GetCustomDescriptionStyleError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.CustomInstructionsOut,
		GetCustomDescriptionStyleError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/preferences/descriptions/',
			operationId: 'getCustomDescriptionStyle',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetCustomDescriptionStyle(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type GetIncludedInAiGovernanceError = Fetcher.ErrorWrapper<undefined>;

export type GetIncludedInAiGovernanceResponse = Schemas.Filter[];

export type GetIncludedInAiGovernanceVariables = ApiContext['fetcherOptions'];

export const fetchGetIncludedInAiGovernance = (
	variables: GetIncludedInAiGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetIncludedInAiGovernanceResponse,
		GetIncludedInAiGovernanceError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/ai/preferences/governance/included/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetIncludedInAiGovernance = <
	TData = GetIncludedInAiGovernanceResponse,
>(
	variables: GetIncludedInAiGovernanceVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetIncludedInAiGovernanceResponse,
			GetIncludedInAiGovernanceError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetIncludedInAiGovernanceResponse,
		GetIncludedInAiGovernanceError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/preferences/governance/included/',
			operationId: 'getIncludedInAiGovernance',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetIncludedInAiGovernance(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type SetIncludedInAiGovernanceError = Fetcher.ErrorWrapper<undefined>;

export type SetIncludedInAiGovernanceRequestBody = Schemas.Filter[];

export type SetIncludedInAiGovernanceVariables = {
	body?: SetIncludedInAiGovernanceRequestBody;
} & ApiContext['fetcherOptions'];

export const fetchSetIncludedInAiGovernance = (
	variables: SetIncludedInAiGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		SetIncludedInAiGovernanceError,
		SetIncludedInAiGovernanceRequestBody,
		{},
		{},
		{}
	>({
		url: '/ai/preferences/governance/included/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetIncludedInAiGovernance = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			SetIncludedInAiGovernanceError,
			SetIncludedInAiGovernanceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		SetIncludedInAiGovernanceError,
		SetIncludedInAiGovernanceVariables
	>({
		mutationFn: (variables: SetIncludedInAiGovernanceVariables) =>
			fetchSetIncludedInAiGovernance({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetExcludedFromAiGovernanceError = Fetcher.ErrorWrapper<undefined>;

export type GetExcludedFromAiGovernanceResponse = Schemas.Filter[];

export type GetExcludedFromAiGovernanceVariables = ApiContext['fetcherOptions'];

export const fetchGetExcludedFromAiGovernance = (
	variables: GetExcludedFromAiGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetExcludedFromAiGovernanceResponse,
		GetExcludedFromAiGovernanceError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/ai/preferences/governance/excluded/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetExcludedFromAiGovernance = <
	TData = GetExcludedFromAiGovernanceResponse,
>(
	variables: GetExcludedFromAiGovernanceVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetExcludedFromAiGovernanceResponse,
			GetExcludedFromAiGovernanceError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetExcludedFromAiGovernanceResponse,
		GetExcludedFromAiGovernanceError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/preferences/governance/excluded/',
			operationId: 'getExcludedFromAiGovernance',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetExcludedFromAiGovernance(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type SetExcludedFromAiGovernanceError = Fetcher.ErrorWrapper<undefined>;

export type SetExcludedFromAiGovernanceRequestBody = Schemas.Filter[];

export type SetExcludedFromAiGovernanceVariables = {
	body?: SetExcludedFromAiGovernanceRequestBody;
} & ApiContext['fetcherOptions'];

export const fetchSetExcludedFromAiGovernance = (
	variables: SetExcludedFromAiGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		SetExcludedFromAiGovernanceError,
		SetExcludedFromAiGovernanceRequestBody,
		{},
		{},
		{}
	>({
		url: '/ai/preferences/governance/excluded/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetExcludedFromAiGovernance = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			SetExcludedFromAiGovernanceError,
			SetExcludedFromAiGovernanceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		SetExcludedFromAiGovernanceError,
		SetExcludedFromAiGovernanceVariables
	>({
		mutationFn: (variables: SetExcludedFromAiGovernanceVariables) =>
			fetchSetExcludedFromAiGovernance({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetEntityIncludedInAiGovernanceQueryParams = {
	entity_id: string;
};

export type GetEntityIncludedInAiGovernanceError =
	Fetcher.ErrorWrapper<undefined>;

export type GetEntityIncludedInAiGovernanceResponse = {
	[key: string]: boolean;
};

export type GetEntityIncludedInAiGovernanceVariables = {
	queryParams: GetEntityIncludedInAiGovernanceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetEntityIncludedInAiGovernance = (
	variables: GetEntityIncludedInAiGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetEntityIncludedInAiGovernanceResponse,
		GetEntityIncludedInAiGovernanceError,
		undefined,
		{},
		GetEntityIncludedInAiGovernanceQueryParams,
		{}
	>({
		url: '/ai/preferences/governance/entities/included/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetEntityIncludedInAiGovernance = <
	TData = GetEntityIncludedInAiGovernanceResponse,
>(
	variables: GetEntityIncludedInAiGovernanceVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetEntityIncludedInAiGovernanceResponse,
			GetEntityIncludedInAiGovernanceError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetEntityIncludedInAiGovernanceResponse,
		GetEntityIncludedInAiGovernanceError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/preferences/governance/entities/included/',
			operationId: 'getEntityIncludedInAiGovernance',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetEntityIncludedInAiGovernance(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type CreateSummaryError = Fetcher.ErrorWrapper<undefined>;

export type CreateSummaryVariables = {
	body: Schemas.EditorPromptIn;
} & ApiContext['fetcherOptions'];

export const fetchCreateSummary = (
	variables: CreateSummaryVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.EditorPromptOut,
		CreateSummaryError,
		Schemas.EditorPromptIn,
		{},
		{},
		{}
	>({ url: '/ai/editor/summary/', method: 'post', ...variables, signal });

export const useCreateSummary = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.EditorPromptOut,
			CreateSummaryError,
			CreateSummaryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.EditorPromptOut,
		CreateSummaryError,
		CreateSummaryVariables
	>({
		mutationFn: (variables: CreateSummaryVariables) =>
			fetchCreateSummary({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetFilterQueryParams = {
	query: string;
};

export type GetFilterError = Fetcher.ErrorWrapper<undefined>;

export type GetFilterVariables = {
	queryParams: GetFilterQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetFilter = (
	variables: GetFilterVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.Filter,
		GetFilterError,
		undefined,
		{},
		GetFilterQueryParams,
		{}
	>({ url: '/ai/filters/filter/', method: 'post', ...variables, signal });

export const useGetFilter = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.Filter,
			GetFilterError,
			GetFilterVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.Filter,
		GetFilterError,
		GetFilterVariables
	>({
		mutationFn: (variables: GetFilterVariables) =>
			fetchGetFilter({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetSortQueryParams = {
	query: string;
};

export type GetSortError = Fetcher.ErrorWrapper<undefined>;

export type GetSortVariables = {
	queryParams: GetSortQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetSort = (
	variables: GetSortVariables,
	signal?: AbortSignal
) =>
	apiFetch<Schemas.Sort, GetSortError, undefined, {}, GetSortQueryParams, {}>({
		url: '/ai/filters/sort/',
		method: 'post',
		...variables,
		signal,
	});

export const useGetSort = (
	options?: Omit<
		reactQuery.UseMutationOptions<Schemas.Sort, GetSortError, GetSortVariables>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<Schemas.Sort, GetSortError, GetSortVariables>({
		mutationFn: (variables: GetSortVariables) =>
			fetchGetSort({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetPersonasError = Fetcher.ErrorWrapper<undefined>;

export type GetPersonasResponse = Schemas.AIPersonaOut[];

export type GetPersonasVariables = ApiContext['fetcherOptions'];

export const fetchGetPersonas = (
	variables: GetPersonasVariables,
	signal?: AbortSignal
) =>
	apiFetch<GetPersonasResponse, GetPersonasError, undefined, {}, {}, {}>({
		url: '/ai/personas/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetPersonas = <TData = GetPersonasResponse,>(
	variables: GetPersonasVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<GetPersonasResponse, GetPersonasError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<GetPersonasResponse, GetPersonasError, TData>({
		queryKey: queryKeyFn({
			path: '/ai/personas/',
			operationId: 'getPersonas',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetPersonas({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type CreatePersonaError = Fetcher.ErrorWrapper<undefined>;

export type CreatePersonaVariables = ApiContext['fetcherOptions'];

export const fetchCreatePersona = (
	variables: CreatePersonaVariables,
	signal?: AbortSignal
) =>
	apiFetch<Schemas.AIPersonaOut, CreatePersonaError, undefined, {}, {}, {}>({
		url: '/ai/personas/',
		method: 'post',
		...variables,
		signal,
	});

export const useCreatePersona = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.AIPersonaOut,
			CreatePersonaError,
			CreatePersonaVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.AIPersonaOut,
		CreatePersonaError,
		CreatePersonaVariables
	>({
		mutationFn: (variables: CreatePersonaVariables) =>
			fetchCreatePersona({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetPersonaPathParams = {
	personaId: string;
};

export type GetPersonaError = Fetcher.ErrorWrapper<undefined>;

export type GetPersonaVariables = {
	pathParams: GetPersonaPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPersona = (
	variables: GetPersonaVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.AIPersonaOut,
		GetPersonaError,
		undefined,
		{},
		{},
		GetPersonaPathParams
	>({ url: '/ai/personas/{personaId}/', method: 'get', ...variables, signal });

export const useGetPersona = <TData = Schemas.AIPersonaOut,>(
	variables: GetPersonaVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<Schemas.AIPersonaOut, GetPersonaError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.AIPersonaOut, GetPersonaError, TData>({
		queryKey: queryKeyFn({
			path: '/ai/personas/{personaId}/',
			operationId: 'getPersona',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetPersona({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type UpdatePersonaPathParams = {
	personaId: string;
};

export type UpdatePersonaError = Fetcher.ErrorWrapper<undefined>;

export type UpdatePersonaVariables = {
	body: Schemas.AIPersonaUpdateIn;
	pathParams: UpdatePersonaPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdatePersona = (
	variables: UpdatePersonaVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.AIPersonaOut,
		UpdatePersonaError,
		Schemas.AIPersonaUpdateIn,
		{},
		{},
		UpdatePersonaPathParams
	>({ url: '/ai/personas/{personaId}/', method: 'put', ...variables, signal });

export const useUpdatePersona = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.AIPersonaOut,
			UpdatePersonaError,
			UpdatePersonaVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.AIPersonaOut,
		UpdatePersonaError,
		UpdatePersonaVariables
	>({
		mutationFn: (variables: UpdatePersonaVariables) =>
			fetchUpdatePersona({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type DeletePersonaPathParams = {
	personaId: string;
};

export type DeletePersonaError = Fetcher.ErrorWrapper<undefined>;

export type DeletePersonaVariables = {
	pathParams: DeletePersonaPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePersona = (
	variables: DeletePersonaVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		DeletePersonaError,
		undefined,
		{},
		{},
		DeletePersonaPathParams
	>({
		url: '/ai/personas/{personaId}/',
		method: 'delete',
		...variables,
		signal,
	});

export const useDeletePersona = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			DeletePersonaError,
			DeletePersonaVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		DeletePersonaError,
		DeletePersonaVariables
	>({
		mutationFn: (variables: DeletePersonaVariables) =>
			fetchDeletePersona({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetIncludedFiltersPathParams = {
	personaId: string;
};

export type GetIncludedFiltersError = Fetcher.ErrorWrapper<undefined>;

export type GetIncludedFiltersResponse = Schemas.Filter[];

export type GetIncludedFiltersVariables = {
	pathParams: GetIncludedFiltersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetIncludedFilters = (
	variables: GetIncludedFiltersVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetIncludedFiltersResponse,
		GetIncludedFiltersError,
		undefined,
		{},
		{},
		GetIncludedFiltersPathParams
	>({
		url: '/ai/personas/{personaId}/included_filters/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetIncludedFilters = <TData = GetIncludedFiltersResponse,>(
	variables: GetIncludedFiltersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetIncludedFiltersResponse,
			GetIncludedFiltersError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetIncludedFiltersResponse,
		GetIncludedFiltersError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/personas/{personaId}/included_filters/',
			operationId: 'getIncludedFilters',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetIncludedFilters({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type SetIncludedFiltersPathParams = {
	personaId: string;
};

export type SetIncludedFiltersError = Fetcher.ErrorWrapper<undefined>;

export type SetIncludedFiltersRequestBody = Schemas.Filter[];

export type SetIncludedFiltersVariables = {
	body?: SetIncludedFiltersRequestBody;
	pathParams: SetIncludedFiltersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSetIncludedFilters = (
	variables: SetIncludedFiltersVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		SetIncludedFiltersError,
		SetIncludedFiltersRequestBody,
		{},
		{},
		SetIncludedFiltersPathParams
	>({
		url: '/ai/personas/{personaId}/included_filters/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetIncludedFilters = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			SetIncludedFiltersError,
			SetIncludedFiltersVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		SetIncludedFiltersError,
		SetIncludedFiltersVariables
	>({
		mutationFn: (variables: SetIncludedFiltersVariables) =>
			fetchSetIncludedFilters({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetExcludedFiltersPathParams = {
	personaId: string;
};

export type GetExcludedFiltersError = Fetcher.ErrorWrapper<undefined>;

export type GetExcludedFiltersResponse = Schemas.Filter[];

export type GetExcludedFiltersVariables = {
	pathParams: GetExcludedFiltersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetExcludedFilters = (
	variables: GetExcludedFiltersVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetExcludedFiltersResponse,
		GetExcludedFiltersError,
		undefined,
		{},
		{},
		GetExcludedFiltersPathParams
	>({
		url: '/ai/personas/{personaId}/excluded_filters/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetExcludedFilters = <TData = GetExcludedFiltersResponse,>(
	variables: GetExcludedFiltersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetExcludedFiltersResponse,
			GetExcludedFiltersError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetExcludedFiltersResponse,
		GetExcludedFiltersError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/ai/personas/{personaId}/excluded_filters/',
			operationId: 'getExcludedFilters',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetExcludedFilters({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type SetExcludedFiltersPathParams = {
	personaId: string;
};

export type SetExcludedFiltersError = Fetcher.ErrorWrapper<undefined>;

export type SetExcludedFiltersRequestBody = Schemas.Filter[];

export type SetExcludedFiltersVariables = {
	body?: SetExcludedFiltersRequestBody;
	pathParams: SetExcludedFiltersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSetExcludedFilters = (
	variables: SetExcludedFiltersVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		SetExcludedFiltersError,
		SetExcludedFiltersRequestBody,
		{},
		{},
		SetExcludedFiltersPathParams
	>({
		url: '/ai/personas/{personaId}/excluded_filters/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetExcludedFilters = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			SetExcludedFiltersError,
			SetExcludedFiltersVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		SetExcludedFiltersError,
		SetExcludedFiltersVariables
	>({
		mutationFn: (variables: SetExcludedFiltersVariables) =>
			fetchSetExcludedFilters({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type UploadAvatarPathParams = {
	personaId: string;
};

export type UploadAvatarError = Fetcher.ErrorWrapper<undefined>;

export type UploadAvatarRequestBody = {
	/**
	 * @format binary
	 */
	file: Blob;
};

export type UploadAvatarVariables = {
	body: UploadAvatarRequestBody;
	pathParams: UploadAvatarPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUploadAvatar = (
	variables: UploadAvatarVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.AIPersonaOut,
		UploadAvatarError,
		UploadAvatarRequestBody,
		{},
		{},
		UploadAvatarPathParams
	>({
		url: '/ai/personas/{personaId}/upload/',
		method: 'post',
		...variables,
		signal,
	});

export const useUploadAvatar = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.AIPersonaOut,
			UploadAvatarError,
			UploadAvatarVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.AIPersonaOut,
		UploadAvatarError,
		UploadAvatarVariables
	>({
		mutationFn: (variables: UploadAvatarVariables) =>
			fetchUploadAvatar({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiGetCreationQueryPathParams = {
	entityId: string;
};

export type ApiGetCreationQueryError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetCreationQueryVariables = {
	pathParams: ApiGetCreationQueryPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Get the creation query of an entity.
 */
export const fetchApiGetCreationQuery = (
	variables: ApiGetCreationQueryVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.LiteQueryOut | null,
		ApiGetCreationQueryError,
		undefined,
		{},
		{},
		ApiGetCreationQueryPathParams
	>({
		url: '/query/queries/creation_query/{entityId}/',
		method: 'get',
		...variables,
		signal,
	});

/**
 * Get the creation query of an entity.
 */
export const useApiGetCreationQuery = <TData = Schemas.LiteQueryOut | null,>(
	variables: ApiGetCreationQueryVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LiteQueryOut | null,
			ApiGetCreationQueryError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.LiteQueryOut | null,
		ApiGetCreationQueryError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/query/queries/creation_query/{entityId}/',
			operationId: 'apiGetCreationQuery',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetCreationQuery({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetQueriesQueryParams = {
	/**
	 * @default 1
	 */
	page?: number;
	/**
	 * @default 50
	 */
	page_size?: number;
	search_term?: string | null;
	sort_by?: string | null;
	/**
	 * @default 30
	 */
	lookback_days?: number;
};

export type ApiGetQueriesError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetQueriesVariables = {
	queryParams?: ApiGetQueriesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetQueries = (
	variables: ApiGetQueriesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.QueryListOut,
		ApiGetQueriesError,
		undefined,
		{},
		ApiGetQueriesQueryParams,
		{}
	>({
		url: '/query/queries/v2/workspace/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetQueries = <TData = Schemas.QueryListOut,>(
	variables: ApiGetQueriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<Schemas.QueryListOut, ApiGetQueriesError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<Schemas.QueryListOut, ApiGetQueriesError, TData>({
		queryKey: queryKeyFn({
			path: '/query/queries/v2/workspace/',
			operationId: 'apiGetQueries',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetQueries({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetEntityQueriesPathParams = {
	entityId: string;
};

export type ApiGetEntityQueriesQueryParams = {
	/**
	 * @default 1
	 */
	page?: number;
	/**
	 * @default 10
	 */
	page_size?: number;
	search_term?: string | null;
};

export type ApiGetEntityQueriesError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetEntityQueriesVariables = {
	pathParams: ApiGetEntityQueriesPathParams;
	queryParams?: ApiGetEntityQueriesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetEntityQueries = (
	variables: ApiGetEntityQueriesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.QueryListOut,
		ApiGetEntityQueriesError,
		undefined,
		{},
		ApiGetEntityQueriesQueryParams,
		ApiGetEntityQueriesPathParams
	>({
		url: '/query/queries/v2/{entityId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetEntityQueries = <TData = Schemas.QueryListOut,>(
	variables: ApiGetEntityQueriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.QueryListOut,
			ApiGetEntityQueriesError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.QueryListOut,
		ApiGetEntityQueriesError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/query/queries/v2/{entityId}/',
			operationId: 'apiGetEntityQueries',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetEntityQueries({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetQueryExecutionsPathParams = {
	queryId: string;
};

export type ApiGetQueryExecutionsQueryParams = {
	/**
	 * @default 1
	 */
	page?: number;
	/**
	 * @default 50
	 */
	page_size?: number;
	/**
	 * @default 30
	 */
	lookback_days?: number;
};

export type ApiGetQueryExecutionsError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetQueryExecutionsVariables = {
	pathParams: ApiGetQueryExecutionsPathParams;
	queryParams?: ApiGetQueryExecutionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetQueryExecutions = (
	variables: ApiGetQueryExecutionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.QueryExecutionListOut,
		ApiGetQueryExecutionsError,
		undefined,
		{},
		ApiGetQueryExecutionsQueryParams,
		ApiGetQueryExecutionsPathParams
	>({
		url: '/query/queries/v2/{queryId}/executions/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetQueryExecutions = <
	TData = Schemas.QueryExecutionListOut,
>(
	variables: ApiGetQueryExecutionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.QueryExecutionListOut,
			ApiGetQueryExecutionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.QueryExecutionListOut,
		ApiGetQueryExecutionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/query/queries/v2/{queryId}/executions/',
			operationId: 'apiGetQueryExecutions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetQueryExecutions({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetQueryMetadataPathParams = {
	queryId: string;
};

export type ApiGetQueryMetadataQueryParams = {
	/**
	 * @default 30
	 */
	lookback_days?: number;
};

export type ApiGetQueryMetadataError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetQueryMetadataVariables = {
	pathParams: ApiGetQueryMetadataPathParams;
	queryParams?: ApiGetQueryMetadataQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetQueryMetadata = (
	variables: ApiGetQueryMetadataVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.QueryMetadata,
		ApiGetQueryMetadataError,
		undefined,
		{},
		ApiGetQueryMetadataQueryParams,
		ApiGetQueryMetadataPathParams
	>({
		url: '/query/queries/v2/{queryId}/metadata/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetQueryMetadata = <TData = Schemas.QueryMetadata,>(
	variables: ApiGetQueryMetadataVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.QueryMetadata,
			ApiGetQueryMetadataError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.QueryMetadata,
		ApiGetQueryMetadataError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/query/queries/v2/{queryId}/metadata/',
			operationId: 'apiGetQueryMetadata',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetQueryMetadata({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiGetQueryChartsPathParams = {
	queryId: string;
};

export type ApiGetQueryChartsQueryParams = {
	/**
	 * @default 30
	 */
	lookback_days?: number;
};

export type ApiGetQueryChartsError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetQueryChartsResponse = Schemas.QueryChart[];

export type ApiGetQueryChartsVariables = {
	pathParams: ApiGetQueryChartsPathParams;
	queryParams?: ApiGetQueryChartsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetQueryCharts = (
	variables: ApiGetQueryChartsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ApiGetQueryChartsResponse,
		ApiGetQueryChartsError,
		undefined,
		{},
		ApiGetQueryChartsQueryParams,
		ApiGetQueryChartsPathParams
	>({
		url: '/query/queries/v2/{queryId}/charts/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetQueryCharts = <TData = ApiGetQueryChartsResponse,>(
	variables: ApiGetQueryChartsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ApiGetQueryChartsResponse,
			ApiGetQueryChartsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ApiGetQueryChartsResponse,
		ApiGetQueryChartsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/query/queries/v2/{queryId}/charts/',
			operationId: 'apiGetQueryCharts',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetQueryCharts({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ApiUpdateQueryTitlePathParams = {
	queryId: string;
};

export type ApiUpdateQueryTitleError = Fetcher.ErrorWrapper<undefined>;

export type ApiUpdateQueryTitleVariables = {
	body: Schemas.QueryTitleIn;
	pathParams: ApiUpdateQueryTitlePathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiUpdateQueryTitle = (
	variables: ApiUpdateQueryTitleVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiUpdateQueryTitleError,
		Schemas.QueryTitleIn,
		{},
		{},
		ApiUpdateQueryTitlePathParams
	>({
		url: '/query/queries/v2/{queryId}/title/',
		method: 'put',
		...variables,
		signal,
	});

export const useApiUpdateQueryTitle = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiUpdateQueryTitleError,
			ApiUpdateQueryTitleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiUpdateQueryTitleError,
		ApiUpdateQueryTitleVariables
	>({
		mutationFn: (variables: ApiUpdateQueryTitleVariables) =>
			fetchApiUpdateQueryTitle({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiUpdateQueryDescriptionPathParams = {
	queryId: string;
};

export type ApiUpdateQueryDescriptionError = Fetcher.ErrorWrapper<undefined>;

export type ApiUpdateQueryDescriptionVariables = {
	body: Schemas.QueryDescriptionIn;
	pathParams: ApiUpdateQueryDescriptionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiUpdateQueryDescription = (
	variables: ApiUpdateQueryDescriptionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiUpdateQueryDescriptionError,
		Schemas.QueryDescriptionIn,
		{},
		{},
		ApiUpdateQueryDescriptionPathParams
	>({
		url: '/query/queries/v2/{queryId}/description/',
		method: 'put',
		...variables,
		signal,
	});

export const useApiUpdateQueryDescription = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiUpdateQueryDescriptionError,
			ApiUpdateQueryDescriptionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiUpdateQueryDescriptionError,
		ApiUpdateQueryDescriptionVariables
	>({
		mutationFn: (variables: ApiUpdateQueryDescriptionVariables) =>
			fetchApiUpdateQueryDescription({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type IsJiraIntegratedError = Fetcher.ErrorWrapper<undefined>;

export type IsJiraIntegratedVariables = ApiContext['fetcherOptions'];

export const fetchIsJiraIntegrated = (
	variables: IsJiraIntegratedVariables,
	signal?: AbortSignal
) =>
	apiFetch<boolean, IsJiraIntegratedError, undefined, {}, {}, {}>({
		url: '/monitor/incidents/jira/is_jira_integrated/',
		method: 'get',
		...variables,
		signal,
	});

export const useIsJiraIntegrated = <TData = boolean,>(
	variables: IsJiraIntegratedVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<boolean, IsJiraIntegratedError, TData>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<boolean, IsJiraIntegratedError, TData>({
		queryKey: queryKeyFn({
			path: '/monitor/incidents/jira/is_jira_integrated/',
			operationId: 'isJiraIntegrated',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchIsJiraIntegrated({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type GetJiraInformationError = Fetcher.ErrorWrapper<{
	status: 400;
	payload: Schemas.JiraError;
}>;

export type GetJiraInformationVariables = ApiContext['fetcherOptions'];

export const fetchGetJiraInformation = (
	variables: GetJiraInformationVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.GetJiraInfoOut,
		GetJiraInformationError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/monitor/incidents/jira/get_jira_information/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetJiraInformation = <TData = Schemas.GetJiraInfoOut,>(
	variables: GetJiraInformationVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.GetJiraInfoOut,
			GetJiraInformationError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.GetJiraInfoOut,
		GetJiraInformationError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/monitor/incidents/jira/get_jira_information/',
			operationId: 'getJiraInformation',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetJiraInformation({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type LinkJiraIssuePathParams = {
	incidentId: string;
};

export type LinkJiraIssueError = Fetcher.ErrorWrapper<{
	status: 400;
	payload: Schemas.JiraError;
}>;

export type LinkJiraIssueVariables = {
	body: Schemas.JiraLinkIn;
	pathParams: LinkJiraIssuePathParams;
} & ApiContext['fetcherOptions'];

export const fetchLinkJiraIssue = (
	variables: LinkJiraIssueVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		LinkJiraIssueError,
		Schemas.JiraLinkIn,
		{},
		{},
		LinkJiraIssuePathParams
	>({
		url: '/monitor/incidents/jira/{incidentId}/link_jira_issue/',
		method: 'post',
		...variables,
		signal,
	});

export const useLinkJiraIssue = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			LinkJiraIssueError,
			LinkJiraIssueVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		LinkJiraIssueError,
		LinkJiraIssueVariables
	>({
		mutationFn: (variables: LinkJiraIssueVariables) =>
			fetchLinkJiraIssue({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type CreateJiraIssuePathParams = {
	incidentId: string;
};

export type CreateJiraIssueError = Fetcher.ErrorWrapper<undefined>;

export type CreateJiraIssueVariables = {
	body: Schemas.IssueCreateIn;
	pathParams: CreateJiraIssuePathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateJiraIssue = (
	variables: CreateJiraIssueVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		CreateJiraIssueError,
		Schemas.IssueCreateIn,
		{},
		{},
		CreateJiraIssuePathParams
	>({
		url: '/monitor/incidents/jira/{incidentId}/create_jira_issue/',
		method: 'post',
		...variables,
		signal,
	});

export const useCreateJiraIssue = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			CreateJiraIssueError,
			CreateJiraIssueVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		CreateJiraIssueError,
		CreateJiraIssueVariables
	>({
		mutationFn: (variables: CreateJiraIssueVariables) =>
			fetchCreateJiraIssue({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type UnlinkJiraIssuePathParams = {
	incidentId: string;
};

export type UnlinkJiraIssueError = Fetcher.ErrorWrapper<undefined>;

export type UnlinkJiraIssueVariables = {
	pathParams: UnlinkJiraIssuePathParams;
} & ApiContext['fetcherOptions'];

export const fetchUnlinkJiraIssue = (
	variables: UnlinkJiraIssueVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		UnlinkJiraIssueError,
		undefined,
		{},
		{},
		UnlinkJiraIssuePathParams
	>({
		url: '/monitor/incidents/jira/{incidentId}/unlink_jira_issue/',
		method: 'post',
		...variables,
		signal,
	});

export const useUnlinkJiraIssue = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			UnlinkJiraIssueError,
			UnlinkJiraIssueVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		UnlinkJiraIssueError,
		UnlinkJiraIssueVariables
	>({
		mutationFn: (variables: UnlinkJiraIssueVariables) =>
			fetchUnlinkJiraIssue({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type ApiCreateMarketplaceIntegrationSpecError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiCreateMarketplaceIntegrationSpecVariables = {
	body: Schemas.MarketplaceIntegrationSpecIn;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateMarketplaceIntegrationSpec = (
	variables: ApiCreateMarketplaceIntegrationSpecVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.MarketplaceIntegrationSpecOut,
		ApiCreateMarketplaceIntegrationSpecError,
		Schemas.MarketplaceIntegrationSpecIn,
		{},
		{},
		{}
	>({
		url: '/integration/marketplace/specs/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiCreateMarketplaceIntegrationSpec = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.MarketplaceIntegrationSpecOut,
			ApiCreateMarketplaceIntegrationSpecError,
			ApiCreateMarketplaceIntegrationSpecVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.MarketplaceIntegrationSpecOut,
		ApiCreateMarketplaceIntegrationSpecError,
		ApiCreateMarketplaceIntegrationSpecVariables
	>({
		mutationFn: (variables: ApiCreateMarketplaceIntegrationSpecVariables) =>
			fetchApiCreateMarketplaceIntegrationSpec({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiListMarketplaceIntegrationSpecsQueryParams = {
	/**
	 * @default false
	 */
	browsing?: boolean;
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ApiListMarketplaceIntegrationSpecsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiListMarketplaceIntegrationSpecsVariables = {
	queryParams?: ApiListMarketplaceIntegrationSpecsQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * @param browsing: If browsing, we also return specs from other workspaces, but only
 * those that have at least one version approved.
 */
export const fetchApiListMarketplaceIntegrationSpecs = (
	variables: ApiListMarketplaceIntegrationSpecsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedMarketplaceIntegrationSpecOut,
		ApiListMarketplaceIntegrationSpecsError,
		undefined,
		{},
		ApiListMarketplaceIntegrationSpecsQueryParams,
		{}
	>({
		url: '/integration/marketplace/specs/',
		method: 'get',
		...variables,
		signal,
	});

/**
 * @param browsing: If browsing, we also return specs from other workspaces, but only
 * those that have at least one version approved.
 */
export const useApiListMarketplaceIntegrationSpecs = <
	TData = Schemas.PagedMarketplaceIntegrationSpecOut,
>(
	variables: ApiListMarketplaceIntegrationSpecsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMarketplaceIntegrationSpecOut,
			ApiListMarketplaceIntegrationSpecsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedMarketplaceIntegrationSpecOut,
		ApiListMarketplaceIntegrationSpecsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/marketplace/specs/',
			operationId: 'apiListMarketplaceIntegrationSpecs',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiListMarketplaceIntegrationSpecs(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiGetMarketplaceIntegrationSpecPathParams = {
	specId: string;
};

export type ApiGetMarketplaceIntegrationSpecError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiGetMarketplaceIntegrationSpecVariables = {
	pathParams: ApiGetMarketplaceIntegrationSpecPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetMarketplaceIntegrationSpec = (
	variables: ApiGetMarketplaceIntegrationSpecVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.MarketplaceIntegrationSpecOut,
		ApiGetMarketplaceIntegrationSpecError,
		undefined,
		{},
		{},
		ApiGetMarketplaceIntegrationSpecPathParams
	>({
		url: '/integration/marketplace/specs/{specId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetMarketplaceIntegrationSpec = <
	TData = Schemas.MarketplaceIntegrationSpecOut,
>(
	variables: ApiGetMarketplaceIntegrationSpecVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MarketplaceIntegrationSpecOut,
			ApiGetMarketplaceIntegrationSpecError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.MarketplaceIntegrationSpecOut,
		ApiGetMarketplaceIntegrationSpecError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/marketplace/specs/{specId}/',
			operationId: 'apiGetMarketplaceIntegrationSpec',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetMarketplaceIntegrationSpec(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiDeleteMarketplaceIntegrationSpecPathParams = {
	specId: string;
};

export type ApiDeleteMarketplaceIntegrationSpecError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiDeleteMarketplaceIntegrationSpecVariables = {
	pathParams: ApiDeleteMarketplaceIntegrationSpecPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiDeleteMarketplaceIntegrationSpec = (
	variables: ApiDeleteMarketplaceIntegrationSpecVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		ApiDeleteMarketplaceIntegrationSpecError,
		undefined,
		{},
		{},
		ApiDeleteMarketplaceIntegrationSpecPathParams
	>({
		url: '/integration/marketplace/specs/{specId}/',
		method: 'delete',
		...variables,
		signal,
	});

export const useApiDeleteMarketplaceIntegrationSpec = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			ApiDeleteMarketplaceIntegrationSpecError,
			ApiDeleteMarketplaceIntegrationSpecVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		ApiDeleteMarketplaceIntegrationSpecError,
		ApiDeleteMarketplaceIntegrationSpecVariables
	>({
		mutationFn: (variables: ApiDeleteMarketplaceIntegrationSpecVariables) =>
			fetchApiDeleteMarketplaceIntegrationSpec({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiGetMarketplaceIntegrationSpecVersionsPathParams = {
	specId: string;
};

export type ApiGetMarketplaceIntegrationSpecVersionsQueryParams = {
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ApiGetMarketplaceIntegrationSpecVersionsError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiGetMarketplaceIntegrationSpecVersionsVariables = {
	pathParams: ApiGetMarketplaceIntegrationSpecVersionsPathParams;
	queryParams?: ApiGetMarketplaceIntegrationSpecVersionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetMarketplaceIntegrationSpecVersions = (
	variables: ApiGetMarketplaceIntegrationSpecVersionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedMarketplaceIntegrationSpecVersionOut,
		ApiGetMarketplaceIntegrationSpecVersionsError,
		undefined,
		{},
		ApiGetMarketplaceIntegrationSpecVersionsQueryParams,
		ApiGetMarketplaceIntegrationSpecVersionsPathParams
	>({
		url: '/integration/marketplace/specs/{specId}/versions/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetMarketplaceIntegrationSpecVersions = <
	TData = Schemas.PagedMarketplaceIntegrationSpecVersionOut,
>(
	variables: ApiGetMarketplaceIntegrationSpecVersionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMarketplaceIntegrationSpecVersionOut,
			ApiGetMarketplaceIntegrationSpecVersionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedMarketplaceIntegrationSpecVersionOut,
		ApiGetMarketplaceIntegrationSpecVersionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/marketplace/specs/{specId}/versions/',
			operationId: 'apiGetMarketplaceIntegrationSpecVersions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetMarketplaceIntegrationSpecVersions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiCreateMarketplaceIntegrationSpecVersionPathParams = {
	specId: string;
};

export type ApiCreateMarketplaceIntegrationSpecVersionError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiCreateMarketplaceIntegrationSpecVersionRequestBody = {
	/**
	 * @format binary
	 */
	code_file: Blob;
	detail: Schemas.CreateOrUpdateMarketplaceIntegrationSpecVersionDetail;
};

export type ApiCreateMarketplaceIntegrationSpecVersionVariables = {
	body: ApiCreateMarketplaceIntegrationSpecVersionRequestBody;
	pathParams: ApiCreateMarketplaceIntegrationSpecVersionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiCreateMarketplaceIntegrationSpecVersion = (
	variables: ApiCreateMarketplaceIntegrationSpecVersionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiCreateMarketplaceIntegrationSpecVersionError,
		ApiCreateMarketplaceIntegrationSpecVersionRequestBody,
		{},
		{},
		ApiCreateMarketplaceIntegrationSpecVersionPathParams
	>({
		url: '/integration/marketplace/specs/{specId}/versions/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiCreateMarketplaceIntegrationSpecVersion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.MarketplaceIntegrationSpecVersionOut,
			ApiCreateMarketplaceIntegrationSpecVersionError,
			ApiCreateMarketplaceIntegrationSpecVersionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiCreateMarketplaceIntegrationSpecVersionError,
		ApiCreateMarketplaceIntegrationSpecVersionVariables
	>({
		mutationFn: (
			variables: ApiCreateMarketplaceIntegrationSpecVersionVariables
		) =>
			fetchApiCreateMarketplaceIntegrationSpecVersion({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiGetMarketplaceIntegrationSpecVersionPathParams = {
	specId: string;
	versionId: string;
};

export type ApiGetMarketplaceIntegrationSpecVersionError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiGetMarketplaceIntegrationSpecVersionVariables = {
	pathParams: ApiGetMarketplaceIntegrationSpecVersionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetMarketplaceIntegrationSpecVersion = (
	variables: ApiGetMarketplaceIntegrationSpecVersionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiGetMarketplaceIntegrationSpecVersionError,
		undefined,
		{},
		{},
		ApiGetMarketplaceIntegrationSpecVersionPathParams
	>({
		url: '/integration/marketplace/specs/{specId}/versions/{versionId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiGetMarketplaceIntegrationSpecVersion = <
	TData = Schemas.MarketplaceIntegrationSpecVersionOut,
>(
	variables: ApiGetMarketplaceIntegrationSpecVersionVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MarketplaceIntegrationSpecVersionOut,
			ApiGetMarketplaceIntegrationSpecVersionError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiGetMarketplaceIntegrationSpecVersionError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/marketplace/specs/{specId}/versions/{versionId}/',
			operationId: 'apiGetMarketplaceIntegrationSpecVersion',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetMarketplaceIntegrationSpecVersion(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiUpdateMarketplaceIntegrationSpecVersionPathParams = {
	specId: string;
	versionId: string;
};

export type ApiUpdateMarketplaceIntegrationSpecVersionError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiUpdateMarketplaceIntegrationSpecVersionRequestBody = {
	/**
	 * @format binary
	 */
	code_file?: Blob;
	detail: Schemas.CreateOrUpdateMarketplaceIntegrationSpecVersionDetail;
};

export type ApiUpdateMarketplaceIntegrationSpecVersionVariables = {
	body: ApiUpdateMarketplaceIntegrationSpecVersionRequestBody;
	pathParams: ApiUpdateMarketplaceIntegrationSpecVersionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiUpdateMarketplaceIntegrationSpecVersion = (
	variables: ApiUpdateMarketplaceIntegrationSpecVersionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiUpdateMarketplaceIntegrationSpecVersionError,
		ApiUpdateMarketplaceIntegrationSpecVersionRequestBody,
		{},
		{},
		ApiUpdateMarketplaceIntegrationSpecVersionPathParams
	>({
		url: '/integration/marketplace/specs/{specId}/versions/{versionId}/edit/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiUpdateMarketplaceIntegrationSpecVersion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.MarketplaceIntegrationSpecVersionOut,
			ApiUpdateMarketplaceIntegrationSpecVersionError,
			ApiUpdateMarketplaceIntegrationSpecVersionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiUpdateMarketplaceIntegrationSpecVersionError,
		ApiUpdateMarketplaceIntegrationSpecVersionVariables
	>({
		mutationFn: (
			variables: ApiUpdateMarketplaceIntegrationSpecVersionVariables
		) =>
			fetchApiUpdateMarketplaceIntegrationSpecVersion({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiPreviewMarketplaceIntegrationSpecVersionCodePathParams = {
	specId: string;
	versionId: string;
};

export type ApiPreviewMarketplaceIntegrationSpecVersionCodeError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiPreviewMarketplaceIntegrationSpecVersionCodeVariables = {
	pathParams: ApiPreviewMarketplaceIntegrationSpecVersionCodePathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiPreviewMarketplaceIntegrationSpecVersionCode = (
	variables: ApiPreviewMarketplaceIntegrationSpecVersionCodeVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		string,
		ApiPreviewMarketplaceIntegrationSpecVersionCodeError,
		undefined,
		{},
		{},
		ApiPreviewMarketplaceIntegrationSpecVersionCodePathParams
	>({
		url: '/integration/marketplace/specs/{specId}/versions/{versionId}/code/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiPreviewMarketplaceIntegrationSpecVersionCode = <
	TData = string,
>(
	variables: ApiPreviewMarketplaceIntegrationSpecVersionCodeVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			string,
			ApiPreviewMarketplaceIntegrationSpecVersionCodeError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		string,
		ApiPreviewMarketplaceIntegrationSpecVersionCodeError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/marketplace/specs/{specId}/versions/{versionId}/code/',
			operationId: 'apiPreviewMarketplaceIntegrationSpecVersionCode',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiPreviewMarketplaceIntegrationSpecVersionCode(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiSubmitMarketplaceIntegrationSpecVersionPathParams = {
	specId: string;
	versionId: string;
	action: string;
};

export type ApiSubmitMarketplaceIntegrationSpecVersionError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiSubmitMarketplaceIntegrationSpecVersionVariables = {
	pathParams: ApiSubmitMarketplaceIntegrationSpecVersionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiSubmitMarketplaceIntegrationSpecVersion = (
	variables: ApiSubmitMarketplaceIntegrationSpecVersionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiSubmitMarketplaceIntegrationSpecVersionError,
		undefined,
		{},
		{},
		ApiSubmitMarketplaceIntegrationSpecVersionPathParams
	>({
		url: '/integration/marketplace/specs/{specId}/versions/{versionId}/submission/{action}/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiSubmitMarketplaceIntegrationSpecVersion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.MarketplaceIntegrationSpecVersionOut,
			ApiSubmitMarketplaceIntegrationSpecVersionError,
			ApiSubmitMarketplaceIntegrationSpecVersionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiSubmitMarketplaceIntegrationSpecVersionError,
		ApiSubmitMarketplaceIntegrationSpecVersionVariables
	>({
		mutationFn: (
			variables: ApiSubmitMarketplaceIntegrationSpecVersionVariables
		) =>
			fetchApiSubmitMarketplaceIntegrationSpecVersion({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiListMarketplaceSpecVersionsInternallyQueryParams = {
	/**
	 * @default 1
	 */
	page?: number | null;
};

export type ApiListMarketplaceSpecVersionsInternallyError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiListMarketplaceSpecVersionsInternallyVariables = {
	queryParams?: ApiListMarketplaceSpecVersionsInternallyQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiListMarketplaceSpecVersionsInternally = (
	variables: ApiListMarketplaceSpecVersionsInternallyVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.PagedMarketplaceIntegrationSpecVersionOut,
		ApiListMarketplaceSpecVersionsInternallyError,
		undefined,
		{},
		ApiListMarketplaceSpecVersionsInternallyQueryParams,
		{}
	>({
		url: '/integration/marketplace/versions_for_review/',
		method: 'get',
		...variables,
		signal,
	});

export const useApiListMarketplaceSpecVersionsInternally = <
	TData = Schemas.PagedMarketplaceIntegrationSpecVersionOut,
>(
	variables: ApiListMarketplaceSpecVersionsInternallyVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMarketplaceIntegrationSpecVersionOut,
			ApiListMarketplaceSpecVersionsInternallyError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.PagedMarketplaceIntegrationSpecVersionOut,
		ApiListMarketplaceSpecVersionsInternallyError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/integration/marketplace/versions_for_review/',
			operationId: 'apiListMarketplaceSpecVersionsInternally',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiListMarketplaceSpecVersionsInternally(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type ApiReviewMarketplaceIntegrationSpecVersionPathParams = {
	specId: string;
	versionId: string;
};

export type ApiReviewMarketplaceIntegrationSpecVersionError =
	Fetcher.ErrorWrapper<undefined>;

export type ApiReviewMarketplaceIntegrationSpecVersionVariables = {
	body: Schemas.ReviewMarketplaceIntegrationSpecVersionRequest;
	pathParams: ApiReviewMarketplaceIntegrationSpecVersionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApiReviewMarketplaceIntegrationSpecVersion = (
	variables: ApiReviewMarketplaceIntegrationSpecVersionVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiReviewMarketplaceIntegrationSpecVersionError,
		Schemas.ReviewMarketplaceIntegrationSpecVersionRequest,
		{},
		{},
		ApiReviewMarketplaceIntegrationSpecVersionPathParams
	>({
		url: '/integration/marketplace/specs/{specId}/versions/{versionId}/review/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiReviewMarketplaceIntegrationSpecVersion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.MarketplaceIntegrationSpecVersionOut,
			ApiReviewMarketplaceIntegrationSpecVersionError,
			ApiReviewMarketplaceIntegrationSpecVersionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.MarketplaceIntegrationSpecVersionOut,
		ApiReviewMarketplaceIntegrationSpecVersionError,
		ApiReviewMarketplaceIntegrationSpecVersionVariables
	>({
		mutationFn: (
			variables: ApiReviewMarketplaceIntegrationSpecVersionVariables
		) =>
			fetchApiReviewMarketplaceIntegrationSpecVersion({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiCheckValidUrlError = Fetcher.ErrorWrapper<undefined>;

export type ApiCheckValidUrlVariables = {
	body: Schemas.CheckValidUrlRequest;
} & ApiContext['fetcherOptions'];

export const fetchApiCheckValidUrl = (
	variables: ApiCheckValidUrlVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.CheckValidUrlResponse,
		ApiCheckValidUrlError,
		Schemas.CheckValidUrlRequest,
		{},
		{},
		{}
	>({
		url: '/integration/marketplace/check_valid_url/',
		method: 'post',
		...variables,
		signal,
	});

export const useApiCheckValidUrl = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.CheckValidUrlResponse,
			ApiCheckValidUrlError,
			ApiCheckValidUrlVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Schemas.CheckValidUrlResponse,
		ApiCheckValidUrlError,
		ApiCheckValidUrlVariables
	>({
		mutationFn: (variables: ApiCheckValidUrlVariables) =>
			fetchApiCheckValidUrl({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type RetrieveQualityPathParams = {
	entityId: string;
};

export type RetrieveQualityError = Fetcher.ErrorWrapper<undefined>;

export type RetrieveQualityVariables = {
	pathParams: RetrieveQualityPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRetrieveQuality = (
	variables: RetrieveQualityVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.DataQualityScore | null,
		RetrieveQualityError,
		undefined,
		{},
		{},
		RetrieveQualityPathParams
	>({
		url: '/quality/{entityId}/quality/',
		method: 'get',
		...variables,
		signal,
	});

export const useRetrieveQuality = <TData = Schemas.DataQualityScore | null,>(
	variables: RetrieveQualityVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DataQualityScore | null,
			RetrieveQualityError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.DataQualityScore | null,
		RetrieveQualityError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/quality/{entityId}/quality/',
			operationId: 'retrieveQuality',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRetrieveQuality({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type RetrieveAggregateQualityQueryParams = {
	team_id?: string | null;
	filters?: string | null;
};

export type RetrieveAggregateQualityError = Fetcher.ErrorWrapper<undefined>;

export type RetrieveAggregateQualityVariables = {
	queryParams?: RetrieveAggregateQualityQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchRetrieveAggregateQuality = (
	variables: RetrieveAggregateQualityVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.DataQualityScore,
		RetrieveAggregateQualityError,
		undefined,
		{},
		RetrieveAggregateQualityQueryParams,
		{}
	>({ url: '/quality/', method: 'get', ...variables, signal });

export const useRetrieveAggregateQuality = <TData = Schemas.DataQualityScore,>(
	variables: RetrieveAggregateQualityVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DataQualityScore,
			RetrieveAggregateQualityError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.DataQualityScore,
		RetrieveAggregateQualityError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/quality/',
			operationId: 'retrieveAggregateQuality',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRetrieveAggregateQuality(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type RetrieveTimeseriesQueryParams = {
	from_date: string;
	to_date: string;
	team_id?: string | null;
	integration_id?: string | null;
};

export type RetrieveTimeseriesError = Fetcher.ErrorWrapper<undefined>;

export type RetrieveTimeseriesVariables = {
	queryParams: RetrieveTimeseriesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchRetrieveTimeseries = (
	variables: RetrieveTimeseriesVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.DataQualityTimeseries,
		RetrieveTimeseriesError,
		undefined,
		{},
		RetrieveTimeseriesQueryParams,
		{}
	>({ url: '/quality/timeseries/', method: 'get', ...variables, signal });

export const useRetrieveTimeseries = <TData = Schemas.DataQualityTimeseries,>(
	variables: RetrieveTimeseriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DataQualityTimeseries,
			RetrieveTimeseriesError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.DataQualityTimeseries,
		RetrieveTimeseriesError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/quality/timeseries/',
			operationId: 'retrieveTimeseries',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRetrieveTimeseries({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type RetrieveQualitySuggestionsQueryParams = {
	team_id?: string | null;
	filters?: string | null;
};

export type RetrieveQualitySuggestionsError = Fetcher.ErrorWrapper<undefined>;

export type RetrieveQualitySuggestionsResponse =
	Schemas.DataQualitySuggestion[];

export type RetrieveQualitySuggestionsVariables = {
	queryParams?: RetrieveQualitySuggestionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchRetrieveQualitySuggestions = (
	variables: RetrieveQualitySuggestionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		RetrieveQualitySuggestionsResponse,
		RetrieveQualitySuggestionsError,
		undefined,
		{},
		RetrieveQualitySuggestionsQueryParams,
		{}
	>({ url: '/quality/suggestions/', method: 'get', ...variables, signal });

export const useRetrieveQualitySuggestions = <
	TData = RetrieveQualitySuggestionsResponse,
>(
	variables: RetrieveQualitySuggestionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RetrieveQualitySuggestionsResponse,
			RetrieveQualitySuggestionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		RetrieveQualitySuggestionsResponse,
		RetrieveQualitySuggestionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/quality/suggestions/',
			operationId: 'retrieveQualitySuggestions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRetrieveQualitySuggestions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type RetrieveQualityEntitySuggestionsPathParams = {
	entityId: string;
};

export type RetrieveQualityEntitySuggestionsError =
	Fetcher.ErrorWrapper<undefined>;

export type RetrieveQualityEntitySuggestionsResponse =
	Schemas.DataQualitySuggestion[];

export type RetrieveQualityEntitySuggestionsVariables = {
	pathParams: RetrieveQualityEntitySuggestionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRetrieveQualityEntitySuggestions = (
	variables: RetrieveQualityEntitySuggestionsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		RetrieveQualityEntitySuggestionsResponse,
		RetrieveQualityEntitySuggestionsError,
		undefined,
		{},
		{},
		RetrieveQualityEntitySuggestionsPathParams
	>({
		url: '/quality/{entityId}/suggestions/',
		method: 'get',
		...variables,
		signal,
	});

export const useRetrieveQualityEntitySuggestions = <
	TData = RetrieveQualityEntitySuggestionsResponse,
>(
	variables: RetrieveQualityEntitySuggestionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			RetrieveQualityEntitySuggestionsResponse,
			RetrieveQualityEntitySuggestionsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		RetrieveQualityEntitySuggestionsResponse,
		RetrieveQualityEntitySuggestionsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/quality/{entityId}/suggestions/',
			operationId: 'retrieveQualityEntitySuggestions',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchRetrieveQualityEntitySuggestions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type GenerateDataQualityImageError = Fetcher.ErrorWrapper<undefined>;

export type GenerateDataQualityImageVariables = {
	body: Schemas.DataQualityScore;
} & ApiContext['fetcherOptions'];

export const fetchGenerateDataQualityImage = (
	variables: GenerateDataQualityImageVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Blob,
		GenerateDataQualityImageError,
		Schemas.DataQualityScore,
		{},
		{},
		{}
	>({ url: '/quality/image/', method: 'post', ...variables, signal });

export const useGenerateDataQualityImage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Blob,
			GenerateDataQualityImageError,
			GenerateDataQualityImageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		Blob,
		GenerateDataQualityImageError,
		GenerateDataQualityImageVariables
	>({
		mutationFn: (variables: GenerateDataQualityImageVariables) =>
			fetchGenerateDataQualityImage({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetIncludedInQualityGovernanceError =
	Fetcher.ErrorWrapper<undefined>;

export type GetIncludedInQualityGovernanceResponse = Schemas.Filter[];

export type GetIncludedInQualityGovernanceVariables =
	ApiContext['fetcherOptions'];

export const fetchGetIncludedInQualityGovernance = (
	variables: GetIncludedInQualityGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetIncludedInQualityGovernanceResponse,
		GetIncludedInQualityGovernanceError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/quality/preferences/governance/included/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetIncludedInQualityGovernance = <
	TData = GetIncludedInQualityGovernanceResponse,
>(
	variables: GetIncludedInQualityGovernanceVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetIncludedInQualityGovernanceResponse,
			GetIncludedInQualityGovernanceError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetIncludedInQualityGovernanceResponse,
		GetIncludedInQualityGovernanceError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/quality/preferences/governance/included/',
			operationId: 'getIncludedInQualityGovernance',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetIncludedInQualityGovernance(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type SetIncludedInQualityGovernanceError =
	Fetcher.ErrorWrapper<undefined>;

export type SetIncludedInQualityGovernanceRequestBody = Schemas.Filter[];

export type SetIncludedInQualityGovernanceVariables = {
	body?: SetIncludedInQualityGovernanceRequestBody;
} & ApiContext['fetcherOptions'];

export const fetchSetIncludedInQualityGovernance = (
	variables: SetIncludedInQualityGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		SetIncludedInQualityGovernanceError,
		SetIncludedInQualityGovernanceRequestBody,
		{},
		{},
		{}
	>({
		url: '/quality/preferences/governance/included/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetIncludedInQualityGovernance = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			SetIncludedInQualityGovernanceError,
			SetIncludedInQualityGovernanceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		SetIncludedInQualityGovernanceError,
		SetIncludedInQualityGovernanceVariables
	>({
		mutationFn: (variables: SetIncludedInQualityGovernanceVariables) =>
			fetchSetIncludedInQualityGovernance({ ...fetcherOptions, ...variables }),
		...options,
	});
};

export type GetExcludedFromQualityGovernanceError =
	Fetcher.ErrorWrapper<undefined>;

export type GetExcludedFromQualityGovernanceResponse = Schemas.Filter[];

export type GetExcludedFromQualityGovernanceVariables =
	ApiContext['fetcherOptions'];

export const fetchGetExcludedFromQualityGovernance = (
	variables: GetExcludedFromQualityGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		GetExcludedFromQualityGovernanceResponse,
		GetExcludedFromQualityGovernanceError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/quality/preferences/governance/excluded/',
		method: 'get',
		...variables,
		signal,
	});

export const useGetExcludedFromQualityGovernance = <
	TData = GetExcludedFromQualityGovernanceResponse,
>(
	variables: GetExcludedFromQualityGovernanceVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetExcludedFromQualityGovernanceResponse,
			GetExcludedFromQualityGovernanceError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		GetExcludedFromQualityGovernanceResponse,
		GetExcludedFromQualityGovernanceError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/quality/preferences/governance/excluded/',
			operationId: 'getExcludedFromQualityGovernance',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchGetExcludedFromQualityGovernance(
				{ ...fetcherOptions, ...variables },
				signal
			),
		...options,
		...queryOptions,
	});
};

export type SetExcludedFromQualityGovernanceError =
	Fetcher.ErrorWrapper<undefined>;

export type SetExcludedFromQualityGovernanceRequestBody = Schemas.Filter[];

export type SetExcludedFromQualityGovernanceVariables = {
	body?: SetExcludedFromQualityGovernanceRequestBody;
} & ApiContext['fetcherOptions'];

export const fetchSetExcludedFromQualityGovernance = (
	variables: SetExcludedFromQualityGovernanceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		undefined,
		SetExcludedFromQualityGovernanceError,
		SetExcludedFromQualityGovernanceRequestBody,
		{},
		{},
		{}
	>({
		url: '/quality/preferences/governance/excluded/',
		method: 'post',
		...variables,
		signal,
	});

export const useSetExcludedFromQualityGovernance = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			undefined,
			SetExcludedFromQualityGovernanceError,
			SetExcludedFromQualityGovernanceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useApiContext();
	return reactQuery.useMutation<
		undefined,
		SetExcludedFromQualityGovernanceError,
		SetExcludedFromQualityGovernanceVariables
	>({
		mutationFn: (variables: SetExcludedFromQualityGovernanceVariables) =>
			fetchSetExcludedFromQualityGovernance({
				...fetcherOptions,
				...variables,
			}),
		...options,
	});
};

export type ApiGetExtensionResourceQueryParams = {
	url?: string | null;
	entity_type?: string | null;
	database_name?: string | null;
	schema_name?: string | null;
	table_name?: string | null;
	project_id?: string | null;
};

export type ApiGetExtensionResourceError = Fetcher.ErrorWrapper<undefined>;

export type ApiGetExtensionResourceVariables = {
	queryParams?: ApiGetExtensionResourceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchApiGetExtensionResource = (
	variables: ApiGetExtensionResourceVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		Schemas.ResourceOut2 | null,
		ApiGetExtensionResourceError,
		undefined,
		{},
		ApiGetExtensionResourceQueryParams,
		{}
	>({ url: '/extension/resource/', method: 'get', ...variables, signal });

export const useApiGetExtensionResource = <
	TData = Schemas.ResourceOut2 | null,
>(
	variables: ApiGetExtensionResourceVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ResourceOut2 | null,
			ApiGetExtensionResourceError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		Schemas.ResourceOut2 | null,
		ApiGetExtensionResourceError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/extension/resource/',
			operationId: 'apiGetExtensionResource',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchApiGetExtensionResource({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type ListAuditItemsPathParams = {
	resourceType: string;
	resourceId: string;
};

export type ListAuditItemsError = Fetcher.ErrorWrapper<undefined>;

export type ListAuditItemsResponse = Schemas.AuditItemOut[];

export type ListAuditItemsVariables = {
	pathParams: ListAuditItemsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListAuditItems = (
	variables: ListAuditItemsVariables,
	signal?: AbortSignal
) =>
	apiFetch<
		ListAuditItemsResponse,
		ListAuditItemsError,
		undefined,
		{},
		{},
		ListAuditItemsPathParams
	>({
		url: '/audit/{resourceType}/{resourceId}/',
		method: 'get',
		...variables,
		signal,
	});

export const useListAuditItems = <TData = ListAuditItemsResponse,>(
	variables: ListAuditItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ListAuditItemsResponse,
			ListAuditItemsError,
			TData
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
	return reactQuery.useQuery<
		ListAuditItemsResponse,
		ListAuditItemsError,
		TData
	>({
		queryKey: queryKeyFn({
			path: '/audit/{resourceType}/{resourceId}/',
			operationId: 'listAuditItems',
			variables,
		}),
		queryFn: ({ signal }) =>
			fetchListAuditItems({ ...fetcherOptions, ...variables }, signal),
		...options,
		...queryOptions,
	});
};

export type QueryOperation =
	| {
			path: '/resource/all/{entityId}/permissions_v2/grouped_permissions/';
			operationId: 'apiGetEntityGroupedPermissions';
			variables: ApiGetEntityGroupedPermissionsVariables;
	  }
	| {
			path: '/resource/all/{entityId}/permissions_v2/has_custom/';
			operationId: 'apiEntityHasCustomPermissions';
			variables: ApiEntityHasCustomPermissionsVariables;
	  }
	| {
			path: '/resource/preview_v2/table/{entityId}/';
			operationId: 'apiGetTablePreview';
			variables: ApiGetTablePreviewVariables;
	  }
	| {
			path: '/resource/preview_v2/url/{entityId}/';
			operationId: 'apiGetUrlPreview';
			variables: ApiGetUrlPreviewVariables;
	  }
	| {
			path: '/resource/all_v2/{entityId}/stakeholders/';
			operationId: 'apiGetEntityStakeholders';
			variables: ApiGetEntityStakeholdersVariables;
	  }
	| {
			path: '/admin/background_job/';
			operationId: 'apiGetBackgroundJob';
			variables: ApiGetBackgroundJobVariables;
	  }
	| {
			path: '/admin/github/latest_git_info/';
			operationId: 'getLatestGitInfo';
			variables: GetLatestGitInfoVariables;
	  }
	| {
			path: '/admin/github/commit_info/{databuilderJobId}/';
			operationId: 'getCommitInfo';
			variables: GetCommitInfoVariables;
	  }
	| {
			path: '/admin/inspect_api/{integrationId}/';
			operationId: 'apiGetIntegrationApiSpec';
			variables: ApiGetIntegrationApiSpecVariables;
	  }
	| {
			path: '/admin/internal_integration_status/status/';
			operationId: 'apiGetInternalIntegrationStatus';
			variables: ApiGetInternalIntegrationStatusVariables;
	  }
	| {
			path: '/admin/internal_integration_status/job_breakdown/{databuilderJobId}/';
			operationId: 'apiGetInternalIntegrationJobBreakdown';
			variables: ApiGetInternalIntegrationJobBreakdownVariables;
	  }
	| {
			path: '/admin/internal_integration_status/in_trial_workspaces/';
			operationId: 'getInTrialWorkspaces';
			variables: GetInTrialWorkspacesVariables;
	  }
	| {
			path: '/admin/internal_query_actions/is_superadmin/';
			operationId: 'isSuperadmin';
			variables: IsSuperadminVariables;
	  }
	| {
			path: '/admin/linear/get_linear_tickets/{title}/';
			operationId: 'getLinearTickets';
			variables: GetLinearTicketsVariables;
	  }
	| {
			path: '/collection/collections/{entityId}/entities_count/';
			operationId: 'apiGetCollectionEntitiesCount';
			variables: ApiGetCollectionEntitiesCountVariables;
	  }
	| {
			path: '/search/v2/meta/';
			operationId: 'getSearchMeta';
			variables: GetSearchMetaVariables;
	  }
	| {
			path: '/auth/teams/';
			operationId: 'apiListTeams';
			variables: ApiListTeamsVariables;
	  }
	| {
			path: '/auth/teams/{teamId}/';
			operationId: 'apiGetTeam';
			variables: ApiGetTeamVariables;
	  }
	| {
			path: '/auth/teams/{teamId}/my_memberships/';
			operationId: 'apiGetMyMemberships';
			variables: ApiGetMyMembershipsVariables;
	  }
	| {
			path: '/auth/teams/{teamId}/empty_state/{sectionName}/';
			operationId: 'apiGetTeamEmptyState';
			variables: ApiGetTeamEmptyStateVariables;
	  }
	| {
			path: '/auth/teams/{teamId}/users/';
			operationId: 'apiGetTeamListUsers';
			variables: ApiGetTeamListUsersVariables;
	  }
	| {
			path: '/auth/teams/{teamId}/memberships/';
			operationId: 'apiListMemberships';
			variables: ApiListMembershipsVariables;
	  }
	| {
			path: '/auth/api_keys/';
			operationId: 'apiListApiKeys';
			variables: ApiListApiKeysVariables;
	  }
	| {
			path: '/forms/';
			operationId: 'listForms';
			variables: ListFormsVariables;
	  }
	| {
			path: '/forms/{formId}/';
			operationId: 'retrieveForm';
			variables: RetrieveFormVariables;
	  }
	| {
			path: '/submissions/';
			operationId: 'listFormSubmissions';
			variables: ListFormSubmissionsVariables;
	  }
	| {
			path: '/submissions/draft/';
			operationId: 'listDraftFormSubmission';
			variables: ListDraftFormSubmissionVariables;
	  }
	| {
			path: '/submissions/{formSubmissionId}/';
			operationId: 'retrieveFormSubmission';
			variables: RetrieveFormSubmissionVariables;
	  }
	| {
			path: '/table/columns_v2/types/';
			operationId: 'apiGetColumnTypes';
			variables: ApiGetColumnTypesVariables;
	  }
	| {
			path: '/table/columns_v2/pii_word_list/';
			operationId: 'apiGetPiiWordList';
			variables: ApiGetPiiWordListVariables;
	  }
	| {
			path: '/table/columns_v2/pii/';
			operationId: 'apiGetPiiColumns';
			variables: ApiGetPiiColumnsVariables;
	  }
	| {
			path: '/integration/integrations/{integrationId}/group_items/';
			operationId: 'apiGetIntegrationGroupItems';
			variables: ApiGetIntegrationGroupItemsVariables;
	  }
	| {
			path: '/integration/integrations/group_items/refresh_support/';
			operationId: 'apiGroupItemsRefreshSupport';
			variables: ApiGroupItemsRefreshSupportVariables;
	  }
	| {
			path: '/integration/integrations/{integrationId}/group_settings/';
			operationId: 'apiGetIntegrationGroupSettings';
			variables: ApiGetIntegrationGroupSettingsVariables;
	  }
	| {
			path: '/integration/integrations/{integrationId}/excluded-titles/';
			operationId: 'getIntegrationExcludedTitles';
			variables: GetIntegrationExcludedTitlesVariables;
	  }
	| {
			path: '/integration/dbt/{integrationId}/failing_tests/';
			operationId: 'apiGetFailingTests';
			variables: ApiGetFailingTestsVariables;
	  }
	| {
			path: '/lineage/';
			operationId: 'apiListLineage';
			variables: ApiListLineageVariables;
	  }
	| {
			path: '/lineage/impacted/{fromId}/ids/';
			operationId: 'apiRetrieveImpactedIds';
			variables: ApiRetrieveImpactedIdsVariables;
	  }
	| {
			path: '/lineage/impacted/{fromId}/paths/';
			operationId: 'apiRetrieveImpactedPaths';
			variables: ApiRetrieveImpactedPathsVariables;
	  }
	| {
			path: '/lineage/impacted/{fromId}/csv/';
			operationId: 'apiExportImpactedCsv';
			variables: ApiExportImpactedCsvVariables;
	  }
	| {
			path: '/lineage/relations/';
			operationId: 'apiListResourceRelations';
			variables: ApiListResourceRelationsVariables;
	  }
	| {
			path: '/lineage/relations/{relationId}/';
			operationId: 'apiRetrieveResourceRelation';
			variables: ApiRetrieveResourceRelationVariables;
	  }
	| {
			path: '/lineage/manual/';
			operationId: 'apiListManualLineages';
			variables: ApiListManualLineagesVariables;
	  }
	| {
			path: '/lineage/manual/{lineageId}/';
			operationId: 'apiRetrieveManualLineage';
			variables: ApiRetrieveManualLineageVariables;
	  }
	| {
			path: '/lineage/{lineageId}/';
			operationId: 'apiRetrieveLineage';
			variables: ApiRetrieveLineageVariables;
	  }
	| {
			path: '/report/{reportId}/create_csvs/';
			operationId: 'createCsvs';
			variables: CreateCsvsVariables;
	  }
	| {
			path: '/ai/embedded_prompt/';
			operationId: 'listPrompts';
			variables: ListPromptsVariables;
	  }
	| {
			path: '/ai/embedded_prompt/suggestions/';
			operationId: 'suggestedPrompts';
			variables: SuggestedPromptsVariables;
	  }
	| {
			path: '/ai/embedded_prompt/{promptId}/';
			operationId: 'retrievePrompt';
			variables: RetrievePromptVariables;
	  }
	| {
			path: '/ai/preferences/tools/';
			operationId: 'getAgentTools';
			variables: GetAgentToolsVariables;
	  }
	| {
			path: '/ai/preferences/instructions/';
			operationId: 'getCustomInstructions';
			variables: GetCustomInstructionsVariables;
	  }
	| {
			path: '/ai/preferences/descriptions/';
			operationId: 'getCustomDescriptionStyle';
			variables: GetCustomDescriptionStyleVariables;
	  }
	| {
			path: '/ai/preferences/governance/included/';
			operationId: 'getIncludedInAiGovernance';
			variables: GetIncludedInAiGovernanceVariables;
	  }
	| {
			path: '/ai/preferences/governance/excluded/';
			operationId: 'getExcludedFromAiGovernance';
			variables: GetExcludedFromAiGovernanceVariables;
	  }
	| {
			path: '/ai/preferences/governance/entities/included/';
			operationId: 'getEntityIncludedInAiGovernance';
			variables: GetEntityIncludedInAiGovernanceVariables;
	  }
	| {
			path: '/ai/personas/';
			operationId: 'getPersonas';
			variables: GetPersonasVariables;
	  }
	| {
			path: '/ai/personas/{personaId}/';
			operationId: 'getPersona';
			variables: GetPersonaVariables;
	  }
	| {
			path: '/ai/personas/{personaId}/included_filters/';
			operationId: 'getIncludedFilters';
			variables: GetIncludedFiltersVariables;
	  }
	| {
			path: '/ai/personas/{personaId}/excluded_filters/';
			operationId: 'getExcludedFilters';
			variables: GetExcludedFiltersVariables;
	  }
	| {
			path: '/query/queries/creation_query/{entityId}/';
			operationId: 'apiGetCreationQuery';
			variables: ApiGetCreationQueryVariables;
	  }
	| {
			path: '/query/queries/v2/workspace/';
			operationId: 'apiGetQueries';
			variables: ApiGetQueriesVariables;
	  }
	| {
			path: '/query/queries/v2/{entityId}/';
			operationId: 'apiGetEntityQueries';
			variables: ApiGetEntityQueriesVariables;
	  }
	| {
			path: '/query/queries/v2/{queryId}/executions/';
			operationId: 'apiGetQueryExecutions';
			variables: ApiGetQueryExecutionsVariables;
	  }
	| {
			path: '/query/queries/v2/{queryId}/metadata/';
			operationId: 'apiGetQueryMetadata';
			variables: ApiGetQueryMetadataVariables;
	  }
	| {
			path: '/query/queries/v2/{queryId}/charts/';
			operationId: 'apiGetQueryCharts';
			variables: ApiGetQueryChartsVariables;
	  }
	| {
			path: '/monitor/incidents/jira/is_jira_integrated/';
			operationId: 'isJiraIntegrated';
			variables: IsJiraIntegratedVariables;
	  }
	| {
			path: '/monitor/incidents/jira/get_jira_information/';
			operationId: 'getJiraInformation';
			variables: GetJiraInformationVariables;
	  }
	| {
			path: '/integration/marketplace/specs/';
			operationId: 'apiListMarketplaceIntegrationSpecs';
			variables: ApiListMarketplaceIntegrationSpecsVariables;
	  }
	| {
			path: '/integration/marketplace/specs/{specId}/';
			operationId: 'apiGetMarketplaceIntegrationSpec';
			variables: ApiGetMarketplaceIntegrationSpecVariables;
	  }
	| {
			path: '/integration/marketplace/specs/{specId}/versions/';
			operationId: 'apiGetMarketplaceIntegrationSpecVersions';
			variables: ApiGetMarketplaceIntegrationSpecVersionsVariables;
	  }
	| {
			path: '/integration/marketplace/specs/{specId}/versions/{versionId}/';
			operationId: 'apiGetMarketplaceIntegrationSpecVersion';
			variables: ApiGetMarketplaceIntegrationSpecVersionVariables;
	  }
	| {
			path: '/integration/marketplace/specs/{specId}/versions/{versionId}/code/';
			operationId: 'apiPreviewMarketplaceIntegrationSpecVersionCode';
			variables: ApiPreviewMarketplaceIntegrationSpecVersionCodeVariables;
	  }
	| {
			path: '/integration/marketplace/versions_for_review/';
			operationId: 'apiListMarketplaceSpecVersionsInternally';
			variables: ApiListMarketplaceSpecVersionsInternallyVariables;
	  }
	| {
			path: '/quality/{entityId}/quality/';
			operationId: 'retrieveQuality';
			variables: RetrieveQualityVariables;
	  }
	| {
			path: '/quality/';
			operationId: 'retrieveAggregateQuality';
			variables: RetrieveAggregateQualityVariables;
	  }
	| {
			path: '/quality/timeseries/';
			operationId: 'retrieveTimeseries';
			variables: RetrieveTimeseriesVariables;
	  }
	| {
			path: '/quality/suggestions/';
			operationId: 'retrieveQualitySuggestions';
			variables: RetrieveQualitySuggestionsVariables;
	  }
	| {
			path: '/quality/{entityId}/suggestions/';
			operationId: 'retrieveQualityEntitySuggestions';
			variables: RetrieveQualityEntitySuggestionsVariables;
	  }
	| {
			path: '/quality/preferences/governance/included/';
			operationId: 'getIncludedInQualityGovernance';
			variables: GetIncludedInQualityGovernanceVariables;
	  }
	| {
			path: '/quality/preferences/governance/excluded/';
			operationId: 'getExcludedFromQualityGovernance';
			variables: GetExcludedFromQualityGovernanceVariables;
	  }
	| {
			path: '/extension/resource/';
			operationId: 'apiGetExtensionResource';
			variables: ApiGetExtensionResourceVariables;
	  }
	| {
			path: '/audit/{resourceType}/{resourceId}/';
			operationId: 'listAuditItems';
			variables: ListAuditItemsVariables;
	  };
