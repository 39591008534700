import { observer } from 'mobx-react-lite';
import { space } from '@repo/theme/primitives';
import HorizontalResizeHandle from '../HorizontalResizeHandle';
import {
	COLLAPSED_NAV_SIDEBAR_WIDTH,
	EXPANDED_NAV_MAX_SIDEBAR_WIDTH,
	EXPANDED_NAV_MIN_SIDEBAR_WIDTH,
	NAV_SIDEBAR_TRIGGER_WIDTH,
	SIDEBAR_COLOR,
} from './constants';
import { sideBarStore } from './SideBar/store';

interface ResizeSideBarProps {
	onActiveChange: (active: boolean) => void;
	onHoverChange: (hover: boolean) => void;
}

function ResizeSideBar({ onActiveChange, onHoverChange }: ResizeSideBarProps) {
	const handleResize = (updatedWidth: number) => {
		const { collapsed } = sideBarStore;

		if (
			(collapsed && updatedWidth >= NAV_SIDEBAR_TRIGGER_WIDTH) ||
			(!collapsed && updatedWidth < NAV_SIDEBAR_TRIGGER_WIDTH)
		) {
			sideBarStore.setCollapsed(!collapsed);
		}

		if (
			!collapsed &&
			updatedWidth >= EXPANDED_NAV_MIN_SIDEBAR_WIDTH &&
			updatedWidth <= EXPANDED_NAV_MAX_SIDEBAR_WIDTH
		) {
			sideBarStore.setWidth(updatedWidth);
		}
	};

	return (
		<HorizontalResizeHandle
			handleSize={space[3]}
			minWidth={COLLAPSED_NAV_SIDEBAR_WIDTH}
			maxWidth={EXPANDED_NAV_MAX_SIDEBAR_WIDTH}
			backgroundColor={SIDEBAR_COLOR}
			onResize={handleResize}
			onActiveChange={onActiveChange}
			onHoverChange={onHoverChange}
		/>
	);
}

export default observer(ResizeSideBar);
