import { Anchor, createStyles, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Icon } from '@repo/foundations';
import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { getUseAddMonitorItemsQueryKey, useCreateMonitor } from '../../api';
import { CATALOG_COLUMN_MAPPING } from '../CatalogView/constants';
import { useAddMonitorStoreContext } from './context';

interface AddMonitorFooterProps {
	onClose: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	root: {
		border: 'thin solid',
		borderColor: theme.other.getColor('border/inverse/active'),
	},
}));

function AddMonitorFooter({ onClose }: AddMonitorFooterProps) {
	const { classes } = useStyles();
	const queryClient = useQueryClient();

	const store = useAddMonitorStoreContext();
	const { mutateAsync: createMonitor, isLoading } = useCreateMonitor({});

	let buttonText = 'Add monitors';

	if (store.getFormValue('selected').length === 1) {
		buttonText = 'Add monitor';
	}

	if (Number(store.getFormValue('selected').length) > 1) {
		buttonText = `Add ${store.getFormValue('selected').length} monitors`;
	}

	const handleAddMonitors = async () => {
		const promises = store.monitorsToCreate.map((data) =>
			createMonitor({
				data,
			})
		);

		await Promise.allSettled(promises);

		showNotification({
			title: `Added ${promises.length} monitors`,
			message: `Successfully added ${promises.length} monitors`,
			icon: <Icon name="check" />,
			color: 'success',
		});

		await queryClient.invalidateQueries({
			queryKey: getUseAddMonitorItemsQueryKey({
				type: store.type,
				id: store.id,
				metricType: store.getFormValue('metricType'),
				page: store.page,
			}),
		});

		CATALOG_COLUMN_MAPPING.monitors?.server?.invalidateCache?.();

		onClose();
	};

	return (
		<Group className={classes.root} position="apart" p="md">
			<Anchor
				size="xs"
				href="https://docs.secoda.co/features/monitoring"
				target="_blank"
				rel="noopener noreferrer"
			>
				Learn more
			</Anchor>
			<Group position="right">
				<Button variant="default" size="md" onClick={onClose}>
					Cancel
				</Button>
				<Button
					variant="primary"
					size="md"
					onClick={handleAddMonitors}
					loading={isLoading}
					disabled={isLoading || !store.isFormValid}
				>
					{buttonText}
				</Button>
			</Group>
		</Group>
	);
}

export default observer(AddMonitorFooter);
