import { isEmpty } from 'lodash-es';
import { Text } from '../Text';

interface TextInputHelpProps {
	help?: string;
	error?: string;
}

export function TextInputHelp({ help, error }: TextInputHelpProps) {
	if (isEmpty(help) || !isEmpty(error)) {
		return null;
	}

	return (
		<Text size="sm" color="text/secondary/default">
			{help}
		</Text>
	);
}
