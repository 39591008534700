import {
	Avatar,
	FileButton,
	Flex,
	Group,
	Input,
	Image,
	useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { Button, Text } from '@repo/foundations';

interface ImageUploadProps {
	callback: (file: File) => void;
	label: string;
	placeholder: string;
	renderAvatar?: boolean;
}

export function ImageUpload({
	label,
	placeholder,
	renderAvatar,
	callback,
}: ImageUploadProps) {
	const [url, setUrl] = useState<string | null>(null);
	const theme = useMantineTheme();

	const handleClickUpload = async (file: File) => {
		setUrl(URL.createObjectURL(file));
		callback(file);
	};

	return (
		<Input.Wrapper
			label={
				<Flex gap="3xs">
					<Text weight="semibold" size="sm">
						{label}
					</Text>
					<Text size="sm">(Optional)</Text>
				</Flex>
			}
		>
			<Group spacing="sm">
				{renderAvatar ? (
					<Avatar
						styles={{
							image: {
								objectFit: 'contain',
							},
							root: {
								backgroundColor: 'transparent',
							},
						}}
						src={url ? url : placeholder}
						size="xl"
					/>
				) : (
					<Image
						src={url ? url : placeholder}
						width={theme.other.space[10]}
						height={theme.other.space[10]}
						radius={theme.other.space[1]}
					/>
				)}

				<FileButton onChange={handleClickUpload} accept="image/png,image/jpeg">
					{(props) => (
						<Button size="sm" leftIconName="upload" {...props}>
							Upload
						</Button>
					)}
				</FileButton>
			</Group>
		</Input.Wrapper>
	);
}
