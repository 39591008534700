import type React from 'react';
import type { PropsWithChildren } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { CannyContext } from '../contexts/CannyContext';
import { Canny } from '../makes/Canny';
import { CannyLoader } from '../makes/CannyLoader';

type Props = PropsWithChildren<{
	appId: string;
	domain?: string;
	subdomain?: string;
	user?: {
		id: number | string;
		name: string;
		email: string;
		avatarURL?: string;
		created?: string;
	};
}>;

const CannyProvider: React.FC<Props> = (props: Props) => {
	const { appId, domain, subdomain, user, children } = props;

	const [isLoaded, setLoaded] = useState(false);
	const refCanny = useRef<any | null>(null);

	const canny = useMemo(() => new Canny(refCanny.current), [isLoaded]);

	useEffect(() => {
		(async () => {
			const loader = new CannyLoader();

			try {
				refCanny.current = await loader.load(subdomain, domain);

				setLoaded(true);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
		})();
	}, []);

	useEffect(() => {
		if (!isLoaded) {
			return;
		}

		canny.identify(appId, user);
	}, [isLoaded, appId, user]);

	return (
		<CannyContext.Provider
			value={{
				appId,
				isLoaded,
				canny,
			}}
		>
			{children}
		</CannyContext.Provider>
	);
};

export type { Props as CannyProviderProps };
export { CannyProvider };
