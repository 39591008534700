import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';

export const ReportsColumns: ColumnDefinition = {
	name: {
		field: 'name',
		editable: false,
		type: ColumnType.textFilterColumn,

		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
	},
	updated_at: {
		field: 'updated_at',
		editable: false,
		sortable: true,
	},
};
