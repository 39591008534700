import { forwardRef, ForwardedRef } from 'react';
import type { TextProps as MantineTextProps } from '@mantine/core';
import { Text as MantineText } from '@mantine/core';
import { typography } from '@repo/theme/primitives';
import { getColor } from '@repo/theme/utils';
import type { ColorNames } from '@repo/theme/utils';

interface SecodaTextProps {
	children?: React.ReactNode;
	dangerouslySetInnerHTML?: {
		__html: string;
	};
	size?: keyof typeof typography.title | keyof typeof typography.text;
	weight?: keyof typeof typography.weight;
	color?: ColorNames;
	nonThemeColor?: string;
}

export type TextProps = SecodaTextProps &
	Omit<MantineTextProps, keyof SecodaTextProps | 'weight'>;

export const Text = forwardRef(
	(
		{
			children,
			size = 'md',
			weight = 'regular',
			color,
			nonThemeColor,
			...others
		}: TextProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const textColor = color
			? getColor(color)
			: nonThemeColor || getColor('text/primary/default');
		const fontWeight = typography.weight[weight];

		return (
			<MantineText
				ref={ref}
				size={size}
				fw={fontWeight}
				color={textColor}
				{...others}
			>
				{children}
			</MantineText>
		);
	}
);
Text.displayName = 'Text';
