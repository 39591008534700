/* eslint-disable prefer-destructuring */
import type MarkdownIt from 'markdown-it';
import Token from 'markdown-it/lib/token';

const BREAK_REGEX = /(?:^|[^\\])\\n/;

export default function markdownTables(md: MarkdownIt): void {
	// this plugin requires markdown-it-attrs to have run before it (aka curly_attributes)
	// https://github.com/arve0/markdown-it-attrs/blob/a77066ccc84c37786f218a618c2bc597f5def1c8/index.js#L40C36-L40C52
	md.core.ruler.after('curly_attributes', 'tables-pm', (state) => {
		const { tokens } = state;
		let inside = false;

		for (let i = tokens.length - 1; i > 0; i -= 1) {
			if (inside) {
				tokens[i].level -= 1;
			}

			// Convert unescaped \n in the text into real br tag
			if (
				tokens[i].type === 'inline' &&
				tokens[i]?.content?.match(BREAK_REGEX)
			) {
				const existing = tokens[i].children || [];
				tokens[i].children = [];

				existing.forEach((child) => {
					const breakParts = child.content.split(BREAK_REGEX);

					// A schema agnostic way to know if a node is inline code would be
					// great, for now we are stuck checking the node type.
					if (breakParts.length > 1 && child.type !== 'code_inline') {
						breakParts.forEach((part, index) => {
							const token = new Token('text', '', 1);
							token.content = part.trim();
							tokens[i].children?.push(token);

							if (index < breakParts.length - 1) {
								const brToken = new Token('br', 'br', 1);
								tokens[i].children?.push(brToken);
							}
						});
					} else {
						tokens[i].children?.push(child);
					}
				});
			}

			// Filter out incompatible tokens from markdown-it that we don't need
			// in prosemirror. thead/tbody do nothing.
			if (
				['thead_open', 'thead_close', 'tbody_open', 'tbody_close'].includes(
					tokens[i].type
				)
			) {
				inside = !inside;
				tokens.splice(i, 1);
			}

			if (['th_open', 'td_open'].includes(tokens[i].type)) {
				// Markdown-it table parser does not return paragraphs inside the cells
				// but prosemirror requires them, so we add 'em in here.
				tokens.splice(i + 1, 0, new Token('paragraph_open', 'p', 1));

				// Markdown-it table parser stores alignment as html styles, convert
				// to a simple string here
				const tokenAttrs = tokens[i].attrs;
				if (tokenAttrs) {
					const style = tokenAttrs[0][1];
					tokens[i].attrSet('alignment', style.split(':')[1]);
				}
			}

			if (['th_close', 'td_close'].includes(tokens[i].type)) {
				tokens.splice(i, 0, new Token('paragraph_close', 'p', -1));
			}
		}

		return false;
	});
}
