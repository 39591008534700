/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, Menu } from '@mantine/core';
import { isNil, map, truncate } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useLayoutEffect, useState, useContext } from 'react';
import { typography } from '@repo/theme/primitives';
import { Icon } from '@repo/foundations';
import { FilterMenuTarget } from '../../../../components/FilterMenu';
import type { FilterOption, FilterValue } from '../FilterCarousel.constants';
import FilterDropdown from '../FilterDropdown';
import { SearchFilterStoreContext } from '../store';

const useStyles = createStyles(
	(theme, { isInclude }: { isInclude: boolean }) => ({
		buttonRoot: {
			height: 28,
			paddingTop: 4,
			paddingBottom: 4,
			paddingLeft: 8,
			paddingRight: 8,
			backgroundColor: isInclude
				? `${theme.colors.primary[1]}15`
				: `${theme.colors.red[1]}15`,
			borderStyle: 'solid',
			borderRadius: 1000,
			borderColor: isInclude ? theme.colors.primary[1] : theme.colors.red[1],
			'&:hover': {
				backgroundColor: isInclude
					? `${theme.colors.primary[1]}35`
					: `${theme.colors.red[1]}35`,
			},
			'&:active': {
				backgroundColor: isInclude
					? `${theme.colors.primary[1]}50`
					: `${theme.colors.red[1]}50`,
			},
		},
		buttonLeftIcon: {
			marginRight: 4,
		},
		buttonRightIcon: {
			marginLeft: 4,
		},
		label: {
			color: isInclude ? theme.colors.primary[6] : theme.colors.red[6],
			fontSize: theme.fontSizes.sm,
			fontWeight: typography.weight.semibold,
		},
		dropdown: {
			maxHeight: 500,
			overflowY: 'auto',
		},
		filterButton: {
			color: theme.colors.primary[4],
			icon: {
				color: theme.colors.primary[4],
				size: 16,
			},
		},
	})
);

function SelectedFilterMenu({ filterValue }: { filterValue: FilterValue }) {
	const [opened, setOpened] = useState(false);
	const searchFilterStore = useContext(SearchFilterStoreContext);

	const item = searchFilterStore.searchFilters[filterValue];
	const { classes } = useStyles({ isInclude: item.isInclude });

	const ItemIcon = item.icon;

	const formatTargetDisplay = (
		label: string,
		isInclude: boolean,
		selectedOptions: FilterOption[]
	) => {
		if (selectedOptions.length === 0) {
			return label;
		}
		const targetDisplay = `${!isInclude ? 'Not: ' : ''} ${map(
			selectedOptions,
			'label'
		).join(', ')}`;
		return truncate(targetDisplay, { length: 24 });
	};

	useLayoutEffect(() => {
		searchFilterStore.determineControlVisibility();
	}, [item.isInclude]);

	const handleIsIncludeChange = (value: boolean) => {
		searchFilterStore.setSelectedFilterIsInclude(item.value, value);
	};

	return (
		<Menu
			classNames={{ dropdown: classes.dropdown }}
			width={300}
			position="bottom"
			opened={opened}
			onChange={setOpened}
			closeOnItemClick={false}
			withinPortal
		>
			<Menu.Target>
				<FilterMenuTarget
					variant={
						item.alwaysDisplayFilter && item.selectedOptions.length === 0
							? 'secondary'
							: 'primary'
					}
					leftIcon={<ItemIcon size={16} />}
					rightIcon={<Icon name="chevronDown" />}
				>
					{formatTargetDisplay(
						item.label,
						item.isInclude,
						item.selectedOptions
					)}
				</FilterMenuTarget>
			</Menu.Target>
			<Menu.Dropdown>
				{!isNil(item.component) ? (
					<item.component />
				) : (
					<FilterDropdown
						filterMenuItem={item}
						handleIsIncludeChange={handleIsIncludeChange}
					/>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}

export default observer(SelectedFilterMenu);
