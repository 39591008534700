import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';

export const AutomationJobColumns: ColumnDefinition = {
	started_at: {
		field: 'started_at',
		headerName: 'Run time',
		editable: false,
		sortable: false,

		flex: 1,

		lockVisible: true,
		suppressMenu: true,
	},
	affected_records_count: {
		field: 'affected_records_count',
		headerName: 'Resource updated',
		editable: false,
		sortable: false,

		type: ColumnType.textFilterColumn,
		flex: 1,

		lockVisible: true,
		suppressMenu: true,
	},
	success_at: {
		field: 'success_at',
		headerName: 'Status',
		editable: false,
		sortable: false,

		flex: 1,

		lockVisible: true,
		suppressMenu: true,
	},
};
