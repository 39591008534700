import type { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { isEmpty } from 'lodash-es';
import { Divider, Flex, Stack, useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router';
import { useCallback, useContext } from 'react';
import { Text } from '@repo/foundations';
import { Button } from '@repo/foundations';
import { trackEventAnonymous } from '../../utils/analytics';
import { loginRequest } from '../../utils/authentication/azure/authConfig';
import { getCookie } from '../../utils/shared.utils';
import {
	EMAIL_VERIFICATION_LOCAL_STORAGE_KEY,
	anonymousId,
	referrer,
} from './AuthStepEmailComponent.constants';
import { getSSOName } from './utils';
import { AuthStepStartContext } from './AuthStepStartContext';
import { GoogleIcon } from './AuthIcons/IconGoogle';
import { MicrosoftIcon } from './AuthIcons/IconMicrosoft';

const REGEX_DOMAIN = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

interface AuthStepSsoComponent {
	disableSSO?: boolean;
}

// We use SPA flow for Google/Microsoft because we don't need to provide our
// secrets to the instances, and therefore can share the same client with
// on-premise.
export function AuthStepSsoComponent({ disableSSO }: AuthStepSsoComponent) {
	const theme = useMantineTheme();
	const { instance } = useMsal();

	const handleEvent = useCallback(() => {
		trackEventAnonymous(
			'auth/sso',
			{
				path: window.location.pathname,
				anonymous_id: anonymousId(),
				referrer: referrer(),
			},
			localStorage.getItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY) ?? ''
		);
	}, []);

	const context = useContext(AuthStepStartContext);
	if (!context) {
		throw new Error(
			'AuthStepSsoComponent must be used within a AuthStepStartContextProvider'
		);
	}
	const { forceSSO, email, startSAMLAuth } = context;
	const navigate = useNavigate();

	const handleMicrosoft = useCallback(async () => {
		// Track event before redirecting to Microsoft.
		handleEvent();

		await (
			instance as IPublicClientApplication & {
				browserStorage: { clear: VoidFunction };
			}
		).browserStorage?.clear();
		instance.loginRedirect(loginRequest);
	}, [handleEvent, instance]);

	const handleGoogle = useCallback(() => {
		// Track event before redirecting to Google.
		handleEvent();

		const GOOGLE_CLIENT_ID =
			import.meta.env.VITE_GOOGLE_CLIENT_ID ??
			'884425733446-2krr618rsmam44n3dhmcg93c07u7rcoj.apps.googleusercontent.com';
		const SUFFIX = window.location.href.endsWith('/') ? '' : '/';
		const CALLBACK_URL = `${window.location.href}${SUFFIX}callback`;
		// This cookie is set by `auth/u/url` endpoint to prevent CSRF attacks.
		const state = getCookie('csrf_state');
		const GOOGLE_ENDPOINT = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${CALLBACK_URL}&state=${state}&prompt=consent&response_type=token&client_id=${GOOGLE_CLIENT_ID}&scope=openid%20email%20profile`;
		window.open(GOOGLE_ENDPOINT, '_self');
	}, [handleEvent]);

	const handleSaml = () => {
		if (forceSSO && email.length > 0) {
			// If it's an email address, extract the domain.
			let domain = email.includes('@') ? email.split('@')[1] : email;
			domain = domain.trim().toLowerCase();

			if (domain && !isEmpty(domain) && domain.match(REGEX_DOMAIN)) {
				startSAMLAuth(domain);
			}
		} else {
			navigate('/auth/login-sso');
		}
	};

	return (
		<Stack spacing={theme.other.space[3]}>
			{forceSSO && (
				<Text size="xs" align="center">
					Your Administrator requires that you log in using{' '}
					{getSSOName(forceSSO)}.{' '}
				</Text>
			)}

			<Flex direction="column" py="sm" gap="sm">
				{(!forceSSO || forceSSO === 'google') && (
					<Button onClick={handleGoogle} size="lg">
						<Flex pr={theme.other.space[1]} justify="center">
							<GoogleIcon />
						</Flex>
						Continue with Google
					</Button>
				)}
				{(!forceSSO || forceSSO === 'microsoft') && (
					<Button onClick={handleMicrosoft} size="lg">
						<Flex pr={theme.other.space[1]} justify="center">
							<MicrosoftIcon />
						</Flex>
						Continue with Microsoft
					</Button>
				)}
				{!disableSSO && (!forceSSO || forceSSO === 'saml') && (
					<Button onClick={handleSaml} size="lg">
						Continue with SAML SSO
					</Button>
				)}
			</Flex>
			<Divider
				hidden={!!forceSSO}
				label={<Text color="text/secondary/default">OR</Text>}
				labelPosition="center"
			/>
		</Stack>
	);
}
