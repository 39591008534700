import { ActionIcon, Group, useMantineTheme } from '@mantine/core';
import { IconArrowsDiagonal2, IconX } from '@tabler/icons-react';
import type { To } from 'react-router';
import { Link } from 'react-router-dom';
import BookmarkToggle from '../../BookmarkToggle/BookmarkToggle';
import EntityDrawerBreadcrumbs from './EntityDrawerBreadcrumbs';
import store from '../store';

interface IEntityDrawerActionBarProps {
	to: To;
}

function EntityDrawerActionBar({ to }: IEntityDrawerActionBarProps) {
	const theme = useMantineTheme();

	return (
		<Group position="apart" noWrap p="md">
			<EntityDrawerBreadcrumbs
				entity={store.entity}
				onClick={store.closeEntityDrawer}
			/>
			<Group spacing={4}>
				<BookmarkToggle entity={store.entity} />
				<ActionIcon
					size="xs"
					component={Link}
					to={to}
					onClick={store.closeEntityDrawer}
				>
					<IconArrowsDiagonal2
						size={16}
						color={theme.other.getColor('icon/primary/default')}
					/>
				</ActionIcon>
				<ActionIcon size="xs" onClick={store.closeEntityDrawer}>
					<IconX
						size={16}
						color={theme.other.getColor('icon/primary/default')}
					/>
				</ActionIcon>
			</Group>
		</Group>
	);
}

export default EntityDrawerActionBar;
