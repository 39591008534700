import { useMemo } from 'react';
import type { IDictionaryTerm } from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import {
	useGenericActions,
	useGenericColumns,
} from '../../components/TableV2/SecodaEntity.hooks';
import { useParentAction } from '../TableEntityPage/Actions/hooks';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';

export const useColumns = (): ExtendedDataTableColumn<IDictionaryTerm>[] => {
	const genericColumns = useGenericColumns({});

	const columns: ExtendedDataTableColumn<IDictionaryTerm>[] = useMemo(
		() => [
			...genericColumns.filter((column) => column.accessor !== 'verified'),
		],
		[genericColumns]
	);

	return columns;
};

export const useActions = () => {
	const genericActions =
		useGenericActions() as ICommandListItem<IDictionaryTerm>[];
	const parentAction = useParentAction(resourceCatalogQueryKeyFactory.all());
	const actions = useMemo(
		() => [parentAction, ...genericActions],
		[genericActions, parentAction]
	);
	return actions;
};
