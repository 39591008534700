/* eslint-disable @typescript-eslint/no-unused-vars */
import type { MantineTheme } from '@mantine/core';
import { createStyles, Menu } from '@mantine/core';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { typography } from '@repo/theme/primitives';
import type {
	IApiListResponse,
	IBaseModelListArgs,
	ISecodaEntity,
} from '../../../../api';
import type { FetchModelListHook } from '../../../../api/factories/types';
import SelectorSearch from '../../../../components/MultiSelector/SelectorSearch';

const useStyles = createStyles((theme: MantineTheme) => ({
	slide: {
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 'auto',
	},
	label: {
		color: theme.colors.gray[7],
		fontSize: theme.fontSizes.sm,
		fontWeight: typography.weight.semibold,
	},
	dropdown: {
		maxHeight: 500,
		overflowY: 'auto',
	},
	item: {
		// This fixes a bug where the items highlight seemingly randomly.
		'&[data-hovered]': {
			backgroundColor: 'transparent !important',
		},
	},
}));

interface IGenericFilterMenuProps<TModel extends ISecodaEntity> {
	useList: FetchModelListHook<TModel>;
	useListParams: Partial<IBaseModelListArgs<IApiListResponse<TModel>>>;
	withTarget?: JSX.Element;
	defaultOpened?: boolean;
	onClick?: (option: TModel) => void;
}

export function GenericFilterMenu<TModel extends ISecodaEntity>({
	useList,
	useListParams = {},
	withTarget,
	defaultOpened,
	onClick,
}: IGenericFilterMenuProps<TModel>) {
	const [opened, _] = useState(defaultOpened);
	const [searchTerm, setSearchTerm] = useState('');

	const { data } = useList(useListParams);

	const { classes } = useStyles();

	const filterFunction = (option: TModel) => {
		if (isEmpty(option.title?.trim())) {
			return false;
		}

		if (!searchTerm) {
			return true;
		}

		return option.title
			?.trim()
			.toLowerCase()
			.includes(searchTerm?.toLowerCase());
	};

	return (
		<Menu
			classNames={{ dropdown: classes.dropdown, item: classes.item }}
			width={300}
			position="bottom"
			opened={opened}
			closeOnItemClick={false}
			withinPortal
		>
			{withTarget}
			<SelectorSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
			{data?.results.filter(filterFunction).map((option) => (
				<Menu.Item key={option.id} onClick={() => onClick?.(option)}>
					{option.title}
				</Menu.Item>
			))}
		</Menu>
	);
}
