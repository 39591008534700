import type { ISecodaEntity } from '../../api';
import type { SecodaEntity, User } from '../../lib/models';
import { UserRole } from '../../lib/types';
import { IS_PRODUCTION } from '../envs';

export const isProductionEnvironment = () =>
	IS_PRODUCTION && !window.location.hostname.includes('staging');

export const isViewer = (user: User) => {
	if (!user) return false;
	// TODO: Refactor this when user.role is a UserRole
	// instead of a string.
	if (UserRole.VIEWER.toString() === user.role.toLowerCase()) return true;
	if (UserRole.GUEST.toString() === user.role.toLowerCase()) return true;
	return false;
};

export const isViewerOfEntity = (
	user: User,
	entity: SecodaEntity | ISecodaEntity
) => {
	if (entity.current_user_permissions) {
		return !entity.current_user_permissions.write;
	}

	return isViewer(user);
};

export const coalesceName = (u?: User) => {
	const name = u?.display_name?.trim();

	if (!name) {
		return u?.email ?? 'User';
	}

	return name;
};
