import { Group } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Breadcrumbs, IconButton } from '@repo/foundations';
import {
	NavBarWrapper,
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
} from '.';
import { usePlan } from '../../../hooks/usePlans';
import { appShellStore } from '../store';
import { DataQualityShareModal } from '../../DataQualityScore/DataQualityShareModal/DataQualityShareModal';
import AddWidgetButton from '../../../pages/AnalyticsPage/components/Buttons/AddWidgetButton';
import { ExportReportCSVButton } from '../../../pages/AnalyticsPage/components/Buttons/ExportReportCSVButton';

function AnalyticsPageNavBar() {
	const { plan } = usePlan();
	const [modalOpen, setModalOpen] = useState(false);

	const {
		navBarUI: {
			analyticsPage: {
				showAddWidgetButton,
				downloadDataQualityScore: { downloadButtonVisible, dataQualityScore },
			},
		},
	} = appShellStore;

	if (!plan?.analytics_support) {
		return null;
	}

	return (
		<>
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Breadcrumbs
						crumbs={[
							{
								title: 'Analytics',
								href: `/analytics`,
							},
						]}
					/>
				</NavBarBreadcrumbWrapper>

				<NavBarRightSideWrapper>
					{showAddWidgetButton && (
						<Group>
							<ExportReportCSVButton /> <AddWidgetButton />
						</Group>
					)}
					{downloadButtonVisible && (
						<IconButton
							variant="tertiary"
							iconName="upload"
							onClick={() => setModalOpen(true)}
						/>
					)}
				</NavBarRightSideWrapper>
			</NavBarWrapper>
			<DataQualityShareModal
				initialOpened={modalOpen}
				dataQualityScore={dataQualityScore}
				onClose={() => setModalOpen(false)}
			/>
		</>
	);
}

export default observer(AnalyticsPageNavBar);
