import { Center, UnstyledButton, createStyles } from '@mantine/core';
import { transparentize } from 'polished';
import { Icon } from '@repo/foundations';

const useStyles = createStyles((theme, { color }: { color: string }) => ({
	button: {
		borderRadius: '50%',
		width: theme.other.space[5],
		height: theme.other.space[5],
		background: color,
		'&:hover': {
			border: `1px solid ${theme.other.getColor('border/primary/default')}`,
			boxShadow: `0 0 3px 3px ${transparentize(0.5, color)}`,
		},
	},
}));

interface IColorButtonProps {
	color: string;
	onClick: VoidFunction;
	active?: boolean;
}

export function ColorButton({
	color,
	onClick,
	active = false,
}: IColorButtonProps) {
	const { classes } = useStyles({ color });

	if (active) {
		return (
			<UnstyledButton key={color} className={classes.button} onClick={onClick}>
				<Center>
					<Icon name="check" color="icon/inverse/default" />
				</Center>
			</UnstyledButton>
		);
	}
	return (
		<UnstyledButton key={color} className={classes.button} onClick={onClick} />
	);
}
