import type { MantineThemeOverride } from '@mantine/core';

export const Menu: MantineThemeOverride['components'] = {
	Menu: {
		styles: (theme) => ({
			dropdown: {
				minWidth: theme.other.space[60],
				borderRadius: theme.other.borderRadius.md,
				boxShadow:
					'0px 0px 0px 1px rgba(35, 34, 33, 0.05), 0px 4px 8px rgba(35, 34, 33, 0.1), 0px 8px 24px rgba(35, 34, 33, 0.2)',
				border: 'none',
				backgroundColor:
					theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
				paddingLeft: theme.spacing['2xs'],
				paddingRight: theme.spacing['2xs'],

				'@media print': {
					display: 'none',
				},
			},
			item: {
				padding: theme.spacing['2xs'],
				'&:hover': {
					backgroundColor: theme.other.getColor('surface/primary/hover'),
				},
				'&:active': {
					backgroundColor: theme.other.getColor('surface/primary/active'),
				},
			},
			itemLabel: {
				color: theme.other.getColor('text/primary/default'),
				fontSize: theme.other.typography.text.sm,
				lineHeight: theme.other.typography.lineHeight.text.sm,
			},
			itemIcon: {
				height: theme.other.space[5],
				width: theme.other.space[5],
				color: theme.other.getColor('icon/primary/default'),
			},
		}),
	},
};
