import cuid from 'cuid';
import { NodeSelection } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import { EditorDictionary, ToastType } from '@repo/secoda-editor';
import uploadPlaceholderPlugin, {
	findPlaceholder,
} from '../lib/uploadPlaceholder';
import findAttachmentById from '../queries/findAttachmentById';
import { captureError } from '../../../../../web-tracing';

export type Options = {
	dictionary: Partial<EditorDictionary>;
	/** Set to true to force images to become attachments */
	isAttachment?: boolean;
	/** Set to true to replace any existing image at the users selection */
	replaceExisting?: boolean;
	uploadFile?: (file: File, isImage: boolean) => Promise<string>;
	onFileUploadStart?: () => void;
	onFileUploadStop?: () => void;
	onShowToast?: (message: string, code: string) => void;
};

export default function insertFiles(
	view: EditorView,
	event: Event | React.ChangeEvent<HTMLInputElement>,
	pos: number,
	files: File[],
	options: Options
): void {
	const {
		dictionary,
		uploadFile,
		onFileUploadStart,
		onFileUploadStop,
		onShowToast,
	} = options;

	if (!uploadFile) {
		// eslint-disable-next-line no-console
		console.warn('uploadFile callback must be defined to handle uploads.');
		return;
	}

	// Okay, we have some dropped files and a handler – lets stop this
	// event going any further up the stack
	event.preventDefault();

	// Let the user know we're starting to process the files
	onFileUploadStart?.();

	const { schema } = view.state;

	// We'll use this to track of how many files have succeeded or failed
	let complete = 0;

	// The user might have dropped multiple files at once, we need to loop
	for (const file of files) {
		const id = `upload-${cuid()}`;
		const isImage = file.type.startsWith('image/') && !options.isAttachment;
		const { tr } = view.state;

		if (isImage) {
			// Insert a placeholder at this position, or mark an existing file as being
			// replaced
			tr.setMeta(uploadPlaceholderPlugin, {
				add: {
					id,
					file,
					pos,
					isImage,
					replaceExisting: options.replaceExisting,
				},
			});
			view.dispatch(tr);
		} else {
			const $pos = tr.doc.resolve(pos);
			view.dispatch(
				view.state.tr.replaceWith(
					$pos.pos,
					$pos.pos + ($pos.nodeAfter?.nodeSize || 0),
					schema.nodes.attachment.create({
						id,
						title: file.name,
						size: file.size,
					})
				)
			);
		}

		// Start uploading the file to the server. Using "then" syntax
		// to allow all placeholders to be entered at once with the uploads
		// happening in the background in parallel.
		uploadFile(file, isImage)
			.then((src) => {
				if (isImage) {
					const newImg = new Image();
					newImg.onload = () => {
						const result = findPlaceholder(view.state, id);

						// If the content around the placeholder has been deleted
						// then forget about inserting this file
						if (result === null) {
							return;
						}

						const [from, to] = result;
						view.dispatch(
							view.state.tr
								.replaceWith(
									from,
									to || from,
									schema.nodes.image.create({
										src,
										width: newImg.naturalWidth,
										height: newImg.naturalHeight,
									})
								)
								.setMeta(uploadPlaceholderPlugin, {
									remove: { id },
								})
						);

						// If the users selection is still at the file then make sure to select
						// the entire node once done. Otherwise, if the selection has moved
						// elsewhere then we don't want to modify it
						if (view.state.selection.from === from) {
							view.dispatch(
								view.state.tr.setSelection(
									new NodeSelection(view.state.doc.resolve(from))
								)
							);
						}
					};

					newImg.onerror = (error) => {
						throw error;
					};

					newImg.src = src;
				} else {
					const result = findAttachmentById(view.state, id);

					// If the attachment has been deleted then forget about updating it
					if (result === null) {
						return;
					}

					const [from, to] = result;
					view.dispatch(
						view.state.tr.replaceWith(
							from,
							to || from,
							schema.nodes.attachment.create({
								href: src,
								title: file.name.replace(/[\(\)\[\]]/g, ''), // Remove brackets as they interfere with the markdown link parsing.
								size: file.size,
							})
						)
					);

					// If the users selection is still at the file then make sure to select
					// the entire node once done. Otherwise, if the selection has moved
					// elsewhere then we don't want to modify it
					if (view.state.selection.from === from) {
						view.dispatch(
							view.state.tr.setSelection(
								new NodeSelection(view.state.doc.resolve(from))
							)
						);
					}
				}
			})
			.catch((error) => {
				captureError(error);

				// Cleanup the placeholder if there is a failure
				if (isImage) {
					view.dispatch(
						view.state.tr.setMeta(uploadPlaceholderPlugin, {
							remove: { id },
						})
					);
				} else {
					const result = findAttachmentById(view.state, id);

					// If the attachment has been deleted then forget about updating it
					if (result === null) {
						return;
					}

					const [from, to] = result;
					view.dispatch(view.state.tr.deleteRange(from, to || from));
				}

				onShowToast?.(
					error.message || dictionary.fileUploadError,
					ToastType.Error
				);
			})
			.finally(() => {
				complete += 1;

				// Once everything is done, let the user know
				if (complete === files.length && onFileUploadStop) {
					onFileUploadStop();
				}
			});
	}
}
