import { capitalize, filter } from 'lodash-es';
import { useMemo } from 'react';
import {
	DefaultContext,
	ISecodaEntity,
	UpdateRequestParams,
	useUpdateIncident,
	type Incident,
} from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { useGenericColumns } from '../../components/TableV2/SecodaEntity.hooks';
import {
	BoldTextRender,
	MonitorResourceRender,
	ReadOnlyBadgeRender,
	RelativeTimeRender,
} from '../../components/TableV2/render';
import { INCIDENT_STATUS_MAPPING } from '../IncidentPage/constants';
import { UpdateModelHook } from '../../api/factories/types';

export function useColumns<T extends Incident>(): ExtendedDataTableColumn<T>[] {
	const genericColumns = useGenericColumns({
		useUpdate: useUpdateIncident as unknown as UpdateModelHook<
			ISecodaEntity,
			UpdateRequestParams<ISecodaEntity>,
			DefaultContext<ISecodaEntity>,
			unknown
		>,
	}) as ExtendedDataTableColumn<T>[];

	const columns = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Name',
				render: (record) => <BoldTextRender record={record} field={'name'} />,
			},
			{
				accessor: 'monitor',
				title: 'Resource',
				render: (record) => <MonitorResourceRender record={record} />,
			},
			{
				accessor: 'status',
				title: 'Status',
				render: (record) => (
					<ReadOnlyBadgeRender
						record={record}
						field={'status'}
						options={Object.entries(INCIDENT_STATUS_MAPPING).map(
							([key, value]) => ({
								color: value.baseColor as string,
								label: capitalize(key),
								option: key,
							})
						)}
						nilOption={{
							color: 'red',
							label: 'Active',
							option: 'active',
						}}
					/>
				),
			},
			{
				accessor: 'value',
				title: 'Value',
				render: (record: Incident) => record.value,
			},
			{
				accessor: 'first_seen_at',
				title: 'Last run',
				render: (record) => (
					<RelativeTimeRender record={record} field={'first_seen_at'} />
				),
			},

			...filter(genericColumns, (column) =>
				['updated_at'].includes(column.accessor)
			),
		],
		[genericColumns]
	);

	return columns;
}
