/* eslint-disable class-methods-use-this */
// eslint-disable-next-line max-classes-per-file
import { ActionIcon, MantineProvider, Tooltip } from '@mantine/core';
import { InputRule } from 'prosemirror-inputrules';
import type { PluginView } from 'prosemirror-state';
import { Plugin } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import ReactDOM from 'react-dom';
import { Icon } from '@repo/foundations';
import { SecodaTheme } from '@repo/theme/mantine/secodaTheme';
import Extension from '../lib/Extension';
import isInCode from '../queries/isInCode';
import { run } from './BlockMenuTrigger';

export const OPEN_REGEX =
	/(?:^|\s)@(([0-9a-zA-Z_.+-]+((\s[0-9a-zA-Z_.+-]+)*|@[0-9a-zA-Z_.+-]*))|)$/;
export const CLOSE_REGEX =
	/(?:^|\s)@(((([0-9a-zA-Z_.+-]+(\s[0-9a-zA-Z_.+-]+)*)|[^0-9a-zA-Z_.+-]+)\s)|([0-9a-zA-Z_.+-]+(@[0-9a-zA-Z_.+-]*)))\s+($)/;

class MentionButtonView implements PluginView {
	editorView: EditorView;

	buttonTrigger?: HTMLDivElement;

	onOpen: VoidFunction;

	constructor(
		editorView: EditorView,
		showMentionMenuButton: boolean,
		onOpen: VoidFunction
	) {
		this.editorView = editorView;
		this.onOpen = onOpen;

		if (!showMentionMenuButton) {
			return;
		}

		this.buttonTrigger = document.createElement('div');
		this.buttonTrigger.className = 'mention-menu-trigger-wrapper';

		ReactDOM.render(
			<MantineProvider
				theme={{ ...SecodaTheme, colorScheme: 'light' }}
				withGlobalStyles
				withNormalizeCSS
			>
				<Tooltip label="Mention resource">
					<ActionIcon
						data-testid="mention-button"
						size="md"
						id="mention-menu-trigger"
						onClick={() => {
							editorView.dispatch(editorView.state.tr.insertText('@'));
							editorView.focus();
							onOpen();
						}}
					>
						<Icon name="at" color="icon/secondary/default" />
					</ActionIcon>
				</Tooltip>
			</MantineProvider>,
			this.buttonTrigger
		);
	}

	destroy() {
		this.buttonTrigger?.remove();
	}
}

export default class MentionTrigger extends Extension {
	get name() {
		// This must NOT match the `name` in mention menu.
		return 'mentionmenu';
	}

	get plugins() {
		return [
			new Plugin({
				view: (view) => {
					const mentionButtonView = new MentionButtonView(
						view,
						this.options.showMentionMenuButton,
						() => this.options.onOpen('')
					);
					if (mentionButtonView.buttonTrigger) {
						view.dom.parentNode?.append(
							mentionButtonView.buttonTrigger,
							view.dom
						);
					}
					return mentionButtonView;
				},
				props: {
					handleClick: () => {
						this.options.onClose();
						return false;
					},
					handleKeyDown: (view, event) => {
						// Prosemirror input rules are not triggered on backspace, however
						// we need them to be evaluted for the filter trigger to work
						// correctly. This additional handler adds inputrules-like handling.
						if (event.key === 'Backspace') {
							// Timeout ensures that the delete has been handled by prosemirror
							// and any characters removed, before we evaluate the rule.
							setTimeout(() => {
								const { pos } = view.state.selection.$from;
								return run(view, pos, pos, OPEN_REGEX, (state, match) => {
									if (match) {
										this.options.onOpen(match[1]);
									} else {
										this.options.onClose();
									}
									return null;
								});
							});
						}

						// If the query is active and we're navigating the block menu then
						// just ignore the key events in the editor itself until we're done
						if (
							event.key === 'Enter' ||
							event.key === 'ArrowUp' ||
							event.key === 'ArrowDown' ||
							event.key === 'Tab'
						) {
							const { pos } = view.state.selection.$from;

							return run(view, pos, pos, OPEN_REGEX, (state, match) =>
								// Just tell Prosemirror we handled it and not to do anything
								match ? true : null
							);
						}

						return false;
					},
				},
			}),
		];
	}

	inputRules() {
		return [
			// Main regex should match only:
			// @word
			new InputRule(OPEN_REGEX, (state, match) => {
				if (
					match &&
					state.selection.$from.parent.type.name === 'paragraph' &&
					!isInCode(state)
				) {
					this.options.onOpen(match[1]);
				}
				return null;
			}),
			new InputRule(CLOSE_REGEX, (state, match) => {
				if (match) {
					this.options.onClose();
				}
				return null;
			}),
		];
	}
}
