import { Input, Stack } from '@mantine/core';
import type { Monitor } from '../../../api';
import type { ISelectablePropertyProps } from '../../../components/EntityPageLayout/EntityPropertySidebar';
import { SelectableProperty } from '../../../components/EntityPageLayout/EntityPropertySidebar';
import { NumericProperty } from '../../../components/EntityPageLayout/EntityPropertySidebar/NumericProperty';
import { THRESHOLD_OPTIONS } from '../constants';
import { useFeatureFlags } from '../../../utils/featureFlags';

interface ThresholdSelectorProps
	extends Pick<ISelectablePropertyProps, 'label' | 'variant'> {
	value: string;
	onChange: (value: string | number | boolean | string[]) => void;
	threshold: 'automatic' | 'manual';
	data?: Pick<Monitor, 'condition_manual_max' | 'condition_manual_min'>;
	onMinimumValueChange: (value: number | null | undefined) => Promise<void>;
	onMaximumValueChange: (value: number | null | undefined) => Promise<void>;
	readOnly?: boolean;
}

function ThresholdSelector({
	data,
	onChange,
	onMinimumValueChange,
	onMaximumValueChange,
	threshold,
	value,
	label,
	variant,
	readOnly = false,
}: ThresholdSelectorProps) {
	const { monitoringV2 } = useFeatureFlags();
	const autoThresholdsMessage =
		"Automatic thresholds use your monitor's historical data to adjust limits, improving accuracy progressively over time.";

	return (
		<Stack spacing="3xs">
			<SelectableProperty
				selected={value}
				type="single"
				label={label}
				variant={variant}
				value="threshold"
				iconType="tabler"
				readOnly={readOnly}
				isViewerUser={false}
				options={THRESHOLD_OPTIONS}
				labelTooltip={monitoringV2 ? autoThresholdsMessage : undefined}
				onChange={onChange}
			/>
			{threshold === 'automatic' && !monitoringV2 && (
				<Input.Description color="subdued" py="3xs">
					Automatic thresholds use your monitor&apos;s historical data to adjust
					limits, improving accuracy progressively over time.
				</Input.Description>
			)}
			{threshold === 'manual' && (
				<>
					<NumericProperty
						readOnly={readOnly}
						label="Minimum"
						value={data?.condition_manual_min}
						onValueChange={onMinimumValueChange}
					/>
					<NumericProperty
						readOnly={readOnly}
						label="Maximum"
						value={data?.condition_manual_max}
						onValueChange={onMaximumValueChange}
					/>
					{data?.condition_manual_max === null &&
						data?.condition_manual_min === null && (
							<Input.Error size="sm" color="critical">
								Please specify either a minimum or maximum threshold. If both
								are left unset, automatic thresholds will be applied.
							</Input.Error>
						)}
					{data?.condition_manual_max !== null &&
						data?.condition_manual_max !== undefined &&
						data?.condition_manual_min !== null &&
						data?.condition_manual_min !== undefined &&
						data?.condition_manual_max < data?.condition_manual_min && (
							<Input.Error size="xs" color="critical" pl="xs" pt="xs">
								The maximum value should be greater than the minimum value.
							</Input.Error>
						)}
				</>
			)}
		</Stack>
	);
}

export default ThresholdSelector;
