import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';
import {
	DataEntityColumns,
	DefaultColumns,
	DescriptionColumns,
	PopularityColumns,
	TableDerivativeColumns,
} from './common';

export const TableColumns: ColumnDefinition = {
	title: {
		field: 'title',
		editable: false,
		sortable: true,

		checkboxSelection: true,

		type: ColumnType.textFilterColumn,
		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		minWidth: 250,
	},
	...PopularityColumns,
	...TableDerivativeColumns,
	...DescriptionColumns,
	...DataEntityColumns,
	...DefaultColumns,
};
