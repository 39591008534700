import type { Embla } from '@mantine/carousel';
import {
	cloneDeep,
	filter,
	forEach,
	has,
	includes,
	isEqual,
	map,
	some,
	sortBy,
	startCase,
	uniqBy,
	uniqWith,
} from 'lodash-es';
import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import {
	queryClient,
	fetchTagList,
	tagsQueryKeyFactory,
	integrationsQueryKeyFactory,
	fetchIntegrationList,
	collectionsQueryKeyFactory,
	fetchCollectionList,
	usersQueryKeyFactory,
	fetchUserList,
} from '../../../api';
import type { IIntegration, IUser, ICollection, ITag } from '../../../api';
import type { ISlackChannel, User } from '../../../lib/models';
import { coalesceName } from '../../../utils/authorization/roles';
import { getEntityTypeDisplayInfo } from '../../../utils/entityDisplayUtils';
import { getEntityFilters, parseSearchFilters } from '../utils';
import { slackChannelsQuery } from '../../../api/hooks/workspace/useSlackChannels';
import { formatIntegrationType } from '../../../utils/stringUtils';
import type {
	DisplayOption,
	DisplayValue,
	FilterOption,
	SearchFilters,
	SearchView,
} from './FilterCarousel.constants';
import {
	DEFAULT_DISPLAY_OPTIONS,
	DEFAULT_FILTER_OPTIONS,
	FilterValue,
	SortValue,
} from './FilterCarousel.constants';

export class SearchFilterStore {
	sort: SortValue;

	displays: Record<DisplayValue, DisplayOption>;

	selectedView: SearchView | undefined;

	searchFilters: SearchFilters;

	defaultSearchFilters: SearchFilters;

	/**
	 * UI ONLY: used for transition when adding a filter and having it show up on the carousel
	 */
	focusedFilter: FilterValue | undefined;

	/** UI ONLY: helps the rendering of the carousel */
	filterDropdownHeaderUI: Record<string, boolean>;

	/** UI ONLY: helps rendering the carousel */
	embla: Embla | undefined;

	// TODO[tan]: hack until I move all calls to react-query
	init: boolean;

	tags: ITag[] | undefined;

	constructor(
		urlParams?: URLSearchParams,
		filterOptions: SearchFilters = DEFAULT_FILTER_OPTIONS
	) {
		makeAutoObservable(this);

		this.defaultSearchFilters = { ...filterOptions };
		this.searchFilters = { ...filterOptions };

		urlParams?.forEach((value, key) => {
			if (key === 'filters') {
				const filters = JSON.parse(value);
				Object.entries(filters).forEach(([key, value]) => {
					if (has(this.searchFilters, key)) {
						this.searchFilters[key as FilterValue].selectedOptions =
							value as any;
					}
				});
			}
		});

		this.sort = SortValue?.RELEVANCE;
		this.displays = DEFAULT_DISPLAY_OPTIONS;

		this.selectedView = undefined;

		this.focusedFilter = undefined;
		this.embla = undefined;
		this.filterDropdownHeaderUI = {
			isMenuOpen: false,
			filterCarouselLeftControl: false,
			filterCarouselRightControl: false,
		};

		this.init = false;
	}

	reset(filterOptions: SearchFilters = DEFAULT_FILTER_OPTIONS) {
		this.sort = SortValue.RELEVANCE;

		this.defaultSearchFilters = { ...filterOptions };
		this.searchFilters = { ...filterOptions };
		this.populateFilterOptions();

		this.embla = undefined;

		this.filterDropdownHeaderUI = {
			isMenuOpen: false,
			filterCarouselLeftControl: false,
			filterCarouselRightControl: false,
		};
	}

	// === Sort
	setSort(sort: SortValue) {
		this.sort = sort;
	}

	// === Display
	toggleDisplay(displayValue: DisplayValue) {
		this.displays[displayValue].checked = !this.displays[displayValue].checked;
	}

	restoreDefaultDisplay() {
		this.displays = DEFAULT_DISPLAY_OPTIONS;
	}

	isDisplayChecked(displayValue: DisplayValue) {
		return !this.displays[displayValue].checked;
	}

	// === Views
	setSelectedView = async (view?: SearchView) => {
		if (!view) {
			this.selectedView = undefined;
			this.resetSelectedFilters();
			return;
		}
		if (!this.init) {
			await this.populateFilterOptions();
			this.init = true;
		}
		this.selectedView = view;
		this.setSelectedFiltersFromView(view);
	};

	resetSelectedFilters() {
		forEach(FilterValue, (filterValue: FilterValue) => {
			this.searchFilters[filterValue].selectedOptions = [];
			this.searchFilters[filterValue].isInclude = true;
		});
	}

	setSelectedFiltersFromView(view: SearchView) {
		if (!this.init) {
			this.populateFilterOptions();
			this.init = true;
		}
		forEach(FilterValue, (fv) => {
			if (!view.selectedFilters[fv]) {
				return;
			}

			const selectedOptions = cloneDeep(
				filter(this.searchFilters[fv].options, (option) =>
					includes(view.selectedFilters[fv].selectedOptionValues, option.value)
				)
			);
			this.searchFilters[fv].selectedOptions = selectedOptions;
			this.searchFilters[fv].isInclude = view.selectedFilters[fv].isInclude;
		});
	}

	// === Filters
	/** Toggle include/exclude mode for filter */
	setSelectedFilterIsInclude = async (
		filterValue: FilterValue,
		isInclude: boolean
	) => {
		this.searchFilters[filterValue].isInclude = isInclude;
	};

	/** Radio option can only have one selectedOptions */
	toggleSelectedFilterOptions = async (
		filterValue: FilterValue,
		option: FilterOption
	) => {
		this.searchFilters[filterValue].selectedOptions = [option];
	};

	/**
	 * Add/remove selectedOptions for the filterValue
	 * @param shouldAdd whether to add or remove the option
	 */
	setSelectedFilterOptions = async (
		filterValue: FilterValue,
		option: FilterOption,
		shouldAdd: boolean
	) => {
		const previousSelectedOptions = cloneDeep(
			this.searchFilters[filterValue].selectedOptions
		);

		if (shouldAdd) {
			this.searchFilters[filterValue].selectedOptions.push(option);
		} else {
			this.searchFilters[filterValue].selectedOptions = filter(
				previousSelectedOptions,
				(o) => o.value !== option.value
			);
		}
	};

	/**
	 * Clear selectedOptions for that FilterValue
	 */
	deleteFilter = (filterValue: FilterValue) => {
		this.searchFilters[filterValue].selectedOptions = [];
	};

	async populateFilterOptions() {
		// Call search/filters
		const entityFilters = await getEntityFilters();

		const nativeTypes = sortBy(uniqBy(entityFilters.native_types, 'label'), [
			(nativeType) => (nativeType.value as string)?.toLowerCase() || '',
		]);
		const nativeTypeItems = map(nativeTypes, (n) => ({
			label: n.label,
			value: n.value,
			icon: getEntityTypeDisplayInfo(n.entityType)?.icon,
			entityType: n.entityType,
		}));

		// Append existing native types
		this.searchFilters[FilterValue.NATIVE_TYPE].options = uniqWith(
			[
				...nativeTypeItems,
				...this.searchFilters[FilterValue.NATIVE_TYPE].options,
			],
			(a, b) => a.value === b.value && a.entityType === b.entityType
		);

		this.searchFilters[FilterValue.PARENT_ID].options = uniqBy(
			entityFilters.groups,
			'value'
		);

		this.searchFilters[FilterValue.DATABASE].options = uniqBy(
			entityFilters.databases,
			'value'
		);

		this.searchFilters[FilterValue.SCHEMA].options = uniqBy(
			entityFilters.schemas,
			'value'
		);

		const tags = await queryClient.fetchQuery(tagsQueryKeyFactory.list(), () =>
			fetchTagList({ filters: { visible: true } })
		);

		const integrations = await queryClient.fetchQuery(
			integrationsQueryKeyFactory.list(),
			fetchIntegrationList
		);

		const collections = await queryClient.fetchQuery(
			collectionsQueryKeyFactory.list(),
			fetchCollectionList
		);

		const users = await queryClient.fetchQuery(
			usersQueryKeyFactory.list(1, {
				disabled: false,
				is_service_account: false,
			}),
			fetchUserList
		);

		this.searchFilters[FilterValue.COLLECTIONS].options = map(
			collections?.results,
			(collection: ICollection) => ({
				label: startCase(collection.title),
				value: collection.id,
			})
		);

		this.searchFilters[FilterValue.INTEGRATION_NAME].options = map(
			integrations?.results,
			(integration: IIntegration) => ({
				label: startCase(integration.name),
				// Must be lowercase for filtering.
				value: integration.name?.toLowerCase(),
			})
		);

		this.searchFilters[FilterValue.OWNERS].options = map(
			users?.results,
			(user: IUser) => ({
				label: coalesceName(user as unknown as User),
				value: user.id,
			})
		);

		const { queryKey, queryFn } = slackChannelsQuery(false);
		const query = await queryClient.fetchQuery(queryKey, queryFn);

		this.searchFilters[FilterValue.SLACK_CHANNELS].options = map(
			map(query, (channel: ISlackChannel) => ({
				label: startCase(channel.name),
				value: channel.name,
			}))
		);

		this.searchFilters[FilterValue.TAGS].options = map(
			tags?.results,
			(tag: ITag) => ({
				label: startCase(tag.name),
				value: tag.id,
				color: tag.color,
			})
		);

		this.searchFilters[FilterValue.SOURCES].options = map(
			uniqBy(
				filter(integrations?.results, (integration) =>
					includes(['dbt', 'airflow'], integration.type)
				),
				'type'
			),
			(integration) => ({
				label: formatIntegrationType(integration.type),
				value: integration.id,
			})
		);
	}

	/**
	 * When the store is autoObservable, all getters are computed, and are cached.
	 */
	get parsedFilters() {
		return parseSearchFilters(this.sort, this.searchFilters, []);
	}

	/**
	 * Check if user selected any filters
	 *
	 * When the store is autoObservable, all getters are computed, and are cached.
	 */
	get isAnyFilterSelected() {
		return some(this.searchFilters, (f) => f.selectedOptions.length > 0);
	}

	/**
	 * Check if user modified the selected view. Return false if no view is selected.
	 *
	 * When the store is autoObservable, all getters are computed, and are cached.
	 */
	get isViewEdited() {
		if (!this.selectedView) return false;
		return some(Object.keys(this.searchFilters), (filterValue: FilterValue) => {
			if (!this.selectedView?.selectedFilters[filterValue]) {
				return false;
			}

			const equalSelectedOption = isEqual(
				map(this.searchFilters[filterValue].selectedOptions, 'value'),
				this.selectedView?.selectedFilters[filterValue].selectedOptionValues
			);
			const equalIsInclude =
				this.searchFilters[filterValue].isInclude ===
				this.selectedView?.selectedFilters[filterValue].isInclude;

			return !equalSelectedOption || !equalIsInclude;
		});
	}

	// === UI state
	/** Handle the transition when a new filter is selected */
	setFocusedFilter(filterValue: FilterValue | undefined) {
		this.focusedFilter = filterValue;
	}

	// Carousel
	setEmbla = (embla: Embla) => {
		this.embla = embla;
	};

	setFilterDropdownHeaderUI = (key: string, value: boolean) => {
		this.filterDropdownHeaderUI[key] = value;
	};

	determineControlVisibility = () => {
		if (this.embla) {
			this.embla.reInit();
			if (this.embla.canScrollNext()) {
				this.setFilterDropdownHeaderUI('filterCarouselRightControl', true);
			} else {
				this.setFilterDropdownHeaderUI('filterCarouselRightControl', false);
			}

			if (this.embla.canScrollPrev()) {
				this.setFilterDropdownHeaderUI('filterCarouselLeftControl', true);
			} else {
				this.setFilterDropdownHeaderUI('filterCarouselLeftControl', false);
			}
		}
	};
}

export const SearchFilterStoreContext = createContext<SearchFilterStore>(
	new SearchFilterStore()
);
