import type { ElementType, PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { useCannyContext } from '../contexts/CannyContext';
import type { ChangeLogOptions } from '../makes/Canny';

type CannyChangelogProps = PropsWithChildren<
	{
		component?: ElementType;
		className?: string;
	} & Partial<Omit<ChangeLogOptions, 'appId'>> & {
			[prop: string]: any;
		}
>;

function CannyChangelog(props: CannyChangelogProps) {
	const {
		align = 'left',
		position = 'bottom',
		labelIds,
		children,
		...rest
	} = props;

	const { isLoaded, appId, canny } = useCannyContext();

	function initChangelog() {
		if (isLoaded && appId) {
			canny.initChangelog({
				appID: appId,
				align,
				position,
				labelIds,
			});

			return function cleanupChangelog() {
				canny.closeChangelog();
			};
		}
	}

	useEffect(initChangelog, [isLoaded, appId, align, position, labelIds, canny]);

	return (
		<div {...rest} data-canny-changelog="">
			{children}
		</div>
	);
}

export type { CannyChangelogProps };
export { CannyChangelog };
