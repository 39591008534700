import { isNil } from 'lodash-es';
import { Icon } from '@repo/foundations';
import type { EditorDictionary, MenuItem } from '@repo/secoda-editor';
import { SHIFT_KEY, CTRL_KEY, MOD_KEY } from '../utils/keyboard';

export default function blockMenuItems(
	dictionary: EditorDictionary,
	isTemplate: boolean
): MenuItem[] {
	return [
		{
			name: 'ai_summary',
			title: dictionary.createSummary,
			keywords: 'ai summary summarize',
			icon: () => <Icon name="sparkles" color="icon/ai/default" />,
		},
		{
			name: 'separator',
		},
		{
			name: 'chart_block',
			title: dictionary.chartBlock,
			keywords: 'metric chart block line bar',
			icon: () => <Icon name="chartHistogram" color="icon/primary/default" />,
		},
		{
			name: 'query_block',
			title: dictionary.queryBlock,
			keywords: 'table query block sql table preview',
			icon: () => <Icon name="bolt" color="icon/primary/default" />,
		},
		{
			name: 'separator',
		},
		{
			name: 'heading',
			title: dictionary.heading1,
			keywords: 'h1 heading1 title',
			icon: () => <Icon name="h1" color="icon/primary/default" />,
			shortcutKeys: [SHIFT_KEY, CTRL_KEY, '1'],
			attrs: { level: 1 },
		},
		{
			name: 'heading',
			title: dictionary.heading2,
			keywords: 'h2 heading2',
			icon: () => <Icon name="h2" color="icon/primary/default" />,
			shortcutKeys: [SHIFT_KEY, CTRL_KEY, '2'],
			attrs: { level: 2 },
		},
		{
			name: 'heading',
			title: dictionary.heading3,
			keywords: 'h3 heading3',
			icon: () => <Icon name="h3" color="icon/primary/default" />,
			shortcutKeys: [SHIFT_KEY, CTRL_KEY, '3'],
			attrs: { level: 3 },
		},
		{
			name: 'separator',
		},
		{
			name: 'page',
			title: dictionary.page,
			keywords: 'page embed sub link',
			icon: () => <Icon name="file" color="icon/primary/default" />,
		},
		{
			name: 'separator',
		},
		{
			name: 'checkbox_list',
			title: dictionary.checkboxList,
			icon: () => <Icon name="listCheck" color="icon/primary/default" />,
			keywords: 'checklist checkbox task',
			shortcutKeys: [SHIFT_KEY, CTRL_KEY, '7'],
		},
		{
			name: 'bullet_list',
			title: dictionary.bulletList,
			icon: () => <Icon name="list" color="icon/primary/default" />,
			shortcutKeys: [SHIFT_KEY, CTRL_KEY, '8'],
		},
		{
			name: 'ordered_list',
			title: dictionary.orderedList,
			icon: () => <Icon name="listNumbers" color="icon/primary/default" />,
			shortcutKeys: [SHIFT_KEY, CTRL_KEY, '9'],
		},
		{
			name: 'separator',
		},
		{
			name: 'table',
			title: dictionary.table,
			icon: () => <Icon name="table" color="icon/primary/default" />,
			attrs: { rowsCount: 3, colsCount: 3 },
		},
		{
			name: 'attachment',
			title: dictionary.file,
			icon: () => <Icon name="paperclip" color="icon/primary/default" />,
			keywords: 'file upload attach',
		},
		{
			name: 'blockquote',
			title: dictionary.quote,
			icon: () => <Icon name="blockquote" color="icon/primary/default" />,
			shortcutKeys: [MOD_KEY, ']'],
		},
		{
			name: 'code_block',
			title: dictionary.codeBlock,
			icon: () => <Icon name="code" color="icon/primary/default" />,
			shortcutKeys: [SHIFT_KEY, CTRL_KEY, '\\'],
			keywords: 'script',
		},
		{
			name: 'hr',
			title: dictionary.hr,
			icon: () => (
				<Icon name="separatorHorizontal" color="icon/primary/default" />
			),
			shortcutKeys: [MOD_KEY, '_'],
			keywords: 'horizontal rule break line',
		},
		{
			name: 'hr',
			title: dictionary.pageBreak,
			icon: () => <Icon name="pageBreak" color="icon/primary/default" />,
			keywords: 'page print break line',
			attrs: { markup: '***' },
		},
		{
			name: 'image',
			title: dictionary.image,
			icon: () => <Icon name="photo" color="icon/primary/default" />,
			keywords: 'picture photo',
		},
		{
			name: 'link',
			title: dictionary.link,
			icon: () => <Icon name="link" color="icon/primary/default" />,
			shortcutKeys: [MOD_KEY, 'J'],
			keywords: 'link url uri href',
		},
		{
			name: 'toc',
			title: dictionary.toc,
			icon: () => <Icon name="listTree" color="icon/primary/default" />,
			keywords: 'toc table of contents',
		},
		{
			name: 'placeholder',
			title: dictionary.placeholder,
			icon: () => <Icon name="cursorText" color="icon/primary/default" />,
			keywords: 'placeholder template',
			visible: isTemplate,
		},
		{
			name: 'separator',
		},
		{
			name: 'container_notice',
			title: dictionary.infoNotice,
			icon: () => <Icon name="alertCircle" color="icon/primary/default" />,
			keywords: 'notice card information',
			attrs: { style: 'info' },
		},
		{
			name: 'container_notice',
			title: dictionary.warningNotice,
			icon: () => <Icon name="alertTriangle" color="icon/primary/default" />,
			keywords: 'notice card error',
			attrs: { style: 'warning' },
		},
		{
			name: 'container_notice',
			title: dictionary.tipNotice,
			icon: () => <Icon name="starFilled" color="icon/primary/default" />,
			keywords: 'notice card suggestion',
			attrs: { style: 'tip' },
		},
	].filter((item) => isNil(item.visible) || !!item.visible);
}
