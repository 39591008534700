import { capitalize } from 'lodash-es';
import { MenuItem } from '@repo/secoda-editor';
import { useUserList, useMention } from '../../../../../api';
import type { ISecodaEntity, SearchResult } from '../../../../../api';
import { EntityType } from '../../../../../lib/types';
import { buildResourceUrl } from '../../../../../utils/navigationUtils';

export interface MentionMenuItem extends MenuItem {
	description?: string;
	entity?: ISecodaEntity;
	attrs?: {
		markup: string;
		'data-name': string;
		href: string;
		title: string;

		// to_entity is null for users.
		to_entity?: string;
	};
}

interface IUseMentionMenuArgs {
	searchTerm?: string;
}

interface IUseMentionMenuResponse {
	isLoading: boolean;
	data: MentionMenuItem[];
}

// Optimizes mentions by always showing fetched users first before doing a full search
// Fetched users are usually cached through reactQuery from other parts of the app so it's usually instant.
export function useMentionMenu({
	searchTerm = '',
}: IUseMentionMenuArgs): IUseMentionMenuResponse {
	const { data: users, isLoading: isUserLoading } = useUserList({
		filters: {
			is_service_account: false,
			disabled: false,
		},
		options: {
			select: (data) =>
				data.results
					.filter(
						(user) =>
							user.display_name
								.toLowerCase()
								.includes(searchTerm.toLowerCase()) ||
							user.email.toLowerCase().includes(searchTerm.toLowerCase())
					)
					.map(
						(user) =>
							({
								name: 'resource_link',
								title: user.display_name,
								description: 'User',
								entity: {
									id: user.id,
									title: user.display_name,
									entity_type: EntityType.user,
									published: true,
									workspace_id: user.workspace_id,
								} as ISecodaEntity,
								attrs: {
									markup: user.display_name,
									'data-name': user.display_name,
									title: user.display_name,
									href: `/user/${user.id}`,
								},
							}) as MentionMenuItem
					)
					.slice(0, 3),
		},
	});

	const { isFetching: isSearchLoading, data: searchResults } = useMention({
		searchTerm,
		options: {
			select: (data) =>
				data.results.map((r: SearchResult) => {
					let displayedTitle = r.title;

					if (r.entity_type === EntityType.table) {
						displayedTitle = `${r.search_metadata?.schema}.${r.title}`;
					} else if (r.entity_type === EntityType.column) {
						displayedTitle = `${r.search_metadata?.schema}.${r.search_metadata?.table}.${r.title}`;
					}

					return {
						name: 'resource_link',
						title: displayedTitle,
						description: capitalize(r.entity_type?.replaceAll('_', ' ')),
						entity: {
							id: r.id,
							title: r.title,
							entity_type: r.entity_type,
							published: true,
							workspace_id: r.workspace,
							integration: r.integration,
							search_metadata: r.search_metadata,
						} as ISecodaEntity,
						attrs: {
							markup: r.title,
							'data-name': r.title,
							title: r.title,
							href: buildResourceUrl(r),
							to_entity: r.id,
						},
					} as MentionMenuItem;
				}),
		},
	});

	const userSeparator: MentionMenuItem[] = users?.length
		? [
				{
					name: 'separator',
					title: 'Users',
				} as MentionMenuItem,
			]
		: [];

	const searchSeparator: MentionMenuItem[] =
		searchResults?.length || isSearchLoading
			? [
					{
						name: 'separator',
						title: 'Resources',
					} as MentionMenuItem,
				]
			: [];

	return {
		isLoading: isUserLoading || isSearchLoading,
		data: [
			...userSeparator,
			...(users ?? []),
			...searchSeparator,
			...(searchResults ?? []),
		],
	};
}
