/* eslint-disable react/jsx-props-no-spreading */

import { ActionIcon, Box } from '@mantine/core';
import { isEqual } from 'lodash-es';
import React, { useCallback } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Icon } from '@repo/foundations';
import type { Catalog } from '../../../api';
import type { ColumnName } from '../helpers';
import { getColumnDisplayName } from '../helpers';
import { DraggableMenuItem } from './MenuItem';
import { reorder } from './utils';

export interface IMenuListProps {
	disableDragging?: boolean;
	catalogType: Catalog['catalog_type'];
	catalogProperties: Catalog['properties'];
	onColumnOrderChange: (columnName: string, toIndex: number) => void;
	onVisibilityChange: (columnName: ColumnName, isVisible: boolean) => void;
	onSettingsClick: (columnName: ColumnName) => VoidFunction;
}

const getListStyle = (columnCount: number): React.CSSProperties => ({
	background: 'transparent',
	height: 32 * columnCount,
});

function MenuList({
	disableDragging = false,
	catalogType,
	catalogProperties,
	onColumnOrderChange,
	onVisibilityChange,
	onSettingsClick,
}: IMenuListProps) {
	const [displayedCatalogProperties, setDisplayedCatalogProperties] =
		React.useState(catalogProperties);

	React.useEffect(() => {
		// This is to reduce the flickering when the columns are reordered
		// as the columns are reordered by react-beautiful-dnd and the API
		setDisplayedCatalogProperties((prevState) => {
			if (!isEqual(catalogProperties, prevState)) {
				return catalogProperties;
			}

			return prevState;
		});
	}, [catalogProperties]);

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			// Dropped outside the list.
			if (!result.destination) {
				return;
			}

			const reorderedColumns = reorder(
				catalogProperties,
				result.source.index,
				result.destination.index
			);
			setDisplayedCatalogProperties(reorderedColumns);

			onColumnOrderChange(
				displayedCatalogProperties[result.source.index]?.value,
				result.destination.index
			);
		},
		[catalogProperties, displayedCatalogProperties, onColumnOrderChange]
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId="droppable">
				{(provided) => (
					<Box
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(catalogProperties.length)}
					>
						{displayedCatalogProperties.map(
							({ value: columnName, hidden }, index) => {
								const name = getColumnDisplayName(columnName, catalogType);

								const handleVisibilityChange = (isVisible: boolean): void => {
									onVisibilityChange(columnName, isVisible);
								};

								const isVisible = !hidden;

								return (
									<DraggableMenuItem
										disableDragging={disableDragging}
										suffix={
											columnName.includes('properties.custom') && (
												<ActionIcon
													onClick={onSettingsClick(columnName)}
													size="xs"
												>
													<Icon name="settings" />
												</ActionIcon>
											)
										}
										key={columnName}
										name={name}
										columnName={columnName}
										index={index}
										onVisibilityChange={handleVisibilityChange}
										isVisible={isVisible}
									/>
								);
							}
						)}
						{provided.placeholder}
					</Box>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export default MenuList;
