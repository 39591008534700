import * as React from 'react';
import styled from 'styled-components';
import { Icon } from '@repo/foundations';
import { EMBED_HEIGHT, EMBED_WIDTH } from '../../nodes/Embed.constants';

type Props = Omit<any, 'children'> & {
	src?: string;
	border?: boolean;
	title?: string;
	icon?: React.ReactNode;
	canonicalUrl?: string;
	isSelected?: boolean;
	width?: string;
	height?: string;
	allow?: string;
};

type PropsWithRef = Props & {
	forwardedRef: React.Ref<HTMLIFrameElement>;
};

class Frame extends React.Component<PropsWithRef, { isLoaded: boolean }> {
	// @ts-expect-error TS(2564): Property 'mounted' has no initializer and is not d... Remove this comment to see the full error message
	mounted: boolean;

	// @ts-expect-error TS(7006): Parameter 'props' implicitly has an 'any' type.
	constructor(props) {
		super(props);
		this.state = { isLoaded: false };
	}

	componentDidMount() {
		this.mounted = true;
		this.loadIframe();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	loadIframe = () => {
		if (!this.mounted) {
			return;
		}
		this.setState({ isLoaded: true });
	};

	render() {
		const {
			border,
			isIframe = false,
			width: _width,
			height: _height,
			forwardedRef,
			icon,
			title,
			canonicalUrl,
			isSelected,
			referrerPolicy,
			src,
		} = this.props;
		const withBar = !!(icon || canonicalUrl);

		let width: string | number = _width ?? EMBED_WIDTH;
		let height: string | number = _height ?? EMBED_HEIGHT;

		if (isIframe) {
			// If the width is set, then show 100%, else use the default width.
			// This is a bit hacky for embed resizing.
			width = _width ? '100%' : EMBED_WIDTH;
			height = _height ? '100%' : EMBED_HEIGHT;
		}

		return (
			<Rounded
				width={width}
				height={height}
				$withBar={withBar}
				$border={border}
				className={isSelected ? 'ProseMirror-selectednode' : ''}
			>
				{this.state.isLoaded && (
					<Iframe
						ref={forwardedRef}
						$withBar={withBar}
						sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
						width={width}
						height={height}
						frameBorder="0"
						title="embed"
						loading="lazy"
						src={src}
						referrerPolicy={referrerPolicy}
						allowFullScreen
					/>
				)}
				{withBar && (
					<Bar>
						{icon} <Title>{title}</Title>
						{canonicalUrl && (
							<Open
								href={canonicalUrl}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Icon name="externalLink" /> Open
							</Open>
						)}
					</Bar>
				)}
			</Rounded>
		);
	}
}

const Iframe = styled.iframe<{ $withBar: boolean }>`
	border-radius: ${(props) => (props.$withBar ? '3px 3px 0 0' : '3px')};
	display: block;
`;

const Rounded = styled.div<{
	width: string | number;
	height: string | number;
	$withBar: boolean;
	$border?: boolean;
}>`
	margin-bottom: 10px;
	border: 1px solid
		${(props) => (props.$border ? props.theme.embedBorder : 'transparent')};
	border-radius: 6px;
	overflow: hidden;
	width: ${(props) => props.width};
	height: ${(props) => (props.$withBar ? props.height : props.height)};
`;

const Open = styled.a`
	color: ${(props) => props.theme.textSecondary} !important;
	font-size: 13px;
	font-weight: 500;
	align-items: center;
	display: flex;
	position: absolute;
	right: 0;
	padding: 0 8px;
`;

const Title = styled.span`
	font-size: 13px;
	font-weight: 500;
	padding-left: 4px;
`;

const Bar = styled.div`
	display: flex;
	align-items: center;
	border-top: 1px solid ${(props) => props.theme.embedBorder};
	background: ${(props) => props.theme.secondaryBackground};
	color: ${(props) => props.theme.textSecondary};
	padding: 0 8px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	user-select: none;
`;

export default React.forwardRef<HTMLIFrameElement, Props>((props, ref) => (
	<Frame {...props} forwardedRef={ref} />
));
