export const palette = [
	[
		'#FEF3C7',
		'#92400E',
		'text',
		'string',
		'varchar',
		'tinytext',
		'character varying',
	],
	['#DBEAFE', '#1E40AF', 'bool', 'boolean'],
	['#E0E7FF', '#3730A3', 'json'],
	['#EDE9FE', '#5B21B6', 'integer', 'int', 'bigint', 'number', 'smallint'],
	['#FCE7F3', '#9D174D', 'enum', 'variant'],
	[
		'#bbf7d0',
		'#166534',
		'date',
		'timestamp',
		'timestamp_ntz',
		'timestamp without time zone',
	],
	['#e5e7eb', '#1f2937', 'float'],
	['#e7e5e4', '#292524'],
	['#fed7aa', '#9a3412', 'in progress', 'in review'],
	['#fecaca', '#991b1b', 'high', 'hard', 'cancelled', 'failure', 'failed'],
	['#e7e5e4', '#292524', 'unanswered', ''],
	['#bbf7d0', '#166534', 'answered', 'completed', 'low', 'easy', 'success'],
	['#e2e8f0', '#1e293b', 'query', 'users', 'view'],
	['#bae6fd', '#075985', 'medium'],
];

export const validateColorHex = (color: string) =>
	/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);

function generateHashCode(input: string) {
	let hash = 0;
	if (input.length === 0) return '0000000000000000'; // Return 16 zeros for an empty string

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < input.length; i++) {
		const char = input.charCodeAt(i);
		// eslint-disable-next-line no-bitwise
		hash = (hash << 5) - hash + char;
	}

	// Ensure the hash is a 16-digit number by taking the last 16 digits and padding with leading zeros if necessary
	const hashString = Math.abs(hash).toString();
	return hashString.slice(-16).padStart(16, '0');
}

export const stringToColor = (input: string) => {
	const inputAsNumber = parseInt(generateHashCode(input).toString(), 16);
	return palette[inputAsNumber % palette.length][1];
};
