import { Modal } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { Text } from '@repo/foundations';
import { useAddMonitorStoreContext } from './context';

function AddMonitorHeader() {
	const form = useAddMonitorStoreContext();
	return (
		<Modal.Header>
			<Modal.Title>
				<Text size="sm">{form.metric.label || 'Select a metric type'}</Text>
			</Modal.Title>
			<Modal.CloseButton />
		</Modal.Header>
	);
}

export default observer(AddMonitorHeader);
