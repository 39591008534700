/* eslint-disable no-irregular-whitespace */
import type { MantineTheme } from '@mantine/core';
import { rem, useMantineTheme } from '@mantine/core';
import type { Ref } from 'react';
import { forwardRef } from 'react';
import styled from 'styled-components';

const EditorStyles = styled.div<{
	rtl: boolean;
	readOnly?: boolean;
	focusedCommentID?: string;
	readOnlyWriteCheckboxes?: boolean;
	mantineTheme: MantineTheme;
}>`
  flex-grow: 0;
  justify-content: start;
  color: ${(props) =>
		props.mantineTheme.other.getColor('text/primary/default')};
  font-family: ${(props) => props.mantineTheme.fontFamily};
  font-weight: ${(props) => props.mantineTheme.other.typography.weight.regular};
  font-size: ${(props) => props.theme.fontSize};
  line-height: ${(props) =>
		props.mantineTheme.other.typography.lineHeight.text.md};
  width: 100%;
  position: relative;

  > div {
    background: transparent;
  }

  .ProseMirror {
    position: relative;
    outline: none;
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: break-spaces;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */

    & > .ProseMirror-yjs-cursor {
      display: none;
    }

    & > * {
      margin-top: 0;
      margin-bottom: ${(props) => props.mantineTheme.spacing.sm};
    }

    & > :first-child,
    & > button:first-child + * {
      margin-top: 0;
    }

    .ProseMirror-yjs-cursor {
      position: relative;
      margin-left: -1px;
      margin-right: -1px;
      border-left: 1px solid black;
      border-right: 1px solid black;
      height: 0.9375rem;
      word-break: normal;

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -8px;
        right: -8px;
        top: 0;
        bottom: 0;
      }
      > div {
        opacity: 0;
        transition: opacity 100ms ease-in-out;
        position: absolute;
        top: -1.8em;
        font-size: 13px;
        background-color: rgb(250, 129, 0);
        font-style: normal;
        line-height: normal;
        user-select: none;
        white-space: nowrap;
        color: white;
        padding: 2px 6px;
        font-weight: 500;
        border-radius: 4px;
        pointer-events: none;
        left: -1px;
      }

      &:hover {
        > div {
          opacity: 1;
        }
      }
    }
  }

  &.show-cursor-names .ProseMirror-yjs-cursor > div {
    opacity: 1;
  }

  li {
    position: relative;
  }

  .image {
    line-height: 0;
    text-align: center;
    max-width: 100%;
    clear: both;

    img {
      pointer-events: ${(props) => (props.readOnly ? 'initial' : 'none')};
      display: inline-block;
      max-width: 100%;
      border-radius: ${(props) => props.mantineTheme.radius.sm};
    }

    .ProseMirror-selectednode img {
      pointer-events: initial;
    }
  }

  .image.placeholder {
    position: relative;
    background: ${(props) =>
			props.mantineTheme.other.getColor('fill/primary/default')};
    margin-bottom: calc(28px + 1.2em);

    img {
      opacity: 0.5;
    }
  }

  .image-replacement-uploading {
    img {
      opacity: 0.5;
    }
  }

  .image-right-50 {
    float: right;
    width: 50%;
    margin-left: 2rem;
    margin-bottom: 1rem;
    clear: initial;
  }

  .image-left-50 {
    float: left;
    width: 50%;
    margin-right: 2rem;
    margin-bottom: 1rem;
    clear: initial;
  }

  .ProseMirror-hideselection *::selection {
    background: transparent;
  }
  .ProseMirror-hideselection *::-moz-selection {
    background: transparent;
  }
  .ProseMirror-hideselection {
    caret-color: transparent;
  }

  .ProseMirror-selectednode {
    outline: 2px solid
      ${(props) =>
				props.readOnly
					? 'transparent'
					: props.mantineTheme.other.getColor('border/emphasis/default')};
  }

  /* Make sure li selections wrap around markers */

  li.ProseMirror-selectednode {
    outline: none;
  }

  li.ProseMirror-selectednode:after {
    content: "";
    position: absolute;
    left: ${(props) => (props.rtl ? '-2px' : '-32px')};
    right: ${(props) => (props.rtl ? '-32px' : '-2px')};
    top: -2px;
    bottom: -2px;
    border: 2px solid ${(props) =>
			props.mantineTheme.other.getColor('border/emphasis/default')};
    pointer-events: none;
  }

  img.ProseMirror-separator {
    display: inline;
    border: none !important;
    margin: 0 !important;
  }

  // Removes forced paragraph spaces below images, this is needed to images
  // being inline nodes that are displayed like blocks
  .component-image + img.ProseMirror-separator,
  .component-image + img.ProseMirror-separator + br.ProseMirror-trailingBreak {
    display: none;
  }

  .ProseMirror[contenteditable="false"] {
    .caption {
      pointer-events: none;
    }
    .caption:empty {
      visibility: hidden;
    }
  }

  h1 {
    font-size: ${(props) => rem(props.mantineTheme.other.space[6])};
    line-height: ${(props) => rem(props.mantineTheme.other.space[7])};
    margin-top: ${(props) => props.mantineTheme.spacing['4xl']} !important;
    margin-bottom: ${(props) => props.mantineTheme.spacing.md} !important;
  }

  h2 {
    font-size: ${(props) => rem(props.mantineTheme.other.space[5])};
    line-height: ${(props) => rem(props.mantineTheme.other.space[6])};
    margin-top: ${(props) => props.mantineTheme.spacing['2xl']} !important;
    margin-bottom: ${(props) => props.mantineTheme.spacing.sm} !important;
  }

  h3, h4, h5, h6 {
    font-size: ${(props) => rem(props.mantineTheme.other.space[4])};
    line-height: ${(props) => rem(props.mantineTheme.other.space[6])};
    margin-top: ${(props) => props.mantineTheme.spacing.xl} !important;
    margin-bottom: ${(props) => props.mantineTheme.spacing.sm} !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${(props) => props.mantineTheme.other.typography.weight.bold};
    cursor: text;

    &:not(.placeholder):before {
      display: ${(props) => (props.readOnly ? 'none' : 'inline-block')};
      font-family: ${(props) => props.mantineTheme.fontFamilyMonospace};
      color: ${(props) =>
				props.mantineTheme.other.getColor('text/secondary/default')};
      font-size: 13px;
      line-height: 0;
      margin-${(props) => (props.rtl ? 'right' : 'left')}: -24px;
      transition: opacity 150ms ease-in-out;
      opacity: 0;
      width: 24px;
    }

    &:hover,
    &:focus-within {
      .heading-actions {
        opacity: 1;
      }
    }
  }

  .heading-content {
    &:before {
      content: "​";
      display: inline;
    }
  }

  .heading-name {
    color: ${(props) =>
			props.mantineTheme.other.getColor('text/primary/default')};
    pointer-events: none;
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;

    &:hover {
      text-decoration: none;
    }
  }

  .heading-name:first-child,
  .heading-name:first-child + .ProseMirror-yjs-cursor {
    & + h1,
    & + h2,
    & + h3,
    & + h4 {
      margin-top: 0 !important;
    }
  }

  a:first-child {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }
  }

  h1:not(.placeholder):before {
    content: "H1";
  }
  h2:not(.placeholder):before {
    content: "H2";
  }
  h3:not(.placeholder):before {
    content: "H3";
  }
  h4:not(.placeholder):before {
    content: "H4";
  }
  h5:not(.placeholder):before {
    content: "H5";
  }
  h6:not(.placeholder):before {
    content: "H6";
  }

  .ProseMirror-focused {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(.placeholder):before {
        opacity: 1;
      }
    }
  }

  .with-emoji {
    margin-${(props) => (props.rtl ? 'right' : 'left')}: -1rem;
  }

  .heading-anchor,
  .heading-fold {
    display: inline-block;
    color: ${(props) =>
			props.mantineTheme.other.getColor('text/primary/default')};
    opacity: .75;
    cursor: pointer;
    background: none;
    outline: none;
    border: 0;
    margin: 0;
    padding: 0;
    text-align: left;
    font-family: ${(props) => props.mantineTheme.fontFamilyMonospace};
    font-size: 14px;
    line-height: 0;
    width: 12px;
    height: 24px;

    &:focus,
    &:hover {
      opacity: 1;
    }
  }

  .heading-anchor {
    box-sizing: border-box;
  }

  .heading-actions {
    opacity: 0;
    background: ${(props) =>
			props.mantineTheme.other.getColor('fill/primary/default')};
    margin-${(props) => (props.rtl ? 'right' : 'left')}: -26px;
    flex-direction: ${(props) => (props.rtl ? 'row-reverse' : 'row')};
    display: inline-flex;
    position: relative;
    top: -2px;
    width: 26px;
    height: 24px;

    &.collapsed {
      opacity: 1;
    }

    &.collapsed .heading-anchor {
      opacity: 0;
    }

    &.collapsed .heading-fold {
      opacity: 1;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:hover {
      .heading-anchor {
        opacity: 0.75 !important;
      }
      .heading-anchor:hover {
        opacity: 1 !important;
      }
    }
  }

  .heading-fold {
    display: inline-block;
    transform-origin: center;
    padding: 0;

    &.collapsed {
      transform: rotate(${(props) => (props.rtl ? '90deg' : '-90deg')});
      transition-delay: 0.1s;
      opacity: 1;
    }
  }

  .placeholder:before {
    display: block;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    content: ${(props) => (props.readOnly ? '' : 'attr(data-empty-text)')};
    pointer-events: none;
    height: 0;
    color: ${(props) => props.mantineTheme.other.getColor('text/secondary/default')};
  }

  /** Show the placeholder if focused or the first visible item nth(2) accounts for block insert trigger */
  .ProseMirror-focused .placeholder:before,
  .placeholder:nth-child(1):before,
  .placeholder:nth-child(2):before {
    opacity: 1;
  }

  .notice-block {
    display: flex;
    align-items: baseline;
    border-radius: 4px;
    padding: ${(props) => props.mantineTheme.spacing.sm} ${(props) =>
			props.mantineTheme.spacing.md};
    margin: 8px 0;

    a {
      color: ${(props) =>
				props.mantineTheme.other.getColor('text/emphasis/default')};

      &:hover {
        color: ${(props) =>
					props.mantineTheme.other.getColor('text/emphasis/hover')};
      }

      &:active {
        color: ${(props) =>
					props.mantineTheme.other.getColor('text/emphasis/active')};
      }
    }

    a:not(.heading-name) {
      text-decoration: underline;
    }

    p {
      align-self: baseline;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .content {
      flex-grow: 1;
      min-width: 0;
    }

    .icon {
      width: 24px;
      height: 24px;
      align-self: flex-start;
      margin-${(props) => (props.rtl ? 'left' : 'right')}: 4px;
    }

    &.tip {
      background: ${(props) =>
				props.mantineTheme.other.getColor('fill/caution-secondary/default')};
      border-left: 4px solid ${(props) =>
				props.mantineTheme.other.getColor('fill/caution/default')};

      .icon {
        color: ${(props) =>
					props.mantineTheme.other.getColor('icon/caution/default')};
      }
    }

    &.warning {
      background: ${(props) =>
				props.mantineTheme.other.getColor('fill/critical-secondary/default')};
      border-left: 4px solid ${(props) =>
				props.mantineTheme.other.getColor('fill/critical/default')};

      .icon {
        color: ${(props) =>
					props.mantineTheme.other.getColor('icon/critical/default')};
      }
    }

    &.info {
      background: ${(props) =>
				props.mantineTheme.other.getColor('fill/info-secondary/default')};
      border-left: 4px solid ${(props) =>
				props.mantineTheme.other.getColor('fill/info/default')};

      .icon {
        color: ${(props) =>
					props.mantineTheme.other.getColor('icon/info/default')};
      }
    }
  }

  blockquote {
    margin: 0;
    padding-left: calc(${(props) => props.mantineTheme.spacing.sm} + ${(props) => props.mantineTheme.spacing['3xs']});
    overflow: hidden;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      width: ${(props) => props.mantineTheme.spacing['3xs']};
      border-radius: ${(props) => props.mantineTheme.radius.md};
      position: absolute;
      margin-${(props) => (props.rtl ? 'right' : 'left')}: calc(-${(props) => props.mantineTheme.spacing.sm} - ${(props) => props.mantineTheme.spacing['3xs']});
      top: 0;
      bottom: 0;
      background: ${(props) => props.mantineTheme.other.getColor('border/tertiary/default')};
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  .template-placeholder {
    color: ${(props) => props.mantineTheme.other.getColor('text/secondary/default')};
    border-bottom: 1px dotted ${(props) => props.theme.placeholder};
    border-radius: 2px;
    cursor: text;

    &:hover {
      border-bottom: 1px dotted
        ${(props) =>
					props.readOnly ? props.theme.placeholder : props.theme.textSecondary};
    }
  }

  p {
    margin: 0;
    span:first-child + br:last-child {
      display: none;
    }
  }

  a {
    color: ${(props) =>
			props.mantineTheme.other.getColor('text/emphasis/default')};
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: .15em;
    cursor: pointer;

    &:hover {
      color: ${(props) =>
				props.mantineTheme.other.getColor('text/emphasis/hover')};
    }
  }

  .ProseMirror-focused {
    a {
      cursor: text;
    }
  }

  ul,
  ol {
    margin: ${(props) =>
			props.rtl
				? `0 -${props.mantineTheme.spacing.xl} 0 ${props.mantineTheme.spacing['5xs']}`
				: `0 ${props.mantineTheme.spacing['5xs']} 0 -${props.mantineTheme.spacing.xl}`};
    padding: ${(props) =>
			props.rtl
				? `0 ${props.mantineTheme.spacing['3xl']} 0 0`
				: `0 0 0 ${props.mantineTheme.spacing['3xl']}`};
  }

  ol ol {
    list-style: lower-alpha;
  }

  ol ol ol {
    list-style: lower-roman;
  }

  ul.checkbox_list {
    list-style: none;
    padding: 0;
    margin-left: ${(props) =>
			props.rtl ? '0' : `-${props.mantineTheme.spacing.xl}`};
    margin-right: ${(props) =>
			props.rtl ? `-${props.mantineTheme.spacing.xl}` : '0'};
  }

  ul li,
  ol li {
    position: relative;
    white-space: initial;

    p {
      white-space: pre-wrap;
    }

    > div {
      width: 100%;
    }
  }

  ul.checkbox_list li {
    display: flex;
		gap: ${(props) => props.mantineTheme.spacing['2xs']};
    padding-${(props) => (props.rtl ? 'right' : 'left')}: ${(props) =>
			props.mantineTheme.spacing.xl};
  }

  ul.checkbox_list li.checked > div > p {
    color: ${(props) =>
			props.mantineTheme.other.getColor('text/secondary/default')};
    text-decoration: line-through;
  }

  ul li::before,
  ol li::before {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iOCIgeT0iNyIgd2lkdGg9IjMiIGhlaWdodD0iMiIgcng9IjEiIGZpbGw9IiM0RTVDNkUiLz48cmVjdCB4PSI4IiB5PSIxMSIgd2lkdGg9IjMiIGhlaWdodD0iMiIgcng9IjEiIGZpbGw9IiM0RTVDNkUiLz48cmVjdCB4PSI4IiB5PSIxNSIgd2lkdGg9IjMiIGhlaWdodD0iMiIgcng9IjEiIGZpbGw9IiM0RTVDNkUiLz48cmVjdCB4PSIxMyIgeT0iNyIgd2lkdGg9IjMiIGhlaWdodD0iMiIgcng9IjEiIGZpbGw9IiM0RTVDNkUiLz48cmVjdCB4PSIxMyIgeT0iMTEiIHdpZHRoPSIzIiBoZWlnaHQ9IjIiIHJ4PSIxIiBmaWxsPSIjNEU1QzZFIi8+PHJlY3QgeD0iMTMiIHk9IjE1IiB3aWR0aD0iMyIgaGVpZ2h0PSIyIiByeD0iMSIgZmlsbD0iIzRFNUM2RSIvPjwvc3ZnPg==') no-repeat;
    background-position: 0 0;
    content: "";
    display: ${(props) => (props.readOnly ? 'none' : 'inline-block')};
    cursor: grab;
    width: ${(props) => props.mantineTheme.spacing.xl};
    height: ${(props) => props.mantineTheme.spacing.xl};
    position: absolute;
    ${(props) => (props.rtl ? 'right' : 'left')}: -${(props) =>
			props.mantineTheme.spacing['3xl']};
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  ul li[draggable=true]::before,
  ol li[draggable=true]::before {
    cursor: grabbing;
  }

  ul > li.counter-2::before,
  ol li.counter-2::before {
    ${(props) => (props.rtl ? 'right' : 'left')}: -50px;
  }

  ul > li.hovering::before,
  ol li.hovering::before {
    opacity: 0.5;
  }

  ul li.ProseMirror-selectednode::after,
  ol li.ProseMirror-selectednode::after {
    display: none;
  }

  ul.checkbox_list li::before {
    ${(props) => (props.rtl ? 'right' : 'left')}: 0;
    background-position: 0 -1px;
  }

  ul.checkbox_list li input[type=checkbox] {
    display: inline-block;
    cursor: pointer;
    pointer-events: ${(props) =>
			props.readOnly && !props.readOnlyWriteCheckboxes ? 'none' : 'initial'};
    opacity: ${(props) =>
			props.readOnly && !props.readOnlyWriteCheckboxes ? 0.75 : 1};
    margin: ${(props) =>
			props.rtl
				? `0 0 0 ${props.mantineTheme.spacing['4xs']}`
				: `0 ${props.mantineTheme.spacing['4xs']} 0 0`};
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
    transition: transform 100ms ease-in-out;
    opacity: .8;

    background-image: ${(props) =>
			`url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 0C1.34315 0 0 1.34315 0 3V11C0 12.6569 1.34315 14 3 14H11C12.6569 14 14 12.6569 14 11V3C14 1.34315 12.6569 0 11 0H3ZM3 2C2.44772 2 2 2.44772 2 3V11C2 11.5523 2.44772 12 3 12H11C11.5523 12 12 11.5523 12 11V3C12 2.44772 11.5523 2 11 2H3Z' fill='${props.theme.text.replace(
				'#',
				'%23'
			)}' /%3E%3C/svg%3E%0A");`}

    &[aria-checked=true] {
      opacity: 1;
      background-image: ${(props) =>
				`url(
          "data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 0C1.34315 0 0 1.34315 0 3V11C0 12.6569 1.34315 14 3 14H11C12.6569 14 14 12.6569 14 11V3C14 1.34315 12.6569 0 11 0H3ZM4.26825 5.85982L5.95873 7.88839L9.70003 2.9C10.0314 2.45817 10.6582 2.36863 11.1 2.7C11.5419 3.03137 11.6314 3.65817 11.3 4.1L6.80002 10.1C6.41275 10.6164 5.64501 10.636 5.2318 10.1402L2.7318 7.14018C2.37824 6.71591 2.43556 6.08534 2.85984 5.73178C3.28412 5.37821 3.91468 5.43554 4.26825 5.85982Z' fill='${props.theme.primary.replace(
						'#',
						'%23'
					)}' /%3E%3C/svg%3E%0A"
        )`};
    }

    &:active {
      transform: scale(0.9);
    }
  }

  li p:first-child {
    margin: 0;
    word-break: break-word;
  }

  hr {
    position: relative;
    height: 1rem;
    border: 0;
  }

  hr:before {
    content: "";
    display: block;
    position: absolute;
    border-top: 1px solid ${(props) =>
			props.mantineTheme.other.getColor('border/primary/default')};
    top: 0.5em;
    left: 0;
    right: 0;
  }

  hr.page-break {
    page-break-after: always;
  }

  hr.page-break:before {
    border-top: 1px dashed ${(props) =>
			props.mantineTheme.other.getColor('border/primary/default')};
  }

  mark {
    border-radius: 1px;
    color: ${(props) => props.theme.textHighlightForeground};
    background: ${(props) => props.theme.textHighlight};

    a {
      color: ${(props) => props.theme.textHighlightForeground};
    }
  }

	span[commentID] {
		border-radius: 1px;
		background: ${(props) => props.theme.textComment};

		&[commentid="${(props) => props.focusedCommentID}"] {
			background: ${(props) => props.theme.textCommentFocused};
		}
	}

  .block-container {
    position: relative;
  }

  .block-actions {
    display: none;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: calc(${(props) => props.mantineTheme.spacing.md} / 2);
    ${(props) => (props.rtl ? 'left' : 'right')}: ${(props) =>
			props.mantineTheme.spacing.md};
    gap: ${(props) => props.mantineTheme.spacing.sm};
    align-items: center;

    select {
      background: none;
      border: 0;
      color: ${(props) =>
				props.mantineTheme.other.getColor('text/primary/default')};
      font-family: ${(props) => props.mantineTheme.fontFamily};
      font-size: ${(props) => props.mantineTheme.fontSizes.xs};
      margin-top: 2px;
    }

    .mantine-NativeSelect-root {
      display: ${(props) => (props.readOnly ? 'none' : 'block')}
    }

    .mantine-NativeSelect-rightSection {
      pointer-events: none;
    }
  }

  .block-container:hover {
    .block-actions {
      display: flex;
    }
  }

  code {
    border-radius: ${(props) => props.mantineTheme.radius.xs};
    border: none;
    background: ${(props) =>
			props.mantineTheme.other.getColor('fill/secondary/default')};
    padding: ${(props) => props.mantineTheme.spacing['4xs']} ${(props) =>
			props.mantineTheme.spacing['3xs']};
    font-family: ${(props) => props.mantineTheme.fontFamilyMonospace};
    font-size: ${(props) => props.mantineTheme.fontSizes.sm};
    font-weight: ${(props) =>
			props.mantineTheme.other.typography.weight.regular};
    color: ${(props) =>
			props.mantineTheme.other.getColor('text/primary/default')};
  }

  pre {
    display: block;
    overflow-x: auto;
    padding: ${(props) => props.mantineTheme.spacing.md};
    position: relative;
    background-color: ${(props) =>
			props.mantineTheme.other.getColor('surface/tertiary/default')};
    border-radius: ${(props) => props.mantineTheme.radius.md};

    -webkit-font-smoothing: initial;
    font-family: ${(props) => props.mantineTheme.fontFamilyMonospace};
    font-size: ${(props) => props.mantineTheme.fontSizes.sm};
    font-weight: ${(props) =>
			props.mantineTheme.other.typography.weight.regular};
    color: ${(props) =>
			props.mantineTheme.other.getColor('text/primary/default')};
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;

    code {
      font-size: ${(props) => props.mantineTheme.fontSizes.sm};
      background: none;
      border: none;
      padding: 0;
    }
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: ${(props) => props.theme.codeComment};
  }

  .token.punctuation {
    color: ${(props) => props.theme.codePunctuation};
  }

  .token.namespace {
    opacity: 0.7;
  }

  .token.operator,
  .token.boolean,
  .token.number {
    color: ${(props) => props.theme.codeNumber};
  }

  .token.property {
    color: ${(props) => props.theme.codeProperty};
  }

  .token.tag {
    color: ${(props) => props.theme.codeTag};
  }

  .token.string {
    color: ${(props) => props.theme.codeString};
  }

  .token.selector {
    color: ${(props) => props.theme.codeSelector};
  }

  .token.attr-name {
    color: ${(props) => props.theme.codeAttr};
  }

  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: ${(props) => props.theme.codeEntity};
  }

  .token.attr-value,
  .token.keyword,
  .token.control,
  .token.directive,
  .token.unit {
    color: ${(props) => props.theme.codeKeyword};
  }

  .token.function {
    color: ${(props) => props.theme.codeFunction};
  }

  .token.statement,
  .token.regex,
  .token.atrule {
    color: ${(props) => props.theme.codeStatement};
  }

  .token.placeholder,
  .token.variable {
    color: ${(props) => props.theme.codePlaceholder};
  }

  .token.deleted {
    text-decoration: line-through;
  }

  .token.inserted {
    border-bottom: 1px dotted ${(props) => props.theme.codeInserted};
    text-decoration: none;
  }

  .token.italic {
    font-style: italic;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.important {
    color: ${(props) => props.theme.codeImportant};
  }

  .token.entity {
    cursor: help;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: ${(props) => props.mantineTheme.radius.xs};
    margin-top: ${(props) => props.mantineTheme.spacing.md};
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    tr {
      position: relative;
      border-bottom: 1px solid ${(props) => props.mantineTheme.other.getColor('border/secondary/default')};
    }

    td,
    th {
      position: relative;
      vertical-align: top;
      border: 1px solid ${(props) => props.mantineTheme.other.getColor('border/secondary/default')};
      padding: 4px 8px;
      text-align: ${(props) => (props.rtl ? 'right' : 'left')};
      min-width: 100px;
      font-weight: normal;
    }

    th {
      background: ${(props) => props.mantineTheme.other.getColor('surface/secondary/default')};
      color: ${(props) => props.mantineTheme.other.getColor('text/secondary/default')};
      font-weight: 500;

      &.selectedCell {
        background: ${(props) =>
					props.readOnly
						? 'inherit'
						: props.mantineTheme.other.getColor('surface/secondary/hover')};  
      }
    }

    .selectedCell {
      background: ${(props) =>
				props.readOnly
					? 'inherit'
					: props.mantineTheme.other.getColor('surface/primary/hover')};

      /* fixes Firefox background color painting over border:
       * https://bugzilla.mozilla.org/show_bug.cgi?id=688556 */
      background-clip: padding-box;
    }

    .table-add-row,
    .table-add-column {
      display: block;
      position: absolute;
      background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/default')};
      cursor: pointer;

      &:hover {
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/hover')};
      }

      &:active {
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/active')};
      }

      &:hover::after {
        width: 16px;
        height: 16px;
        z-index: 20;
        background-color: ${(props) => props.mantineTheme.other.getColor('fill/secondary/hover')};
        background-size: 16px 16px;
        background-position: 50% 50%;
        background-image: url("data:image/svg+xml;base64,${btoa(
					'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 5C11.4477 5 11 5.44772 11 6V11H6C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H11V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H13V6C13 5.44772 12.5523 5 12 5Z" fill="white"/></svg>'
				)}")
      }

      &:active::after {
        background-color: ${(props) => props.mantineTheme.other.getColor('fill/secondary/active')};
      }

      // extra clickable area
      &::before {
        content: "";
        display: block;
        cursor: pointer;
        position: absolute;
        width: 24px;
        height: 24px;
      }
    }

    .table-add-row {
      bottom: -1px;
      left: -16px;
      width: 0;
      height: 2px;

      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: -10px;
        width: 4px;
        height: 4px;
        display: ${(props) => (props.readOnly ? 'none' : 'block')};
        border-radius: 100%;
        background-color: ${(props) => props.mantineTheme.other.getColor('fill/secondary/hover')};
      }

      &:hover {
        width: calc(var(--table-width) - (${(props) => props.mantineTheme.spacing['2xl']} * 1.5));
      }

      &:hover::after {
        bottom: -7.5px;
        left: -16px;
      }

      // extra clickable area
      &::before {
        bottom: -12px;
        left: -18px;
      }

      &.first {
        bottom: auto;
        top: -1px;
        &::before {
          bottom: auto;
          top: -12px;
        }
      }
    }

    .table-add-column {
      top: -16px;
      right: -1px;
      width: 2px;
      height: 0;

      &::after {
        content: "";
        position: absolute;
        top: -10px;
        right: -1px;
        width: 4px;
        height: 4px;
        display: ${(props) => (props.readOnly ? 'none' : 'block')};
        border-radius: 100%;
        background-color: ${(props) => props.mantineTheme.other.getColor('fill/secondary/hover')};
      }

      &:hover {
        height: calc(var(--table-height) - ${(props) => props.mantineTheme.spacing['2xl']} + 6px);
      }

      &:hover::after {
        top: -16px;
        right: -7px;
      }

      // extra clickable area
      &::before {
        top: -16px;
        right: -12px;
      }

      &.first {
        right: auto;
        left: -1px;
        &::before {
          right: auto;
          left: -12px;
        }
      }
    }

    .grip-column {
      /* usage of ::after for all of the table grips works around a bug in
       * prosemirror-tables that causes Safari to hang when selecting a cell
       * in an empty table:
       * https://github.com/ProseMirror/prosemirror/issues/947 */
      &::after {
        content: "";
        cursor: pointer;
        position: absolute;
        top: -16px;
        ${(props) => (props.rtl ? 'right' : 'left')}: 0;
        width: 100%;
        height: 9px;
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/default')};
        display: ${(props) => (props.readOnly ? 'none' : 'block')};
      }

      &:hover::after {
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/hover')};
      }
      &.first::after {
        border-top-${(props) => (props.rtl ? 'right' : 'left')}-radius: ${(props) => props.mantineTheme.radius.xs};
      }
      &.last::after {
        border-top-${(props) => (props.rtl ? 'left' : 'right')}-radius: ${(props) => props.mantineTheme.radius.xs};
      }
      &.selected::after {
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/active')};
      }
    }

    .grip-row {
      &::after {
        content: "";
        cursor: pointer;
        position: absolute;
        ${(props) => (props.rtl ? 'right' : 'left')}: -16px;
        top: 0;
        height: 100%;
        width: 9px;
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/default')};
        display: ${(props) => (props.readOnly ? 'none' : 'block')};
      }

      &:hover::after {
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/hover')};
      }
      &.first::after {
        border-top-${(props) => (props.rtl ? 'right' : 'left')}-radius: ${(
					props
				) => props.mantineTheme.radius.xs};
      }
      &.last::after {
        border-bottom-${(props) => (props.rtl ? 'right' : 'left')}-radius: ${(
					props
				) => props.mantineTheme.radius.xs};
      }
      &.selected::after {
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/active')};
      }
    }

    .grip-table {
      &::after {
        content: "";
        cursor: pointer;
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/default')};
        width: 13px;
        height: 13px;
        border-radius: 13px;
        border: 2px solid ${(props) => props.mantineTheme.other.getColor('fill/primary/default')};
        position: absolute;
        top: -18px;
        ${(props) => (props.rtl ? 'right' : 'left')}: -18px;
        display: ${(props) => (props.readOnly ? 'none' : 'block')};
        z-index: 10;
      }

      &:hover::after {
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/hover')};
      }
      &.selected::after {
        background: ${(props) => props.mantineTheme.other.getColor('fill/secondary/active')};
      }
    }
  }

  @keyframes Table-resize-handle-fadein {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .column-resize-handle {
    animation: Table-resize-handle-fadein 150ms ease-in-out;
    ${(props) => (props.readOnly ? 'display: none;' : '')}
    position: absolute;
    right: -1px;
    top: 0;
    bottom: -1px;
    width: 2px;
    z-index: 20;
    background-color: ${(props) => props.mantineTheme.other.getColor('fill/secondary/active')};
    pointer-events: none;
  }

  .resize-cursor {
    ${(props) => (props.readOnly ? 'pointer-events: none;' : '')}
    cursor: ew-resize;
    cursor: col-resize;
  }

  .scrollable-wrapper {
    position: relative;
  }

  .scrollable {
    position: relative;
    margin: -1em -${(props) => props.mantineTheme.spacing['2xl']} -0.5em;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    overflow-y: hidden;
    overflow-x: auto;
    padding-top: 1em;
    padding-bottom: .5em;
    padding-left: ${(props) => props.mantineTheme.spacing['2xl']};
    padding-right: ${(props) => props.mantineTheme.spacing['2xl']};
    transition: border 250ms ease-in-out 0s;

    &:hover {
      scrollbar-color: ${(props) => props.theme.scrollbarThumb} ${(props) => props.theme.scrollbarBackground};
    }

    & ::-webkit-scrollbar {
      height: 14px;
      background-color: transparent;
    }

    &:hover ::-webkit-scrollbar {
      background-color: ${(props) => props.theme.scrollbarBackground};
    }

    & ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border: 3px solid transparent;
      border-radius: 7px;
    }

    &:hover ::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.scrollbarThumb};
      border-color: ${(props) => props.theme.scrollbarBackground};
    }
  }

  .table-shadow-left::before,
  .table-shadow-right::after {
    content: "";
    position: absolute;
    top: 1px;
    bottom: 0;
    ${(props) => (props.rtl ? 'right' : 'left')}: -1em;
    width: ${(props) => props.mantineTheme.spacing['2xl']};
    z-index: 20;
    transition: box-shadow 250ms ease-in-out;
    border: 0px solid transparent;
    pointer-events: none;
  }

  .table-shadow-left::before {
    left: -${(props) => props.mantineTheme.spacing['2xl']};
    right: auto;
    box-shadow: 16px 0 16px -16px inset rgba(0, 0, 0, ${(props) =>
			props.theme.isDark ? 1 : 0.25});
    border-left: ${(props) => props.mantineTheme.spacing['2xl']} solid ${(props) => props.mantineTheme.other.getColor('fill/primary/default')};
  }

  .table-shadow-right::after {
    right: -${(props) => props.mantineTheme.spacing['2xl']};
    left: auto;
    box-shadow: -16px 0 16px -16px inset rgba(0, 0, 0, ${(props) =>
			props.theme.isDark ? 1 : 0.25});
    border-right: ${(props) => props.mantineTheme.spacing['2xl']} solid ${(props) => props.mantineTheme.other.getColor('fill/primary/default')};
  }

  .block-menu-trigger {
    opacity: 0;
    pointer-events: none;
    display: ${(props) => (props.readOnly ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: ${(props) =>
			props.mantineTheme.other.getColor('text/secondary/default')};
    background: none;
    position: absolute;
    transition: color 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
      opacity 150ms ease-in-out;
    outline: none;
    border: 0;
    padding: 0;
    margin-top: 1px;
    margin-${(props) => (props.rtl ? 'right' : 'left')}: -28px;
    border-radius: ${(props) => props.mantineTheme.radius.xs};

    &:hover,
    &:focus {
      cursor: pointer;
      color: ${(props) =>
				props.mantineTheme.other.getColor('text/primary/default')};
      background: ${(props) => props.theme.secondaryBackground};
    }
  }

  .ProseMirror-focused .block-menu-trigger,
  .block-menu-trigger:active,
  .block-menu-trigger:focus {
    opacity: 1;
    pointer-events: initial;
  }

  .mention-menu-trigger-wrapper {
    display: ${(props) => (props.readOnly ? 'none' : 'block')};
    position: absolute;
    right: 2px;
    top: calc(50% - 16px);
    z-index: 1;
  }

  &[data-with-mention-menu-button] .ProseMirror {
    padding-${(props) => (props.rtl ? 'left' : 'right')}: 2rem; // this is the width of the Mention menu trigger button
  }

  .ProseMirror-gapcursor {
    display: none;
    pointer-events: none;
    position: absolute;
  }

  .ProseMirror-gapcursor:after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 20px;
    border-top: 1px solid ${(props) => props.theme.cursor};
    animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
  }

  .folded-content {
    display: none;
  }

  @keyframes ProseMirror-cursor-blink {
    to {
      visibility: hidden;
    }
  }

  .ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
  }

  .summary {
    border: solid 1px transparent;
    margin: -${(props) => props.mantineTheme.spacing.xs};
    padding: ${(props) => props.mantineTheme.spacing.xs};

    &:hover {
      border-color: ${(props) =>
				props.mantineTheme.other.getColor('border/ai-secondary/default')};
    }
  }

  .find-result {
    background: rgba(255, 213, 0, 0.25);
    &.current-result {
      background: rgba(255, 213, 0, 0.75);
    }
  }

  @media print {
    .placeholder:before,
    .block-menu-trigger,
    .heading-actions,
    h1:not(.placeholder):before,
    h2:not(.placeholder):before,
    h3:not(.placeholder):before,
    h4:not(.placeholder):before,
    h5:not(.placeholder):before,
    h6:not(.placeholder):before {
      display: none;
    }

    .page-break {
      opacity: 0;
    }

    em,
    blockquote {
      font-family: "SF Pro Text", ${(props) => props.mantineTheme.fontFamily};
    }
  }
`;

interface NewEditorStylesProps extends React.HTMLAttributes<HTMLDivElement> {
	rtl: boolean;
	readOnly?: boolean;
	focusedCommentID?: string;
	readOnlyWriteCheckboxes?: boolean;
}

function NewEditorStyles(
	props: NewEditorStylesProps,
	ref: Ref<HTMLDivElement>
) {
	const mantineTheme = useMantineTheme();

	return <EditorStyles {...props} ref={ref} mantineTheme={mantineTheme} />;
}

export default forwardRef(NewEditorStyles);
