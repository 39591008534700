import CollectionFooter from './Collection/CollectionFooter';
import DictionaryTermFooter from './DictionaryTerm/DictionaryTermFooter';
import DocumentFooter from './Document/DocumentFooter';
import MetricFooter from './Metric/MetricFooter';
import QuestionFooter from './Question/QuestionFooter';

function EntityModalFooter() {
	return (
		<>
			<CollectionFooter />
			<DictionaryTermFooter />
			<MetricFooter />
			<DocumentFooter />
			<QuestionFooter />
		</>
	);
}

export default EntityModalFooter;
