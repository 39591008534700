/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLayoutEffect, useState } from 'react';
import {
	metricsQueryKeyFactory,
	useAuthUser,
	useUpdateMetric,
	useWorkspace,
} from '../../../../../../api';
import { useGetOrCreateMetric } from '../../../../../../api/hooks/metricTerm/useGetOrCreateMetric';
import queryClient from '../../../../../../api/queryClient';
import type { Metric } from '../../../../../../lib/models';
import type { DjangoValueType } from '../../../../../../pages/TemplatePage/types';
import { trackEvent } from '../../../../../../utils/analytics';
import { entityIdFromCurrentPath } from '../../../../../../utils/shared.utils';
import SqlCharts from '../../../../../Sql/SqlCharts';
import SqlChartsStore from '../../../../../Sql/store';

interface MetricContainerProps {
	id: string;
	readOnly: boolean;
	withQuery?: boolean;
	withChart?: boolean;
}

export function MetricContainer({
	id,
	readOnly,
	withQuery,
	withChart,
}: MetricContainerProps) {
	const { workspace } = useWorkspace();
	const { user } = useAuthUser();
	const { data } = useGetOrCreateMetric({
		id,
		hidden: true,
		parent: entityIdFromCurrentPath(),
	});

	const { mutateAsync } = useUpdateMetric({
		options: {
			onSuccess: (successData) => {
				// Updating queryClient cache when query_block is edited
				// otherwise query_block will be empty when made through cmd + k menu until refresh
				queryClient.setQueryData(
					[...metricsQueryKeyFactory.all(), 'get_or_create', id], // QueryKey from useGetOrCreateMetric
					successData
				);
			},
		},
	});

	const [store, setStore] = useState(
		new SqlChartsStore(data as unknown as Metric)
	);

	useLayoutEffect(() => {
		if (id !== store?.metric.id) {
			setStore(new SqlChartsStore(data as unknown as Metric));
		}
	}, [data, id, store?.metric.id]);

	const updateMetric = async (entries: Record<string, DjangoValueType>) => {
		trackEvent(
			'metric/embedded/update',
			{
				id: store.metric.id,
			},
			user,
			workspace
		);
		const updated = Object.entries(entries).reduce(
			(acc, [key, value]) => ({
				...acc,
				[key]: value,
			}),
			{}
		);
		(store.metric as any) = { ...store.metric, ...updated };
		await mutateAsync({ data: { id, ...updated }, optimisticUpdate: false });
	};

	return (
		<SqlCharts
			withQuery={withQuery}
			withChart={withChart}
			canEdit={!readOnly}
			store={store}
			onChange={updateMetric}
		/>
	);
}

export default MetricContainer;
