import { observer } from 'mobx-react-lite';
import SingleSelector from '../SingleSelector/SingleSelector';
import { useAddMonitorStoreContext } from './context';

function IntegrationSelector() {
	const store = useAddMonitorStoreContext();

	const handleChange = (id: string) => {
		store.setFormFields('integration', id);
	};

	return (
		<SingleSelector
			variant="default"
			iconType="react-node"
			isViewerUser={false}
			searchable
			options={store.integrationOptions}
			placeholder="Select an integration"
			onChange={handleChange}
			initialSelected={store.getFormValue('integration') ?? ''}
			property="integration"
			readOnly={Boolean(store.initialIntegration)}
		/>
	);
}

export default observer(IntegrationSelector);
