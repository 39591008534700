import { wrappingInputRule } from 'prosemirror-inputrules';
import type {
	Attrs,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import ReactDOM from 'react-dom';
import { NativeSelect } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { ParseSpec } from 'prosemirror-markdown';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import toggleWrap from '../commands/toggleWrap';
import noticesRule from '../rules/notices';
import Node, { NodeOptions } from './Node';

export default class Notice extends Node {
	get styleOptions() {
		return Object.entries({
			info: this.options.dictionary.info,
			warning: this.options.dictionary.warning,
			tip: this.options.dictionary.tip,
		});
	}

	get name() {
		return 'container_notice';
	}

	get rulePlugins() {
		return [noticesRule];
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				style: {
					default: 'info',
				},
			},
			content: 'block+',
			group: 'block',
			defining: true,
			draggable: true,
			parseDOM: [
				{
					tag: 'div.notice-block',
					preserveWhitespace: 'full',
					contentElement: 'div.content',
					getAttrs: (dom: HTMLElement | string) => {
						if (typeof dom === 'string') {
							return null;
						}

						let style = 'info';

						if (dom.className.includes('tip')) {
							style = 'tip';
						}

						if (dom.className.includes('warning')) {
							style = 'warning';
						}

						return {
							style,
						};
					},
				},
			],
			toDOM: (node) => {
				let component;

				if (node.attrs.style === 'tip') {
					component = <Icon name="starFilled" color="icon/info/default" />;
				} else if (node.attrs.style === 'warning') {
					component = (
						<Icon name="alertTriangleFilled" color="icon/warning/default" />
					);
				} else {
					component = (
						<Icon name="alertCircleFilled" color="icon/info/default" />
					);
				}

				const icon = document.createElement('div');
				icon.className = 'icon';
				ReactDOM.render(component, icon);

				const blockActions = document.createElement('div');
				blockActions.className = 'block-actions notice-actions';
				blockActions.contentEditable = 'false';

				const options = this.styleOptions.map(([key, label]) => ({
					value: key,
					label,
				}));

				ReactDOM.render(
					<NativeSelect
						rightSection={<Icon name="chevronDown" />}
						className="block-select"
						data={options}
						value={node.attrs.style}
						size="xs"
						onChange={this.handleStyleChange}
					/>,
					blockActions
				);

				return [
					'div',
					{ class: `block-container notice-block ${node.attrs.style}` },
					icon,
					blockActions,
					['div', { class: 'content' }, 0],
				];
			},
		};
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Attrs) => toggleWrap(type, attrs);
	}

	handleStyleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { view } = this.editorState;
		const { tr } = view.state;
		const element = event.target;
		const { top, left } = element.getBoundingClientRect();
		const result = view.posAtCoords({ top, left });

		if (result) {
			const transaction = tr.setNodeMarkup(result.inside, undefined, {
				style: element.value,
			});
			view.dispatch(transaction);
		}
	};

	inputRules({ type }: NodeOptions) {
		return [wrappingInputRule(/^:::$/, type)];
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.write(`\n:::${node.attrs.style || 'info'}\n`);
		state.renderContent(node);
		state.ensureNewLine();
		state.write(':::');
		state.closeBlock(node);
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'container_notice',
			getAttrs: (tok) => ({ style: tok.info }),
		};
	}
}
