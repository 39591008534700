import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IEventProperty } from '../../types';

export const EVENT_PROPERTIES_NAMESPACE = ['event', 'event_properties'];

export const eventPropertiesQueryKeyFactory = createQueryKeys(
	EVENT_PROPERTIES_NAMESPACE
);

const { prefetchEventProperty, prefetchEventPropertyList } =
	prefetchFunctionsFactory('eventProperty', eventPropertiesQueryKeyFactory);

const {
	useEventProperty,
	useEventPropertyInfiniteList,
	useEventPropertyList,
	useCreateEventProperty,
	useDeleteEventProperty,
	useUpdateEventProperty,
	fetchEventPropertyList,
	updateEventProperty,
} = baseQueryHooksFactory<IEventProperty, 'eventProperty'>(
	'eventProperty',
	eventPropertiesQueryKeyFactory
);

export {
	updateEventProperty,
	fetchEventPropertyList,
	prefetchEventProperty,
	prefetchEventPropertyList,
	useEventProperty,
	useEventPropertyInfiniteList,
	useEventPropertyList,
	useCreateEventProperty,
	useDeleteEventProperty,
	useUpdateEventProperty,
};
