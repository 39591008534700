import { search } from '../../../lib/models';
import type { Filter } from '../../codegen/apiSchemas';
import type {
	ApiCatalogSort,
	IApiListResponse,
	IUseListQueryHookArgs,
	SearchResult,
} from '../../types';
import { useBaseModelList } from '../base';
import { SEARCH_NAMESPACE, searchQueryKeyFactory } from './constants';

interface IUseSearchArgs<T> extends IUseListQueryHookArgs<SearchResult, T> {
	searchTerm?: string;
	filters?: Record<string, string | number | boolean>;
	filterV2?: Filter;
	sortV2?: ApiCatalogSort;
	onSearchComplete?: () => void;
}

export function useSearch<TData = IApiListResponse<SearchResult>>({
	page = 1,
	searchTerm = '',
	filters = {},
	filterV2,
	sortV2,
	onSearchComplete,
	options,
}: IUseSearchArgs<TData>) {
	const filterV1Params = { search_term: searchTerm, ...filters, page };
	const filterV2Params = filterV2
		? { search_term: searchTerm, filters: filterV2, page }
		: undefined;
	const filterParams = { sort: sortV2, ...(filterV2Params ?? filterV1Params) };
	const queryKey = searchQueryKeyFactory.list(page, filterParams);

	const queryFn = async () => {
		const { data } = await search(filterParams);
		if (onSearchComplete) {
			onSearchComplete();
		}
		return data;
	};

	return useBaseModelList<SearchResult, TData, unknown>({
		queryKey,
		queryFn,
		namespace: SEARCH_NAMESPACE,
		page,
		filters: filterParams,
		options: options || {},
	});
}
