import { Group, Skeleton, UnstyledButton, createStyles } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { ISecodaEntity, IUser } from '../../api';
import { UserAvatar } from '../UserAvatar';
import { ResourcePreview } from '../ResourcePreview';
import { isUserPreview } from '../ResourcePreview/ResourcePreview';
import { SecodaEntityIcon } from '../SecodaEntity/SecodaEntityIcon';
import { useResourceLink } from './useResourceLink';

type ResourceLinkSize = 'sm' | 'md';

const useStyles = createStyles(
	(
		theme,
		{ isSelected, size }: { isSelected: boolean; size: ResourceLinkSize }
	) => ({
		wrapper: {
			display: 'inline-block',
			verticalAlign: 'bottom',
		},
		anchor: {
			display: 'block',
			cursor: 'pointer !important',
			textDecoration: 'none !important',
			color: `${theme.other.getColor('text/primary/default')} !important`,
			backgroundColor: theme.other.getColor('fill/tertiary/default'),
			borderRadius: theme.radius.sm,
			border: `1px solid ${
				isSelected
					? theme.other.getColor('border/tertiary/default')
					: 'transparent'
			}`,
			padding: `0 ${theme.spacing['2xs']} 0 ${theme.spacing['4xs']}`,

			'&:hover': {
				backgroundColor: theme.other.getColor('fill/tertiary/hover'),
			},
			'&:active': {
				backgroundColor: theme.other.getColor('fill/tertiary/active'),
			},
		},
		text: {
			fontSize: theme.fontSizes[size],
			lineHeight: theme.other.typography.lineHeight.text[size],
		},
	})
);

interface ResourceLinkProps {
	href: string | undefined | null;
	isSelected?: boolean;
	size?: ResourceLinkSize;
}

export function ResourceLink({
	href,
	isSelected = false,
	size = 'sm',
}: ResourceLinkProps) {
	const { id, data, resourceType, isLoading, hasError } = useResourceLink({
		href,
	});
	const { classes } = useStyles({ isSelected, size });

	if (!href) {
		return null;
	}

	if (isLoading) {
		return <Skeleton className={classes.wrapper} width={200} height={22} />;
	}

	if (hasError || !data || !id) {
		return <a href={href}>{href}</a>;
	}

	return (
		<div className={classes.wrapper}>
			<ResourcePreview
				id={id}
				data={data}
				resourceType={resourceType}
				isLoading={isLoading}
				hasError={hasError}
			>
				<UnstyledButton
					component="a"
					href={href}
					className={classes.anchor}
					data-testid="resource-link"
				>
					<Group spacing="4xs" align="center">
						{isUserPreview(data) ? (
							<>
								<UserAvatar user={data as IUser} size="xxs" />
								<Text className={classes.text}>
									{(data as IUser).display_name}
								</Text>
							</>
						) : (
							<>
								<SecodaEntityIcon
									entity={
										data.entity_type === 'monitor' ? data.targetEntity : data
									}
									inline
									size={20}
								/>
								<Text className={classes.text}>
									{(data as ISecodaEntity).title}
								</Text>
							</>
						)}
					</Group>
				</UnstyledButton>
			</ResourcePreview>
		</div>
	);
}
