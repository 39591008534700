import { Group, Modal, Stack, createStyles } from '@mantine/core';
import { useRef } from 'react';
import { Text } from '@repo/foundations';
import { Button } from '@repo/foundations';
import { useDataQualityImage } from '../../../api/hooks/quality/useDataQualityImage';
import { DataQualityScore } from '../../../api/codegen/apiSchemas';
import { DataQualityShareContent } from './DataQualityShareContent';

const useStyles = createStyles((theme) => ({
	header: {
		background: theme.other.getColor('surface/secondary/default'),
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
	},
	content: {
		borderRadius: theme.radius.lg,
	},
	body: {
		padding: theme.spacing.md,

		'&:not(:only-child)': {
			paddingTop: theme.spacing.md,
		},
	},
	wrapper: {
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		background: theme.other.getColor('surface/primary/default'),
	},
	details: {
		flex: 1,
	},
}));

interface DataQualityShareModalProps {
	initialOpened?: boolean;
	dataQualityScore: DataQualityScore;
	onClose: () => void;
}

export function DataQualityShareModal({
	initialOpened = false,
	dataQualityScore,
	onClose,
}: DataQualityShareModalProps) {
	const { classes } = useStyles();
	const ref = useRef(null);

	const { copy, download } = useDataQualityImage(dataQualityScore);

	if (!dataQualityScore) {
		return null;
	}

	return (
		<Modal
			size={480}
			opened={initialOpened}
			onClose={onClose}
			title={
				<Text size="md" fw={600}>
					Share image
				</Text>
			}
			classNames={{
				header: classes.header,
				content: classes.content,
				body: classes.body,
			}}
		>
			<Stack spacing={0}>
				<DataQualityShareContent
					dataQualityScore={dataQualityScore}
					ref={ref}
				/>
				<Group pt="md" spacing="xs" position="right">
					<Button leftIconName="copy" onClick={copy}>
						Copy
					</Button>
					<Button leftIconName="download" onClick={download}>
						Download
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
