import type { MantineColor } from '@mantine/core';
import { Badge, createStyles } from '@mantine/core';
import type { User } from '../../../../lib/models';
import { coalesceName } from '../../../../utils/authorization/roles';
import { UserAvatar } from '../../../UserAvatar';
import { primaryTextColor, primaryTextSize, primaryTextWeight } from '../theme';

interface IStyleParams {
	inline?: boolean;
	bg?: MantineColor;
}

interface IUserChipProps extends IStyleParams {
	user?: User;
}

const useStyles = createStyles((theme, { inline, bg }: IStyleParams) => ({
	badge: {
		borderWidth: 0,
		backgroundColor: bg || theme.colors.gray[0],
		padding: inline ? 0 : '0 12px',
	},
	label: {
		fontSize: primaryTextSize,
		color: primaryTextColor,
		fontWeight: primaryTextWeight,
		textTransform: 'none',
	},
}));

function UserChip({ user, inline = false, bg }: IUserChipProps) {
	const { classes } = useStyles({ inline, bg });

	const displayName = coalesceName(user);

	if (!user) {
		return null;
	}

	const avatar =
		user.id === 'empty' ? null : <UserAvatar size="xxs" user={user} />;

	return (
		<Badge
			size="md"
			classNames={{
				root: classes.badge,
				inner: classes.label,
			}}
			leftSection={avatar}
		>
			{user.disabled ? <s>{displayName}</s> : displayName}
		</Badge>
	);
}

export default UserChip;
