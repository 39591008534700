import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';
import {
	DashboardCommonColumns,
	DataEntityColumns,
	DefaultColumns,
	DescriptionColumns,
	PopularityColumns,
} from './common';

export const DashboardColumns: ColumnDefinition = {
	title: {
		field: 'title',
		editable: false,
		sortable: true,

		checkboxSelection: true,

		type: ColumnType.textFilterColumn,
		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		minWidth: 250,
	},
	group: {
		field: 'group',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
	},
	url: {
		field: 'url',
		editable: true,
		sortable: false,
		type: ColumnType.textFilterColumn,
	},
	...PopularityColumns,
	...DescriptionColumns,
	...DataEntityColumns,
	...DefaultColumns,
	...DashboardCommonColumns,
};
