import { Flex } from '@mantine/core';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { PlainButton, Text, Title } from '@repo/foundations';
import { AuthCard } from './AuthCard';
import { AuthLayoutBase } from './AuthLayouts/AuthLayoutBase';
import { AuthStepEmailComponent } from './AuthStepEmailComponent';
import { AuthStepSsoComponent } from './AuthStepSsoComponent';
import { AuthStepStartContext } from './AuthStepStartContext';
import { AuthTermsAndPolicy } from './AuthTermsAndPolicy';

export function AuthStepLogin() {
	const context = useContext(AuthStepStartContext);
	if (!context) {
		throw new Error(
			'AuthStepLogin must be used within a AuthStepStartContextProvider'
		);
	}
	const navigate = useNavigate();
	const { setError, setEmail, resetSSO, forceSSO } = context;

	const handleSwitchToRegister = useCallback(() => {
		setError(null);
		navigate('/auth/signup');
	}, [navigate, setError]);

	const handleBackToLogin = useCallback(() => {
		setError(null);
		setEmail('');
		resetSSO();
	}, [setError, setEmail, resetSSO]);

	return (
		<AuthLayoutBase>
			<Flex gap="xl" direction="column">
				<AuthCard>
					<Title order={1}>Welcome back to Secoda</Title>
					<Flex direction="column" py="xl">
						<AuthStepSsoComponent />
						<AuthStepEmailComponent cta="Log in with email" />
					</Flex>
					<AuthTermsAndPolicy />
				</AuthCard>
				{forceSSO ? (
					<PlainButton onClick={handleBackToLogin} size="md">
						Back to login
					</PlainButton>
				) : (
					<Flex gap="xs" justify="center">
						<Text size="md">Don&apos;t have an account?</Text>
						<PlainButton
							onClick={handleSwitchToRegister}
							rightIconName="arrowRight"
							size="md"
						>
							Sign up
						</PlainButton>
					</Flex>
				)}
			</Flex>
		</AuthLayoutBase>
	);
}
