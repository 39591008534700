import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { apiClient, getEndpoints } from '../../common';
import type { MeasurementChartData } from '../../types';
import {
	measurementsQueryKeyFactory,
	monitorsQueryKeyFactory,
} from './constants';

export type TimeRange = '7' | '14' | '30';

interface useMeasurementsGraphDataArgs {
	incidentId?: string;
	monitorId?: string;
	incidentDate?: string;
	timeRange?: TimeRange;
}

export function useMeasurementsChartData(
	args: useMeasurementsGraphDataArgs = {}
) {
	const { monitorId, incidentId, incidentDate, timeRange = '7' } = args;

	return useQuery({
		queryKey: monitorsQueryKeyFactory.byArgs(
			'aggregate',
			args as Record<string, unknown>
		),
		queryFn: async () => {
			if (!monitorId && !incidentId) {
				throw new Error('monitorId or incidentId is required');
			}

			const url = getEndpoints(measurementsQueryKeyFactory.namespace).byPath([
				'aggregate',
			]);

			let start = dayjs()
				.utc()
				.subtract(Number(timeRange), 'day')
				.format('YYYY-MM-DD HH:mm:ss');
			let end = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');

			if (incidentDate) {
				start = dayjs(incidentDate)
					.utc()
					.subtract(36, 'hours')
					.format('YYYY-MM-DD HH:mm:ss');
				end = dayjs(incidentDate)
					.utc()
					.add(36, 'hours')
					.format('YYYY-MM-DD HH:mm:ss');
			}

			const queryParams = {
				monitor: monitorId,
				incident: incidentId,
				start,
				end,
				aggregate: 'hour',
			};

			const { data } = await apiClient.get<MeasurementChartData[]>(url, {
				params: queryParams,
			});

			return data;
		},
		enabled: Boolean(monitorId) || Boolean(incidentId),
	});
}
