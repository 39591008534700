import { EntityType } from '../../../lib/types';

export const getDocumentationEditorPlaceholder = (entityType?: EntityType) => {
	switch (entityType) {
		case EntityType.document:
			return 'Add document information...';
		case EntityType.dictionary_term:
			return 'Add dictionary term information...';
		case EntityType.metric:
			return 'Add brief metric description...';
		case EntityType.question:
			return 'Describe your question...';
		case EntityType.query:
			return 'Add query description...';
		default:
			return 'Add information...';
	}
};
