import { sortBy } from 'lodash-es';
import type { IMetric } from '../../../api';
import { Metric } from '../../../lib/models';
import type { EntityType } from '../../../lib/types';
import type { DocumentTreeNode } from '../constants';

export const getNodeFromMetric = (
	metric: Metric | IMetric
): DocumentTreeNode => ({
	id: metric.id,
	name: metric.title,
	entityType: metric.entity_type as EntityType,
	hasChildren: metric.children_count > 0,
	fetchedChildren: false,
	children: [],
	isOpen: false,
	isLoading: false,
});

export const getMetricNodeChildren = async (node: DocumentTreeNode) => {
	const children = await Metric.listAll({
		parent_id: node.id,
		calculate_children_count: true,
	});

	return sortBy(children, 'title').map((metric) => getNodeFromMetric(metric));
};
