import {
	Box,
	Flex,
	Group,
	LoadingOverlay,
	PinInput,
	Stack,
	createStyles,
	useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlainButton, Text, Title } from '@repo/foundations';
import { api } from '../../../network';
import { trackEventAnonymous } from '../../utils/analytics';
import { AuthLayoutBase } from './AuthLayouts/AuthLayoutBase';
import { AuthCard } from './AuthCard';
import {
	anonymousId,
	EMAIL_VERIFICATION_LOCAL_STORAGE_KEY,
	referrer,
} from './AuthStepEmailComponent.constants';

const useStyles = createStyles((theme) => ({
	page: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		height: '100vh',
		alignItems: 'center',
	},
	emailText: {
		overflowWrap: 'break-word',
		width: '100%',
		color: theme.other.getColor('text/secondary/default'),
	},
}));

interface AuthStepVerifyProps {
	initialCode?: string;
	initialVerifyErrorCode?: string;
	email?: string;
}

function getEmailFromStorage() {
	return localStorage.getItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY);
}

export function AuthStepVerify({
	initialCode,
	initialVerifyErrorCode,
	email,
}: AuthStepVerifyProps) {
	const CODE_LENGTH = 8;
	const EMAIL = email || getEmailFromStorage();

	const styles = useStyles();
	const theme = useMantineTheme();
	const navigate = useNavigate();
	const [params] = useSearchParams();

	const [visible, { close, open }] = useDisclosure(false);

	const [code, setCode] = useState<string>(initialCode || '');
	const [verifyCodeError, setVerifyCodeError] = useState<string | null>(
		initialVerifyErrorCode || null
	);

	const handleResendCode = useCallback(async () => {
		// If a user requests a new code
		setCode('');
		setVerifyCodeError(null);

		axios
			.post(`${api()}auth/u/resend/`, {
				// We use the email from local storage because it is potentially more up-to-date than the EMAIL `const` above.
				email: localStorage.getItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY),
			})
			.then((res) => {
				const { message } = res.data;
				if (/^Can only resend in/.test(message)) {
					showNotification({
						message:
							'Verification codes can only be resent every few minutes. Please try again shortly.',
						color: 'red',
					});
				} else {
					showNotification({
						message,
						color: 'green',
					});
				}
			})
			.catch((res) => {
				showNotification({
					message: res.response.data.message,
					color: 'red',
				});
			});
	}, []);

	useEffect(() => {
		// This is used when we want to verify the email from a search param.
		// It is called if a user signs in with SSO but the IDP indicates their email isn't verified.
		const paramsEmail = params.get('email');
		if (paramsEmail && !isEmpty(paramsEmail)) {
			localStorage.setItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY, paramsEmail);
			// This serves two purposes:
			// It re-renders the component with the passed email.
			// It sets a message to the user that the email has been sent.
			handleResendCode();
		}
	}, [handleResendCode, params]);

	const handleVerifyCode = useCallback(
		async (verifyEmail: string, verifyCode: string) => {
			// We set an artificial delay to make the loading overlay visible.
			setTimeout(() => {
				trackEventAnonymous(
					'auth/verify',
					{
						path: window.location.pathname,
						anonymous_id: anonymousId(),
						referrer: referrer(),
					},
					localStorage.getItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY) ?? ''
				);

				axios
					.post(`${api()}auth/u/verify/`, {
						email: verifyEmail,
						code: verifyCode,
					})
					.then((res) => {
						localStorage.setItem('id', res.data.id);

						if (res.data.workspace_id) {
							navigate('/onboarding/confirm');
						} else {
							navigate('/onboarding/profile');
						}
					})
					.catch((res) => {
						setVerifyCodeError(res.response.data.message);
						close();
					});
			}, 300);
		},
		[close, navigate]
	);

	const handleCodeChange = useCallback(
		(value: string) => {
			const cleanValue = value.toUpperCase().trim();
			setCode(cleanValue);
			if (cleanValue.length === CODE_LENGTH && cleanValue !== code && EMAIL) {
				// We open the loading overlay.
				open();

				handleVerifyCode(EMAIL, cleanValue);
			}
		},
		[handleVerifyCode, EMAIL, code, open]
	);

	return (
		<Box className={styles.classes.page}>
			<LoadingOverlay
				visible={visible}
				overlayBlur={2}
				loaderProps={{ color: theme.other.neutralColors.black }}
			/>
			<AuthLayoutBase
				onClickBackButton={() => {
					navigate('/auth');
				}}
			>
				<AuthCard>
					<Stack h="100%">
						<Group spacing="3xs">
							<Title order={1}>Check your email</Title>
							<Text className={styles.classes.emailText}>
								An email with a verification code has been sent to{' '}
								<strong>{EMAIL}</strong>.
							</Text>
						</Group>
						<Flex direction="column" align="center" gap="xs">
							<PinInput
								error={verifyCodeError !== null}
								onChange={handleCodeChange}
								value={code}
								type="alphanumeric"
								inputMode="text"
								length={CODE_LENGTH}
								placeholder=""
								aria-label="One time code"
								autoFocus
							/>
							{verifyCodeError && (
								<Text color="text/critical/default">{verifyCodeError}</Text>
							)}
						</Flex>
					</Stack>
				</AuthCard>
				<Flex pt="xl" direction="column" align="center">
					<Flex gap="xs" justify="center">
						<Text>Didn&apos;t get a verification code it?</Text>
						<PlainButton onClick={handleResendCode} size="md">
							Send a new code
						</PlainButton>
					</Flex>
				</Flex>
			</AuthLayoutBase>
		</Box>
	);
}
