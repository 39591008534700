import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';
import { DescriptionColumns } from './common';

export type AutomationCardFields = 'title' | 'status' | 'description';

export const AutomationColumns: ColumnDefinition = {
	title: {
		field: 'title',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
		flex: 1,

		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		checkboxSelection: true,
	},
	status: {
		field: 'status',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
		flex: 1,
	},
	next_run_at: {
		field: 'next_run_at',
	},
	last_run_at: {
		field: 'last_run_at',
	},
	schedule: {
		field: 'schedule',
		sortable: true,

		editable: false,
	},
	description: {
		...DescriptionColumns.description,
		minWidth: 300,
	},
};
