import { DataTableColumn, uniqBy } from '@repo/mantine-datatable';
import produce from 'immer';
import {
	capitalize,
	cloneDeep,
	compact,
	isArray,
	isNil,
	maxBy,
	sortBy,
} from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { Catalog } from '../../../api';
import { useAuthUser } from '../../../api';

import {
	useCatalogProperties,
	useCatalogToggleView,
	useUpdateCatalogProperties,
} from '../../../api/hooks/catalog';
import { CUSTOM_PROPERTY_COLUMN_PREFIX } from '../../../constants';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { ExtendedDataTableColumn } from '../../TableV2/types';
import { DataQualityColumns } from '../TableView/columns/common';
import {
	CATALOG_COLUMN_MAPPING,
	CATALOG_TYPES_ENABLE_DATA_QUALITY_SCORE,
} from '../constants';
import { getColumnDisplayName } from '../helpers';
import type { CatalogServer } from '../types';
import { ColumnType } from '../types';

export interface UseColumnDefsArgs<T> {
	defaultColumns: DataTableColumn<T>[];
	catalogType: Catalog['catalog_type'];
	catalogServerType: CatalogServer['type'];
	entityId?: Catalog['entity_id'];
	forceReadOnly?: boolean;
	isEditorOrAdminUser?: boolean;
	disableSorting?: boolean;
	checkboxSelection?: boolean;
	suspense?: boolean;
}

const getCustomPropertyColumnDef = (
	columnName: string,
	hidden: boolean,
	headerName?: string
): any => ({
	hide: hidden,
	field: columnName,
	headerName: headerName ?? getColumnDisplayName(columnName),
	sortable: false,
	editable: true,
	type: ColumnType.textFilterColumn,
	minWidth: 250,
});

export function useColumnDefs<T>({
	defaultColumns,
	catalogType,
	catalogServerType,
	entityId = null,
	forceReadOnly = false,
	isEditorOrAdminUser = false,
	disableSorting = false,
	checkboxSelection = false,
	suspense = false,
}: UseColumnDefsArgs<T>) {
	const { isViewerOrGuestUser } = useAuthUser();
	const { data: _catalog, refetch: refetchCatalog } = useCatalogProperties(
		{
			catalog_type: catalogType,
			entity_id: entityId,
		},
		suspense
	);

	const { mutateAsync: updateCatalogProperties } = useUpdateCatalogProperties();
	const { mutateAsync: toggleCatalogView } = useCatalogToggleView();
	const { dataQualityScore } = useFeatureFlags();

	const canShowDataQualityScoreColumn =
		dataQualityScore &&
		CATALOG_TYPES_ENABLE_DATA_QUALITY_SCORE.includes(catalogServerType);

	const catalog: Catalog | null = useMemo(() => {
		if (!_catalog) {
			return null;
		}

		if (_catalog && !isArray(_catalog?.properties)) {
			return {
				..._catalog,
				properties:
					defaultColumns?.map((column, idx) => ({
						value: column.accessor as string,
						order: idx,
						hidden: false,
					})) ?? [],
			} as Catalog;
		}

		return {
			..._catalog,
			properties: [
				..._catalog.properties
					.filter((el) => {
						if (el.value === 'dqs' || el.value === 'dqs.total') {
							return canShowDataQualityScoreColumn;
						}
						return true;
					})
					.sort((a, b) => a.order - b.order),
				...defaultColumns
					.filter((column) =>
						isNil(
							_catalog.properties.find(
								(property) =>
									property.value === column.accessor ||
									property.value ===
										(column as ExtendedDataTableColumn<T>)?.esAccessor
							)
						)
					)
					.map((column, idx) => ({
						value: column.accessor as string,
						order:
							(maxBy(_catalog.properties, (el) => el.order)?.order ?? 0) +
							idx +
							1,
						hidden: false,
					})),
			],
		} as Catalog;
	}, [_catalog, defaultColumns, canShowDataQualityScoreColumn]);

	const { server, columns: columnDefinitions } =
		CATALOG_COLUMN_MAPPING[catalogServerType];

	const columnDefinitionsWithFeatureFlags = useMemo(() => {
		if (canShowDataQualityScoreColumn) {
			return {
				...columnDefinitions,
				...DataQualityColumns,
			};
		}
		return columnDefinitions;
	}, [canShowDataQualityScoreColumn, columnDefinitions]);

	const onColumnReorder = useCallback(
		async (column: string | number, toIndex: number) => {
			if (!catalog) {
				return null;
			}

			const draft = cloneDeep(catalog.properties ?? []);

			if (typeof column === 'number') {
				// Reorder column by index.
				const [removed] = draft.splice(column, 1);
				draft.splice(toIndex, 0, removed);
			} else {
				// Reorder column by name.
				const [removed] = draft.splice(
					draft.findIndex((f) => f.value === column),
					1
				);
				draft.splice(toIndex, 0, removed);
			}

			// Update order of all properties.
			draft.forEach((draftProperty, index) => {
				draftProperty.order = index;
			});

			const result = await updateCatalogProperties({
				...catalog,
				properties: uniqBy(draft, (el) => el.value),
			});

			return result;
		},
		[catalog, updateCatalogProperties]
	);

	const onColumnVisibilityChange = useCallback(
		async (columnName: string, isVisible: boolean) => {
			if (!catalog) {
				return null;
			}

			const properties = produce(catalog?.properties ?? [], (draft) => {
				const index = draft.findIndex(({ value }) => value === columnName);
				draft[index].hidden = !isVisible;
			});

			const result = await updateCatalogProperties({
				...catalog,
				properties,
			});

			return result;
		},
		[catalog, updateCatalogProperties]
	);

	const onAddColumn = useCallback(
		async (columnName: string) => {
			if (!catalog) {
				return;
			}

			const properties = produce(catalog?.properties ?? [], (draft) => {
				draft.push({
					value: columnName,
					order: draft.length,
					hidden: false,
				});
			});

			await updateCatalogProperties({
				...catalog,
				properties,
			});
		},
		[catalog, updateCatalogProperties]
	);

	const onDeleteColumn = useCallback(async () => {}, []);

	const onRenameColumn = useCallback(async () => {}, []);

	const columnDefs = useMemo(
		() =>
			compact(
				sortBy(catalog?.properties, 'order').map(({ value, hidden }, i) => {
					const columnDefinition = columnDefinitionsWithFeatureFlags?.[value];

					if (isViewerOrGuestUser && columnDefinition?.field === 'published') {
						return null;
					}

					if (
						!canShowDataQualityScoreColumn &&
						(value === 'dqs' || value === 'dqs.total')
					) {
						return null;
					}

					if (value.startsWith(CUSTOM_PROPERTY_COLUMN_PREFIX)) {
						return getCustomPropertyColumnDef(value, hidden) satisfies any;
					}

					if (!columnDefinition) {
						return {
							field: value,
							headerName: capitalize(value),
							hide: hidden,
							editable: false,
							sortable: false,
							filter: undefined,
						} satisfies any;
					}

					return {
						...columnDefinition,
						refData: {
							// For ReactTable
							fieldName: value,
						},
						headerName:
							columnDefinition.headerName ??
							getColumnDisplayName(
								columnDefinition?.field ?? value,
								catalogType
							),
						editable:
							!forceReadOnly &&
							isEditorOrAdminUser &&
							columnDefinition?.editable,
						sortable: !disableSorting && columnDefinition?.sortable,
						hide: hidden,
						checkboxSelection:
							!forceReadOnly &&
							isEditorOrAdminUser &&
							((i === 0 && checkboxSelection) ||
								columnDefinition?.checkboxSelection),
					} satisfies any;
				})
			),
		[
			catalog?.properties,
			catalogType,
			checkboxSelection,
			columnDefinitionsWithFeatureFlags,
			canShowDataQualityScoreColumn,
			disableSorting,
			forceReadOnly,
			isEditorOrAdminUser,
			isViewerOrGuestUser,
		]
	);

	const switchView = useCallback(
		async (view: Catalog['view']) => {
			if (!catalog) {
				return;
			}

			await toggleCatalogView({
				...catalog,
				view,
			});

			await refetchCatalog();
		},
		[catalog, refetchCatalog, toggleCatalogView]
	);

	return {
		catalog,
		server,
		view: catalog?.view,
		switchView,
		columnDefs,
		onColumnReorder,
		onColumnVisibilityChange,
		onAddColumn,
		onDeleteColumn,
		onRenameColumn,
	};
}
