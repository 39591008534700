import { observer } from 'mobx-react-lite';
import { Group, createStyles } from '@mantine/core';
import { TOP_NAVBAR_HEIGHT } from '../constants';
import { entitySideBarStore } from './../../EntityPageLayout/store';

export const useStyles = createStyles((theme) => ({
	sidesheetControlsWrapper: {
		height: `${TOP_NAVBAR_HEIGHT}px`,
		gap: theme.spacing.xs,
		flexWrap: 'nowrap',
		paddingLeft: theme.spacing.xs,
		justifyContent: 'flex-end',

		transition: 'width 200ms ease-in-out',
	},
}));

interface NavBarSidesheetControlsWrapperProps {
	children: React.ReactNode;
}

export const NavBarSidesheetControlsWrapper = observer(
	({ children }: NavBarSidesheetControlsWrapperProps) => {
		const { classes, theme } = useStyles();

		return (
			<Group
				className={classes.sidesheetControlsWrapper}
				style={{
					width: !entitySideBarStore.collapsed
						? `calc(${entitySideBarStore.width}px - ${theme.spacing['2xl']})`
						: undefined,
				}}
			>
				{children}
			</Group>
		);
	}
);
