import { Group, Stack, TextInput, useMantineTheme } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form/lib/types';
import * as yup from 'yup';
import { Icon, type IconNames, Text } from '@repo/foundations';
import { CustomSelector } from '../../CustomSelector';
import EmojiIconSelector from '../../EmojiIconSelector';
import { TeamType } from '../../../interfaces';

export const teamFormSchema = yup.object({
	icon: yup.string().required(),
	name: yup
		.string()
		.required('Must provide name')
		.trim()
		.min(1, 'Must be at least 1 character'),
	type: yup.string().oneOf([TeamType.OPEN, TeamType.CLOSED]).required(),
});

export type ITeamForm = yup.InferType<typeof teamFormSchema>;

export function TeamForm({ form }: { form: UseFormReturnType<ITeamForm> }) {
	const theme = useMantineTheme();

	return (
		<>
			<Stack spacing={4}>
				<Text fw={500} size="sm">
					Icon & name
				</Text>
				<Group spacing={8} align="start">
					<EmojiIconSelector
						icon={form.getInputProps('icon').value}
						title="teams-icon"
						onChange={(emoji) => form.getInputProps('icon').onChange(emoji)}
						iconProps={{
							variant: 'outline',
							// This 36px matches the current height of the mantine text input pretheming.
							h: '36px',
							w: '36px',
							sx: {
								paddingLeft: 5,
								borderColor: theme.colors.gray[3],
							},
						}}
					/>
					<TextInput
						sx={{ flexGrow: 1 }}
						size="sm"
						placeholder="e.g. Marketing"
						{...form.getInputProps('name')}
					/>
				</Group>
			</Stack>
			<Stack spacing={4}>
				<Text fw={500} size="sm">
					Permissions
				</Text>
				<Group spacing={8}>
					<CustomSelector
						menuProps={{
							width: 'target',
							position: 'bottom-start',
							withinPortal: true,
						}}
						targetProps={{ w: '100%' }}
						items={[
							{
								value: 'OPEN',
								label: 'Public',
								description: 'Anyone can see, browse, and join this team',
								iconName: 'users' as IconNames,
							},
							{
								value: 'CLOSED',
								label: 'Private',
								description: 'Only admins and team members can see this team',
								iconName: 'lock' as IconNames,
							},
						]}
						renderItem={(item) => (
							<Group align="center" py={8} px={12} spacing={8} noWrap>
								<Icon name={item.iconName} />
								<Stack spacing={0}>
									<Text size="sm">{item.label}</Text>
									<Text size="xs" color="text/secondary/default">
										{item.description}
									</Text>
								</Stack>
							</Group>
						)}
						{...form.getInputProps('type')}
					/>
				</Group>
			</Stack>
		</>
	);
}
