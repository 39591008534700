import type { TablerIconsProps } from '@tabler/icons-react';
import {
	IconBook,
	IconChartBar,
	IconChartHistogram,
	IconCircleCaretRight,
	IconClick,
	IconCloud,
	IconColumns,
	IconDatabase,
	IconFileDescription,
	IconFolder,
	IconFolders,
	IconPresentationAnalytics,
	IconQuestionMark,
	IconSchema,
	IconTable,
	IconTemplate,
	IconUserCircle,
} from '@tabler/icons-react';
import { capitalize } from 'lodash-es';
import { EntityType } from '../lib/types';
import { SecodaEntity } from '../lib/models';
import { ISecodaEntity } from '../api';

export interface IEntityTypeDisplayInfo {
	icon: (props: TablerIconsProps) => JSX.Element;
	label: string;
}

export const getEntityDisplayType = (
	entity: SecodaEntity | ISecodaEntity
): string => capitalize(entity.entity_type?.replace('_', ' '));

export const getEntityTypeDisplayInfo = (
	entityType: EntityType | undefined
): IEntityTypeDisplayInfo => {
	switch (entityType) {
		case EntityType.user:
			return {
				icon: IconUserCircle,
				label: 'People',
			};
		case EntityType.dictionary_term:
			return {
				icon: IconBook,
				label: 'Terms',
			};
		case EntityType.metric:
			return {
				icon: IconChartHistogram,
				label: 'Metrics',
			};
		case EntityType.question:
			return {
				icon: IconQuestionMark,
				label: 'Questions',
			};
		case EntityType.document:
			return {
				icon: IconFileDescription,
				label: 'Documents',
			};
		case EntityType.table:
			return {
				icon: IconTable,
				label: 'Tables',
			};
		case EntityType.dashboard:
			return {
				icon: IconPresentationAnalytics,
				label: 'Dashboards',
			};
		case EntityType.job:
			return {
				icon: IconCircleCaretRight,
				label: 'Jobs',
			};
		case EntityType.collection:
			return {
				icon: IconFolders,
				label: 'Collections',
			};
		case EntityType.column:
			return {
				icon: IconColumns,
				label: 'Columns',
			};
		case EntityType.chart:
			return {
				icon: IconChartBar,
				label: 'Charts',
			};
		case EntityType.event:
			return {
				icon: IconClick,
				label: 'Events',
			};
		case EntityType.database:
			return {
				icon: IconDatabase,
				label: 'Database',
			};
		case EntityType.schema:
			return {
				icon: IconSchema,
				label: 'Schema',
			};
		case EntityType.dashboard_group:
			return {
				icon: IconFolder,
				label: 'Dashboard Group',
			};
		case EntityType.job_group:
			return {
				icon: IconFolder,
				label: 'Job Group',
			};
		case EntityType.template:
			return {
				icon: IconTemplate,
				label: 'Templates',
			};
		default:
			return {
				icon: IconCloud,
				label: 'Resource',
			};
	}
};
