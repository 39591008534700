import { Box, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Icon, IconButton, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuthUser } from '../../../api';
import { useApiListTeams } from '../../../api/codegen/apiComponents';
import { useRefs } from '../../../hooks/utils';
import { NewTeamModal } from '../../NewTeamModal/NewTeamModal';
import SideBarLink from './SideBarLink';
import { sideBarStore } from './store';
import type { TeamSideBarItemHandle } from './TeamSideBarItem';
import { TeamSideBarItem, useTeamSideBarItemStyle } from './TeamSideBarItem';

function SideBarTeamsLinks() {
	const [
		createTeamModalOpened,
		{ close: closeCreateTeamModal, open: openCreateTeamModal },
	] = useDisclosure(false);

	const location = useLocation();
	const { isAdminUser, isGuestUser } = useAuthUser();

	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			suspense: false,
		}
	);
	const { classes, cx, theme } = useTeamSideBarItemStyle({
		open: true,
		sideBarCollapsed: !!sideBarStore.collapsed,
	});

	const { refs: teamMenuRefs, setRefFromKey } =
		useRefs<TeamSideBarItemHandle>();
	const navigate = useNavigate();

	const onTeamCreationFinish = (teamId: string) => {
		const teamMenuRef = teamMenuRefs[teamId];
		if (teamMenuRef) {
			teamMenuRef.open();
		}

		navigate(`/teams/${teamId}/`);
	};

	return (
		<Flex
			direction="column"
			align={sideBarStore.collapsed ? 'center' : 'flex-start'}
		>
			<NewTeamModal
				opened={createTeamModalOpened}
				onClose={closeCreateTeamModal}
				onFinish={onTeamCreationFinish}
			/>
			{!sideBarStore.collapsed && !isGuestUser && (
				<Flex
					className={classes.nonButtonParent}
					w="100%"
					h={theme.other.space[7]}
					pl={theme.other.space[1]}
					align="center"
					justify="space-between"
				>
					<Text size="xs" color="text/secondary/default" weight="bold">
						Teams
					</Text>
					{isAdminUser && (
						<IconButton
							onClick={openCreateTeamModal}
							className={cx(classes.hiddenButton, classes.displayOnHover)}
							iconName="plus"
							variant="tertiary"
							size="sm"
							data-testid="sidebar-create-team-button"
							tooltip="Create new team"
							tooltipProps={{ position: 'right', withArrow: true }}
							mr="xs"
						/>
					)}
				</Flex>
			)}
			<Box pb={theme.other.space[0.25]} w="100%">
				<SideBarLink
					link={{
						id: 'nav::teams',
						label: 'Browse teams',
						icon: <Icon name="users" color="icon/primary/default" />,
						href: ['/teams'],
						matchExact: true,
						show: !isGuestUser,
					}}
					selected={location.pathname === '/teams'}
				/>
			</Box>
			{teams &&
				teams.results.map((team) => (
					<TeamSideBarItem
						ref={setRefFromKey(team.id)}
						key={team.id}
						team={team}
						sideBarCollapsed={!!sideBarStore.collapsed}
					/>
				))}
		</Flex>
	);
}

export default observer(SideBarTeamsLinks);
