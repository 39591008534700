import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import {
	useFloating,
	useId,
	useInteractions,
	useDismiss,
	useRole,
	useClick,
	offset,
	shift,
	flip,
	size,
	limitShift,
	useListNavigation,
	FloatingList,
} from '@floating-ui/react';
import { ListBoxTarget } from './ListBoxTarget';
import { ListBoxDropdown } from './ListBoxDropdown';
import { ListBoxItem } from './ListBoxItem';
import { ListBoxDivider } from './ListBoxDivider';
import { ListBoxContext, ListBoxContextType } from './context';
import { ListBoxItemsDropdown } from './ListBoxItemsDropdown';

export interface ListBoxProps {
	opened: boolean;
	onOpenChange: (opened: boolean) => void;
	closeOnEscape?: boolean;
	onKeyDown?: (event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => void;
	disabled?: boolean;
	targetRef?: HTMLElement | null;
}

export function ListBox({
	opened,
	onOpenChange,
	children,
	onKeyDown,
	closeOnEscape = true,
	disabled,
	targetRef,
}: PropsWithChildren<ListBoxProps>) {
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const dropdownId = useId();
	const targetId = useId();

	const { refs, floatingStyles, context } = useFloating({
		open: opened,
		onOpenChange: onOpenChange,
		strategy: 'fixed',
		placement: 'bottom-start',
		elements: targetRef
			? {
					reference: targetRef,
				}
			: undefined,
		middleware: [
			offset(8),
			shift({ limiter: limitShift() }),
			flip({ padding: 5 }),
			size({
				padding: 5,
				apply({ availableHeight }) {
					const styles = refs.floating.current?.style ?? {};

					Object.assign(styles, {
						maxHeight: `${availableHeight}px`,
					});
				},
			}),
		],
	});

	const listRef = useRef<Array<HTMLButtonElement>>([]);

	const listNav = useListNavigation(context, {
		listRef,
		activeIndex,
		loop: true,
		virtual: true,
		focusItemOnOpen: true,
		onNavigate: setActiveIndex,
	});
	const click = useClick(context);
	const dismiss = useDismiss(context, {
		escapeKey: closeOnEscape,
	});
	const role = useRole(context, { role: 'listbox' });

	const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
		[listNav, click, dismiss, role]
	);

	const listBoxContext = useMemo(
		() =>
			({
				activeIndex,
				setActiveIndex,
				getItemProps,
				getReferenceProps,
				getFloatingProps,
				dropdownId,
				opened,
				refs,
				targetId,
				floatingStyles,
				floatingContext: context,
				onKeyDown,
				listRef,
				disabled,
			}) as ListBoxContextType,
		[
			activeIndex,
			setActiveIndex,
			dropdownId,
			floatingStyles,
			getFloatingProps,
			getItemProps,
			getReferenceProps,
			opened,
			refs,
			targetId,
			context,
			onKeyDown,
			listRef,
			disabled,
		]
	);

	return (
		<FloatingList elementsRef={listRef}>
			<ListBoxContext.Provider value={listBoxContext}>
				{children}
			</ListBoxContext.Provider>
		</FloatingList>
	);
}
ListBox.Target = ListBoxTarget;
ListBox.Dropdown = ListBoxDropdown;
ListBox.ItemsDropdown = ListBoxItemsDropdown;
ListBox.Item = ListBoxItem;
ListBox.Divider = ListBoxDivider;
