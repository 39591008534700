/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import {
	Box,
	Center,
	Loader,
	MantineSize,
	createStyles,
	getStylesRef,
	packSx,
	px,
	type MantineTheme,
} from '@mantine/core';
import {
	memo,
	useCallback,
	useMemo,
	type CSSProperties,
	type Key,
} from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import DataTableEmptyRow from './DataTableEmptyRow';
import DataTableEmptyState from './DataTableEmptyState';
import DataTableHeader from './DataTableHeader';
import DataTableLoader from './DataTableLoader';
import DataTablePagination from './DataTablePagination';
import DataTableRow from './DataTableRow';
import { VirtuosoTableComponent } from './VirtuosoTableComponent';
import {
	DataTableContext,
	VirtuosoRowComponentWrapper,
} from './ViruosoRowComponent';
import { useRowContextMenu, useRowExpansion } from './hooks';
import type { DataTableProps } from './types';
import { differenceBy, getRecordId, uniqBy } from './utils';

const EMPTY_OBJECT = {};

const useStyles = createStyles(
	(
		theme,
		{
			borderColor,
			rowBorderColor,
			withStickyColumnBorder,
		}: {
			borderColor: string | ((theme: MantineTheme) => string);
			rowBorderColor: string | ((theme: MantineTheme) => string);
			withStickyColumnBorder: boolean;
		}
	) => {
		const borderColorValue =
			typeof borderColor === 'function' ? borderColor(theme) : borderColor;
		const rowBorderColorValue =
			typeof rowBorderColor === 'function'
				? rowBorderColor(theme)
				: rowBorderColor;
		const shadowGradientAlpha = theme.colorScheme === 'dark' ? 0.5 : 0.05;

		return {
			root: {
				position: 'relative',
				zIndex: 0,
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
				tr: {
					backgroundColor:
						theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
				},
				'&&': {
					'thead tr th': {
						borderBottomColor: borderColorValue,
						':is(:first-of-type)': {
							paddingLeft: theme.spacing.md,
						},
					},
					'tbody tr td': {
						borderTopColor: rowBorderColorValue,
						':is(:first-of-type)': {
							paddingLeft: theme.spacing.md,
						},
					},
				},
			},
			lastRowBorderBottomVisible: {
				'tbody tr:last-of-type td': {
					borderBottom: `1px solid ${rowBorderColorValue}`,
				},
			},
			textSelectionDisabled: {
				userSelect: 'none',
			},
			table: {
				borderCollapse: 'separate',
				borderSpacing: 0,
			},
			tableWithBorder: {
				border: withStickyColumnBorder
					? `1px solid ${borderColorValue}`
					: undefined,
			},
			resizableColumnHeaderKnobTransparent: {
				[`& .${getStylesRef('resizableColumnHeaderKnob')}`]: {
					background: 'transparent',
				},
			},
			tableWithColumnBorders: {
				'&&': {
					'th, td': {
						':not(:first-of-type)': {
							borderLeft: `1px solid ${rowBorderColorValue}`,
						},
					},
				},
			},
			tableWithColumnBordersAndSelectableRecords: {
				thead: {
					'tr + tr': {
						th: {
							borderLeft: `1px solid ${rowBorderColorValue}`,
						},
					},
				},
			},
			verticalAlignmentTop: {
				td: {
					verticalAlign: 'top',
				},
			},
			verticalAlignmentBottom: {
				td: {
					verticalAlign: 'bottom',
				},
			},
			pinLastColumn: {
				'th:last-of-type, td:last-of-type': {
					position: 'sticky',
					right: 0,
					zIndex: 1,
					background: 'inherit',
					'&::after': {
						content: "''",
						position: 'absolute',
						top: 0,
						bottom: 0,
						width: theme.spacing.sm,
						background: `linear-gradient(to left, ${theme.fn.rgba(theme.black, shadowGradientAlpha)}, ${theme.fn.rgba(
							theme.black,
							0
						)}), linear-gradient(to left, ${theme.fn.rgba(theme.black, shadowGradientAlpha)}, ${theme.fn.rgba(
							theme.black,
							0
						)} 30%)`,
						borderRight: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
						left: -px(theme.spacing.sm),
						pointerEvents: 'none',
						opacity: 0,
						transition: 'opacity 0.2s',
					},
				},
			},
			pinnedColumnShadowVisible: {
				'th:last-of-type, td:last-of-type': {
					'&::after': {
						opacity: 1,
					},
				},
			},
		};
	}
);

const defaultGetSelectionCheckboxProps = <T extends any>(
	_: T,
	index: number
) => ({
	'data-testid': `select-record-${index + 1}`,
	'aria-label': `Select record ${index + 1}`,
});

function DataTableComponent<T>({
	withBorder,
	borderRadius,
	borderColor = (theme) =>
		theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
	rowBorderColor = (theme) =>
		theme.fn.rgba(
			theme.colorScheme === 'dark'
				? theme.colors.dark[4]
				: theme.colors.gray[3],
			0.65
		),
	withStickyColumnBorder = true,
	withColumnBorders = false,
	textSelectionDisabled,
	height = '100%',
	maxHeight,
	minHeight,
	shadow,
	nested,
	verticalAlignment = 'center',
	fetching,
	nextPageFetching,
	columns,
	storeColumnsKey,
	groups,
	pinLastColumn,
	defaultColumnProps,
	defaultColumnRender,
	idAccessor = 'id',
	records,
	selectedRecordsState,
	onSelectedRecordsStateChange,
	isRecordSelectable,
	allRecordsSelectionCheckboxProps = { 'aria-label': 'Select all records' },
	getRecordSelectionCheckboxProps = defaultGetSelectionCheckboxProps,
	sortStatus,
	sortIcons,
	onSortStatusChange,
	horizontalSpacing,
	page,
	onPageChange,
	totalRecords,
	recordsPerPage,
	onRecordsPerPageChange,
	recordsPerPageOptions,
	recordsPerPageLabel = 'Records per page',
	paginationColor,
	paginationSize = 'sm',
	paginationText = ({ from, to, totalRecords }) =>
		`${from} - ${to} / ${totalRecords}`,
	paginationWrapBreakpoint = 'sm',
	getPaginationControlProps = (control) => {
		if (control === 'previous') {
			return { 'aria-label': 'Previous page' };
		} else if (control === 'next') {
			return { 'aria-label': 'Next page' };
		}
		return {};
	},
	loaderBackgroundBlur,
	customLoader,
	loaderSize,
	loaderVariant,
	loaderColor,
	loadingText = '...',
	emptyState,
	endReached,
	noRecordsText = 'No records',
	noRecordsIcon,
	noHeader: withoutHeader,
	onRowClick,
	onCellClick,
	rowContextMenu,
	rowExpansion,
	rowClassName,
	rowStyle,
	rowSx,
	customRowAttributes,
	m,
	my,
	mx,
	mt,
	mb,
	ml,
	mr,
	sx,
	className,
	classNames,
	style,
	styles,
}: DataTableProps<T> & {
	withStickyColumnBorder?: boolean;
	nested?: boolean;
	nextPageFetching?: boolean;
	endReached?: () => void;
}) {
	const effectiveColumns = useMemo(
		() => groups?.flatMap((group) => group.columns) ?? columns!,
		[columns, groups]
	);

	const { rowContextMenuInfo } = useRowContextMenu<T>(fetching);

	const rowExpansionInfo = useRowExpansion<T>({
		rowExpansion,
		records,
		idAccessor,
	});

	const recordsLength = records?.length;
	const recordIds = records?.map((record) => getRecordId(record, idAccessor));
	const selectionColumnVisible = !!selectedRecordsState;
	const selectedRecordIds = useMemo(
		() =>
			selectedRecordsState?.selectedRecords?.map((record) =>
				getRecordId(record, idAccessor)
			),
		[selectedRecordsState?.selectedRecords, idAccessor]
	);
	const hasRecordsAndSelectedRecords =
		recordIds !== undefined &&
		selectedRecordIds !== undefined &&
		selectedRecordIds.length > 0;

	const selectableRecords = isRecordSelectable
		? records?.filter(isRecordSelectable)
		: records;
	const selectableRecordIds = selectableRecords?.map((record) =>
		getRecordId(record, idAccessor)
	);

	const allSelectableRecordsSelected =
		hasRecordsAndSelectedRecords &&
		selectableRecordIds!.every((id) => selectedRecordIds.includes(id));
	const someRecordsSelected =
		hasRecordsAndSelectedRecords &&
		selectableRecordIds!.some((id) => selectedRecordIds.includes(id));

	const handleHeaderSelectionChange = useCallback(() => {
		if (selectedRecordsState?.selectedRecords && onSelectedRecordsStateChange) {
			onSelectedRecordsStateChange({
				selectedRecords: allSelectableRecordsSelected
					? selectedRecordsState.selectedRecords.filter(
							(record) =>
								!selectableRecordIds!.includes(getRecordId(record, idAccessor))
						)
					: uniqBy(
							[...selectedRecordsState.selectedRecords, ...selectableRecords!],
							(record) => getRecordId(record, idAccessor)
						),
				lastSelectedIndex: selectedRecordsState.lastSelectedIndex,
			});
		}
	}, [
		allSelectableRecordsSelected,
		idAccessor,
		onSelectedRecordsStateChange,
		selectableRecordIds,
		selectableRecords,
		selectedRecordsState,
	]);

	const { cx, classes, theme } = useStyles({
		borderColor,
		rowBorderColor,
		withStickyColumnBorder,
	});
	const marginProperties = { m, my, mx, mt, mb, ml, mr };
	const styleProperties =
		typeof styles === 'function'
			? styles(theme, EMPTY_OBJECT, EMPTY_OBJECT)
			: styles;

	const allRecordsSelectability = JSON.stringify(
		(records || []).map((record, index) =>
			isRecordSelectable ? isRecordSelectable(record, index) : true
		)
	);
	const handleSelectionChange = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			record: T,
			recordIndex: number
		) => {
			if (!onSelectedRecordsStateChange) {
				return;
			}

			onSelectedRecordsStateChange((currentSelectedState) => {
				const { selectedRecords, lastSelectedIndex } = currentSelectedState;
				const recordId = getRecordId(record, idAccessor);
				const isSelected = currentSelectedState.selectedRecords.some(
					(r) => getRecordId(r, idAccessor) === recordId
				);
				if (
					(e.nativeEvent as PointerEvent).shiftKey &&
					lastSelectedIndex !== null &&
					records
				) {
					const targetRecords = records.filter(
						recordIndex > lastSelectedIndex
							? (r, index) =>
									index >= lastSelectedIndex &&
									index <= recordIndex &&
									(isRecordSelectable ? isRecordSelectable(r, index) : true)
							: (r, index) =>
									index >= recordIndex &&
									index <= lastSelectedIndex &&
									(isRecordSelectable ? isRecordSelectable(r, index) : true)
					);
					return {
						selectedRecords: isSelected
							? differenceBy(selectedRecords, targetRecords, (r) =>
									getRecordId(r, idAccessor)
								)
							: uniqBy([...selectedRecords, ...targetRecords], (r) =>
									getRecordId(r, idAccessor)
								),
						lastSelectedIndex: recordIndex,
					};
				} else {
					return {
						selectedRecords: isSelected
							? selectedRecords.filter(
									(r) => getRecordId(r, idAccessor) !== recordId
								)
							: uniqBy([...selectedRecords, record], (r) =>
									getRecordId(r, idAccessor)
								),
						lastSelectedIndex: recordIndex,
					};
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			onSelectedRecordsStateChange,
			isRecordSelectable,
			idAccessor,
			// Use this to avoid changing handleSelectionChange whenever records changes
			// (which happen whenever a record is updated) but doesn't affect selectability of records
			// (which are either all true or all false at the moment June 2024, but this might change)
			allRecordsSelectability,
		]
	);

	const context: DataTableContext<T> = useMemo(
		() => ({
			tableContext: {
				horizontalSpacing,
				className: cx(classes.table, {
					[classes.tableWithColumnBorders]: withColumnBorders,
					[classes.textSelectionDisabled]: textSelectionDisabled,
					[classes.verticalAlignmentTop]: verticalAlignment === 'top',
					[classes.verticalAlignmentBottom]: verticalAlignment === 'bottom',
					[classes.tableWithColumnBordersAndSelectableRecords]:
						selectionColumnVisible && withColumnBorders,
					[classes.pinLastColumn]: pinLastColumn,
				}),
			},
			columnContext: {
				onRowClick,
				defaultColumnProps,
				defaultColumnRender,
				isRecordSelectable,
				getRecordSelectionCheckboxProps,
				onCellClick,
				idAccessor,
				rowClassName,
				rowSx,
				customRowAttributes,
				rowStyle,
				effectiveColumns,
				selectedRecordIds,
				selectionColumnVisible,
				rowContextMenuInfo,
				rowExpansionInfo,
				handleSelectionChange,
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			horizontalSpacing,
			withColumnBorders,
			textSelectionDisabled,
			verticalAlignment,
			pinLastColumn,
			onRowClick,
			defaultColumnProps,
			defaultColumnRender,
			isRecordSelectable,
			getRecordSelectionCheckboxProps,
			onCellClick,
			idAccessor,
			rowClassName,
			rowSx,
			customRowAttributes,
			rowStyle,
			selectedRecordIds,
			handleSelectionChange,
			effectiveColumns,
			selectionColumnVisible,
			rowContextMenuInfo,
			rowExpansionInfo,
		]
	);

	if (!fetching && nested) {
		return (
			<>
				{records?.map((record, recordIndex) => {
					const recordId = getRecordId(record, idAccessor);
					return (
						<DataTableRow<T>
							sx={rowSx}
							key={recordId as Key}
							record={record}
							recordIndex={recordIndex}
							columns={effectiveColumns}
							defaultColumnProps={defaultColumnProps}
							defaultColumnRender={defaultColumnRender}
							isRecordSelectable={() => false}
							onSelectionChange={() => undefined}
							selectionVisible={selectionColumnVisible}
							selectionChecked={allSelectableRecordsSelected}
							getSelectionCheckboxProps={getRecordSelectionCheckboxProps}
							onCellClick={onCellClick}
							contextMenuVisible={false}
							expansion={rowExpansionInfo}
							className={rowClassName}
							style={rowStyle}
							customAttributes={customRowAttributes}
							leftShadowVisible={false}
							onClick={undefined}
							onContextMenu={undefined}
						/>
					);
				})}
			</>
		);
	}

	return (
		<Box
			{...marginProperties}
			className={cx(
				classes.root,
				{
					[classes.tableWithBorder]: withBorder,
					[classes.resizableColumnHeaderKnobTransparent]: withColumnBorders,
				},
				className,
				classNames?.root
			)}
			sx={[
				{
					borderRadius:
						theme.radius[borderRadius as MantineSize] || borderRadius,
					boxShadow: theme.shadows[shadow as MantineSize] || shadow,
					height,
					minHeight,
					maxHeight,
				},
				...packSx(sx),
			]}
			style={{ ...styleProperties?.root, ...style } as CSSProperties}
		>
			{(!fetching || nextPageFetching) && !nested && recordsLength ? (
				<TableVirtuoso<T, DataTableContext<T>>
					endReached={endReached}
					overscan={{
						main: 10,
						reverse: 10,
					}}
					increaseViewportBy={{
						top: 30 * 45,
						bottom: 30 * 45,
					}}
					placeholder="No records"
					style={{ height: '100%', width: '100%' }}
					data={records}
					context={context}
					computeItemKey={(index) => getRecordId(records[index], idAccessor)}
					fixedHeaderContent={
						!withoutHeader
							? () => (
									<DataTableHeader<T>
										className={classNames?.header}
										columns={effectiveColumns}
										defaultColumnProps={defaultColumnProps}
										groups={groups}
										sortStatus={sortStatus}
										sortIcons={sortIcons}
										onSortStatusChange={onSortStatusChange}
										selectionVisible={selectionColumnVisible}
										selectionChecked={allSelectableRecordsSelected}
										selectionIndeterminate={
											someRecordsSelected && !allSelectableRecordsSelected
										}
										onSelectionChange={handleHeaderSelectionChange}
										selectionCheckboxProps={allRecordsSelectionCheckboxProps}
										leftShadowVisible={false}
									/>
								)
							: undefined
					}
					components={{
						Table: VirtuosoTableComponent,
						TableRow: VirtuosoRowComponentWrapper,
					}}
				/>
			) : (
				<DataTableEmptyRow />
			)}
			{nextPageFetching && (
				// eslint-disable-next-line jsx-a11y/control-has-associated-label
				<tr>
					<Center pb={theme.other.space[3]} pt={theme.other.space[3]}>
						<Loader color="gray" size="xs" />
					</Center>
				</tr>
			)}
			{page && (
				<DataTablePagination
					className={classNames?.pagination}
					style={styleProperties?.pagination}
					topBorderColor={borderColor}
					horizontalSpacing={horizontalSpacing}
					fetching={fetching}
					page={page}
					onPageChange={onPageChange}
					totalRecords={totalRecords}
					recordsPerPage={recordsPerPage}
					onRecordsPerPageChange={onRecordsPerPageChange}
					recordsPerPageOptions={recordsPerPageOptions}
					recordsPerPageLabel={recordsPerPageLabel}
					paginationColor={paginationColor}
					paginationSize={paginationSize}
					paginationText={paginationText}
					paginationWrapBreakpoint={paginationWrapBreakpoint}
					getPaginationControlProps={getPaginationControlProps}
					noRecordsText={noRecordsText}
					loadingText={loadingText}
					recordsLength={recordsLength}
				/>
			)}
			<DataTableLoader
				pt={0}
				pb={0}
				fetching={fetching && !nextPageFetching}
				backgroundBlur={loaderBackgroundBlur}
				customContent={customLoader}
				size={loaderSize}
				variant={loaderVariant}
				color={loaderColor}
			/>
			<DataTableEmptyState
				pt={0}
				pb={0}
				icon={noRecordsIcon}
				text={noRecordsText}
				active={!fetching && !recordsLength}
			>
				{emptyState}
			</DataTableEmptyState>
		</Box>
	);
}

export const DataTable = memo(DataTableComponent) as typeof DataTableComponent;
export default DataTable;
