import type { IntegrationSpec } from '../../interfaces/IntegrationSpec';
import type { WidgetData } from '../WidgetSelector/types';
import { BuiltinIntegrationSpecCard } from './BuiltinIntegrationSpecCard';
import { MarketplaceIntegrationSpecCard } from './MarketplaceIntegrationSpecCard';

interface IntegrationCardProps {
	data: WidgetData<IntegrationSpec>;
	onClick: VoidFunction;
	withConnectButton?: boolean;
}

export function IntegrationSpecCard({
	data: widgetData,
	onClick,
	withConnectButton = true,
}: IntegrationCardProps) {
	if (widgetData.data.type === 'builtin') {
		return (
			<BuiltinIntegrationSpecCard
				spec={widgetData.data.value}
				onClick={onClick}
				withConnectButton={withConnectButton}
			/>
		);
	}

	return (
		<MarketplaceIntegrationSpecCard
			spec={widgetData.data.value}
			onClick={onClick}
			withConnectButton={withConnectButton}
		/>
	);
}
