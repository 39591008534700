import type { MantineTheme } from '@mantine/core';
import { ActionIcon, createStyles, Group, Menu, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { typography } from '@repo/theme/primitives';
import { Icon } from '@repo/foundations';
import { useNavigate } from '../../hooks/useNavigate';
import { pickColor } from '../../utils/utils';
import type { ItemIconType } from '../ItemIcon';
import type { SelectablePropertyItem } from '../SingleSelector/types';
import SelectorItemContent from './SelectorItemContent';
import SelectorItemCheckbox from './SelectorItemCheckbox';

export interface IMultiSelectorItemProps {
	iconType: ItemIconType;
	item: SelectablePropertyItem;
	isMenuItemBadge: boolean;
	isViewerUser: boolean;
	permittedId: string;
	onClick: (item: SelectablePropertyItem) => void;
	checked?: boolean;
	disabled?: boolean;
	disabledTooltip?: string;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	badge: {
		backgroundColor: theme.other.getColor('fill/transparent-secondary/default'),
	},
	badgeInner: {
		fontSize: theme.fontSizes.sm,
		fontWeight: typography.weight.regular,
		color: theme.other.getColor('text/primary/default'),
		lineHeight: '20px',
	},
	actionIcon: {
		backgroundColor: theme.other.getColor('fill/transparent/default'),
		width: 20,
		height: 20,
		padding: 0,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/transparent/active'),
		},
	},
}));

function MultiSelectorItem({
	iconType,
	item,
	isMenuItemBadge,
	isViewerUser,
	permittedId,
	onClick,
	checked = false,
	disabled,
	disabledTooltip,
}: IMultiSelectorItemProps) {
	const { hovered, ref } = useHover<HTMLButtonElement>();

	const { theme, classes } = useStyles();

	const navigate = useNavigate();

	let color: string;

	if (iconType === 'avatar') {
		color = pickColor(item.label);
	} else if (iconType === 'tag') {
		color = theme.other.getColor('icon/brand/default');
	} else {
		color =
			(item.color as string) || theme.other.getColor('icon/brand/default');
	}

	const RightSection = useMemo(
		() =>
			(!isViewerUser || permittedId === item.value) &&
			!isNil(item.navigateTo) &&
			hovered ? (
				<ActionIcon
					className={classes.actionIcon}
					size={20}
					onClick={(event) => {
						event.stopPropagation();
						navigate(item.navigateTo as string);
					}}
				>
					<Icon name="chevronRight" color="icon/primary/default" />
				</ActionIcon>
			) : null,
		[
			isViewerUser,
			item.value,
			item.navigateTo,
			hovered,
			classes.actionIcon,
			permittedId,
			navigate,
		]
	);

	const handleOnClick = () => {
		if (isViewerUser && !isNil(item.navigateTo) && permittedId !== item.value) {
			navigate(item.navigateTo);
		} else if (!disabled) {
			onClick(item);
		}
	};

	return (
		<Menu.Item
			ref={ref}
			icon={
				<SelectorItemCheckbox
					checked={checked}
					disabled={!!disabled}
					disabledTooltip={disabledTooltip}
					item={item}
					isViewerUser={isViewerUser}
					permittedId={permittedId}
				/>
			}
			rightSection={RightSection}
			onClick={handleOnClick}
		>
			<Group spacing={4}>
				{item.showDescriptionInTooltip && item.description ? (
					<Tooltip label={item.description} withinPortal openDelay={500}>
						<SelectorItemContent
							isMenuItemBadge={isMenuItemBadge}
							iconType={iconType}
							item={item}
							color={color}
						/>
					</Tooltip>
				) : (
					<SelectorItemContent
						isMenuItemBadge={isMenuItemBadge}
						iconType={iconType}
						item={item}
						color={color}
					/>
				)}
			</Group>
		</Menu.Item>
	);
}

export default MultiSelectorItem;
