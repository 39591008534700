import { isEmpty } from 'lodash-es';
import { Group, useMantineTheme } from '@mantine/core';
import { Icon } from '../Icon';
import { Text } from '../Text';

interface TextInputErrorProps {
	error?: string;
}

export function TextInputError({ error }: TextInputErrorProps) {
	const theme = useMantineTheme();

	if (isEmpty(error)) {
		return null;
	}

	return (
		<Group spacing={theme.spacing['2xs']} noWrap>
			<Icon name="alertCircle" color="text/critical/default" />
			<Text size="sm" color="text/critical/default">
				{error}
			</Text>
		</Group>
	);
}
