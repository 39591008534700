import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { AIAssistantMode } from './types';
import { AIAssistantStore } from './store';

const AIAssistantContext = createContext<AIAssistantStore | null>(null);

export interface AIAssistantContextProviderProps {
	children: ReactNode;
	mode: AIAssistantMode;
	id: string;
}

export function AIAssistantProvider({
	children,
	mode,
	id,
}: AIAssistantContextProviderProps) {
	const [store] = useState(() => new AIAssistantStore(mode));

	useEffect(() => {
		// updated the store whenever id changes
		store.setId(id);
	}, [id, store]);

	return (
		<AIAssistantContext.Provider value={store}>
			{children}
		</AIAssistantContext.Provider>
	);
}

export function useAIAssistantContext() {
	const ctx = useContext(AIAssistantContext);
	if (!ctx) {
		throw new Error(
			'useAIAssistantContext must be used within a AIAssistantProvider'
		);
	}
	return ctx;
}
