import { createStyles, getDefaultZIndex } from "@mantine/styles";

// all styles below were based off of Mantine's Popover and Menu components
// https://github.com/mantinedev/mantine/tree/v6/src/mantine-core/src/Menu
// https://github.com/mantinedev/mantine/tree/v6/src/mantine-core/src/Popover

export const useListBoxStyles = createStyles((theme) => ({
	dropdown: {
		background: theme.other.getColor('surface/primary/default'),
		border: `${theme.other.borderWidth.xs}px solid ${theme.other.getColor('border/secondary/default')}`,
		boxShadow: theme.shadows.md,
		borderRadius: theme.radius.md,
		padding: `0 0 ${theme.spacing['2xs']} 0`,
		display: 'flex',
		flexDirection: 'column',
    zIndex: getDefaultZIndex('popover'),
    width: 'max-content',
	},

	divider: {
		margin: `${theme.spacing['2xs']} 0`,
		borderTop: `${theme.other.borderWidth.xs}px solid ${theme.other.getColor('border/secondary/default')}`,
	},

	item: {
		...theme.fn.fontStyles(),
		WebkitTapHighlightColor: 'transparent',
		fontSize: theme.fontSizes.sm,
		border: 0,
		backgroundColor: 'transparent',
		outline: 0,
		textAlign: 'left',
		textDecoration: 'none',
		boxSizing: 'border-box',
		cursor: 'pointer',
		borderRadius: theme.radius.sm,
		color: theme.other.getColor('text/primary/default'),
		display: 'flex',
		alignItems: 'center',
		margin: `0 ${theme.spacing['2xs']}`,
		width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
		padding: theme.spacing['2xs'],

		'&:disabled': {
			color: theme.other.getColor('text/primary/disabled'),
			pointerEvents: 'none',
			userSelect: 'none',
		},

		'&[data-hovered], &:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},

	itemLabel: {
		flex: 1,
	},

	itemIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: theme.spacing.xs,
	},

	itemRightSection: {},
}));
