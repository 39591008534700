import type {
	MultiSelectProps,
	MultiSelectValueProps,
	SelectItem,
} from '@mantine/core';
import {
	createStyles,
	Group,
	MultiSelect,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import { forwardRef } from 'react';

const useValueComponentStyles = createStyles((theme) => ({
	container: {
		borderRadius: 1000,
		backgroundColor: theme.colors.gray[2],
	},
}));

function ValueComponent(
	props: {
		label: ReactNode;
		icon?: ReactNode;
	} & MultiSelectValueProps
) {
	const theme = useMantineTheme();
	const { classes } = useValueComponentStyles();

	const { label, icon, onRemove } = props;

	return (
		<Group noWrap spacing={0} p={4} className={classes.container}>
			{icon}
			<Text ml={4} size="sm">
				{label}
			</Text>
			<Group
				h={20}
				w={20}
				align="center"
				position="center"
				role="button"
				onMouseDown={onRemove}
			>
				<IconX color={theme.colors.gray[7]} size={12} />
			</Group>
		</Group>
	);
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		'&[aria-expanded=true]': {
			'.mantine-Input-wrapper': {
				overflow: 'unset !important',
				'.mantine-MultiSelect-input': {
					backgroundColor: theme.other.getColor('surface/input/default'),
					borderWidth: 0.5,
					borderColor: theme.other.getColor('border/input/active'),
					boxShadow: `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`,
				},
			},
		},
	},
	input: {
		backgroundColor: theme.other.getColor('surface/input/default'),
		borderRadius: theme.radius.sm,
		borderWidth: 0.5,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/input/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/input/default'),
			borderWidth: 0.5,
			borderColor: theme.other.getColor('border/input/hover'),
		},
		'&:disabled': {
			backgroundColor: theme.other.getColor('surface/primary/disabled'),
			borderWidth: 0,
		},
	},
	required: {
		visibility: 'hidden',
	},
	values: {
		margin: 0,
		display: 'flex',
		flexWrap: 'wrap',
		gap: 6,
		paddingTop: 6,
		paddingBottom: 6,
		paddingRight: 8,
	},
	item: {
		paddingTop: 6,
		paddingBottom: 6,
		paddingLeft: 8,
	},
	separatorLabel: {
		color: theme.colors.gray[6],
		fontSize: theme.fontSizes.sm,
		fontWeight: theme.other.typography.weight.semibold,

		'&::after': {
			flex: 0,
		},
	},
	searchInput: {
		height: 20,
		fontSize: theme.fontSizes.sm,
		lineHeight: '20px',
	},
}));

// eslint-disable-next-line react/display-name
const ItemComponent = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<'div'> & {
		label: ReactNode;
		icon: ReactNode;
		'data-disabled'?: boolean;
		'data-hovered'?: boolean;
		'data-selected'?: boolean;
	}
>((props, ref) => {
	const { label, icon, id, className, onMouseEnter, onMouseDown, ...rest } =
		props;

	return (
		<div
			data-testid={`select-item-${label}`}
			ref={ref}
			key={id}
			className={className}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			data-disabled={props['data-disabled']}
			data-hovered={props['data-hovered']}
			data-selected={props['data-selected']}
		>
			<Group noWrap spacing={0}>
				{icon}
				<Text ml={4} size="sm">
					{label}
				</Text>
			</Group>
		</div>
	);
});

export function CustomMultiSelect<T extends SelectItem>({
	data,
	setValue,
	value,
	renderIcon,
	renderLabel,
	nothingFound,
	...rest
}: {
	data: T[];
	value: string[];
	setValue: (xs: string[]) => void;
	renderIcon: (item: T) => ReactNode;
	renderLabel: (item: T) => ReactNode;
} & Omit<MultiSelectProps, 'filter' | 'data'>) {
	const { classes } = useStyles();

	return (
		<MultiSelect
			withinPortal
			dropdownPosition="bottom"
			classNames={classes}
			data={data}
			searchable
			// eslint-disable-next-line react/no-unstable-nested-components
			itemComponent={forwardRef<HTMLDivElement, T>((props, ref) => (
				<ItemComponent
					ref={ref}
					label={renderLabel(props)}
					icon={renderIcon(props)}
					{...props}
				/>
			))}
			rightSectionWidth={0}
			rightSection={<div />}
			value={value}
			onChange={setValue}
			// eslint-disable-next-line react/no-unstable-nested-components
			valueComponent={(props) => (
				<ValueComponent
					{...props}
					label={renderLabel(props)}
					icon={renderIcon(props)}
				/>
			)}
			nothingFound={nothingFound || 'Nothing found'}
			{...rest}
		/>
	);
}
