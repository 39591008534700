import type { IBaseModel } from '../baseModel';
import type {
	AutomationAction,
	AutomationActionUpdateEntity,
} from './automationActions';
import type { AutomationActionSendAnnouncement } from './automationAnnouncement';
import type { AutomationActionSendEmail } from './automationEmail';
import type {
	AutomationFilter,
	AutomationFilterConfig,
	AutomationFilterOperator,
} from './automationFilters';
import type { AutomationActionPropagateMetadata } from './automationPropagateMetadata';
import type { AutomationSchedule } from './automationSchedule';
import type { AutomationActionSendSlackMessage } from './automationSlack';

export enum AutomationTarget {
	SELF = 'SELF',
	PARENT = 'PARENT',
	CHILD = 'CHILD',
}

export enum AutomationTriggerType {
	SCHEDULE = 'SCHEDULE',
	SCHEMA_CHANGE = 'SCHEMA_CHANGE',
	TABLE_DROP = 'TABLE_DROP',
}

export enum AutomationField {
	// List fields: These support CONTAINS, DOES_NOT_CONTAIN, IS_SET, IS_NOT SET
	TAGS = 'tags',
	COLLECTIONS = 'collections',
	OWNERS = 'owners',
	SUBSCRIBERS = 'subscribers',
	SOURCES = 'sources',
	SLACK_CHANNELS = 'slack_channels',
	TEAMS = 'teams',

	// Text Value Fields
	// These support all conditions
	TITLE = 'title',
	TITLE_FULL = 'title_full',
	DEFINITION = 'definition',
	DESCRIPTION = 'description',
	DATABASE = 'database',
	SCHEMA = 'schema',
	TABLE = 'table',

	// Constrained Value Fields
	// These support IS and IS_NOT, IS_SET and IS_NOT_SET
	NATIVE_TYPE = 'native_type',
	INTEGRATION_ID = 'integration_id',

	// Boolean Value Fields
	// These support IS and IS_NOT
	PII = 'pii',
	VERIFIED = 'verified',
	PUBLISHED = 'published',
	QUALITY = 'dqs.total',
	QUALITY_ACCURACY = 'dqs.accuracy',
	QUALITY_RELIABILITY = 'dqs.reliability',
	QUALITY_USABILITY = 'dqs.usability',
	QUALITY_STEWARDSHIP = 'dqs.stewardship',

	// Date fields
	// These support GTE and LTE
	CREATED_AT = 'created_at',
	EXTERNAL_UPDATED_AT = 'external_updated_at',
	INTERNAL_USAGE = 'internal_usage',
	EXTERNAL_USAGE = 'external_usage',
}

export interface Automation extends IBaseModel {
	title: string;
	description?: string;
	status?: string;

	schedule: AutomationSchedule;
	trigger_type: AutomationTriggerType;

	filter_config?: AutomationFilterConfig | null;

	action_target: AutomationTarget;
	action_update_entities?: AutomationActionUpdateEntity[] | null;

	action_send_announcement?: AutomationActionSendAnnouncement | null;
	action_send_email?: AutomationActionSendEmail | null;
	action_send_slack_message?: AutomationActionSendSlackMessage | null;
	action_propagate_metadata?: AutomationActionPropagateMetadata | null;

	icon?: string;

	should_overwrite_metadata: boolean;
	is_enabled: boolean;

	last_run_at?: string;
	next_run_at?: string;

	workspace: string;

	// Legacy columns
	filter_operator: AutomationFilterOperator;
	filters: AutomationFilter[];
	actions: AutomationAction[];
}
