/**
 * @fileoverview Dark & light themes for monaco-editor
 *
 * @see https://microsoft.github.io/monaco-editor/playground.html#customizing-the-appearence-tokens-and-colors
 * @see https://github.com/microsoft/vscode/blob/1a58734121fed4606cbb804dc03b286fc7bb9c0a/src/vs/editor/standalone/common/themes.ts
 */

import { colors } from '@repo/theme/primitives';
import type { editor } from 'monaco-editor';

function token(tokenName: string, color: string) {
	return { token: tokenName, foreground: color };
}

function rgbToHex(rgba: string, forceRemoveAlpha = false) {
	return (
		'#' +
		rgba
			.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
			.split(',') // splits them at ","
			.filter((string: any, index: number) => !forceRemoveAlpha || index !== 3)
			.map((string: string) => parseFloat(string)) // Converts them to numbers
			.map((number: number, index: number) =>
				index === 3 ? Math.round(number * 255) : number
			) // Converts alpha to 255 number
			.map((number: { toString: (arg0: number) => string }) =>
				number.toString(16)
			) // Converts numbers to hex
			.map((string: string | number[]) =>
				string.length === 1 ? '0' + string : string
			) // Adds 0 when length of one number is 1
			.join('')
	); // Puts the array to togehter to a string
}

export const MonacoThemeLight: editor.IStandaloneThemeData = {
	base: 'vs',
	colors: {
		'editor.background': '#fcfcfc',
		'editor.foreground': rgbToHex(colors.gray[7]),
	},
	inherit: false,
	rules: [
		token('attribute.name', rgbToHex(colors.orange[2])),
		token('attribute.value', rgbToHex(colors.lime[2])),
		token('delimiter', rgbToHex(colors['black-alpha'][4])),
		token('function', rgbToHex(colors.blue[2])),
		token('identifier', rgbToHex(colors.orange[6])),
		token('identifier.function', rgbToHex(colors.orange[6])),
		token('number', rgbToHex(colors.green[2])),
		token('keyword', rgbToHex(colors.blue[5])),
		token('operator', rgbToHex(colors.blue[5])),
		token('type', rgbToHex(colors.pink[2])),
		token('string', rgbToHex(colors.violet[3])),
		token('tag', rgbToHex(colors.green[2])),
		token('type.identifier', rgbToHex(colors.yellow[3])),
	],
};

export const MonacoThemeDark: editor.IStandaloneThemeData = {
	base: 'vs-dark',
	colors: {
		'editor.background': rgbToHex(colors['black-alpha'][9]),
		'editor.foreground': rgbToHex(colors.gray[5]),
	},
	inherit: false,
	rules: [
		...MonacoThemeLight.rules,
		token('keyword', rgbToHex(colors.blue[4])),
		token('operator', rgbToHex(colors.blue[4])),
	],
};
