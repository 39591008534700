import { every } from 'lodash-es';
import { useEffect } from 'react';
import { useDatabuilderJobList } from '..';
import type { IDatabuilderJob } from '../../types';
import { DatabuilderJobType } from '../../../interfaces';
import queryClient from '../../queryClient';
import { databuilderJobQueryKeyFactory } from './constants';
import { useDatabuilderJobPollingContext } from './context';

type IPollArgs = {
	integrationId: string;
	type: 'PUSH' | 'PULL';
	enabled?: boolean;
};

type IPollResults = {
	databuilderJobs?: IDatabuilderJob[];
	isLoading: boolean;
	polling: boolean;
	setExtractionPolling: (r: boolean) => void;
};

export const usePollDatabuilderJobList = ({
	integrationId,
	type,
	enabled,
}: IPollArgs): IPollResults => {
	const { pollingState, setPollingState } = useDatabuilderJobPollingContext();
	const polling = pollingState[integrationId]?.[type] || false;

	const setExtractionPolling = (state: boolean) => {
		setPollingState(integrationId, type, state);
	};

	useEffect(() => {
		if (enabled) {
			setExtractionPolling(true);
		}
		return () => {
			setExtractionPolling(false);
		};
	}, []);

	const jobTypeFilter =
		type === 'PULL'
			? [
					DatabuilderJobType.SCHEMA,
					DatabuilderJobType.DUMMY,
					DatabuilderJobType.METADATA,
				]
			: [DatabuilderJobType.PUSH];
	const listFilter = {
		integration_id: integrationId,
		type__in: jobTypeFilter.join(','),
	};

	const { data: databuilderJobs, isLoading } = useDatabuilderJobList({
		filters: listFilter,
		options: {
			enabled: enabled,
			refetchInterval: () => (polling ? 2000 : false),
			select: (data) => data.results,
			onSuccess: (jobs) => {
				if (
					every(
						jobs,
						(job) =>
							job.failed || job.completed || job.terminated || job.timed_out
					)
				) {
					setExtractionPolling(false);
				}
			},
		},
	});

	return {
		databuilderJobs,
		isLoading,
		polling,
		setExtractionPolling,
	};
};

// Force fetching for synchronous UI actions without waiting for polling.
export const forceFetchJobs = () => {
	queryClient.invalidateQueries(databuilderJobQueryKeyFactory.all());
};
