import { useEffect, useMemo, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import {
	action,
	autorun,
	flow,
	flowResult,
	makeAutoObservable,
	toJS,
} from 'mobx';
import { useLocalObservable } from 'mobx-react-lite';
import {
	FilterDropdownType,
	FilterOptionType,
	type FilterItem,
	type FilterOption,
} from '../types';
import { FILTER_OPTIONS_DIVIDER } from '../constants';
import { createMockableHook } from '../../../utils/createMockableHook';
import { useFeatureFlags } from '../../../utils/featureFlags';

interface NestedFilterMenuItem {
	item: FilterItem;
	option: FilterOption;
}

interface UseNestedSearchProps {
	options: Array<FilterOption | typeof FILTER_OPTIONS_DIVIDER>;
}

class Searcher {
	result: Array<NestedFilterMenuItem> = [];

	pending: number = 0;

	isError: boolean = false;

	constructor() {
		makeAutoObservable(this, {
			execute: flow,
		});
	}

	*execute(searchTerm: string, options: Array<FilterOption>) {
		this.isError = false;
		this.pending = options.length;

		const allPromises = options.map(async (option) => {
			if (
				option.filterDropdownConfig.dropdownType !== FilterDropdownType.List
			) {
				// ignore dropdown types that are not lists
				// this also ignores the related dropdown, which would cause a subsequent search
				this.pending -= 1;
				return Promise.resolve([]);
			}

			const { getItems } = option.filterDropdownConfig;

			let promise: Promise<Array<FilterItem>>;

			if (typeof getItems !== 'function') {
				promise = Promise.resolve(
					getItems.filter((item) =>
						item.label.toLowerCase().includes(searchTerm.toLowerCase())
					)
				);
			} else {
				promise = Promise.resolve(getItems(1, searchTerm));
			}

			return promise
				.then((items) => items.map((item) => ({ item, option })))
				.finally(() => {
					action(() => {
						this.pending -= 1;
					});
				});
		});

		try {
			const items: Array<NestedFilterMenuItem> = yield Promise.all(allPromises);
			this.result = items.flat();
		} catch (error) {
			this.isError = true;
		} finally {
			this.pending = 0;
		}
	}
}

function useNestedSearchInternal({ options }: UseNestedSearchProps) {
	const { aiFilters } = useFeatureFlags();
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [searchItems, setSearchItems] = useState<Array<NestedFilterMenuItem>>(
		[]
	);

	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);
	const [debouncedLoading] = useDebouncedValue(loading, 300, { leading: true });
	const [isEmptyResults] = useDebouncedValue(
		!!debouncedSearchTerm &&
			!debouncedLoading &&
			!error &&
			searchItems.length === 0,
		300
	);

	const optionsWithoutDividers = useMemo(
		() =>
			options
				.filter((option) => {
					if (option === FILTER_OPTIONS_DIVIDER) {
						return null;
					}

					return option;
				})
				.filter(Boolean) as Array<FilterOption>,
		[options]
	);

	const searcher = useLocalObservable(() => new Searcher());

	useEffect(
		() =>
			autorun(() => {
				setLoading(searcher.pending > 0);
				setError(searcher.isError);
				setSearchItems(toJS(searcher.result));
			}),
		[searcher]
	);

	useEffect(() => {
		if (!debouncedSearchTerm) {
			setSearchItems([]);
			return () => {};
		}

		const result = flowResult(
			searcher.execute(debouncedSearchTerm, optionsWithoutDividers)
		);
		// result.cancel below will throw an cancellation error - it's safe to ignore it
		result.catch(() => {});

		return () => {
			result.cancel();
		};
	}, [debouncedSearchTerm, optionsWithoutDividers, searcher]);

	const searchOptions = useMemo(
		() =>
			!debouncedSearchTerm
				? options
				: optionsWithoutDividers.filter(
						(option) =>
							(aiFilters && option.type === FilterOptionType.AI) ||
							option.label
								.toLowerCase()
								.includes(debouncedSearchTerm.toLowerCase())
					),
		[debouncedSearchTerm, options, optionsWithoutDividers, aiFilters]
	);

	const items = useMemo(
		() => [...searchOptions, ...searchItems],
		[searchItems, searchOptions]
	);

	return {
		searchTerm,
		items,
		setSearchTerm,
		loading: debouncedLoading,
		error,
		isEmptyResults,
	};
}

export const [useNestedSearch, MockUseNestedSearchProvider] =
	createMockableHook(useNestedSearchInternal);
