import { camelCase, upperFirst } from 'lodash-es';

import {
	useBaseModel,
	useBaseModelInfiniteList,
	useBaseModelList,
	useCreateBaseModel,
	useDeleteBaseModel,
	useUpdateBaseModel,
} from '../hooks/base';
import { getDefaultListQueryFn } from '../hooks/base/useBaseModelList';
import { getDefaultUpdateFn } from '../hooks/base/useUpdateBaseModel';
import queryClient from '../queryClient';
import type {
	IApiListResponse,
	IBaseModel,
	IUseCreateMutationHookArgs,
	IUseDeleteMutationHookArgs,
	IUseInfiniteListQueryHookArgs,
	IUseListQueryHookArgs,
	IUseQueryHookArgs,
	IUseUpdateMutationHookArgs,
} from '../types';
import type { QueryKeyFactory } from './queryKeyFactory';
import type {
	CreateModelHook,
	CreateModelHookName,
	DeleteModelHook,
	DeleteModelHookName,
	FetchModelHook,
	FetchModelHookName,
	FetchModelInfiniteListHook,
	FetchModelInfiniteListHookName,
	FetchModelList,
	FetchModelListHook,
	FetchModelListHookName,
	FetchModelListName,
	QueryHooksFactory,
	UpdateModelHook,
	UpdateModelHookName,
	UpdateModelName,
} from './types';

/**
 * Returns the standard names of the hooks for a given model
 *
 * @param modelName The model for which to create the hooks
 * @returns An object containing the standard names of the hooks
 */
export const hookNameFactory = <IModelName extends string>(
	modelName: IModelName
) => {
	const hookModelName = upperFirst(camelCase(modelName));

	return {
		fetchModel: `use${hookModelName}` as FetchModelHookName<IModelName>,
		fetchModelList:
			`use${hookModelName}List` as FetchModelListHookName<IModelName>,
		fetchModelInfiniteList:
			`use${hookModelName}InfiniteList` as FetchModelInfiniteListHookName<IModelName>,
		createQuery: `useCreate${hookModelName}` as CreateModelHookName<IModelName>,
		updateQuery: `useUpdate${hookModelName}` as UpdateModelHookName<IModelName>,
		deleteQuery: `useDelete${hookModelName}` as DeleteModelHookName<IModelName>,
		directFetchModelList:
			`fetch${hookModelName}List` as FetchModelListName<IModelName>,
		directUpdateModel: `update${hookModelName}` as UpdateModelName<IModelName>,
	} as const;
};

/**
 * Returns a factory function that returns the hooks for a given model based on the BaseModel
 *
 * For ex., for an Integration model
 * ```ts
 *  queryHooksFactory<Integration, 'integration'>('integration', ['integration', 'integrations']]);
 * ```
 * will return the following hooks:
 *
 * - `useIntegration`
 * - `useIntegrationList`
 * - `useIntegrationInfiniteList`
 * - `useCreateIntegration`
 * - `useUpdateIntegration`
 * - `useDeleteIntegration`
 *
 * @param modelName The model for which to create the hooks
 * @param queryKeyFactory The query key factory for the model
 * @returns A factory function that returns the hooks for the given model based on the BaseModel
 */
export const baseQueryHooksFactory = <
	TModel extends IBaseModel,
	TModelName extends string,
>(
	modelName: TModelName,
	queryKeyFactory: QueryKeyFactory
) => {
	const hookNames = hookNameFactory<TModelName>(modelName);
	const useFetchModel: FetchModelHook<TModel> = <TData = TModel>({
		id,
		options,
	}: IUseQueryHookArgs<TModel, TData>) =>
		useBaseModel<TModel, TData>({
			id,
			queryKey: queryKeyFactory.byId(id),
			namespace: queryKeyFactory.namespace,
			options,
		});

	const useFetchModelList: FetchModelListHook<TModel> = <
		TData = IApiListResponse<TModel>,
	>({
		page,
		filters,
		options,
	}: IUseListQueryHookArgs<TModel, TData>) =>
		useBaseModelList<TModel, TData>({
			page,
			filters,
			queryKey: queryKeyFactory.list(page, filters),
			namespace: queryKeyFactory.namespace,
			options,
		});
	const fetchModelList: FetchModelList<TModel> = <
		TData = IApiListResponse<TModel>,
	>({
		page,
		filters,
		options,
	}: IUseListQueryHookArgs<TModel, TData>) =>
		queryClient.fetchQuery(
			queryKeyFactory.list(page, filters),
			getDefaultListQueryFn({ page, filters }, queryKeyFactory.namespace),
			options
		);

	const updateModel = getDefaultUpdateFn<TModel>(queryKeyFactory.namespace);

	const useFetchModelInfiniteList: FetchModelInfiniteListHook<TModel> = ({
		filters = {},
		options = {},
	}: IUseInfiniteListQueryHookArgs<TModel>) =>
		useBaseModelInfiniteList<TModel>({
			queryKey: queryKeyFactory.allLists(),
			namespace: queryKeyFactory.namespace,
			filters,
			options,
		});

	const useCreateQuery: CreateModelHook<TModel> = ({
		invalidationKeys = [],
		options,
	}: IUseCreateMutationHookArgs<TModel>) =>
		useCreateBaseModel<TModel>({
			invalidationKeys,
			namespace: queryKeyFactory.namespace,
			options,
		});

	const useUpdateQuery: UpdateModelHook<TModel> = ({
		disableOptimisticUpdate,
		disableInvalidation,
		invalidationKeys = [],
		options,
	}: IUseUpdateMutationHookArgs<TModel>) =>
		useUpdateBaseModel<TModel>({
			disableOptimisticUpdate,
			disableInvalidation,
			invalidationKeys,
			namespace: queryKeyFactory.namespace,
			options,
			queryKeyFactory,
		});
	const useDeleteQuery: DeleteModelHook<TModel> = ({
		options,
		invalidationKeys,
	}: IUseDeleteMutationHookArgs<TModel>) =>
		useDeleteBaseModel<TModel>({
			namespace: queryKeyFactory.namespace,
			invalidationKeys,
			options,
		});

	return {
		[hookNames.fetchModel]: useFetchModel,
		[hookNames.fetchModelList]: useFetchModelList,
		[hookNames.fetchModelInfiniteList]: useFetchModelInfiniteList,
		[hookNames.createQuery]: useCreateQuery,
		[hookNames.updateQuery]: useUpdateQuery,
		[hookNames.deleteQuery]: useDeleteQuery,
		[hookNames.directFetchModelList]: fetchModelList,
		[hookNames.directUpdateModel]: updateModel,
	} as QueryHooksFactory<TModel, TModelName>;
};
