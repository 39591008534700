import { isNil } from 'lodash-es';
import { Lineage } from '../../../lib/models';
import queryClient from '../../queryClient';
import type { IApiListResponse } from '../../types';
import { lineageQueryKeyFactory } from './constants';

export const fetchLineage = ({
	filters,
	page,
}: {
	filters: Record<string, string>;
	page: number;
}) => {
	if (isNil(filters?.id)) {
		throw new Error('id is required');
	}

	const queryFilters = {
		direction: 'UPSTREAM',
		depth: 3,
		...filters,
	};

	return queryClient.fetchQuery(
		lineageQueryKeyFactory.list(page, queryFilters),
		async () => {
			const data = await Lineage.list(queryFilters);
			return data as unknown as IApiListResponse<Lineage>;
		}
	);
};
