import React from 'react';
import type { ScrollAreaProps } from '@mantine/core';
import { Box, Center, ScrollArea, SimpleGrid } from '@mantine/core';
import { flatten, map, values } from 'lodash-es';
import { space } from '@repo/theme/primitives';
import { EmptyState } from '../EmptyState';
import type { WidgetData } from './types';
import {
	DefaultWidgetRenderer,
	type IWidgetSelectorGroupProps,
} from './WidgetSelectorGroup';

interface IWidgetSelectorFilterViewProps<T extends object = object> {
	data: Record<string, WidgetData<T>[]>;
	groupNames: string[];
	onWidgetSelect: IWidgetSelectorGroupProps<T>['onWidgetSelect'];
	scrollAreaHeight?: ScrollAreaProps['h'];
	widgetGroupProps?: Omit<
		IWidgetSelectorGroupProps<T>,
		| 'groupName'
		| 'widgets'
		| 'onHidden'
		| 'onVisible'
		| 'onWidgetSelect'
		| 'renderer'
	>;
	cols?: number;
	renderer?: IWidgetSelectorGroupProps<T>['renderer'];
}

function WidgetSelectorFilterView<T extends object = object>({
	data,
	groupNames,
	onWidgetSelect,
	scrollAreaHeight,
	widgetGroupProps,
	cols,
	renderer = DefaultWidgetRenderer,
}: IWidgetSelectorFilterViewProps<T>) {
	if (Object.keys(data).length === 0) {
		return (
			<Center w="100%" h={scrollAreaHeight}>
				<EmptyState
					title="No results found"
					description="Try refining your search or create a custom integration."
					iconName="search"
					includeGoBack={false}
					size="lg"
					stateHeight={space[40]}
				/>
			</Center>
		);
	}

	const widgets =
		groupNames[0] === 'All' ? flatten(values(data)) : data[groupNames[0]];

	return (
		<ScrollArea w="100%" h={scrollAreaHeight} offsetScrollbars>
			<SimpleGrid
				cols={cols}
				className={widgetGroupProps?.classNames?.widgetItemsWrapper}
				breakpoints={[
					{ maxWidth: 'sm', cols: 1 },
					{ maxWidth: 'md', cols: 2 },
				]}
			>
				{map(widgets, (widget) => {
					const props = {
						data: widget,
						onClick: () => onWidgetSelect(widget),
					};

					// Renderer from widget data takes precedence
					// over the one passed as a prop
					const widgetRenderer = widget?.renderer
						? widget.renderer(props)
						: React.createElement(renderer, props);

					return (
						<Box
							key={widget.key || `${groupNames[0]}__${widget.title}`}
							className={widgetGroupProps?.classNames?.widgetWrapper}
						>
							{widgetRenderer}
						</Box>
					);
				})}
			</SimpleGrid>
		</ScrollArea>
	);
}

export default WidgetSelectorFilterView;
