import { AppShell, Box, createStyles } from '@mantine/core';
import { Outlet } from 'react-router';
import Spotlight from '../Spotlight';
import { PropagateMetadataModal } from '../PropagateMetadata';
import SideBar from './SideBar';

const useStyles = createStyles((theme) => ({
	children: {
		height: '100%',
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'transparent',
	},
}));

function SecodaAppShell() {
	const { classes } = useStyles();

	return (
		<AppShell navbar={<SideBar />} padding={0}>
			<PropagateMetadataModal />
			<Box className={classes.children}>
				<Outlet />
			</Box>
			<Spotlight />
		</AppShell>
	);
}

export default SecodaAppShell;
