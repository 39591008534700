/* eslint-disable no-restricted-syntax */
import type * as React from 'react';
import styled from 'styled-components';
import { Icon } from '@repo/foundations';
import type { EmbedDescriptor } from '@repo/secoda-editor';
import Abstract from './Abstract';
import Airtable from './Airtable';
import Bilibili from './Bilibili';
import Cawemo from './Cawemo';
import ClickUp from './ClickUp';
import Codepen from './Codepen';
import Image from './components/Image';
import DBDiagram from './DBDiagram';
import Descript from './Descript';
import Diagrams from './Diagrams';
import Figma from './Figma';
import Framer from './Framer';
import Generic from './Generic';
import Gist from './Gist';
import GoogleCalendar from './GoogleCalendar';
import LookerStudio from './LookerStudio';
import GoogleDocs from './GoogleDocs';
import GoogleDrawings from './GoogleDrawings';
import GoogleDrive from './GoogleDrive';
import GoogleSheets from './GoogleSheets';
import GoogleSlides from './GoogleSlides';
import Hex from './Hex';
import InVision from './InVision';
import Loom from './Loom';
import Lucidchart from './Lucidchart';
import Marvel from './Marvel';
import Mindmeister from './Mindmeister';
import Miro from './Miro';
import ModeAnalytics from './ModeAnalytics';
import Pitch from './Pitch';
import Prezi from './Prezi';
import Spotify from './Spotify';
import Trello from './Trello';
import Typeform from './Typeform';
import Vimeo from './Vimeo';
import Whimsical from './Whimsical';
import YouTube from './YouTube';

export type EmbedProps = {
	isSelected: boolean;
	isEditable: boolean;
	embed: EmbedDescriptor;
	attrs: {
		href: string;
		matches: RegExpMatchArray;
	};
};

function matcher(Component: React.ComponentType<EmbedProps>) {
	return (url: string): boolean | [] | RegExpMatchArray => {
		// @ts-expect-error TS(2339): Property 'ENABLED' does not exist on type 'Compone... Remove this comment to see the full error message
		const regexes = Component.ENABLED;

		for (const regex of regexes) {
			const result = url.match(regex);

			if (result) {
				return result;
			}
		}

		return false;
	};
}

const Img = styled(Image)`
	margin: 4px;
	width: 18px;
	height: 18px;
`;

const embeds: EmbedDescriptor[] = [
	{
		title: 'Generic Embed',
		keywords: 'embed iframe',
		icon: () => <Icon name="globe" />,
		component: Generic,
		matcher: matcher(Generic),
	},
	{
		title: 'Abstract',
		keywords: 'design',
		// DefaultHidden: true,
		icon: () => <Img src="/images/abstract.webp" alt="Abstract" />,
		component: Abstract,
		matcher: matcher(Abstract),
	},
	{
		title: 'Airtable',
		keywords: 'spreadsheet',
		icon: () => <Img src="/images/airtable.webp" alt="Airtable" />,
		component: Airtable,
		matcher: matcher(Airtable),
	},
	{
		title: 'Bilibili',
		keywords: 'video',
		// DefaultHidden: true,
		icon: () => <Img src="/images/bilibili.webp" alt="Bilibili" />,
		component: Bilibili,
		matcher: matcher(Bilibili),
	},
	{
		title: 'Cawemo',
		keywords: 'bpmn process',
		// DefaultHidden: true,
		icon: () => <Img src="/images/cawemo.webp" alt="Cawemo" />,
		component: Cawemo,
		matcher: matcher(Cawemo),
	},
	{
		title: 'ClickUp',
		keywords: 'project',
		// DefaultHidden: true,
		icon: () => <Img src="/images/clickup.webp" alt="ClickUp" />,
		component: ClickUp,
		matcher: matcher(ClickUp),
	},
	{
		title: 'Codepen',
		keywords: 'code editor',
		// DefaultHidden: true,
		icon: () => <Img src="/images/codepen.webp" alt="Codepen" />,
		component: Codepen,
		matcher: matcher(Codepen),
	},
	{
		title: 'DBDiagram',
		keywords: 'diagram database db dbdiagram',
		icon: () => <Img src="/images/dbdiagram.webp" alt="DBDiagram" />,
		component: DBDiagram,
		matcher: matcher(DBDiagram),
	},
	{
		title: 'Descript',
		keywords: 'audio',
		// DefaultHidden: true,
		icon: () => <Img src="/images/descript.webp" alt="Descript" />,
		component: Descript,
		matcher: matcher(Descript),
	},
	{
		title: 'Figma',
		keywords: 'design svg vector',
		icon: () => <Img src="/images/figma.webp" alt="Figma" />,
		component: Figma,
		matcher: matcher(Figma),
	},
	{
		title: 'Framer',
		keywords: 'design prototyping',
		// DefaultHidden: true,
		icon: () => <Img src="/images/framer.webp" alt="Framer" />,
		component: Framer,
		matcher: matcher(Framer),
	},
	{
		title: 'GitHub Gist',
		keywords: 'code',
		icon: () => <Img src="/images/github-gist.webp" alt="GitHub" />,
		component: Gist,
		matcher: matcher(Gist),
	},
	{
		title: 'Diagrams.net',
		keywords: 'diagrams drawio',
		icon: () => <Img src="/images/diagrams.webp" alt="Diagrams.net" />,
		component: Diagrams,
		matcher: matcher(Diagrams),
	},
	{
		title: 'Google Drawings',
		keywords: 'drawings',
		icon: () => (
			<Img src="/images/google-drawings.webp" alt="Google Drawings" />
		),
		component: GoogleDrawings,
		matcher: matcher(GoogleDrawings),
	},
	{
		title: 'Google Drive',
		keywords: 'drive',
		// DefaultHidden: true,
		icon: () => <Img src="/images/google-drive.webp" alt="Google Drive" />,
		component: GoogleDrive,
		matcher: matcher(GoogleDrive),
	},
	{
		title: 'Google Docs',
		keywords: 'documents word',
		icon: () => <Img src="/images/google-docs.webp" alt="Google Docs" />,
		component: GoogleDocs,
		matcher: matcher(GoogleDocs),
	},
	{
		title: 'Google Sheets',
		keywords: 'excel spreadsheet',
		icon: () => <Img src="/images/google-sheets.webp" alt="Google Sheets" />,
		component: GoogleSheets,
		matcher: matcher(GoogleSheets),
	},
	{
		title: 'Google Slides',
		keywords: 'presentation slideshow',
		icon: () => <Img src="/images/google-slides.webp" alt="Google Slides" />,
		component: GoogleSlides,
		matcher: matcher(GoogleSlides),
	},
	{
		title: 'Google Calendar',
		keywords: 'calendar',
		// DefaultHidden: true,
		icon: () => (
			<Img src="/images/google-calendar.webp" alt="Google Calendar" />
		),
		component: GoogleCalendar,
		matcher: matcher(GoogleCalendar),
	},
	{
		title: 'Looker Studio',
		keywords: 'bi business intelligence',
		icon: () => <Img src="/images/looker-studio.webp" alt="Looker Studio" />,
		component: LookerStudio,
		matcher: matcher(LookerStudio),
	},
	{
		title: 'Hex Tech',
		keywords: 'hex tech analytics',
		icon: () => <Img src="/images/hex.webp" alt="Hex" />,
		component: Hex,
		matcher: matcher(Hex),
	},
	{
		title: 'InVision',
		keywords: 'design prototype',
		// DefaultHidden: true,
		icon: () => <Img src="/images/invision.webp" alt="InVision" />,
		component: InVision,
		matcher: matcher(InVision),
	},
	{
		title: 'Loom',
		keywords: 'video screencast',
		icon: () => <Img src="/images/loom.webp" alt="Loom" />,
		component: Loom,
		matcher: matcher(Loom),
	},
	{
		title: 'Lucidchart',
		keywords: 'chart',
		icon: () => <Img src="/images/lucidchart.webp" alt="Lucidchart" />,
		component: Lucidchart,
		matcher: matcher(Lucidchart),
	},
	{
		title: 'Marvel',
		keywords: 'design prototype',
		// DefaultHidden: true,
		icon: () => <Img src="/images/marvel.webp" alt="Marvel" />,
		component: Marvel,
		matcher: matcher(Marvel),
	},
	{
		title: 'Mindmeister',
		keywords: 'mindmap',
		icon: () => <Img src="/images/mindmeister.webp" alt="Mindmeister" />,
		component: Mindmeister,
		matcher: matcher(Mindmeister),
	},
	{
		title: 'Miro',
		keywords: 'whiteboard',
		icon: () => <Img src="/images/miro.webp" alt="Miro" />,
		component: Miro,
		matcher: matcher(Miro),
	},
	{
		title: 'Mode',
		keywords: 'analytics',
		// DefaultHidden: true,
		icon: () => <Img src="/images/mode-analytics.webp" alt="Mode" />,
		component: ModeAnalytics,
		matcher: matcher(ModeAnalytics),
	},
	{
		title: 'Pitch',
		keywords: 'presentation',
		// DefaultHidden: true,
		icon: () => <Img src="/images/pitch.webp" alt="Pitch" />,
		component: Pitch,
		matcher: matcher(Pitch),
	},
	{
		title: 'Prezi',
		keywords: 'presentation',
		// DefaultHidden: true,
		icon: () => <Img src="/images/prezi.webp" alt="Prezi" />,
		component: Prezi,
		matcher: matcher(Prezi),
	},
	{
		title: 'Spotify',
		keywords: 'music',
		// DefaultHidden: true,
		icon: () => <Img src="/images/spotify.webp" alt="Spotify" />,
		component: Spotify,
		matcher: matcher(Spotify),
	},
	{
		title: 'Trello',
		keywords: 'kanban',
		// DefaultHidden: true,
		icon: () => <Img src="/images/trello.webp" alt="Trello" />,
		component: Trello,
		matcher: matcher(Trello),
	},
	{
		title: 'Typeform',
		keywords: 'form survey',
		icon: () => <Img src="/images/typeform.webp" alt="Typeform" />,
		component: Typeform,
		matcher: matcher(Typeform),
	},
	{
		title: 'Vimeo',
		keywords: 'video',
		icon: () => <Img src="/images/vimeo.webp" alt="Vimeo" />,
		component: Vimeo,
		matcher: matcher(Vimeo),
	},
	{
		title: 'Whimsical',
		keywords: 'whiteboard',
		// DefaultHidden: true,
		icon: () => <Img src="/images/whimsical.webp" alt="Whimsical" />,
		component: Whimsical,
		matcher: matcher(Whimsical),
	},
	{
		title: 'YouTube',
		keywords: 'google video',
		// DefaultHidden: true,
		icon: () => <Img src="/images/youtube.webp" alt="YouTube" />,
		component: YouTube,
		matcher: matcher(YouTube),
	},
];

export default embeds;
