import { isNil } from 'lodash-es';
import { Box, Group, Stack, Text, Tooltip } from '@mantine/core';
import { colors } from '@repo/theme/primitives';

// Define the possible format options
type FormatNumberOptions = 'number' | 'percent' | 'currency' | 'decimal';

interface LegendElementProps {
	value: number;
	color: string;
	name: string;
	decimals?: number;
	currency?: string;
	numericFormat?: FormatNumberOptions;
}

interface TruncatedTextProps {
	text: string;
}

function TruncatedText({ text }: TruncatedTextProps) {
	return (
		<Tooltip label={text} openDelay={500} closeDelay={500}>
			<Text truncate>{text}</Text>
		</Tooltip>
	);
}

const formatNumbers = (
	num: number,
	style: FormatNumberOptions,
	decimals: number = 2,
	currency: string = 'USD'
) => {
	let formatterOptions: Intl.NumberFormatOptions = {};

	switch (style) {
		case 'percent':
			formatterOptions = {
				style: 'percent',
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals,
			};
			break;
		case 'currency':
			formatterOptions = {
				style: 'currency',
				currency: currency,
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals,
			};
			break;
		case 'decimal':
			formatterOptions = {
				style: 'decimal',
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals,
			};
			break;
		case 'number':
		default:
			formatterOptions = {
				style: 'decimal',
				minimumSignificantDigits: decimals,
				maximumFractionDigits: decimals,
			};
			break;
	}

	const formatter = new Intl.NumberFormat(undefined, formatterOptions);
	return formatter.format(num);
};

export function LegendElement({
	value,
	color,
	name,
	currency = 'USD',
	decimals = 2,
	numericFormat = 'number',
}: LegendElementProps) {
	const showRound = !isNil(value) && value > 0;
	const showZero = !isNil(value) && value === 0;

	const valueString = (() => {
		if (showZero) {
			return '0';
		}
		if (showRound && !isNil(value)) {
			return formatNumbers(value, numericFormat, decimals, currency);
		}
		return value?.toString() ?? 'N/A';
	})();

	return (
		<Stack spacing={4} w={250}>
			<Group spacing={8} sx={{ fontSize: 11 }} color={colors.gray[7]}>
				<Box
					w={8}
					h={8}
					sx={{
						padding: 0,
						margin: 0,
						borderRadius: 8,
						backgroundColor: color,
					}}
				/>
				<TruncatedText text={name} />
			</Group>
			<Group sx={{ fontSize: 24 }} color={colors.dark[7]} fw={500}>
				<TruncatedText text={valueString} />
			</Group>
		</Stack>
	);
}
