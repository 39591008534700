import { Box, Flex, createStyles, useMantineTheme } from '@mantine/core';
import type React from 'react';
import { Text } from '@repo/foundations';
import { Title } from '@repo/foundations';
import { IconButton } from '@repo/foundations';
import { SecodaFullTextLogo } from '../../SecodaFullTextLogo';

interface AuthLayoutBaseProps {
	title?: string;
	subTitle?: string;
	children: React.ReactNode;
	onClickBackButton?: () => void;
	backButtonTooltipText?: string;
}

const useStyles = createStyles((theme) => ({
	page: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		height: '100vh',
		alignItems: 'center',
	},
	logo: {
		position: 'absolute',
		top: theme.other.space[8],
		left: theme.other.space[8],
		zIndex: 1,
	},
	background: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		zIndex: 0,
		overflow: 'hidden',
	},
	backgroundImage: {
		overflow: 'hidden',
		marginBottom: '-10px',
		marginRight: '-2px',
		width: '100vw',
	},
	contents: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		zIndex: 1,
		minWidth: theme.other.space[80],
		maxWidth: theme.other.space[120],
	},
}));

export function AuthLayoutBase({
	title,
	subTitle,
	children,
	onClickBackButton,
	backButtonTooltipText,
}: AuthLayoutBaseProps) {
	const theme = useMantineTheme();
	const { classes } = useStyles();
	return (
		<Box className={classes.page}>
			<Flex className={classes.logo} align="center" gap="sm">
				{onClickBackButton && (
					<IconButton
						iconName="chevronLeft"
						onClick={onClickBackButton}
						tooltip={backButtonTooltipText}
					/>
				)}
				<SecodaFullTextLogo />
			</Flex>
			<Box className={classes.contents}>
				{title && (
					<Title order={1} size="lg" ta="center">
						{title}
					</Title>
				)}
				{subTitle && (
					<Text size="sm" ta="center" color="text/secondary/default">
						{subTitle}
					</Text>
				)}
				{children}
			</Box>
			<Box className={classes.background}>
				<svg
					viewBox="0 0 2560 1440"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={classes.backgroundImage}
				>
					<g
						clipPath="url(#clip0_2196_36990)"
						filter="url(#filter0_d_2196_36990)"
					>
						<rect
							width="2560"
							height="1440"
							transform="translate(4)"
							fill={theme.other.getColor('surface/secondary/default')}
						/>
						<g filter="url(#filter1_f_2196_36990)">
							<circle cx="1824" cy="1725" r="833" fill="#93DBE8" />
						</g>
						<g filter="url(#filter2_f_2196_36990)">
							<circle cx="2117.24" cy="1835.24" r="871.241" fill="#EB95D5" />
						</g>
						<g filter="url(#filter3_f_2196_36990)">
							<circle cx="2337.72" cy="1673.72" r="611.722" fill="#F99385" />
						</g>
					</g>
					<rect width={2560} height={1440} filter="url(#nnnoise-filter)" />
					<defs>
						<filter
							id="filter0_d_2196_36990"
							x="0"
							y="0"
							width="2560"
							height="1440"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="4" />
							<feGaussianBlur stdDeviation="2" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2196_36990"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2196_36990"
								result="shape"
							/>
						</filter>
						<filter
							id="filter1_f_2196_36990"
							x="663.799"
							y="564.799"
							width="2320.4"
							height="2320.4"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="BackgroundImageFix"
								result="shape"
							/>
							<feGaussianBlur
								stdDeviation="163.601"
								result="effect1_foregroundBlur_2196_36990"
							/>
						</filter>
						<filter
							id="filter2_f_2196_36990"
							x="903.778"
							y="621.778"
							width="2426.93"
							height="2426.93"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="BackgroundImageFix"
								result="shape"
							/>
							<feGaussianBlur
								stdDeviation="171.111"
								result="effect1_foregroundBlur_2196_36990"
							/>
						</filter>
						<filter
							id="filter3_f_2196_36990"
							x="1383.78"
							y="719.778"
							width="1907.89"
							height="1907.89"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="BackgroundImageFix"
								result="shape"
							/>
							<feGaussianBlur
								stdDeviation="171.111"
								result="effect1_foregroundBlur_2196_36990"
							/>
						</filter>
						<clipPath id="clip0_2196_36990">
							<rect
								width="2560"
								height="1440"
								fill="white"
								transform="translate(4)"
							/>
						</clipPath>

						<filter
							id="nnnoise-filter"
							x="-20%"
							y="-20%"
							width="140%"
							height="140%"
							filterUnits="objectBoundingBox"
							primitiveUnits="userSpaceOnUse"
							colorInterpolationFilters="linearRGB"
						>
							<feTurbulence
								type="fractalNoise"
								baseFrequency="0.9"
								numOctaves="8"
								seed="15"
								stitchTiles="stitch"
								x="0%"
								y="0%"
								width="100%"
								height="100%"
								result="turbulence"
							/>
							<feSpecularLighting
								surfaceScale="1"
								specularConstant="0.75"
								specularExponent="20"
								lightingColor="gray"
								x="0%"
								y="0%"
								width="100%"
								height="100%"
								in="turbulence"
								result="specularLighting"
							>
								<feDistantLight azimuth="3" elevation="100" />
							</feSpecularLighting>
						</filter>
					</defs>
				</svg>
			</Box>
		</Box>
	);
}
