import axios from 'axios';
import {
	cloneDeep,
	get as lodashGet,
	isEmpty,
	isNil,
	omitBy,
	set as lodashSet,
} from 'lodash-es';
import { api } from '../../../network';
import { authHeaders } from '../../api/common';
import queryClient from '../../api/queryClient';
import type { APIListResponse, APIListResponseData } from './interface';

const queryKeyMapping: Record<string, string[][]> = {
	Integration: [
		['integration', 'integrations', 'list'],
		['auth', 'teams'],
	],
	Collection: [
		['collection', 'collections', 'list'],
		['auth', 'teams'],
	],
	DictionaryTerm: [
		['dictionary', 'terms', 'list'],
		['auth', 'teams'],
	],
	Metric: [
		['metric', 'metrics', 'list'],
		['auth', 'teams'],
	],
	Question: [
		['question', 'questions', 'list'],
		['auth', 'teams'],
	],
	Document: [
		['document', 'list'],
		['auth', 'teams'],
	],
};

const handleError = (error: unknown) => {
	// eslint-disable-next-line no-console
	console.error(error);
};

export class BaseModel {
	static CLASS_NAME = '';

	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'updated_at' has no initializer and is no... Remove this comment to see the full error message
	updated_at: string;

	// @ts-expect-error TS(2564): Property 'created_at' has no initializer and is no... Remove this comment to see the full error message
	created_at: string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(obj: any) {
		Object.keys(obj).forEach((key) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return ['id', 'updated_at', 'created_at'];
	}

	static get namespace(): string[] {
		return [];
	}

	static get apiRootPath() {
		return `${api()}${this.namespace.join('/')}/`;
	}

	get apiPath() {
		// @ts-expect-error TS(2339): Property 'apiRootPath' does not exist on type 'Fun... Remove this comment to see the full error message
		return `${this.constructor.apiRootPath + this.id}/`;
	}

	// We will move to this from `apiRootPath`.
	static path(namespace: string[]) {
		return `${api()}${namespace.join('/')}/`;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static async list(params: Record<string, any> = {}, page = 1) {
		const queryParams = omitBy({ page: String(page), ...params }, (value) =>
			isNil(value)
		);
		const searchParams = new URLSearchParams(queryParams).toString();

		const url = `${this.apiRootPath}?${searchParams}`;

		const response = await axios.get<APIListResponse>(url, authHeaders());

		const results = response.data.results.map(
			(result) => new this(result as never)
		);

		const returnResult: APIListResponseData = {
			results,
			count: response.data.count,
			totalPages: response.data.total_pages,
			next: null,
			previous: null,
		};

		returnResult.previous = response.data.meta.previous_page;
		returnResult.next = response.data.meta.next_page;

		return returnResult;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static async listAll(params: Record<string, any> = {}, page = 1) {
		const results = [];
		let response = await this.list(params, page);

		results.push(...response.results);

		while (response.next) {
			// eslint-disable-next-line no-await-in-loop
			response = await this.list(params, response.next);
			results.push(...response.results);
		}

		return results;
	}

	static async create(
		params:
			| { definition: string | undefined }
			| { definition?: string | undefined }
			| { id: string | null; parent: string | null }
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			| any
	) {
		const res = await axios.post(this.apiRootPath, params, authHeaders());
		// eslint-disable-next-line no-underscore-dangle
		const _this = new this(res.data as never);

		// Invalidate the query cache for this model.
		// To make old models play nicely with react-query
		const queryKeys =
			queryKeyMapping[this.CLASS_NAME] || queryKeyMapping[this.name];

		if (queryKeys) {
			queryKeys.forEach((queryKey) => {
				queryClient.invalidateQueries({ queryKey });
			});
		}

		return _this;
	}

	async sync() {
		const res = await axios.get(this.apiPath, authHeaders());

		Object.entries(res.data).forEach(([key, value]) => {
			// @ts-expect-error TS(2339): Property 'fields' does not exist on type 'Function... Remove this comment to see the full error message
			if (this.constructor.fields.indexOf(key) !== -1) {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				this[key] = value;
			}
		});

		return this; // Return this, nicer to play with.
	}

	async save(updateFields: string[] = []) {
		const updateData = {};

		const fieldsToUpdate = isEmpty(updateFields)
			? // @ts-expect-error TS(2339): Property 'fields' does not exist on type 'Function... Remove this comment to see the full error message
				this.constructor.fields
			: updateFields;

		// @ts-expect-error TS(7006): Parameter 'field' implicitly has an 'any' type.
		fieldsToUpdate.forEach((field) => {
			lodashSet(updateData, field, lodashGet(this, field, null));
		});

		try {
			const { data } = await axios.patch(
				this.apiPath,
				updateData,
				authHeaders()
			);
			// Assign the changes to the local object
			Object.entries(data).forEach(([field, value]) => {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				this[field] = value;
			});
		} catch (err) {
			handleError(err);
		}

		// Invalidate the query cache for this model.
		// To make old models play nicely with react-query
		const queryKeys =
			// @ts-expect-error TS(2339): Property 'CLASS_NAME' does not exist on type 'Function'.
			queryKeyMapping[this.constructor.CLASS_NAME] ||
			queryKeyMapping[this.constructor.name];

		if (queryKeys) {
			queryKeys.forEach((queryKey) => {
				queryClient.invalidateQueries({ queryKey });
			});
		}

		return this;
	}

	async destroy() {
		try {
			await axios.delete(this.apiPath, {
				data: {},
				...authHeaders(),
			});

			// Invalidate the query cache for this model.
			// To make old models play nicely with react-query
			const queryKeys =
				// @ts-expect-error TS(2339): Property 'CLASS_NAME' does not exist on type 'Function'.
				queryKeyMapping?.[this.constructor.CLASS_NAME] ||
				queryKeyMapping?.[this.constructor.name];

			if (queryKeys) {
				queryKeys.forEach((queryKey) => {
					queryClient.invalidateQueries({ queryKey });
				});
			}
		} catch (err) {
			handleError(err);
		}
	}

	setValue(key: string, value: unknown) {
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Function'.
		this[key] = value;
		return this;
	}
}
