import { Group, createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	breadcrumbWrapper: {
		flexGrow: 1,
		flexShrink: 1,
		overflowX: 'auto',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		paddingRight: theme.spacing.md,
	},
}));

interface NavBarBreadcrumbWrapperProps {
	children: React.ReactNode;
}

export function NavBarBreadcrumbWrapper({
	children,
}: NavBarBreadcrumbWrapperProps) {
	const { classes } = useStyles();

	return <Group className={classes.breadcrumbWrapper}>{children}</Group>;
}
