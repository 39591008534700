import { showNotification } from '@mantine/notifications';
import { IconAlertCircle, IconCheck } from '@tabler/icons-react';
import type { MutationFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { PartialWithoutId } from '../../../lib/typescript';
import { apiClient, getEndpoints } from '../../common';
import type { ISecodaEntity, Namespace } from '../../types';
import { secodaEntitiesQueryKeyFactory } from './constants';

export type BulkUpdateData = Array<{
	id: string;
	data: PartialWithoutId<ISecodaEntity>;
}>;

export type RequestData = {
	data: BulkUpdateData;
	signal?: AbortSignal;
};

export function getDefaultBulkUpdateFn(
	namespace: Namespace
): MutationFunction<string[], RequestData> {
	return async ({ data, signal }) => {
		const baseUrl = getEndpoints(namespace).root();
		const url = `${baseUrl}bulk_update`;
		const { data: responseData } = await apiClient.post<string[]>(url, data, {
			signal,
		});
		return responseData;
	};
}

export type SuccessMessage = {
	title: string;
	message: string;
};

function useBulkUpdateSecodaEntities(
	onSuccessMessage: SuccessMessage = {
		title: 'Resources Updated',
		message: 'The resources have been updated with selected metadata',
	}
) {
	const { namespace } = secodaEntitiesQueryKeyFactory;
	const mutationFn = getDefaultBulkUpdateFn(namespace);

	return useMutation<string[], unknown, RequestData>({
		mutationFn,
		onError: () => {
			showNotification({
				title: 'Error updating resources',
				message: 'If the issues persists, please contact support',
				color: 'red',
				icon: <IconAlertCircle size={16} />,
			});
		},
		onSuccess: () => {
			showNotification({
				title: onSuccessMessage.title,
				message: onSuccessMessage.message,
				color: 'green',
				icon: <IconCheck size={16} />,
			});
		},
	});
}

export default useBulkUpdateSecodaEntities;
