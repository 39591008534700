import { Plugin } from 'prosemirror-state';
import { DecorationSet, Decoration } from 'prosemirror-view';
import { NodeSpec } from 'prosemirror-model';
import { ParseSpec } from 'prosemirror-markdown';
import {
	getCellAttrs,
	getCellAttrsFromMarkdownToken,
	setCellAttrs,
} from '../lib/table';
import {
	getCellsInColumn,
	isRowSelected,
	isTableSelected,
} from '../queries/table';
import { addRowBefore, selectRow, selectTable } from '../commands/table';
import Node from './Node';

export default class TableCell extends Node {
	get name() {
		return 'td';
	}

	get schema(): NodeSpec {
		return {
			content: 'paragraph+',
			tableRole: 'cell',
			isolating: true,
			parseDOM: [{ tag: 'td', getAttrs: getCellAttrs }],
			toDOM(node) {
				return ['td', setCellAttrs(node), 0];
			},
			attrs: {
				colspan: { default: 1 },
				rowspan: { default: 1 },
				colwidth: { default: null },
				alignment: { default: null },
			},
		};
	}

	toMarkdown() {
		// See: renderTable
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'td',
			getAttrs: (tok) => getCellAttrsFromMarkdownToken(tok),
		};
	}

	get plugins() {
		function buildAddRowDecoration(pos: number, index: number) {
			let className = 'table-add-row';
			if (index === 0) {
				className += ' first';
			}

			return Decoration.widget(
				pos + 1,
				() => {
					const plus = document.createElement('a');
					plus.role = 'button';
					plus.className = className;
					plus.dataset.index = index.toString();
					return plus;
				},
				{
					key: `${className}-${index}`,
				}
			);
		}

		return [
			new Plugin({
				props: {
					handleDOMEvents: {
						mousedown: (view, event) => {
							if (!(event.target instanceof HTMLElement)) {
								return false;
							}

							const targetAddRow = event.target.closest('.table-add-row');
							if (targetAddRow) {
								event.preventDefault();
								event.stopImmediatePropagation();
								const index = Number(targetAddRow.getAttribute('data-index'));

								addRowBefore({ index })(view.state, view.dispatch);
								return true;
							}

							const targetGrip = event.target.closest('.grip-table');
							if (targetGrip) {
								event.preventDefault();
								event.stopImmediatePropagation();
								selectTable()(view.state, view.dispatch);
								return true;
							}

							const targetGripRow = event.target.closest('.grip-row');
							if (targetGripRow) {
								event.preventDefault();
								event.stopImmediatePropagation();

								selectRow(
									Number(targetGripRow.getAttribute('data-index')),
									event.metaKey || event.shiftKey
								)(view.state, view.dispatch);
								return true;
							}

							return false;
						},
					},
					decorations: (state) => {
						if (!this.editorState.view?.editable) {
							return;
						}

						const { doc } = state;
						const decorations: Decoration[] = [];
						const rows = getCellsInColumn(0)(state);

						if (rows) {
							rows.forEach((pos: number, index: number) => {
								if (index === 0) {
									let className = 'grip-table';
									const tableSelected = isTableSelected(state);
									if (tableSelected) {
										className += ' selected';
									}

									decorations.push(
										Decoration.widget(
											pos + 1,
											() => {
												const grip = document.createElement('a');
												grip.role = 'button';
												grip.className = className;
												return grip;
											},
											{
												key: className,
											}
										)
									);
								}

								const rowSelected = isRowSelected(index)(state);
								let className = 'grip-row';
								if (rowSelected) {
									className += ' selected';
								}
								if (index === 0) {
									className += ' first';
								}
								if (index === rows.length - 1) {
									className += ' last';
								}

								decorations.push(
									Decoration.widget(
										pos + 1,
										() => {
											const grip = document.createElement('a');
											grip.role = 'button';
											grip.className = className;
											grip.dataset.index = index.toString();
											return grip;
										},
										{
											key: `${className}-${index}`,
										}
									)
								);

								if (index === 0) {
									decorations.push(buildAddRowDecoration(pos, index));
								}

								decorations.push(buildAddRowDecoration(pos, index + 1));
							});
						}

						return DecorationSet.create(doc, decorations);
					},
				},
			}),
		];
	}
}
