import { createStyles, Modal, Text } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { typography } from '@repo/theme/primitives';
import PropagateMetadata from './PropagateMetadata';
import { propagateMetadataModalStore } from './store';

const useStyles = createStyles((theme) => ({
	body: {
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
		paddingTop: theme.spacing.lg,
	},
	close: { transform: 'scale(1.25)' },
	header: {
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
		paddingTop: theme.spacing.xs,
		paddingBottom: theme.spacing.xs,
		borderBottom: `thin solid ${theme.colors.gray[2]}`,
	},
	title: {
		fontSize: theme.fontSizes.md,
		fontWeight: typography.weight.bold,
	},
}));

function PropagateMetadataModal() {
	const { classes } = useStyles();

	return (
		<Modal
			classNames={{
				body: classes.body,
				close: classes.close,
				header: classes.header,
			}}
			size="60%"
			padding={0}
			opened={propagateMetadataModalStore.isOpen}
			onClose={propagateMetadataModalStore.closeModal}
			title={
				<Text size={16} fw={600}>
					Propagate
				</Text>
			}
			withinPortal
		>
			{propagateMetadataModalStore.entityId && (
				<PropagateMetadata
					entityId={propagateMetadataModalStore.entityId}
					isModal
				/>
			)}
		</Modal>
	);
}

export default observer(PropagateMetadataModal);
