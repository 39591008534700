import type { UseQueryResult } from '@tanstack/react-query';
import { createMockableHook } from '../../../utils/createMockableHook';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IUseQueryHookArgs, IUser } from '../../types';
import { filterFromListCacheByIds } from '../../utils';
import { useThisUser } from './useThisUser';

export const USERS_NAMESPACE = ['user'];

export const usersQueryKeyFactory = createQueryKeys(USERS_NAMESPACE);

const { prefetchUser, prefetchUserList } = prefetchFunctionsFactory(
	'user',
	usersQueryKeyFactory
);

export const filterUsersByIds = (ids: string[]) =>
	filterFromListCacheByIds<IUser>(usersQueryKeyFactory, ids);

const {
	useUser: useUserInternal,
	useUserInfiniteList,
	useUserList,
	useCreateUser,
	useDeleteUser,
	useUpdateUser,
	fetchUserList,
	updateUser,
} = baseQueryHooksFactory<IUser, 'user'>('user', usersQueryKeyFactory);

export const [useUser, MockUseUser] = createMockableHook<
	IUseQueryHookArgs<IUser, IUser>,
	UseQueryResult<IUser>
>(useUserInternal);

export {
	updateUser,
	fetchUserList,
	prefetchUser,
	prefetchUserList,
	useCreateUser,
	useDeleteUser,
	useThisUser,
	useUpdateUser,
	useUserInfiniteList,
	useUserList,
};
