import { Box } from '@mantine/core';
import { IconHierarchy } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { type ListQueryParams, useCollectionList } from '../../../../api';
import { useCurrentTeamId } from '../../../../utils/hook/useCurrentTeamId';
import SingleSelector from '../../../SingleSelector/SingleSelector';

interface CollectionSelectorProps {
	onChange?: (value: string) => void;
	initialValue?: string | null;
}

export function ParentCollectionSelector({
	onChange,
	initialValue,
}: CollectionSelectorProps) {
	const teamId = useCurrentTeamId();
	const [selected, setSelected] = React.useState<string>(initialValue ?? '');
	const [searchTerm, setSearchTerm] = useState('');

	const filters = useMemo(() => {
		const currentFilters: ListQueryParams['filters'] = {};

		if (teamId) {
			currentFilters.teams = teamId;
		}

		if (searchTerm) {
			currentFilters.title__icontains = searchTerm;
		} else {
			delete currentFilters.title__icontains;
		}

		return currentFilters;
	}, [searchTerm, teamId]);

	const { data } = useCollectionList({
		filters,
		options: {
			select: ({ results }) => results,
		},
	});

	const collections =
		data?.map((collection) => ({
			label: collection.title,
			value: collection.id,
			icon: collection.icon,
		})) ?? [];

	const handleChange = (value: string) => {
		setSelected(value);
		onChange?.(value);
	};

	return (
		<Box maw={256}>
			<SingleSelector
				placeholder="Parent"
				placeholderIcon={IconHierarchy}
				variant={selected ? 'default' : 'tertiary'}
				iconType="emoji"
				isViewerUser={false}
				options={collections}
				onChange={handleChange}
				initialSelected={selected}
				property="parent"
				searchable
				onSearchTermChange={setSearchTerm}
			/>
		</Box>
	);
}
