import { Skeleton } from '@mantine/core';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';

import { ParseSpec } from 'prosemirror-markdown';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { ChartBlockContainer } from '../components/MetricContainer/ChartBlockContainer';
import chartBlocksRule from '../rules/chartblocks';
import type { ComponentProps } from '../lib/ComponentView';
import ReactNode from './ReactNode';

export default class ChartBlock extends ReactNode {
	get name() {
		return 'chart_block';
	}

	get markdownToken() {
		return 'chart_block';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '',
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			draggable: false,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.chartblock',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.getAttribute('id'),
								},
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'chartblock',
					id: node.attrs.id,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id } = node.attrs;
		if (id) {
			state.ensureNewLine();
			state.write(`:;:${id}:;:\n\n`);
			state.ensureNewLine();
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'chart_block',
			getAttrs: (tok) => ({
				id: tok.attrs?.[0]?.[1].split(':;:')[1],
			}),
		};
	}

	get rulePlugins() {
		return [chartBlocksRule];
	}

	commands() {
		return () => () => true;
	}

	component = ({ node }: ComponentProps) => {
		if (!node) {
			return <Skeleton height={100} />;
		}

		return (
			<ChartBlockContainer
				id={node.attrs.id}
				readOnly={this.editorState.readOnly}
			/>
		);
	};
}
