import { useIntegrationList } from '../../../api';
import { integrationList } from '../../../data/integrations';
import { EntityType } from '../../../lib/types';
import IntegrationLogo from '../../IntegrationLogo';

export const useQueryableIntegrationOptions = () => {
	const integrations = useIntegrationList({});

	return (
		integrations?.data?.results
			.filter((integration) =>
				integrationList.find(
					(localIntegration) =>
						localIntegration.type === integration.type &&
						localIntegration.queryBlockSupport
				)
			)
			.map((integration) => ({
				icon: (
					<IntegrationLogo
						entityType={EntityType.integration}
						size={16}
						integrationType={integration.type}
					/>
				),
				value: integration.id,
				label: integration.name,
			})) || []
	);
};
