import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IUserGroup } from '../../types';
import { filterFromListCacheByIds } from '../../utils';

export const USER_GROUPS_NAMESPACE = ['auth', 'group'];

export const userGroupsQueryKeyFactory = createQueryKeys(USER_GROUPS_NAMESPACE);

const { prefetchUserGroup, prefetchUserGroupList } = prefetchFunctionsFactory(
	'userGroup',
	userGroupsQueryKeyFactory
);

export const filterUserGroupsByIds = (ids: string[]) =>
	filterFromListCacheByIds<IUserGroup>(userGroupsQueryKeyFactory, ids);

const {
	useUserGroup,
	useUserGroupInfiniteList,
	useUserGroupList,
	useCreateUserGroup,
	useDeleteUserGroup,
	useUpdateUserGroup,
	fetchUserGroupList,
} = baseQueryHooksFactory<IUserGroup, 'userGroup'>(
	'userGroup',
	userGroupsQueryKeyFactory
);

export {
	fetchUserGroupList,
	prefetchUserGroup,
	prefetchUserGroupList,
	useUserGroup,
	useUserGroupInfiniteList,
	useUserGroupList,
	useCreateUserGroup,
	useDeleteUserGroup,
	useUpdateUserGroup,
};
