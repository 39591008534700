import {
	ActionIcon,
	Box,
	Collapse,
	CopyButton,
	Text,
	Tooltip,
	createStyles,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Icon } from '@repo/foundations';

const useStyles = createStyles((theme) => ({
	wrapper: {
		width: '100%',
	},
	collapseRow: {
		display: 'flex',
		alignItems: 'center',
		color: theme.colors.red[6],
		cursor: 'pointer',
	},
	logBox: {
		position: 'relative',
		fontFamily: 'monospace',
		fontSize: '12px',
		backgroundColor: theme.colors.red[0],
		padding: '10px',
		marginTop: '15px',
		marginBottom: '15px',
		whiteSpace: 'pre-wrap',
		wordBreak: 'break-all',
		borderRadius: '10px',
		boxShadow: `${theme.colors.gray[0]} 0px 0px 0px 1px inset`,
	},
	copyButton: {
		position: 'absolute',
		top: '5px',
		right: '5px',
	},
}));

interface IErrorLogsProps {
	title: string;
	logs: string[];
}

export function ErrorLogs({ title, logs }: IErrorLogsProps) {
	const { classes } = useStyles();
	const [opened, { toggle }] = useDisclosure(false);

	let icon = <Icon name="chevronRight" />;

	if (opened) {
		icon = <Icon name="chevronDown" />;
	}

	const message = logs.join('\n');

	const copyValue = JSON.stringify({
		title,
		message,
	});

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.logBox}>
				<Box className={classes.collapseRow} onClick={toggle}>
					{icon}
					<Text fw={600}>{title ?? 'Error'}</Text>
				</Box>
				<Collapse in={opened}>{message}</Collapse>
				<CopyButton value={copyValue} timeout={2000}>
					{({ copied, copy }) => (
						<Box className={classes.copyButton}>
							<Tooltip
								label={copied ? 'Copied' : 'Copy full error'}
								withArrow
								position="right"
							>
								<ActionIcon
									bg="transparent"
									color={copied ? 'red' : 'gray'}
									onClick={copy}
								>
									{copied ? <Icon name="check" /> : <Icon name="copy" />}
								</ActionIcon>
							</Tooltip>
						</Box>
					)}
				</CopyButton>
			</Box>
		</Box>
	);
}
