import { Box, createStyles } from '@mantine/core';
import { ForwardedRef, forwardRef, HTMLProps, PropsWithChildren } from 'react';
import { MenuItem } from '@repo/secoda-editor';
import { KeyboardShortcut } from '../../../../../KeyboardShortcut/KeyboardShortcut';

export const useStyles = createStyles((theme) => ({
	item: {
		...theme.fn.fontStyles(),
		WebkitTapHighlightColor: 'transparent',
		fontSize: theme.fontSizes.sm,
		border: 0,
		backgroundColor: 'transparent',
		outline: 0,
		textAlign: 'left',
		textDecoration: 'none',
		boxSizing: 'border-box',
		cursor: 'pointer',
		borderRadius: theme.radius.sm,
		color: theme.other.getColor('text/primary/default'),
		display: 'flex',
		alignItems: 'center',
		margin: `0 ${theme.spacing['2xs']}`,
		width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
		padding: theme.spacing['2xs'],
		gap: theme.spacing.xs,

		'&:disabled': {
			color: theme.other.getColor('text/primary/disabled'),
			pointerEvents: 'none',
			userSelect: 'none',
		},

		'&[data-hovered], &:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},

	itemLabel: {
		flex: 1,
	},

	itemIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

interface CommandMenuItemProps
	extends Omit<HTMLProps<HTMLButtonElement>, 'ref' | 'onClick'> {
	icon?: MenuItem['icon'];
	ref?: React.Ref<HTMLButtonElement>;
	shortcutKeys?: string[];
}

export const CommandMenuItem = forwardRef(
	(
		{
			icon,
			shortcutKeys,
			children,
			...props
		}: PropsWithChildren<CommandMenuItemProps>,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { classes } = useStyles();

		const Icon = icon;

		return (
			<Box
				{...props}
				ref={ref}
				component="button"
				type="button"
				role="option"
				className={classes.item}
			>
				{Icon && (
					<div className={classes.itemIcon}>
						<Icon />
					</div>
				)}
				{children && <div className={classes.itemLabel}>{children}</div>}
				{shortcutKeys && <KeyboardShortcut keys={shortcutKeys} />}
			</Box>
		);
	}
);
CommandMenuItem.displayName = 'CommandMenuItem';
