import type { HoverCardProps } from '@mantine/core';
import { Avatar, Group, HoverCard, Stack, createStyles } from '@mantine/core';
import { lowerCase } from 'lodash-es';
import { Text } from '@repo/foundations';
import { type useResourceLink } from '../Link/useResourceLink';
import type { ISecodaEntity, IUser } from '../../api';
import { UserAvatar } from '../UserAvatar';
import { pluralize } from '../../utils/stringUtils';
import { getMarkdownAsPlainText } from '../../utils/getMarkdownAsPlainText';
import type { AvatarUser } from '../UserAvatar/common';
import { SecodaEntityIcon } from '../SecodaEntity';
import type { ISecodaEntityIcon } from '../SecodaEntity/SecodaEntityIcon/SecodaEntityIcon';
import { getSummaryAndBreadCrumbs } from '../../utils/breadcrumb';

interface UseStylesProps {
	hasDetails: boolean;
}

const useStyles = createStyles((theme, { hasDetails }: UseStylesProps) => ({
	dropdown: {
		maxWidth: '268px',
		borderRadius: theme.radius.md,
		padding: 0,
		boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.12)',
	},
	title: {
		cursor: 'pointer',
		padding: hasDetails
			? `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm}`
			: theme.spacing.sm,
	},
	body: {
		borderTop: `1px solid ${theme.other.getColor('border/primary/default')}`,
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.sm}`,
	},
	description: {
		fontSize: theme.fontSizes.sm,
		display: '-webkit-box',
		lineClamp: 2,
		WebkitLineClamp: 2,
		boxOrient: 'vertical',
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
	},
}));

export type EntityPreview = ISecodaEntityIcon &
	Pick<
		ISecodaEntity,
		'search_metadata' | 'display_metadata' | 'description' | 'definition'
	>;
export type MonitorPreview = Omit<EntityPreview, 'entity_type'> & {
	entity_type: 'monitor';
	targetEntity: ISecodaEntity;
};
export type UserPreview = AvatarUser;

interface ResourcePreviewProps
	extends Omit<ReturnType<typeof useResourceLink>, 'data'>,
		HoverCardProps {
	initiallyOpen?: boolean;
	data: EntityPreview | UserPreview | MonitorPreview;
}

export const isUserPreview = (
	data: EntityPreview | UserPreview | MonitorPreview
): data is UserPreview => 'display_name' in data;

export function ResourcePreview({
	data,
	hasError,
	isLoading,
	resourceType,
	children,
	initiallyOpen,
	...props
}: ResourcePreviewProps) {
	const showError = hasError || (!isLoading && !data);

	const title = isUserPreview(data) ? data.display_name : data.title;
	const description = isUserPreview(data)
		? null
		: data.description ?? data?.definition;
	const entityType = isUserPreview(data)
		? 'user'
		: (data as ISecodaEntity).native_type ||
			(data as ISecodaEntity).entity_type;
	const breadcrumbs = getSummaryAndBreadCrumbs(
		entityType,
		isUserPreview(data) ? {} : data.search_metadata
	);

	const owners = !isUserPreview(data)
		? data.display_metadata?.owners ?? []
		: [];
	const hasOwners = owners.length > 0;

	const { classes } = useStyles({ hasDetails: !!description || hasOwners });

	const cleanedDescription = getMarkdownAsPlainText(description ?? '', 2);

	return (
		<HoverCard openDelay={500} initiallyOpened={initiallyOpen} {...props}>
			<HoverCard.Target>{children}</HoverCard.Target>
			<HoverCard.Dropdown className={classes.dropdown}>
				{isLoading && <div className={classes.title}>Loading...</div>}
				{showError && (
					<div className={classes.title}>Could not load preview</div>
				)}
				{!isLoading && !showError && (
					<Stack spacing={0}>
						<Group
							spacing="xs"
							align="flex-start"
							className={classes.title}
							noWrap
						>
							{isUserPreview(data) ? (
								<UserAvatar user={data} size="xxs" />
							) : (
								<SecodaEntityIcon
									inverseIconColor
									entity={
										data.entity_type === 'monitor' ? data.targetEntity : data
									}
									inline
									size={20}
								/>
							)}
							<Stack spacing={0}>
								<Text size="sm" fw="bold" color="text/primary/default">
									{title}
								</Text>
								<Text size="xs" color="text/secondary/default">
									{breadcrumbs}
								</Text>
							</Stack>
						</Group>
						{(description || hasOwners) && (
							<Stack className={classes.body}>
								{description && (
									<div className={classes.description}>
										{cleanedDescription}
									</div>
								)}
								{hasOwners && (
									<Group spacing="xs">
										<Avatar.Group>
											{owners.map((owner) => (
												<UserAvatar
													key={owner.id}
													user={owner as IUser}
													size="sm"
												/>
											))}
										</Avatar.Group>
										<Text size="xs" color="text/secondary/default">
											{owners.length === 1
												? `Owned by ${owners[0].display_name}`
												: pluralize(lowerCase('owner'), owners.length, true)}
										</Text>
									</Group>
								)}
							</Stack>
						)}
					</Stack>
				)}
			</HoverCard.Dropdown>
		</HoverCard>
	);
}
