import { toggleMark } from 'prosemirror-commands';
import type {
	Mark as ProsemirrorMark,
	MarkSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import moveLeft from '../commands/moveLeft';
import moveRight from '../commands/moveRight';
import markInputRule from '../lib/markInputRule';
import Mark, { MarkOptions } from './Mark';

function backticksFor(node: ProsemirrorNode, side: -1 | 1) {
	const ticks = /`+/g;
	let match: RegExpMatchArray | null;
	let len = 0;

	if (node.isText) {
		while ((match = ticks.exec(node.text || ''))) {
			len = Math.max(len, match[0].length);
		}
	}

	let result = len > 0 && side > 0 ? ' `' : '`';
	for (let i = 0; i < len; i += 1) {
		result += '`';
	}
	if (len > 0 && side < 0) {
		result += ' ';
	}
	return result;
}

export default class Code extends Mark {
	get name() {
		return 'code_inline';
	}

	get schema(): MarkSpec {
		return {
			excludes: this.options.excludes ? this.options.excludes : '_',
			parseDOM: [{ tag: 'code.inline', preserveWhitespace: true }],
			toDOM: () => ['code', { class: 'inline', spellCheck: 'false' }],
		};
	}

	inputRules({ type }: MarkOptions) {
		return [markInputRule(/(?:^|[^`])(`([^`]+)`)$/, type)];
	}

	keys({ type }: MarkOptions) {
		return {
			'Mod-e': toggleMark(type),
			ArrowLeft: moveLeft(),
			ArrowRight: moveRight(),
		};
	}

	toMarkdown() {
		return {
			open(
				_state: MarkdownSerializerState,
				_mark: ProsemirrorMark,
				parent: ProsemirrorNode,
				index: number
			) {
				return backticksFor(parent.child(index), -1);
			},
			close(
				_state: MarkdownSerializerState,
				_mark: ProsemirrorMark,
				parent: ProsemirrorNode,
				index: number
			) {
				return backticksFor(parent.child(index - 1), 1);
			},
			escape: false,
		};
	}

	parseMarkdown() {
		return { mark: 'code_inline' };
	}
}
