import { Group, Stack, createStyles } from '@mantine/core';
import type { PropsWithChildren, ReactNode } from 'react';
import { Title } from '@repo/foundations';

const useStyles = createStyles((theme) => ({
	avatarWrapper: {
		width: theme.other.space[10],
	},
	contentWrapper: {
		marginLeft: theme.other.space[10],
	},
}));

interface MessageBodyProps {
	avatar: ReactNode;
	author: ReactNode;
}

export function MessageBody({
	avatar,
	author,
	children,
}: PropsWithChildren<MessageBodyProps>) {
	const { classes } = useStyles();

	return (
		<Stack spacing={0}>
			<Group spacing={0}>
				<div className={classes.avatarWrapper}>{avatar}</div>
				<Title size="md" weight="bold" color="text/primary/default">
					{author}
				</Title>
			</Group>
			<div className={classes.contentWrapper}>{children}</div>
		</Stack>
	);
}
