import { Box, createStyles } from '@mantine/core';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';

export interface ITableV2HeaderResizeHandleProps {
	minWidth: number;
	maxWidth: number;
	onResize: (updatedWidth: number) => void;
	headerRef: HTMLDivElement | null;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'absolute',
		cursor: 'col-resize',
		top: `-${theme.other.space[6]}px`,
		height: `${theme.other.space[8]}px`,
		bottom: 0,
		right: 0,
		width: `${theme.other.space[2]}px`,
		zIndex: 1,
		'::after': {
			content: '""',
			position: 'absolute',
			top: theme.other.space[0.25],
			left: theme.other.space[0.5],
			bottom: theme.other.space[0.25],
			background: 'inherit',
			borderLeft: `${theme.other.space[0.5]}px dotted ${theme.other.getColor('border/primary/hover')}`,
			opacity: 0,
		},
		'&:hover::after': {
			borderLeftColor: theme.other.getColor('border/primary/hover'),
		},
		'th:hover &::after': {
			opacity: 1,
			borderLeftColor: theme.other.getColor('border/primary/hover'),
		},
	},
}));

const useTempCursor = (enabled: boolean) => {
	useEffect(() => {
		if (enabled) {
			const cursorStyle = document.createElement('style');
			cursorStyle.innerHTML = '*{cursor: col-resize!important;}';
			cursorStyle.id = 'cursor-style';
			document.head.appendChild(cursorStyle);
		}

		return () => {
			window?.document?.getElementById('cursor-style')?.remove();
		};
	}, [enabled]);
};

function TableV2HeaderResizeHandle({
	minWidth,
	maxWidth,
	headerRef,
	onResize,
}: ITableV2HeaderResizeHandleProps) {
	const { classes } = useStyles();

	const [clickXPosition, setClickXPosition] = useState(Infinity);
	useTempCursor(clickXPosition !== Infinity);

	const handleMouseUp = useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			if (!headerRef) {
				return;
			}

			let width = headerRef.clientWidth;

			if (headerRef.clientWidth) {
				width += e.clientX - clickXPosition;
			}

			if (width < 0 || width < minWidth || width > maxWidth) {
				return;
			}

			onResize(width);

			document.body.style.cursor = 'auto';
			document.removeEventListener('mouseup', handleMouseUp);
			setClickXPosition(Infinity);
		},
		[clickXPosition, maxWidth, minWidth, onResize, headerRef]
	);

	const handleMouseDown = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		setClickXPosition(e.clientX);
		document.body.style.cursor = 'col-resize';
	}, []);

	useEffect(() => {
		if (clickXPosition !== Infinity) {
			document.addEventListener('mouseup', handleMouseUp);
		}

		return () => {
			document.removeEventListener('mouseup', handleMouseUp);
		};
	}, [clickXPosition, handleMouseUp]);

	return <Box className={classes.wrapper} onMouseDown={handleMouseDown} />;
}

export default TableV2HeaderResizeHandle;
