import type {
	Attrs,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import { EditorState, TextSelection } from 'prosemirror-state';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { ensureEntityRelation } from '../lib/ensureEntityRelation';
import { Dispatch } from '../types';
import Node, { NodeOptions } from './Node';

export default class Mention extends Node {
	get name() {
		return 'mention';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				'data-name': {
					default: undefined,
				},
				href: {
					default: '',
				},
			},
			inline: true,
			content: 'text*',
			marks: '',
			group: 'inline',
			selectable: false,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'a[href].mention',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									href: dom.getAttribute('href'),
									'data-name': dom.getAttribute('data-name'),
								},
				},
			],
			toDOM: (node) => [
				'a',
				{
					...node.attrs,
					class: `mention ${node.attrs['data-name']}`,
					'data-name': node.attrs['data-name'],
					rel: 'noopener noreferrer nofollow',
				},
				node.attrs['data-name'],
			],
		};
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Attrs) => (state: EditorState, dispatch: Dispatch) => {
			const { selection } = state;
			const position =
				selection instanceof TextSelection
					? selection.$cursor?.pos
					: selection.$to.pos;

			if (position === undefined) {
				return false;
			}

			const node = type.create(attrs);
			const transaction = state.tr.insert(position, node);
			dispatch(transaction);

			ensureEntityRelation(!this.editorState.disableResourceLinking, attrs);

			return true;
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const name = node.attrs['data-name'];
		const { href } = node.attrs;
		if (name) {
			state.write(`[${name}](${href})`);
		}
	}
}
