import queryClient from '../../queryClient';
import type {
	IApiListResponse,
	ISecodaEntity,
	IUseListQueryHookArgs,
} from '../../types';
import {
	secodaEntitiesQueryKeyFactory,
	useSecodaEntityList,
} from '../secodaEntity';

export interface IUseResourceRelationListArgs<T>
	extends IUseListQueryHookArgs<ISecodaEntity, T> {
	entityId: string;
}

// We query from SecodaEntityViewset to re-use the permission and serialization logic.
export function useResourceRelationList<T = IApiListResponse<ISecodaEntity>>({
	entityId,
	options,
}: IUseResourceRelationListArgs<T>) {
	return useSecodaEntityList<ISecodaEntity, T>({
		filters: {
			related_entity_id: entityId,
		},
		options,
	});
}

export const invalidateResourceRelationList = (entityId: string): void => {
	queryClient.invalidateQueries(
		secodaEntitiesQueryKeyFactory.list(1, { related_entity_id: entityId })
	);
};
