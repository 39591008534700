import type { ColumnDefinition } from '../../types';
import { TableColumns } from './table';

export const MetricColumns: ColumnDefinition = [
	'title',
	'description',
	'collections',
	'owners',
	'published',
	'updated_at',
	'tags',
	'pii',
].reduce(
	(columnDefs, columnName) => ({
		...columnDefs,
		[columnName]: {
			...TableColumns[columnName],
			checkboxSelection: columnName === 'title',
		},
	}),
	{
		last_status: {
			field: 'last_status',

			sortable: false,
			flex: 1,
		},
		last_run: {
			field: 'last_run',

			sortable: false,
		},
	}
);
