import { Box } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import React from 'react';
import { useAuthUser } from '../../../../api';
import {
	useExtendedUserList,
	useGuestUserList,
} from '../../../../api/hooks/user/useExtendedUserList';
import type { User } from '../../../../lib/models';
import SingleSelector from '../../../SingleSelector/SingleSelector';

interface AssigneeSelectorProps {
	onChange?: (value: string) => void;
	initialValue?: string;
}

export function AssigneeSelector({
	onChange,
	initialValue,
}: AssigneeSelectorProps) {
	const [selected, setSelected] = React.useState<string>(initialValue ?? '');

	const { isGuestUser } = useAuthUser();
	const { activeUsers } = useExtendedUserList({
		suspense: false,
	});

	const { usersVisibleToGuests } = useGuestUserList({ suspense: false });

	const visibleUsers = (isGuestUser
		? usersVisibleToGuests
		: activeUsers) as unknown as User[];

	const assignee =
		visibleUsers?.map((owner) => ({
			label: owner.display_name,
			value: owner.id,
			icon: owner.profile_picture,
		})) ?? [];

	const handleChange = (value: string) => {
		setSelected(value);
		onChange?.(value);
	};

	return (
		<Box maw={256}>
			<SingleSelector
				placeholder="Assignee"
				placeholderIcon={IconUser}
				variant={selected ? 'default' : 'tertiary'}
				iconType="avatar"
				isViewerUser={false}
				options={assignee}
				onChange={handleChange}
				initialSelected={selected}
				property="owner"
				searchable
			/>
		</Box>
	);
}
