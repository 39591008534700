// We ideally don't want to use `@mantine/core/Button` in favor of `@repo/foundations/Button`.
// eslint-disable-next-line no-restricted-imports
import { Breadcrumbs, Button, createStyles, Menu } from '@mantine/core';
import { map } from 'lodash-es';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { ISecodaEntity } from '../../../api';
import { useBreadcrumbs } from '../../../hooks/useBreadcrumbs';
import type { SecodaEntity } from '../../../lib/models';
import { buildResourceUrl } from '../../../utils/navigationUtils';

interface EntityDrawerBreadcrumbsProps {
	entity: SecodaEntity | ISecodaEntity;
	collapsed?: boolean;
	onClick?: VoidFunction;
	includeEntity?: boolean;
}

const useStyles = createStyles((theme) => ({
	breadcrumbs: {
		marginLeft: `calc(${theme.spacing.xs} * -1)`,
	},
	breadcrumb: {
		color: theme.other.getColor('text/primary/default'),
	},
	breadcrumbSeparator: {
		marginLeft: 0,
		marginRight: 0,
	},
}));

function EntityDrawerBreadcrumbs({
	includeEntity = false,
	entity,
	onClick,
}: EntityDrawerBreadcrumbsProps) {
	const { classes } = useStyles();
	let breadcrumbLinks = useBreadcrumbs(entity);

	if (includeEntity) {
		breadcrumbLinks = [
			...breadcrumbLinks,
			{
				title: entity.title,
				href: buildResourceUrl(entity),
			},
		];
	}

	const breadcrumbs = useMemo(() => {
		const showAll = breadcrumbLinks.length <= 2;

		if (showAll) {
			return map(breadcrumbLinks, (item, i) => (
				<Button
					key={`${item.title}#${i}`}
					component={Link}
					to={item.href}
					variant="subtle"
				>
					{item.title}
				</Button>
			));
		}

		const firstBreadcrumb = breadcrumbLinks[0];
		const lastBreadcrumb = breadcrumbLinks[breadcrumbLinks.length - 1];

		return [
			<Button
				key={`${firstBreadcrumb.title}#first`}
				component={Link}
				to={firstBreadcrumb.href}
				variant="subtle"
				onClick={onClick}
			>
				{firstBreadcrumb.title}
			</Button>,
			<Menu key="breadcrumb-menu" position="top-start">
				<Menu.Target>
					<Button variant="subtle">...</Button>
				</Menu.Target>
				<Menu.Dropdown data-testid="entity-page-layout-breadcrumbs-collapsed">
					{map(breadcrumbLinks.slice(1, -1), (item) => (
						<Link
							to={`${item.href}`}
							onClick={onClick}
							key={item.href + item.title}
						>
							<Menu.Item>{item.title}</Menu.Item>
						</Link>
					))}
				</Menu.Dropdown>
			</Menu>,
			<Button
				key={`${firstBreadcrumb.title}#last`}
				component={Link}
				to={lastBreadcrumb.href}
				variant="subtle"
				onClick={onClick}
			>
				{lastBreadcrumb.title}
			</Button>,
		];
	}, [breadcrumbLinks, onClick]);

	return (
		<Breadcrumbs
			data-testid="entity-page-layout-breadcrumbs"
			classNames={{
				root: classes.breadcrumbs,
				breadcrumb: classes.breadcrumb,
				separator: classes.breadcrumbSeparator,
			}}
		>
			{breadcrumbs}
		</Breadcrumbs>
	);
}

export default EntityDrawerBreadcrumbs;
