import { Avatar, Badge, createStyles, Text } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import type { UserGroup } from '../../../../lib/models';
import { primaryTextColor, primaryTextSize, primaryTextWeight } from '../theme';

interface IStyleParams {
	inline?: boolean;
}

interface IUserGroupChipProps extends IStyleParams {
	userGroup: UserGroup;
}

const useStyles = createStyles((theme, { inline }: IStyleParams) => ({
	badge: {
		backgroundColor: theme.colors.gray[0],
		padding: inline ? 0 : '0 12px',
	},
	label: {
		fontSize: primaryTextSize,
		color: primaryTextColor,
		fontWeight: primaryTextWeight,
		textTransform: 'none',
	},
	leftSection: {
		backgroundColor: theme.colors.gray[1],
	},
}));

function UserGroupChip({ userGroup, inline = false }: IUserGroupChipProps) {
	const { classes } = useStyles({ inline });

	const avatar =
		userGroup.id === 'empty' ? null : (
			<Avatar size="xs" bg={userGroup?.icon ? 'transparent' : ''}>
				{userGroup.icon ? (
					<Text size={12}>{userGroup.icon}</Text>
				) : (
					<IconUsers size={12} />
				)}
			</Avatar>
		);

	return (
		<Badge
			classNames={{
				root: classes.badge,
				inner: classes.label,
				leftSection: classes.leftSection,
			}}
			leftSection={avatar}
		>
			{userGroup.name}
		</Badge>
	);
}

export default UserGroupChip;
