import { Group, Stack, Text } from '@mantine/core';
import { isEmpty, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { ISecodaEntity, useSecodaEntityInfiniteList } from '../../../api';
import { FetchModelInfiniteListHook } from '../../../api/factories/types';
import { useColumns } from '../../../pages/TeamCatalogPage/TeamCatalogPage.hooks';
import { TableV2 } from '../../TableV2';
import { FilterOptionSelector } from '../FilterOptionSelector';
import { FILTER_OPTIONS } from '../constants';

interface IEntitiesListProps {
	selectedEntities: object[];
	onSelectionChanged: (selectedItems: object[]) => void;
	defaultRequiredSearchParams: Record<string, string>;
	onFilterChange: (value: (typeof FILTER_OPTIONS)[number] | null) => void;
}

function EntitiesList({
	defaultRequiredSearchParams,
	onSelectionChanged,
	selectedEntities,
	onFilterChange,
}: IEntitiesListProps) {
	const hasSelectedEntities = !isEmpty(selectedEntities);

	const selectedEntitiesCount = size(selectedEntities);

	const columns = useColumns();

	return (
		<Stack mt={8} spacing={8}>
			<Group position="apart" noWrap>
				<Text size={14} fw={600} lineClamp={1}>
					Select similar resources to propagate to
				</Text>
				{hasSelectedEntities && (
					<Text size="sm" color="gray.6" lineClamp={1}>
						{selectedEntitiesCount} selected
					</Text>
				)}
			</Group>
			<FilterOptionSelector
				defaultValue={'exactName'}
				onChange={onFilterChange}
			/>
			<TableV2<ISecodaEntity>
				onSelectedRecordsStateChange={(state) => {
					onSelectionChanged(state.selectedRecords);
				}}
				pluralTypeString="resources"
				withCsvExport={false}
				withDialog={false}
				withInteractiveHeader={false}
				withCheckbox
				withFilters={false}
				withSearch={false}
				defaultSort={null}
				height="calc(55vh)"
				columns={columns.filter((column) =>
					[
						'title',
						'type',
						'integration',
						'parent',
						'description',
						'verified',
						'pii',
						'tags',
						'collections',
						'owners',
					].includes(column.accessor)
				)}
				defaultRequiredSearchParams={defaultRequiredSearchParams}
				withInfiniteScroll
				usePaginationList={
					useSecodaEntityInfiniteList as FetchModelInfiniteListHook<ISecodaEntity>
				}
			/>
		</Stack>
	);
}

export default observer(EntitiesList);
