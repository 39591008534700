import { Skeleton } from '@mantine/core';
import { Suspense } from 'react';

import { MetricContainer } from './MetricContainer';

interface QueryBlockContainerProps {
	id: string;
	readOnly: boolean;
}

export function QueryBlockContainer({
	id,
	readOnly,
}: QueryBlockContainerProps) {
	return (
		<Suspense fallback={<Skeleton height={300} />}>
			<MetricContainer
				withQuery
				withChart={false}
				id={id}
				readOnly={readOnly}
			/>
		</Suspense>
	);
}
