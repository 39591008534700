/* eslint-disable class-methods-use-this */
import { Skeleton } from '@mantine/core';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { ParseSpec } from 'prosemirror-markdown';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { v4 } from '../../../../../utils/uuid/v4';

import { QueryBlockContainer } from '../components/MetricContainer/QueryBlockContainer';
import queryBlocksRule from '../rules/queryblocks';
import { ComponentProps, Dispatch } from '../types';
import ReactNode from './ReactNode';
import { NodeOptions } from './Node';

export default class QueryBlock extends ReactNode {
	get name() {
		return 'query_block';
	}

	get markdownToken() {
		return 'query_block';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '', // This value is shared across all new nodes, so the `uuid()` call cannot be here.
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			draggable: false,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.queryblock',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.getAttribute('id'),
								},
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'queryblock',
					id: node.attrs.id,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id } = node.attrs;
		if (id) {
			state.ensureNewLine();
			state.write(`;;;${id};;;\n\n`);
			state.ensureNewLine();
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'query_block',
			getAttrs: (tok) => ({ id: tok.attrs?.[0][1].split(';;;')[1] }),
		};
	}

	get rulePlugins() {
		return [queryBlocksRule];
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Record<string, unknown>) =>
			(state: EditorState, dispatch: Dispatch) => {
				let id = v4();
				if (attrs && attrs.id && attrs.id !== '') {
					id = attrs.id;
				}

				dispatch(
					state.tr.insert(
						state.selection.from,
						type.create({ ...(attrs ?? {}), id })
					)
				);

				return true;
			};
	}

	component = ({ node }: ComponentProps) => {
		if (!node) {
			return <Skeleton height={100} />;
		}

		return (
			<QueryBlockContainer
				id={node.attrs.id}
				readOnly={this.editorState.readOnly}
			/>
		);
	};
}
