import {
	Anchor,
	Group,
	List,
	Paper,
	Title,
	useMantineTheme,
} from '@mantine/core';
import { isEmpty, lowerCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@repo/foundations';
import { useAuthUser } from '../../../../api';
import { EntityType } from '../../../../lib/types';
import { trackEvent } from '../../../../utils/analytics';

import { entityModalStore } from '../../store';

function QuestionFooter() {
	const theme = useMantineTheme();
	const { user, workspace } = useAuthUser();
	const navigate = useNavigate();

	const handleDefaultActionClick = () => {
		trackEvent('question/create', {}, user, workspace!);
		entityModalStore.createQuestion(navigate, user);
	};

	const handleCloseModal = () => {
		entityModalStore.setOpened(false);
	};

	if (entityModalStore.type !== EntityType.question) {
		return null;
	}

	return (
		<>
			{!isEmpty(entityModalStore.relatedQuestions) && (
				<Paper
					bg={theme.other.getColor('surface/secondary/default')}
					ml={theme.spacing.xl}
					p="md"
					withBorder
					mb={20}
					mt={20}
				>
					<Title order={5} color={theme.other.getColor('text/primary/default')}>
						Similar questions
					</Title>
					<List size="sm" listStyleType="none">
						{entityModalStore.relatedQuestions?.map((question) => (
							<List.Item key={question.id}>
								<Anchor
									onClick={handleCloseModal}
									component={Link}
									to={`/questions/${question.id}`}
								>
									{question.title}
								</Anchor>
							</List.Item>
						))}
					</List>
				</Paper>
			)}
			<Group position="right">
				<Button
					onClick={handleDefaultActionClick}
					loading={entityModalStore.loading}
					variant="primary"
					size="md"
				>
					{entityModalStore.action === 'create'
						? `Create ${lowerCase(entityModalStore.type)}`
						: `Save ${lowerCase(entityModalStore.type)}`}
				</Button>
			</Group>
		</>
	);
}

export default observer(QuestionFooter);
