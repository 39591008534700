import { iconSize, space } from '@repo/theme/primitives';
import type { EntityType } from '../../lib/types';

export const TREE_INDENT = space[3];
export type DocumentTreeNode = {
	id: string;
	name: string;
	entityType: EntityType;
	hasChildren: boolean;
	fetchedChildren: boolean;
	children: DocumentTreeNode[];
	isOpen: boolean;
	isLoading: boolean;
};

export const NAV_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX = 'navigationSidebar';
export const NAV_SIDEBAR_TRIGGER_WIDTH = space[32];
export const COLLAPSED_NAV_SIDEBAR_WIDTH =
	iconSize.md + 2 * space[2] + 2 * space[3];
export const EXPANDED_NAV_MIN_SIDEBAR_WIDTH = space[60];
export const EXPANDED_NAV_MAX_SIDEBAR_WIDTH = space[120];

export const ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX = 'entitySidebar';
export const ENTITY_SIDEBAR_WIDTH = space[80];
export const SIDEBAR_COLOR = 'transparent';
export const TOP_NAVBAR_HEIGHT = 52;
