import { useDebounceEffect } from 'ahooks';

import { useState } from 'react';
import { isNil } from 'lodash-es';
import { EntityType } from '../../../lib/types';
import DescriptionEditor from '../../DescriptionEditor';
import { useIntegrationPrefetched } from '../../../api';

export interface IEntityPageDescriptionProps {
	entityId?: string;
	description: string;
	readOnly?: boolean;
	onChange: (title: string) => void;
	integrationId?: string;
}

function EntityPageDescription({
	entityId,
	description,
	readOnly = false,
	onChange,
	integrationId,
}: IEntityPageDescriptionProps) {
	const [inputValue, setInputValue] = useState<string>(description);

	const { data: integration } = useIntegrationPrefetched({
		id: integrationId || '',
		options: {
			enabled: !isNil(integrationId),
		},
	});

	const persistDescription = integration
		? !!integration.credentials?.use_native_descriptions
		: undefined;

	// Debounce the callback.
	useDebounceEffect(
		() => {
			// Prevent the callback from being called on first load.
			if (description !== inputValue) {
				onChange(inputValue);
			}
		},
		[inputValue],
		{ wait: 250 }
	);

	return (
		<DescriptionEditor
			entityId={entityId}
			entityType={EntityType.document}
			readOnly={readOnly}
			value={inputValue}
			onChange={setInputValue}
			integrationId={integrationId}
			persistDescription={persistDescription}
		/>
	);
}

export default EntityPageDescription;
