import { isNil, startCase } from 'lodash-es';
import { ColumnType } from '../../types';
import { TableColumns } from './table';

const IGNORE_NATIVE_TYPE_CASE_CONVERSION = ['sObject'];

function getNativeTypeLabel(value?: string) {
	if (isNil(value)) {
		return '';
	}

	return IGNORE_NATIVE_TYPE_CASE_CONVERSION.includes(value)
		? value
		: startCase(value);
}

export const CatalogColumns = {
	...TableColumns,
	type: {
		field: 'native_type',
		type: ColumnType.textFilterColumn,
	},
	integration: {
		field: 'integration',

		sortable: true,
	},
	parent: {
		field: 'parent',

		sortable: false,
	},
};
