import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type { IMetric } from '../../types';
import { metricsQueryKeyFactory } from '.';

export function useGetOrCreateMetric({ id, ...data }: Partial<IMetric>) {
	return useQuery(
		[...metricsQueryKeyFactory.all(), 'get_or_create', id],
		async () => {
			const url = getEndpoints([...metricsQueryKeyFactory.namespace]).root();
			const { data: metric } = await apiClient.post<IMetric[]>(url, {
				id,
				...data,
			});
			return metric;
		},

		{ suspense: true }
	);
}
