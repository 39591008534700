import { useNavigate } from 'react-router';
import { Button, Title } from '@repo/foundations';
import { useCreateForm } from '../../../api/hooks/forms';
import {
	NavBarWrapper,
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
} from '.';

function FormListPageNavBar() {
	const { mutateAsync: createForm } = useCreateForm();
	const navigate = useNavigate();

	const handleCreateForm = async () => {
		const form = await createForm({
			form: {
				title: '',
			},
		});

		navigate(`/forms/${form.id}`);
	};

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Title order={1} size="sm" data-testid="forms-nav-bar-title">
					Forms
				</Title>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<Button variant="primary" onClick={handleCreateForm}>
					Create form
				</Button>
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default FormListPageNavBar;
