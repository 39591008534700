import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IDatabase } from '../../types/models/database';

const DATABASE_NAMESPACE = ['table', 'databases'];

export const databasesQueryKeyFactory = createQueryKeys(DATABASE_NAMESPACE);

const { prefetchDatabase, prefetchDatabaseList } = prefetchFunctionsFactory(
	'database',
	databasesQueryKeyFactory
);

const {
	useDatabase,
	useDatabaseInfiniteList,
	useDatabaseList,
	useCreateDatabase,
	useDeleteDatabase,
	useUpdateDatabase,
	fetchDatabaseList,
	updateDatabase,
} = baseQueryHooksFactory<IDatabase, 'database'>(
	'database',
	databasesQueryKeyFactory
);

export {
	updateDatabase,
	fetchDatabaseList,
	prefetchDatabase,
	prefetchDatabaseList,
	useDatabase,
	useDatabaseInfiniteList,
	useDatabaseList,
	useCreateDatabase,
	useDeleteDatabase,
	useUpdateDatabase,
};
