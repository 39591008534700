export const stripWidthHeight = (link?: string | null) => {
	if (!link) {
		return { href: undefined, width: undefined, height: undefined };
	}

	const url = new URL(link);
	const { searchParams: params } = url;
	const width = params.get('embedWidth');
	const height = params.get('embedHeight');
	params.delete('embedWidth');
	params.delete('embedHeight');
	const paramsAsString = `${params.toString().length > 0 ? '?' : ''}${
		params.toString() ?? ''
	}`;
	const href = new URL(url.pathname, url.href) + paramsAsString;
	// The false issue is an artifact of a buggy embed. Can be removed in the future.
	return { href: href.trim().replace(/false$/, ''), width, height };
};
