import { Menu, Tooltip } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { IconButton } from '@repo/foundations';

interface TableV2HeaderDotsProps {
	onDownload: VoidFunction;
}

export function TableV2HeaderDots({ onDownload }: TableV2HeaderDotsProps) {
	return (
		<Menu position="bottom-end">
			<Menu.Target>
				<Tooltip label="Additional actions">
					<IconButton
						data-testid="dots-button"
						iconName="dots"
						variant="tertiary"
					/>
				</Tooltip>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item icon={<Icon name="download" />} onClick={onDownload}>
					Export page as CSV
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
