import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { trackEvent } from '../../utils/analytics';
import { useAuthUser } from './authUser';

export function useRegionRedirect() {
	const { domain } = useAuthUser();

	const queryFn = async () => {
		const res = await axios.get(
			`https://region-redirect.secoda.workers.dev/redirect/get?domain=${domain}`
		);
		if (res.data?.to) {
			const redirect = new URL(res.data?.to);
			const current = new URL(window.location.href);

			if (redirect.origin !== current.origin) {
				trackEvent('region_redirect', { region: res.data.to });
				setTimeout(() => {
					window.open(res.data.to, '_self');
				}, 1000);
			}
			return true;
		}
		return false;
	};

	return useQuery<boolean, boolean, void>(['region'], queryFn);
}
