import { makeAutoObservable } from 'mobx';
import { debounce } from 'lodash-es';
import {
	ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX,
	ENTITY_SIDEBAR_WIDTH,
} from '../SecodaAppShell/constants';

export type EntitySidebarMode = 'info' | 'comment' | 'ai';

export class EntitySideBarStore {
	collapsed: boolean | null = null;

	mode: EntitySidebarMode = 'info';

	entitySidebarWidth: number = ENTITY_SIDEBAR_WIDTH;

	activeTab?: string;

	get width() {
		return this.collapsed ? 0 : this.entitySidebarWidth;
	}

	setCollapsed = (shouldCollapse: boolean) => {
		if (shouldCollapse) {
			localStorage.setItem(
				`${ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`,
				'true'
			);
		} else {
			localStorage.setItem(
				`${ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`,
				'false'
			);
		}
		this.collapsed = shouldCollapse;
	};

	setMode = (mode: 'info' | 'comment' | 'ai') => {
		localStorage.setItem(
			`${ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Mode`,
			mode
		);
		this.mode = mode;
	};

	setWidthLocalStorageDebounce = debounce((width: number) => {
		localStorage.setItem(
			`${ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Width`,
			width.toString()
		);
	}, 500);

	setWidth = (updatedWidth: number) => {
		this.entitySidebarWidth = updatedWidth;
		this.setWidthLocalStorageDebounce(updatedWidth);
	};

	setActiveTab = (tab: string) => {
		this.activeTab = tab;
	};

	constructor() {
		const localStorageWidth = localStorage.getItem(
			`${ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Width`
		);

		this.activeTab = 'Secoda';

		if (localStorageWidth) {
			this.entitySidebarWidth = parseInt(localStorageWidth, 10);
		}

		const initialCollapsed =
			localStorage.getItem(
				`${ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Collapsed`
			) === 'true';
		if (initialCollapsed) {
			this.setCollapsed(true);
		}

		const mode = localStorage.getItem(
			`${ENTITY_SIDEBAR_LOCAL_STORAGE_PROPERTY_PREFIX}Mode`
		);
		if (mode === 'info' || mode === 'comment' || mode === 'ai') {
			this.setMode(mode);
		} else {
			this.setMode('info');
		}

		makeAutoObservable(this);
	}
}

export const entitySideBarStore = new EntitySideBarStore();
