import { Stack, Group, Divider } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { Button } from '@repo/foundations';
import type { DevPanelItemProps } from './DevPanel';

interface IDefaultDevDropdownProps extends DevPanelItemProps {
	label: string;
	children: JSX.Element;
}

export function DefaultDevDropdown({
	close,
	label,
	children,
}: IDefaultDevDropdownProps) {
	return (
		<Stack spacing="xs">
			<Group>
				<Button size="sm" onClick={close}>
					<Icon name="arrowLeft" />
				</Button>
				<Text size="md" weight="semibold">
					{label}
				</Text>
			</Group>
			<Divider />
			{children}
		</Stack>
	);
}
