import styled from 'styled-components';
import { Icon } from '@repo/foundations';
import { stringToColor } from '../utils/color';

type Props = {
	title: string;
	size?: number;
};

export default function FileExtension(props: Props) {
	const parts = props.title.split('.');
	const extension = parts.length > 1 ? parts.pop() : undefined;

	return (
		<IconContainer
			style={{ background: stringToColor(extension || '') }}
			$size={props.size || 28}
		>
			{extension ? (
				<span>{extension?.slice(0, 4)}</span>
			) : (
				<Icon name="paperclip" />
			)}
		</IconContainer>
	);
}

const IconContainer = styled.span<{ $size: number }>`
	font-family: ${(props) => props.theme.fontFamilyMono};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	text-transform: uppercase;
	color: white;
	text-align: center;
	border-radius: 4px;

	min-width: ${(props) => props.$size}px;
	height: ${(props) => props.$size}px;
`;
