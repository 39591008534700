import { Badge, createStyles } from '@mantine/core';

interface IPublishStatusBadgeProps {
	value?: boolean;
}

const useStyles = createStyles(
	(theme, { value = false }: IPublishStatusBadgeProps) => {
		const backgroundColor = value
			? theme.other.getColor('fill/success-secondary/default')
			: theme.other.getColor('fill/secondary/default');

		const color = value
			? theme.other.getColor('text/success/default')
			: theme.other.getColor('text/secondary/default');
		return {
			root: { backgroundColor },
			inner: { color },
		};
	}
);

export default function PublishStatusBadge({
	value,
}: IPublishStatusBadgeProps) {
	const { classes } = useStyles({ value });

	return (
		<Badge
			classNames={{
				root: classes.root,
				inner: classes.inner,
			}}
		>
			{value ? 'Published' : 'Draft'}
		</Badge>
	);
}
