import type { Dispatch, RefObject, SetStateAction } from 'react';
import {
	Center,
	ScrollArea,
	type ScrollAreaProps,
	Space,
	Stack,
} from '@mantine/core';
import { findIndex } from 'lodash-es';
import { space } from '@repo/theme/primitives';
import { EmptyState } from '../EmptyState';
import type { WidgetData } from './types';
import type { IWidgetSelectorGroupProps } from './WidgetSelectorGroup';
import { WidgetSelectorGroup } from './WidgetSelectorGroup';
import { sortArrayByAnotherArray } from './utils';

interface IWidgetSelectorScrollViewProps<T extends object = object> {
	data: Record<string, WidgetData<T>[]>;
	groupNames: string[];
	setGroupNames: Dispatch<SetStateAction<string[]>>;
	viewportRef: RefObject<HTMLDivElement>;
	onWidgetSelect: IWidgetSelectorGroupProps<T>['onWidgetSelect'];
	scrollAreaHeight?: ScrollAreaProps['h'];
	scrollAreaProps?: Omit<ScrollAreaProps, 'viewportRef' | 'h'>;
	widgetGroupProps?: Omit<
		IWidgetSelectorGroupProps<T>,
		| 'groupName'
		| 'widgets'
		| 'onHidden'
		| 'onVisible'
		| 'onWidgetSelect'
		| 'renderer'
	>;
	cols?: number;
	renderer?: IWidgetSelectorGroupProps<T>['renderer'];
}

function WidgetSelectorScrollView<T extends object = object>({
	data,
	groupNames,
	setGroupNames,
	viewportRef,
	onWidgetSelect,
	scrollAreaHeight,
	scrollAreaProps,
	widgetGroupProps,
	cols,
	renderer,
}: IWidgetSelectorScrollViewProps<T>) {
	const allGroupNames = Object.keys(data);

	// Persist groupNames when the current group is hidden but no new group is visible.
	let replace = false;

	if (allGroupNames.length === 0) {
		return (
			<Center w="100%" h={scrollAreaHeight}>
				<EmptyState
					title="No results found"
					description="Try refining your search or create a custom integration."
					iconName="search"
					includeGoBack={false}
					size="lg"
					stateHeight={space[40]}
				/>
			</Center>
		);
	}

	const handleWidgetGroupVisible = (groupName: string) => () => {
		if (!groupNames.includes(groupName)) {
			if (replace) {
				setGroupNames([groupName]);
				replace = false;
			} else {
				setGroupNames((prev) =>
					sortArrayByAnotherArray([...prev, groupName], allGroupNames)
				);
			}
		}
	};

	const handleWidgetGroupHidden = (groupName: string) => () => {
		const index = findIndex(groupNames, (name) => name === groupName);

		if (index !== -1) {
			if (index === 0 && groupNames.length === 1) {
				replace = true;
			} else {
				setGroupNames((prev) => {
					const next = [...prev];
					next.splice(index, 1);
					return sortArrayByAnotherArray(next, allGroupNames);
				});
			}
		}
	};

	return (
		<ScrollArea
			viewportRef={viewportRef}
			w="100%"
			h={scrollAreaHeight}
			offsetScrollbars
			{...scrollAreaProps}
		>
			<Stack spacing="lg">
				{Object.entries(data).map(([groupName, widgets]) => (
					<WidgetSelectorGroup<T>
						key={groupName}
						groupName={groupName}
						widgets={widgets}
						cols={cols}
						renderer={renderer}
						onVisible={handleWidgetGroupVisible(groupName)}
						onHidden={handleWidgetGroupHidden(groupName)}
						onWidgetSelect={onWidgetSelect}
						{...widgetGroupProps}
					/>
				))}
			</Stack>
			<Space h={`calc(${scrollAreaHeight} - calc(${scrollAreaHeight} / 4))`} />
		</ScrollArea>
	);
}

export default WidgetSelectorScrollView;
