import { Box } from '@mantine/core';
import { Title } from '@repo/foundations';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router';
import { captureError } from '../../web-tracing';

export function RootAuthRedirect() {
	const error = useRouteError();
	const navigate = useNavigate();

	useEffect(() => {
		if (error) {
			captureError(error);
		}

		if ((error as AxiosError)?.message?.includes('403')) {
			navigate('/auth');
		}
	}, [navigate, error]);

	return (
		<Box p={20}>
			<Title order={1} size="xl" mt={52}>
				Redirecting to auth...
			</Title>
		</Box>
	);
}
