import { useQuery } from '@tanstack/react-query';
import { omit } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import type { IDictionaryTerm } from '../../types';
import { dictionaryTermsQueryKeyFactory } from '.';

export function useGetOrCreateDictionaryTerm({
	id,
	...props
}: Partial<IDictionaryTerm> & { options: Record<string, unknown> }) {
	return useQuery(
		[...dictionaryTermsQueryKeyFactory.all(), 'get_or_create', id],
		async () => {
			if (!props.options?.enabled) {
				return null;
			}

			const data = omit(props, ['options']);

			const url = getEndpoints([
				...dictionaryTermsQueryKeyFactory.namespace,
			]).root();
			const { data: term } = await apiClient.post<IDictionaryTerm>(url, {
				id,
				...data,
			});
			return term;
		},

		{ suspense: true }
	);
}
