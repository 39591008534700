import { baseQueryHooksFactory } from '../../factories';
import type { IBackgroundJob } from '../../types/models/backgroundJob';
import { backgroundJobQueryKeyFactory } from './constants';

const { useBackgroundJob, useBackgroundJobList, fetchBackgroundJobList } = baseQueryHooksFactory<
	IBackgroundJob,
	'backgroundJob'
>('backgroundJob', backgroundJobQueryKeyFactory);

export * from './useBackgroundJobTerminate';
export { fetchBackgroundJobList, useBackgroundJob, useBackgroundJobList };

