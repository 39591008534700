import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IJob, IJobGroup, IJobRun, IJobTask } from '../../types';

export const JOBS_NAMESPACE = ['job', 'jobs'];

export const jobsQueryKeyFactory = createQueryKeys(JOBS_NAMESPACE);

const { prefetchJob, prefetchJobList } = prefetchFunctionsFactory(
	'job',
	jobsQueryKeyFactory
);

const {
	useJob,
	useJobInfiniteList,
	useJobList,
	useCreateJob,
	useDeleteJob,
	useUpdateJob,
	fetchJobList,
	updateJob,
} = baseQueryHooksFactory<IJob, 'job'>('job', jobsQueryKeyFactory);

export const JOB_RUNS_NAMESPACE = ['job', 'runs'];

export const jobRunsQueryKeyFactory = createQueryKeys(JOB_RUNS_NAMESPACE);

const { useJobRunsList, useJobRunsInfiniteList } = baseQueryHooksFactory<
	IJobRun,
	'jobRuns'
>('jobRuns', jobRunsQueryKeyFactory);

export const JOB_GROUPS_NAMESPACE = ['job', 'groups'];

export const jobGroupsQueryKeyFactory = createQueryKeys(JOB_GROUPS_NAMESPACE);

const { useJobGroupsList } = baseQueryHooksFactory<IJobGroup, 'jobGroups'>(
	'jobGroups',
	jobGroupsQueryKeyFactory
);

export const JOB_TASKS_NAMESPACE = ['job', 'tasks'];

export const jobTasksQueryKeyFactory = createQueryKeys(JOB_TASKS_NAMESPACE);

const { useJobTasksList, useJobTasksInfiniteList } = baseQueryHooksFactory<
	IJobTask,
	'jobTasks'
>('jobTasks', jobTasksQueryKeyFactory);

export {
	updateJob,
	fetchJobList,
	prefetchJob,
	prefetchJobList,
	useJob,
	useJobInfiniteList,
	useJobList,
	useCreateJob,
	useDeleteJob,
	useUpdateJob,
	useJobRunsList,
	useJobGroupsList,
	useJobTasksList,
	useJobTasksInfiniteList,
	useJobRunsInfiniteList,
};
