import { useEffect, useState } from 'react';
import { ISecodaEntity, useMonitor, useSecodaEntity, useUser } from '../../api';
import { createMockableHook } from '../../utils/createMockableHook';
import type {
	UserPreview,
	EntityPreview,
	MonitorPreview,
} from '../ResourcePreview/ResourcePreview';

const ID_REGEX =
	/\/(.*)\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;

export enum ResourceLinkType {
	Entity = 'entity',
	User = 'user',
	Monitor = 'monitor',
}

function useResourceLinkInternal({
	href,
}: {
	href: string | undefined | null;
}) {
	const [id, setId] = useState<string>('');
	const [resourceType, setResourceType] = useState<ResourceLinkType | null>(
		null
	);

	const {
		data: entityData,
		isInitialLoading: isLoadingEntity,
		error: errorEntity,
	} = useSecodaEntity({
		id,
		options: {
			enabled: resourceType === ResourceLinkType.Entity,
		},
	});

	const {
		data: userData,
		isInitialLoading: isLoadingUser,
		error: errorUser,
	} = useUser({
		id,
		options: {
			enabled: resourceType === ResourceLinkType.User,
		},
	});

	const {
		data: monitorData,
		isInitialLoading: isLoadingMonitor,
		error: errorMonitor,
	} = useMonitor({
		id,
		options: {
			enabled: resourceType === ResourceLinkType.Monitor,
		},
	});
	const {
		data: monitorTargetData,
		isInitialLoading: isLoadingMonitorTarget,
		error: errorMonitorTarget,
	} = useSecodaEntity({
		id: monitorData?.target ?? '',
		options: {
			enabled:
				resourceType === ResourceLinkType.Monitor && !!monitorData?.target,
		},
	});

	useEffect(() => {
		if (!href) {
			return;
		}

		const matches = href.match(ID_REGEX);

		if (matches?.length !== 3) {
			return;
		}

		if (matches?.[1] === 'user') {
			setResourceType(ResourceLinkType.User);
			setId(matches?.[2]);
		} else if (matches?.[1] === 'monitors') {
			setResourceType(ResourceLinkType.Monitor);
			setId(matches?.[2]);
		} else {
			setResourceType(ResourceLinkType.Entity);
			setId(matches?.[2]);
		}
	}, [href]);

	let data: EntityPreview | UserPreview | MonitorPreview =
		entityData as EntityPreview;
	if (resourceType === ResourceLinkType.User) {
		data = userData as UserPreview;
	} else if (resourceType === ResourceLinkType.Monitor) {
		data = {
			id: monitorData?.id,
			entity_type: 'monitor',
			title: monitorData?.name,
			description: monitorData?.description,
			targetEntity: monitorTargetData as ISecodaEntity,
		} as MonitorPreview;
	}

	return {
		id,
		data,
		resourceType,
		isLoading:
			isLoadingEntity ||
			isLoadingUser ||
			isLoadingMonitor ||
			isLoadingMonitorTarget,
		hasError:
			!!errorEntity || !!errorUser || !!errorMonitor || !!errorMonitorTarget,
	};
}

export const [useResourceLink, MockUseResourceLink] = createMockableHook(
	useResourceLinkInternal
);
