import type { DataQualitySuggestion } from '../../api/codegen/apiSchemas';

export enum DataQualityClassification {
	GOOD = 'good',
	MODERATE = 'moderate',
	POOR = 'poor',
	UNAVAILABLE = 'unavailable',
}

export interface DataQualityBreakdownSubItem extends DataQualitySuggestion {
	percentage: number;
	classification: DataQualityClassification;
}

export interface DataQualityBreakdownSubItemCompleted
	extends Pick<
		DataQualitySuggestion,
		| 'data_quality_key'
		| 'data_quality_top_key'
		| 'max_score'
		| 'score'
		| 'title'
	> {
	percentage: number;
	classification: DataQualityClassification;
	isCompleted: true;
}

export interface DataQualityBreakdownItem {
	score: number;
	maxScore: number;
	percentage: number;
	classification: DataQualityClassification;
	title: string;
	key: DataQualitySuggestion['data_quality_top_key'];
	subItems: Array<
		DataQualityBreakdownSubItem | DataQualityBreakdownSubItemCompleted
	>;
}

export type QualityFilterKey =
	| 'total'
	| DataQualitySuggestion['data_quality_top_key'];
