import { observer } from 'mobx-react-lite';
import BackgroundJobProgress from '../BackgroundJobProgress';
import globalBackgroundJobStore from './store';

function GlobalBackgroundJob() {
	// Return null if there is no active job
	if (!globalBackgroundJobStore.activeJob) {
		return null;
	}

	return (
		<BackgroundJobProgress
			job={globalBackgroundJobStore.activeJob}
			onCompleted={globalBackgroundJobStore.onComplete}
		/>
	);
}

export default observer(GlobalBackgroundJob);
