import type { PluginSimple, Options } from 'markdown-it';
import markdownit from 'markdown-it';
import markdownItAttrs from 'markdown-it-attrs';

export default function rules({
	options = {},
	plugins = [],
}: {
	options?: Partial<Options & { disable?: string | string[] }>;
	plugins?: PluginSimple[];
}) {
	const markdownIt = markdownit('default', {
		breaks: false,
		html: false,
		linkify: false,
		...options,
	});
	markdownIt.use(markdownItAttrs, {
		allowedAttributes: [
			// used for heading anchor links
			'id',
			// used for table column's resizing
			'colwidth',
		],
	});
	plugins.forEach((plugin) => markdownIt.use(plugin));

	if (options.disable) {
		markdownIt.disable(options.disable, true);
	}

	return markdownIt;
}
