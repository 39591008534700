import { Plugin } from 'prosemirror-state';
import { DecorationSet, Decoration, EditorView } from 'prosemirror-view';
import { NodeSpec } from 'prosemirror-model';
import { ParseSpec } from 'prosemirror-markdown';
import {
	getCellAttrs,
	getCellAttrsFromMarkdownToken,
	setCellAttrs,
} from '../lib/table';
import { addColumnBefore, selectColumn } from '../commands/table';
import { getCellsInRow, isColumnSelected } from '../queries/table';
import Node from './Node';

export default class TableHeadCell extends Node {
	get name() {
		return 'th';
	}

	get schema(): NodeSpec {
		return {
			content: 'paragraph+',
			tableRole: 'header_cell',
			isolating: true,
			parseDOM: [{ tag: 'th', getAttrs: getCellAttrs }],
			toDOM(node) {
				return ['th', setCellAttrs(node), 0];
			},
			attrs: {
				colspan: { default: 1 },
				rowspan: { default: 1 },
				colwidth: { default: null },
				alignment: { default: null },
			},
		};
	}

	toMarkdown() {
		// See: renderTable
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'th',
			getAttrs: (tok) => getCellAttrsFromMarkdownToken(tok),
		};
	}

	get plugins() {
		function buildAddColumnDecoration(pos: number, index: number) {
			let className = 'table-add-column';
			if (index === 0) {
				className += ' first';
			}

			return Decoration.widget(
				pos + 1,
				() => {
					const plus = document.createElement('a');
					plus.role = 'button';
					plus.className = className;
					plus.dataset.index = index.toString();
					return plus;
				},
				{
					key: `${className}-${index}`,
				}
			);
		}

		return [
			new Plugin({
				props: {
					handleDOMEvents: {
						mousedown: (view: EditorView, event: MouseEvent) => {
							if (!(event.target instanceof HTMLElement)) {
								return false;
							}

							const targetAddColumn = event.target.closest('.table-add-column');
							if (targetAddColumn) {
								event.preventDefault();
								event.stopImmediatePropagation();
								const index = Number(
									targetAddColumn.getAttribute('data-index')
								);
								addColumnBefore({ index })(view.state, view.dispatch);
								return true;
							}

							const targetGripColumn = event.target.closest('.grip-column');
							if (targetGripColumn) {
								event.preventDefault();
								event.stopImmediatePropagation();

								selectColumn(
									Number(targetGripColumn.getAttribute('data-index')),
									event.metaKey || event.shiftKey
								)(view.state, view.dispatch);
								return true;
							}

							return false;
						},
					},
					decorations: (state) => {
						if (!this.editorState.view?.editable) {
							return;
						}

						const { doc } = state;
						const decorations: Decoration[] = [];
						const cols = getCellsInRow(0)(state);

						if (cols) {
							cols.forEach((pos, index) => {
								let className = 'grip-column';
								if (isColumnSelected(index)(state)) {
									className += ' selected';
								}
								if (index === 0) {
									className += ' first';
								}
								if (index === cols.length - 1) {
									className += ' last';
								}

								decorations.push(
									Decoration.widget(
										pos + 1,
										() => {
											const grip = document.createElement('a');
											grip.role = 'button';
											grip.className = className;
											grip.dataset.index = index.toString();
											return grip;
										},
										{
											key: `${className}-${index}`,
										}
									)
								);

								if (index === 0) {
									decorations.push(buildAddColumnDecoration(pos, index));
								}

								decorations.push(buildAddColumnDecoration(pos, index + 1));
							});
						}

						return DecorationSet.create(doc, decorations);
					},
				},
			}),
		];
	}
}
