import { Anchor } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';
import { makeAutoObservable } from 'mobx';
import type { NavigateFunction } from 'react-router-dom';
import type { IForm, ISecodaEntity, ITemplate } from '../../api';
import { queryClient, questionsQueryKeyFactory } from '../../api';
import type { APIListResponseData, User } from '../../lib/models';
import {
	Collection,
	DictionaryTerm,
	Document,
	Metric,
	Question,
	ResourceRelation,
	search,
	SecodaEntity,
} from '../../lib/models';
import { EntityType } from '../../lib/types';
import { v4 } from '../../utils/uuid/v4';
import { invalidateResourceRelationList } from '../../api/hooks/relations';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
class EntityModalStore {
	action: 'create' | 'edit' | undefined;

	collections: Collection[] = [];

	type: EntityType | undefined;

	relatedResource?: SecodaEntity | ISecodaEntity;

	template: ITemplate | undefined;

	loading = false;

	opened = false;

	form: IForm | undefined;

	title = '';

	description = '';

	metadata: Record<string, unknown> = {};

	relatedQuestions: Question[] | undefined;

	loadingRelatedQuestions = false;

	// Warning: deprecated attribute. We should be able to support multiple teams in the future.
	// Currently only questions support multiple teams.
	teamId?: string;

	teamIds: string[] | undefined;

	// Uses to force re-render of the rich editor when the template changes.
	richEditorKey = Math.random();

	constructor() {
		makeAutoObservable(this);
	}

	reset() {
		this.richEditorKey = Math.random();
		this.action = undefined;
		this.type = undefined;
		this.relatedResource = undefined;
		this.template = undefined;
		this.loading = false;
		this.opened = false;
		this.title = '';
		this.description = '';
		this.metadata = {};
		this.teamId = undefined;
		this.relatedQuestions = undefined;
		this.loadingRelatedQuestions = false;
		this.teamIds = undefined;
	}

	setOpened(opened: boolean) {
		this.opened = opened;
	}

	setRelatedResource(relatedResource: SecodaEntity | ISecodaEntity) {
		this.relatedResource = relatedResource;
	}

	setTeamIds(teamIds: string[]) {
		this.teamIds = teamIds;
	}

	async setEntity(
		action: 'create' | 'edit',
		type: EntityType,
		teamId?: string,
		autoOpen = true
	) {
		this.action = action;
		this.type = type;
		this.teamId = teamId;

		// Only open after all properties have been set.
		if (autoOpen) {
			this.opened = true;
		}
	}

	setTitle = (title: string) => {
		this.title = title;
	};

	setTemplate = (template: ITemplate) => {
		this.template = template;
		this.richEditorKey = Math.random();
		this.setDescription(template.definition ?? template.description ?? '');
		this.setTitle(template.title ?? '');
		this.setMetadata('parent', template.parent ?? '');
		this.setMetadata('published', template.published ?? false);
		this.setMetadata('assignee', template.assignee ?? '');
		this.setMetadata('priority', template.priority ?? '');
		this.setMetadata('tags', template.tags ?? []);
		this.setMetadata('owners', template.owners ?? []);
		this.setMetadata('collections', template.collections ?? []);
	};

	setDescription = (description: string) => {
		this.description = description;
	};

	setMetadata = (key: string, value: unknown) => {
		this.metadata[key] = value;
	};

	checkEmptyTitle() {
		if (this.title === '') {
			showNotification({
				title: 'Title required',
				message: 'Please add a title before creating',
			});
			this.loading = false;
			return true;
		}
		return false;
	}

	findRelatedQuestions = () => {
		this.loadingRelatedQuestions = true;
		search(
			{ search_term: this.title, page: 1, entity_type: EntityType.question },
			false
		)
			// @ts-expect-error TS(2304): Cannot find name 'AxiosResponse'.
			.then((res: AxiosResponse<APIListResponseData>) => {
				if (res.data.results.length > 0) {
					this.relatedQuestions = res.data.results.slice(0, 5); // Get max 5 related questions
				}
				this.loadingRelatedQuestions = false;
			})
			.catch(() => {
				this.loadingRelatedQuestions = false;
			});
	};

	quickCreateEntity = async (
		entityType: EntityType,
		navigate: NavigateFunction,
		currentUser: User,
		navigateToEntity: boolean = false
	) => {
		if (this.checkEmptyTitle()) {
			return;
		}

		switch (entityType) {
			case EntityType.question:
				await this.createQuestion(navigate, currentUser, navigateToEntity);
				break;
			case EntityType.collection:
				await this.createCollection(navigate, navigateToEntity);
				break;
			case EntityType.document:
				this.createDocument(navigate, navigateToEntity);
				break;
			case EntityType.metric:
				this.createMetric(navigate, navigateToEntity);
				break;
			case EntityType.dictionary_term:
				this.createDictionaryTerm(navigate, navigateToEntity);
				break;
			default:
				break;
		}
	};

	createQuestion = async (
		navigate: NavigateFunction,
		currentUser: User,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) {
			return;
		}

		const assignedTo = this.metadata.assignee ?? null;

		const priority = this.metadata.priority ?? null;

		const tags = this.metadata.tags ?? [];

		const teams = [];

		if (this.teamIds && this.teamIds.length > 0) {
			teams.push(...this.teamIds);
		} else if (this.teamId) {
			// Legacy support for single team questions.
			teams.push(this.teamId);
		}

		try {
			const question = await Question.create({
				title: this.title,
				definition: this.description,
				owners: [currentUser.id],
				assigned_to: assignedTo,
				priority,
				tags,
				teams,
				properties: this.template?.properties ?? {},
			});

			if (this.relatedResource) {
				await ResourceRelation.create({
					from_entity: question.id,
					to_entity: this.relatedResource.id,
				}).then(() => {
					if (this.relatedResource) {
						invalidateResourceRelationList(this.relatedResource.id);
					}
					invalidateResourceRelationList(question.id);
				});
			}

			if (navigateToEntity) {
				navigate(`/questions/${question.id}`);
			}

			// Reload the table v1.

			// Reload the table v2.
			queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

			// Invalidate the questions on the discussions page.
			queryClient.invalidateQueries(
				questionsQueryKeyFactory.list(1, {
					entity_id: this.relatedResource?.id,
				})
			);

			this.reset();

			showNotification({
				title: 'Question created',
				message: (
					<Anchor
						data-testid="created-notification"
						role="link"
						onClick={() => navigate(`/questions/${question.id}`)}
					>
						View your question
					</Anchor>
				),
				color: 'green',
			});
		} catch (error) {
			showNotification({
				title: 'Error creating question',
				message: 'If the issues persists, please contact support',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		} finally {
			this.loading = false;
		}
	};

	createCollection = async (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) {
			return;
		}

		const owners = this.metadata?.owners ?? [];
		const teams = this.teamId ? [this.teamId] : [];
		const collections = this.metadata.parent ? [this.metadata.parent] : [];

		try {
			const collection = (await Collection.create({
				title: this.title,
				description: this.description,
				icon: '🗂',
				owners,
				published: this.metadata.published,
				pinned: this.metadata.pinned,
				teams,
				collections,
				properties: this.template?.properties ?? {},
			})) as Collection;

			showNotification({
				title: 'Collection created',
				message: (
					<Anchor
						data-testid="created-notification"
						role="link"
						onClick={() => navigate(`/collections/${collection.id}`)}
					>
						{collection.title}
					</Anchor>
				),
				color: 'green',
			});

			if (navigateToEntity) {
				navigate(`/collections/${collection.id}`);
			}

			// Reload the table v1.

			// Reload the table v2.
			queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

			this.reset();
		} catch (error) {
			showNotification({
				title: 'Error creating collection',
				message: 'If the issue persists, please contact support',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		} finally {
			this.loading = false;
		}
	};

	createQuery = (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.description = `${this.description}\n\n;;;${v4()};;;`;
		this.createDocument(navigate, navigateToEntity);
	};

	createDocument = (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) return;

		const owners = (this.metadata?.owners ?? []) as string[];
		const collections = (this.metadata?.collections ?? []) as string[];
		const tags = (this.metadata?.tags ?? []) as string[];
		const teams = this.teamId ? [this.teamId] : [];

		Document.create({
			title: this.title,
			definition: this.description,
			teams,
			owners,
			collections,
			tags,
			properties: this.template?.properties ?? {},
		})
			.then(async (document: any) => {
				showNotification({
					title: 'Document created',
					message: (
						<Anchor
							data-testid="created-notification"
							role="link"
							onClick={() => navigate(`/docs/${document.id}`)}
						>
							{document.title}
						</Anchor>
					),
					color: 'green',
				});

				if (navigateToEntity) {
					navigate(`/docs/${document.id}`);
				}

				// Reload the table v2.
				queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

				this.reset();
			})
			.catch(() => {
				this.loading = false;
				showNotification({
					title: 'Error creating document',
					message: 'If the issues persists, please contact support',
					color: 'red',
					icon: <Icon name="alertCircle" />,
				});
			});
	};

	createMetric = (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) return;

		const owners = (this.metadata?.owners ?? []) as string[];
		const collections = (this.metadata?.collections ?? []) as string[];
		const tags = (this.metadata?.tags ?? []) as string[];

		const teams = this.teamId ? [this.teamId] : [];

		Metric.create({
			title: this.title,
			description: this.description,
			teams,
			owners,
			collections,
			tags,
			properties: this.template?.properties ?? {},
		})
			.then(async (metric: any) => {
				showNotification({
					title: 'Metric created',
					message: (
						<Anchor
							data-testid="created-notification"
							role="link"
							onClick={() => navigate(`/metrics/${metric.id}`)}
						>
							{metric.title}
						</Anchor>
					),
					color: 'green',
				});

				if (navigateToEntity) {
					navigate(`/metrics/${metric.id}`);
				}

				// Reload the table v2.
				queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

				this.reset();
			})
			.catch(() => {
				this.loading = false;
				showNotification({
					title: 'Error creating metric',
					message: 'If the issues persists, please contact support',
					color: 'red',
					icon: <Icon name="alertCircle" />,
				});
			});
	};

	createDictionaryTerm = (
		navigate: NavigateFunction,
		navigateToEntity: boolean = false
	) => {
		this.loading = true;

		if (this.checkEmptyTitle()) return;

		const owners = (this.metadata?.owners ?? []) as string[];
		const collections = (this.metadata?.collections ?? []) as string[];
		const tags = (this.metadata?.tags ?? []) as string[];

		const teams = this.teamId ? [this.teamId] : [];

		DictionaryTerm.create({
			title: this.title,
			description: this.description,
			teams,
			owners,
			collections,
			tags,
			properties: this.template?.properties ?? {},
		})
			.then(async (dictionaryTerm: any) => {
				showNotification({
					title: 'Term created',
					message: (
						<Anchor
							data-testid="created-notification"
							role="link"
							onClick={() => navigate(`/dictionary/${dictionaryTerm.id}`)}
						>
							{dictionaryTerm.title}
						</Anchor>
					),
					color: 'green',
				});

				if (navigateToEntity) {
					navigate(`/dictionary/${dictionaryTerm.id}`);
				}

				// Reload the table v1.

				// Reload the table v2.
				queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.all());

				this.reset();
			})
			.catch(() => {
				this.loading = false;
				showNotification({
					title: 'Error creating term',
					message: 'If the issues persists, please contact support',
					color: 'red',
					icon: <Icon name="alertCircle" />,
				});
			});
	};
}

export const entityModalStore = new EntityModalStore();
