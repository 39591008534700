import { Text, useMantineTheme } from '@mantine/core';
import { isNil } from 'lodash-es';
import { openConfirmModal } from '../../../components/ModalManager';
import {
	useApiGetMyMemberships,
	useApiGetTeam,
	useApiJoinTeam,
	useApiLeaveTeam,
	useApiListTeams,
} from '../../codegen/apiComponents';
import { apiQueryKey } from '../../codegen/apiQueryKey';
import type { TeamOut } from '../../codegen/apiSchemas';
import { queryClient, useAuthUser } from '../../index';

export function useDefaultTeam() {
	const { data: teams } = useApiListTeams(
		{},
		{
			select: (data) => data?.results,
		}
	);
	return {
		defaultTeam: teams?.filter((team) => team.is_default_team)?.[0],
	};
}

export function useCurrentTeam() {
	const { defaultTeam } = useDefaultTeam();

	const urlTeam = window.location.pathname.match(/\/teams\/([^/]+)/);
	const currentTeamId = urlTeam?.[1] ?? defaultTeam?.id ?? '';

	const { data: currentTeam } = useApiGetTeam(
		{
			pathParams: {
				teamId: currentTeamId,
			},
		},
		{
			enabled: !isNil(currentTeamId),
		}
	);

	return {
		currentTeamId,
		currentTeam,
	};
}

export function useTeamPermission() {
	const { isEditorOrAdminUser, user } = useAuthUser();

	const { currentTeam } = useCurrentTeam();
	const { data: memberships } = useApiGetMyMemberships(
		{
			pathParams: {
				// To avoid a crash when the `useCurrentTeam` is loading. `enabled`
				// below prevents this from running until the team is loaded.
				teamId: currentTeam?.id ?? '',
			},
		},
		{
			enabled: !isNil(currentTeam?.id),
		}
	);

	if (memberships?.[0]?.user?.id === user?.id) {
		return {
			write: memberships?.[0]?.write,
			read: memberships?.[0]?.read,
		};
	} else {
		return {
			write: isEditorOrAdminUser,
			read: true,
		};
	}
}

export function useTeamMyMembershipsLeaveJoin(team: TeamOut) {
	const theme = useMantineTheme();
	const { data: myMemberships, isLoading: isLoadingMyMemberships } =
		useApiGetMyMemberships({
			pathParams: {
				teamId: team.id,
			},
		});

	const { mutateAsync: apiJoinTeam, isLoading: isJoining } = useApiJoinTeam({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('auth/teams'));
		},
	});

	const { mutateAsync: apiLeaveTeam, isLoading: isLeaving } = useApiLeaveTeam({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('auth/teams'));
		},
	});

	const leaveTeam = async () => {
		openConfirmModal({
			sx: {
				paddingTop: `${theme.spacing.md} !important`,
				paddingBottom: `${theme.spacing.md} !important`,
			},
			title: (
				<Text size="md" fw={600}>
					Are you sure you want to leave {team.name} ?
				</Text>
			),
			children: (
				<Text size="sm">
					You’ll no longer see this team in your sidebar and you may lose
					permissions to the team’s resources.
				</Text>
			),
			labels: {
				confirm: 'Leave',
				cancel: 'Cancel',
			},
			cancelProps: {
				size: 'md',
			},
			confirmProps: {
				size: 'md',
				variant: 'primary',
				tone: 'critical',
			},
			onConfirm: async () => {
				await apiLeaveTeam({
					pathParams: {
						teamId: team.id,
					},
				});
			},
		});
	};
	return {
		myMemberships,
		isLoadingMyMemberships,
		apiJoinTeam,
		isJoining,
		isLeaving,
		leaveTeam,
	};
}
