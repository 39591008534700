import { FloatingFocusManager } from '@floating-ui/react';
import { Box, BoxProps, createStyles, rem } from '@mantine/core';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useListBoxContext } from './context';
import { ListBoxSearch } from './ListBoxSearch';
import { ListBoxItem, ListBoxItemProps } from './ListBoxItem';
import { isNil } from 'lodash-es';
import { useListBoxStyles } from './ListBox.styles';

interface ListBoxItemsDropdownProps<T = ListBoxItemProps> extends BoxProps {
	search?: React.ComponentProps<typeof ListBoxSearch>;
	renderItem?: (
		item: T,
		getItemProps: (
			userProps: Omit<React.HTMLProps<HTMLElement>, 'ref'>
		) => Omit<React.HTMLProps<HTMLElement>, 'ref'>,
		index: number
	) => React.ReactElement;
	items?: Array<T>;
}

export function ListBoxItemsDropdown<T>({
	children,
	search,
	renderItem,
	items,
	...boxProps
}: PropsWithChildren<ListBoxItemsDropdownProps<T>>) {
	const { classes, cx, theme } = useListBoxStyles();
	const { className, ...rest } = boxProps;

	const {
		opened,
		disabled,
		floatingContext,
		dropdownId,
		refs,
		floatingStyles,
		targetId,
		getFloatingProps,
		onKeyDown,
		activeIndex,
		getItemProps,
		setActiveIndex,
		listRef,
	} = useListBoxContext();

	const searchOnKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			search?.onKeyDown?.(e);

			if (e.key === 'Enter' && !isNil(activeIndex)) {
				e.preventDefault();
				e.stopPropagation();
				listRef.current?.[activeIndex]?.click();
			}
		},
		[search, activeIndex, listRef]
	);

	useEffect(() => {
		setActiveIndex(0);
	}, [items]);

	if (!opened || disabled) {
		return null;
	}

	return (
		<FloatingFocusManager
			context={floatingContext}
			modal={false}
			initialFocus={-1}
		>
			<Box
				{...rest}
				id={dropdownId}
				className={cx(classes.dropdown, className)}
				ref={refs.setFloating}
				style={{
					...floatingStyles,
					paddingTop: search ? undefined : theme.spacing['2xs'],
				}}
				aria-labelledby={targetId}
				{...getFloatingProps({
					onKeyDown,
				})}
			>
				{search && <ListBoxSearch {...search} onKeyDown={searchOnKeyDown} />}
				<Box style={{ overflow: 'auto', flex: 1 }}>
					{items?.map((option, index) =>
						renderItem ? (
							renderItem(
								option,
								({ onClick, ...userProps } = {}) => ({
									...userProps,
									tabIndex: activeIndex === index ? 0 : -1,
									'data-hovered': activeIndex === index ? true : undefined,
									'data-index': index,
									...getItemProps({
										ref: (el: HTMLButtonElement) => {
											listRef.current[index] = el;
										},
										onClick,
									}),
								}),
								index
							)
						) : (
							<ListBoxItem
								{...{
									...option,
									tabIndex: activeIndex === index ? 0 : -1,
									'data-hovered': activeIndex === index ? true : undefined,
									'data-index': index,
									...getItemProps({
										ref: (el: HTMLButtonElement) => {
											listRef.current[index] = el;
										},
									}),
								}}
							/>
						)
					)}
					{children}
				</Box>
			</Box>
		</FloatingFocusManager>
	);
}
