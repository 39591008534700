import type { Attrs, MarkType } from 'prosemirror-model';
import type { TextSelection, Command } from 'prosemirror-state';

// Create a command function that adds a mark to the current selection if appropriate.
// Useful for marks that can overlap and have custom remove logic.
// Command function returns true if the mark should be added.
function addCommentMark(markType: MarkType, attrs: Attrs): Command {
	return (state, dispatch) => {
		const { empty, ranges } = state.selection as TextSelection;

		// If there is no text selection, do nothing.
		if (empty) {
			return false;
		}

		if (dispatch) {
			const { tr } = state;
			for (let i = 0; i < ranges.length; i += 1) {
				const { $from, $to } = ranges[i];
				tr.addMark($from.pos, $to.pos, markType.create(attrs));
			}
			dispatch(tr.scrollIntoView());
		}
		return true;
	};
}

export default addCommentMark;
