import { Box, createStyles, Group, Modal, Table } from '@mantine/core';
import { startCase } from 'lodash-es';
import { Text } from '@repo/foundations';
import ColumnProfile from '../../../../ColumnProfile/ColumnProfile';

type NTile = {
	frequency: number;
	is_numeric: boolean;
	label: string;
	left: number;
	right: number;
};

type ProfileData = {
	count: number;
	max: number;
	mean: number;
	median: number;
	min: number;
	ntiles: NTile[];
	percent_filled: number;
	unique: number;
};

interface IDistributionPopupProps {
	fieldName: string;
	opened: boolean;
	onClose: () => void;
	profileData: ProfileData;
	columnType: string;
	isPrimaryKey?: boolean;
}

const useStyles = createStyles({
	wrapper: {
		display: 'grid',
		gridTemplateRows: '1fr',
		gridTemplateColumns: '30% 60%',
		gridGap: 36,
		padding: 24,
	},
	table: {
		'& .numericCell': {
			textAlign: 'right',
		},
	},
	chartWrapper: {
		display: 'grid',
		placeContent: 'center',
	},
});

function DistributionPopup({
	fieldName,
	columnType,
	profileData,
	opened,
	onClose,
	isPrimaryKey,
}: IDistributionPopupProps) {
	const { classes, theme } = useStyles();

	const { ntiles, ...tabularValues } = profileData;

	const withFrequencyDistribution = ntiles?.length === 0;

	const tableBody = Object.entries(tabularValues).map(([key, value]) => {
		const title = startCase(key.replaceAll('_', ' '));
		return (
			<tr key={key}>
				<td>{title}</td>
				<td className="numericCell">{value}</td>
			</tr>
		);
	});

	return (
		<Modal
			title={
				<Group>
					<Text>Statistics of {fieldName}</Text>
				</Group>
			}
			opened={opened}
			onClose={onClose}
			closeOnClickOutside
			size="70%"
			centered
			padding={24}
		>
			<Box className={classes.wrapper}>
				<Table
					className={classes.table}
					horizontalSpacing="sm"
					highlightOnHover
					fontSize={12}
				>
					<thead>
						<tr>
							<th>Statistic</th>
							<th className="numericCell">Value</th>
						</tr>
					</thead>
					<tbody>{tableBody}</tbody>
				</Table>
				<Box className={classes.chartWrapper}>
					{withFrequencyDistribution ? (
						<Text size="sm">
							<i>
								Cannot chart frequency distribution for &quot;{columnType}&quot;
								field
							</i>
						</Text>
					) : (
						<ColumnProfile
							axis
							data={ntiles}
							height={theme.other.space[90]}
							width={theme.other.space[120]}
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
}

export default DistributionPopup;
