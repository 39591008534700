import { EntityType } from '../../lib/types';
import type { IEntitySidebarContentProps } from '../EntityPageLayout/EntitySidebarContent';

export function getEntityPageSidebarProps(
	entityType: EntityType
): Partial<IEntitySidebarContentProps> {
	switch (entityType) {
		case EntityType.document:
		case EntityType.metric:
		case EntityType.dictionary_term:
			return {
				withOwnerSelector: true,
				withCollectionSelector: true,
				withTagSelector: true,
				withCustomPropertyEditors: true,
				withRelatedResourceSelector: true,
				withTeamSelector: true,
				withVerifiedSelector: true,
				withGovernanceSelector: true,
			};

		case EntityType.collection:
			return {
				withCustomPropertyEditors: true,
				withEntityPopularity: true,
				withFrequentUsers: true,
				withOwnerSelector: true,
				withTagSelector: true,
				withTeamSelector: true,
				withCollectionParentSelector: true,
			};
		case EntityType.column:
			return {
				withCollectionSelector: true,
				withRelatedResourceSelector: true,
				withEntityPopularity: true,
				withFrequentUsers: true,
				withGovernanceSelector: true,
				withOwnerSelector: true,
				withTagSelector: true,
				withVerifiedSelector: true,
			};
		case EntityType.table:
		case EntityType.dashboard:
		case EntityType.chart:
		case EntityType.job:
		case EntityType.event:
			return {
				withCollectionSelector: true,
				withCustomPropertyEditors: true,
				withEntityPopularity: true,
				withFrequentUsers: true,
				withGovernanceSelector: true,
				withOwnerSelector: true,
				withTagSelector: true,
				withVerifiedSelector: true,
				withRelatedResourceSelector: true,
			};
		case EntityType.question:
			return {
				withCollectionSelector: true,
				withOwnerSelector: true,
				withTagSelector: true,
				withRelatedResourceSelector: true,
			};
		default:
			return {};
	}
}
