import { Box, Card, Group, Stack, createStyles } from '@mantine/core';
import type { MantineTheme } from '@mantine/styles';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { Button } from '@repo/foundations';
import type { IEmbeddedPrompt } from '../../../api';
import { MessageBody } from '../../../components/AIAssistant/Messages/MessageBody';
import { AIAvatar } from '../../../components/AIAvatar';
import { RichEditor } from '../../../components/RichEditor';
import { MessageAIError } from '../../../components/AIAssistant/Messages/MessageAIError';
import { MessageAILoading } from '../../../components/AIAssistant/Messages/MessageAILoading';

const useStyles = createStyles((theme: MantineTheme) => ({
	card: {
		backgroundColor: theme.colors.gray[0],
		borderColor: theme.colors.gray[2],
	},
	richEditorContainer: {
		'.markdown-body': {
			color: theme.other.getColor('text/primary/default'),
			fontSize: theme.fontSizes.md,
			fontWeight: theme.other.typography.weight.bold,
			lineHeight: theme.other.typography.lineHeight.text.md,
		},
	},
}));

interface AISearchResultProps {
	isLoading: boolean;
	result: IEmbeddedPrompt | null;
	onNavigate: () => void;
}

function AISearchResult({
	isLoading,
	result,
	onNavigate,
}: AISearchResultProps) {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const onShowMore = () => {
		if (result) {
			navigate(`/ai/${result.id}/`);
			onNavigate();
		}
	};

	if (!result?.response && !isLoading) {
		return null;
	}

	const content = result?.response?.content;
	const shouldDisplayLoading =
		isLoading ||
		((result?.status === 'running' || result?.status === 'pending') &&
			!content);
	const shouldDisplayFailure = result?.status === 'failed';

	return (
		<Card className={classes.card} shadow="lg" withBorder>
			<Stack spacing={12}>
				<MessageBody
					avatar={
						<AIAvatar
							size="md"
							speed={shouldDisplayLoading ? 'slow' : 'none'}
						/>
					}
					author="Secoda AI"
				>
					{shouldDisplayLoading && <MessageAILoading />}
					{shouldDisplayFailure && <MessageAIError />}
					{!shouldDisplayLoading && !shouldDisplayFailure && (
						<Box className={classes.richEditorContainer}>
							<RichEditor readOnly value={content ?? ''} />
						</Box>
					)}
				</MessageBody>
				<Group position="right">
					<Button onClick={onShowMore}>Continue conversation</Button>
				</Group>
			</Stack>
		</Card>
	);
}

export default observer(AISearchResult);
