/* eslint-disable no-plusplus */
import type MarkdownIt from 'markdown-it';
import type MarkdownToken from 'markdown-it/lib/token';

const CHART_REGEX = /:;:(.*):;:/;

function isChartBlock(token: MarkdownToken) {
	return token.content?.match(CHART_REGEX);
}

export default function chartBlocks(md: MarkdownIt) {
	md.core.ruler.after('inline', 'chartblocks', (state) => {
		const { Token } = state;
		const { tokens } = state;

		for (let i = tokens.length - 1; i > 0; i--) {
			const tokenChildren = tokens[i].children || [];
			const matches = tokenChildren.filter(isChartBlock);

			if (matches.length && matches.length > 0) {
				let token;

				const nodes: MarkdownToken[] = [];
				const { content } = matches[0];

				token = new Token('chart_block_open', '', 1);
				token.attrs = [['id', content]];
				nodes.push(token);

				const text = new Token('text', '', 0);
				text.content = '';
				nodes.push(text);

				token = new Token('chart_block_close', '', -1);
				nodes.push(token);
				tokens.splice(i - 1, 3, ...nodes);
			}
		}

		return false;
	});
}
