import type { DefaultProps } from '@mantine/core';
import { Box, createStyles, Divider, Menu, Stack, Text } from '@mantine/core';
import { keys, noop } from '@mantine/utils';
import type { Icon as tablerIcon } from '@tabler/icons-react';
import {
	every,
	filter,
	forEach,
	groupBy,
	includes,
	isEmpty,
	isNil,
	map,
	partition,
	size,
	sortBy,
	startCase,
	uniq,
} from 'lodash-es';
import type React from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { ButtonVariants, Icon } from '@repo/foundations';
import { EmptyState, type ButtonDetails } from '../EmptyState';
import type { SelectablePropertyType } from '../EntityPageLayout/EntityPropertySidebar/SelectableProperty/types';
import type { ItemIconType } from '../ItemIcon';
import type { SelectablePropertyItem } from '../SingleSelector/types';
import { singularize } from '../../utils/stringUtils';
import type { IMultiSelectorItemProps } from './MultiSelectorItem';
import MultiSelectorItem from './MultiSelectorItem';
import MultiSelectorTarget from './MultiSelectorTarget';
import SelectorSearch from './SelectorSearch';

interface IMultiSelectorProps extends DefaultProps {
	placeholder?: string | null;
	placeholderIcon?: tablerIcon;
	variant?: ButtonVariants;
	/**
	 * The initially selected values.
	 */
	initialSelected: string[] | boolean[];
	/**
	 * The type of selectable property.
	 */
	property: SelectablePropertyType;
	/**
	 * The inherited values (e.g., entity's teams inherited from integrations).
	 * These values are shown but not editable.
	 */
	inheritedValues?: string[];
	/**
	 * The tooltip text for inherited values.
	 */
	inheritedValuesTooltip?: string;
	/**
	 * The icon type for the property.
	 */
	iconType: ItemIconType;
	/**
	 * The options for selection.
	 */
	options: SelectablePropertyItem[];
	/**
	 * The permitted ID for selection.
	 */
	permittedId: string;
	/**
	 * Determines if the menu items should display a badge.
	 */
	isMenuItemBadge: boolean;
	/**
	 * Determines if the viewer is a user.
	 */
	isViewerUser: boolean;
	/**
	 * The function called when the selection changes.
	 */
	onChange?: (newValue: (string | boolean)[]) => void;
	/**
	 * Determines if the selector is read only.
	 */
	readOnly?: boolean;
	/**
	 * The component to render for each item.
	 */
	selectorItem?: React.FunctionComponent<IMultiSelectorItemProps>;
	/**
	 * The size of each item.
	 */
	itemSize?: number;
	/**
	 *  The number of items to render.
	 */
	itemsToRender?: number;
	hideOnEmpty?: boolean;
	/**
	 * The function called when the search term changes.
	 */
	onSearchTermChange?: (value: string) => void;
	emptyState?: React.ReactNode;
	onCreate?: (initTitle: string) => void;
}

const useStyles = createStyles((theme) => ({
	selectedStack: {
		maxHeight: 256,
		overflowY: 'auto',
	},
	createTagText: {
		color: theme.other.getColor('text/secondary/default'),
	},
}));

/**
 * Renders a multi-selector component.
 * @param placeholder - Placeholder text for the selector.
 * @param placeholderIcon - Icon for the placeholder.
 * @param variant - Variant of the selector.
 * @param initialSelected - Array of initially selected items.
 * @param inheritedValues - Array of inherited values.
 * @param inheritedValuesTooltip - Tooltip for inherited values.
 * @param property - Property of the selector.
 * @param iconType - Icon for the property.
 * @param options - Available options for selection.
 * @param permittedId - Permitted ID for selection.
 * @param isMenuItemBadge - Boolean indicating if the item is a menu item badge.
 * @param isViewerUser - Boolean indicating if the user is a viewer.
 * @param onChange - Callback function when the selection changes.
 * @param readOnly - Boolean indicating if the selector is read only.
 * @param selectorItem - Component to render for each item.
 * @param itemSize - Size of each item.
 * @param itemsToRender - Number of items to render.
 * @param onSearchTermChange - Callback function when the search term changes.
 * @param onCreate - Callback function when a new item is created.
 */
function MultiSelector({
	placeholder = 'Empty',
	placeholderIcon,
	variant,
	initialSelected,
	inheritedValues = [],
	inheritedValuesTooltip,
	property,
	iconType,
	options,
	permittedId,
	isMenuItemBadge,
	hideOnEmpty,
	isViewerUser,
	onChange,
	readOnly,
	selectorItem: SelectorItem = MultiSelectorItem,
	itemSize = 32,
	itemsToRender = 8,
	onSearchTermChange,
	emptyState,
	onCreate,
	w,
	className,
}: IMultiSelectorProps) {
	// State variables
	const [searchTerm, setSearchTerm] = useState('');
	const [selected, setSelected] = useState<SelectablePropertyItem[]>([]);
	const [notSelected, setNotSelected] = useState<SelectablePropertyItem[]>([]);
	const [hasGroups, setHasGroups] = useState<boolean>(false);
	const [groups, setGroups] = useState<string[]>([]);
	const [notSelectedGroups, setNotSelectedGroups] = useState<
		Record<string, SelectablePropertyItem[]>
	>({});

	const { classes, theme } = useStyles();

	// Effect to update the selected and not selected items based on the initialSelected and options
	useEffect(() => {
		const partitionedSelected = partition(sortBy(options, 'label'), (o) =>
			initialSelected.includes(o.value as never)
		);

		setSelected(partitionedSelected[0]);

		if (!isEmpty(options)) {
			// Show groups in the not selected list
			if ('group' in options[0]) {
				setHasGroups(true);
				setGroups(uniq(map(options, 'group')) as string[]);
				setNotSelectedGroups(groupBy(partitionedSelected[1], 'group'));
			} else {
				setNotSelected(partitionedSelected[1]);
			}
		}

		if (isEmpty(options)) {
			setNotSelected([]);
		}
	}, [initialSelected, options]);

	// Filter the selected items by excluding the inherited values
	const inheritSelected: SelectablePropertyItem[] = useMemo(
		() =>
			inheritedValues
				?.map(
					(v) => options.find((o) => o.value === v) as SelectablePropertyItem
				)
				.filter((v) => !isNil(v)),
		[inheritedValues, options]
	);

	// Memoized filtered selected items based on the search term and inherited values
	const filteredSelected = useMemo(() => {
		const items = filter(
			selected,
			(item) => !inheritSelected.find((v) => v.value === item.value)
		);

		if (isEmpty(searchTerm)) {
			return items;
		}

		return filter(items, (item) =>
			item.label.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [selected, searchTerm, inheritSelected]);

	// Memoized filtered not selected items based on the search term, inherited values, and user permissions
	const filteredNotSelected = useMemo(() => {
		const items = filter(
			filter(
				notSelected,
				(item) => !inheritSelected.find((v) => v.value === item.value)
			),
			(item) => !item.hidden
		);

		if (isViewerUser && size(permittedId) > 0) {
			return filter(items, (item) => item.value === permittedId);
		}

		if (isEmpty(searchTerm)) {
			return items;
		}

		return filter(
			filter(items, (item) =>
				item.label?.toLowerCase()?.includes(searchTerm.toLowerCase())
			)
		);
	}, [notSelected, isViewerUser, permittedId, searchTerm, inheritSelected]);

	// Memoized filtered not selected items grouped by groups based on the search term, inherited values, and user permissions
	const filteredNotSelectedGroups = useMemo(() => {
		const newNotSelectedGroups = { ...notSelectedGroups };

		forEach(groups, (group) => {
			newNotSelectedGroups[group] = filter(
				newNotSelectedGroups[group],
				(item) => !inheritSelected.find((v) => v.value === item.value)
			);
		});

		// Filter out the hidden items from both groups
		// Not sure if there is a workaround to make this more efficient/succinct
		groups.forEach((group) => {
			newNotSelectedGroups[group] =
				newNotSelectedGroups[group]?.filter((item) => !item.hidden) || [];
			notSelectedGroups[group] =
				notSelectedGroups[group]?.filter((item) => !item.hidden) || [];
		});

		if (isViewerUser && size(permittedId) > 0) {
			forEach(groups, (group) => {
				newNotSelectedGroups[group] = filter(
					newNotSelectedGroups[group],
					(item) => item.value === permittedId
				);
			});

			return newNotSelectedGroups;
		}

		if (isEmpty(searchTerm)) {
			return notSelectedGroups;
		}

		forEach(groups, (group) => {
			newNotSelectedGroups[group] = filter(
				newNotSelectedGroups[group],
				(item) => item.label.toLowerCase().includes(searchTerm.toLowerCase())
			);
		});

		return newNotSelectedGroups;
	}, [
		notSelectedGroups,
		groups,
		isViewerUser,
		permittedId,
		searchTerm,
		inheritSelected,
	]);

	// Check if there are selected and not selected items
	const hasSelectedAndNotSelected = useMemo(
		() =>
			!isEmpty(filteredSelected) &&
			!isEmpty(filteredNotSelected) &&
			(!isViewerUser || size(permittedId) > 0),
		[filteredSelected, filteredNotSelected, isViewerUser, permittedId]
	);

	// Check if there are no search results
	const hasNoResults = useMemo(() => {
		if (hasGroups) {
			return (
				isEmpty(options) ||
				(isEmpty(inheritedValues) &&
					isEmpty(filteredSelected) &&
					every(filteredNotSelectedGroups, (group) => isEmpty(group)))
			);
		}

		return (
			isEmpty(options) ||
			(isEmpty(inheritedValues) &&
				isEmpty(filteredSelected) &&
				isEmpty(filteredNotSelected))
		);
	}, [
		hasGroups,
		options,
		inheritedValues,
		filteredSelected,
		filteredNotSelected,
		filteredNotSelectedGroups,
	]);

	// Check if not selected items should be shown
	const showNotSelected = useMemo(
		() =>
			!isEmpty(filteredNotSelected) &&
			!hasGroups &&
			(!isViewerUser || size(permittedId) > 0),
		[filteredNotSelected, hasGroups, isViewerUser, permittedId]
	);

	const handleSearchTermChange = useCallback(
		(value: string) => {
			setSearchTerm(value);
			onSearchTermChange?.(value);
		},
		[onSearchTermChange]
	);

	// Handler to clear the search term
	const handleClearSearch = useCallback(() => {
		setSearchTerm('');
		onSearchTermChange?.('');
	}, [onSearchTermChange]);

	// Handler when an item is clicked
	const handleOnClick = useCallback(
		(item: SelectablePropertyItem) => {
			const newSelected = includes(map(selected, 'value'), item.value)
				? filter(selected, (s) => s.value !== item.value)
				: sortBy([...selected, item], 'label');
			setSelected(newSelected);

			// Handle groups
			if (hasGroups) {
				const newNotSelectedGroups = { ...notSelectedGroups };

				groups.forEach((group) => {
					const itemInNotSelectedGroup = includes(
						map(newNotSelectedGroups[group], 'value'),
						item.value
					);

					if (itemInNotSelectedGroup) {
						// Remove from group if it exists
						newNotSelectedGroups[group] = filter(
							newNotSelectedGroups[group],
							(s) => s.value !== item.value
						);
					} else if (group === item.group) {
						// Add to group if it doesn't exist and is in the same group
						newNotSelectedGroups[group] = newNotSelectedGroups[group]
							? sortBy([...newNotSelectedGroups[group], item], 'label')
							: [item];
					}
				});
				setNotSelectedGroups(newNotSelectedGroups);
			} else {
				const newNotSelected = includes(map(notSelected, 'value'), item.value)
					? filter(notSelected, (s) => s.value !== item.value)
					: sortBy([...notSelected, item], 'label');
				setNotSelected(newNotSelected);
			}

			if (onChange) {
				onChange(map(newSelected, 'value'));
			}

			// Handle onClick on item if it exists (e.g., for owners/groups)
			if (!isNil(item.onClick)) {
				if (hasGroups) {
					item.onClick(
						map(filter(newSelected, { group: item.group }), 'value')
					);
				} else {
					item.onClick(map(newSelected, 'value'));
				}
			}
		},
		[groups, hasGroups, notSelected, notSelectedGroups, onChange, selected]
	);

	const handleOnCreate = useCallback(() => {
		if (onCreate) {
			onCreate(searchTerm);
		}
	}, [onCreate, searchTerm]);

	const buttons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Clear search',
				action: handleClearSearch,
				isPrimary: false,
				size: 'sm',
			},
		],
		[handleClearSearch]
	);

	const height = useMemo(
		() =>
			size(filteredNotSelected) > itemsToRender
				? 256
				: size(filteredNotSelected) * itemSize,
		[filteredNotSelected, itemSize, itemsToRender]
	);

	const itemContent = useMemo(
		() =>
			// eslint-disable-next-line react/no-unstable-nested-components, func-names, react/display-name
			function (index: number, item: SelectablePropertyItem) {
				return (
					<SelectorItem
						// eslint-disable-next-line react/destructuring-assignment
						key={`${item.value}`}
						iconType={iconType}
						item={item}
						isMenuItemBadge={isMenuItemBadge}
						isViewerUser={isViewerUser}
						permittedId={permittedId}
						onClick={handleOnClick}
					/>
				);
			},
		[
			SelectorItem,
			handleOnClick,
			iconType,
			isMenuItemBadge,
			isViewerUser,
			permittedId,
		]
	);

	// If there is only one selected item and no permitted ID, render the target component instead
	if (
		readOnly ||
		(isViewerUser && size(selected) <= 1 && size(permittedId) === 0)
	) {
		return (
			<MultiSelectorTarget
				hideOnEmpty={hideOnEmpty}
				placeholder={placeholder}
				placeholderIcon={placeholderIcon}
				inheritSelected={inheritSelected}
				selected={selected}
				property={property}
				iconType={iconType}
				isMenuItemBadge={isMenuItemBadge}
				isViewerUser={isViewerUser}
				permittedId={permittedId}
				readOnly={readOnly || isNil(onChange)}
				w={w}
				className={className}
			/>
		);
	}

	return (
		<Menu
			keepMounted={false}
			width={300}
			position="bottom-start"
			closeOnItemClick={false}
			withinPortal
		>
			<Menu.Target>
				<MultiSelectorTarget
					hideOnEmpty={hideOnEmpty}
					placeholder={placeholder}
					placeholderIcon={placeholderIcon}
					variant={variant}
					inheritSelected={inheritSelected}
					selected={selected}
					property={property}
					iconType={iconType}
					isMenuItemBadge={isMenuItemBadge}
					isViewerUser={isViewerUser}
					permittedId={permittedId}
					readOnly={readOnly || isNil(onChange)}
					className={className}
					w={w}
				/>
			</Menu.Target>
			<Menu.Dropdown>
				<SelectorSearch
					searchTerm={searchTerm}
					setSearchTerm={handleSearchTermChange}
				/>
				{map(inheritSelected, (item) => (
					<SelectorItem
						key={`${item.label}-${item.value}`}
						disabled
						disabledTooltip={inheritedValuesTooltip}
						iconType={iconType}
						item={item}
						isMenuItemBadge={isMenuItemBadge}
						isViewerUser={isViewerUser}
						permittedId={permittedId}
						onClick={noop}
						checked
					/>
				))}
				{!isEmpty(filteredSelected) && (
					<Stack className={classes.selectedStack} spacing={0}>
						{map(filteredSelected, (item) => (
							<SelectorItem
								key={`${item.label}+${item.value}`}
								iconType={iconType}
								item={item}
								isMenuItemBadge={isMenuItemBadge}
								isViewerUser={isViewerUser}
								permittedId={permittedId}
								onClick={handleOnClick}
								checked
							/>
						))}
					</Stack>
				)}
				{hasSelectedAndNotSelected && <Divider my={theme.spacing['2xs']} />}
				{showNotSelected && (
					<Virtuoso
						style={{
							height,
						}}
						data={filteredNotSelected}
						totalCount={size(filteredNotSelected)}
						itemContent={itemContent}
					/>
				)}
				{hasGroups &&
					(!isViewerUser || size(permittedId) > 0) &&
					map(
						keys(filteredNotSelectedGroups),
						(group) =>
							size(filteredNotSelectedGroups[group]) > 0 && (
								<Box key={group}>
									<Menu.Label>{startCase(group)}</Menu.Label>
									<Virtuoso
										style={{
											height:
												size(filteredNotSelectedGroups[group]) > itemsToRender
													? 256
													: size(filteredNotSelectedGroups[group]) * itemSize,
										}}
										data={filteredNotSelectedGroups[group]}
										totalCount={size(filteredNotSelectedGroups[group])}
										itemContent={itemContent}
									/>
								</Box>
							)
					)}
				{hasNoResults &&
					(emptyState || (
						<EmptyState
							iconName="search"
							title="No results found"
							description="No resources or invalid search"
							buttons={buttons}
							includeGoBack={false}
							size="sm"
						/>
					))}
				{onCreate && (
					<>
						<Menu.Divider my={theme.spacing['2xs']} />
						<Menu.Item
							icon={<Icon name="plus" />}
							closeMenuOnClick
							onClick={handleOnCreate}
						>
							Create new {singularize(property)}
							{searchTerm && (
								<Text
									size="sm"
									className={classes.createTagText}
									component="span"
								>
									: "{searchTerm}"
								</Text>
							)}
						</Menu.Item>
					</>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}

export default memo(MultiSelector);
