/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ActionIcon, Group, Portal, ScrollArea, Stack } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { useClipboard } from '@mantine/hooks';
import { Icon } from '@repo/foundations';
import { DefaultDevDropdown } from './DefaultDevDropdown';
import type { DevPanelItemProps } from './DevPanel';

const SOURCE_MAP_ANNOTATION_PREFIX = 'sourceMappingURL=';

interface Source {
	line: string;
	path?: string;
	lineNum?: string;
	columnNum?: string;
}

class ReactDebugger {
	isError: boolean = false;

	element: HTMLElement | null = null;

	hook: any;

	renderer: any;

	fiberNode: any;

	stack: string[] = [];

	sources?: Source[] = undefined;

	fiberNodeID: number = 0;

	displayName: string = '';

	constructor() {
		try {
			this.hook = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__ as unknown;
			this.renderer = this.hook.rendererInterfaces.values().toArray()[0];
		} catch {
			this.isError = true;
		}
	}

	setElement(element: HTMLElement) {
		if (!element.isEqualNode(this.element)) {
			this.element = element;
			this.load();
		}
	}

	load() {
		if (this.renderer) {
			this.fiberNode = this.renderer.getFiberForNative(this.element);
			this.fiberNodeID = this.renderer.getFiberIDForNative(this.element, true);
			this.displayName = this.renderer.getDisplayNameForFiberID(
				this.fiberNodeID
			);
			this.loadSource();
		}
	}

	private loadSource() {
		const stackRaw: string =
			this.renderer.getComponentStackForFiber(this.fiberNode) ?? '';
		this.stack = stackRaw.split('\n');
		this.sources =
			this.stack
				?.map((line: string) => line.trim())
				?.filter((line: string) => !line.startsWith('at http'))
				?.map((line: string) => {
					const locationInParenthesesMatch = line.match(/ (\(.+\)$)/);

					if (locationInParenthesesMatch) {
						const locationUrl = locationInParenthesesMatch[1];
						const withoutParentheses = locationUrl
							.replace(/^\(+/, '')
							.replace(/\)+$/, '');
						const locationParts =
							/(at )?(.+?)\?.+?(?::(\d+))?(?::(\d+))?$/.exec(
								withoutParentheses
							);

						return {
							line,
							path: locationParts?.[2] ?? '',
							lineNum: locationParts?.[3] ?? '',
							columnNum: locationParts?.[4] ?? '',
						};
					}

					return { line };
				})
				?.filter((source) => !!source.path) ?? [];
	}
}

async function getSystemFilePath(path: string): Promise<string> {
	const response = await fetch(path);
	const text = await response.text();
	const lines = text.split(/[\r\n]+/);

	const sourceMapLine = lines.find((line) => {
		if (
			!line ||
			!line.startsWith('//#') ||
			!line.includes(SOURCE_MAP_ANNOTATION_PREFIX)
		) {
			return false;
		}

		return true;
	});

	if (!sourceMapLine) {
		return Promise.reject(new Error('No source map found'));
	}

	const sourceMapAnnotationStartIndex = sourceMapLine.indexOf(
		SOURCE_MAP_ANNOTATION_PREFIX
	);
	const sourceMapURL = sourceMapLine.slice(
		sourceMapAnnotationStartIndex + SOURCE_MAP_ANNOTATION_PREFIX.length,
		sourceMapLine.length
	);

	const parsedSourceMap = await (await fetch(sourceMapURL)).json();

	return parsedSourceMap.file;
}

function isLocalFile(source: Source) {
	return (
		source.line.indexOf(':') !== -1 &&
		source.line.indexOf('/node_modules/') === -1
	);
}

async function openFileInVSCode(source: Source) {
	if (!source.path) {
		return;
	}

	const systemPath = await getSystemFilePath(source.path);
	window.open(`vscode://file/${systemPath}:${source.lineNum}`, '_blank');
}

export function ReactComponentPicker({ close }: DevPanelItemProps) {
	const [isPicking, setIsPicking] = useState(false);
	const [element, setElement] = useState<HTMLElement | null>(null);
	const [debuggerState] = useState(new ReactDebugger());
	const { copy } = useClipboard();

	const handleCopyFilePathToClipboard = useCallback(
		async (source: Source) => {
			if (!source.path) {
				return;
			}

			const systemPath = await getSystemFilePath(source.path);
			copy(`${systemPath}:${source.lineNum}`);
		},
		[copy]
	);

	const handlePointerMove = useCallback(
		(event: MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			debuggerState.setElement(event.target as HTMLElement);
			setElement(debuggerState.element);
		},
		[debuggerState]
	);

	const handlePointerClick = useCallback(
		(event: MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			setIsPicking(false);
		},
		[setIsPicking]
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (isPicking && (event.key === 'Escape' || event.key === 'i')) {
				setIsPicking(false);
			}

			if (!isPicking && event.key === 'i') {
				setIsPicking(true);
			}
		},
		[setIsPicking, isPicking]
	);

	useEffect(() => {
		if (isPicking) {
			window.addEventListener('pointermove', handlePointerMove);
		} else {
			window.removeEventListener('pointermove', handlePointerMove);
		}

		window.addEventListener('click', handlePointerClick);
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('pointermove', handlePointerMove);
			window.removeEventListener('click', handlePointerClick);
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown, handlePointerClick, handlePointerMove, isPicking]);

	return (
		<DefaultDevDropdown label="React Debugger" close={close}>
			<Stack spacing={0}>
				{!isPicking ? (
					<p>Press i to select an element to inspect</p>
				) : (
					<p>Press i or ESC to stop</p>
				)}
				{element && (
					<div>
						<Portal>
							<div
								style={{
									position: 'absolute',
									backgroundColor: 'rgba(0, 0, 0, 0.1)',
									color: 'white',
									top: element.getBoundingClientRect().top,
									left: element.getBoundingClientRect().left,
									width: element.getBoundingClientRect().width,
									height: element.getBoundingClientRect().height,
									pointerEvents: 'none',
								}}
							>
								&nbsp;
							</div>
						</Portal>
						<p>
							<strong>Component:</strong> {debuggerState.displayName}
							{debuggerState.fiberNode?._debugSource?.fileName && (
								<>
									&nbsp;(
									<a
										href="#"
										onClick={() =>
											window.open(
												`vscode://file/${debuggerState.fiberNode._debugSource.fileName}:${debuggerState.fiberNode._debugSource.lineNumber}`,
												'_blank'
											)
										}
									>
										open source
									</a>
									)
								</>
							)}
						</p>
						<p>
							<strong>Source Path:</strong>
						</p>
						<ScrollArea h={400}>
							<Stack spacing="xs">
								{debuggerState.sources?.map((source, idx) => (
									<Group key={idx} spacing="xs">
										{isLocalFile(source) ? (
											<>
												<ActionIcon
													onClick={() => handleCopyFilePathToClipboard(source)}
												>
													<Icon name="clipboard" />
												</ActionIcon>
												<a href="#" onClick={() => openFileInVSCode(source)}>
													{source.line}
												</a>
											</>
										) : (
											<span>{source.line}</span>
										)}
									</Group>
								))}
							</Stack>
						</ScrollArea>
					</div>
				)}
			</Stack>
		</DefaultDevDropdown>
	);
}
