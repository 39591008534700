import { IconButton } from '@repo/foundations';
import JSZip from 'jszip';
import { useCreateCsvs } from '../../../../api/codegen/apiComponents';
import { saveBlob } from '../../../../lib/models/misc';
import { appShellStore } from '../../../../components/SecodaAppShell/store';

export function ExportReportCSVButton() {
	const { analyticsPageReportId: id } = appShellStore;

	const { isFetching, refetch } = useCreateCsvs(
		{
			pathParams: { reportId: id },
		},
		{ enabled: false }
	);

	const handleExport = async () => {
		const { data: csvData, error } = await refetch();
		if (csvData && !error) {
			const zip = new JSZip();

			for (const [filename, content] of Object.entries(csvData)) {
				zip.file(`${filename}.csv`, content);
			}

			const zipBlob = await zip.generateAsync({ type: 'blob' });
			saveBlob(zipBlob, `report_${id}.zip`);
		}
	};

	return (
		<IconButton
			onClick={handleExport}
			iconName="download"
			variant="tertiary"
			tooltip="Export widgets to CSVs"
			loading={isFetching}
		>
			Export widgets to CSVs
		</IconButton>
	);
}
