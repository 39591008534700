import type { MantineSize, TextInputProps } from '@mantine/core';
import {
	Box,
	Checkbox,
	CloseButton,
	createStyles,
	MultiSelect,
	NumberInput,
	Select,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useDebounceFn } from 'ahooks';
import dayjs from 'dayjs';
import type React from 'react';
import { useState } from 'react';
import { Icon } from '@repo/foundations';
import type { SearchResult } from '../../api';
import { OwnerSelector } from '../EntityModal/Metadata/OwnerSelector';
import { TagSelector } from '../EntityModal/Metadata/TagSelector';
import MultiResourceSelector from '../MultiResourceSelector';
import type { CustomProperty } from './CustomProperty.types';
import TextPropertyEditor from './TextPropertyEditor';

interface PropertyEditorProps {
	readOnly: boolean;
	property: CustomProperty;
	size?: MantineSize;
	variant?: TextInputProps['variant'];
	handleChange: (
		value: 'name' | 'value' | 'type'
		// eslint-disable-next-line no-shadow
	) => (value: string) => void | undefined;
	isViewerUser: boolean;
}

const useStyles = createStyles((theme) => ({
	withNegativeMargin: {
		marginLeft: `calc(-1 * ${theme.spacing.xs})`,
	},
}));

export function PropertyEditor({
	readOnly,
	property,
	size = 'xs',
	variant = 'unstyled',
	handleChange,
	isViewerUser,
}: PropertyEditorProps) {
	const { classes } = useStyles();

	const propertyValue = (() => {
		let finalValue;

		try {
			finalValue = JSON.parse(property.value || '');
		} catch (e) {
			finalValue = [property.value || ''];
		}

		if (Array.isArray(finalValue)) {
			return finalValue;
		}
		return [finalValue];
	})();

	const [data, setData] = useState([
		...new Set([...(property.options || []), ...propertyValue]),
	]);

	const [owners, setOwners] = useState<string[]>([]);
	const [groups] = useState<string[]>([]);

	const { run: syncOwnersAndGroups } = useDebounceFn(
		() => {
			handleChange('value')([...owners, ...groups].join(','));
		},
		{ wait: 20 }
	);

	const handleUserOwnerChange = (users: string | string[]) => {
		if (Array.isArray(users)) {
			setOwners(users);
		} else {
			setOwners(users.split(','));
		}
		syncOwnersAndGroups();
	};

	const handleTagsChange = (tags: string | string[]) => {
		if (Array.isArray(tags)) {
			handleChange('value')(tags.join(','));
		} else {
			handleChange('value')(tags);
		}
	};

	const handleDateChange = (date: Date) => {
		if (date) {
			handleChange('value')(date.toISOString());
		}
	};

	const handleSelectChange = (values: string) => {
		handleChange('value')(values || '');
	};

	const handleSelectCreate = (query: string) => {
		setData((current) => [...current, query]);
		return query;
	};

	const handleMultiSelectChange = (items: string[]) => {
		handleChange('value')(JSON.stringify(items));
	};

	const handleMultiSelectCreate = (query: string) => {
		setData((data || []).concat(query));
		return query;
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!readOnly) {
			handleChange('value')(e.target.checked ? 'true' : 'false');
		}
	};

	const handleTextChange = (value: string) => {
		handleChange('value')(value);
	};

	const handleNumberChange = (value: number) => {
		handleChange('value')(value.toString());
	};

	const handleEntitySelect = (entity: SearchResult) => {
		handleChange('value')(entity.id);
	};

	switch ((property.type || 'text').toLowerCase()) {
		case 'user':
			return (
				<Box className={classes.withNegativeMargin}>
					<OwnerSelector
						readOnly={readOnly}
						initialValue={property.value.split(',')}
						onChangeUserOwners={handleUserOwnerChange}
						placeholder={readOnly ? 'No user' : 'Add user'}
						forceVariant="tertiary"
					/>
				</Box>
			);
		case 'tag':
			return (
				<Box className={classes.withNegativeMargin}>
					<TagSelector
						readOnly={readOnly}
						initialValue={property.value.split(',')}
						onChange={handleTagsChange}
						placeholder="Empty"
						forceVariant="tertiary"
					/>
				</Box>
			);
		case 'date':
			return (
				<DatePickerInput
					readOnly={readOnly}
					variant={variant}
					size={size}
					type="default"
					placeholder="Pick a date"
					value={
						dayjs(property.value).isValid()
							? new Date(property.value)
							: new Date()
					}
					onChange={handleDateChange}
				/>
			);
		case 'select':
			return (
				<Select
					readOnly={readOnly}
					data={(data || []).filter(Boolean)}
					size={size}
					variant={variant}
					placeholder="Select item"
					searchable
					creatable
					clearable
					getCreateLabel={(query) => `+ Create ${query}`}
					onChange={handleSelectChange}
					value={property.value || ''}
					onCreate={handleSelectCreate}
					rightSection={
						property.value ? (
							<CloseButton
								variant="transparent"
								size="sm"
								onClick={() => handleSelectChange('')}
							/>
						) : (
							<Icon name="chevronDown" />
						)
					}
					styles={{
						rightSection: {
							pointerEvents: property.value ? undefined : 'none',
						},
					}}
					px="xs"
				/>
			);
		case 'multiselect':
			return (
				<MultiSelect
					readOnly={readOnly}
					data={data || []}
					placeholder="Select items"
					searchable
					creatable
					variant={variant}
					size={size}
					getCreateLabel={(query) => `+ Create ${query}`}
					onChange={handleMultiSelectChange}
					value={
						(property.value || '').length > 0
							? (() => {
									try {
										return JSON.parse(property.value || '');
									} catch (e) {
										return [];
									}
								})()
							: []
					}
					onCreate={handleMultiSelectCreate}
					rightSectionWidth={0}
					rightSection={<Icon name="chevronDown" />}
					px="xs"
					styles={{ rightSection: { pointerEvents: 'none' } }}
				/>
			);
		case 'checkbox':
			// Checkbox can't implement readOnly prop, need to handle it it the handler
			return (
				<Checkbox
					checked={property.value === 'true'}
					onChange={handleCheckboxChange}
				/>
			);
		case 'resource':
			return (
				<Box className={classes.withNegativeMargin}>
					<MultiResourceSelector
						excludedIds={[]}
						readOnly={readOnly}
						selected={property.value}
						onItemSelect={handleEntitySelect}
						isViewerUser={isViewerUser}
					/>
				</Box>
			);
		case 'number':
			return (
				<NumberInput
					readOnly={readOnly}
					variant={variant}
					size={size}
					value={Number(property.value)}
					onChange={handleNumberChange}
				/>
			);

		default:
			return (
				<TextPropertyEditor
					readOnly={readOnly}
					value={property.value}
					onChange={handleTextChange}
				/>
			);
	}
}
