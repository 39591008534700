import { ListBox, Text } from '@repo/foundations';
import { useMemo, useState } from 'react';
import { FilterOptionType, type FilterValue } from '../types';
import { AIFilterItem } from '../AddFilter/AIFilter/AIFilterItem';
import { FILTER_OPTIONS_CONFIG } from '../constants';
import { useFilterDropdownAI } from './useFilterDropdownAI';

interface FilterDropdownAIProps {
	initialSearchTerm: string;
	onChange: (value: FilterValue, close: boolean) => void;
}

export function FilterDropdownAI({
	initialSearchTerm,
	onChange,
}: FilterDropdownAIProps) {
	const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

	const { error, loading, askAi } = useFilterDropdownAI({
		onChange,
	});

	const items = useMemo(() => [searchTerm], [searchTerm]);

	return (
		<ListBox.ItemsDropdown
			search={{
				onChange: setSearchTerm,
				value: searchTerm,
				placeholder: 'Filter by...',
			}}
			items={items}
			renderItem={(item) => (
				<AIFilterItem
					onClick={() => askAi(item)}
					getProps={(p) => p}
					searchTerm={item}
					option={FILTER_OPTIONS_CONFIG[FilterOptionType.AI]}
				/>
			)}
		>
			{loading && (
				<Text size="sm" px="xs" py="2xs">
					Loading...
				</Text>
			)}
			{error && (
				<Text size="sm" px="xs" py="2xs">
					An error has ocurred
				</Text>
			)}
		</ListBox.ItemsDropdown>
	);
}
