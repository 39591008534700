import { lowerCase } from 'lodash-es';
import { forwardRef, useCallback } from 'react';
import type { Ref, MouseEvent, ReactNode } from 'react';
import { Icon, SplitButton } from '@repo/foundations';
import type { EntityType } from '../../lib/types';

import type { ITemplate } from '../../api';
import { entityModalStore } from '../EntityModal/store.tsx';
import { useCurrentTeamId } from '../../utils/hook/useCurrentTeamId.ts';
import { TemplatesButton } from './TemplatesButton';

interface TemplatesButtonGroupProps {
	type: EntityType;
	withForm?: boolean;
	withSetDefault?: boolean;
	handleNew: (ev: MouseEvent<HTMLButtonElement>) => void;
	leftButtonTooltip?: ReactNode;
}

function TemplatesButtonGroupInternal(
	{
		type,
		withForm,
		withSetDefault,
		handleNew,
		leftButtonTooltip,
	}: TemplatesButtonGroupProps,
	ref: Ref<HTMLButtonElement>
) {
	const teamId = useCurrentTeamId();
	const handleUseTemplate = useCallback(
		async (template: ITemplate) => {
			entityModalStore.setEntity('create', type, teamId, false);
			entityModalStore.setTemplate(template);
			entityModalStore.setOpened(true);
		},
		[teamId, type]
	);

	return (
		<SplitButton
			onClick={handleNew}
			variant="primary"
			label={`Create ${lowerCase(type)}`}
			leftButtonTooltip={leftButtonTooltip}
			ref={ref}
		>
			<TemplatesButton
				withSetDefault={withSetDefault}
				withForm={withForm}
				type={type}
				handleUseTemplate={handleUseTemplate}
			>
				<Icon name="chevronDown" />
			</TemplatesButton>
		</SplitButton>
	);
}

export const TemplatesButtonGroup = forwardRef(TemplatesButtonGroupInternal);
