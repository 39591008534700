import { Box, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Icon, Text } from '@repo/foundations';
import { useMembershipsAndBulkCreateMemberships } from '../../api/hooks/team/teamsManagement';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import type { User, UserGroup } from '../../lib/models';
import { getDisplayName } from '../../utils/userUtils';
import { CustomMultiSelect } from '../CustomMultiSelect/CustomMultiSelect';
import { IconWrapper } from '../IconWrapper';
import { UserAvatar } from '../UserAvatar';
import type { TeamOut } from '../../api/codegen/apiSchemas';
import { currentUser } from '../Documentation/Documentation.stories.constants';

type UserOrGroupItem = { value: string; group: string } & (
	| {
			group: 'Select a person';
			user: User;
	  }
	| {
			group: 'Select a group';
			userGroup: UserGroup;
	  }
);
const prepareData = (selectedValues: string[]) =>
	selectedValues.map((value) => {
		const [key, id] = value.split(':');
		if (key === 'user_id') {
			return {
				user_id: id,
			};
		}
		return {
			user_group_id: id,
		};
	});

export function InviteTeamMemberForm({
	team,
	onFinish,
	searchValue,
	setSearchValue,
	placeholder,
}: {
	team: TeamOut;
	onFinish?: (teamId: string) => void;
	searchValue?: string;
	setSearchValue?: (value: string) => void;
	placeholder: string;
}) {
	const [value, setValue] = useState<string[]>([]);
	const navigate = useNavigate();

	const { activeUsers: usersData, userGroups: groupsData } =
		useExtendedUserList({});

	const {
		memberships: existingMemberships,
		isBulkCreatingMemberships: isLoading,
		bulkCreateTeamMembership,
	} = useMembershipsAndBulkCreateMemberships(team.id);

	const data: UserOrGroupItem[] = useMemo(() => {
		const users =
			usersData?.filter(
				(user) =>
					!existingMemberships?.results?.find(
						(membership) => membership.user?.id === user.id
					) && user.id !== currentUser.id
			) ?? [];

		const userGroups =
			groupsData?.filter(
				(userGroup: { id: string | undefined }) =>
					!existingMemberships?.results.find(
						(membership) => membership.user_group?.id === userGroup.id
					)
			) ?? [];

		return [
			...(users
				.filter((u) => !u.disabled)
				.map((user) => ({
					label: getDisplayName(user),
					value: `user_id:${user.id}`,
					group: 'Select a person' as const,
					user,
				})) as unknown as UserOrGroupItem[]),
			...(userGroups.map((userGroup: { name: string; id: string }) => ({
				label: userGroup.name,
				value: `user_group_id:${userGroup.id}`,
				group: 'Select a group' as const,
				userGroup,
			})) as unknown as UserOrGroupItem[]),
		];
	}, [existingMemberships?.results, groupsData, usersData]);

	const filteredValue = value.filter((v) =>
		data.find((item) => item.value === v)
	);

	const invite = async () => {
		await bulkCreateTeamMembership({
			pathParams: {
				teamId: team.id,
			},
			body: prepareData(value),
		});

		showNotification({
			title: 'Members added',
			message: `Added members to ${team.name}`,
			color: 'green',
			icon: <Icon name="check" />,
		});

		onFinish?.(team.id);
	};

	const handleInvitePeopleClick = () => {
		navigate('/settings/members');
	};

	return (
		<Group noWrap>
			<Box
				sx={{
					flexGrow: 1,
					flexShrink: 1,
				}}
			>
				<CustomMultiSelect
					data={data}
					placeholder={placeholder}
					value={filteredValue}
					searchValue={searchValue}
					onSearchChange={setSearchValue}
					setValue={setValue}
					renderIcon={(item) =>
						item.group === 'Select a person' ? (
							<UserAvatar user={item.user} size="sm" />
						) : (
							<IconWrapper>
								<Text size="sm">{item.userGroup.icon}</Text>
							</IconWrapper>
						)
					}
					renderLabel={(item) =>
						item.group === 'Select a person'
							? getDisplayName(item.user)
							: item.userGroup.name
					}
					nothingFound={
						<Button
							w="100%"
							size="sm"
							variant="tertiary"
							leftIconName="send"
							onClick={handleInvitePeopleClick}
						>
							Invite people...
						</Button>
					}
				/>
			</Box>

			<Button
				size="md"
				variant="primary"
				disabled={filteredValue.length === 0}
				loading={isLoading}
				onClick={invite}
			>
				Add
			</Button>
		</Group>
	);
}
