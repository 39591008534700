import { Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import SelectReportTemplateMenu from '../../../pages/AnalyticsPage/components/Buttons/SelectTemplateMenu';
import {
	NavBarWrapper,
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
} from '.';

function AnalyticsListPageNavBar() {
	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Title order={1} size="sm" data-testid="analytics-page-nav-bar-title">
					Analytics
				</Title>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<SelectReportTemplateMenu />
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default observer(AnalyticsListPageNavBar);
