export const getEditorOptions = (
	lineNumbers: boolean,
	readOnly: boolean,
	alwaysConsumeMouseWheel = false
) => ({
	lineNumbers: (lineNumbers ? 'on' : 'off') as unknown as 'on' | 'off',
	automaticLayout: true,
	folding: false,
	lineNumbersMinChars: 3,
	glyphMargin: false,
	contextmenu: false,
	autoIndent: 'advanced' as const,
	// Allow editor pop up widgets such as context menus, signature help, hover tips to be able to be
	// displayed outside of the editor. Without this, the pop up widgets can be clipped.
	fixedOverflowWidgets: true,
	find: {
		addExtraSpaceOnTop: false,
		seedSearchStringFromSelection: 'always' as const,
		autoFindInSelection: 'never' as const, // Default is "never".
	},
	minimap: {
		enabled: false,
	},
	overviewRulerLanes: 0,
	readOnly,
	renderLineHighlight: 'none' as const,
	scrollbar: {
		useShadows: true,
		verticalHasArrows: true,
		horizontalHasArrows: true,
		verticalScrollbarSize: 8,
		horizontalScrollbarSize: 8,
		arrowSize: 30,
		alwaysConsumeMouseWheel,
	},
	scrollBeyondLastLine: false,
});
