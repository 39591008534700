import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';
import {
	DataEntityColumns,
	DefaultColumns,
	DescriptionColumns,
	SchemaDerivativeColumns,
} from './common';

export const SchemaColumns: ColumnDefinition = {
	title: {
		field: 'title',
		editable: false,
		sortable: true,

		checkboxSelection: true,

		type: ColumnType.textFilterColumn,
		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		minWidth: 250,
	},
	...SchemaDerivativeColumns,
	...DescriptionColumns,
	...DataEntityColumns,
	...DefaultColumns,
};
