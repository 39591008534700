import { Group } from '@mantine/core';
import React from 'react';
import { Text } from '@repo/foundations';
import { DataQualityProgressIcon } from '../DataQualityProgressIcon';
import { sentenceCase } from '../../../utils/stringUtils';
import { DATA_QUALITY_MAX_SCORE } from '../constants';
import { DataQualitySuggestion } from '../../../api/codegen/apiSchemas';
import { renderScore } from './DataQualityDetailsCard';

interface DataQualityDetailsGroupProps {
	score: number;
	scoreType: 'points' | 'percentage';
	dataQualityKey: DataQualitySuggestion['data_quality_top_key'];
}

export function DataQualityDetailsGroup({
	score,
	scoreType,
	dataQualityKey,
}: DataQualityDetailsGroupProps) {
	return (
		<Group position="apart" spacing={0}>
			<Group spacing="xs">
				<DataQualityProgressIcon
					score={(score / DATA_QUALITY_MAX_SCORE[dataQualityKey]) * 100}
				/>
				<Text size="sm" fw={500}>
					{sentenceCase(String(dataQualityKey))}
				</Text>
			</Group>
			<Text size="sm" color="text/secondary/default">
				{renderScore(scoreType, score, DATA_QUALITY_MAX_SCORE[dataQualityKey])}
			</Text>
		</Group>
	);
}
