import {
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import { withFaroRouterInstrumentation } from '@grafana/faro-react';

import { routes } from './routes';

const browserRouter = createBrowserRouter(createRoutesFromElements(routes));

export const router = withFaroRouterInstrumentation(browserRouter);
