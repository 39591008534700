import { isNil, startCase, toLower } from 'lodash-es';

/**
 * Truncates a string to a specified maximum length and appends an ellipsis if it exceeds that length.
 *
 * @param text The string to be truncated.
 * @param maxLength The maximum length of the string including the ellipsis.
 * @returns The truncated string with an ellipsis if its length exceeded the maximum length,
 *          otherwise the original string.
 */
export function truncateWithEllipsis(text: string, maxLength: number): string {
	// Check if the text length is greater than the maximum length
	if (text.length > maxLength) {
		// Truncate the text and add an ellipsis
		return `${text.substring(0, maxLength - 1)}…`;
	}

	// Return the original text if it's within the length limit
	return text;
}

/**
 * Singularizes a word based on common English pluralization patterns.
 * @param word - The word to singularize.
 * @returns The singularized form of the input word.
 */
export function singularize(word: string) {
	if (word.endsWith('ies')) {
		return `${word.slice(0, -3)}y`;
	}
	if (word.endsWith('es')) {
		return word.slice(0, -2);
	}
	if (word.endsWith('s')) {
		return word.slice(0, -1);
	}
	return word;
}

/**
 * Pluralizes a word based on common English pluralization patterns.
 * @param word - The word to pluralize.
 * @param count - The count associated with the word (optional).
 * @param inclusive - Whether to include the count in the result (optional).
 * @returns The pluralized form of the input word, optionally including the count.
 */
export function pluralize(word: string, count?: number, inclusive?: boolean) {
	let result = word;

	if (isNil(count) || count > 1) {
		if (word.toLowerCase().endsWith('s')) {
			result = word;
		} else if (word.toLowerCase().endsWith('y')) {
			result = `${word.slice(0, -1)}ies`;
		} else {
			result = `${word}s`;
		}
	}

	if (inclusive) {
		result = `${count} ${result}`;
	}

	return result;
}

/**
 * Checks if a string is null, undefined, or consists only of whitespace characters.
 * @param str - The string to check.
 * @returns `true` if the string is null, undefined, or whitespace; otherwise, `false`.
 */
export function isNullOrWhiteSpace(str: string | null | undefined) {
	return !str || str.trim().length === 0;
}

/**
 * Checks if a string represents a color, either in hexadecimal or RGB format.
 * @param str - The string to check.
 * @returns `true` if the string represents a color; otherwise, `false`.
 */
export function isColor(str: string) {
	return str.startsWith('#') || str.startsWith('rgb');
}

/**
 * Checks if a string represents a URL starting with "https".
 * @param str - The string to check.
 * @returns `true` if the string represents a URL starting with "https"; otherwise, `false`.
 */
export function isUrl(str: string) {
	return str.startsWith('https');
}

/**
 * Formats a list of strings or objects into a human-readable list.
 * e.g. ['a', 'b', 'c'] -> 'a, b, and c'
 * e.g. [{ name: 'a' }, { name: 'b' }, { name: 'c' }] -> 'a, b, and c'
 * e.g ['a'] -> 'a'
 *
 * @param arr The list to format.
 * @param type The type of conjunction to use.
 * @returns The formatted list as a string.
 */
export function formatListWithConjunction<T>(
	arr: T[],
	type: 'and' | 'or' = 'and'
) {
	const isStringArray = arr.every((item) => typeof item === 'string');
	const listType = type === 'and' ? 'conjunction' : 'disjunction';

	const formattedList = isStringArray
		? (arr as string[])
		: arr.map((item) => JSON.stringify(item));

	const formatter = new Intl.ListFormat('en', {
		style: 'long',
		type: listType,
	});
	return formatter.format(formattedList);
}

export function sentenceCase(str: string): string {
	return startCase(toLower(str));
}

export function formatIntegrationType(type: string): string {
	const integrationTypeMapping: Record<string, string> = {
		bigquery: 'BigQuery',
		confluent: 'Confluent',
		dbt: 'dbt',
		dbt_cloud: 'dbt Cloud',
		dbt_core: 'dbt Core',
		looker_studio: 'Looker Studio',
		mongodb: 'MongoDB',
		ms_teams: 'MS Teams',
		mssql: 'MS SQL',
		mysql: 'MySQL',
		pagerduty: 'PagerDuty',
		powerbi: 'PowerBI',
		quicksight: 'QuickSight',
		s3: 'S3',
		singlestore: 'SingleStore',
		ssrs: 'SSRS',
		thoughtspot: 'ThoughtSpot',
	};

	if (integrationTypeMapping.hasOwnProperty(type)) {
		return integrationTypeMapping[type];
	}
	return startCase(type);
}
