import { Spoiler, Box, Text, Code, Paper, Stack, Title } from '@mantine/core';
import { isNil } from 'lodash-es';
import { useAgentScratchpad } from '../../../../../api';
import { useFmtSql } from '../../../../../api/hooks/query/useFormatterQuery';

interface IAIBlockContainerProps {
	id: string;
}

function useFormattedTitle(title: string) {
	const fmtSql = useFmtSql(title.substring(8, title.length - 1));

	if (title.startsWith('run_sql')) {
		return fmtSql;
	}

	return title;
}

export function AIBlockContainer({ id }: IAIBlockContainerProps) {
	const { data: agentScratchPage } = useAgentScratchpad({
		id,
		options: {
			enabled: !isNil(id),
			suspense: false,
		},
	});

	const formattedTitle = useFormattedTitle(agentScratchPage?.title ?? '');

	return (
		<Box sx={{ position: 'relative', overflow: 'hidden' }}>
			<Spoiler
				maxHeight={240}
				showLabel="Show more"
				hideLabel="Hide"
				style={{ marginBottom: '1rem' }}
			>
				<Paper shadow="xs" p="xs" withBorder>
					<Stack spacing="xs">
						<Title order={5}>Function Call</Title>
						<Code block>{`${formattedTitle}`}</Code>
						<Title order={6}>Result</Title>
						<Text size="sm">
							<Code block>
								{agentScratchPage ? agentScratchPage.definition : 'Loading...'}
							</Code>
						</Text>
					</Stack>
				</Paper>
			</Spoiler>
			<Box
				sx={{
					position: 'absolute',
					bottom: '42px',
					left: 0,
					right: 0,
					height: '32px',
					background:
						'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
					pointerEvents: 'none', // Ensure the gradient doesn't interfere with interactions
					boxShadow: 'inset 0 -10px 8px -10px rgba(0,0,0,0.2)', // Adjust shadow to your liking
				}}
			/>
		</Box>
	);
}
