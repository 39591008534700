import produce from 'immer';
import { isNil } from 'lodash-es';
import type { IEmbeddedPrompt } from '../api';

/**
 * Replaces the domain in the response content of the given data object with the current window location host.
 * This workaround is used when a URL in AI response uses the wrong domain name,
 * particularly "app.secoda.co". ID in URL is generated from current user, thus
 * the incorrect domain in the link is swapped with the current domain of the user.
 *
 * @param data - The data object containing the response content to be updated.
 * @returns The updated data object with the replaced domain, or undefined if the data is undefined.
 */
export function replaceResponseDomain(data: IEmbeddedPrompt): IEmbeddedPrompt {
	return produce(data, (draft) => {
		if (isNil(draft.response)) {
			return;
		}

		const regex = /https?:\/\/[a-z]*\.secoda\.co/gi;
		draft.response.content = draft.response.content.replace(
			regex,
			`${window.location.protocol}//${window.location.host}`
		);
	});
}
