import { keys } from 'lodash-es';
import type { ISecodaEntity } from '../../../api';
import { EntityType } from '../../../lib/types';
import type { TreeNodeBase } from '../TreeNode';
import { fetchApiGetCollectionEntitiesCount } from '../../../api/codegen/apiComponents';
import { fetchCatalogList } from '../../../api/hooks/resourceCatalog';
import type { Filter } from '../../../api/codegen/apiSchemas';

export type NewCollectionTreeNode = TreeNodeBase &
	(
		| {
				type: 'entity_group';
				entityType: string;
				parentCollectionId: string;
		  }
		| {
				type: 'collection';
				entity: ISecodaEntity;
		  }
		| {
				type: 'child_entity';
				entity: ISecodaEntity;
		  }
	);

export const getCollectionNodeChildren = async (
	node: NewCollectionTreeNode,
	page: number = 1
): Promise<{ results: NewCollectionTreeNode[]; nextPage?: number | null }> => {
	if (node.type === 'collection') {
		const response = await fetchApiGetCollectionEntitiesCount({
			pathParams: {
				entityId: node.entity.id,
			},
		});

		const { results: directCollectionChildren } = await fetchCatalogList({
			filters: {
				filter: {
					operator: 'and',
					operands: [
						{
							field: 'entity_type',
							operator: 'exact',
							value: EntityType.collection,
						},
						{
							field: 'collections',
							operator: 'exact',
							value: node.entity.id,
						},
					],
				} as Filter,
				page_size: 100,
				sort: {
					field: 'title',
					order: 'asc',
				},
			},
			page,
		});

		return {
			results: [
				...directCollectionChildren.map((entity) => ({
					id: entity.id,
					entity,
					type: 'collection' as const,
					hasChildren: true,
				})),
				...keys(response)
					.filter((entityType) => entityType !== 'collection')
					.map((entityType) => ({
						id: `${node.id}-${entityType}`,
						type: 'entity_group' as const,
						entityType: entityType,
						hasChildren: true,
						parentCollectionId: node.entity.id,
					})),
			],
		};
	} else if (node.type === 'entity_group') {
		const { results, meta } = await fetchCatalogList({
			filters: {
				filter: {
					operator: 'and',
					operands: [
						{
							field: 'entity_type',
							operator: 'exact',
							value: node.entityType,
						},
						{
							field: 'collections',
							operator: 'exact',
							value: node.parentCollectionId,
						},
					],
				} as Filter,
				page_size: 100,
			},
			page,
		});

		return {
			results: results.map((entity) => ({
				id: entity.id,
				entity,
				type: 'child_entity' as const,
				hasChildren: false,
			})),
			nextPage: meta.next_page,
		};
	}

	return {
		results: [],
	};
};
