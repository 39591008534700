import { Badge, createStyles } from '@mantine/core';
import { primaryTextColor, primaryTextSize, primaryTextWeight } from '../theme';

interface IStyleParams {
	inline?: boolean;
}

export interface IBoolChipProps extends IStyleParams {
	active: boolean;
	activeText: string;
	activeIcon: JSX.Element;
	inactiveText?: string;
	inactiveIcon?: JSX.Element;
}

const useStyles = createStyles((theme, { inline }: IStyleParams) => ({
	badge: {
		backgroundColor: inline ? 'white' : theme.colors.gray[0],
		padding: inline ? 0 : '0 12px',
	},
	label: {
		fontSize: primaryTextSize,
		color: primaryTextColor,
		fontWeight: primaryTextWeight,
		textTransform: 'none',
	},
}));

function BoolChip({
	active,
	activeText,
	activeIcon,
	inactiveText,
	inactiveIcon,
	inline = false,
}: IBoolChipProps) {
	const { classes } = useStyles({ inline });
	let text: string | undefined = activeText;
	let icon: JSX.Element | undefined = activeIcon;

	// Show a draft badge.
	if (!active) {
		text = inactiveText;
		icon = inactiveIcon;
	}

	if (!text || !icon) {
		return null;
	}

	return (
		<Badge
			classNames={{
				root: classes.badge,
				inner: classes.label,
			}}
			leftSection={icon}
		>
			{text}
		</Badge>
	);
}

export default BoolChip;
