/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
import { Box, Group, Skeleton, Space, Stack, Tooltip } from '@mantine/core';
import { useDebouncedState, useHotkeys, useOs } from '@mantine/hooks';
import { spotlight } from '@mantine/spotlight';
import { Button, Icon } from '@repo/foundations';
import type {
	DataTableColumn,
	DataTableProps,
	DataTableSortStatus,
} from '@repo/mantine-datatable';
import { DataTable, useDataTableColumns } from '@repo/mantine-datatable';
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { useDebounceFn, useKeyPress } from 'ahooks';
import { isEmpty } from 'lib0/object';
import {
	filter,
	floor,
	isBoolean,
	isNil,
	map,
	noop,
	omit,
	omitBy,
	size,
	uniqBy,
} from 'lodash-es';
import { comparer, reaction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { unparse } from 'papaparse';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { IApiListResponse, IBaseModel, ISecodaEntity } from '../../api';
import { queryClient, useAuthUser } from '../../api';
import entityDrawerStore from '../EntityDrawer/store';

import type {
	FetchModelInfiniteListHook,
	FetchModelListHook,
} from '../../api/factories/types';
import { saveBlob } from '../../lib/models';
import { EntityType } from '../../lib/types';

import type { Filter as CatalogFilter } from '../../api/codegen/apiSchemas';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import ViewMenu from '../../pages/SearchPage/FilterCarousel/ViewMenu';
import { useFeatureFlags } from '../../utils/featureFlags';
import { pluralize } from '../../utils/stringUtils';
import CustomizeColumnsPanel from '../CatalogView/CustomizeColumnsPanel';
import { useColumnDefs } from '../CatalogView/hooks/useColumnDefs';
import { CatalogServerType } from '../CatalogView/types';
import {
	AddFilter,
	DEFAULT_FILTER_OPTIONS,
	Filter,
	FilterOptionType,
	SearchFilterV2Store,
	SearchFilterV2StoreContext,
	SortValue,
} from '../Filter';
import {
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
	FILTER_OPTIONS_CONFIG,
	FILTER_OPTIONS_DIVIDER,
	OPERATORS_CONFIG,
} from '../Filter/constants';
import SearchBox from '../SearchBox/SearchBox';
import { openSpotlight } from '../Spotlight';
import type { ICommandListItem } from '../Spotlight/components/CommandPalette/constants';
import { closeSpotlight } from '../Spotlight/events';
import { useFilterQueryString } from '../Filter/useFilterQueryString';
import { getParamsFromUrl } from '../../utils/url';
import {
	BOTTOM_PADDING,
	HEADER_HEIGHT,
	ROW_HEIGHT,
	rowSx,
	useTableStyles,
} from './TableV2.styles';
import { TableV2Dialog } from './TableV2Dialog';
import { TableV2Header } from './TableV2Header';
import { TableV2HeaderDots } from './TableV2HeaderDots';
import {
	DEFAULT_PAGINATION_SIZE,
	SKIP_RESTRICTED_FILTERS,
	STICKY_COLUMNS,
} from './constants';
import { SortStatusV2StoreContext } from './context';
import { makeRecordsExpandable } from './helpers';
import { useStyles } from './styles';
import { ExtendedDataTableColumn } from './types';

export interface ITableV2Props<T extends IBaseModel> {
	pluralTypeString?: string;
	onSelectedRecordsStateChange?: (selectedRecordsState: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}) => void;
	withInfiniteScroll?: boolean;
	withDialog?: boolean;
	tableCacheKey?: string;
	withDefaultSearchTerm?: string;
	height?: string;
	withHeader?: boolean;
	withStickyColumnBorder?: boolean;
	withInteractiveHeader?: boolean;
	withCsvExport?: boolean;
	withCheckbox?: boolean;
	withFilters?: boolean;
	withSavedViews?: boolean;
	defaultSort?: { field: string; order: 'asc' | 'desc' } | null;
	withSearch?: boolean;
	defaultRequiredOptions?: Record<string, unknown>;
	defaultRequiredSearchParams?: Record<string, unknown>;
	defaultRequiredSearchParamsNesting?: Record<string, string | boolean>;
	defaultRequiredCatalogFilters?: { operands: CatalogFilter[] };
	nestingFilter?: string;
	withActions?: ICommandListItem<T>[];
	withAdditionalButtons?: React.ReactNode | null;
	withQuickActions?: string[];
	columnVisibility?: {
		catalogType?: string;
		catalogServerType?: CatalogServerType;
		catalogEntityId?: string;
	};
	onCellClick?: (params: {
		event: MouseEvent;
		record: T;
		recordIndex: number;
		column: DataTableColumn<T>;
		columnIndex: number;
	}) => void;
	onRowClick?: (row: T, index: number, event: MouseEvent<unknown>) => void;
	onTotalRowCountChange?: (totalCount: number) => void;
	columns: DataTableColumn<T>[];
	usePaginationList: FetchModelListHook<T> | FetchModelInfiniteListHook<T>;
	additionalFilters?: string[];
	listFilterFunction?: (el: T) => boolean;
	usePaginationListOptions?: Record<string, unknown>;
}

function NestedTable<T extends IBaseModel>({
	id,
	columnsWithSortFilterWidth,
	usePaginationList,
	paramExpandedLevel,
	nestingFilter,
	defaultRequiredSearchParamsNesting,
}: {
	id: string;
	columnsWithSortFilterWidth: DataTableColumn<T>[];
	usePaginationList: FetchModelListHook<T> | FetchModelInfiniteListHook<T>;
	paramExpandedLevel: number;
	nestingFilter: string;
	defaultRequiredSearchParamsNesting?: Record<string, string | boolean>;
}) {
	const [expandedRecords, setExpandedRecords] = useState<T[]>([]);

	const { classes: tableClasses, theme } = useTableStyles({
		hideCheckbox: true,
		nested: true,
	});

	const paginationListData = usePaginationList({
		page: 1,
		filters: {
			calculate_children_count: true,
			...defaultRequiredSearchParamsNesting,
			filter: {
				operator: 'and',
				operands: [
					{ operands: [], field: nestingFilter, operator: 'in', value: [id] },
				],
			},
		},
	});

	const { data: paginationData, isFetching } = paginationListData;

	let listData = paginationData as IApiListResponse<T>;
	if ((paginationData && (paginationData as InfiniteData<T>))?.pages) {
		listData['results'] =
			(paginationData as InfiniteData<T> | undefined)?.pages ?? [];
	}

	if (isFetching && !listData) {
		// The render requires this to take up a certain amount of space, so we
		// cannot use `null`.
		return (
			<Box h={theme.spacing.xl}>
				<Space />
			</Box>
		);
	}

	return (
		<DataTableComponent
			rowSx={rowSx}
			nested
			noHeader
			withBorder={false}
			onPageChange={noop}
			records={makeRecordsExpandable(
				listData?.results ?? [],
				expandedRecords,
				paramExpandedLevel,
				setExpandedRecords
			)}
			columns={columnsWithSortFilterWidth}
			classNames={tableClasses}
			// Leave this `empty` since we want the padding of the checkboxes to
			// be consistent with the parent table, but we use css to hide them.
			selectedRecordsState={{ selectedRecords: [], lastSelectedIndex: null }}
			rowExpansion={{
				allowMultiple: true,
				expanded: {
					recordIds: expandedRecords.map((record) => record.id),
				},
				content: ({ record }) => (
					<NestedTable<T>
						id={record.id}
						key={record.id}
						nestingFilter={nestingFilter}
						defaultRequiredSearchParamsNesting={
							defaultRequiredSearchParamsNesting
						}
						paramExpandedLevel={paramExpandedLevel + 1}
						columnsWithSortFilterWidth={columnsWithSortFilterWidth}
						usePaginationList={usePaginationList}
					/>
				),
			}}
		/>
	);
}

const DataTableComponent = DataTable as <T>(
	props: Omit<DataTableProps<T>, 'page'> &
		Partial<{
			withStickyColumnBorder?: boolean;
			page: number & Partial<{ paginationSize: 'sm' | 'md' | 'lg' }>;
			nested: boolean;
			endReached?: () => void;
			nextPageFetching?: boolean;
			maxHeight?: number;
		}>
) => JSX.Element;

export function TableV2Loader() {
	const { theme } = useStyles();
	return (
		<Stack
			h="100%"
			w="100%"
			bg={theme.other.getColor('fill/primary/default')}
			py={theme.other.space[3]}
			px={theme.other.space[3]}
			spacing={theme.other.space[4]}
		>
			{new Array(floor((window.innerHeight / ROW_HEIGHT) * 1.2))
				.fill(0)
				.map((_, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<Skeleton radius="lg" key={i} h={theme.other.space[4]} />
				))}
		</Stack>
	);
}

function TableV2<T extends IBaseModel>({
	tableCacheKey,
	columns,
	pluralTypeString,
	onSelectedRecordsStateChange,
	onRowClick,
	onCellClick,
	onTotalRowCountChange,
	usePaginationList,
	withDefaultSearchTerm,
	withInfiniteScroll = false,
	withHeader = true,
	withStickyColumnBorder = true,
	withInteractiveHeader = true,
	withFilters = true,
	withDialog = true,
	withSavedViews = false,
	withCsvExport = false,
	withCheckbox: withCheckboxParam,
	withSearch = false,
	withAdditionalButtons = null,
	withActions = [],
	defaultSort = {
		field: SortValue.POPULARITY,
		order: 'desc',
	},
	withQuickActions = [
		'actions::pii',
		'actions::verified',
		'actions::sidebar',
		'actions::ai',
		'actions::delete',
	],
	defaultRequiredOptions = {},
	defaultRequiredSearchParams = {},
	defaultRequiredSearchParamsNesting,
	defaultRequiredCatalogFilters = { operands: [] },
	additionalFilters,
	columnVisibility,
	nestingFilter,
	height,
	listFilterFunction,
	usePaginationListOptions = {},
}: ITableV2Props<T>) {
	// The key for storing the sort and filter preferences in local storage.
	const pathKey = btoa(window.location.pathname);
	const storeColumnsKey = `${pathKey}${tableCacheKey ?? ''}${
		columnVisibility?.catalogEntityId
	}${columnVisibility?.catalogServerType}`;
	const sortPreferenceKey = `sort-v2-${storeColumnsKey}`;
	const filterPreferenceKey = `filters-v2-${storeColumnsKey}`;
	const searchPreferenceKey = `search-v2-${storeColumnsKey}`;

	const { isViewerOrGuestUser, isEditorOrAdminUser } = useAuthUser();
	const withCheckbox = withCheckboxParam && !isViewerOrGuestUser;

	const searchRef = useRef<HTMLInputElement>();
	const tableRef = useRef<HTMLTableSectionElement>(null);

	const distanceFromTop = tableRef?.current?.getBoundingClientRect()?.top ?? 0;

	const filtersQueryStringKey = 'filters';
	const hasFiltersInUrl =
		getParamsFromUrl()?.get(filtersQueryStringKey) !== null;

	const { classes } = useStyles();
	const { classes: tableClasses } = useTableStyles({
		hideCheckbox: false,
		stickyColumn: withCheckbox ? 2 : 1,
		withStickyColumnBorder,
	});

	const [expandedRecords, setExpandedRecords] = useState<T[]>([]);
	const [selectedRecordsState, setSelectedRecordsState] = useState<{
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}>({
		selectedRecords: [],
		lastSelectedIndex: null,
	});

	useEffect(() => {
		onSelectedRecordsStateChange?.(selectedRecordsState);
	}, [onSelectedRecordsStateChange, selectedRecordsState]);

	const [page, setPage] = useState(1);

	const [sortStatus, setSortStatus] = useState<DataTableSortStatus | undefined>(
		localStorage.getItem(sortPreferenceKey) && !hasFiltersInUrl
			? JSON.parse(localStorage.getItem(sortPreferenceKey) ?? '{}')
			: undefined
	);

	const defaultSearchTerm = useMemo(
		() =>
			withDefaultSearchTerm ??
			(localStorage.getItem(searchPreferenceKey) && !hasFiltersInUrl
				? localStorage.getItem(searchPreferenceKey) ?? ''
				: ''),
		[hasFiltersInUrl, searchPreferenceKey, withDefaultSearchTerm]
	);

	const [debouncedSearch, setDebouncedSearch] = useDebouncedState<string>(
		defaultSearchTerm,
		// Make negligible to avoid unnecessary re-renders.
		15
	);

	const handleSearch = useCallback(
		(value: string) => {
			localStorage.setItem(searchPreferenceKey, value);
			setDebouncedSearch(value);
		},
		[searchPreferenceKey, setDebouncedSearch]
	);

	const { dataQualityScore, aiFilters } = useFeatureFlags();
	const { enableAi } = useAiEnabled();

	const filtersUrlParamKey = 'filters';

	const searchFilterStoreV2 = useMemo(() => {
		let options = dataQualityScore
			? DEFAULT_FILTER_OPTIONS_WITH_DQS
			: DEFAULT_FILTER_OPTIONS;

		if (aiFilters && enableAi) {
			options = [FilterOptionType.AI, FILTER_OPTIONS_DIVIDER, ...options];
		}

		return new SearchFilterV2Store(
			options,
			undefined,
			// The preferences are stored in the local storage, with this key.
			filterPreferenceKey,
			filtersUrlParamKey
		);
	}, [aiFilters, dataQualityScore, enableAi, filterPreferenceKey]);

	useEffect(() => {
		searchFilterStoreV2.prefetchPromises();
	}, [searchFilterStoreV2]);

	// Reset pagination if filters change.
	useEffect(
		() =>
			reaction(
				() => searchFilterStoreV2.values,
				() => setPage(1),
				{
					equals: comparer.structural,
				}
			),
		[searchFilterStoreV2]
	);

	useFilterQueryString(searchFilterStoreV2);

	const { catalog: catalogProperties, onColumnVisibilityChange } =
		useColumnDefs<T>({
			defaultColumns: columns,
			catalogType: columnVisibility?.catalogType ?? EntityType.table,
			catalogServerType:
				columnVisibility?.catalogServerType ?? EntityType.table,
			entityId: columnVisibility?.catalogEntityId,
			suspense: true,
		});

	const handleSetSortStatus = useCallback(
		(status: DataTableSortStatus) => {
			setSortStatus((prev) => {
				if (
					prev?.columnAccessor === status.columnAccessor &&
					prev?.direction === status.direction
				) {
					localStorage.removeItem(sortPreferenceKey);
					return undefined;
				}

				localStorage.setItem(sortPreferenceKey, JSON.stringify(status));

				return status;
			});
		},
		[sortPreferenceKey]
	);

	const catalogFilter = useMemo(() => {
		// eslint-disable-next-line no-underscore-dangle
		const _catalogFilter = { ...searchFilterStoreV2.catalogFilter };
		if (defaultRequiredCatalogFilters && _catalogFilter) {
			if (isNil(_catalogFilter.operator)) {
				_catalogFilter.operator = 'and';
				_catalogFilter.operands = defaultRequiredCatalogFilters.operands;
			} else {
				_catalogFilter.operands = [
					...(searchFilterStoreV2.catalogFilter?.operands ?? []),
					...defaultRequiredCatalogFilters.operands,
				];
			}
		}
		return _catalogFilter;
	}, [defaultRequiredCatalogFilters, searchFilterStoreV2.catalogFilter]);

	const sort = useMemo(() => {
		// eslint-disable-next-line no-underscore-dangle
		let _sort = null;
		if (sortStatus) {
			_sort = {
				field: sortStatus.columnAccessor,
				order: sortStatus.direction,
			};
			// If there is a default sort, but no search term, use the default sort.
		} else if (defaultSort && !debouncedSearch) {
			_sort = defaultSort;
		} else if (defaultSort && debouncedSearch) {
			_sort = {
				field: SortValue.RELEVANCE,
				order: 'desc',
			};
		}
		return _sort;
	}, [debouncedSearch, defaultSort, sortStatus]);

	const paginationListData = usePaginationList(
		omitBy(
			{
				...defaultRequiredOptions,
				page,
				filters: !withSearch
					? { ...defaultRequiredSearchParams }
					: omitBy(
							{
								...defaultRequiredSearchParams,
								filter: catalogFilter,
								search_term: debouncedSearch,
								sort,
							},
							isNil
						),
				options: {
					onError: () => {
						// Delete all the filters and sort preferences if the query fails,
						// to avoid breaking this page for the end-user.
						localStorage.removeItem(sortPreferenceKey);
						localStorage.removeItem(filterPreferenceKey);
						localStorage.removeItem(searchPreferenceKey);
					},
					...usePaginationListOptions,
				},
			},
			isNil
		)
	);

	// This cannot be in `onSuccess`, because on navigating between pages,
	// the `onSuccess` is not called.
	useEffect(() => {
		const data = paginationListData?.data as { count: number } | undefined;
		if (!isNil(data?.count)) {
			onTotalRowCountChange?.(data?.count);
		}
	}, [onTotalRowCountChange, paginationListData?.data]);

	const { data: paginationData, isFetching } = paginationListData;

	let listData = paginationData as IApiListResponse<T>;
	if ((paginationData && (paginationData as InfiniteData<T>))?.pages) {
		listData['results'] =
			(paginationData as InfiniteData<T> | undefined)?.pages ?? [];
	}

	const handleEndReached = useCallback(() => {
		if (
			withInfiniteScroll &&
			(paginationListData as UseInfiniteQueryResult<T>).hasNextPage
		) {
			(paginationListData as UseInfiniteQueryResult<T>).fetchNextPage();
		}
	}, [paginationListData, withInfiniteScroll]);

	const results = listFilterFunction
		? listData?.results.filter(listFilterFunction)
		: listData?.results;

	const totalCount = listData?.count ?? 0;
	const totalCountWithNesting = expandedRecords.length + totalCount;

	const { run: onResizeColumn } = useDebounceFn(
		(columnName: string, newWidth: number) => {
			setColumnWidth(columnName, newWidth);
		},
		{ wait: 5 }
	);

	useEffect(() => {
		// List of selected records must be consistent with the list of records
		setSelectedRecordsState((currentState) => ({
			selectedRecords: currentState.selectedRecords.map(
				(r) => listData?.results.find((result) => result.id === r.id) ?? r
			),
			lastSelectedIndex: currentState.lastSelectedIndex,
		}));
	}, [listData?.results]);

	const mutateColumnVisibility = useCallback(
		async (columnName: string, isVisible: boolean) => {
			await onColumnVisibilityChange(columnName, isVisible);
			queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());
		},
		[onColumnVisibilityChange]
	);

	const columnsWithSort = useMemo(
		() =>
			columns
				.sort((a, b) => {
					if (STICKY_COLUMNS.includes(a.accessor)) {
						return -1;
					} else if (STICKY_COLUMNS.includes(b.accessor)) {
						return 1;
					} else {
						return (
							(catalogProperties?.properties?.find(
								(prop) =>
									prop.value === a.accessor ||
									prop.value === (a as ExtendedDataTableColumn<T>).esAccessor
							)?.order ?? 0) -
							(catalogProperties?.properties?.find(
								(prop) =>
									prop.value === b.accessor ||
									prop.value === (b as ExtendedDataTableColumn<T>).esAccessor
							)?.order ?? 0)
						);
					}
				})
				.map((column) => ({
					...column,
					defaultToggle: true,
					title: (
						<TableV2Header
							column={column}
							onColumnVisibilityChange={mutateColumnVisibility}
							onSort={
								withInteractiveHeader && column.sortable !== false
									? handleSetSortStatus
									: undefined
							}
							withFilters={
								withFilters &&
								withInteractiveHeader &&
								column.filtering !== false
							}
							onResizeColumn={onResizeColumn}
						/>
					),
				})),
		[
			catalogProperties?.properties,
			columns,
			handleSetSortStatus,
			mutateColumnVisibility,
			onResizeColumn,
			withFilters,
			withInteractiveHeader,
		]
	);

	const columnsWithSortFilter = useMemo(
		() =>
			columnsWithSort
				.filter(
					(column) =>
						// Filter out all columns that require the backend to explicitly define
						// them, to render. This is used mainly on the columns table where
						// distributions and custom properties may be added.
						!(
							(column as DataTableColumn<T> & { explicit: boolean }).explicit &&
							isNil(
								catalogProperties?.properties?.find(
									(prop) =>
										prop.value === column.accessor ||
										prop.value ===
											(column as ExtendedDataTableColumn<T>).esAccessor
								)
							)
						)
				)
				.filter(
					(column) =>
						!catalogProperties?.properties?.find(
							(prop) =>
								prop.value === column.accessor ||
								prop.value === (column as ExtendedDataTableColumn<T>).esAccessor
						)?.hidden || STICKY_COLUMNS.includes(column.accessor)
				),
		[catalogProperties?.properties, columnsWithSort]
	);

	const handleOpenActions = useCallback(() => {
		const selected = selectedRecordsState.selectedRecords.filter((record) =>
			results?.some((result) => result.id === record.id)
		) as unknown as IBaseModel[];

		const actions = withActions.map((action) => ({
			...action,
			selected,
			show: isBoolean(action.show) ? action.show : action.show(selected as T[]),
			onClick: async () => {
				await action?.onClick?.(selected as T[], () =>
					setSelectedRecordsState({
						selectedRecords: [],
						lastSelectedIndex: null,
					})
				);
			},
		}));

		openSpotlight({
			type: 'bulkActions',
			props: {
				actions: filter(actions, (action) => action.show),
			},
		});
	}, [results, selectedRecordsState, withActions]);

	const handleCloseDialog = useCallback(() => {
		closeSpotlight('bulkActions');
		setSelectedRecordsState({ selectedRecords: [], lastSelectedIndex: null });
		spotlight.close();
	}, []);

	useKeyPress(['Escape'], () => {
		spotlight.close();
	});

	useKeyPress(['meta.k'], () => {
		if (isViewerOrGuestUser) {
			return;
		}
		if (selectedRecordsState.selectedRecords.length > 0) {
			handleOpenActions();
		}
	});

	useKeyPress(['meta.a'], (e) => {
		if (isViewerOrGuestUser) {
			return;
		}

		// Only select all if we are hovering over the table.
		if (isNil(getHoveredId())) {
			return;
		}

		e.preventDefault();
		if (selectedRecordsState.selectedRecords.length === results?.length) {
			setSelectedRecordsState({ selectedRecords: [], lastSelectedIndex: null });
		} else {
			setSelectedRecordsState((currentState) => ({
				selectedRecords: results ?? [],
				lastSelectedIndex: currentState.lastSelectedIndex,
			}));
		}
	});

	const getHoveredId = useCallback(() => {
		const hoverElements = Array.from(document.querySelectorAll(':hover'));
		// Get the first `tr` element.
		const selectedRow = hoverElements.find(
			(el) => el.tagName?.toUpperCase() === 'TR'
		);
		const entityId = selectedRow?.getAttribute('entity-id');
		return entityId;
	}, []);

	useKeyPress(['meta.s'], (e) => {
		const hoveredId = getHoveredId();
		const hoveredEntity = results?.find((entity) => entity.id === hoveredId);
		if (hoveredEntity || selectedRecordsState.selectedRecords.length === 1) {
			const entity = hoveredEntity ?? selectedRecordsState.selectedRecords[0];
			e.preventDefault();
			entityDrawerStore.openEntityDrawerById(
				isEditorOrAdminUser,
				entity.id,
				(entity as unknown as ISecodaEntity).entity_type,
				resourceCatalogQueryKeyFactory.allLists()
			);
		}
	});

	useHotkeys([
		[
			'x',
			(e) => {
				if (isViewerOrGuestUser) {
					return;
				}

				const entityId = getHoveredId();
				if (entityId) {
					// Only the key press from being propagated to the rest of the app, if
					// we are hovering over a row.
					e.preventDefault();

					const found = results?.find((entity) => entity.id === entityId);
					if (found) {
						if (
							selectedRecordsState.selectedRecords.some(
								(record) => record.id === entityId
							)
						) {
							setSelectedRecordsState((currentState) => ({
								selectedRecords: currentState.selectedRecords.filter(
									(record) => record.id !== entityId
								),
								lastSelectedIndex: currentState.lastSelectedIndex,
							}));
						} else {
							setSelectedRecordsState((currentState) => ({
								selectedRecords: uniqBy(
									[...currentState.selectedRecords, found],
									'id'
								),
								lastSelectedIndex: currentState.lastSelectedIndex,
							}));
						}
					}
				}
			},
		],
	]);

	const {
		effectiveColumns: columnsWithSortFilterWidth,
		setColumnWidth,
		refreshColumns,
	} = useDataTableColumns({
		key: storeColumnsKey,
		columns: columnsWithSortFilter,
	});

	const handleCsvExport = useCallback(() => {
		const csv = unparse(
			// Remove some fields that are exported as `[object Object]` in the CSV.
			map(results, (el) =>
				omit(el, [
					'display_metadata',
					'properties',
					'search_metadata',
					'credentials',
					'last_error_message',
					'schedule',
				])
			)
		);
		saveBlob(new Blob([csv], { type: 'text/csv' }), `${page}.csv`);
	}, [results, page]);

	const activeActions = useMemo(
		() =>
			withActions.filter(
				(action) =>
					withQuickActions.includes(action.id) &&
					(typeof action.show === 'function'
						? action.show(selectedRecordsState.selectedRecords)
						: action.show)
			),
		[selectedRecordsState.selectedRecords, withActions, withQuickActions]
	);

	const records = useMemo(
		() =>
			nestingFilter
				? makeRecordsExpandable(
						results ?? [],
						expandedRecords,
						// The `root` level of nesting is 0.
						0,
						setExpandedRecords
					)
				: results ?? [],
		[
			expandedRecords,
			results,
			// We want this to re-run when the listData changes with an optimistic
			// update, so we set JSON.stringify as a dependency.
			// eslint-disable-next-line react-hooks/exhaustive-deps
			JSON.stringify(results),
			nestingFilter,
		]
	);

	const rowExpansion = useMemo(
		() =>
			nestingFilter
				? {
						allowMultiple: true,
						expanded: {
							recordIds: expandedRecords.map((record) => record.id),
						},
						// eslint-disable-next-line react/no-unused-prop-types
						content: ({ record }: { record: T }) => (
							<NestedTable
								key={record.id}
								id={record.id}
								nestingFilter={nestingFilter}
								columnsWithSortFilterWidth={columnsWithSortFilterWidth}
								usePaginationList={usePaginationList}
								// The first level of nesting is 1.
								paramExpandedLevel={1}
								defaultRequiredSearchParamsNesting={
									defaultRequiredSearchParamsNesting
								}
							/>
						),
					}
				: undefined,
		[
			defaultRequiredSearchParamsNesting,
			columnsWithSortFilterWidth,
			expandedRecords,
			nestingFilter,
			usePaginationList,
		]
	);

	const restrictFilters = useMemo(
		() =>
			columns.reduce(
				(
					prv: string[],
					cur: DataTableColumn<T> & {
						esAccessor?: string;
						filterOptionType?: FilterOptionType;
					}
				) => {
					if (cur.accessor) {
						prv.push(cur.accessor);
					}
					if (cur.esAccessor) {
						prv.push(cur.esAccessor);
					}
					if (cur.filterOptionType) {
						prv.push(cur.filterOptionType);
					}
					return prv;
				},
				[...(additionalFilters ?? [])]
			),
		[additionalFilters, columns]
	);

	const noRecordsIcon = useMemo(() => <Icon size="lg" name="search" />, []);

	let tableMaxHeight =
		totalCountWithNesting > 0
			? (withHeader ? HEADER_HEIGHT : 0) +
				Math.min(DEFAULT_PAGINATION_SIZE, totalCountWithNesting) * ROW_HEIGHT +
				// Account for the border widths of each row.
				Math.min(DEFAULT_PAGINATION_SIZE, totalCountWithNesting) +
				1
			: undefined;

	const os = useOs();
	if (!!tableMaxHeight && os === 'windows') {
		// We need to add a 20px gutter to the max-height on Windows to account for the scrollbars taking over the element's space
		// in this issue we see that the horizontal scrollbar is forcing the vertical scrollbar to appear, which is causing the scrollbar to hide part of the elements
		// on Mac this doesn't happen because Mac uses overlay scrollbars, while Windows uses classic scrollbars
		// Reference: https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter
		// Note: scrollbar-gutter doesn't work either because all of these elements have fixed height. We need to revisit this whole structure to fix this properly.
		// This will cause some unwanted extra space at the bottom for some windows users if the browser decides to use overlay scrollbars. This is less of a problem than the current issue.
		tableMaxHeight += 20;
	}

	let tableHeight: number | string | undefined = height;
	if (!tableHeight) {
		// If the table is on the bottom-half of the screen, set height to auto.
		if (distanceFromTop > window.innerHeight / 2) {
			tableHeight = tableMaxHeight;
		} else {
			tableHeight = `calc(100vh - ${distanceFromTop + BOTTOM_PADDING}px)`;
		}
	}

	const options = useMemo(
		() =>
			toJS(
				toJS(searchFilterStoreV2.filterOptions).filter(
					(option) =>
						option === 'divider' ||
						(restrictFilters
							? restrictFilters?.includes(option.type) ||
								restrictFilters?.includes(option.field) ||
								SKIP_RESTRICTED_FILTERS.includes(option.type)
							: true)
				)
			),
		[restrictFilters, searchFilterStoreV2.filterOptions]
	);

	return (
		<SearchFilterV2StoreContext.Provider value={searchFilterStoreV2}>
			<Stack spacing="sm" h="100%">
				{(withSearch || withAdditionalButtons) && (
					<Stack spacing="sm">
						{(withSearch || withAdditionalButtons) && (
							<Group className={classes.searchControls}>
								<Group sx={{ flexGrow: 1 }}>
									{withSearch && (
										<SearchBox
											key={defaultSearchTerm}
											variant="tertiary"
											ref={searchRef}
											placeholder={`Search ${pluralize(
												pluralTypeString ?? 'resources'
											)}`}
											onSearch={handleSearch}
											onlySearchOnEnter
											onCancelSearch={noop}
											defaultSearchTerm={defaultSearchTerm}
											autoFocus={!isEmpty(debouncedSearch)}
										/>
									)}
								</Group>
								<Group>
									{withAdditionalButtons}
									{withSavedViews && (
										<Tooltip label="Select view">
											<ViewMenu standalone />
										</Tooltip>
									)}
									{!isViewerOrGuestUser &&
										columnVisibility?.catalogType &&
										columnVisibility?.catalogServerType && (
											<CustomizeColumnsPanel<T>
												onChangeVisibility={refreshColumns}
												onChangeOrder={refreshColumns}
												defaultColumns={columnsWithSort}
												catalogType={columnVisibility.catalogType}
												catalogServerType={columnVisibility.catalogServerType}
												entityId={columnVisibility.catalogEntityId}
											/>
										)}
									{withCsvExport && (
										<TableV2HeaderDots onDownload={handleCsvExport} />
									)}
								</Group>
							</Group>
						)}
						{withFilters && (
							<Group>
								{size(searchFilterStoreV2.values) > 0 && (
									<>
										{searchFilterStoreV2.values.map((value, idx) => (
											<Filter
												// eslint-disable-next-line react/no-array-index-key
												key={`filter-${idx}}`}
												value={toJS(value)}
												filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
												onChange={searchFilterStoreV2.onChangeValue(idx)}
												onClear={searchFilterStoreV2.onClearValue(idx)}
												showDetailedLabel
												operatorConfig={
													OPERATORS_CONFIG[
														FILTER_OPTIONS_CONFIG[value.filterType]
															.filterDropdownConfig.dropdownType
													]
												}
											/>
										))}
									</>
								)}
								<AddFilter
									options={options}
									onAddFilter={searchFilterStoreV2.onAddValue}
								/>
							</Group>
						)}
					</Stack>
				)}
				<SortStatusV2StoreContext.Provider value={sortStatus}>
					<Box ref={tableRef}>
						<DataTableComponent
							withStickyColumnBorder={withStickyColumnBorder}
							noHeader={!withHeader}
							endReached={handleEndReached}
							nextPageFetching={
								(paginationListData as UseInfiniteQueryResult<T>)
									?.isFetchingNextPage
							}
							maxHeight={tableMaxHeight}
							height={tableHeight}
							storeColumnsKey={storeColumnsKey}
							rowExpansion={rowExpansion}
							classNames={tableClasses}
							columns={columnsWithSortFilterWidth}
							fetching={isFetching}
							loadingText="Loading..."
							noRecordsIcon={noRecordsIcon}
							noRecordsText={`No ${pluralize(
								pluralTypeString ?? 'resources'
							)} found`}
							onCellClick={onCellClick}
							onPageChange={withInfiniteScroll ? undefined : setPage}
							onRowClick={onRowClick}
							page={totalCount > DEFAULT_PAGINATION_SIZE ? page : undefined}
							paginationSize="md"
							records={records}
							recordsPerPage={DEFAULT_PAGINATION_SIZE}
							onRecordsPerPageChange={noop}
							recordsPerPageOptions={[DEFAULT_PAGINATION_SIZE]}
							recordsPerPageLabel=""
							sortStatus={sortStatus}
							totalRecords={totalCount}
							withBorder
							rowSx={rowSx}
							paginationText={({ from, to, totalRecords }) => {
								const totalRecordsString =
									totalRecords === 10000
										? `${totalRecords.toLocaleString()}+`
										: totalRecords.toLocaleString();

								return withInfiniteScroll
									? `Showing ${to.toLocaleString()} of ${totalRecordsString} ${pluralize(
											pluralTypeString ?? 'resources'
										)}`
									: `Showing ${from.toLocaleString()} to ${to.toLocaleString()} of ${totalRecordsString} ${pluralize(
											pluralTypeString ?? 'resources'
										)}`;
							}}
							selectedRecordsState={
								withCheckbox ? selectedRecordsState : undefined
							}
							onSelectedRecordsStateChange={
								withCheckbox ? setSelectedRecordsState : undefined
							}
							customLoader={<TableV2Loader />}
						/>
					</Box>
				</SortStatusV2StoreContext.Provider>
				{withDialog && (
					<TableV2Dialog
						withQuickActions={
							<>
								{activeActions.map((action) => (
									<ActionButton<T>
										key={action.id}
										action={action}
										selectedRecordsState={selectedRecordsState}
										handleCloseDialog={handleCloseDialog}
										setSelectedRecordsState={setSelectedRecordsState}
									/>
								))}
							</>
						}
						count={selectedRecordsState.selectedRecords.length}
						totalCount={totalCount}
						onClose={handleCloseDialog}
						onClick={handleOpenActions}
					/>
				)}
			</Stack>
		</SearchFilterV2StoreContext.Provider>
	);
}

function ActionButton<T extends IBaseModel>({
	action,
	selectedRecordsState,
	handleCloseDialog,
	setSelectedRecordsState,
}: {
	action: ICommandListItem<T>;
	selectedRecordsState: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	};
	handleCloseDialog: () => void;
	setSelectedRecordsState: (state: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}) => void;
}) {
	const [isDisabled, setIsDisabled] = useState(false);

	return (
		<Button
			disabled={isDisabled}
			key={action.id}
			leftIconName={action.iconName ?? undefined}
			onClick={async () => {
				setIsDisabled(true);
				await action.onClick?.(selectedRecordsState.selectedRecords, () => {
					handleCloseDialog();
					setSelectedRecordsState({
						selectedRecords: [],
						lastSelectedIndex: null,
					});
				});
				setIsDisabled(false);
			}}
		>
			{action.title}
		</Button>
	);
}

export default observer(TableV2);
