import { intersection, map } from 'lodash-es';
import { EntityType } from '../../../../lib/types';
import type { FilterMenuItem, FilterOption } from '../FilterCarousel.constants';
import { FilterValue } from '../FilterCarousel.constants';

/**
 * FilterOption is not shown if the filter type is not applicable to the selected filters
 *
 * @param selectedTypes: searchFilterStore['native_type'].selectedOptions
 */
export function showFilterOption(
	searchTerm: string,
	selectedTypes: FilterOption[],
	filterMenuItem: FilterMenuItem
) {
	if (filterMenuItem.alwaysHideFilter) {
		return false;
	}

	if (filterMenuItem.alwaysDisplayFilter) {
		return true;
	}

	// If there's a search term, show if it matches the filter value
	if (
		searchTerm.length &&
		!filterMenuItem.value.toLowerCase().includes(searchTerm.toLowerCase())
	) {
		return false;
	}

	// If it's already selected, don't display
	if (filterMenuItem.selectedOptions.length) return false;

	// Display if the filter value is 'RELATED'
	if (filterMenuItem.value === FilterValue.RELATED) return true;

	// Display if no entity type is selected
	if (selectedTypes.length === 0) {
		return true;
	}

	// Display if the filter applies to any of the selected entity types
	if (filterMenuItem.type) {
		if (filterMenuItem.type.includes(EntityType.all)) {
			return true;
		}
		return (
			intersection(filterMenuItem.type, map(selectedTypes, 'value')).length > 0
		);
	}

	// Default to display
	return true;
}
