import { Code, Stack, Tooltip, UnstyledButton } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import { useAuthUser, useWorkspace } from '../../api';

function InfoItem({
	label,
	value,
	canCopy,
}: {
	label: string;
	value: string;
	canCopy?: boolean;
}) {
	const clipboard = useClipboard();

	return (
		<Stack spacing="xs">
			<Text size="sm">{label}</Text>
			{canCopy ? (
				<Tooltip label={clipboard.copied ? 'Copied!' : 'Click to copy'}>
					<UnstyledButton onClick={() => clipboard.copy(value)}>
						<Code>{value}</Code>
					</UnstyledButton>
				</Tooltip>
			) : (
				<Code>{value}</Code>
			)}
		</Stack>
	);
}

export function Home() {
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	return (
		<Stack spacing="xs">
			<InfoItem label="Workspace ID" value={workspace.id} canCopy />
			<InfoItem label="User ID" value={user.id} canCopy />
		</Stack>
	);
}
