import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';

export const IncidentsColumns: ColumnDefinition = {
	name: {
		field: 'name',
		headerName: 'Name',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
		pinned: 'left',
		lockPosition: 'left',
		suppressMovable: true,
		checkboxSelection: false,
	},
	resource: {
		field: 'resource_id',
		headerName: 'Resource',
		editable: false,
		sortable: false,
		suppressFiltersToolPanel: true,
	},
	status: {
		field: 'incident_status',
		headerName: 'Incident Status',
		colId: 'status',
		editable: true,
	},
	first_seen_at: {
		field: 'first_seen_at',
		headerName: 'First Seen At',
		flex: 1,
	},
	value: {
		field: 'value',
		headerName: 'Value',
		flex: 1,
	},
};
