import {
	Avatar,
	Badge,
	Box,
	createStyles,
	Group,
	Tooltip,
} from '@mantine/core';
import { isNil, map, slice, upperFirst } from 'lodash-es';
import type { ReactNode } from 'react';
import { Icon, Text } from '@repo/foundations';
import { useNavigate } from '../../../../hooks/useNavigate';
import { EntityType } from '../../../../lib/types';
import { pickColor } from '../../../../utils/utils';
import IntegrationLogo from '../../../IntegrationLogo';
import type { SelectablePropertyItem } from '../../../SingleSelector/types';
import { usePropertyStyles } from '../styles';
import type { StaticPropertyType } from './types';
import { isSelectableProperty, isSelectablePropertyArray } from './utils';

interface IStaticPropertyProps {
	type?: StaticPropertyType;
	label: string;
	value?: string | SelectablePropertyItem | SelectablePropertyItem[];
	tooltip?: string;
	forceInline?: boolean;
	wideLabel?: boolean;
	infoTooltip?: string;
	disableUserNavigation?: boolean;
	custom?: ReactNode;
}

const useStyles = createStyles(() => ({
	avatarRoot: {
		cursor: 'pointer',
		outlineWidth: 2,
		outlineStyle: 'solid',
		outlineColor: 'white',
	},
	sourceLogo: {
		background: 'transparent !important',
	},
}));

export function StaticProperty({
	type,
	label,
	value,
	tooltip,
	forceInline,
	wideLabel,
	infoTooltip,
	disableUserNavigation,
	custom,
}: IStaticPropertyProps) {
	const { classes, cx } = useStyles();
	const { classes: propertyClasses } = usePropertyStyles();

	const navigate = useNavigate();

	let valueCol = null;

	const handleNavigateToUser = (userId: string) => {
		if (!disableUserNavigation) {
			// Navigate to user page
			navigate(`/user/${userId}`);
		}
	};

	const handleNavigateToTable = (tableId: string) => {
		// Navigate to table page
		navigate(`/table/${tableId}`);
	};

	if (type === 'text') {
		valueCol = (
			<Tooltip label={tooltip} withArrow withinPortal disabled={isNil(tooltip)}>
				<Box>
					<Text ml={0} size="sm" truncate>
						{value}
					</Text>
				</Box>
			</Tooltip>
		);
	}

	if (type === 'users' && isSelectablePropertyArray(value)) {
		valueCol = (
			<Avatar.Group spacing="xs">
				{map(slice(value, 0, 3), (item) => (
					<Tooltip key={`${item.value}`} label={item.label} withArrow>
						<Avatar
							classNames={{ root: classes.avatarRoot }}
							src={item.icon as string}
							size="xs"
							sx={{
								backgroundColor: pickColor(item.label),
							}}
							onClick={() => {
								handleNavigateToUser(item.value as string);
							}}
						>
							{item.icon || upperFirst(item.label?.slice(0, 1))}
						</Avatar>
					</Tooltip>
				))}
			</Avatar.Group>
		);
	}

	if (type === 'source') {
		valueCol = (
			<Tooltip label={tooltip} withArrow disabled={isNil(tooltip)}>
				<Group spacing={4}>
					<IntegrationLogo
						size={16}
						entityType={EntityType.integration}
						integrationType={value?.toString().toLowerCase()}
					/>
					<Text ml={0} size="sm">
						{value}
					</Text>
				</Group>
			</Tooltip>
		);
	}

	if (type === 'tables' && isSelectablePropertyArray(value)) {
		valueCol = (
			<Avatar.Group spacing="xs" ml={8}>
				{map(slice(value, 0, 3), (item) => (
					<Tooltip key={`${item.value}`} label={item.label} withArrow>
						<Avatar
							classNames={{ root: classes.avatarRoot }}
							size="xs"
							onClick={() => {
								handleNavigateToTable(item.value as string);
							}}
							className={classes.sourceLogo}
						>
							{item.icon}
						</Avatar>
					</Tooltip>
				))}
			</Avatar.Group>
		);
	}

	if (type === 'badge' && isSelectableProperty(value)) {
		valueCol = (
			<Badge my={0} color={value.color} leftSection={value.icon} radius="sm">
				{value.label}
			</Badge>
		);
	}

	if (custom) {
		valueCol = custom;
	}

	return (
		<Group spacing={8} noWrap>
			<Group
				className={cx(propertyClasses.leftCol, {
					[propertyClasses.leftColForceInline]: Boolean(forceInline),
					[propertyClasses.leftColWide]: Boolean(wideLabel),
				})}
				noWrap
			>
				<Group spacing={6} noWrap>
					<Group spacing={0}>
						<Text size="sm" color="text/secondary/default" lineClamp={1}>
							{label}
						</Text>
						{infoTooltip && (
							<Tooltip label={infoTooltip}>
								<Icon name="infoCircle" color="icon/secondary/default" />
							</Tooltip>
						)}
					</Group>
				</Group>
			</Group>
			<Group className={propertyClasses.rightCol}>{valueCol}</Group>
		</Group>
	);
}
