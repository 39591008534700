export const LineageColumns = {
	title: {
		field: 'title',
		headerName: 'Title',
	},
	depth: {
		field: 'depth',
		headerName: 'Depth',
	},
	schema: {
		field: 'metadata.schema',
		headerName: 'Schema',
		flex: 1,
	},
	database: {
		field: 'metadata.database',
		headerName: 'Database',
		flex: 1,
	},
	cluster: {
		field: 'metadata.cluster',
		headerName: 'Cluster',
		flex: 1,
	},
};
